import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { EditTwoTone } from '@ant-design/icons';
import { Input, Pagination, Form, Select, Card, Row, Col,message } from 'antd';
import { app } from '../../config';
import getHeaders from '../../utils/authHeaders';
import ListinngCard from '../../layouts/ListingCard';

const Roles = () => {
  const { t } = useTranslation(['common']);
  const [editbtn, setEditBtn] = useState('none');
  const [fetchedRoles, setFetchedRoles] = useState([]);
  const [search, setSearch] = useState('');
  const [searchShow, setSearchShow] = useState(false);
  const [pagesCount, setPagesCount] = useState(0);
  const [renderList, setRenderList] = useState([]);
  const [tableShow, setTableShow] = useState(true);
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [tableHeaders, setTableHeaders] = useState([]);
  const [err, setErr] = useState('');
  const [loader, setLoader] = useState(false);
  let navigate = useNavigate();
  const editRole = (e) => {
    localStorage.setItem('roleId', JSON.stringify({ roleId: e.roleId, roleName: e.name, isActive: e.isActive }));
    navigate('/role/updaterole', { replace: true });
  };
  
  const rolesListingApi = async (formData) => {
    setLoader(true);
    const roleResp = await axios.post(`${app.URL}/api/v1/rolePermission/role/roleListing`, formData, { headers: getHeaders() });
    console.log(roleResp);
    roleResp.data ? setFetchedRoles(roleResp.data.result.data) : ' ';
    roleResp.data ? setPagesCount(roleResp.data.result.data.count) : ' ';
    roleResp.data ? setRenderList(roleResp.data.result.data.data) : ' ';
    roleResp.data && setLoader(false);
  };

  let formData = {
    limit: pageSize,
    offset: 0,
    searchBy: 'name',
    searchTxt: '',
  };
  
  useEffect(() => {
    setLoader(true);
    const fetchRoles = async () => {
      const user = JSON.parse(localStorage.getItem('user'));
      if (user.userType === 'A') setEditBtn('block');
      let header = ['Role Name', 'Status', 'Action']
      setTableHeaders(header);
      
      await rolesListingApi(formData);
     
      // setSearchItems(searchFilters);
    };
    fetchRoles();
  }, []);

   let searchItems = [
     {
       name: 'Role Name',
       value: 'name',
     },
   ];
  const onFinish = async (values) => {
    setSearchShow(values);
    let formData = {
      limit: 10,
      offset: 0,
      searchBy: values.searchBy,
      searchTxt: values.searchTxt,
    };
    if (!formData.searchTxt) message.error('Please enter search text');
    else await rolesListingApi(formData);
  };
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  
  
  const onChange = async (page, pageSize) => {
    console.log(page);
    setPageSize(pageSize);
    setCurrentPage(page);
    if (!page) page = 1;
    let limit = pageSize;
    try {
      let reqObj;
      if (Object.keys(searchShow).length !== 0) {
        reqObj = {
          limit: limit,
          offset: limit * (page - 1),
          searchBy: searchShow.searchBy,
          searchTxt: searchShow.searchTxt,
        };
      } else {
        reqObj = {
          limit: limit,
          offset: limit * (page - 1),
          searchBy: 'name',
          searchTxt: '',
        };
      }

      await rolesListingApi(reqObj);
    } catch (e) {
      console.log('Error');
      if (e.message) setErr(e.message);
      setTableShow(false);
    }
  };
  const handleSearchChange = (e) => {
    if (!e.target.value) {
      setRenderList(fetchedRoles.slice(0, 10));
      setPagesCount(fetchedRoles.length);
    }
  };
  const addRole = () => {
    navigate('/role/addrole', { replace: true });
  };
  console.log(renderList);
  //   useEffect(()=>{
  //     if(!searchShow)setRenderList(role);
  // },[handleSearch])
  // console.log(renderList);
  const handleOnChange = (e) => {
    if (e.target.value.length === 0) {
      rolesListingApi(formData);
      setSearchShow({});
    }
  };


  return (
    <>
      <ListinngCard
        heading="Role Management"
        buttonName="Add Role"
        addButton={addRole}
        onSearch={onFinish}
        onSearchFailed={onFinishFailed}
        searchItems={searchItems}
        tableHeaders={tableHeaders}
        tableRows={renderList}
        editButton={editRole}
        onPageChange={onChange}
        currentPage={currentPage}
        pagesCount={pagesCount}
        patientsListing={false}
        inventoryListing={false}
        userListings={false}
        storeListing={false}
        productListing={false}
        ethnicListing={false}
        roleListing={true}
        loader={loader}
        onChange={handleOnChange}
      />
    </>
  );

//   return (
//     <div className=''>
//       <div className='ml-3 col-lg-12 col-md-12 col-sm-12 '>
//         <div className='row mt-4'>
//           <div className='col-lg-10 col-md-10 col-sm-10 fs-3 fw-bold'>{t('Role & Permission Management')}</div>
//         </div>
//         <div class='container m-0 pl-0' style={{ maxWidth: '100%' }}>
//           <div className='row mb-3 ml-3'>
//             <div className='col-lg-7 col-md-7 col-sm-7 d-flex flex-row-reverse'>
//               <button class='btn btn-primary button' onClick={addRole}>
//                 Add Role
//               </button>
//             </div>
//           </div>
//           <div class='row'>
//             <Form
//               name='basic'
//               labelCol={{
//                 span: 8,
//               }}
//               wrapperCol={{
//                 span: 8,
//               }}
//               style={{ alignItems: 'flexEnd' }}
//               initialValues={{
//                 remember: true,
//               }}
//               onFinish={onFinish}
//               onFinishFailed={onFinishFailed}
//               autoComplete='off'
//             >
//               <Row gutter={[16, 16]}>
//                 <Col style={{ paddingLeft: '0px', paddingRight: '0px' }} span={4}>
//                   <Form.Item
//                     colon={false}
//                     style={{
//                       width: 700,
//                       paddingLeft: '10px',
//                     }}
//                     name='searchFor'
//                     rules={[
//                       {
//                         required: false,
//                         message: 'Please select date!',
//                       },
//                     ]}
//                   >
//                     <Select placeholder='Search By Order/Patient/Partner/Product' className='search'>
//                       {searchItems.map((item) => (
//                         <Select.Option value={item.fieldMappedBy}>{item.fieldName}</Select.Option>
//                       ))}
//                     </Select>
//                   </Form.Item>
//                 </Col>
//                 <Col style={{ paddingLeft: '0px', paddingRight: '0px' }} span={4}>
//                   <Form.Item
//                     colon={false}
//                     name='searchItem'
//                     style={{
//                       width: 700,
//                       paddingLeft: '10px',
//                     }}
//                   >
//                     <Input placeholder='Enter value for search' className='search' />
//                   </Form.Item>
//                 </Col>

//                 <Col style={{ paddingLeft: '0px', paddingRight: '0px' }} span={3}>
//                   <Form.Item
//                     style={{
//                       paddingLeft: '10px',
//                     }}
//                   >
//                     <button type='primary' class='btn btn-primary button' htmlType='submit'>
//                       Search
//                     </button>
//                   </Form.Item>
//                 </Col>
//                 <Col style={{ paddingLeft: '0px', paddingRight: '0px' }} span={3}>
//                   {/* <CSVLink filename={'users-report.csv'} data={respList}>
//                 <img style={{ marginTop: '-5px' }} src='/excel.png' />
//               </CSVLink> */}
//                 </Col>
//               </Row>
//             </Form>
//           </div>
//         </div>
//         <div className='col-lg-7 col-md-12 col-sm-12 '>
//           <div class=' styled-table '>
//             <table class='table text-black'>
//               <thead class='thead-light'>
//                 <tr>
//                   {/* <th>{t("ID")}</th> */}
//                   <th>{t('Role Name')}</th>
//                   <th>{t('Role Type')}</th>
//                   <th>{t('Status')}</th>
//                   {/* <th>{t("Phone")}</th>
//                              <th>{t("User Type")}</th> */}
//                   <th> </th>
//                 </tr>
//               </thead>
//               <tbody>
//                 {renderList
//                   ? renderList.map((output) => (
//                       // onClick={() => editRole(output)}
//                       <tr className='row-hover'>
//                         <td style={{ width: '300px' }}>{output.name ? output.name : 'null'}</td>
//                         <td style={{ width: '200px' }}>{output.roleType === 'S' ? 'Super Admin' : output.roleType === 'A' ? 'Admin' : 'Organizations'}</td>
//                         <td style={{ width: '200px' }}>{output.isActive === '1' ? 'Active' : 'Inactive'}</td>
//                         <td>
//                           <EditTwoTone displayName='edit' twoToneColor='blue' style={{ fontSize: '20px' }} onClick={() => editRole(output)} />
//                         </td>
//                       </tr>
//                     ))
//                   : ' '}
//               </tbody>
//             </table>
//           </div>
//         </div>
//         <Pagination style={{ marginTop: '20px' }} onChange={onChange} defaultCurrent={currentPage} total={pagesCount} showTotal={(total) => `Total ${total} items`} showSizeChanger />
//       </div>
//       {/* </div>  
// </div> */}
//     </div>
//   );
};

export default Roles;
