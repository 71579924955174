import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import getHeaders from '../../utils/authHeaders';
import { app } from '../../config';

import { Pagination, Input, Form, Select, Row, Col, Space, Spin, message, Collapse, DatePicker, Button, Switch } from 'antd';
import moment from 'moment';

const renderSerialNoData = (data)=>{
  return (
    <>
      <div className='row '>
        <div className='col-12 d-flex flex-column gap-3'>
          {data?.batchId && (
            <div className='row justify-content-evenly'>
              <div className='col-5 p-2 px-3 border'>
                <span>Batch Id</span>
              </div>
              <div className='col-5 p-2 px-3 border'>
                <span>{data?.batchId}</span>
              </div>
            </div>
          )}

          <div className='row justify-content-evenly'>
            <div className='col-5 p-2 px-3 border'>
              <span>Bind</span>
            </div>

            <div className='col-5 p-2 px-3 border'>
              <Switch checked={data?.isBind} disabled />
            </div>
          </div>

          <div className='row justify-content-evenly'>
            <div className='col-5 p-2 px-3 border'>
              <span>Used</span>
            </div>

            <div className='col-5 p-2 px-3 border'>
              <Switch checked={data?.isUsed} disabled />
            </div>
          </div>

          <div className='row justify-content-evenly'>
            <div className='col-5 p-2 px-3 border'>
              <span>Active</span>
            </div>

            <div className='col-5 px-3 p-2 border'>
              <Switch checked={data?.isActive} disabled />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const renderGenericBoxData = (data)=>{
  return (
    <>
      <div className='row '>
        <div className='col-12 d-flex flex-column gap-3'>
          {data?.batchId && (
            <div className='row justify-content-evenly'>
              <div className='col-5 p-2 px-3 border'>
                <span>Batch Id</span>
              </div>
              <div className='col-5 p-2 px-3 border'>
                <span>{data?.batchId}</span>
              </div>
            </div>
          )}

          <div className='row justify-content-evenly'>
            <div className='col-5 p-2 px-3 border'>
              <span>Bind</span>
            </div>

            <div className='col-5 p-2 px-3 border'>
              <Switch checked={data?.isBind} disabled />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const renderOrderNoData = (data)=>{
  return (
    <>
      <div className='row '>
        <div className='col-12 d-flex flex-column gap-3'>
          {data?.genBoxSerial && (
            <div className='row justify-content-evenly'>
              <div className='col-5 p-2 px-3 border'>
                <span>Generic Box</span>
              </div>
              <div className='col-5 p-2 px-3 border'>
                <span>{data?.genBoxSerial}</span>
              </div>
            </div>
          )}

          {data?.serialNo && (
            <div className='row justify-content-evenly'>
              <div className='col-5 p-2 px-3 border'>
                <span>Serial No</span>
              </div>
              <div className='col-5 p-2 px-3 border'>
                <span>{data?.serialNo}</span>
              </div>
            </div>
          )}

          {data?.accessionNo && (
            <div className='row justify-content-evenly'>
              <div className='col-5 p-2 px-3 border'>
                <span>Accession No</span>
              </div>
              <div className='col-5 p-2 px-3 border'>
                <span>{data?.accessionNo}</span>
              </div>
            </div>
          )}

          {data?.orderDate && (
            <div className='row justify-content-evenly'>
              <div className='col-5 p-2 px-3 border'>
                <span>Order Date</span>
              </div>
              <div className='col-5 p-2 px-3 border'>
                <span>{moment(data?.orderDate).format('LL')}</span>
              </div>
            </div>
          )}

          {data?.cisPanelCode && (
            <div className='row justify-content-evenly'>
              <div className='col-5 p-2 px-3 border'>
                <span>CIS Panel Code</span>
              </div>
              <div className='col-5 p-2 px-3 border'>
                <span>{data?.cisPanelCode}</span>
              </div>
            </div>
          )}

          {data?.sendToLabDateTime && (
            <div className='row justify-content-evenly'>
              <div className='col-5 p-2 px-3 border'>
                <span>Send To Lab Date And Time</span>
              </div>
              <div className='col-5 p-2 px-3 border'>
                <span>{moment(data?.sendToLabDateTime).format('MMMM Do YYYY, h:mm:ss a')}</span>
              </div>
            </div>
          )}

          <div className='row justify-content-evenly'>
            <div className='col-5 p-2 px-3 border'>
              <span>Active</span>
            </div>

            <div className='col-5 p-2 px-3 border'>
              <Switch checked={data?.isActive} disabled />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
const renderAccessionNoData = (data)=>{
  return (
    <>
      <div className='row '>
        <div className='col-12 d-flex flex-column gap-3'>
          {data?.genBoxSerial && (
            <div className='row justify-content-evenly'>
              <div className='col-5 p-2 px-3 border'>
                <span>Generic Box</span>
              </div>
              <div className='col-5 p-2 px-3 border'>
                <span>{data?.genBoxSerial}</span>
              </div>
            </div>
          )}

          {data?.serialNo && (
            <div className='row justify-content-evenly'>
              <div className='col-5 p-2 px-3 border'>
                <span>Serial No</span>
              </div>
              <div className='col-5 p-2 px-3 border'>
                <span>{data?.serialNo}</span>
              </div>
            </div>
          )}

          {data?.orderNo && (
            <div className='row justify-content-evenly'>
              <div className='col-5 p-2 px-3 border'>
                <span>Order No</span>
              </div>
              <div className='col-5 p-2 px-3 border'>
                <span>{data?.orderNo}</span>
              </div>
            </div>
          )}

          {data?.orderDate && (
            <div className='row justify-content-evenly'>
              <div className='col-5 p-2 px-3 border'>
                <span>Order Date</span>
              </div>
              <div className='col-5 p-2 px-3 border'>
                <span>{moment(data?.orderDate).format('LL')}</span>
              </div>
            </div>
          )}

          {data?.cisPanelCode && (
            <div className='row justify-content-evenly'>
              <div className='col-5 p-2 px-3 border'>
                <span>CIS Panel Code</span>
              </div>
              <div className='col-5 p-2 px-3 border'>
                <span>{data?.cisPanelCode}</span>
              </div>
            </div>
          )}

          {data?.sendToLabDateTime && (
            <div className='row justify-content-evenly'>
              <div className='col-5 p-2 px-3 border'>
                <span>Send To Lab Date And Time</span>
              </div>
              <div className='col-5 p-2 px-3 border'>
                <span>{moment(data?.sendToLabDateTime).format('MMMM Do YYYY, h:mm:ss a')}</span>
              </div>
            </div>
          )}

          {data?.collectionTime && (
            <div className='row justify-content-evenly'>
              <div className='col-5 p-2 px-3 border'>
                <span>Collection Time</span>
              </div>
              <div className='col-5 p-2 px-3 border'>
                <span>{moment(data?.collectionTime).format('MMMM Do YYYY, h:mm:ss a')}</span>
              </div>
            </div>
          )}

          <div className='row justify-content-evenly'>
            <div className='col-5 p-2 px-3 border'>
              <span>Receiver Order</span>
            </div>

            <div className='col-5 p-2 px-3 border'>
              <Switch checked={data?.isReceiveOrder} disabled />
            </div>
          </div>

          <div className='row justify-content-evenly'>
            <div className='col-5 p-2 px-3 border'>
              <span>Active</span>
            </div>

            <div className='col-5 p-2 px-3 border'>
              <Switch checked={data?.isActive} disabled />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}


const EcommerceModal = ({ isVisible, setIsVisible, ecomOrderNo, ecomOrderCreatedData, patientName, shipmentTotal, productTotalAmount, subOrdersData, mainOrderTotalAmount, mainOrderShipmentAmount, mainOrderGrandTotal, isModalLoading, setIsLoading, totalProductAmount, isApiCallAble, ecomOrderId, wareHouseStatus, commentsData = [], totalTax }) => {
  const commentTableHeaders = ['Sub Order No.', 'Date & Time', 'Comment By', 'Comment'];

  // const [commentsObj, setCommentsObj] = useState({});

  // useEffect(() => {
  //   setCommentsObj({
  //     ecomOrderId: ecomOrderId,
  //   });
  // }, [ecomOrderId]);
  // console.log('commentsObj: ');
  // console.log(commentsObj);
  //   useEffect(() => {
  //     const getAllMainOrderComments = async () => {
  //       try {
  //         let resp = await axios.get(`${app.URL}/api/v1/admin/comment/viewbyorder/${ecomOrderId}`, { headers: getHeaders() });
  //         console.log('comments respo: ', resp.data.result.data);
  //         setCommentsData(resp.data.result.data);
  //         console.log('comments data: ', commentsData);
  //       } catch (error) {
  //         console.log('error in getting comments data: ', error);
  //       }
  //     };
  //     getAllMainOrderComments();
  //   }, [commentsObj]);

  //   useEffect(() => {
  //     const getMainOrderData = async () => {
  //       try {
  //         // setIsLoading(true);
  //         console.log('id: ', ecomOrderId);
  //         console.log('app:', app.URL);
  //         console.log('headers: ', getHeaders());

  //         let resp = await axios.get(`${app.URL}/api/v1/admin/ecomorders/view/${ecomOrderId}`, { headers: getHeaders() });

  //         console.log('order data in modal effect: ', resp.data.result.data);
  //         subOrdersData = resp.data.result.data?.orderDetails;
  //         // setIsLoading(false);
  //       } catch (error) {
  //         console.log('Error: ', error);
  //       }
  //     };

  //     console.log('call able: ', isApiCallAble);
  //     // This code will run when the component is mounted or `isVisible` changes to `true`.
  //     if (isApiCallAble) {
  //       console.log('loading modal: ', isLoading);
  //       console.log('data modal: ', subOrdersData);
  //       console.log('opened');
  //       getMainOrderData();
  //       // getAllMainOrderComments();
  //     }
  //     // getAllMainOrderComments();
  //   }, [isApiCallAble]);

  return (
    <Modal
      show={isVisible}
      size='lg'
      onHide={() => {
        setIsVisible(!isVisible);
      }}
      centered
    >
      <Modal.Header
        closeButton={true}
        style={{
          display: 'block',
        }}
      >
        <Modal.Title style={{ display: 'block', padding: '9px 13px 8px 8px', fontSize: '20px' }}>
          <div className='row'>
            <div className='col-lg-6'>{ecomOrderNo}</div>
            <div className='col-lg-6 text-right'>{ecomOrderCreatedData && moment(ecomOrderCreatedData).format('LL')}</div>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ padding: '0px 20px !important' }}>
        {!isModalLoading ? (
          <>
            {subOrdersData?.map((subOrder, index) => {
              
              return (
                <div
                  className='p-4 mb-4'
                  style={{
                    border: '1px solid silver',
                    borderRadius: 10,
                  }}
                >
                  <div className='row'>
                    <div className='row'>
                      <div className='col-lg-6 fs-5 mt-0 mb-0 pt-0 pb-0 pl-3'>{subOrder?.title}</div>
                      <div className='col-lg-6 mt-0 mb-0 pt-2 pb-0 pl-3 text-right'>{subOrder?.status}</div>
                    </div>
                    <div className='row'>
                      <div className='col-lg-6  mt-0 mb-0 pt-0 pb-0 pl-3'>
                        {subOrder?.address} {subOrder?.state} {subOrder?.city} {subOrder?.zipCode}
                      </div>
                      {subOrder?.wareHouseStatus && <div className='col-lg-6  mt-0 mb-0 pt-0 pb-0 pl-0 text-right'>Shipment Status: {subOrder?.wareHouseStatus}</div>}
                      {/* <div className='col-lg-6  mt-0 mb-0 pt-0 pb-0 pl-0 text-right'>Warehouse Status: {wareHouseStatus}</div> */}
                    </div>
                    <div className='row'>
                      <div className='col-lg-6  mt-0 mb-0 pt-0 pb-0 pl-3'>{subOrder?.shipmePackageName}</div>
                      {subOrder?.requestId && <div className='col-lg-6  mt-0 mb-0 pt-0 pb-0 pl-0 text-right'>Request Type: {subOrder?.requestType}</div>}
                    </div>
                    <div className='row'>{subOrder?.requestId && <div className='col-lg-12  mt-0 mb-0 pt-0 pb-0 pl-0 text-right'>Request Status: {subOrder?.requestStatus}</div>}</div>
                    {/* <div className='col-lg-6 fs-5 mt-0 mb-0 pt-0 pb-0 pl-3'>{title}</div>
                    <div className='col-lg-6 mt-0 mb-0 pt-2 pb-0 pl-3 text-right'>{status}</div>
                    <div className='col-lg-6  mt-0 mb-0 pt-0 pb-0 pl-3'>{address}</div>
                    <div className='col-lg-6  mt-0 mb-0 pt-0 pb-0 pl-0 text-right'>Warehouse Status: {wareHouseStatus}</div>
                    <div className='col-lg-6  mt-0 mb-0 pt-0 pb-0 pl-3'>{shipmePackageName}</div>

                    {
                        requestId && (
                            <>
                                <div className='col-lg-6  mt-0 mb-0 pt-0 pb-0 pl-0 text-right'>Request Type: {requestType}</div>
                                <div className='col-lg-12  mt-0 mb-0 pt-0 pb-0 pl-0 text-right'>Request Status: {requestStatus}</div>
                            </>
                        )
                    } */}
                  </div>
                  {/* <div className='row'>
                                    <div className='col-lg-6 fs-5 mt-0 mb-0 pt-0 pb-0 pl-3'>{subOrder.title}</div>
                                    <div className='col-lg-6 mt-0 mb-0 pt-2 pb-0 pl-3 text-right'>{subOrder.status}</div>
                                    <div className='col-lg-6  mt-0 mb-0 pt-0 pb-0 pl-3'>{`${subOrder.address}, ${subOrder.state}, ${subOrder.city}, ${subOrder.zipCode}`}</div>
                                    <div className='col-lg-6 mt-0 mb-0 pt-0 pb-0 pl-3 text-right'>{subOrder.shipmePackageName}</div>

                                    {
                                        subOrder.requestId && (
                                            <>
                                                <div className='col-lg-12  mt-0 mb-0 pt-0 pb-0 pl-0 text-right'>Request Type: {subOrder.requestType}</div>
                                                <div className='col-lg-12  mt-0 mb-0 pt-0 pb-0 pl-0 text-right'>Request Status: {subOrder.requestStatus}</div>
                                            </>
                                        )
                                    }

                                </div> */}
                  <div className='mb-4'>
                    <table id='customers' className='w-100'>
                      <thead>
                        <tr
                          style={{
                            fontWeight: 'bold',
                          }}
                        >
                          <th>Product Name </th>
                          <th>Quantity</th>
                          <th>Unit Price</th>
                          <th>Total</th>
                        </tr>
                      </thead>
                      <tbody>
                        {subOrder?.products?.map((product, index) => {
                          return (
                            <tr>
                              <td style={{ width: '100px' }}>{product.productName}</td>
                              <td style={{ width: '100px' }}>{product.quantity}</td>
                              <td style={{ width: '100px' }}>${Number(product.unitPrice).toFixed(2)}</td>
                              <td style={{ width: '100px' }}>${(parseFloat(product.quantity) * parseFloat(product.unitPrice)).toFixed(2)}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                    <div className='row'>
                      <div className='col-lg-12  mt-0 mb-0 pt-0 pb-0 pl-3 text-right'>Total Product Amount: ${subOrder.productTotal}</div>
                      <div className='col-lg-12  mt-0 mb-0 pt-0 pb-0 pl-3 text-right'>Tax: ${subOrder.tax}</div>
                      {/* <div className='col-lg-12  mt-0 mb-0 pt-0 pb-0 pl-3 text-right'>Shipment Charges: {Number(subOrder.shipmentCharges) <= 0 ? 'Free' : `$${Number(subOrder.shipmentCharges)?.toFixed(2)}`} </div> */}
                      <div className='col-lg-12  mt-0 mb-0 pt-0 pb-0 pl-3 text-right'>Grand Total: ${subOrder.grandTotal}</div>
                    </div>
                  </div>

                  {/* <div className='row'>
                                    <div className='col-lg-12  mt-0 mb-0 pt-0 pb-0 pl-3 text-right'>Total Amount: ${product.totalShipmentAmount}</div>
                                    <div className='col-lg-12  mt-0 mb-0 pt-0 pb-0 pl-3 text-right'>Total Tax: ${product.taxAmount.toFixed(2)}</div>
                                    <div className='col-lg-12  mt-0 mb-0 pt-0 pb-0 pl-3 text-right'>Shipment Amount: ${product.shipmentCharges} </div>
                                    <div className='col-lg-12  mt-0 mb-0 pt-0 pb-0 pl-3 text-right'>Grand Total: ${(product.totalAmountwithShipment + product.taxAmount).toFixed(2)}</div>
                                </div> */}
                </div>
              );
            })}

            <div className='row mb-6'>
              {totalProductAmount ? <div className='col-lg-12  mt-0 mb-0 pt-0 pb-0 pl-3 text-right'>Total Product Amount: ${totalProductAmount?.toFixed(2)}</div> : <div className='col-lg-12  mt-0 mb-0 pt-0 pb-0 pl-3 text-right'>Total Amount: $ {mainOrderTotalAmount?.toFixed(2)}</div>}
              {/* <div className='col-lg-12  mt-0 mb-0 pt-0 pb-0 pl-3 text-right'>Total Amount: $ {mainOrderTotalAmount}</div> */}
              <div className='col-lg-12  mt-0 mb-0 pt-0 pb-0 pl-3 text-right'>Total Tax: ${totalTax}</div>
              {/* <div className='col-lg-12  mt-0 mb-0 pt-0 pb-0 pl-3 text-right'>Shipment Charges: {mainOrderShipmentAmount != 0 ? `$${mainOrderShipmentAmount?.toFixed(2)}` : 'Free'}</div> */}
              <div className='col-lg-12  mt-0 mb-0 pt-0 pb-0 pl-3 text-right'>Grand Total: ${mainOrderGrandTotal}</div>
            </div>
            <div
              className='row mt-2'
              style={{
                border: '1px solid silver',
                borderRadius: 10,
                // height:"300px"
              }}
            >
              <div className='col-lg-6 fs-5 mt-0 mb-0 pt-0 pb-0 pl- mt-2'>History</div>

              <div
                style={{
                  maxHeight: '200px',
                  overflowY: 'auto',
                }}
              >
                <table
                  className='table'
                  style={{
                    width: '100%',
                    borderCollapse: 'collapse',
                  }}
                >
                  <thead
                    className=''
                    style={{
                      backgroundColor: '#f2b544',
                      color: '#fff',
                    }}
                  >
                    <tr
                      style={{
                        fontWeight: 'bold',
                      }}
                    >
                      {commentTableHeaders.map((header, index) => {
                        return (
                          <th
                            style={{
                              textAlign: 'center',
                              padding: '8px',
                              fontSize: '13px',
                            }}
                          >
                            {header}
                          </th>
                        );
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    {commentsData?.map((commentItem, index) => {
                      return (
                        <tr
                          key={index}
                          style={{
                            backgroundColor: index % 2 == 0 ? '' : '#dddddd',
                          }}
                        >
                          <td
                            style={{
                              width: '30px',
                              textAlign: 'center',
                              padding: '8px',
                              fontSize: '13px',
                            }}
                          >
                            {`${commentItem.ecomOrderNo}-${commentItem.addressId}`}
                          </td>
                          <td style={{ width: '50px', textAlign: 'center', padding: '8px', fontSize: '13px' }}>{moment(commentItem.createdAt).format('MM-DD-YYYY HH:mm')}</td>

                          <td
                            style={{
                              width: '20px',
                              textAlign: 'center',
                              padding: '8px',
                              fontSize: '13px',
                            }}
                          >
                            {commentItem.commentBy}
                          </td>

                          <td style={{ width: '100px', textAlign: 'center', padding: '8px', fontSize: '13px' }}>{commentItem.comment}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </>
        ) : (
          <div className='row text-center'>
            <span>
              {' '}
              <Space size='middle'>
                <Spin size='large' />
              </Space>
            </span>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <button
          variant='primary'
          className='btn btn-primary'
          onClick={() => {
            setIsVisible(!isVisible);
          }}
        >
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
};

const SearchOrderModals = ({isVisible, setIsVisible,modalData, header})=>{
  console.log("header = > ", modalData)
  return (
    <Modal
      show={isVisible}
      size='lg'
      onHide={() => {
        setIsVisible(!isVisible);
      }}
      centered
    >
      <Modal.Header
        closeButton={true}
        style={{
          display: 'block',
        }}
      >
        <Modal.Title style={{ display: 'block', padding: '9px 13px 8px 8px', fontSize: '20px' }}>
          <div className='row'>
            <div className='col-lg-6'>{header === 'orderNo' ? modalData?.orderNo : header === 'accessionNo' ? modalData?.accessionNo : modalData?.serialNo}</div>
            <div className='col-lg-6 text-right'>{modalData?.createdAt && moment(modalData?.createdAt).format('LL')}</div>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ padding: '0px 20px !important' }}>
        {header === 'serialNo' && renderSerialNoData(modalData)}
        {header === 'genericBox' && renderGenericBoxData(modalData)}
        {header === 'orderNo' && renderOrderNoData(modalData)}
        {header === 'accessionNo'&& renderAccessionNoData(modalData)}
      </Modal.Body>
      <Modal.Footer>
        <button
          variant='primary'
          className='btn btn-primary'
          onClick={() => {
            setIsVisible(!isVisible);
          }}
        >
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
}
export { EcommerceModal ,SearchOrderModals};
