import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { app } from '../../config';
import { useNavigate, useLocation } from 'react-router-dom';
import useFetchUsers from '../../hooks/fetchUsers';
import { message, Pagination, Input, Form, Select, Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import { EditTwoTone } from '@ant-design/icons';
import getHeaders from '../../utils/authHeaders';
import ListinngCard from '../../layouts/ListingCard';

function UserFeedbackListing() {
  const { t } = useTranslation(['common']);
  let navigate = useNavigate();
  let location = useLocation();
  let searchedUser;
  const [editbtn, setEditBtn] = useState('none');
  const [search, setSearch] = useState('');
  const [pageSize, setPageSize] = useState(10);
  const [fetchUsers, setFetchUsers] = useState([]);
  const [pagesCount, setPagesCount] = useState(0);
  const [searchShow, setSearchShow] = useState({});
  const [renderList, setRenderList] = useState();
  const [tableShow, setTableShow] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [loader, setLoader] = useState(false);
 
  const [tableHeaders, setTableHeaders] = useState(['Date', 'Full Name', 'Screen Name', 'Type', 'Title', 'Description', 'Comments', 'Status', 'Attachment', 'Action']);
  const [err, setErr] = useState('');

  let searchItems = [
    {
      name: 'Status',
      value: 'status',
    },
  ];

  const getAllUserTestingFeedbacks = async (requestObj = {}) => {
    setLoader(true);
    try {
      const apiResponse = await axios.post(`${app.URL}/api/v1/admin/feedback/view`, requestObj);
      console.log(apiResponse);
      setRenderList(apiResponse.data.result.data.data);
      setPagesCount(apiResponse.data.result.data.count);
      setLoader(false)
    } catch (error) {
      setLoader(false);

      console.log('Error in getAllUserTestingFeedbacks: ', error);
      setFeedbackGetResponse(error.response.data.result);
    }
  };

  let query = {
    limit: pageSize,
    offset: 0,
    searchBy: 'status', //partnerName, serialNo
    searchTxt: '',
  };
  useEffect(() => {
    getAllUserTestingFeedbacks(query);
    return () => {};
  }, []);

  const onChange = async (page, pageSize) => {
    console.log(page, pageSize);
    setPageSize(pageSize);
    setCurrentPage(page);
    if (!page) page = 1;
    let limit = pageSize;
    try {
      let reqObj;
      reqObj = {
        limit: limit,
        offset: limit * (page - 1),
        searchBy: '', //partnerName, serialNo
        searchTxt: '',
      };

      console.log(reqObj);

      getAllUserTestingFeedbacks(reqObj);
    } catch (e) {
      console.log('Error');
    }
  };

  const onFinish = async (values) => {
    console.log(values);
    setSearchShow(values);
    try {
      let searchData = {
        limit: 10,
        offset: 0,
        searchBy: values.searchBy,
        searchTxt: values.searchTxt,
      };
      if (!searchData.searchTxt) message.error('Please enter search text');
      else await getAllUserTestingFeedbacks(searchData);
    } catch (e) {
      console.log('Error');
      if (e.message) setErr(e.message);
    }
  };
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const editFeedback = (e) => {
    localStorage.setItem('setFeedbackId', JSON.stringify(e.userFeedbackId));
    navigate('/feedback/update', { replace: true });
  };
  const addFeedback = () => {
    navigate('/feedback/create', { replace: true });
  };

  console.log(currentPage, pagesCount);

  // console.log(renderList)
  const handleOnChange = (e) => {
    if (e.target.value.length === 0) {
      getAllUserTestingFeedbacks(query);
      setSearchShow({});

    }
  };

  return (
   
      <ListinngCard
        heading='User Feedback'
        buttonName='Add Feedback'
        addButton={addFeedback}
        onSearch={onFinish}
        onSearchFailed={onFinishFailed}
        searchItems={searchItems}
        tableHeaders={tableHeaders}
        tableRows={renderList}
        editButton={editFeedback}
        onPageChange={onChange}
        currentPage={currentPage}
        pagesCount={pagesCount}
        patientsListing={false}
        inventoryListing={false}
        storeListing={false}
        productListing={false}
        userListings={false}
        feedbackListing={true}
      onChange={handleOnChange}
      loader={loader}
      />
  
  );
}

export default UserFeedbackListing;
