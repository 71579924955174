import React, { useEffect, useState } from 'react';
import { InputField, SelectField, TextAreaField } from '../../layouts/FormFields';
import { Form, Select, Space, Switch, message } from 'antd';
import { QuestionaireForm } from '../../layouts/AddQuestionaire';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { app } from '../../config';
import getHeaders from '../../utils/authHeaders';
import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';

const RequestButtonEdit = () => {
  const [loading, setLoading] = useState(false);
  const [uploadObj, setUploadObj] = useState({});
  const [show, setShow] = useState(false);
  const [addUserResp, setAddUserResp] = useState({});
  const [requestImage, setRequestImage] = useState(false);
  const [requestQuestion, setRequestQuestion] = useState(false);
  const [questionnaire, setQuestionnaire] = useState([]);
  const [typeLoader, setTypeLoader] = useState(false);
  const [Name, setRequestName] = useState('');
  const navigate = useNavigate();

  const user = JSON.parse(localStorage.getItem('user'));

  useEffect(() => {
    const requestButtonId = localStorage.getItem('requestTypeId');
    if (!requestButtonId) {
      message.error('No request button ID found. Redirecting to view page.');
      navigate('/reqbuttons/view', { replace: true });
      return;
    }

    const fetchRequestButton = async () => {
      try {
        const res = await axios.get(`${app.URL}/api/v1/admin/reqbuttons/view/${requestButtonId}`, { headers: getHeaders() });
        const data = res.data.result.data[0];
        console.log('Fetched Data:', data);

        setRequestImage(data.requestImage);
        setRequestQuestion(data.requestQuestion);

        const questions =
          data.questions.length > 0
            ? data.questions
            : [
                {
                  question: '',
                  options: [''],
                  type: '',
                  displayPosition: '',
                },
              ];
        setQuestionnaire(questions);
        setRequestName(data.requestName);

        setUploadObj({
          requestImage: data.requestImage,
          requestQuestion: data.requestQuestion,
          requestType: data.requestType,
        });
      } catch (err) {
        message.error('Failed to fetch request button data.');
      }
    };

    fetchRequestButton();
  }, [navigate]);

  const onFinish = () => {
    setShow(true);

    // Remove questionId from each question in the questionnaire
    const updatedQuestions = questionnaire.map(({ questionId, ...rest }) => rest);

    setUploadObj({
      ...uploadObj,
      questions: updatedQuestions,
    });
  };

  const uploadData = async (uploadObj) => {
    setLoading(true);
    try {
      console.log('hello', uploadObj);
      let userId = user?.result.data.user.userId;
      uploadObj.userId = userId;
      const requestButtonId = localStorage.getItem('requestTypeId');
      const res = await axios.post(`${app.URL}/api/v1/admin/reqbuttons/update/${requestButtonId}`, uploadObj, { headers: getHeaders() });
      setLoading(false);
      setShow(false);

      if (res.data.result.success) {
        message.success(res.data.result.msg);
        setTimeout(() => {
          navigate('/reqbuttons/view');
        }, 1000);
      } else {
        message.warning(res.data.result.msg);
      }
    } catch (err) {
      setLoading(false);
      setShow(false);
      message.error('Failed to update request button.');
    }
  };

  const submitData = () => {
    if (Object.keys(uploadObj).length > 0) {
      uploadData(uploadObj);
    }
  };

  const handleClose = () => {
    setShow(false);
  };

  const updateOptions = (inputValue, optionIndx, questionIndx) => {
    setQuestionnaire((prev) => {
      const updated = [...prev];
      updated[questionIndx].options[optionIndx] = inputValue;
      return updated;
    });
  };

  const deleteOption = (questionIndx, optionIndx) => {
    setQuestionnaire((prev) => {
      const updated = [...prev];
      updated[questionIndx].options.splice(optionIndx, 1);
      return updated;
    });
  };

  const deleteQuestion = (questionIndx) => {
    setQuestionnaire((prev) => {
      const updated = [...prev];
      updated.splice(questionIndx, 1);
      return updated;
    });
  };

  const addQuestion = () => {
    setQuestionnaire((prev) => [
      ...prev,
      {
        question: '',
        options: [''],
        type: '',
        displayPosition: '',
      },
    ]);
  };

  const changeQuestionType = (questionIndx, newType) => {
    setQuestionnaire((prev) => {
      const updated = [...prev];
      updated[questionIndx].type = newType;

      if (newType === 'text') {
        updated[questionIndx].options = ['']; // Ensure only one option exists
      }

      return updated;
    });
  };

  const changeQuestionRequired = (questionIndx, newType) => {
    setQuestionnaire((prevQuestionnaire) => {
      const newQuestionnaire = [...prevQuestionnaire];
      newQuestionnaire[questionIndx].isRequired = newType;
      return newQuestionnaire;
    });
  };

  const changeQuestionText = (questionIndx, newText) => {
    setQuestionnaire((prev) => {
      const updated = [...prev];
      updated[questionIndx].question = newText;
      return updated;
    });
  };

  console.log(questionnaire);

  if (questionnaire.length > 0) {
    return (
      <QuestionaireForm
        loading={loading}
        pageTitle='Update Request Button'
        initialValues={{
          remember: true,
          questionnaire,
        }}
        onFinish={onFinish}
        handleClose={handleClose}
        addUserResp={addUserResp}
        show={show}
        addMessage='Are you sure you want to update this request button?'
        goBack={() => navigate('/reqbuttons/view')}
        submitData={submitData}
        upload={false}
        inputs={[<InputField col='col-lg-2 col-md-4 col-12' label='Request Type' required={false} disabled={true} value={Name} />]}
        extras={[
          <div className='col-md-12'>
            <div className='row'>
              <div className='d-flex align-items-center col-lg-2 col-md-4 col-12'>
                <Form.Item label='Get Image' valuePropName='checked' initialValue={requestImage}>
                  <Switch checked={requestImage} onChange={setRequestImage} />
                </Form.Item>
              </div>
              <div className='d-flex align-items-center col-lg-2 col-md-4 col-12'>
                <Form.Item label='Ask Question' valuePropName='checked' initialValue={requestQuestion}>
                  <Switch checked={requestQuestion} onChange={setRequestQuestion} />
                </Form.Item>
              </div>

              <Form.List name='questionnaire'>
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, ...restField }) => {
                      const question = questionnaire[key];
                      const options = question.options;

                      console.log(question);

                      return (
                        <Space key={key} className='col-md-12 m-0' align='baseline' gap='13'>
                          <div className='row w-100'>
                            <InputField {...restField} col='col-lg-4 col-md-3 col-12' placeholder='Enter Question' label='Enter Question' required={true} name={[name, 'question']} value={question.question || ''} onChange={(e) => changeQuestionText(key, e.target.value)} />

                            <SelectField
                              {...restField}
                              col='col-lg-2 col-md-4 col-12'
                              placeholder='Select Question Type'
                              label='Question Type'
                              required={true}
                              value={question.type || ''}
                              onChange={(value) => {
                                setTypeLoader(true);
                                changeQuestionType(key, value);
                                setTimeout(() => setTypeLoader(false), 120);
                              }}
                              options={[
                                { name: 'Select', value: '' },
                                { name: 'Multiple Choices', value: 'mcqs' },
                                { name: 'Policy', value: 'text' },
                              ].map((item) => (
                                <Select.Option key={item.value} value={item.value}>
                                  {item.name}
                                </Select.Option>
                              ))}
                            />

                            <InputField
                              {...restField}
                              col='col-lg-2 col-md-2 col-12'
                              placeholder='Enter Display Position'
                              rules={[{ required: true, message: 'Please Enter a Display Position' }]}
                              label='Display Position'
                              name={[name, 'displayPosition']}
                              type='text'
                              onChange={(e) =>
                                setQuestionnaire((prev) => {
                                  const updated = [...prev];
                                  updated[key].displayPosition = Number(e.target.value);
                                  return updated;
                                })
                              }
                              value={questionnaire[key].displayPosition}
                            />

                            {question.type === 'mcqs' && !typeLoader && (
                              <div className='col-12'>
                                <div className='row'>
                                  <AnswerOptions options={options} questionIndx={key} updateOptions={updateOptions} deleteOption={deleteOption} />
                                </div>
                              </div>
                            )}

                            {question.type === 'text' && !typeLoader && (
                              <TextAreaField
                                showCount={true}
                                rows={3}
                                col='col-lg-7 col-md-7 col-7'
                                placeholder='Enter Answer'
                                label='Answer'
                                required={true}
                                value={options[0]} // Display existing value
                                onChange={(e) => updateOptions(e.target.value, 0, key)} // Update first and only option
                              />
                            )}

                            {fields.length > 1 && (
                              <Form.Item className='col-lg-2 d-flex align-items-center'>
                                <div
                                  className='add-btn'
                                  style={{ textAlign: 'center', backgroundColor: '#F21D2F' }}
                                  onClick={() => {
                                    remove(name);
                                    deleteQuestion(key);
                                  }}
                                >
                                  Remove Question
                                </div>
                              </Form.Item>
                            )}
                          </div>
                        </Space>
                      );
                    })}

                    <button onClick={() => add() || addQuestion()} type='button' className='btn btn-primary col-md-2 mx-md-2 mb-4'>
                      Add Question
                    </button>
                  </>
                )}
              </Form.List>
            </div>
          </div>,
        ]}
      />
    );
  }
};

const AnswerOptions = ({ options, questionIndx, updateOptions, deleteOption }) => {
  const [optionState, setOptionState] = useState(options);

  return (
    <>
      {optionState.map((item, indx) => {
        return <Option optionValue={item} optionState={optionState} optionIndx={indx} setOptionState={setOptionState} questionIndx={questionIndx} updateOptions={updateOptions} deleteOption={deleteOption} />;
      })}
    </>
  );
};

const Option = ({ optionState, setOptionState, optionIndx, optionValue, questionIndx, updateOptions, deleteOption }) => {
  const [input, setInput] = useState(optionValue ? optionValue : '');
  return (
    <div className='col-lg-3 col-md-6 col-12'>
      <div className='row'>
        <InputField
          value={input}
          onChange={(e) => {
            setInput(e.target.value);
            updateOptions(e.target.value, optionIndx, questionIndx);
          }}
          col='col-lg-10 col-md-11 col-12'
          placeholder='Enter Option'
          label={`Option ${optionIndx + 1}`}
          required={true}
          type='text'
          suffix={
            optionState.length > 1 && (
              <MinusCircleOutlined
                style={{
                  fontSize: '1rem',
                  color: 'red',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setOptionState(optionState.filter((item, ind) => ind !== optionIndx));
                  deleteOption(questionIndx, optionIndx);
                }}
              />
            )
          }
        />

        <div className='col-lg-2 col-md-1 p-0 d-flex align-items-center justify-content-md-start justify-content-center m-md-0 mb-3'>
          {optionState.length < 5 && optionState.length === optionIndx + 1 && (
            <PlusCircleOutlined
              style={{
                fontSize: '1rem',
                color: 'blue',
                cursor: 'pointer',
              }}
              onClick={() => {
                setOptionState([...optionState, '']);
                updateOptions(input, optionIndx, questionIndx);
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};
export default RequestButtonEdit;
