import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { Switch, Input, Form, Select, message } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import useFetchStates from '../../hooks/fetchStates';
import { SelectField } from '../../layouts/FormFields';
import { app } from '../../config';
import axios from 'axios';
import getHeaders from '../../utils/authHeaders';

const { Option } = Select;

const UpdateAddressModal = ({ showModal, handleClosed, form, selectedAddress, handleSave }) => {
  const [loading, setLoading] = useState(true);
  const [stateId, setStateId] = useState(0);
  const [error, setError] = useState(null);
   const [submitting, setSubmitting] = useState(false);

  const [states, setStates] = useState([]);

  const stateResponse = useFetchStates();

  useEffect(() => {
    if (showModal) {
      setLoading(true);

      const timer = setTimeout(() => {
        setLoading(false);

        if (stateResponse) {
          stateResponse.response ? setStates(stateResponse.response.data) : setStates([]);
        }

        setStateId(selectedAddress?.stateId);

        form.setFieldsValue({
          title: selectedAddress?.title,
          email: selectedAddress?.contactPerson,
          address: selectedAddress?.address,
          city: selectedAddress?.city,
          mobile: selectedAddress?.mobile,
          zipCode: selectedAddress?.zipCode,
          //  stateId: selectedAddress?.stateId,
          isActive: selectedAddress?.isActive === 1,
        });
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [showModal, selectedAddress, form]);

let user = JSON.parse(localStorage.getItem('user'));

const onFinish = async (values) => {
setSubmitting(true);
  try {
    // let userId = user?.result.data.user.userId;
    // values.userId = userId;
    values.stateId = stateId;
    const response = await axios.post(`${app.URL}/api/v1/admin/address/update/${selectedAddress.addressId}`, values, { headers: getHeaders() });
console.log(response)
    // Handle successful response
    if (response.status === 200) {
      message.success('Address updated successfully');
      handleClosed(); 
      setSubmitting(false);// Close the modal
    }
  } catch (err) {
    message.error('Failed to update the address');
  }
};

  return (
    <Modal show={showModal} onHide={handleClosed} centered>
      <Modal.Header closeButton className='border-0 bg-light'>
        <Modal.Title className='text-black'>Update Address</Modal.Title>
      </Modal.Header>
      <Modal.Body className='bg-light'>
        {loading ? (
          <div className='d-flex justify-content-center align-items-center' style={{ height: '200px' }}>
            <LoadingOutlined style={{ fontSize: '40px', color: '#1890ff' }} />
          </div>
        ) : (
          <Form form={form} onFinish={onFinish} layout='vertical' autoComplete='off' className='p-0' style={{ background: '#f8f9fa', borderRadius: '8px' }}>
            <div className='row'>
              <div className='col-md-6'>
                <Form.Item name='title' label='Name' rules={[{ required: false, message: 'Please enter the name' }]}>
                  <Input placeholder='Enter Name' />
                </Form.Item>
              </div>
              <div className='col-md-6'>
                <Form.Item name='email' label='Email Address' rules={[{ required: false, message: 'Please enter the email address' }]}>
                  <Input disabled={true} placeholder='Enter Email Address' />
                </Form.Item>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-6'>
                <Form.Item name='mobile' label='Phone Number' rules={[{ required: false, message: 'Please enter the phone number' }]}>
                  <Input placeholder='Enter Phone Number' />
                </Form.Item>
              </div>
              <div className='col-md-6'>
                <Form.Item name='zipCode' label='Zip Code' rules={[{ required: false, message: 'Please enter the zip code' }]}>
                  <Input placeholder='Enter Zip Code' />
                </Form.Item>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-12'>
                <Form.Item name='address' label='Address' rules={[{ required: false, message: 'Please enter the address' }]}>
                  <Input placeholder='Enter Address' />
                </Form.Item>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-6'>
                <Form.Item name='city' label='City' rules={[{ required: false, message: 'Please enter the city' }]}>
                  <Input placeholder='Enter City' />
                </Form.Item>
              </div>
              <div className='col-md-6 mb-3'>
                <div className='form-floating'>
                  <Form.Item label='State' rules={[{ required: false, message: 'Please select state' }]}>
                    {states.length > 0 && (
                      <select value={stateId} onChange={(e) => setStateId(e.target.value)} className='form-select' id='floatingSelectGrid'>
                        <option selected disabled>
                          Open to select category
                        </option>
                        {states.map((item) => (
                          <option key={item.stateId} value={item.stateId}>
                            {item.name}
                          </option>
                        ))}
                      </select>
                    )}
                  </Form.Item>
                </div>
              </div>
            </div>

            <div className='d-flex justify-content-start mt-4'>
              <Button variant='secondary' onClick={handleClosed} className='me-2'>
                Close
              </Button>
              <Button variant='primary' type='submit' >
                {submitting ? <LoadingOutlined spin /> : 'Save Changes'}
              </Button>
            </div>
          </Form>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default UpdateAddressModal;
