import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { app } from '../../config';
import CalenderImg from '../../assets/images/calender.png'
import { useNavigate } from 'react-router-dom';
import { Pagination, Input, Form, Select, Row, Col, Space, Spin, message, Collapse,DatePicker } from 'antd';
//Collapse
import { CaretRightOutlined, CaretDownOutlined, SettingOutlined } from '@ant-design/icons'
import moment from 'moment';
//Collapse End
import { useTranslation } from 'react-i18next';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { EditTwoTone, BranchesOutlined } from '@ant-design/icons';
import getHeaders from '../../utils/authHeaders';
import ListinngCard from '../../layouts/ListingCard';
import { dateConverter } from '../../utils/utcDateConversionCommon';
import AddNewImg from '../../assets/images/add-new.png'
import editBtnImg from '../../assets/images/edit.png'
import lockIcon from '../../assets/images/lock.png'
import Typography from 'antd/lib/typography/Typography';
const UserRequests = () => {
  const { RangePicker } = DatePicker;
  const [editbtn, setEditBtn] = useState('none');
  const [pageSize, setPageSize] = useState(10);
  const [fetchPartners, setFetchPartners] = useState([]);
  const [subOrderObj, setSubOrderObj] = useState({})
  const [pagesCount, setPagesCount] = useState(0);
  const [searchShow, setSearchShow] = useState({});
  const [renderList, setRenderList] = useState();
  const [tableShow, setTableShow] = useState(true);
  const [activeKey, setActiveKey] = useState(['1']);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchItems, setSearchItems] = useState([]);
  const [err, setErr] = useState('');
  const [tableHeaders, setTableHeaders] = useState([]);
  const [show, setShow] = useState(false);
  const [lockModalShow, setLockModalShow] = useState(false);
  const [delBatchResp, setDelBatchResp] = useState({});
  const [lockBatchResp, setLockBatchResp] = useState({});
  const [batchListing, setBatchListing] = useState({})
  const handleClose = () => setShow(false);
  const handleLockClose = () => setLockModalShow(false);
  let navigate = useNavigate();
  let convertedObj;

  let searchFilters = [
    {
      fieldName: 'Batch No.',
      fieldMappedBy: 'batchno',
    },
    {
      fieldName: 'Product',
      fieldMappedBy: 'serialNo',
    },
    // {
    //   fieldName: 'Partner Name',
    //   fieldMappedBy: 'partnerName',
    // },
    {
      fieldName: 'Date',
      fieldMappedBy: 'date'
    },

  ];

  // console.log(theme);
  // const { token } = theme.useToken();
  // console.log(token)
  //   const panelStyle = {
  //     marginBottom: 24,
  //     background: token.colorFillAlter,
  //     borderRadius: token.borderRadiusLG,
  //     border: 'none',
  //   };
  useEffect(() => {
    setSearchItems(searchFilters);
  }, [])

  const user = JSON.parse(localStorage.getItem('user'));
  if (user.userType === 'A') setEditBtn('block');
  const addEcomInventory = () => {
    navigate('/ecominv/add', { replace: true });
  };
  const editButton = (values) => {
    console.log(values.bannerId);
    localStorage.setItem('bannerId', values.bannerId);
    navigate('/banner/update', { replace: true });
  }

  const inventoryListingApi = async (formData) => {
    console.log(formData);
    const partnerResp = await axios.post(`${app.URL}/api/v1/admin/userrequest/view`, formData, { headers: getHeaders() });
    // console.log(partnerResp)
    partnerResp ? console.log(partnerResp.data.result.data) : "";
    partnerResp ? convertedObj = dateConverter(partnerResp.data.result.data.data, "createdAt") : " ";
    partnerResp.data.result ? setFetchPartners(convertedObj) : ' ';
    partnerResp.data.result ? setPagesCount(partnerResp.data.result.data.count) : ' ';
    partnerResp.data.result ? setRenderList(convertedObj) : ' ';
  };
  const onChange = async (page, pageSize) => {
    console.log(page, pageSize);
    setPageSize(pageSize);
    setCurrentPage(page);
    if (!page) page = 1;
    let limit = pageSize;
    try {
      let reqObj;
      if (Object.keys(searchShow).length !== 0) {
        reqObj = {
          limit: limit,
          offset: limit * (page - 1),
          searchBy: searchShow.searchFor,
          searchTxt: searchShow.searchItem,
        };
      } else {
        reqObj = {
          limit: limit,
          offset: limit * (page - 1),
          searchBy: 'orderNo.',
          searchTxt: '',
        };
      }
      console.log(reqObj);
      await inventoryListingApi(reqObj);
    } catch (e) {
      console.log('Error');
      if (e.message) setErr(e.message);
      setTableShow(false);
    }
  };


  console.log(renderList)
  useEffect(() => {
    const getData = async () => {
      let formData = {
        limit: pageSize,
        offset: 0,
        searchBy: '',
        searchTxt: '',
      };
      console.log(formData);
      const partnerResp = await axios.post(`${app.URL}/api/v1/admin/userrequest/view`, formData, { headers: getHeaders() });
      console.log(partnerResp)
      partnerResp ? console.log(partnerResp.data.result.data) : " ";
      partnerResp.data.result ? convertedObj = await dateConverter(partnerResp.data.result.data.data, "createdAt") : " ";
      partnerResp.data.result ? setFetchPartners(convertedObj) : ' ';
      partnerResp.data.result ? setPagesCount(partnerResp.data.result.data.count) : ' ';
      partnerResp.data.result ? setRenderList(convertedObj) : ' ';
      return (console.log("return called"))
    }
    getData()
  }, [])
console.log(fetchPartners);
console.log(pagesCount);
console.log(renderList);
  useEffect(() => {
    let searchFilters = [
      {
        fieldName: 'Order No.',
        fieldMappedBy: 'orderNo',
      },
      {
        fieldName: 'Customer Name',
        fieldMappedBy: 'patientName',
      },
      {
        fieldName: 'Status',
        fieldMappedBy: 'patientName',
      },
    ];
    setSearchItems(searchFilters);
  }, [])

  const { Panel } = Collapse;
  const customExpandIcon = (panelProps) => {
    const { isActive } = panelProps;

    return (
      <span>
        {isActive ? <CaretDownOutlined /> : <CaretRightOutlined />}
      </span>
    );
  }
  const onFinish = async (values) => {
    console.log(values);
    setSearchShow(values);
    try {
      let searchData = {
        limit: 10,
        offset: 0,
        searchBy: values.searchFor,
        searchTxt: values.searchItem,
      };
      if (!searchData.searchBy) message.error("Select search criteria ")
      if (searchData.searchBy) await inventoryListingApi(searchData);
    } catch (e) {
      console.log('Error');
      if (e.message) setErr(e.message);
      setTableShow(false);
    }
  };
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const onCollapseChange = async (key) => {
    console.log('Collapse change:', key);
    console.log(key);
    setSubOrderObj({})
    const orderResp = await axios.post(`${app.URL}/api/v1/admin/userrequest/view`, {}, { headers: getHeaders() });
    console.log(orderResp)
    let converArr = [];
    orderResp.data.code == 200 ? converArr.push(orderResp.data.data) : " ";
    orderResp.data.code == 200 ? convertedObj = await dateConverter(converArr, "orderDate") : " ";
    orderResp.data.code == 200 ? setSubOrderObj(convertedObj[0]) : " "
  };
  console.log(subOrderObj)
  const onViewClick = (data) => {
    console.log("onViewClick")
    console.log(data.ecomOrderId)
    localStorage.setItem("orderId", JSON.stringify(data.ecomOrderId));
    navigate('/ecomorders/view',{replace:true})
  }




  return (
    <div className='for-pseudo ml-3 col-lg-12 col-md-12 col-sm-12' style={{ backgroundColor: "#fff", borderRadius: "10px", marginBottom: "20px" }}>
      <div className='row' style={{ paddingRight: "10px" }}>
      <div className="for-before col-lg-4 head fs-3 fw-bold" style={{ backgroundColor: "#a7c1d9", borderBottomRightRadius: "10px", color: "#FFFFFF" }}>
     Customer Requests
            </div>
            {/* <div className="col-lg-5" style={{ margin: "auto", marginTop: "20px" }}>
              <RangePicker onChange={onChange} />
            </div>
            <div className="col-lg-3 d-flex align-end" style={{ margin: "auto", marginTop: "20px", position: "relative" }}>
              <img className='calender-img' src={CalenderImg} />
              <select className="form-control drop-down-select" name="dates" id="date" onChange={(e) => dateSelect(e.target.value)}>
                <option value={moment().subtract(24, "hours").format("YYYY-MM-DD")}>last 24 hours</option>
                <option value={moment().subtract(7, "d").format("YYYY-MM-DD")}>last week</option>
                <option value={moment().subtract(1, "months").format("YYYY-MM-DD")}>last month</option>
                <option value={moment().subtract(1, "year").format("YYYY-MM-DD")}>last year</option>
              </select>
            </div> */}
        {/* <div style={{ backgroundColor: "#a7c1d9", borderBottomRightRadius: "10px" }} className='for-before col-lg-4 col-md-4 col-sm-10'><p style={{ color: "#FFFFFF", paddingBottom: "10px" }} className=" fs-3 fw-bold">Ecommerce Order Management</p></div>
        <div className='col-lg-8'>
          <div className='d-flex justify-content-end'>
            <div className='col-lg-6'>

            </div>


          </div>
        </div> */}
      </div>
      <div style={{ paddingBottom: "50px", paddingLeft: "50px" }}>
        <div class='container m-0 pl-0' style={{ maxWidth: '100%' }}>
          <div className='row' style={{ marginTop: "40px" }}>
            <div className='col-11 d-flex flex-row-reverse'>
              <div class='row'>
                <Form
                  name='basic'
                  labelCol={{
                    span: 8,
                  }}
                  wrapperCol={{
                    span: 8,
                  }}
                  style={{ alignItems: 'flexEnd' }}
                  initialValues={{
                    remember: true,
                  }}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  autoComplete='off'
                >
                  <Row gutter={[16, 16]}>
                    <Col style={{ paddingLeft: '0px', paddingRight: '0px' }} span={6}>
                      <Form.Item
                        colon={false}
                        name='searchFor'
                        style={{
                          width: 700,
                          paddingLeft: '10px'
                        }}
                      >
                        <Select placeholder='Search By'>
                          {searchItems.map((item) => <Select.Option value={item.fieldMappedBy}>{item.fieldName}</Select.Option>
                          )}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col style={{ paddingLeft: '0px', paddingRight: '0px' }} span={6}>
                      <Form.Item
                        name='searchItem'
                        style={{
                          width: 700,
                          paddingLeft: '10px'
                        }}
                      >
                        <Input placeholder='Enter value for search' />
                      </Form.Item>
                    </Col>

                    <Col style={{ paddingLeft: '0px', paddingRight: '0px' }} span={3}>
                      <Form.Item
                        colon={false}
                        wrapperCol={{
                          offset: 8,
                          span: 16,
                        }}
                      >
                        <button type='primary' class='btn btn-primary button' htmlType='submit'>
                          Search
                        </button>
                      </Form.Item>
                    </Col>
                    <Col style={{ paddingLeft: '0px', paddingRight: '0px' }} span={3}>
                    </Col>
                  </Row>
                </Form>
              </div>
            </div>
          </div>

        </div>
        <div className='col-lg-11 col-md-12 col-sm-12 '>
                        <div>
                        <table id="customers">
              <thead class=''>
                        <tr>
                        <th>Request Type</th>
                        <th>Order No.</th>
                        <th>Sub Order No.</th>
                        <th>Customer Name</th>
                        <th>Request Date</th>
                        <th>Request Status</th>
                        <th></th>
                        </tr>
              </thead>
              <tbody>
                {renderList ? renderList.map((tableValues) => (
                    <tr >
                      <td  style={{ width: '200px',textTransform:"capitalize" }}>{tableValues.requestType ? tableValues.requestType : 'null'}</td>
                      <td style={{ width: '200px' }}>{tableValues.ecomOrderNo ? tableValues.ecomOrderNo : 'null'}</td>
                      <td style={{ width: '150px' }}>{tableValues.ecomOrderNo ? tableValues.ecomOrderNo+"-"+tableValues.addressId : 'null'}</td>
                      <td style={{ width: '150px', textAlign: "center" }}>{tableValues.patientName ? tableValues.patientName : 'null'}</td>
                      <td style={{ width: '150px' }}>{tableValues.createdAt ? tableValues.createdAt : 'null'}</td>
                      <td style={{ width: '150px',textTransform:"capitalize"  }}>{tableValues.requestStatus ? tableValues.requestStatus : 'null'}</td>
                      <td style={{ width: '100px',textTransform:"capitalize"  }}><button className="button btn btn-primary" onClick={()=>{onViewClick(tableValues)}} style={{ width: "70px", height: "34px", fontSize: "small" }} size="medium">
                  View
                </button></td>
                    </tr>
                  ))
                  : ' '}
              </tbody>
            </table>
                        </div>
        </div>
        {currentPage && pagesCount ? <Pagination style={{ marginTop: '10px' }} onChange={onChange} defaultCurrent={currentPage} total={pagesCount} showTotal={(total) => `Total ${total} items`} showSizeChanger /> : " "}
      </div>
    </div>
  )
}

export default UserRequests