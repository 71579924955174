import React, { useEffect, useState } from 'react';
import axios from 'axios';
// import { app } from '../../config';
// import getHeaders from '../../utils/authHeaders';
import { Pagination, Input, Form, Select, Row, Col, DatePicker, Button, message } from 'antd';
import ListinngCard from '../../../layouts/ListingCard';
import { app } from '../../../config';
import getHeaders from '../../../utils/authHeaders';
import { useNavigate } from 'react-router-dom';
const BlogListing = () => {
     const [tableHeaders, setTableHeaders] = useState([]);
     const [loader, setLoader] = useState(false);
     const [renderList, setrenderList] = useState(false);
     const [searchShow, setSearchShow] = useState({});
  let navigate = useNavigate();

 const BlogListingApi = async () => {
   setLoader(true);

   let productResp = await axios.get(`${app.URL}/api/v1/admin/blogs/view`, { headers: getHeaders() });
   console.log('xcdd', productResp?.data?.result?.data);
   productResp?.data?.result?.data ? setrenderList(productResp.data.result.data) : '';
   productResp?.data?.result?.data && setLoader(false);
 };

 useEffect(() => {
   // const user = JSON.parse(localStorage.getItem('user'));
   let header = ['Title', 'Description', 'IsActive ','Action'];
   setTableHeaders(header);

   BlogListingApi();
 }, []);
console.log(renderList)


const addBlog = () => {
  navigate('/blogs/create', { replace: true });
};

 const editBlog = (e) => {
  console.log(e.blogId);
   localStorage.setItem('blogId', e.blogId);
   navigate('/blogs/edit', { replace: true });
 };
  return (
    <div>
      <ListinngCard
        heading='Blog Management'
        buttonName='Add Blogs'
        addButton={addBlog}
        // onSearch={onFinish}
        // onSearchFailed={onFinishFailed}
        // searchItems={searchItems}

        tableHeaders={tableHeaders}
        tableRows={renderList}
        editButton={editBlog}
        // editButton={editProduct}   --------    conditional rendering ask?

        // will use

        // onPageChange={onChange}
        // currentPage={currentPage}
        // pagesCount={pagesCount}
        anuraListing={false}
        inventoryBatchListing={false}
        patientsListing={false}
        inventoryListing={false}
        userListings={false}
        storeListing={false}
        testResultListing={false}
        stockBalanceListing={false}
        productListing={false}
        BlogsListing={true}
        // searchValue={searchData}

        // onChange={handleOnChange}
        loader={loader}
      />
    </div>
  );
}

export default BlogListing
