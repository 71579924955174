import React, { useState } from 'react';
import axios from 'axios';
import { app } from '../../config';
import { MinusCircleOutlined, PlusOutlined, PrinterOutlined, LoadingOutlined } from '@ant-design/icons';
import getHeaders from '../../utils/authHeaders';
import { Button as AButton, Form, Input, Space, Select, Row, Col, message, Tag } from 'antd';
import useFetchProducts from '../../hooks/fetchProducts';
import useFetchPartners from '../../hooks/fetchPartner';
import { CSVLink } from 'react-csv';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
// import AddNewImg from '../../assets/images/add-new.png';
import Excel from '../../assets/images/excel.png';
import Delete from '../../assets/images/delete.png';
import { extractObjValues } from '../../utils/extractObjValues';
import { dateConverter } from '../../utils/utcDateConversionCommon';
import Barcode from 'react-barcode';
import { useReactToPrint } from 'react-to-print';
import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import QRCodeLogo from '../../lib/QRCodeLogo';
import { TweenOneGroup } from 'rc-tween-one';
import { InputField } from '../../layouts/FormFields';
import { sortAddInventoryArray } from './inventoryHelpers';

const AddInventory = () => {
  let navigate = useNavigate();
  // const [listingIndx, setListingIndx] = useState(0);
  // const [invInputs, setInvInputs] = useState({
  //   productId: '',
  //   partnerId: '',
  //   availQty: '',
  //   serialType: '',
  //   issueDate: '',
  //   isActive: '',
  // });
  const [invListData, setInvListData] = useState([]);
  const [addInvResp, setAddInvResp] = useState({});
  const [apiResp, setAPIResp] = useState(false);
  const [delBatchRes, setDelBatchRes] = useState({});
  const [show, setShow] = useState(false);
  const [showDelModal, setShowDelModal] = useState(false);
  const [uploadArr, setUploadArr] = useState([]);
  const [boxSerialType, setBoxSerialType] = useState('');
  const [loading, setLoading] = useState(false);
  const [noinvList, setNoinvList] = useState(false);
  const [products, setProducts] = useState([]);

  const handleClose = () => setShow(false);
  const handleDelModalClose = () => setShowDelModal(false);
  let prrds;

  const fetchedProducts = useFetchProducts(setProducts);
  prrds = fetchedProducts.response ? fetchedProducts.response.data : [];
  const fetchedPartners = useFetchPartners();
  const partners = fetchedPartners.response ? fetchedPartners.response.data : [];
  console.log(partners);
  let user = JSON.parse(localStorage.getItem('user'));

  const uploadData = async (userDetails) => {
    setLoading(true);
    let userId = user?.result.data.user.userId;
    console.log("userId",userId)
    userDetails[0].userId = userId;
    console.log(userDetails);

    try {
      const res = await axios.post(`${app.URL}/api/v1/admin/inventory/create`, userDetails, { headers: getHeaders() });
      console.log(res);
      setLoading(false);
      setShow(false);

      if (res.data.result.success) {
        setAPIResp(true);

        console.log('dataaaaaaaaaaaaaaa');
        console.log(res.data);
        console.log(res.data.result.data);
        setAddInvResp(res.data.result.data);
        let convertedData = dateConverter(res.data.result.data, 'issueDate');
        console.log('convertedData');
        console.log(convertedData);
        setInvListData(convertedData);
        message.success(res.data.result.msg);
      } else if (!res.data.result.success) {
        message.warning(res.data.result.msg);
      }
    } catch (err) {
      setShow(false);
      setLoading(false);
      message.warning(err.response.data.message);
      if (err) console.log(err.response.data.message);
      else return;
    }
  };
  console.log(invListData);
  const submitData = () => {
    console.log('calling');
    if (Object.keys(uploadArr).length > 0) {
      uploadData(uploadArr);
    }
  };
  const onFinish = (values) => {
    console.log('Received values of form:', values.invList);
    const { invList } = values;
    const csvData = [
  ["firstname", "lastname", "email"],
  ["Ahmed", "Tomi", "ah@smthing.co.com"],
  ["Raed", "Labes", "rl@smthing.co.com"],
  ["Yezzi", "Min l3b", "ymin@cocococo.com"]
];
    let tempArr = [];
    if (boxSerialType && invList.length > 0) {
      for (let i = 0; i < invList.length; i++) {
        console.log(invList[i]);
        tempArr.push({
          productId: invList[i].product ? invList[i].product : null,
          // partnerId: data[i].partnerId ? data[i].partnerId : null,
          partnerId: 1,
          availQty: invList[i].availableQty ? invList[i].availableQty : null,
          serialType: boxSerialType,
          issueDate: new Date(),
          isActive: 1,
        });
      }
      console.log('upload temp arr: ', tempArr);
      setUploadArr(tempArr);
      setShow(true);
      setNoinvList(false);
    } else {
      setNoinvList(true);
    }
  };
  let newArray = [];
  // newArray = sortAddInventoryArray(invListData);
  const refinedArray = extractObjValues(invListData, ['batchId', 'name', 'productSerials', 'issueDate']);
  console.log(refinedArray);
  refinedArray.forEach((ele) => {
    newArray.push({ [`Batch ID`]: ele.batchId, [`Product`]: ele.name, [`Inventory Date`]: ele.issueDate, [`Serial No.`]: ele.productSerials.map((item) => `${item.serialNo} `) });
  });
  console.log(newArray);
  const deleteBatch = async (batchId) => {
    console.log(batchId);
    const res = await axios.delete(`${app.URL}/api/v1/admin/inventory/delete/${batchId}`, { headers: getHeaders() });
    console.log(res);
    if (res && res.data.result.success) {
      setDelBatchRes(res.data.result.data);
      setShowDelModal(false);
      setInvListData([]);
      setAPIResp(false);
    }
  };
  function goBack() {
    navigate('/inventory/listing', { replace: true });
  }
  window.addEventListener('popstate', goBack);

  let printRef = useRef();

  const pageStyle = `
      @page {
        size: 1in 1in; /* Specify your custom page size here (width x height) */
           marginTop: 4px;
           margin: 0;
       }
      //   @page {
      //   size: 8.5in 11.5in; /* Specify your custom page size here (width x height) */
      //      marginTop: 4px;
      //      margin: 0;
      //  }
       body {
         font-family: Arial, sans-serif;
         margin: 0;
         marginTop:4px !important;
       }
       `;

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    pageStyle: pageStyle,
    onAfterPrint: goBack,
  });

  const [displayQRCode, setDisplayQRCode] = useState([]);
  const [printLoader, setPrintLoader] = useState(false);

  const generateBarCode = (e) => {
    setPrintLoader(true);
    e.stopPropagation();

    let qrCodes = [];
    
    for (let i = 0; i < invListData.length; i++) {
      for (let j = 0; j < invListData[i].productSerials.length; j++) {
        let flag = invListData[i].productSerials[j].serialNo.split('-')[2][0];
        console.log('flag', flag);
        qrCodes.push(<QRCodeLogo value={invListData[i].productSerials[j].serialNo} flag={flag} />);
      }
    }

    // let qrCodes = [];
    // for (let i = 0; i < invListData.length; i++) {
    //   // console.log(invListData[i].serialNo);
    //   let flag = invListData[i].serialNo.split('-')[2][0];
    //   // message.warning(flag);
    //   qrCodes.push(<QRCodeLogo value={invListData[i].serialNo} flag={flag} />);
    // }

    setDisplayQRCode(qrCodes);

    setTimeout(() => {
      setPrintLoader(false);
      handlePrint();
    }, 1000);
  };

  // const addListingInputFields = () => {
  //   setInvListData([
  //     ...invListData,
  //     {
  //       productId: '',
  //       // partnerId: data[i].partnerId ? data[i].partnerId : null,
  //       partnerId: 1,
  //       availQty: '',
  //       serialType: '',
  //       issueDate: new Date(),
  //       isActive: 1,
  //     },
  //   ]);
  // };

  console.log(apiResp, invListData);
  function copyToClipboard(value) {
    console.log(navigator);

    // Copy the text inside the text field
    navigator.clipboard.writeText(value);

    // Provide feedback to the user (you can customize this part)
    message.success(`Serial No has been copied to the clipboard: ${value}`);
  }
  const forMap = (tag) => {
    const tagElem = (
      <Tag
        style={{
          borderRadius: '5px',
          // backgroundColor: '#f2b544',
          boxShadow: '1px 1px 1px #ccc',
          cursor: 'pointer',
        }}
        id='serial'
        // value={tag}
        aria-disabled={true}
        onClick={() => copyToClipboard(tag)}
        className={`border-none d-flex align-items-center text-dark bg-light fs-6 p-2 mb-2`}
      >
        {tag}
      </Tag>
    );

    return (
      <span
        key={tag}
        style={{
          display: 'inline-block',
        }}
      >
        {tagElem}
      </span>
    );
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo.values.invList);

    // Extract boxType values from the invList array
    const boxTypesToRemove = errorInfo?.values?.invList?.filter((item) => item.product !== undefined).map((item) => item.product);

    // Filter the main bocType array
    setProducts(prrds.filter((item) => !boxTypesToRemove.includes(item.productId)));
  };
  // const tagChild = invListData?.map((item) => forMap(item.serialNo));
  return (
    <div className='dashboard-card container-fluid'>
      <div className='row'>
        <div className='for-before col-12 col-md-7 head fs-3 fw-bold' style={{ backgroundColor: '#a7c1d9', borderBottomRightRadius: '10px', color: '#FFFFFF' }}>
          {/* Product Inventory */}
          Product Inventory - Generate QR Code
        </div>
        <div style={{ display: 'none' }}>
          <div ref={printRef}>{displayQRCode.map((item) => item)}</div>
        </div>
      </div>

      <Form name='inventoryList' onFinishFailed={onFinishFailed} onFinish={onFinish} className='row p-5 px-3 pb-1' autoComplete='off' initialValues={{ invList: [''] }}>
        <div className='col-md-9'>
          <div className='row'>
            <div className='row'>
              <Form.Item
                className='col-lg-3'
                name='serialType'
                rules={[
                  {
                    required: true,
                    message: 'Select Box Serial Type',
                  },
                ]}
              >
                <Select value={boxSerialType} onChange={(e) => setBoxSerialType(e)} placeholder='Select Box Serial Type'>
                  <Select.Option value='G'>Generic</Select.Option>
                  <Select.Option value='R'>Retail</Select.Option>
                </Select>
              </Form.Item>
            </div>

            <Form.List name='invList'>
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <Space key={fields.key} className='col-md-12 m-0 ' align='baseline' gap='13'>
                      <div className='row w-100'>
                        <Form.Item
                          {...restField}
                          name={[name, 'product']}
                          className='col-5 col-md-3'
                          rules={[
                            {
                              required: true,
                              message: 'Select a Product',
                            },
                          ]}
                        >
                          <Select placeholder='Select Product'>
                            {products.map((item) => (
                              <Select.Option value={item.productId}>{item.name}</Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                        {/* <Form.Item
                          className='col-5 col-md-3'
                          {...restField}
                          name={[name, 'partnerId']}
                          rules={[
                            {
                              required: false,
                              message: 'Select a partner',
                            },
                          ]}
                        >
                          <Select disabled={true} placeholder='Select Partner'>
                            {partners.map((item) => (
                              <Select.Option value={item.partnerId}>{item.partnerName}</Select.Option>
                            ))}
                          </Select>
                        </Form.Item> */}

                        <Form.Item
                          className='col-5 col-md-3'
                          {...restField}
                          name={[name, 'availableQty']}
                          rules={[
                            {
                              required: true,
                              message: 'Enter Quantity',
                            },
                          ]}
                        >
                          <Input placeholder='Quantity' type='number' min='1' />
                        </Form.Item>
                        <div
                          className='col-5 col-md-3'
                          style={{
                            fontSize: '1rem',
                            color: 'red',
                            paddingTop: '0.5rem',
                          }}
                        >
                          <MinusCircleOutlined
                            onClick={() => {
                              if (fields.length > 1) {
                                remove(name);
                              }
                            }}
                          />
                        </div>
                      </div>
                    </Space>
                  ))}

                  {!apiResp && (
                    <button
                      disabled={apiResp}
                      onClick={() => {
                        add();
                        setNoinvList(false);
                      }}
                      type='primary'
                      className={`btn btn-primary  button col-md-3 mx-md-2 ${noinvList ? 'mb-1' : 'mb-4'}`}
                    >
                      Add More
                    </button>
                  )}
                  <span className={`${noinvList ? 'd-block' : 'd-none'} mb-4 text-danger`}>Please add atleast one record!</span>
                </>
              )}
            </Form.List>

            {/* <div className='col-12 mb-4'> */}
            <div className='col-12 mb-4'>
              <div className='row'>
                <button disabled={apiResp} onClick={goBack} type='primary' className='btn btn-primary button patient-cancel-btn col-md-3 mx-md-2 mr-2 mb-2'>
                  Cancel
                </button>

                <button disabled={apiResp} type='primary' class='btn btn-primary button col-md-3 mb-2 mr-2' htmlType='submit'>
                  Generate
                </button>

                {apiResp && (
                  <span onClick={generateBarCode} disabled={printLoader} type='primary' class='btn btn-primary button col-md-3 mb-2 mr-2' htmlType='submit'>
                    {printLoader ? <LoadingOutlined className='mr-2' /> : <PrinterOutlined className='mr-2' />}
                    Print QR Codes
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>

        {addInvResp.code === 200 ? (
          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title className='text-center'>Success</Modal.Title>
            </Modal.Header>
            <Modal.Body>{addInvResp.message}</Modal.Body>
            <Modal.Footer></Modal.Footer>
          </Modal>
        ) : addInvResp.code === 400 ? (
          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title className='text-center'>Failed</Modal.Title>
            </Modal.Header>
            <Modal.Body>{addInvResp.data.message}</Modal.Body>
            <Modal.Footer></Modal.Footer>
          </Modal>
        ) : (
          <Modal show={show} onHide={handleClose}>
            {/* <Modal.Header closeButton>
              <Modal.Title>Confirmation</Modal.Title>
            </Modal.Header> */}
            <Modal.Body>Are you sure, you want to generate inventory?</Modal.Body>
            <Modal.Footer>
              <Button variant='secondary' onClick={handleClose}>
                Cancel
              </Button>
              <Button disabled={loading} variant='primary' htmlType='submit' onClick={submitData}>
                {loading ? <LoadingOutlined /> : 'Confirm'}
              </Button>
            </Modal.Footer>
          </Modal>
        )}

        {/* <button style={{ width: '70%', marginTop: '30px' }} type='primary' class='btn btn-primary button' htmlType='submit'>
                                        Save & Generate Serial No.
                                    </button> */}

        {apiResp ? (
          <>
            <div className='row mb-3'>
              <div className='col-lg-1 ml-6'>
                {/* <Button class='btn btn-primary button ml-4 mr-2'> */}
                <CSVLink filename={'inventoryRecord.csv'} data={newArray}>
                  <img src={Excel} style={{ width: '30px', height: '30px' }} />
                  <img />
                </CSVLink>
                {/* </Button> */}

                {/* <Button class='btn btn-primary button float-right' onClick={()=>{deleteBatch(refinedArray[0].batchId)}}>Delete Batch</Button> */}
              </div>
              <div className='col-lg-1'>
                {/* <button>  */}

                {/* </button> */}

                {/* Delete Modal Start */}
                {delBatchRes.code === 200 ? (
                  <Modal show={showDelModal} onHide={handleDelModalClose}>
                    <Modal.Header closeButton>
                      <Modal.Title className='text-center'>Success</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{delBatchRes.message}</Modal.Body>
                    <Modal.Footer></Modal.Footer>
                  </Modal>
                ) : addInvResp.code === 400 ? (
                  <Modal show={showDelModal} onHide={handleDelModalClose}>
                    <Modal.Header closeButton>
                      <Modal.Title className='text-center'>Failed</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{delBatchRes.data.message}</Modal.Body>
                    <Modal.Footer></Modal.Footer>
                  </Modal>
                ) : (
                  <Modal show={showDelModal} onHide={handleDelModalClose}>
                    {/* <Modal.Header closeButton>
                      <Modal.Title>Confirmation</Modal.Title>
                    </Modal.Header> */}
                    <Modal.Body>Are you sure, you want to Delete Batch?</Modal.Body>
                    <Modal.Footer>
                      <Button variant='secondary' onClick={handleDelModalClose}>
                        Cancel
                      </Button>
                      <Button
                        variant='primary'
                        htmlType='submit'
                        onClick={() => {
                          deleteBatch(refinedArray[0].batchId);
                        }}
                      >
                        Confirm
                      </Button>
                    </Modal.Footer>
                  </Modal>
                )}
                {/* Delete Modal End */}
                <img
                  src={Delete}
                  style={{ width: '25px', height: '25px' }}
                  onClick={() => {
                    setShowDelModal(true);
                  }}
                />
              </div>
              {/* <div className='col-lg-1'>
                {printLoader ? (
                  <LoadingOutlined
                    style={{
                      fontSize: '1.5rem',
                    }}
                  />
                ) : (
                  <PrinterOutlined
                    style={{
                      fontSize: '2rem',
                    }}
                    onClick={generateBarCode}
                  />
                )}
              </div> */}
            </div>

            <div
              // className='row table-scrollbar mr-0'
              // style={{
              //   overflowX: 'auto',
              // }}
              className='row mr-0 px-2'
            >
              {invListData.map((item) => (
                <Form
                  initialValues={{
                    remember: true,
                    ['batchNo']: item?.batchId,
                    ['issueDate']: item?.issueDate,
                    ['name']: item?.name,
                    ['quantity']: item?.productSerials?.length,
                  }}
                  autoComplete='off'
                  className='row p-2 px-3 pb-1 mb-3'
                >
                  <InputField col='col-md-2' label='Batch No.' name='batchNo' disabled={true} />
                  <InputField col='col-md-2' label='Inventory Date' name='issueDate' disabled={true} />
                  <InputField col='col-md-2' label='Product' name='name' disabled={true} />
                  <InputField col='col-md-2' label='Quantity' name='quantity' disabled={true} />
                  {/* <InputField col='col-md-3' label='Partner Name' name='partnerName' disabled={true} /> */}
                  <div className='col-lg-12'>
                    <label>Serial No.</label>
                    <TweenOneGroup
                      enter={{
                        scale: 0.8,
                        opacity: 0,
                        type: 'from',
                        duration: 100,
                      }}
                      onEnd={(e) => {
                        if (e.type === 'appear' || e.type === 'enter') {
                          e.target.style = 'display: inline-block';
                        }
                      }}
                      leave={{
                        opacity: 0,
                        width: 0,
                        scale: 0,
                        duration: 200,
                      }}
                      appear={false}
                    >
                      {/* {tagChild} */}
                      {item?.productSerials?.map((item) => forMap(item.serialNo, item.isUsed))}
                    </TweenOneGroup>
                  </div>
                </Form>
              ))}
            </div>
          </>
        ) : (
          ' '
        )}
      </Form>
    </div>
  );
};

export default AddInventory;
