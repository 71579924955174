import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { app } from '../../config';
import CalenderImg from '../../assets/images/calender.png';
import { redirect, useNavigate } from 'react-router-dom';
import { Pagination, Input, Form, Select, Row, Col, Space, Spin, message, Collapse, DatePicker } from 'antd';
//Collapse
import { CaretRightOutlined, CaretDownOutlined, SettingOutlined, FilterFilled, CaretUpOutlined } from '@ant-design/icons';
import moment from 'moment';
//Collapse End
// import { useTranslation } from 'react-i18next';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
// import { EditTwoTone, BranchesOutlined } from '@ant-design/icons';
import getHeaders from '../../utils/authHeaders';
// import ListinngCard from '../../layouts/ListingCard';
import { dateConverter } from '../../utils/utcDateConversionCommon';

import EcommerceDashboardCard from '../../layouts/EcommerceDashboardCard';
import NoDataFound from '../../layouts/NoDataFound';
import MainOrderViewModal from '../Ecom Orders/MainOrderViewModal';
import SubOrderViewModal from '../Ecom Orders/SubOrderViewModal';
// import DashboardMainOrderModal from '../Ecom Orders/DashboardMainOrderModal';
import { SearchBar } from '../../layouts/SearchBar';
import LoaderImage from '../LoaderImage';
// import FilterSorting from '../../utils/FilterSorting';
const { TextArea } = Input;

const PrepareForShip = () => {
  const { RangePicker } = DatePicker;
  const [activeIndex, setActiveIndex] = useState(1);
  const [cancelShow, setCancelShow] = useState(false);
  const [sortValue, setSortValue] = useState('asc');
  const [editbtn, setEditBtn] = useState('none');
  const [pageSize, setPageSize] = useState(10);
  const [fetchPartners, setFetchPartners] = useState([]);
  const [subOrderObj, setSubOrderObj] = useState({});
  const [pagesCount, setPagesCount] = useState(0);
  const [searchShow, setSearchShow] = useState({});
  const [sortShow, setSortShow] = useState({});
  const [renderList, setRenderList] = useState([]);
  const [tableShow, setTableShow] = useState(true);
  const [activeKey, setActiveKey] = useState(['1']);
  const [currentPage, setCurrentPage] = useState(1);
  const [err, setErr] = useState('');
  const [tableHeaders, setTableHeaders] = useState([]);
  const [show, setShow] = useState(false);
  const [status, setStatus] = useState([]);
  const [cancelData, setCancelData] = useState({});
  const [orderCancelResp, setOrderCancelResp] = useState();
  const [cancelStatus, setCancelStatus] = useState(false);

  const [reciveBackData, setReciveBackData] = useState({});
  const [reciveBackResp, setReciveBackResp] = useState();
  const [reciveBackStatus, setReciveBackStatus] = useState(false);
  const [reciveBackShow, setReciveBackShow] = useState(false);
  const [isRecivedBack, setIsReciveBack] = useState(false);

  const [resendOrderData, setResendOrderData] = useState({});
  const [resendOrderResp, setResendOrderResp] = useState();
  const [resendOrderStatus, setResendOrderStatus] = useState(false);
  const [resendOrderShow, setResendOrderShow] = useState(false);
  const [loader, setLoader] = useState(false);

  const [missingItem, setMissingItem] = useState(false);

  const [stockOutResp, setStockOutResp] = useState([]);
  const [dispatchData, setDispatchData] = useState([]);
  const [dispatchShow, setDispatchShow] = useState(false);
  const [refreshPage, setRefreshPage] = useState(false);
  const [singleOrderOBj, setSingleOrderObj] = useState({});
  const [showSingleOrder, setShowSingleOrder] = useState(false);

  const [singleMainOrderOBj, setSingleMainOrderObj] = useState({});
  const [showSingleMainOrder, setShowSingleMainOrder] = useState(false);
  const [isModalLoading, setIsModalLoading] = useState(false);

  const [ws, setWs] = useState('');

  const user = JSON.parse(localStorage.getItem('user'));

  console.log(singleOrderOBj);
  

  // const handleClose = () => setShow(false);
  // const handleLockClose = () => setLockModalShow(false);

  let navigate = useNavigate();
  let convertedObj;

  let shipmentHeader = ['Sub Order No.', 'Shipment Name', 'Address', 'Shipment Package', 'Customer Request', 'Action', ''];
  // let shipmentHeader = ['Ecom Order No', 'Title', 'Address', 'Shipme Package Name', 'Request Type', 'Action', ''];

  // const user = JSON.parse(localStorage.getItem('user'));
  if (user.userType === 'A') setEditBtn('block');
  console.log(user);
  const userRequestListingAPI = async (formData) => {
    console.log(formData);
    const partnerResp = await axios.post(`${app.URL}/api/v1/admin/userrequest/view`, formData, { headers: getHeaders() });
    // console.log(partnerResp)
    partnerResp ? console.log(partnerResp.data.result.data) : '';
    partnerResp ? (convertedObj = dateConverter(partnerResp.data.result.data.data, 'createdAt')) : ' ';
    partnerResp.data.result ? setFetchPartners(convertedObj) : ' ';
    partnerResp.data.result ? setPagesCount(partnerResp.data.result.data.count) : ' ';
    partnerResp.data.result ? setRenderList(convertedObj) : ' ';
  };
  const inventoryListingApi = async (formData) => {
    console.log(formData);

    const partnerResp = await axios.post(`${app.URL}/api/v1/admin/ecomorders/warehouseorders/1`, formData, { headers: getHeaders() });
    // console.log(partnerResp)
    partnerResp ? console.log(partnerResp.data.result.data) : '';
    partnerResp ? (convertedObj = dateConverter(partnerResp.data.result.data.data, 'createdAt')) : ' ';
    partnerResp.data.result ? setFetchPartners(convertedObj) : ' ';
    partnerResp.data.result ? setPagesCount(partnerResp.data.result.data.count) : ' ';
    partnerResp.data.result ? setRenderList(convertedObj) : ' ';
    partnerResp.data.result && setLoader(false);
  };
  const getData = async () => {
    let formData = {
      limit: pageSize,
      offset: 0,
      searchBy: '',
      queryTxt: '',
    };
    console.log(formData);
    const partnerResp = await axios.post(`${app.URL}/api/v1/admin/userrequest/view`, formData, { headers: getHeaders() });
    console.log(partnerResp);
    partnerResp ? console.log(partnerResp.data.result.data) : ' ';
    partnerResp.data.result ? (convertedObj = await dateConverter(partnerResp.data.result.data.data, 'createdAt')) : ' ';
    partnerResp.data.result ? setFetchPartners(convertedObj) : ' ';
    partnerResp.data.result ? setPagesCount(partnerResp.data.result.data.count) : ' ';
    partnerResp.data.result ? setRenderList(convertedObj) : ' ';
    return console.log('return called');
  };
  const onRequestChange = async (page, pageSize) => {
    console.log(page, pageSize);
    setPageSize(pageSize);
    setCurrentPage(page);
    if (!page) page = 1;
    let limit = pageSize;
    try {
      let reqObj;
      if (Object.keys(searchShow).length !== 0) {
        reqObj = {
          limit: limit,
          offset: limit * (page - 1),
          searchBy: searchShow.searchBy,
          queryTxt: searchShow.queryTxt,
        };
      } else {
        reqObj = {
          limit: limit,
          offset: limit * (page - 1),
          searchBy: '',
          queryTxt: '',
        };
      }
      console.log(reqObj);
      await userRequestListingAPI(reqObj);
    } catch (e) {
      console.log('Error');
      if (e.message) setErr(e.message);
      setTableShow(false);
    }
  };
  const onChange = async (page, pageSize) => {
    console.log(page, pageSize);
    setPageSize(pageSize);
    setCurrentPage(page);
    if (!page) page = 1;
    let limit = pageSize;
    try {
      // let reqObj;
      // if (Object.keys(searchShow).length !== 0) {
      //   reqObj = {
      //     limit: limit,
      //     offset: limit * (page - 1),
      //     searchBy: searchShow.searchBy,
      //     queryTxt: searchShow.searchTxt,
      //     sortBy: sortShow?.sortBy,
      //     sortMethod: sortShow?.sortMethod,
      //   };
      // } else {
      let reqObj = {
        limit: limit,
        offset: limit * (page - 1),
        searchBy: searchShow.searchBy ? searchShow.searchBy : 'orderNo',
        queryTxt: searchShow.searchTxt ? searchShow.searchTxt : '',
        sortBy: sortShow.sortBy ? sortShow.sortBy : 'ecomOrderNo',
        sortMethod: sortShow.sortMethod ? sortShow.sortMethod : 'asc',
      };
      // }
      console.log(reqObj);
      setLoader(true);
      await inventoryListingApi(reqObj);
    } catch (e) {
      console.log('Error');
      if (e.message) setErr(e.message);
      setTableShow(false);
    }
  };

  //Approve and Refund Workflow
  const handleCancelModalClose = () => setCancelShow(false);
  const cancelShipment = async (data) => {
    try {
      let resp = await axios.post(`${app.URL}/api/v1/admin/userrequest/cancelshipment`, data, { headers: getHeaders() });
      console.log('resp');
      setOrderCancelResp(resp.data.result);
      setCancelStatus(true);
      setTimeout(() => {
        setCancelShow(false);
      }, [3000]);
      await getData();
    } catch (e) {
      throw e;
    }
  };
  const onCancelTrigger = (data) => {
    setOrderCancelResp({});
    console.log(data);
    let cancelObj = {
      requestId: data.requestId,
      patientId: data.patientId,
      ecomOrderId: data.ecomOrderId,
      addressId: data.addressId,
    };

    console.log(cancelObj);
    setCancelShow(true);
    setCancelData(cancelObj);
  };
  const onCancelClick = async () => {
    console.log(cancelData);
    await cancelShipment(cancelData);
  };
  // Recive back order work flow

  const handleReciveBackModalClose = () => setReciveBackShow(false);
  const reciveorder = async (data) => {
    try {
      let resp = await axios.post(`${app.URL}/api/v1/admin/ecomorders/receiveback`, data, { headers: getHeaders() });
      console.log('resp');
      setReciveBackResp(resp.data.result);
      setReciveBackStatus(true);
      setTimeout(() => {
        setReciveBackShow(false);
      }, [3000]);
      await getData();
    } catch (e) {
      throw e;
    }
  };
  const onReciveBackTrigger = (data) => {
    setReciveBackResp({});
    console.log(data);
    let recivebackObj = {
      requestId: data.requestId,
      patientId: data.patientId,
      ecomOrderId: data.ecomOrderId,
      addressId: data.addressId,
    };

    console.log(recivebackObj);
    setReciveBackShow(true);
    setReciveBackData(recivebackObj);
  };
  const onReciveBackClick = async () => {
    console.log(reciveBackData);
    await reciveorder(reciveBackData);
  };

  //Recive back order workflow end

  //Resend Order Workflow

  const handleResendOrderModal = () => setResendOrderShow(false);
  const resendOrder = async (data) => {
    try {
      let resp = await axios.post(`${app.URL}/api/v1/admin/ecomorders/resendorder`, data, { headers: getHeaders() });
      console.log('resp');
      setResendOrderResp(resp.data.result);
      setResendOrderStatus(true);
      setTimeout(() => {
        setResendOrderShow(false);
      }, [3000]);
      await getData();
    } catch (e) {
      throw e;
    }
  };
  const onResendOrderTrigger = (data) => {
    setResendOrderResp({});
    console.log(data);
    if (data.requestType == 'Missing Item' || data.requestType == 'missing item') {
      setMissingItem(true);
    }
    let resendOrderObj = {
      requestId: data.requestId,
      patientId: data.patientId,
      ecomOrderId: data.ecomOrderId,
      addressId: data.addressId,
    };

    console.log(resendOrderObj);
    setResendOrderShow(true);
    setResendOrderData(resendOrderObj);
  };
  const onResendOrderonClick = async () => {
    console.log(resendOrderData);
    await resendOrder(resendOrderData);
  };

  //Resend Order Work flow End
  console.log(renderList);

  useEffect(() => {
    let formData = {
      limit: pageSize,
      offset: 0,
      searchBy: 'orderNo',
      queryTxt: '',
      sortBy: 'ecomOrderNo',
      sortMethod: 'asc',
    };
    setLoader(true);
    inventoryListingApi(formData);
  }, [refreshPage]);

  const { Panel } = Collapse;
  const customExpandIcon = (panelProps) => {
    const { isActive } = panelProps;

    return <span>{isActive ? <CaretDownOutlined /> : <CaretRightOutlined />}</span>;
  };

  console.log(subOrderObj);

  const onSearchFinish = async (values) => {
    console.log('onSearchFinish');
    console.log('Success:', values);
    setSearchShow(values);
    let formData = {
      limit: 10,
      offset: 0,
      searchBy: values.searchBy, //partnerName, serialNo, orderNo
      queryTxt: values.searchTxt,
      sortBy: sortShow.sortBy ? sortShow.sortBy : 'ecomOrderNo',
      sortMethod: sortShow.sortMethod ? sortShow.sortMethod : 'asc',
    };
    if (!values.searchTxt) message.error('Please enter search text');
    else {
      setLoader(true);
      await inventoryListingApi(formData);
    }
  };

  const onSearchFinishFailed = (values) => {
    console.log('Failed:', values);
  };

  const onCardViewClick = (data) => {
    if (data == 1) {
      setActiveIndex(data);
      navigate('/ecom/shipment/prepare', { replace: true });
    } else if (data == 2) {
      setActiveIndex(data);
      navigate('/ecom/shipment/labelprint', { replace: true });
    } else if (data == 3) {
      setActiveIndex(data);
      navigate('/ecom/shipment/pickup', { replace: true });
    }
  };

  const stockOut = async (data) => {
    try {
      let resp = await axios.post(`${app.URL}/api/v1/admin/stockout/create`, data, { headers: getHeaders() });
      console.log('resp');
      setStockOutResp(resp.data.result);
      // setStockOutStatus(true)
      setDispatchShow(true);

      setTimeout(() => {
        setDispatchShow(!dispatchShow);
      }, [3000]);
    } catch (e) {
      throw e;
    }
  };

  const onDispatchTrigger = (data) => {
    console.log('dispatch data: ', data);
    setStockOutResp({});
    setDispatchShow(!dispatchShow);
    setDispatchData(data);
  };

  const onDispatchClick = async () => {
    console.log(dispatchData);
    let stockOutArr = [];
    dispatchData.products.forEach((ele) => {
      stockOutArr.push({
        productId: ele.productId,
        quantity: ele.quantity,
        unitPrice: ele.unitPrice,
        patientId: subOrderObj.patientId,
        ecomOrderId: subOrderObj.ecomOrderId,
        addressId: dispatchData.addressId,
      });
    });

    await stockOut(stockOutArr);
    console.log('stock out resp: ', stockOutResp);
    // getInProgressData();
    setRefreshPage(true);

    // console.log(stockOutResp)
  };

  const [mainEcomOrderId, setMainEcomOrderId] = useState(null);

  const onProcessTrigger = (subOrder) => {
    let orderObj = {
      ecomOrderId: subOrder.ecomOrderId,
      addressId: subOrder.addressId,
    };

    // const messageToSend = { name: 'Shipment Process Start', process: 1 };
    // setTimeout(() => {
    //   ws.send(JSON.stringify(messageToSend));
    // }, 1000);

    
    localStorage.setItem('order', JSON.stringify(orderObj));

    
    navigate('/ecomorders/prepareshipment', { replace: true }, );
  };

  const onReleaseTrigger = async (subOrder) => {
    const ItemCartShipId = subOrder?.products?.map((item) => item.ItemCartShipId);
    console.log(ItemCartShipId);

    // const messageToSend = { name: 'Shipment Process Start', process: 0 };

    try {
      let resp = await axios.post(`${app.URL}/api/v1/admin/ecomorders/releasebyadmin`, { ItemCartShipId }, { headers: getHeaders() });
      console.log('Release User from Shipment by Admin', resp);
      // ws.send(JSON.stringify(messageToSend));
      setRefreshPage(true);

    } catch (error) {
      console.log('Error: ', error);
    }
  };

  useEffect(() => {
    const getState = async () => {
      const statusResp = await axios.get(`${app.URL}/api/v1/admin/ecomorders/warehouseordercount`, { headers: getHeaders() });
      statusResp.data ? console.log(statusResp.data.result.data) : console.log('data');
      statusResp.status == 200 ? setStatus(statusResp.data.result.data) : ' ';
      console.log('status: ', status);
    };
    getState();
  }, [refreshPage]);

  const [mainOrderObj, setMainOrderObj] = useState({});

  const mainOrderModal = (order) => {
    console.log('Main Order Modal Running');
    const getData = async () => {
      let resp = await axios.get(`${app.URL}/api/v1/admin/ecomorders/view/${order.ecomOrderId}`, { headers: getHeaders() });
      setMainOrderObj(resp.data.result.data);
      setSingleMainOrderObj(resp.data.result.data);
      openMainOrderModal();
    };

    getData();
  };

  const openMainOrderModal = () => {
    setShowSingleMainOrder(true);
  };

  const handleOnChange = (e) => {
    if (e.target.value.length === 0) {
      setRefreshPage(!refreshPage);
      setSearchShow({});
    }
  };

  // const handleSorting = (e) => {
  //   // message.info(e)
  //   console.log(renderList);
  //   // renderList.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
  //   if (e === "New") {
  //     // Sorting the data by createdAt in descending order
  //     const descendingOrder = renderList.slice().sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
  //     setRenderList(descendingOrder)
  //   }
  //   else if (e === "Old") {
  //     // Sorting the data by createdAt in ascending order
  //     const ascendingOrder = renderList.slice().sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
  //     setRenderList(ascendingOrder)
  //   }
  //   else {
  //     setRefreshPage(!refreshPage);
  //   }
  // }

  const handleSorting = (sortBy, sortMethod) => {
    setSortShow({ sortBy, sortMethod });
    // setOrderSorting(false);
    setSortValue(sortMethod);
    // setPackageSorting(false);
    let formData = {
      limit: pageSize,
      offset: 0,
      searchBy: searchShow.searchBy ? searchShow.searchBy : 'orderNo',
      queryTxt: searchShow.searchTxt ? searchShow.searchTxt : '',
      sortBy,
      sortMethod,
    };
    // console.log(formData)
    setLoader(true);
    inventoryListingApi(formData);
  };

 

let _ws;
// const handleCloseSocket = () => {
//   ws.close();
//   ws.onclose = () => {
//     console.log('Disconnected');
//   };
// };
  useEffect(() => {
    const messageToSend = { name: 'Shipment Dashboard', process: 0 };
    _ws = new WebSocket(`ws://192.168.18.198:3300/hello-ws?name=${user?.result.data.user.firstName} ${user?.result.data.user.lastName}`);
    setWs(_ws);
   

    _ws.onopen = () => {
      console.log('Connected');
      setTimeout(() => {
        _ws.send(JSON.stringify(messageToSend));
      }, 1000);
    };
   
    if(ws){
      ws.onmessage = (e) => {
      const message = JSON.parse(e.data);
      console.log(message);
      // if (message.process || !message.process) {
        let formData = {
          limit: pageSize,
          offset: 0,
          searchBy: searchShow.searchBy ? searchShow.searchBy : 'orderNo',
          queryTxt: searchShow.searchTxt ? searchShow.searchTxt : '',
          sortBy: sortShow.sortBy ? sortShow.sortBy : 'ecomOrderNo',
          sortMethod: sortShow.sortMethod ? sortShow.sortMethod : 'asc',
        };
        // console.log(formData)
        console.log("Reload")
        inventoryListingApi(formData);
      }
    // };
    }
    else{
      _ws.onmessage = (e) => {
        const message = JSON.parse(e.data);
        console.log(message);
        // if (message.process || !message.process) {
          let formData = {
            limit: pageSize,
            offset: 0,
            searchBy: searchShow.searchBy ? searchShow.searchBy : 'orderNo',
            queryTxt: searchShow.searchTxt ? searchShow.searchTxt : '',
            sortBy: sortShow.sortBy ? sortShow.sortBy : 'ecomOrderNo',
            sortMethod: sortShow.sortMethod ? sortShow.sortMethod : 'asc',
          };
          console.log('Reload');
          inventoryListingApi(formData);
        }
      // };
    }
  }, []);

  console.log(renderList.length)

  return (
    <div className='dashboard-card container-fluid'>
      <div className='row p-0 pb-4'>
        <div className='for-before col-12 col-lg-4 col-md-6 head fs-3 fw-bold' style={{ backgroundColor: '#a7c1d9', borderBottomRightRadius: '10px', color: '#FFFFFF' }}>
          Shipment Dashboard
        </div>
        {/* <div className='col-12 mt-2 col-lg-4 col-md-3'>
          <RangePicker onChange={onChange} />
        </div>
        <div className='col-12 mt-2 col-lg-4 col-md-3 d-flex align-end' style={{ margin: 'auto', position: 'relative' }}>
          <img className='calender-img' src={CalenderImg} />
          <select className='form-control drop-down-select' name='dates' id='date' onChange={(e) => dateSelect(e.target.value)}>
            <option value={moment().subtract(24, 'hours').format('YYYY-MM-DD')}>last 24 hours</option>
            <option value={moment().subtract(7, 'd').format('YYYY-MM-DD')}>last week</option>
            <option value={moment().subtract(1, 'months').format('YYYY-MM-DD')}>last month</option>
            <option value={moment().subtract(1, 'year').format('YYYY-MM-DD')}>last year</option>
          </select>
        </div> */}
      </div>

      <div className='row px-3'>
        <div style={{ position: 'relative' }} className='col-md-4 col-12 my-1' onClick={() => onCardViewClick(1)}>
          <EcommerceDashboardCard count={status.length > 1 ? status[0]?.count : 0} isActive={window.location.pathname == '/ecom/shipment/prepare'} activatedProducts={true} awaitingProducts={false} cardName={'Prepare Shipment'} />
        </div>
        <div style={{ position: 'relative' }} className='col-md-4 col-12 my-1' onClick={() => onCardViewClick(2)}>
          <EcommerceDashboardCard count={status.length > 1 ? status[1]?.count : 0} isActive={window.location.pathname == '/ecom/shipment/labelprint'} activatedProducts={true} awaitingProducts={false} cardName={'Print Labels'} />
        </div>
        <div style={{ position: 'relative' }} className='col-md-4 col-12 my-1' onClick={() => onCardViewClick(3)}>
          <EcommerceDashboardCard
            // count={status.length > 1 ? status[3].count : 0}
            count={status.length > 1 ? status[2]?.count : 0}
            isActive={window.location.pathname == '/ecom/shipment/pickup'}
            activatedProducts={false}
            awaitingProducts={true}
            cardName={'Ready to Ship'}
          />
        </div>
      </div>

      <SearchBar
        onFinish={onSearchFinish}
        onFinishFailed={onSearchFinishFailed}
        onChange={handleOnChange}
        // handleSorting={handleSorting}
        searchItems={[
          {
            name: 'Order No.',
            value: 'orderNo',
          },
          {
            name: 'Shipment Name',
            value: 'title',
          },
        ]}
      />

      <div
        className=' table-scrollbar '
        style={{
          overflowX: 'auto',
        }}
      >
        {loader && <LoaderImage />}
        {renderList.length > 0 && !loader ? (
          <table id='customers' className='w-100'>
            <thead class=''>
              <tr>
                {shipmentHeader?.map((header, indx) => (
                  <th className='relative text-center' key={header}>
                    <div className='text-center px-2'>
                      {header}
                      {/* {indx === 0 ? (
                        <div className='ml-1 d-flex flex-column'>
                          <CaretUpOutlined className='cursor-pointer' onClick={() => handleSorting('ecomOrderNo', 'asc')} style={{ color: sortValue === 'desc' && '#ccc', fontSize: '0.8rem' }} />
                          <CaretDownOutlined className='cursor-pointer' onClick={() => handleSorting('ecomOrderNo', 'desc')} style={{ color: sortValue === 'asc' && '#ccc', fontSize: '0.8rem' }} />
                        </div>
                      ) : indx === 3 ? (
                        <div className='m-1 d-flex flex-column'>
                          <CaretUpOutlined className='cursor-pointer' onClick={() => handleSorting('shipmePackageName', 'Over Night')} style={{ color: sortValue === 'Standard Delivery' && '#ccc', fontSize: '0.8rem' }} />
                          <CaretDownOutlined className='cursor-pointer' onClick={() => handleSorting('shipmePackageName', 'Standard Delivery')} style={{ color: sortValue === 'Over Night' && '#ccc', fontSize: '0.8rem' }} />
                        </div>
                      ) : (
                        ''
                      )} */}
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {renderList.map((tableValues, key) => (
                <tr>
                  <td style={{ width: '180px' }}>{tableValues.ecomOrderNo ? tableValues.ecomOrderNo + '-' + tableValues.addressId : 'null'}</td>
                  <td style={{ width: '180px' }}>{tableValues.title ? tableValues.title : 'null'}</td>
                  <td style={{ width: '220px', textAlign: 'center' }}>{`${tableValues.address}, ${tableValues.state}, ${tableValues.city}, ${tableValues.zipCode}`}</td>
                  <td style={{ width: '180px' }}>{tableValues.shipmePackageName ? tableValues.shipmePackageName : 'null'}</td>
                  <td style={{ width: '180px' }}>{tableValues.requestType ? tableValues.requestType : ''}</td>
                  <td style={{ width: '70px', textTransform: 'capitalize' }}>
                    {!tableValues.requestId && (
                      <div className='d-flex'>
                        {user.result.data.permission.some((item) => item.action === 'ecomorders/releasebyadmin') && tableValues.isAssigned && (
                          <>
                            <Button
                              className='btn btn-primary button patient-cancel-btn'
                              style={{ width: '80px', height: '34px', fontSize: 'small', marginRight: '0.5rem' }}
                              size='medium'
                              onClick={() => {
                                onReleaseTrigger(tableValues);
                              }}
                            >
                              Release
                            </Button>
                            {/* <Button
                              style={{ width: '80px', height: '34px', fontSize: 'small', marginRight: '0.5rem' }}
                              size='medium'
                              onClick={() => {
                                onProcessTrigger(tableValues);
                              }}
                            >
                              Process
                            </Button> */}
                          </>
                        )}
                        {!tableValues.isAssigned && (
                          <Button
                            style={{ width: '80px', height: '34px', fontSize: 'small', marginRight: '0.5rem' }}
                            size='medium'
                            onClick={() => {
                              onProcessTrigger(tableValues);
                            }}
                          >
                            Process
                          </Button>
                        )}
                      </div>
                    )}
                  </td>
                  <td style={{ width: '70px', textTransform: 'capitalize' }}>
                    <Button
                      style={{ width: '70px', height: '34px', fontSize: 'small', marginRight: '0.5rem' }}
                      size='medium'
                      onClick={() => {
                        setSingleOrderObj(tableValues);
                        setShowSingleOrder(true);
                      }}
                    >
                      View
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <>{!loader && <NoDataFound />}</>
        )}
      </div>

      {/* {renderList.length > 0 && !loader && <div className='row'> */}
        {currentPage && pagesCount ? <Pagination style={{ marginTop: '10px' }} 
        onChange={activeIndex == 4 ? onRequestChange : onChange} defaultCurrent={currentPage} 
        total={pagesCount} showTotal={(total) => `Total ${total} items`} showSizeChanger /> : ' '}
        {/* </div>} */}

      {/* show single main order modal start */}

      {showSingleMainOrder && (
        <MainOrderViewModal
          isVisible={showSingleMainOrder}
          setIsVisible={setShowSingleMainOrder}
          ecomOrderNo={singleMainOrderOBj.ecomOrderNo}
          ecomOrderCreatedData={moment(singleMainOrderOBj.createdAt).format('MM-DD-YYYY')}
          // patientName={"singleMainOrderOBj.patientName"}
          productTotalAmount={singleMainOrderOBj.totalProductAmount}
          shipmentTotal={singleMainOrderOBj.totalShipmentCharges}
          subOrdersData={singleMainOrderOBj?.orderDetails}
          isLoading={isModalLoading}
          setIsLoading={setIsModalLoading}
          mainOrderShipmentAmount={singleMainOrderOBj.totalShipmentCharges}
          mainOrderGrandTotal={singleMainOrderOBj.grandTotalAmount}
          totalProductAmount={singleMainOrderOBj.totalProductAmount}
          ecomOrderId={singleMainOrderOBj.ecomOrderId}
          isApiCallAble={false}
        />
      )}

      {/* show single main order modal end */}

      {/* show single sub order modal start */}

      {showSingleOrder && (
        <SubOrderViewModal
          isVisible={showSingleOrder}
          ecomOrderId={singleOrderOBj.ecomOrderId}
          addressId={singleOrderOBj.addressId}
          ecomOrderNo={`${singleOrderOBj.ecomOrderNo}-${singleOrderOBj.addressId}`}
          setIsVisible={setShowSingleOrder}
          address={`${singleOrderOBj.address}, ${singleOrderOBj.state}, ${singleOrderOBj.city}, ${singleOrderOBj.zipCode}`}
          ecomOrderCreatedDate={subOrderObj.createdAt}
          productsData={singleOrderOBj?.products}
          status={singleOrderOBj.status}
          title={singleOrderOBj.title}
          shipmentCharges={singleOrderOBj.shipmentCharges}
          grandTotal={singleOrderOBj.totalAmountwithShipment}
          totalShipmentAmount={singleOrderOBj.totalShipmentAmount}
          tax={singleOrderOBj.taxAmount}
          requestId={singleOrderOBj.requestId}
          requestStatus={singleOrderOBj.requestStatus}
          requestType={singleOrderOBj.requestType}
          shipmePackageName={singleOrderOBj.shipmePackageName}
          wareHouseStatus={singleOrderOBj.wareHouseStatus}
          locationURL={window.location.pathname}
        />
      )}

      {/* show single sub order modal end */}

      {/* dispatch order modal start */}

      {stockOutResp && stockOutResp.code === 200 ? (
        <Modal show={dispatchShow} onHide={handleCancelModalClose}>
          <Modal.Header closeButton>
            <Modal.Title className='text-center'>Success</Modal.Title>
          </Modal.Header>
          <Modal.Body>{stockOutResp.msg}</Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      ) : stockOutResp && stockOutResp.code === 400 ? (
        <Modal show={dispatchShow} onHide={handleCancelModalClose}>
          <Modal.Header closeButton>
            <Modal.Title className='text-center'>Failed</Modal.Title>
          </Modal.Header>
          <Modal.Body>{stockOutResp.msg}</Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      ) : (
        <Modal show={dispatchShow} onHide={handleCancelModalClose}>
          <Modal.Header closeButton>
            <Modal.Title>Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure you want to dispatch the shipment?
            {/* comment start */}
            {/* <div className='row'>

                                        <div className='col lg-12 p-2 mt-2'>



                                            <Form
                                                initialValues={{
                                                    adminComment: adminComment 
                                                }}
                                            >
                                                <Row>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            label="Admin Comment"
                                                            name="adminComment"
                                                            style={{padding:"0px !important"}}
                                                        >

                                                        </Form.Item>

                                                    </Col>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            name="adminComment"
                                                        >
                                                            <TextArea rows={3} autoSize={false} />
                                                        </Form.Item>

                                                    </Col>
                                                </Row>
                                            </Form>
                                        </div>
                                    </div> */}
            {/* comment end */}
          </Modal.Body>

          <Modal.Footer>
            <Button
              variant='secondary'
              onClick={() => {
                setDispatchShow(!dispatchShow);
              }}
            >
              Cancel
            </Button>
            <Button
              variant='primary'
              htmlType='submit'
              onClick={() => {
                onDispatchClick();
              }}
            >
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>
      )}

      {/* dispatch order modal end */}

      {/* Cancel Modal */}
      {orderCancelResp && orderCancelResp.code === 200 ? (
        <Modal show={cancelShow} onHide={handleCancelModalClose}>
          <Modal.Header closeButton>
            <Modal.Title className='text-center'>Success</Modal.Title>
          </Modal.Header>
          <Modal.Body>{orderCancelResp.msg}</Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      ) : orderCancelResp && orderCancelResp.code === 400 ? (
        <Modal show={cancelShow} onHide={handleCancelModalClose}>
          <Modal.Header closeButton>
            <Modal.Title className='text-center'>Failed</Modal.Title>
          </Modal.Header>
          <Modal.Body>{orderCancelResp.msg}</Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      ) : (
        <Modal show={cancelShow} onHide={handleCancelModalClose}>
          <Modal.Header closeButton>
            <Modal.Title>Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to cancel shipment?</Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={handleCancelModalClose}>
              Cancel
            </Button>
            <Button variant='primary' htmlType='submit' onClick={onCancelClick}>
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>
      )}

      {/* Recive Back Modal */}
      {reciveBackResp && reciveBackResp.code === 200 ? (
        <Modal show={reciveBackShow} onHide={handleReciveBackModalClose}>
          <Modal.Header closeButton>
            <Modal.Title className='text-center'>Success</Modal.Title>
          </Modal.Header>
          <Modal.Body>{reciveBackResp.msg}</Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      ) : reciveBackResp && reciveBackResp.code === 400 ? (
        <Modal show={reciveBackShow} onHide={handleReciveBackModalClose}>
          <Modal.Header closeButton>
            <Modal.Title className='text-center'>Failed</Modal.Title>
          </Modal.Header>
          <Modal.Body>{reciveBackResp.msg}</Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      ) : (
        <Modal show={reciveBackShow} onHide={handleReciveBackModalClose}>
          <Modal.Header closeButton>
            <Modal.Title>Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure, you want to recive shipment back?</Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={handleReciveBackModalClose}>
              Cancel
            </Button>
            <Button variant='primary' htmlType='submit' onClick={onReciveBackClick}>
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>
      )}

      {/* Resend Order Modal */}
      {resendOrderResp && resendOrderResp.code === 200 ? (
        <Modal show={resendOrderShow} onHide={handleResendOrderModal}>
          <Modal.Header closeButton>
            <Modal.Title className='text-center'>Success</Modal.Title>
          </Modal.Header>
          <Modal.Body>{resendOrderResp.msg}</Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      ) : resendOrderResp && resendOrderResp.code === 400 ? (
        <Modal show={resendOrderShow} onHide={handleResendOrderModal}>
          <Modal.Header closeButton>
            <Modal.Title className='text-center'>Failed</Modal.Title>
          </Modal.Header>
          <Modal.Body>{resendOrderResp.msg}</Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      ) : (
        <Modal show={resendOrderShow} onHide={handleResendOrderModal}>
          <Modal.Header closeButton>
            <Modal.Title>Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>{missingItem ? `Are you sure, you want to send the missing item?` : `Are you sure, you want to resend the order?`}</Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={handleResendOrderModal}>
              Cancel
            </Button>
            <Button variant='primary' htmlType='submit' onClick={onResendOrderonClick}>
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
};

export default PrepareForShip;
