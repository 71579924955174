import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { app } from '../../config';
import { useNavigate } from 'react-router-dom';
import { Pagination, Input, Form, Select, Row, Col, DatePicker, Button, message, Modal as AntModal } from 'antd';
import { useTranslation } from 'react-i18next';
import Modal from 'react-bootstrap/Modal';
import { EditTwoTone, BranchesOutlined, CodeSandboxCircleFilled, ConsoleSqlOutlined, LoadingOutlined } from '@ant-design/icons';
import getHeaders from '../../utils/authHeaders';
import ListinngCard from '../../layouts/ListingCard';
import AddNewImg from '../../assets/images/add-new.png';
import { dateConverter } from '../../utils/utcDateConversionCommon';
import moment from 'moment';
import { useForm } from 'antd/lib/form/Form';
import { useReactToPrint } from 'react-to-print';
import Barcode from 'react-barcode';
import TextArea from 'antd/lib/input/TextArea';
import LoaderImage from '../LoaderImage';
import { SearchBar } from '../../layouts/SearchBar';
import SearchOrderTable from './SearchOrderTable';
import  { EcommerceModal, SearchOrderModals } from './SearchOrderViewModal';

function SearchOrder() {
  let navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem('user'));
  const [orderDateFrom, setorderDateFrom] = useState('');
  const [orderDateTo, setorderDateTo] = useState('');

  // const [accessionNo, setAccessionNo] = useState(false);
  const [boxNumberError, setBoxNumberError] = useState('');
  const [orderResponseData, setOrderResponseData] = useState();
  // const [orderReceiveData, setOrderReceiveData] = useState();
  const [searchSerialNo, setSearchSerialNo] = useState('');
  const [sendToLabResp, setSendToLabResp] = useState('');
  const [orderForm] = useForm();
  const [show, setShow] = useState(false);
  const [openModal, setOpenModal]= useState(false)
  const [loader, setLoader] = useState(false);
  const [dataKeys, setDataKeys] = useState([]);
  const [searchOrderObject, setSearchOrderObject] = useState([]);
  // const handleAccClose = () => setAccessionNo(!accessionNo);

  const inputRef = useRef();
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  let resp;
  const onReceiveOrder = async () => {
    setLoader(true);
    let userId = user?.result.data.user.userId;

    const payload = {
      testOrderId: orderResponseData?.testOrderId,
      patientId: orderResponseData?.patientId,
      productId: orderResponseData?.productId,
      serialNo: orderResponseData?.serialNo,
      userId: userId,
    };

    try {
      const res = await axios.post(`${app.URL}/api/v1/admin/dashboard/reciveorder`, payload, { headers: getHeaders() });
      console.log(res.data);

      // console.log("res",res.data.result)
      // return;

      if (res.data.result.success) {
        setOrderResponseData(res.data?.result?.data);
        console.log(res.data?.result?.data);
        resp = res.data?.result?.data;

        setTimeout(() => {
          handlePrint();
          setLoader(false);
        }, 2000);
      }
    } catch (error) {
      console.log('error in check order api: ', error);
    }
  };

  console.log(orderResponseData);

  const sendToLab = async () => {
    console.log(resp);
    let userId = user?.result.data.user.userId;
    resp.userId = userId;

    try {
      const res = await axios.post(`${app.URL}/api/v1/admin/dashboard/sendtolab/${orderResponseData.testOrderId}`, resp, { headers: getHeaders() });

      console.log(res);
      // navigate('/dashboard/listing');
      setOrderResponseData();
      // setShow(false);
      setSendToLabResp(res.data.result.msg);
      localStorage.removeItem('accessionCode');
      // message.success(res.data.result.msg);
      setLoader(false);
    } catch (err) {
      console.log(err);
      setLoader(false);
      if (err) setSendToLabResp(err.response.data.result.msg);
      else return;
    }
  };

  const handleSendToLabResp = () => {
    setSendToLabResp('');
    setTimeout(() => {
      inputRef.current.focus();
    }, 300);
  };

  const searchOrderListing = async (formData) => {
    console.log(formData);
    setLoader(true);
    try {
      // let res = await axios.get(`${app.URL}/api/v1/admin/reports/search?searchBy=${formData?.searchBy}&searchTxt=${formData?.searchTxt}`);
      let res = await axios.get(`${app.URL}/api/v1/admin/reports/search?searchBy=${formData?.searchBy}&searchTxt=${formData?.searchTxt}&all=1`);
      console.log(res.data);

      // setDataKeys( Object.keys(res?.data?.data))

      const transformedData = Object.keys(res?.data?.result?.data).map((key) => {
        const section = res?.data?.result?.data[key];
        return {
          heading: key,
          tableHeader: section.headings,
          tableBody: Array.isArray(section) ? section : [section],
        };
      });

      setSearchOrderObject(transformedData);

      //   let dataObject = dataKeys?.find((value) =>  value?.replace(/_/g, '') === formData?.searchBy );
      //   let searchObjKeys = Object.keys(res?.data?.data[dataObject])
      //   let searchObjValues = Object.values(res?.data?.data[dataObject]);
      //   console.log(dataObject)
      console.log(dataKeys);
      //   console.log(searchObjKeys)
      //   console.log(searchObjValues)
      setLoader(false);
    } catch (error) {
      console.log(`Error While Searching API - > ${error}`);
    }
  };

  const pageStyle = `
       @page {
        size: 2in 1in; /* Specify your custom page size here (width x height) */
        padding: 0;
        margin: 0;
       }
      //   @page {
      //   size: 8.5in 11.5in; /* Specify your custom page size here (width x height) */
      //      marginTop: 4px;
      //      margin: 0;
      //  }
       body {
         font-family: Arial, sans-serif;
         margin: 0;
       }
       `;

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: pageStyle,
    onAfterPrint: sendToLab,
  });

  const handleRePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: pageStyle,
  });

  // const handleOpen = () => {
  //   setShow(true);
  // };
  // const handleClose = () => {
  //   setShow(false);
  // };

  const concatName = () => {
    // orderResponseData || orderReceiveData;
    if (orderResponseData) {
      //  let fullname = `${orderReceiveData ? orderReceiveData?.firstName : orderResponseData?.firstName} ${orderReceiveData ? orderReceiveData?.lastName : orderResponseData?.lastName} `;
      let fullname = `${orderResponseData?.firstName} ${orderResponseData?.lastName} `;
      if (fullname && fullname.length > 12) {
        // return `${orderReceiveData ? orderReceiveData?.firstName.slice(0, 1) : orderResponseData?.firstName.slice(0, 1)}. ${orderReceiveData ? orderReceiveData?.lastName : orderResponseData?.lastName}`;
        return `${orderResponseData?.firstName.slice(0, 1)}. ${orderResponseData?.lastName}`;
      } else {
        return fullname;
      }
    }
  };

  const onSearchFinish = async (values) => {
    console.log('onSearchFinish');

    console.log('Success:', values);
    let formData = {
      searchBy: values?.searchBy,
      searchTxt: values?.searchTxt || '',
    };
    if (!values.searchTxt) return message.error('Please enter search text');
    console.log(formData);
    await searchOrderListing(formData);
  };

  const onSearchFinishFailed = (values) => {
    console.log('Failed:', values);
  };

  const handleOnChange = async (e) => {
    if (e.target.value.length === 0) {
      let formData = {
        searchBy: '',
        searchTxt: '',
      };
      setSearchShow({});
      //  await dashboardListingApi(formData);
    }
  };

  const [modalLoader,setModalLoader] =useState(false)
    //  For Ecommerce Order  
    const [commentsData, setCommentsData] = useState([]);
    const [ecomOrderResp,setEcomOrderResp]=useState({})

    //  For Other Data
    const [modalData,setModalData]=useState({})
    // State For Identifying Which Modal To Be Opened
    const [modalHeader, setModalHeader] =useState("")

  const handleClick = async (data,header) => {
      const payload = { data, header };
      setModalLoader(true)
     try {
      setModalHeader(header)
      const res = await axios.get(`${app.URL}/api/v1/admin/reports/search?searchBy=${header}&searchTxt=${data}&${header}=1`);
      if(res?.data?.result?.success){
      if (header === 'ecommerceOrder') {
      setCommentsData(res?.data?.result?.data?.comments);
      setEcomOrderResp(res?.data?.result?.data?.ecomOrderResp);
     

        }else{
          setModalData(res?.data?.result?.data);
        }
        setModalLoader(false)
      }
     } catch (error) {
      
     }

    
    setOpenModal(true)
  
  console.log(payload)
  };



  const SearchItems = [
    //  {
    //    value: 'productName',
    //    name: 'Product',
    //  },
    // {
    //   value: 'partnerName',
    //   name: 'Partner',
    // },

    {
      value: 'genericBox',
      name: 'Generic Box',
    },

    {
      value: 'serialNo',
      name: 'Serial No.',
    },

    {
      value: 'ecommerceOrder',
      name: 'Ecom Order No.',
    },
    {
      value: 'testOrder',
      name: 'Test Order No.',
    },
    {
      value: 'accessionNo',
      name: 'Accession No.',
    },
    {
      value: 'orderNo',
      name: 'Order No.',
    },
  ];

  const searchTableHeaders = ['E-Commerce Order', 'Product Name', 'Serial No', 'Generic Box', 'Return Label', 'Order No', 'Accession No', 'Test Result'];
 

  // Temp Data For Serach Order Modal
  const tempModalData = {
    ecomOrderId: 1078,
    ecomOrderNo: 'EOR-240000491',
    createdAt: '2024-05-02T07:19:44.000Z',
    comments: [
      {
        commentHistoryId: 3210,
        addressId: 238,
        ecomOrderId: 1078,
        commentBy: 'System',
        comment: 'Shipment status has been set to delivered',
        image: '471',
        isActive: 1,
        createdAt: '2024-06-25T11:11:15.000Z',
        updatedAt: '2024-06-25T11:11:15.000Z',
        createdBy: null,
        updatedBy: null,
      },
      {
        commentHistoryId: 3391,
        addressId: 715,
        ecomOrderId: 1078,
        commentBy: 'System',
        comment: 'Shipment has been sent for label printing',
        image: null,
        isActive: 1,
        createdAt: '2024-06-28T10:26:05.000Z',
        updatedAt: '2024-06-28T10:26:05.000Z',
        createdBy: null,
        updatedBy: null,
      },
      {
        commentHistoryId: 5064,
        addressId: 735,
        ecomOrderId: 1078,
        commentBy: 'System',
        comment: 'Shipment has been sent for label printing',
        image: null,
        isActive: 1,
        createdAt: '2024-08-26T10:10:27.000Z',
        updatedAt: '2024-08-26T10:10:27.000Z',
        createdBy: null,
        updatedBy: null,
      },
      {
        commentHistoryId: 5065,
        addressId: 735,
        ecomOrderId: 1078,
        commentBy: 'System',
        comment: 'Shipment Label print successfully',
        image: null,
        isActive: 1,
        createdAt: '2024-08-26T10:10:37.000Z',
        updatedAt: '2024-08-26T10:10:37.000Z',
        createdBy: null,
        updatedBy: null,
      },
      {
        commentHistoryId: 5066,
        addressId: 735,
        ecomOrderId: 1078,
        commentBy: 'System',
        comment: 'Order status has been set to In-Transit',
        image: null,
        isActive: 1,
        createdAt: '2024-08-26T10:11:07.000Z',
        updatedAt: '2024-08-26T10:11:07.000Z',
        createdBy: null,
        updatedBy: null,
      },
      {
        commentHistoryId: 5067,
        addressId: 735,
        ecomOrderId: 1078,
        commentBy: 'System',
        comment: 'Shipment status has been set to delivered',
        image: '471',
        isActive: 1,
        createdAt: '2024-08-26T10:12:53.000Z',
        updatedAt: '2024-08-26T10:12:53.000Z',
        createdBy: null,
        updatedBy: null,
      },
      {
        commentHistoryId: 5070,
        addressId: 238,
        ecomOrderId: 1078,
        commentBy: null,
        comment: null,
        image: null,
        isActive: 1,
        createdAt: '2024-08-26T11:10:29.000Z',
        updatedAt: '2024-08-26T11:10:29.000Z',
        createdBy: 471,
        updatedBy: null,
      },
      {
        commentHistoryId: 5071,
        addressId: 238,
        ecomOrderId: 1078,
        commentBy: null,
        comment: null,
        image: null,
        isActive: 1,
        createdAt: '2024-08-26T11:11:09.000Z',
        updatedAt: '2024-08-26T11:11:09.000Z',
        createdBy: 471,
        updatedBy: null,
      },
    ],
    totalProductAmount: 6,
    totalTaxAmount: 0.12,
    totalShipmentCharges: 2,
    grandTotalAmount: 8.120000000000001,
    
  };

  const subOrderData =  [
      {
        ecomOrderId: 1078,
        ecomOrderNo: 'EOR-240000491',
        ItemCartShipId: 2251,
        addressId: 238,
        name: null,
        title: 'Appu Raja',
        address: '1031 Meadow Ridge Dr, Bainbridge, GA 39819',
        contactPerson: 'sajidhamid71+82@gmail.com',
        mobile: '+1 (333) 280-3378',
        stateId: 3,
        state: 'Alabama',
        city: 'Nyc',
        zipCode: '70220',
        isDefault: 0,
        requestId: null,
        requestStatus: null,
        shipmentPackageId: 2,
        shipmePackageName: 'Standard Delivery',
        shipmentCharges: '0.00',
        estimatedDeliveryHours: '96',
        deliveryHours: null,
        isRecivedBack: null,
        orderStatus: 4,
        status: 'Delivered',
        wareHouseStatusId: null,
        wareHouseStatus: null,
        createdAt: '2024-05-02T07:19:44.000Z',
        comments: [
          {
            commentHistoryId: 3210,
            addressId: 238,
            ecomOrderId: 1078,
            commentBy: 'System',
            comment: 'Shipment status has been set to delivered',
            image: '471',
            isActive: 1,
            createdAt: '2024-06-25T11:11:15.000Z',
            updatedAt: '2024-06-25T11:11:15.000Z',
            createdBy: null,
            updatedBy: null,
          },
          {
            commentHistoryId: 5070,
            addressId: 238,
            ecomOrderId: 1078,
            commentBy: null,
            comment: null,
            image: null,
            isActive: 1,
            createdAt: '2024-08-26T11:10:29.000Z',
            updatedAt: '2024-08-26T11:10:29.000Z',
            createdBy: 471,
            updatedBy: null,
          },
          {
            commentHistoryId: 5071,
            addressId: 238,
            ecomOrderId: 1078,
            commentBy: null,
            comment: null,
            image: null,
            isActive: 1,
            createdAt: '2024-08-26T11:11:09.000Z',
            updatedAt: '2024-08-26T11:11:09.000Z',
            createdBy: 471,
            updatedBy: null,
          },
        ],
        products: [
          {
            productId: 43,
            productName: 'Broken Hearts',
            patientId: 471,
            eCommOrderId: 1078,
            quantity: '1',
            unitPrice: '2.00',
          },
        ],
        totalShipmentAmount: 2,
        taxAmount: 0.04,
        totalAmountwithShipment: 2,
      },
      {
        ecomOrderId: 1078,
        ecomOrderNo: 'EOR-240000491',
        ItemCartShipId: 2252,
        addressId: 715,
        name: null,
        title: 'darakhshan',
        address: 'address',
        contactPerson: 'darakhshan.sarwar@evantagesoft.com',
        mobile: '',
        stateId: 17,
        state: 'Hawaii',
        city: 'city',
        zipCode: '12345',
        isDefault: 0,
        requestId: null,
        requestStatus: null,
        shipmentPackageId: 2,
        shipmePackageName: 'Standard Delivery',
        shipmentCharges: '0.00',
        estimatedDeliveryHours: '96',
        deliveryHours: null,
        isRecivedBack: null,
        orderStatus: 2,
        status: 'In Progress',
        wareHouseStatusId: 2,
        wareHouseStatus: 'Print Label',
        createdAt: '2024-05-02T07:19:44.000Z',
        comments: [
          {
            commentHistoryId: 3391,
            addressId: 715,
            ecomOrderId: 1078,
            commentBy: 'System',
            comment: 'Shipment has been sent for label printing',
            image: null,
            isActive: 1,
            createdAt: '2024-06-28T10:26:05.000Z',
            updatedAt: '2024-06-28T10:26:05.000Z',
            createdBy: null,
            updatedBy: null,
          },
        ],
        products: [
          {
            productId: 30,
            productName: 'Food Monsters',
            patientId: 471,
            eCommOrderId: 1078,
            quantity: '1',
            unitPrice: '2.00',
          },
        ],
        totalShipmentAmount: 2,
        taxAmount: 0.04,
        totalAmountwithShipment: 2,
      },
      {
        ecomOrderId: 1078,
        ecomOrderNo: 'EOR-240000491',
        ItemCartShipId: 2250,
        addressId: 735,
        name: null,
        title: 'testing address',
        address: 'address',
        contactPerson: 'qa@mailinator.com',
        mobile: '415 425 5855',
        stateId: 6,
        state: 'Arizona',
        city: 'city',
        zipCode: '',
        isDefault: 0,
        requestId: null,
        requestStatus: null,
        shipmentPackageId: 1,
        shipmePackageName: 'Over Night',
        shipmentCharges: '2.00',
        estimatedDeliveryHours: '72',
        deliveryHours: null,
        isRecivedBack: null,
        orderStatus: 4,
        status: 'Delivered',
        wareHouseStatusId: null,
        wareHouseStatus: null,
        createdAt: '2024-05-02T07:19:44.000Z',
        comments: [
          {
            commentHistoryId: 5064,
            addressId: 735,
            ecomOrderId: 1078,
            commentBy: 'System',
            comment: 'Shipment has been sent for label printing',
            image: null,
            isActive: 1,
            createdAt: '2024-08-26T10:10:27.000Z',
            updatedAt: '2024-08-26T10:10:27.000Z',
            createdBy: null,
            updatedBy: null,
          },
          {
            commentHistoryId: 5065,
            addressId: 735,
            ecomOrderId: 1078,
            commentBy: 'System',
            comment: 'Shipment Label print successfully',
            image: null,
            isActive: 1,
            createdAt: '2024-08-26T10:10:37.000Z',
            updatedAt: '2024-08-26T10:10:37.000Z',
            createdBy: null,
            updatedBy: null,
          },
          {
            commentHistoryId: 5066,
            addressId: 735,
            ecomOrderId: 1078,
            commentBy: 'System',
            comment: 'Order status has been set to In-Transit',
            image: null,
            isActive: 1,
            createdAt: '2024-08-26T10:11:07.000Z',
            updatedAt: '2024-08-26T10:11:07.000Z',
            createdBy: null,
            updatedBy: null,
          },
          {
            commentHistoryId: 5067,
            addressId: 735,
            ecomOrderId: 1078,
            commentBy: 'System',
            comment: 'Shipment status has been set to delivered',
            image: '471',
            isActive: 1,
            createdAt: '2024-08-26T10:12:53.000Z',
            updatedAt: '2024-08-26T10:12:53.000Z',
            createdBy: null,
            updatedBy: null,
          },
        ],
        products: [
          {
            productId: 43,
            productName: 'Broken Hearts',
            patientId: 471,
            eCommOrderId: 1078,
            quantity: '1',
            unitPrice: '2.00',
          },
        ],
        totalShipmentAmount: 2,
        taxAmount: 0.04,
        totalAmountwithShipment: 4,
      },
    ]
  return (
    <div className='dashboard-card container-fluid pb-3'>
     {modalHeader === "ecommerceOrder"  ? <EcommerceModal
        isLoading={modalLoader}
        isVisible={openModal}
        setIsVisible={setOpenModal}
        subOrdersData={ecomOrderResp?.addresses}
        totalProductAmount={ecomOrderResp?.totalProductAmount}
        mainOrderGrandTotal={ecomOrderResp?.grandTotal}
        ecomOrderNo={ecomOrderResp?.ecomOrderNo}
        ecomOrderId={ecomOrderResp?.ecomOrderId}
        ecomOrderCreatedData={ecomOrderResp?.createdAt}
        commentsData={commentsData}
        totalTax={ecomOrderResp?.totalTax}
        patientName={ecomOrderResp?.patientName}
      /> : <SearchOrderModals isVisible={openModal} setIsVisible={setOpenModal} modalData={modalData} header={modalHeader}/>}
      <div className='row p-0 pb-4'>
        <div className='for-before col-12 col-md-7 head fs-3 fw-bold' style={{ backgroundColor: '#a7c1d9', borderBottomRightRadius: '10px', color: '#FFFFFF' }}>
          Search Order
        </div>
      </div>

      <SearchBar onFinish={onSearchFinish} onFinishFailed={onSearchFinishFailed} searchItems={SearchItems} onChange={handleOnChange} />

      {searchOrderObject.length > 0 ? (
        <div className='row px-3'>
          <SearchOrderTable tableHeaders={searchTableHeaders} tableBody={searchOrderObject} searchOrderTable={true} handleClick={handleClick} />
        </div>
      ) : (
        ''
      )}

      {/* {searchOrderObject.length > 0 ? (
        <div className='row px-3'>
          {searchOrderObject.map((data, idx) => (
            <div className='col-12' key={idx}>
              <div className='d-flex flex-column '>
                {<h1>{data?.heading?.charAt(0).toUpperCase() + data?.heading?.slice(1)?.replace(/_/g, ' ')}</h1>}
                <SearchOrderTable tableName={data?.heading} tableHeaders={data?.tableHeader} tableBody={data?.tableBody}/>
              </div>
            </div>
          ))}
        </div>
      ) : (
        ''
      )} */}

      {boxNumberError && (
        <div className='row px-3 pb-1'>
          <div className='col-12'>
            <small
              style={{
                color: 'crimson',
                fontWeight: '500',
                fontSize: '0.8rem',
              }}
            >
              {boxNumberError}
            </small>
          </div>
        </div>
      )}
      {orderResponseData?.accessionNo && orderResponseData?.orderStatusId === 2 && (
        <div className='row px-3 '>
          <div className='col-12'>
            <span className='text-success font-bold'>The test order has already been sent to the lab.</span>
          </div>
        </div>
      )}

      {loader && <LoaderImage />}

      {orderResponseData && (
        <div className='px-3'>
          <div className='row mt-4'>
            {/* <div className='row'> */}
            <div className='col-12 fw-bold fs-5 mt-0 mb-0 pt-0 pb-0 pl-3'>{orderResponseData?.orderNo}</div>
            {/* <div className='col-12 mt-0 mb-0 pt-2 pb-0 pl-3'>{moment(orderResponseData?.orderDate).format('MM-DD-YYYY')}</div> */}
            {/* </div> */}
            {/* <div className='row'> */}
            <div className='col-12 mt-0 mb-0 pt-0 pb-0 pl-3'>{orderResponseData?.patientName}</div>
            <div className='col-12  mt-0 mb-0 pt-0 pb-0 pl-3'>{orderResponseData?.mobile}</div>

            {/* <div className='col-lg-6  mt-0 mb-0 pt-0 pb-0 pl-0 text-right'>{orderResponseData?.statusName}</div> */}
            {/* </div> */}
            {/* <div className='row'>
              
            </div> */}
          </div>
          <div
            className='row table-scrollbar mr-0'
            style={{
              overflowX: 'auto',
            }}
          >
            <table id='customers'>
              <thead>
                <tr
                  style={{
                    fontWeight: 'bold',
                  }}
                >
                  {/* <th>Partner Name</th> */}
                  <th>Product Code</th>
                  <th>Product Name </th>
                  <th>Panel Code</th>
                  <th>Panel Name</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  {/* <td>{orderResponseData?.partnerName}</td> */}
                  <td>{orderResponseData?.productCode}</td>
                  <td>{orderResponseData?.productName}</td>
                  <td>{orderResponseData?.cisPanelCode}</td>
                  <td>{orderResponseData?.cisPanelName}</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div style={{ display: 'none' }}>
            <div ref={componentRef} className='checkorderprint'>
              <div className='d-flex align-items-center justify-content-between'>
                <small>{concatName()}</small>
                {/* <small>{orderReceiveData ? orderReceiveData?.gender : orderResponseData?.gender}</small> */}
                <small>{orderResponseData?.gender}</small>
                {/* <small>{orderReceiveData ? orderReceiveData?.cisPanelCode : orderResponseData?.cisPanelCode}</small> */}
                <small>{orderResponseData?.cisPanelCode}</small>
              </div>
              <div className='d-flex align-items-center justify-content-between'>
                {/* <small>{orderReceiveData ? moment(orderReceiveData?.dateOfBirth).format('MM-DD-YYYY') : moment(orderResponseData?.dateOfBirth).format('MM-DD-YYYY')}</small> */}
                <small>{moment(orderResponseData?.dateOfBirth).format('MM-DD-YYYY')}</small>
                {/* <small>{orderReceiveData ? moment(orderReceiveData?.orderDate).format('MM-DD-YY hh:mm') : moment(orderResponseData?.orderDate).format('MM-DD-YY hh:mm')}</small> */}
                <small>{moment(orderResponseData?.collectionTime).format('MM-DD-YY hh:mm')}</small>
              </div>
              {/* <div className='d-flex align-items-center justify-content-between'></div> */}
              <div className='d-flex align-items-center justify-content-center'>
                {/* <Barcode margin={0} width={1.5} fontSize={14} height={31} value={orderReceiveData ? orderReceiveData?.accessionNo : orderResponseData?.accessionNo} /> */}
                <Barcode margin={0} width={1.5} fontSize={14} height={31} value={orderResponseData?.accessionNo} />
              </div>
            </div>
          </div>

          <div className='row'>
            <div className='text-right col-md-12'>
              {orderResponseData?.accessionNo && orderResponseData?.orderStatusId === 2 ? (
                <button variant='primary' className='btn btn-primary' onClick={handleRePrint}>
                  {/* Re Print Label  */}
                  Print Accession No.
                </button>
              ) : (
                <button
                  variant='primary'
                  // disabled={orderResponseData?.accessionNo && orderResponseData?.orderStatusId === 2}
                  className='btn btn-primary'
                  onClick={onReceiveOrder}
                >
                  {/* Generate & Print Label  */}
                  Print Accession No.
                </button>
              )}
              {/* <button
                variant='primary'
                className='btn btn-primary'
                onClick={onReceiveOrder}
                disabled={orderResponseData.isReceiveOrder}
                
              >
                Receive
              </button> */}

              {/* <button
                variant='primary'
                className='btn btn-primary'
                onClick={handlePrint}
                style={{
                  margin: '0px 20px',
                }}
                disabled={!orderReceiveData && !orderResponseData.isReceiveOrder}
              >
                Print Label
              </button> */}

              {/* {orderResponseData.orderStatusId === 1 ? (
                <button variant='primary' className='btn btn-primary' disabled={!orderReceiveData && !orderResponseData.isReceiveOrder} onClick={handleOpen}>
                  Send To Lab
                </button>
              ) : (
                <button variant='primary' className='btn btn-primary' disabled={true}>
                  Send To Lab
                </button>
              )} */}
            </div>
          </div>
        </div>
      )}

      {/* <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Order - Send To Lab</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            initialValues={{
              remember: true,
            }}
            className='row'
            onFinish={sendToLab}
            onFinishFailed={() => {}}
            autoComplete='off'
          >
            <div className='col-lg-12'>
              <label>Enter Comments</label>
              <Form.Item name='comments'>
                <TextArea placeholder='Enter Comments' className='admin' style={{ width: '100% !important' }} />
              </Form.Item>
            </div>

            <div className='col-lg-12 text-center'>
              <button variant='success' className='dashboard col-lg-6 btn btn-primary' htmlType='submit'>
                Send To Lab
              </button>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button className='col-lg-2 btn-style' variant='secondary' onClick={handleClose}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal> */}

      {/* <Modal show={accessionNo} onHide={handleAccClose}>
        <Modal.Body>
          <div className='container-fluid'>
            <div className='row'>
              <label className='col-md-6 d-flex align-items-center' style={{ backgroundColor: '#fff', fontWeight: '500', fontSize: '1.8rem' }}>
                Accession Code
              </label>
              <input className='col-md-6 ' disabled value={orderReceiveData ? orderReceiveData?.accessionNo : orderResponseData?.accessionNo} style={{ backgroundColor: '#fff', fontWeight: '700', fontSize: '2rem' }} />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className='container-fluid'>
            <div className='row'>
              <Button className='offset-md-4 col-lg-4 btn-style' variant='secondary' onClick={handleAccClose}>
                Close
              </Button>
            </div>
          </div>
        </Modal.Footer>
      </Modal> */}

      <Modal show={sendToLabResp} onHide={handleSendToLabResp}>
        <Modal.Body>
          <div className='container-fluid'>
            <div className='row'>
              <input className='col-12 text-center' disabled value={sendToLabResp} style={{ backgroundColor: '#fff', fontWeight: '500', fontSize: '1.5rem' }} />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className='container-fluid'>
            <div className='row'>
              <Button className='offset-md-4 col-lg-4 btn-style' variant='secondary' onClick={handleSendToLabResp}>
                Close
              </Button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default SearchOrder;
