import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { app } from '../../config';
import getHeaders from '../../utils/authHeaders';
import ListinngCard from '../../layouts/ListingCard';
import { Pagination, Input, Form, Select, Row, Col, DatePicker, Button, message } from 'antd';

const StockBalancereport = () => {
  const [tableHeaders, setTableHeaders] = useState([]);
  const [loader, setLoader] = useState(false);
  const [renderList, setrenderList] = useState(false);
  const [searchShow, setSearchShow] = useState({});



 const stockReportListingApi = async () => {
   setLoader(true);

   let productResp = await axios.get(`${app.URL}/api/v1/admin/reports/view/4`, { headers: getHeaders() });
  console.log(productResp?.data?.data);
   productResp?.data?.data? setrenderList(productResp.data.data):'';
   productResp.data.data && setLoader(false);
 };
  



  useEffect(() => {
    // const user = JSON.parse(localStorage.getItem('user'));
    let header = [ 'Product Code','Product Name','Stock Balance'];
    setTableHeaders(header);

    stockReportListingApi();
  }, []);





  return (
    <div>
      <ListinngCard
        heading='Report Management'
        // buttonName='Add Product'
        // addButton={addProduct}


        // onSearch={onFinish}  
        // onSearchFailed={onFinishFailed}
        // searchItems={searchItems}




        tableHeaders={tableHeaders}
        tableRows={renderList}
        // editButton={editProduct}   --------    conditional rendering ask?

        // will use

        // onPageChange={onChange}
        // currentPage={currentPage}
        // pagesCount={pagesCount}

        patientsListing={false}
        inventoryListing={false}
        userListings={false}
        storeListing={false}
        productListing={false}
        stockBalanceListing={true}
        // searchValue={searchData}




        // onChange={handleOnChange}
        loader={loader}
      />
    </div>
  );
}

export default StockBalancereport
