import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { app } from '../../config';
import { useNavigate } from 'react-router-dom';
import { Pagination, Input, Form, Select, Row, Col, DatePicker, Button, message } from 'antd';
import { useTranslation } from 'react-i18next';
import Modal from 'react-bootstrap/Modal';
import { EditTwoTone, BranchesOutlined } from '@ant-design/icons';
import getHeaders from '../../utils/authHeaders';
import ListinngCard from '../../layouts/ListingCard';

function PorductsListing() {
  const { t } = useTranslation(['common']);
  let navigate = useNavigate();
  const [editbtn, setEditBtn] = useState('none');
  const [pageSize, setPageSize] = useState(10);
  const [fetchUsers, setFetchPartners] = useState([]);
  const [pagesCount, setPagesCount] = useState(0);
  const [searchShow, setSearchShow] = useState({});
  const [renderList, setRenderList] = useState();
  const [tableShow, setTableShow] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [err, setErr] = useState('');
  // const [show, setShow] = useState(false);
  // const [storesList, setStoresList] = useState([]);
  const [tableHeaders, setTableHeaders] = useState([]);
  const [searchData, setSearchData] = useState();
  const [loader, setLoader] = useState(false);

  const productListingApi = async (formData) => {
    setLoader(true);
    const productResp = await axios.post(`${app.URL}/api/v1/admin/product/listing`, formData, { headers: getHeaders() });
    productResp.data.result ? setFetchPartners(productResp.data.result.data) : ' ';
    productResp.data.result ? setPagesCount(productResp.data.result.data.count) : ' ';
    productResp.data.result ? setRenderList(productResp.data.result.data.data) : ' ';
    productResp.data.result && setLoader(false);
  };
  
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    let header = ['Product Code', 'Product Name', 'Status', 'Action'];
    setTableHeaders(header);
    if (user.userType === 'A') setEditBtn('block');
    let formData = {
      limit: pageSize,
      offset: 0,
      searchBy: 'name',
      searchTxt: '',
    };
    productListingApi(formData);
  }, []);

  let searchItems = [
    {
      name: 'Product Code',
      value: 'productCode',
    },
    {
      name: 'Product Name',
      value: 'name',
    },
    
  ];
  const onChange = async (page, pageSize) => {
    setPageSize(pageSize);
    setCurrentPage(page);
    if (!page) page = 1;
    let limit = pageSize;

    try {
      let reqObj;
      if (Object.keys(searchShow).length !== 0) {
        reqObj = {
          limit: limit,
          offset: limit * (page - 1),
          searchBy: searchShow.searchBy,
          searchTxt: searchShow.searchTxt,
        };
      } else {
        reqObj = {
          limit: limit,
          offset: limit * (page - 1),
          searchBy: 'firstName',
          searchTxt: '',
        };
      }

      await productListingApi(reqObj);
    } catch (e) {
      console.log('Error');
      if (e.message) setErr(e.message);
      setTableShow(false);
    }
  };

  const onFinish = async (values) => {
    console.log(values);
    setSearchShow(values);
    let formData = {
      limit: 10,
      offset: 0,
      searchBy: values.searchBy,
      searchTxt: values.searchTxt,
    };
    if (!formData.searchTxt) message.error('Please enter search text');
    else await productListingApi(formData);
  };
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const editProduct = (e) => {
    localStorage.setItem('editProductId', JSON.stringify(e.productId));
    navigate('/product/update', { replace: true });
  };
  const addProduct = () => {
    navigate('/product/create', { replace: true });
  };
  // const handleSearchChange = async (e) => {
  //   if (!e.target.value) {
  //     try {
  //       let searchData = {
  //         limit: pageSize,
  //         offset: 0,
  //         searchBy: 'partnerName',
  //         searchTxt: '',
  //       };
  //       await productListingApi(searchData);
  //       setSearchShow({});
  //     } catch (e) {
  //       if (e.message) setErr(e.message);
  //       setTableShow(false);
  //     }
  //   }
  // };

  // Sort the array based on the "active" property
  renderList?.sort((a, b) => {
    console.log(!!a.isActive);
    // Handling null and "1" values
    const isActiveA = a.isActive == 1 ? true : !!a.isActive; // Convert "1" or non-null values to true
    const isActiveB = b.isActive == 1 ? true : !!b.isActive; // Convert "1" or non-null values to true

    // Sort active objects before inactive ones
    return isActiveA == isActiveB ? 0 : isActiveA ? -1 : 1;
  });

  const handleOnChange = async(e) => {
    if (e.target.value.length === 0) {
      // userList();
      setSearchShow({});
      let formData = {
        limit: pageSize,
        offset: 0,
        searchBy: 'name',
        searchTxt: '',
      };
      await productListingApi(formData);
    }
  };

  return (
    <ListinngCard
      heading='Product Management'
      buttonName='Add Product'
      addButton={addProduct}
      onSearch={onFinish}
      onSearchFailed={onFinishFailed}
      searchItems={searchItems}
      tableHeaders={tableHeaders}
      tableRows={renderList}
      editButton={editProduct}
      onPageChange={onChange}
      currentPage={currentPage}
      pagesCount={pagesCount}
      patientsListing={false}
      inventoryListing={false}
      userListings={false}
      storeListing={false}
      productListing={true}
      searchValue={searchData}
      onChange={handleOnChange}
      loader={loader}
    />
  );
}

export default PorductsListing;
