import axios from 'axios';
import { useEffect, useState } from 'react';
import {app} from "../config"
import getHeaders from '../utils/authHeaders';
const getBanner = (id, setUserImage, setUploadedImg) => {
  let reqid = id;
  const [loading, setLoading] = useState(true);
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  useEffect(() => {
    const get = async (id) => {
      try {
        const res = await axios.get(`${app.URL}/api/v1/admin/banner/view/${id}`, { headers: getHeaders() });
        setResponse(res.data.result);
        if (res.data.result.data.image) {
          setUserImage(true)
          setUploadedImg(res.data.result.data.image)
        }
        setLoading(false);
      } catch (err) {
        setError(err);
        setLoading(false);
      }
    };
    get(reqid);
  }, []);

  return { loading, response, error };
};
export default getBanner