import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import getHeaders from '../../utils/authHeaders';
import { app } from '../../config';

import { Pagination, Input, Form, Select, Row, Col, Space, Spin, message, Collapse, DatePicker, Button } from 'antd';
import moment from 'moment';

const commentsData = [
  {
    subOrderNo: '19387389',
    commentDateTime: '10-20-2023 05:10 PM',
    commentText: 'this is a testing comment this is a testing commentthis is a testing comment',
    commentBy: 'Sandesh',
  },
  {
    subOrderNo: '12345678',
    commentDateTime: '11-15-2023 03:30 AM',
    commentText: 'another comment',
    commentBy: 'John',
  },
  {
    subOrderNo: '98765432',
    commentDateTime: '09-05-2023 01:45 PM',
    commentText: 'a different comment',
    commentBy: 'Alice',
  },
  {
    subOrderNo: '56789012',
    commentDateTime: '12-01-2023 08:20 AM',
    commentText: 'yet another comment',
    commentBy: 'Bob',
  },
  {
    subOrderNo: '23456789',
    commentDateTime: '08-10-2023 10:55 PM',
    commentText: 'more comments here',
    commentBy: 'Ella',
  },
];
const MainOrderViewModal = ({ isVisible, setIsVisible, ecomOrderNo, ecomOrderCreatedData, patientName, shipmentTotal, productTotalAmount, subOrdersData, mainOrderTotalAmount, mainOrderShipmentAmount, mainOrderGrandTotal, isLoading, setIsLoading, totalProductAmount, isApiCallAble, ecomOrderId, wareHouseStatus }) => {
  let totalTax = 0;

  const commentTableHeaders = ['Sub Order No.', 'Date & Time', 'Comment By', 'Comment'];

  const [isModalLoading, setIsModalLoading] = useState(false);

  const [commentsData, setCommentsData] = useState([]);
  const [commentsObj, setCommentsObj] = useState({});
  useEffect(() => {
    setCommentsObj({
      ecomOrderId: ecomOrderId,
    });
  }, [ecomOrderId]);
  console.log('commentsObj: ');
  console.log(commentsObj);
  useEffect(() => {
    const getAllMainOrderComments = async () => {
      try {
        let resp = await axios.get(`${app.URL}/api/v1/admin/comment/viewbyorder/${ecomOrderId}`, { headers: getHeaders() });
        console.log('comments respo: ', resp.data.result.data);
        setCommentsData(resp.data.result.data);
        console.log('comments data: ', commentsData);
      } catch (error) {
        console.log('error in getting comments data: ', error);
      }
    };
    getAllMainOrderComments();
  }, [commentsObj]);

  useEffect(() => {
    const getMainOrderData = async () => {
      try {
        // setIsLoading(true);
        console.log('id: ', ecomOrderId);
        console.log('app:', app.URL);
        console.log('headers: ', getHeaders());

        let resp = await axios.get(`${app.URL}/api/v1/admin/ecomorders/view/${ecomOrderId}`, { headers: getHeaders() });

        console.log('order data in modal effect: ', resp.data.result.data);
        subOrdersData = resp.data.result.data?.orderDetails;
        // setIsLoading(false);
      } catch (error) {
        console.log('Error: ', error);
      }
    };

    console.log('call able: ', isApiCallAble);
    // This code will run when the component is mounted or `isVisible` changes to `true`.
    if (isApiCallAble) {
      console.log('loading modal: ', isLoading);
      console.log('data modal: ', subOrdersData);
      console.log('opened');
      getMainOrderData();
      // getAllMainOrderComments();
    }
    // getAllMainOrderComments();
  }, [isApiCallAble]);

  return (
    <Modal
      show={isVisible}
      size='lg'
      onHide={() => {
        setIsVisible(!isVisible);
      }}
      centered
    >
      <Modal.Header
        closeButton={true}
        style={{
          display: 'block',
        }}
      >
        <Modal.Title style={{ display: 'block', padding: '9px 13px 8px 8px', fontSize: '20px' }}>
          <div className='row'>
            <div className='col-lg-6'>{ecomOrderNo}</div>
            <div className='col-lg-6 text-right'>{ecomOrderCreatedData}</div>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ padding: '0px 20px !important' }}>
        {!isModalLoading ? (
          <>
            

            {subOrdersData?.map((subOrder, index) => {
              console.log(subOrder)
              totalTax += Number(subOrder.taxAmount);
              return (
                <div
                  className='p-4 mb-4'
                  style={{
                    border: '1px solid silver',
                    borderRadius: 10,
                  }}
                >
                  <div className='row'>
                    <div className='row'>
                      <div className='col-lg-6 fs-5 mt-0 mb-0 pt-0 pb-0 pl-3'>{subOrder?.title}</div>
                      <div className='col-lg-6 mt-0 mb-0 pt-2 pb-0 pl-3 text-right'>{subOrder?.status}</div>
                    </div>
                    <div className='row'>
                      <div className='col-lg-6  mt-0 mb-0 pt-0 pb-0 pl-3'>
                        {subOrder?.address} {subOrder?.state} {subOrder?.city} {subOrder?.zipCode}
                      </div>
                      {subOrder?.wareHouseStatus && <div className='col-lg-6  mt-0 mb-0 pt-0 pb-0 pl-0 text-right'>Shipment Status: {subOrder?.wareHouseStatus}</div>}
                      {/* <div className='col-lg-6  mt-0 mb-0 pt-0 pb-0 pl-0 text-right'>Warehouse Status: {wareHouseStatus}</div> */}
                    </div>
                    <div className='row'>
                      <div className='col-lg-6  mt-0 mb-0 pt-0 pb-0 pl-3'>{subOrder?.shipmePackageName}</div>
                      {subOrder?.requestId && <div className='col-lg-6  mt-0 mb-0 pt-0 pb-0 pl-0 text-right'>Request Type: {subOrder?.requestType}</div>}
                    </div>
                    <div className='row'>{subOrder?.requestId && <div className='col-lg-12  mt-0 mb-0 pt-0 pb-0 pl-0 text-right'>Request Status: {subOrder?.requestStatus}</div>}</div>
                    {/* <div className='col-lg-6 fs-5 mt-0 mb-0 pt-0 pb-0 pl-3'>{title}</div>
                    <div className='col-lg-6 mt-0 mb-0 pt-2 pb-0 pl-3 text-right'>{status}</div>
                    <div className='col-lg-6  mt-0 mb-0 pt-0 pb-0 pl-3'>{address}</div>
                    <div className='col-lg-6  mt-0 mb-0 pt-0 pb-0 pl-0 text-right'>Warehouse Status: {wareHouseStatus}</div>
                    <div className='col-lg-6  mt-0 mb-0 pt-0 pb-0 pl-3'>{shipmePackageName}</div>

                    {
                        requestId && (
                            <>
                                <div className='col-lg-6  mt-0 mb-0 pt-0 pb-0 pl-0 text-right'>Request Type: {requestType}</div>
                                <div className='col-lg-12  mt-0 mb-0 pt-0 pb-0 pl-0 text-right'>Request Status: {requestStatus}</div>
                            </>
                        )
                    } */}
                  </div>
                  {/* <div className='row'>
                                    <div className='col-lg-6 fs-5 mt-0 mb-0 pt-0 pb-0 pl-3'>{subOrder.title}</div>
                                    <div className='col-lg-6 mt-0 mb-0 pt-2 pb-0 pl-3 text-right'>{subOrder.status}</div>
                                    <div className='col-lg-6  mt-0 mb-0 pt-0 pb-0 pl-3'>{`${subOrder.address}, ${subOrder.state}, ${subOrder.city}, ${subOrder.zipCode}`}</div>
                                    <div className='col-lg-6 mt-0 mb-0 pt-0 pb-0 pl-3 text-right'>{subOrder.shipmePackageName}</div>

                                    {
                                        subOrder.requestId && (
                                            <>
                                                <div className='col-lg-12  mt-0 mb-0 pt-0 pb-0 pl-0 text-right'>Request Type: {subOrder.requestType}</div>
                                                <div className='col-lg-12  mt-0 mb-0 pt-0 pb-0 pl-0 text-right'>Request Status: {subOrder.requestStatus}</div>
                                            </>
                                        )
                                    }

                                </div> */}
                  <div className='mb-4'>
                    <table id='customers' className='w-100'>
                      <thead>
                        <tr
                          style={{
                            fontWeight: 'bold',
                          }}
                        >
                          <th>Product Name </th>
                          <th>Quantity</th>
                          <th>Unit Price</th>
                          <th>Total</th>
                        </tr>
                      </thead>
                      <tbody>
                        {subOrder?.products?.map((product, index) => {
                          return (
                            <tr>
                              <td style={{ width: '100px' }}>{product.productName}</td>
                              <td style={{ width: '100px' }}>{product.quantity}</td>
                              <td style={{ width: '100px' }}>${Number(product.unitPrice).toFixed(2)}</td>
                              <td style={{ width: '100px' }}>${(parseFloat(product.quantity) * parseFloat(product.unitPrice)).toFixed(2)}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                    <div className='row'>
                      <div className='col-lg-12  mt-0 mb-0 pt-0 pb-0 pl-3 text-right'>Total Product Amount: ${subOrder.totalShipmentAmount?.toFixed(2)}</div>
                      <div className='col-lg-12  mt-0 mb-0 pt-0 pb-0 pl-3 text-right'>Tax: ${subOrder.taxAmount.toFixed(2)}</div>
                      <div className='col-lg-12  mt-0 mb-0 pt-0 pb-0 pl-3 text-right'>Shipment Charges: {Number(subOrder.shipmentCharges) <= 0 ? 'Free' : `$${Number(subOrder.shipmentCharges)?.toFixed(2)}`} </div>
                      <div className='col-lg-12  mt-0 mb-0 pt-0 pb-0 pl-3 text-right'>Grand Total: ${(subOrder.totalAmountwithShipment + subOrder.taxAmount).toFixed(2)}</div>
                    </div>
                  </div>

                  {/* <div className='row'>
                                    <div className='col-lg-12  mt-0 mb-0 pt-0 pb-0 pl-3 text-right'>Total Amount: ${product.totalShipmentAmount}</div>
                                    <div className='col-lg-12  mt-0 mb-0 pt-0 pb-0 pl-3 text-right'>Total Tax: ${product.taxAmount.toFixed(2)}</div>
                                    <div className='col-lg-12  mt-0 mb-0 pt-0 pb-0 pl-3 text-right'>Shipment Amount: ${product.shipmentCharges} </div>
                                    <div className='col-lg-12  mt-0 mb-0 pt-0 pb-0 pl-3 text-right'>Grand Total: ${(product.totalAmountwithShipment + product.taxAmount).toFixed(2)}</div>
                                </div> */}
                </div>
              );
            })}

            <div className='row mb-6'>
              {totalProductAmount ? <div className='col-lg-12  mt-0 mb-0 pt-0 pb-0 pl-3 text-right'>Total Product Amount: ${totalProductAmount?.toFixed(2)}</div> : <div className='col-lg-12  mt-0 mb-0 pt-0 pb-0 pl-3 text-right'>Total Amount: $ {mainOrderTotalAmount?.toFixed(2)}</div>}
              {/* <div className='col-lg-12  mt-0 mb-0 pt-0 pb-0 pl-3 text-right'>Total Amount: $ {mainOrderTotalAmount}</div> */}
              <div className='col-lg-12  mt-0 mb-0 pt-0 pb-0 pl-3 text-right'>Total Tax: ${totalTax.toFixed(2)}</div>
              <div className='col-lg-12  mt-0 mb-0 pt-0 pb-0 pl-3 text-right'>Shipment Charges: {mainOrderShipmentAmount != 0 ? `$${mainOrderShipmentAmount?.toFixed(2)}` : 'Free'}</div>
              <div className='col-lg-12  mt-0 mb-0 pt-0 pb-0 pl-3 text-right'>Grand Total: ${mainOrderGrandTotal?.toFixed(2)}</div>
            </div>
            <div
              className='row mt-2'
              style={{
                border: '1px solid silver',
                borderRadius: 10,
                // height:"300px"
              }}
            >
              <div className='col-lg-6 fs-5 mt-0 mb-0 pt-0 pb-0 pl- mt-2'>History</div>

              <div
                style={{
                  maxHeight: '200px',
                  overflowY: 'auto',
                }}
              >
                <table
                  className='table'
                  style={{
                    width: '100%',
                    borderCollapse: 'collapse',
                  }}
                >
                  <thead
                    className=''
                    style={{
                      backgroundColor: '#f2b544',
                      color: '#fff',
                    }}
                  >
                    <tr
                      style={{
                        fontWeight: 'bold',
                      }}
                    >
                      {commentTableHeaders.map((header, index) => {
                        return (
                          <th
                            style={{
                              textAlign: 'center',
                              padding: '8px',
                              fontSize: '13px',
                            }}
                          >
                            {header}
                          </th>
                        );
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    {commentsData?.map((commentItem, index) => {
                      return (
                        <tr
                          key={index}
                          style={{
                            backgroundColor: index % 2 == 0 ? '' : '#dddddd',
                          }}
                        >
                          <td
                            style={{
                              width: '30px',
                              textAlign: 'center',
                              padding: '8px',
                              fontSize: '13px',
                            }}
                          >
                            {`${commentItem.ecomOrderNo}-${commentItem.addressId}`}
                          </td>
                          <td style={{ width: '50px', textAlign: 'center', padding: '8px', fontSize: '13px' }}>{moment(commentItem.createdAt).format('MM-DD-YYYY HH:mm')}</td>

                          <td
                            style={{
                              width: '20px',
                              textAlign: 'center',
                              padding: '8px',
                              fontSize: '13px',
                            }}
                          >
                            {commentItem.commentBy}
                          </td>

                          <td style={{ width: '100px', textAlign: 'center', padding: '8px', fontSize: '13px' }}>{commentItem.comment}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </>
        ) : (
          <div className='row text-center'>
            <span>
              {' '}
              <Space size='middle'>
                <Spin size='large' />
              </Space>
            </span>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <button
          variant='primary'
          className='btn btn-primary'
          onClick={() => {
            setIsVisible(!isVisible);
          }}
        >
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default MainOrderViewModal;
