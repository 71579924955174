import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import useFetchPermissions from '../../hooks/fetchPermissions';
import { app } from '../../config';
import { Checkbox, Form, Input, Switch, Col, Row, message } from 'antd';
import { LeftCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import getHeaders from '../../utils/authHeaders';
import { InputField } from '../../layouts/FormFields';

const UserPermissions = () => {
  const { t } = useTranslation(['common']);
  const [checkedList, setCheckedList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState('');
  let navigate = useNavigate();
  let rolePermissions = useFetchPermissions();
  let permissions = rolePermissions.response ? rolePermissions.response.data : [];
  let roleId;
  let permLabel = [];
  let orgPermLabel = [];
  let gnrPermLabel = [];
  let appPermLabel = [];
  let webPermLabel = [];
  if (permissions.length > 0)
    permissions.forEach((ele) => {
      if (ele.permType === 'adm') permLabel.push(ele.label);
      else if (ele.permType === 'org') orgPermLabel.push(ele.label);
      else if (ele.permType === 'gnr') gnrPermLabel.push(ele.label);
      else if (ele.permType === 'app') appPermLabel.push(ele.label);
      else if (ele.permType === 'web') webPermLabel.push(ele.label);
    });
  permLabel = [...new Set(permLabel)];
  orgPermLabel = [...new Set(orgPermLabel)];
  gnrPermLabel = [...new Set(gnrPermLabel)];
  appPermLabel = [...new Set(appPermLabel)];
  webPermLabel = [...new Set(webPermLabel)];


  let user = JSON.parse(localStorage.getItem('user'));


  const uploadData = async (uploadObj) => {
    setLoading(true);
    let userId = user?.result.data.user.userId;
    uploadObj.userId = userId;
    try {
      const res = await axios.post(`${app.URL}/api/v1/rolePermission/role/addrole`, { name: uploadObj.role, roleType: 'O', isActive: uploadObj.isActive, userId:uploadObj.userId}, { headers: getHeaders() });
      res ? (roleId = res.data.result.data.roleId) : console.log('error');
      const rolePermRes = await axios.post(`${app.URL}/api/v1/rolePermission/rolePerm/addrolepermission`, { roleId: roleId, perms: uploadObj.list }, { headers: getHeaders() });
      setLoading(false);
      if (res.data.result.code === 200) {
        message.success(res.data.result.msg);
        navigate('/role/rolelisting');
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };
  let uploadObj = { role: ' ', isActive: 1, list: [] };
  const onFinish = (values) => {
    uploadObj.role = values.role;
    uploadObj.isActive = values.isActive;
    uploadObj.list = checkedList;
    
    if (Object.keys(uploadObj).length > 0 && uploadObj.list.length > 0) {
      console.log(true);
      uploadData(uploadObj);
    } else {
      message.error('Kindly select atleast one permission');
    }
  };
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  const onChange = (checkedValues) => {
    checkedValues = checkedValues.map((item) => {
      let perms = {};
      perms.permId = item;
      return perms;
    });
    setCheckedList(checkedValues);
  };
  function goBack() {
    navigate('/role/rolelisting', { replace: true });
  }
  window.addEventListener('popstate', goBack);

  return (
    <div className='dashboard-card container-fluid pb-5'>
      <div className='row p-0 pb-4'>
        <div className='for-before col-12 col-md-7 head fs-3 fw-bold' style={{ backgroundColor: '#a7c1d9', borderBottomRightRadius: '10px', color: '#FFFFFF' }}>
          Add Role
        </div>
      </div>

      <Form
        initialValues={{
          remember: true,
          ['id']: 1,
          // ['causeCategory']: `${causeCat.length > 0 ? causeCat[0].cause_category : ''}`,
          ['isActive']: true,
        }}
        className='row p-5 px-3 pb-1'
        name='basic'
        // style={{
        //   maxWidth: 600,
        // }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete='off'
      >
        <div className='col-12 input-column'>
          <div className='row'>
            <InputField
              col='col-md-4'
              name='role'
              rules={[
                {
                  required: true,
                  message: 'Enter Role',
                },
              ]}
              required={true}
              label='Enter Role'
              placeholder='Enter Role'
            />
            <Form.Item className='col-md-4 pt-md-4' label='Active' valuePropName='checked' colon={false} name='isActive'>
              <Switch defaultChecked={true} />
            </Form.Item>
          </div>
          <hr className='fade-effect1 mb-6' />
          <Checkbox.Group
            // defaultValue={perms}
            className='row'
            style={{
              // width: '100%',
              height: '300px',
              overflow: 'auto',
              marginBottom: '2rem',
            }}
            onChange={onChange}
          >
            <div className='col-lg-6 '>
              <div className='col-12 col-md-12 mb-4'>
                <div className='permission-main yellow-main'>
                  <h1 className='permission-header yellow-head'>Admin Permissions</h1>
                  <div className='content-div'>
                    {permLabel.map((element) => {
                      return (
                        <>
                          {
                            <>
                              <h2 class='fw-bold mb-2 mt-3' style={{ textTransform: 'capitalize' }}>
                                {element}
                              </h2>
                              <div>
                                <Row justify='start'>
                                  {permissions.map((ele) => {
                                    // console.log(ele.permType)
                                    if (ele.label === element && ele.permType === 'adm') {
                                      // console.log(ele)
                                      return (
                                        <div>
                                          <Col span={8}>
                                            {' '}
                                            <Checkbox className='yellow-checkbox' checked={true} value={ele.permId}>
                                              {ele.name}
                                            </Checkbox>
                                          </Col>
                                        </div>
                                      );
                                    }
                                  })}
                                </Row>
                              </div>
                            </>
                          }
                        </>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
            <div className='col-lg-5'>
              <div className='col-12 col-md-12 mb-4'>
                <div className='permission-main'>
                  <h1 className='permission-header'>General Permissions</h1>
                  <div className='content-div'>
                    {gnrPermLabel.map((element) => {
                      return (
                        <>
                          {
                            <>
                              <h2 class='fw-bold mb-2 mt-3' style={{ textTransform: 'capitalize' }}>
                                {element}
                              </h2>
                              <div>
                                <Row justify='start'>
                                  {permissions.map((ele) => {
                                    // console.log(ele.permType)
                                    if (ele.label === element && ele.permType === 'gnr') {
                                      // console.log(ele)
                                      return (
                                        <div>
                                          <Col span={8}>
                                            {' '}
                                            <Checkbox className='red-checkbox' checked={true} value={ele.permId}>
                                              {ele.name}
                                            </Checkbox>
                                          </Col>
                                        </div>
                                      );
                                    }
                                  })}
                                </Row>
                              </div>
                            </>
                          }
                        </>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className='col-12 col-md-12 mb-4'>
                <div className='permission-main yellow-main'>
                  <h1 className='permission-header yellow-head'>App Permissions</h1>
                  <div className='content-div'>
                    {appPermLabel.map((element) => {
                      return (
                        <>
                          {
                            <>
                              <h2 class='fw-bold mb-2 mt-3' style={{ textTransform: 'capitalize' }}>
                                {element}
                              </h2>
                              <div>
                                <Row justify='start'>
                                  {permissions.map((ele) => {
                                    // console.log(ele.permType)
                                    if (ele.label === element && ele.permType === 'app') {
                                      // console.log(ele)
                                      return (
                                        <div>
                                          <Col span={8}>
                                            {' '}
                                            <Checkbox className='yellow-checkbox' checked={true} value={ele.permId}>
                                              {ele.name}
                                            </Checkbox>
                                          </Col>
                                        </div>
                                      );
                                    }
                                  })}
                                </Row>
                              </div>
                            </>
                          }
                        </>
                      );
                    })}
                  </div>
                </div>
              </div>

              <div className='col-12 col-md-12'>
                <div className='permission-main yellow-main'>
                  <h1 className='permission-header yellow-head'>Web Permissions</h1>
                  <div className='content-div'>
                    {webPermLabel.map((element) => {
                      return (
                        <>
                          {
                            <>
                              <h2 class='fw-bold mb-2 mt-3' style={{ textTransform: 'capitalize' }}>
                                {element}
                              </h2>
                              <div>
                                <Row justify='start'>
                                  {permissions.map((ele) => {
                                    // console.log(ele.permType)
                                    if (ele.label === element && ele.permType === 'web') {
                                      // console.log(ele)
                                      return (
                                        <div>
                                          <Col span={8}>
                                            {' '}
                                            <Checkbox className='yellow-checkbox' checked={true} value={ele.permId}>
                                              {ele.name}
                                            </Checkbox>
                                          </Col>
                                        </div>
                                      );
                                    }
                                  })}
                                </Row>
                              </div>
                            </>
                          }
                        </>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </Checkbox.Group>

          <div className='row'>
            <button onClick={goBack} type='primary' className='btn btn-primary button patient-cancel-btn col-md-3 mr-2 mb-2'>
              Cancel
            </button>

            <button disabled={loading} type='primary' class='btn btn-primary button col-md-3 mb-2' htmlType='submit'>
              {loading ? <LoadingOutlined /> : 'Save'}
            </button>
          </div>
        </div>

        {/* <div className='mt-4'>
          <button
            onClick={() => {
              navigate('/role/rolelisting');
            }}
            type='primary'
            className='btn btn-primary button patient-cancel-btn col-lg-3 mr-2'
          >
            Cancel
          </button>

          <button type='primary' className='btn btn-primary button ml-5 col-lg-3' htmlType='submit'>
            Save
          </button>
        </div> */}
      </Form>
    </div>
  );
};

export default UserPermissions;
