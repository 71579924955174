import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
const Unauthorized = () => {
  const { t } = useTranslation(['common']);
  const navigate = useNavigate();

  const goBack = () => navigate(-1);

  return (
    <div className='colorbg' style={{ height: '720px' }}>
      <div class='row'>
        <div class='col-12 d-flex justify-content-center text-center' style={{ height: '150px' }}></div>
        <div class='col-12 d-flex justify-content-center text-center fw-bold text-xl'>{t('OOPS! You are not allowed to visit this page')}</div>
        <div class='col-12 d-flex justify-content-center text-center fw-bold text-xl'>
          <img src='/404.png' />
        </div>
        <div class='col-12 d-flex justify-content-center text-center '>
          <button className='btn button color1 text-white' onClick={goBack}>
            {t('Go Back')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Unauthorized;
