import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { app } from '../../config';
import { useNavigate } from 'react-router-dom';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';
import getHeaders from '../../utils/authHeaders';
import ListinngCard from '../../layouts/ListingCard';

function Users() {
  const { t } = useTranslation(['common']);
  let navigate = useNavigate();
  let searchedUser;
  const [editbtn, setEditBtn] = useState('none');
  const [search, setSearch] = useState('');
  const [pageSize, setPageSize] = useState(10);
  const [fetchUsers, setFetchUsers] = useState([]);
  const [pagesCount, setPagesCount] = useState(0);
  const [searchShow, setSearchShow] = useState({});
  const [renderList, setRenderList] = useState();
  const [tableShow, setTableShow] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [tableHeaders, setTableHeaders] = useState([]);
  const [searchData, setSearchData] = useState();
  const [err, setErr] = useState('');
  const [loader, setLoader] = useState(false);

  const userListingApi = async (formData) => {
    setLoader(true);
    const userResp = await axios.post(`${app.URL}/api/v1/admin/adminAccount/userListing`, formData, { headers: getHeaders() });
    userResp.data.result ? setFetchUsers(userResp.data.result.data) : ' ';
    userResp.data.result ? setPagesCount(userResp.data.result.data.count) : ' ';
    userResp.data.result ? setRenderList(userResp.data.result.data.data) : ' ';
    userResp.data.result && setLoader(false);
  };

  let formData = {
    limit: pageSize,
    offset: 0,
    searchBy: '',
    searchTxt: '',
  };
  useEffect(() => {
    setLoader(true);
    const userList = async () => {
      const user = JSON.parse(localStorage.getItem('user'));
      let header = ['Full Name', 'Email', 'Mobile', 'Role', 'Status', 'Action'];
      setTableHeaders(header);
      if (user.userType === 'A') setEditBtn('block');

      await userListingApi(formData);
    };
    userList();
  }, []);
  let searchItems = [
    {
      name: 'Name',
      value: 'firstName',
    },
    {
      name: 'Email',
      value: 'email',
    },
    {
      name: 'Mobile',
      value: 'mobile',
    },
  ];

  const onChange = async (page, pageSize) => {
    setPageSize(pageSize);
    setCurrentPage(page);
    if (!page) page = 1;
    let limit = pageSize;
    try {
      let reqObj;
      if (Object.keys(searchShow).length !== 0) {
        reqObj = {
          limit: limit,
          offset: limit * (page - 1),
          searchBy: searchShow.searchBy,
          searchTxt: searchShow.searchTxt,
        };
      } else {
        reqObj = {
          limit: limit,
          offset: limit * (page - 1),
          searchBy: 'firstName',
          searchTxt: '',
        };
      }

      await userListingApi(reqObj);
    } catch (e) {
      console.log('Error');
      if (e.message) setErr(e.message);
      setTableShow(false);
    }
  };

  const onFinish = async (values) => {
    setSearchShow(values);
    let formData = {
      limit: 10,
      offset: 0,
      searchBy: values.searchBy,
      searchTxt: values.searchTxt,
    };
    if (!formData.searchTxt) message.error('Please enter search text');
    else await userListingApi(formData);
  };
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  // const handleSearchChange = (e) => {
  //   if (!e.target.value) {
  //     setRenderList(fetchUsers.slice(0, 10));
  //     //  console.log(organizations.length)
  //     setPagesCount(fetchUsers.length);
  //     setTableShow(true);
  //   }
  //   setSearch(e.target.value);
  // };

  const editUser = (e) => {
    localStorage.setItem('getUserId', JSON.stringify(e.userId));
    navigate('/adminaccount/userupdate', { replace: true });
  };
  const addUser = () => {
    navigate('/adminaccount/usercreate', { replace: true });
  };

  console.log(currentPage, pagesCount);

  console.log(renderList);
  const handleOnChange = (e) => {
    if (e.target.value.length === 0) {
      userListingApi(formData);
      setSearchShow({});
    }
  };
  

 

  return (
    <ListinngCard
      heading='User Management'
      buttonName='Add User'
      addButton={addUser}
      onSearch={onFinish}
      onSearchFailed={onFinishFailed}
      searchItems={searchItems}
      tableHeaders={tableHeaders}
      tableRows={renderList}
      editButton={editUser}
      onPageChange={onChange}
      currentPage={currentPage}
      pagesCount={pagesCount}
      patientsListing={false}
      inventoryListing={false}
      // warehouseBatchListing={false}
      storeListing={false}
      productListing={false}
      userListings={true}
      searchValue={searchData}
      onChange={handleOnChange}
      loader={loader}
     
    />
  );
}

export default Users;
