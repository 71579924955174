import axios from 'axios';
import { useEffect, useState } from 'react';
import {app} from '../config'
import getHeaders from '../utils/authHeaders';
let url=`${app.URL}/org/fetchall`;
const getRolePermissionById = (roleId) => {
  const [loading, setLoading] = useState(true);
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async (id) => {
      try {
        const res = await axios.post(`${app.URL}/api/v1/rolePermission/rolePerm/viewRolePermByRole/${id}`, {},{headers: getHeaders()});
        setResponse(res.data.result);
        setLoading(false);
      } catch (err) {
        setError(err);
        setLoading(false);
      }
    };
    fetchData(roleId);
  }, []);

  return { loading, response, error };
};

export default getRolePermissionById;
