import axios from 'axios';
import { useEffect, useState } from 'react';
import { app } from '../config';
import getHeaders from '../utils/authHeaders';
const getUser = async (id, setUser, setUserImage, setUploadedImg) => {
  const [loading, setLoading] = useState(true);
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const get = async (id) => {
      try {
        const res = await axios.get(`${app.URL}/api/v1/admin/adminAccount/userView/${id}`, { headers: getHeaders() });
        console.log(res.data.result.data);
        setResponse(res.data.result);
        setUser(res.data.result.data);
        if (res.data.result.data.image) {
          setUserImage(true);
          setUploadedImg(res.data.result.data.image);
        }

        setLoading(false);
      } catch (err) {
        setError(err);
        setLoading(false);
      }
    };
    get(id);
  }, [id]);

  return { loading, response, error };
};
export default getUser;
