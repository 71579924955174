import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { app } from '../../config';
import CalenderImg from '../../assets/images/calender.png';
import { useNavigate } from 'react-router-dom';
import { Pagination, Input, Form, Select, Row, Col, Space, Spin, message, Collapse, DatePicker } from 'antd';
//Collapse
import { CaretRightOutlined, CaretDownOutlined, LoadingOutlined } from '@ant-design/icons';
import moment from 'moment';
//Collapse End
import { useSSR, useTranslation } from 'react-i18next';

import Modal from 'react-bootstrap/Modal';
import { EditTwoTone, BranchesOutlined } from '@ant-design/icons';
import getHeaders from '../../utils/authHeaders';
import ListinngCard from '../../layouts/ListingCard';
import { dateConverter } from '../../utils/utcDateConversionCommon';
import SubOrderViewModal from './SubOrderViewModal';
import EcommerceDashboardCard from '../../layouts/EcommerceDashboardCard';
import NoDataFound from '../../layouts/NoDataFound';
import OrderRefundModal from './OrderRefundModal';
import EcomDashboardHeader from './EcomDashboardHeader';
import EcommerceCards from './EcommerceCards';
import { SearchBar } from '../../layouts/SearchBar';
import LoaderImage from '../LoaderImage';

import { OverlayTrigger, Tooltip, Button } from 'react-bootstrap';
import ViewOrderModal from './ViewOrderModal';
const { TextArea } = Input;

const Request = () => {
  const { RangePicker } = DatePicker;
  const [activeIndex, setActiveIndex] = useState(1);
  const [cancelShow, setCancelShow] = useState(false);
  const [editbtn, setEditBtn] = useState('none');
  const [pageSize, setPageSize] = useState(10);
  const [refreshPage, setRefreshPage] = useState(false);

  const tooltipText = 'Order payment is not settled yet, Try again after sometime!';
  const renderTooltip = (props) => (
    <Tooltip id='button-tooltip' {...props}>
      {tooltipText}
    </Tooltip>
  );

  const [fetchPartners, setFetchPartners] = useState([]);
  const [subOrderObj, setSubOrderObj] = useState({});
  const [requestObj, setRequestObj] = useState({})
  const [pagesCount, setPagesCount] = useState(0);
  const [searchShow, setSearchShow] = useState({});
  const [renderList, setRenderList] = useState([]);
  const [tableShow, setTableShow] = useState(true);
  const [activeKey, setActiveKey] = useState(['1']);
  const [currentPage, setCurrentPage] = useState(1);
  const [orderData, setOrderData] = useState({});
  const [err, setErr] = useState('');
  const [loader, setLoader] = useState(false);

  const [tableHeaders, setTableHeaders] = useState([]);
  const [show, setShow] = useState(false);
  const [lockModalShow, setLockModalShow] = useState(false);
  const [delBatchResp, setDelBatchResp] = useState({});
  const [lockBatchResp, setLockBatchResp] = useState({});
  const [rejectShow, setRejectShow] = useState(false);
  const [status, setStatus] = useState([]);
  // const [dashboardCardsCounts, setDashboardCardsCounts] = useState({
  //   totalInProgress: 0,
  //   totalInTransit: 0,
  //   totalDelivered: 0,
  //   totalCancelled: 0,
  //   totalReturnClaim: 0,
  // });
  const [cancelData, setCancelData] = useState({});
  const [orderCancelResp, setOrderCancelResp] = useState();
  const [cancelStatus, setCancelStatus] = useState(false);

  const [reciveBackData, setReciveBackData] = useState({});
  const [reciveBackResp, setReciveBackResp] = useState();
  const [reciveBackStatus, setReciveBackStatus] = useState(false);
  const [reciveBackShow, setReciveBackShow] = useState(false);
  const [isRecivedBack, setIsReciveBack] = useState(false);

  const [resendOrderData, setResendOrderData] = useState({});
  const [resendOrderResp, setResendOrderResp] = useState();
  const [resendOrderStatus, setResendOrderStatus] = useState(false);
  const [resendOrderShow, setResendOrderShow] = useState(false);

  const [missingItem, setMissingItem] = useState(false);

  const [showOrderRefund, setShowOrderRefund] = useState(false);
  
  const [modalLoading, setModalLoading] = useState(false);
  const [passedkey, setPassedKey] = useState();

  const [showSingleOrder, setShowSingleOrder] = useState(false);
  const [showSingleRequest, setShowRequest] = useState(false);

  const [viewPassedKey, setViewPassedKey] = useState();

  const [commentData, setCommentData] = useState({});
  const [refreshView, setRefreshView] = useState(false);
  // const [subOrderObj,setSubOrderObj]=useState(false);

  const handleClose = () => setShow(false);
  const handleLockClose = () => setLockModalShow(false);
  const handleRejectModalClose = () => {
    setRejectShow(false);
    setModalLoading(false);
    setLoaderName('');
  };

  let navigate = useNavigate();
  let convertedObj;

  let inProgressHeaders = ['Order No.', 'Date', 'Customer', 'Total Amount', 'Status', 'Action'];
  let inTransitHeaders = ['Order No.', 'Date', 'Customer', 'Total Amount', 'Status', 'Action'];
  let deliveredHeaders = ['Order No.', 'Date', 'Customer', 'Total Amount', 'Status', 'Action'];
  let customerRequestHeaders = ['Request Type', 'Order No.', 'Sub Order No.', 'Customer Name', 'Request Date', 'Request Status', 'Action', ''];
  let cancelledHeaders = ['Order No.', 'Date', 'Customer', 'Total Amount', 'Status', 'Action'];

  let headersMap = new Map();
  headersMap.set(1, inProgressHeaders);
  headersMap.set(2, inTransitHeaders);
  headersMap.set(3, deliveredHeaders);
  headersMap.set(4, customerRequestHeaders);
  headersMap.set(5, cancelledHeaders);

  let statusArr = [];

  useEffect(() => {
    const getState = async () => {
      const statusResp = await axios.get(`${app.URL}/api/v1/admin/ecomorders/orderscount`, { headers: getHeaders() });
      statusResp.data ? console.log(statusResp.data.result.data) : console.log('data');
      statusResp.status == 200 ? setStatus(statusResp.data.result.data) : ' ';
      console.log(statusResp.data.result.data);
    };
    getState();
  }, []);
  console.log( "Status in request", status);

  const user = JSON.parse(localStorage.getItem('user'));
  if (user.userType === 'A') setEditBtn('block');
 
  const getRequestData = async (formData) => {
    console.log('get dataaaaaaaaa called');
    setLoader(true);
    console.log(formData);
    setSearchShow(formData);

    const partnerResp = await axios.post(`${app.URL}/api/v1/admin/userrequest/view`, formData, { headers: getHeaders() });
    console.log(partnerResp);
    partnerResp ? console.log(partnerResp.data.result.data) : ' ';
    partnerResp.data.result ? (convertedObj = await dateConverter(partnerResp.data.result.data.data, 'createdAt')) : ' ';
    partnerResp.data.result ? setFetchPartners(convertedObj) : ' ';
    partnerResp.data.result ? setPagesCount(partnerResp.data.result.data.count) : ' ';
    partnerResp.data.result ? setRenderList(convertedObj) : ' ';
    partnerResp.data.result && setLoader(false);

    setRefreshView(false); // refresh view false so next modal can function properly
    
  };

  useEffect(() => {
    const callGetData = async () => {
      console.log('getDataCalling');
      let formData = {
        limit: pageSize,
        offset: 0,
        searchBy: 'status',
        searchTxt: '',
      };
      getRequestData(formData);
    };
    console.log('refresing view');
    setSubOrderObj({});
    if (refreshView) message.success('Request completed successfully');
    callGetData();
  }, [refreshView]);
  const onRequestChange = async (page, pageSize) => {
    console.log(page, pageSize);
    setPageSize(pageSize);
    setCurrentPage(page);
    if (!page) page = 1;
    let limit = pageSize;
    try {
      let reqObj;
      if (Object.keys(searchShow).length !== 0) {
        reqObj = {
          limit: limit,
          offset: limit * (page - 1),
          searchBy: searchShow.searchFor,
          searchTxt: searchShow.searchItem,
        };
      } else {
        reqObj = {
          limit: limit,
          offset: limit * (page - 1),
          searchBy: 'orderNo.',
          searchTxt: '',
        };
      }
      console.log(reqObj);
      await getRequestData(reqObj);
    } catch (e) {
      console.log('Error');
      if (e.message) setErr(e.message);
      setTableShow(false);
    }
  };
  const onChange = async (page, pageSize) => {
    console.log(page, pageSize);
    setPageSize(pageSize);
    setCurrentPage(page);
    if (!page) page = 1;
    let limit = pageSize;
    try {
      let reqObj;
      if (Object.keys(searchShow).length !== 0) {
        reqObj = {
          limit: limit,
          offset: limit * (page - 1),
          searchBy: searchShow.searchBy,
          searchTxt: searchShow.searchTxt,
        };
      } else {
        reqObj = {
          limit: limit,
          offset: limit * (page - 1),
          searchBy: 'orderNo.',
          searchTxt: '',
        };
      }
      console.log(reqObj);
      await getRequestData(reqObj);
    } catch (e) {
      console.log('Error');
      if (e.message) setErr(e.message);
      setTableShow(false);
    }
  };

  //Approve and Refund Workflow
  const handleCancelModalClose = () => setCancelShow(false);

  const getSingleSubOrder = async (ecomOrderId, addressId,patientId) => {
    console.log('get single sub order');
    console.log(ecomOrderId, addressId);
    try {
      let resp = await axios.get(`${app.URL}/api/v1/admin/ecomorders/vieworder?orderId=${ecomOrderId}&patientId=${patientId}&addressId=${addressId?addressId:""}`, { headers: getHeaders() });
      console.log('View Order Resp', resp);
      console.log(resp);
      let shipmentResp;
      resp ? (shipmentResp = resp.data.result.data) : '';
      return shipmentResp;
    } catch (e) {
      throw e;
    }
  };

    const getRequest = async (requestId) => {
      console.log('get single sub order');
      // console.log(ecomOrderId, addressId);
      try {
        let resp = await axios.get(`${app.URL}/api/v1/admin/userrequest/view/${requestId}`, { headers: getHeaders() });
        console.log('View Order Resp', resp);
        console.log(resp);
        let shipmentResp;
        resp ? (shipmentResp = resp.data.result.data) : '';
        return shipmentResp;
      } catch (e) {
        throw e;
      }
    };

  const getSubOrderComments = async (ecomOrderId, addressId) => {
    console.log('get single sub order');
    console.log(ecomOrderId, addressId);
    try {
      let resp = await axios.get(`${app.URL}/api/v1/admin/comment/viewbysuborder/${ecomOrderId}/${addressId}`, { headers: getHeaders() });
      console.log('resp');
      console.log(resp);
      let commentResp;
      resp ? (commentResp = resp.data.result.data) : '';
      return commentResp;
    } catch (e) {
      throw e;
    }
  };
  // const cancelShipment = async (data) => {
  //     try {
  //         let resp = await axios.post(`${app.URL}/api/v1/admin/userrequest/cancelshipment`, data, { headers: getHeaders() });
  //         console.log("resp");
  //         setOrderCancelResp(resp.data.result);
  //         setCancelStatus(true)
  //         setTimeout(() => {
  //             setCancelShow(false);
  //         }, [3000])
  //         await getData()
  //     }
  //     catch (e) {
  //         throw e
  //     }
  // }

  const [orderPayment, setOrderPayment] = useState('');
  const [loaderName, setLoaderName] = useState('');
  const onCancelTrigger = async (data, key) => {
    console.log('Cancel Trigger');
    setLoaderName('Approve');
    setPassedKey(key);
    console.log(key);
    console.log(data);
    setModalLoading(true);
    const apiResp = await getSingleSubOrder(data.ecomOrderId, data.addressId);
    console.log('apiResp');
    console.log(apiResp);
    let createdAt = moment(apiResp.createdAt).utc().format('DD-MMM-YYYY');
    apiResp.createdAt = createdAt;
    console.log('API REsponse');
    console.log(apiResp);
    apiResp ? setOrderData(apiResp) : '';
    apiResp ? setOrderPayment(data.paymentStatus) : '';
    const commentResp = await getSubOrderComments(data.ecomOrderId, data.addressId);
    console.log('commentResp');
    console.log(commentResp);
    commentResp ? setCommentData(commentResp) : '';
    setModalLoading(false);
    setPassedKey(null);
    setShowOrderRefund(true);
  };
  console.log('commentData');
  console.log(commentData);
  // const onCancelClick = async () => {
  //     console.log(cancelData);
  //     await cancelShipment(cancelData)
  // }
  // Recive back order work flow
  const [rejectData, setRejectData] = useState('');
  const onRejectTrigger = async (data, key) => {
    setPassedKey(key);
    setModalLoading(true);
    setRejectData(data);
    setRejectShow(true);
    setLoaderName('Reject');
  };

  const handleReciveBackModalClose = () => {
    setReceiveBackComment('');

    setReciveBackShow(false);
  };
  const reciveorder = async (data) => {
    
    try {
      let resp = await axios.post(`${app.URL}/api/v1/admin/ecomorders/receiveback`, data, { headers: getHeaders() });
      console.log('resp');
      setReciveBackResp(resp.data.result);
      setReciveBackStatus(true);
      setTimeout(() => {
        setReciveBackShow(false);
      }, 500);
      let formData = {
        limit: pageSize,
        offset: 0,
        searchBy: '',
        searchTxt: '',
      };
      await getRequestData(formData);
    } catch (e) {
      throw e;
    }
  };

  const [receiveBackComment, setReceiveBackComment] = useState('');
  const [missingItemComment, setMissingItemComment] = useState('');
  const [receiveBackKey, setReceiveBackKey] = useState();
  const [resendOrderKey, setResendOrderKey] = useState();
  const [missingItemKey, setMissingItemKey] = useState();
  const [viewRequestLoader, setViewRequestLoader] = useState(false)
  const onReciveBackTrigger = async (data, key) => {
    setReceiveBackKey(key);
    console.log(key);
    console.log(data);
    setModalLoading(true);
    const apiResp = await getSingleSubOrder(data.ecomOrderId, data.addressId);
    console.log('apiResp');
    console.log(apiResp);
    let createdAt = moment(apiResp.createdAt).utc().format('DD-MMM-YYYY');
    apiResp.createdAt = createdAt;
    apiResp ? setOrderData(apiResp) : '';
    const commentResp = await getSubOrderComments(data.ecomOrderId, data.addressId);
    console.log('commentResp');
    console.log(commentResp);
    commentResp ? setCommentData(commentResp) : '';
    setModalLoading(false);
    setReceiveBackKey(null);

    // setReciveBackResp({});
    // let recivebackObj = {
    //     requestId: data.requestId,
    //     patientId: data.patientId,
    //     ecomOrderId: data.ecomOrderId,
    //     addressId: data.addressId,
    //     comment:receiveBackComment,
    // }

    // console.log(recivebackObj);
    setShowOrderRefund(true);
    // setReciveBackData(recivebackObj);
  };
  const onReciveBackClick = async () => {
    console.log(reciveBackData);
    await reciveorder(reciveBackData);
    setReceiveBackComment('');
  };

  //Recive back order workflow end

  //Resend Order Workflow

  const handleResendOrderModal = () => {
    setMissingItemComment('');
    setReceiveBackComment('');
    setResendOrderShow(false);
  };
  const resendOrder = async (data) => {
    try {
      let resp = await axios.post(`${app.URL}/api/v1/admin/ecomorders/resendorder`, data, { headers: getHeaders() });
      console.log('resp');
      setResendOrderResp(resp.data.result);
      setResendOrderStatus(true);
      setTimeout(() => {
        setResendOrderShow(false);
      }, 500);
      let formData = {
        limit: pageSize,
        offset: 0,
        searchBy: '',
        searchTxt: '',
      };
      await getRequestData(formData);
    } catch (e) {
      throw e;
    }
  };
  const onResendOrderTrigger = async (data, key) => {
    setResendOrderKey(key);
    setMissingItemKey(key);
    console.log(key);
    console.log(data);
    setModalLoading(true);
    const apiResp = await getSingleSubOrder(data.ecomOrderId, data.addressId);
    console.log('apiResp');
    console.log(apiResp);
    let createdAt = moment(apiResp.createdAt).utc().format('DD-MMM-YYYY');
    apiResp.createdAt = createdAt;
    apiResp ? setOrderData(apiResp) : '';
    const commentResp = await getSubOrderComments(data.ecomOrderId, data.addressId);
    console.log('commentResp');
    console.log(commentResp);
    commentResp ? setCommentData(commentResp) : '';
    setModalLoading(false);
    setResendOrderKey(null);
    setMissingItemKey(null);
    setShowOrderRefund(true);
  };
  const onResendOrderonClick = async () => {
    console.log(resendOrderData);
    await resendOrder(resendOrderData);

    setMissingItemComment('');
  };

  //Resend Order Work flow End
  console.log(renderList);
  useEffect(() => {
    setTableHeaders(headersMap.get(1));
    let formData = {
      limit: pageSize,
      offset: 0,
      searchBy: '',
      searchTxt: '',
    };
    getRequestData(formData);
  }, [refreshPage]);

  let searchItems = [
    {
      name: 'Order No.',
      value: 'ecomOrderNo',
    },
    {
      name: 'Customer',
      value: 'patientName',
    },
    {
      name: 'Type',
      value: 'requestType',
    },
    {
      name: 'Status',
      value: 'status',
    },
  ];

  const { Panel } = Collapse;
  const customExpandIcon = (panelProps) => {
    const { isActive } = panelProps;

    return <span>{isActive ? <CaretDownOutlined /> : <CaretRightOutlined />}</span>;
  };
  const onFinish = async (values) => {
    console.log(values);
    setSearchShow(values);
    try {
      let searchData = {
        limit: 10,
        offset: 0,
        searchBy: values.searchFor,
        searchTxt: values.searchItem,
      };
      if (!searchData.searchBy) message.error('Select search criteria ');
      if (searchData.searchBy) await getRequestData(searchData);
    } catch (e) {
      console.log('Error');
      if (e.message) setErr(e.message);
      setTableShow(false);
    }
  };
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const onCollapseChange = async (key) => {
    console.log('Collapse change:', key);
    console.log(key);
    setSubOrderObj({});
    const orderResp = await axios.post(`${app.URL}/api/v1/app/ecomorderdetails/view/${key}`, {}, { headers: getHeaders() });
    console.log(orderResp);
    let converArr = [];
    orderResp.data.code == 200 ? converArr.push(orderResp.data.data) : ' ';
    orderResp.data.code == 200 ? (convertedObj = await dateConverter(converArr, 'orderDate')) : ' ';
    orderResp.data.code == 200 ? setSubOrderObj(convertedObj[0]) : ' ';
  };
  console.log( "Sub order Obj", subOrderObj);
  const onViewClick = async (data, key) => {
    console.log("Data",data,"Key",key)
    setViewPassedKey(key);
    setModalLoading(true);
    console.log('onViewClick');
    console.log(data.ecomOrderId, data.addressId);
    let apiResp = await getSingleSubOrder(data.ecomOrderId, data.addressId, data.patientId);
    console.log('apiResp', apiResp);
    // console.log(apiResp);
    let createdAt = moment(apiResp.createdAt).utc().format('DD-MMM-YYYY');
    apiResp.createdAt = createdAt;
    apiResp ? setSubOrderObj(apiResp) : '';
    setModalLoading(false);
    setViewPassedKey(null);
    setShowSingleOrder(true);
    // localStorage.setItem("orderId", JSON.stringify(data.ecomOrderId));
    // navigate('/ecomorders/view', { replace: true })
  };

  const onViewRequest = async (data, key) => {
    console.log('Data', data, 'Key', key);
    console.log('Data', data, 'Key', key);
    setViewPassedKey(key);
    setViewRequestLoader(true);
    console.log('onViewClick');
    console.log(data.ecomOrderId, data.addressId);
    let apiResp = await getRequest(data.requestId);
    console.log('apiResp Request', apiResp);
    console.log(apiResp);
    let createdAt = moment(apiResp.createdAt).utc().format('DD-MMM-YYYY');
    apiResp.createdAt = createdAt;
    apiResp ? setRequestObj(apiResp) : '';
    setViewRequestLoader(false);
    setViewPassedKey(null);
    setShowRequest(true);
    
  };
  const [searchBy, setSearchBy] = useState('');
  const onSearchFinish = async (values) => {
    console.log('onSearchFinish');
    console.log('Success:', values);

    let formData = {
      limit: 10,
      offset: 0,
      searchBy: values.searchBy, //partnerName, serialNo, orderNo
      searchTxt: values.searchTxt,
      // status: activeIndex, //1 = In-Transit (Inprogress), 2 = Sent to Lab, 3 = Completed

      //"orderDateFrom": orderDateFrom ? orderDateFrom : "", //2023-03-03  only give date without time
      // "orderDateTo": orderDateTo ? orderDateTo : ""
    };
    if (!formData.searchTxt) message.error('Please enter search text');
    else await getRequestData(formData);
  };

  const onSearchFinishFailed = (values) => {
    console.log('Failed:', values);
  };
  const onCardViewClick = (data) => {
    if (data == 1) {
      setActiveIndex(data);
      navigate('/ecom/dashboard/inprogress', { replace: true });
    } else if (data == 2) {
      setActiveIndex(data);
      navigate('/ecom/dashboard/intransit', { replace: true });
    } else if (data == 3) {
      setActiveIndex(data);
      navigate('/ecom/dashboard/delivered', { replace: true });
    } else if (data == 4) {
      setActiveIndex(data);
      navigate('/ecom/dashboard/request', { replace: true });
    } else if (data == 5) {
      setActiveIndex(data);
      navigate('/ecom/dashboard/cancel', { replace: true });
    }
  };

  const onRequestClick = async (index) => {
    setActiveIndex(index);
    setTableHeaders(headersMap.get(index));
    let formData = {
      limit: pageSize,
      offset: 0,
      searchBy: '',
      searchTxt: '',
    };
    await getRequestData(formData);
  };

  const genExtra = (order) => (
    <>
      <table id=''>
        <tbody>
          <tr>
            <td style={{ width: '160px' }}>
              <a>{order.ecomOrderNo}</a>
            </td>
            <td style={{ width: '180px' }}>
              <a>{order.createdAt}</a>
            </td>
            <td style={{ width: '200px' }}>{order.patientName}</td>
            <td style={{ width: '130px' }}>{Number(order.productTotalAmount)}</td>
            <td style={{ width: '100px' }}>{order.orderStatus ? order.orderStatus : null}</td>
          </tr>
        </tbody>
      </table>
    </>
  );

  console.log('Order data');
  console.log(orderData);
  const rejectRequest = async (data) => {
    console.log('Request Reject Clicked');

    const uploadObj = {
      requestId: data.requestId,
      patientId: data.patientId,
      ecomOrderId: data.ecomOrderId,
      addressId: data.addressId,
    };

    console.log(uploadObj);

    try {
      let resp = await axios.post(`${app.URL}/api/v1/admin/userrequest/reject`, uploadObj, { headers: getHeaders() });
      console.log(resp);
      setModalLoading(false);
      setRejectData();
      setRejectShow(false);
      setRefreshView(true);
      setCommentData({});
    } catch (e) {
      throw e;
    }
  };
  const handleOnChange = (e) => {
    if (e.target.value.length === 0) {
      setRefreshPage(!refreshPage);
      setSearchShow({});
    }
  };
  console.log("SuborderObj", subOrderObj)
  console.log(renderList);
  return (
    <div className='dashboard-card container-fluid'>
      <EcomDashboardHeader onChange={onChange} />
      <EcommerceCards status={status} onCardViewClick={onCardViewClick} />
      <SearchBar onFinish={onSearchFinish} onFinishFailed={onSearchFinishFailed} searchItems={searchItems} onChange={handleOnChange} />

      {/* orders table to copy*/}

      <div
        className=' table-scrollbar mx-1'
        style={{
          overflowX: 'auto',
        }}
      >
        {loader && <LoaderImage />}
        {renderList.length > 0 ? (
          <table
            id='customers'
            style={{
              padding: '5px 0px',
            }}
          >
            <thead class=''>
              <tr
                style={{
                  fontWeight: 'bold',
                }}
              >
                {/* <th>Request Type</th> */}
                <th>Request No.</th>
                <th>Order No.</th>
                <th>Sub Order No.</th>
                <th>Customer</th>
                <th>Request Type</th>
                <th>Request Date</th>
                <th>Request Status</th>
                {/* {tableValues.requestType === 'Missing' || (tableValues.isRecivedBack && tableValues.requestType === 'Exchange') ? <th></th> : ''} */}
                <th></th>
                <th></th>
                <th></th>
                {/* <th></th>
                <th></th> */}
                {/* {tableValues.requestType === 'Cancel' || (tableValues.isRecivedBack && tableValues.requestType === 'Refund') ? <th></th> : ''} */}
                {/* <th></th> */}
              </tr>
            </thead>
            <tbody>
              {renderList.map((tableValues, key) => (
                <tr>
                  {/* <td style={{ width: '200px', textTransform: "capitalize" }}>{tableValues.requestType ? tableValues.requestType : 'null'}</td> */}
                  <td style={{ width: '200px', textTransform: 'capitalize' }}>{tableValues.reqNo ? tableValues.reqNo : 'null'}</td>
                  <td style={{ width: '200px' }}>{tableValues.ecomOrderNo ? tableValues.ecomOrderNo : 'null'}</td>
                  <td style={{ width: '150px' }}> {tableValues.ecomOrderNo ? tableValues.ecomOrderNo + (tableValues.addressId ? '-' + tableValues.addressId : '') : null}</td>
                  <td style={{ width: '150px', textAlign: 'center' }}>{tableValues.title ? tableValues.title : 'null'}</td>
                  <td style={{ width: '200px', textTransform: 'capitalize' }}>{tableValues.requestName ? tableValues.requestName : 'null'}</td>
                  <td style={{ width: '150px' }}>{tableValues.createdAt ? tableValues.createdAt : 'null'}</td>
                  <td style={{ width: '150px', textTransform: 'capitalize' }}>{tableValues.requestStatus ? tableValues.requestStatus : 'null'}</td>
                  {/* {tableValues.isRecivedBack && tableValues.requestName === 'Replace' ? (
                    <td>
                      <button
                        className='button btn btn-primary mx-1'
                        onClick={() => {
                          onResendOrderTrigger(tableValues, key);
                        }}
                        style={{ fontSize: '0.8rem', width: '170px' }}
                        size='medium'
                      >
                        {modalLoading && resendOrderKey == key ? <LoadingOutlined /> : 'Resend Order'}
                      </button>
                    </td>
                  ) : (
                    ''
                  )} */}

                  {/* {tableValues.requestName === 'Missing Item' ? (
                    <td>
                      <button
                        className='button btn btn-primary mx-1'
                        onClick={() => {
                          onViewClick(tableValues, key);
                        }}
                        style={{ fontSize: '0.8rem', width: '170px' }}
                        size='medium'
                      >
                        {modalLoading && missingItemKey == key ? <LoadingOutlined /> : 'Send Missing Item'}
                      </button>
                    </td>
                  ) : (
                    ''
                  )} */}

                  {/* {(tableValues.requestName === 'Replace' || tableValues.requestName === 'Return & Refund') && !tableValues.isRecivedBack ? (
                    <td>
                      <button
                        className='button btn btn-primary mx-1'
                        onClick={() => {
                          onReciveBackTrigger(tableValues, key);
                        }}
                        style={{ fontSize: '0.8rem', width: '170px' }}
                        size='medium'
                      >
                        {modalLoading && receiveBackKey == key ? <LoadingOutlined /> : 'Receive Back Order'}
                      </button>
                    </td>
                  ) : (
                    ''
                  )} */}

                  {/* {tableValues.requestName === 'Cancel' || (tableValues.isRecivedBack && tableValues.requestName === 'Return & Refund') ? (
                    <td>
                      {tableValues.paymentStatus === 'Settled' ? (
                        <button
                          className='button btn btn-primary mx-1'
                          onClick={() => {
                            onCancelTrigger(tableValues, key);
                          }}
                          style={{ fontSize: '0.8rem', width: '170px' }}
                          size='medium'
                        >
                          {loaderName === 'Approve' && passedkey == key ? <LoadingOutlined /> : 'Approve & Refund'}
                        </button>
                      ) : (
                        <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={renderTooltip}>
                          <button className='button btn btn-primary mx-1' style={{ fontSize: '0.8rem', width: '170px', cursor: 'not-allowed', opacity: 0.7 }} size='medium'>
                            Approve & Refund
                          </button>
                        </OverlayTrigger>
                      )}
                    </td>
                  ) : (
                    ''
                  )} */}

                  {/* {!tableValues.isRecivedBack ? (
                    <td>
                      <button
                        className='button btn btn-primary patient-cancel-btn mx-1'
                        onClick={() => {
                          onRejectTrigger(tableValues, key);
                        }}
                        style={{ fontSize: '0.8rem', width: '70px', backgroundColor: 'crimson !important' }}
                        size='medium'
                      >
                        {loaderName === 'Reject' && passedkey == key ? <LoadingOutlined /> : 'Reject'}
                      </button>
                    </td>
                  ) : (
                    ''
                  )} */}

                  <td>
                    <button
                      className='button btn btn-primary mx-2'
                      onClick={() => {
                        onViewClick(tableValues, key);
                      }}
                      size='medium'
                    >
                      {modalLoading && viewPassedKey == key ? <LoadingOutlined /> : 'Order'}
                    </button>
                  </td>

                  <td>
                    <button
                      className='button btn btn-primary mx-2'
                      onClick={() => {
                        onViewRequest(tableValues, key);
                      }}
                      size='medium'
                    >
                      {viewRequestLoader && viewPassedKey == key ? <LoadingOutlined /> : 'Request'}
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <>{!loader && <NoDataFound />}</>
        )}
        <Modal show={rejectShow} onHide={handleRejectModalClose}>
          {/* <Modal.Header closeButton>
            <Modal.Title>Confirmation</Modal.Title>
          </Modal.Header> */}
          <Modal.Body>Are you sure you want to reject refund request?</Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={handleRejectModalClose}>
              Cancel
            </Button>
            <Button variant='primary' htmlType='submit' onClick={() => rejectRequest(rejectData)}>
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>
      </div>

      {currentPage && pagesCount ? <Pagination style={{ marginTop: '10px' }} onChange={activeIndex == 4 ? onRequestChange : onChange} defaultCurrent={currentPage} total={pagesCount} showTotal={(total) => `Total ${total} items`} showSizeChanger /> : ' '}

      {/* refund order modal start */}

      <OrderRefundModal
        orderPayment={orderPayment}
        // orderData={orderData}
        isVisible={showOrderRefund}
        ecomOrderId={orderData.ecomOrderId}
        ecomOrderNo={`${orderData.ecomOrderNo}-${orderData.addressId}`}
        setIsVisible={setShowOrderRefund}
        addressId={orderData.addressId}
        address={`${orderData.address}, ${orderData.state}, ${orderData.city}, ${orderData.zipCode}`}
        ecomOrderCreatedDate={orderData.createdAt}
        productsData={orderData?.products}
        status={orderData.status}
        title={orderData.title}
        patientId={orderData.patientId}
        shipmentCharges={orderData.shipmentCharges}
        grandTotal={orderData.grandTotal}
        totalShipmentAmount={orderData.totalProductAmount}
        tax={orderData.taxAmount}
        requestId={orderData.requestId}
        requestStatus={orderData.requestStatus}
        requestType={orderData.requestType}
        customerComment={commentData}
        image={commentData}
        refreshView={setRefreshView}
        shipmePackageName={orderData.shipmePackageName}
      />

      {/* refund order modal end */}

      <SubOrderViewModal
        viewOrder={true}
        isVisible={showSingleOrder}
        ecomOrderId={subOrderObj?.ecomOrderId}
        setIsVisible={setShowSingleOrder}
        address={subOrderObj?.address}
        ecomOrderCreatedDate={subOrderObj?.createdAt}
        ecomOrderNo={subOrderObj?.ecomOrderNo}
        subOrderObj={subOrderObj}
        overallProductTotal={subOrderObj?.overallProductTotal}
        taxAmount={subOrderObj?.taxAmount}
        shipmentCharges={subOrderObj?.shipmentCharges}
        grandTotal={subOrderObj?.grandTotal}
        // ecomOrderNo={`${subOrderObj.ecomOrderNo}-${subOrderObj.addressId}`}

        // address={`${subOrderObj.address}, ${subOrderObj.state}, ${subOrderObj.city}, ${subOrderObj.zipCode}`}
        // productsData={subOrderObj?.products}
        // status={subOrderObj.status}
        // title={subOrderObj.title}
        // shipmentCharges={subOrderObj.shipmentTotal}
        // grandTotal={subOrderObj.grandTotal}
        // totalShipmentAmount={subOrderObj.grandTotal}
        // tax={subOrderObj.taxAmount}
        // requestId={subOrderObj.requestId}
        // requestStatus={subOrderObj.requestStatus}
        // requestType={subOrderObj.requestType}
        // shipmePackageName={subOrderObj.shipmentPackage}
        locationURL={window.location.pathname}
      />

      <ViewOrderModal isVisible={showSingleRequest} setIsVisible={setShowRequest} requestObj={requestObj} />

      {/* Cancel Modal */}
      {orderCancelResp && orderCancelResp.code === 200 ? (
        <Modal show={cancelShow} onHide={handleCancelModalClose}>
          <Modal.Header closeButton>
            <Modal.Title className='text-center'>Success</Modal.Title>
          </Modal.Header>
          <Modal.Body>{orderCancelResp.msg}</Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      ) : orderCancelResp && orderCancelResp.code === 400 ? (
        <Modal show={cancelShow} onHide={handleCancelModalClose}>
          <Modal.Header closeButton>
            <Modal.Title className='text-center'>Failed</Modal.Title>
          </Modal.Header>
          <Modal.Body>{orderCancelResp.msg}</Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      ) : (
        <Modal show={cancelShow} onHide={handleCancelModalClose}>
          {/* <Modal.Header closeButton>
            <Modal.Title>Confirmation</Modal.Title>
          </Modal.Header> */}
          <Modal.Body>Are you sure you want to cancel shipment?</Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={handleCancelModalClose}>
              Cancel
            </Button>
            <Button variant='primary' htmlType='submit' onClick={() => console.log('on cancel click')}>
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>
      )}

      {/* Resend Order Modal */}
      {resendOrderResp && resendOrderResp.code === 200 ? (
        <Modal show={resendOrderShow} onHide={handleResendOrderModal}>
          <Modal.Header closeButton>
            <Modal.Title className='text-center'>Success</Modal.Title>
          </Modal.Header>
          <Modal.Body>{resendOrderResp.msg}</Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      ) : resendOrderResp && resendOrderResp.code === 400 ? (
        <Modal show={resendOrderShow} onHide={handleResendOrderModal}>
          <Modal.Header closeButton>
            <Modal.Title className='text-center'>Failed</Modal.Title>
          </Modal.Header>
          <Modal.Body>{resendOrderResp.msg}</Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      ) : (
        <Modal show={resendOrderShow} onHide={handleResendOrderModal}>
          {/* <Modal.Header closeButton>
            <Modal.Title>Confirmation</Modal.Title>
          </Modal.Header> */}
          <Modal.Body>
            {missingItem ? `Are you sure, you want to send the missing item?` : `Are you sure, you want to resend the order?`}

            <div className='row'>
              <div className='col lg-12 p-2 mt-1'>
                <Form
                  initialValues={{
                    missingItemComment: missingItemComment,
                  }}
                >
                  <Row>
                    <Col span={24}>
                      <Form.Item label='Admin Comment' style={{ padding: '0px !important' }}></Form.Item>
                    </Col>
                    <Col
                      style={{
                        marginTop: '-20px',
                      }}
                      span={24}
                    >
                      <Form.Item name='missingItemComment'>
                        <TextArea
                          rows={3}
                          autoSize={false}
                          onChange={(event) => {
                            console.log('text: ', event.target.value);
                            setMissingItemComment(event.target.value);
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={handleResendOrderModal}>
              Cancel
            </Button>
            <Button variant='primary' htmlType='submit' onClick={onResendOrderonClick}>
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
};

export default Request;
