import React, { useEffect, useRef, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { TweenOneGroup } from 'rc-tween-one';
import { Select, Tag } from 'antd';

const TagSelect = ({ tags, setTags, options, label, cisPanelCode, isProductCat, col, isPanelCode, cisPanelCodeError, setCisPanelCodeError }) => {
  // console.log(tags, cisPanelCode);
  //   const { token } = theme.useToken();
  const [warning, setWarning] = useState('');
  const [inputVisible, setInputVisible] = useState(false);
  const inputRef = useRef(null);
  useEffect(() => {
    if (inputVisible) {
      inputRef.current?.focus();
    }
  }, [inputVisible]);

  useEffect(() => {
    setTimeout(() => {
      setWarning('');
    }, 7000);
  }, [warning]);

  const handleClose = (removedTag) => {
    const newTags = tags.filter((tag) => tag !== removedTag);
    console.log(newTags);
    setTags(newTags);
  };
  const showInput = () => {
    setInputVisible(true);
  };

  const handleSelectChange = (e) => {
    console.log(e, "Tags length", tags.length, tags);
    if (tags.length >= 0){
      {setCisPanelCodeError && setCisPanelCodeError(''); }
    }
    setTags([...tags, e]);
  };

  const forMap = (tag) => {
    let tagName = [];
    tagName = isProductCat ? cisPanelCode.filter((code) => code?.productCategoryId == tag) : cisPanelCode.filter((code) => code?.resultElementGroupId === tag);
    // console.log(tagName)
    const tagElem = (
      <Tag
        closable
        onClose={(e) => {
          e.preventDefault();
          handleClose(tag);
        }}
        style={{
          borderRadius: '5px',
          boxShadow: '1px 1px 1px #ccc',
          fontSize: '1rem !important',
        }}
        className='border-none text-light d-flex align-items-center bg-secondary p-2 mb-1'
      >
        {isProductCat && tagName.length > 0 && tagName[0]?.name}
        {isPanelCode && tagName.length > 0 && `${tagName[0]?.cisPanelName} (${tagName[0]?.cisPanelCode})`}
        {/* {isPanelName && tagName.length > 0 && tagName[0]?.cisPanelName} */}
      </Tag>
    );
    return (
      <span
        key={tag}
        style={{
          display: 'inline-block',
        }}
      >
        {tagElem}
      </span>
    );
  };
  const tagChild = tags?.map(forMap);
  const tagPlusStyle = {
    // background: token.colorBgContainer,
    borderStyle: 'dashed',
    borderRadius: '5px',
  };
  return (
    <div className='mb-4 row'>
      <div
        className={col}
        style={{
          marginBottom: 16,
        }}
      >
        {label && (
          <label>
            {label}
            <span className='required-star'>*</span>
          </label>
        )}
        {inputVisible ? (
          <Select
            required={true}
            rules={[
              {
                required: true,
                message: 'Select atleast one of the following',
              },
            ]}
            placeholder={isProductCat ? 'Select Product Category' : 'Select CIS Panels'}
            onChange={handleSelectChange}
            onBlur={() => setInputVisible(false)}
          >
            {options}
          </Select>
        ) : (
          <Tag className='d-flex align-items-center p-2 mb-1' onClick={showInput} style={tagPlusStyle}>
            <PlusOutlined className='me-1' /> {isProductCat ? 'Select Product Category' : 'Select CIS Panels'}
          </Tag>
        )}
        <small className='text-danger fs-6'>{warning}</small>
        {cisPanelCodeError && <p className='text-danger p-0 m-0'> {cisPanelCodeError} </p>}
      </div>
      <div className='col-12'>
        <TweenOneGroup
          enter={{
            scale: 0.8,
            opacity: 0,
            type: 'from',
            duration: 100,
          }}
          onEnd={(e) => {
            if (e.type === 'appear' || e.type === 'enter') {
              e.target.style = 'display: inline-block';
            }
          }}
          leave={{
            opacity: 0,
            width: 0,
            scale: 0,
            duration: 200,
          }}
          appear={false}
        >
          {tagChild}
        </TweenOneGroup>
      </div>
    </div>
  );
};
export default TagSelect;
