export function getStorageItem(key, callback) {
  if (typeof callback !== 'function') throw new Error('Invalid callback handler');

  var result = localStorage.getItem(key);

  if (result instanceof window.Promise) {
    result.then(callback);
  } else {
    callback(result);
  }
}

export function setStorageItem(storage, key, value, callback) {
  var result = storage.setItem(key, value);

  if (result instanceof window.Promise && callback) {
    result.then(callback);
  } else if (callback) {
    callback();
  }
}
