import React, { useEffect, useState } from 'react';
import { InputField } from '../../layouts/FormFields';
import { Button, Form, Switch } from 'antd';
import axios from 'axios';
import { app } from '../../config';
import getHeaders from '../../utils/authHeaders';
import LoaderImage from '../LoaderImage';
import NoDataFound from '../../layouts/NoDataFound';
import { LoadingOutlined } from '@ant-design/icons';
import { useLocation } from 'react-router-dom';

const AnuraCategories = () => {
  const [renderList, setRenderList] = useState([]);
  const [loader, setLoader] = useState(false);
  const [editloader, setEditLoader] = useState(false);
  const [anura, setAnura] = useState([]);
  const [updateId, setUpdateId] = useState('');
  let location = useLocation();

  const [isWrite, setIsWrite] = useState(true);

  const getAnuraCategoryListing = async () => {
    console.log('Listing API Called');
    setLoader(true);
    const res = await axios.get(`${app.URL}/api/v1/admin/anura/categories/view`, { headers: getHeaders() });
    res.data.result.success && setRenderList(res.data.result.data);
    setLoader(false);
  };

  let user = JSON.parse(localStorage.getItem('user'));

  const putAnuraCategoryListing = async (detail, anuraGroupId) => {
    setEditLoader(true);
    let userId = user?.result.data.user.userId;
    detail.userId = userId;
    const res = await axios.post(`${app.URL}/api/v1/admin/anura/categories/update/${anuraGroupId}`, detail, { headers: getHeaders() });
    setEditLoader(false);
    res.data.result.success && setAnura([]);
    res.data.result.success && getAnuraCategoryListing();
  };

  useEffect(() => {
    getAnuraCategoryListing();
    const [filteredRoute] = user.result.data.permission.filter((item) => item.action === location.pathname.slice(1));
    setIsWrite(filteredRoute.isWrite);
  }, []);

  const onFinish = (values) => {
    let updateData = {
      anuraGroup: values.anuraGroup,
      displayPosition: values.displayPosition,
      isActive: values.isActive ? 1 : 0,
    };
    setUpdateId(values.anuraGroupId);
    putAnuraCategoryListing(updateData, values.anuraGroupId);
  };
  const onFinishFailed = () => {};

  return (
    <div className='dashboard-card pb-5 container-fluid'>
      <div className='row p-0 pb-4'>
        <div className='for-before col-12 col-md-7 head fs-3 fw-bold' style={{ backgroundColor: '#a7c1d9', borderBottomRightRadius: '10px', color: '#FFFFFF' }}>
          Facial Scan Categories
        </div>
      </div>
      <div className='mt-4'>
        {loader && <LoaderImage />}
        {!loader && renderList.length === 0 && <NoDataFound />}
        {!loader && renderList.length > 1 && (
          <table id='customers' className='px-3 facial-scan-cat-table'>
            <thead>
              <tr>
                <th className='w-50'>Name</th>
                <th className='dp'>Display Position</th>
              </tr>
            </thead>
          </table>
        )}
        {!loader &&
          renderList.map((item, indx) => {
            return (
              <Form
                key={indx}
                name='basic'
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete='off'
                initialValues={{
                  remember: true,
                  ['anuraGroup']: item.anuraGroup,
                  ['displayPosition']: item.displayPosition,
                  ['isActive']: item.isActive,
                  ['anuraGroupId']: item.anuraGroupId,
                }}
                className='row px-3'
              >
                <InputField
                  col='col-md-2 col-3'
                  name='anuraGroup'
                  // label='Name'
                  placeholder='Enter Name'
                  rules={[
                    {
                      required: true,
                      message: 'Enter Param Name',
                    },
                  ]}
                  // required={true}
                  onChange={(e) => {
                    if (e.target.value !== item.anuraGroup) {
                      if (!anura.includes(item.anuraGroupId)) {
                        setAnura([...anura, item.anuraGroupId]);
                      }
                    } else {
                      const filterId = anura.filter((id) => id !== item.anuraGroupId);
                      setAnura(filterId);
                    }
                  }}
                />
                <InputField
                  col='col-md-1 col-2  ml-5'
                  name='displayPosition'
                  // label='Display Position'
                  placeholder='Enter Position'
                  rules={[
                    {
                      required: true,
                      message: 'Enter Display Position',
                    },
                  ]}
                  // required={true}
                  onChange={(e) => {
                    if (Number(e.target.value) !== item.displayPosition) {
                      if (!anura.includes(item.anuraGroupId)) {
                        setAnura([...anura, item.anuraGroupId]);
                      }
                    } else {
                      const filterId = anura.filter((id) => id !== item.anuraGroupId);
                      setAnura(filterId);
                    }
                  }}
                />

                <div className='col-2 pt-1'>
                  <Form.Item label='Active' className='selector-main' colon={false} valuePropName='checked' name='isActive'>
                    <Switch
                      onChange={(e) => {
                        if (e !== item.isActive) {
                          if (!anura.includes(item.anuraGroupId)) {
                            setAnura([...anura, item.anuraGroupId]);
                          }
                        } else {
                          const filterId = anura.filter((id) => id !== item.anuraGroupId);
                          setAnura(filterId);
                        }
                      }}
                    />
                  </Form.Item>
                </div>
                <Form.Item className='d-none selector-main' colon={false} valuePropName='checked' name='anuraGroupId'></Form.Item>
                {anura.includes(item.anuraGroupId) && isWrite && (
                  <div className='col-2 pt-1 '>
                    <Button className=' btn btn-primary' htmlType='submit'>
                      {editloader && item.anuraGroupId === updateId ? <LoadingOutlined /> : 'Update'}
                    </Button>
                  </div>
                )}
              </Form>
            );
          })}
      </div>
    </div>
  );
};

export default AnuraCategories;
