import ScopeImg from '../assets/images/scope.png';
import ResultsImg from '../assets/images/results.png';
import TestsImg from '../assets/images/tests.png';

const DashboardCard = (props) => {
  return (
    <div className='steps' style={{ backgroundColor: props.isActive ? '#F2B544' : '#D5D5D5', cursor: "pointer" }}>
      <p className='circle-div'>{props.count}</p>
      <div className='product-img'>
        <img src={props.activatedProducts ? ScopeImg : props.awaitingProducts ? ResultsImg : TestsImg} />
      </div>
      <p style={{ color: '#fff', marginTop: '-0.5rem' }} className='product-title'>
        {props.cardName}
      </p>
    </div>
  );
};

export default DashboardCard;
