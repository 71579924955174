import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { app } from '../../config';
import getHeaders from '../../utils/authHeaders';
import { Form, Input, message } from 'antd';
import { useNavigate } from 'react-router-dom';
import LoaderImage from '../LoaderImage';
import { useForm } from 'antd/es/form/Form';

const BindCodes = () => {
  const [apiResp, setAPIResp] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [loading, setLoading] = useState(false);
  const [focusCode, setFocusCode] = useState('G');
  const [genericError, setGenericError] = useState('');
  const [labelError, setLabelError] = useState('');
  const [productError, setProductError] = useState('');
  const [genericCount, setGenericCount] = useState(2);

  const [orderForm] = useForm();
  let inputRef = useRef();
  useEffect(() => {
    // console.log('9470111899562082997366'.length); /// 22
    if (inputRef.current) {
      console.log(inputRef);
      inputRef.current.focus();
    }
  }, []);
  const antMessage = (type, content, duration) => {
    messageApi.open({
      type,
      content,
      duration,
    });
  };

  const navigate = useNavigate();

  const uploadData = async (codes) => {
    console.log(codes);
    setLoading(true);
    setGenericError('');
    setLabelError('');
    setProductError('');
    messageApi.destroy();
    try {
      const res = await axios.post(`${app.URL}/api/v1/admin/inventory/mapping`, codes, { headers: getHeaders() });
      console.log(res);
      setLoading(false);

      if (res.data.result.success) {
        setAPIResp(true);
        console.log(res.data.result.msg);
        orderForm.resetFields();
        setFocusCode('G');
        setTimeout(() => {
          inputRef.current.focus();
        }, 500);

        // message.success(res.data.result.msg);
        antMessage('success', res.data.result.msg, 10);
      } else if (!res.data.result.success) {
        // message.warning(res.data.result.msg);
        antMessage('warning', res.data.result.msg, 60);
      }
    } catch (err) {
      setLoading(false);
      console.log(err.response.data.result.data);

      if (err.response.data.result.data.errorType === 'product') {
        // message.error(err.response.data.result.data);
        // antMessage('error', err.response.data.result.data.message, 60);
        setFocusCode('S');
        setProductError(err.response.data.result.data.message);

        orderForm.setFieldsValue({ serial: undefined });

        setTimeout(() => {
          inputRef.current.focus();
        }, 500);
      }

      if (err.response.data.result.data.errorType === 'generic') {
        // message.error(err.response.data.result.data);
        // antMessage('error', err.response.data.result.data.message, 60);
        setFocusCode('G');
        setGenericError(err.response.data.result.data.message);

        orderForm.setFieldsValue({ generic: undefined, generic2: undefined, generic3: undefined });
        setTimeout(() => {
          inputRef.current.focus();
        }, 500);
      }

      if (err.response.data.result.data.errorType === 'label') {
        // message.error(err.response.data.result.data);
        // antMessage('error', err.response.data.result.data.message, 60);
        setFocusCode('R');
        setLabelError(err.response.data.result.data.message);

        orderForm.setFieldsValue({ returnlabel: undefined });
        setTimeout(() => {
          inputRef.current.focus();
        }, 500);
      }
    }
  };

  const onFinish = (values) => {
    // messageApi.destroy();
    console.log(values);

    if (genericCount === 3) {
      if (values.generic === values.generic2 && values.generic === values.generic3) {
        let uploadObj = {
          genericBoxSerial: values.generic ? values.generic : null,
          productSerial: values.serial ? values.serial : null,
          returnLabelTracking: values.returnlabel ? values.returnlabel : null,
        };
        if (Object.keys(uploadObj).length > 0) {
          uploadData(uploadObj);
        }
      } else {
        setFocusCode('G');
        setGenericError('Generic Box Bar Codes should be same!');
        orderForm.setFieldsValue({ generic: undefined, generic2: undefined, generic3: undefined });
        setTimeout(() => {
          inputRef.current.focus();
        }, 500);
      }
    } else {
      if (values.generic === values.generic2) {
        let uploadObj = {
          genericBoxSerial: values.generic ? values.generic : null,
          productSerial: values.serial ? values.serial : null,
          returnLabelTracking: values.returnlabel ? values.returnlabel : null,
        };
        if (Object.keys(uploadObj).length > 0) {
          uploadData(uploadObj);
        }
      } else {
        setFocusCode('G');
        setGenericError('Generic Box Bar Codes should be same!');
        orderForm.setFieldsValue({ generic: undefined, generic2: undefined });
        setTimeout(() => {
          inputRef.current.focus();
        }, 500);
      }
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
    setGenericError('');
    setLabelError('');
    setProductError('');
  };

  function goBack() {
    navigate('/returnlabel/view', { replace: true });
  }
  window.addEventListener('popstate', goBack);

  const checkBoxCount = async (gType) => {
    try {
      const res = await axios.get(`${app.URL}/api/v1/admin/boxtype/checkgenbox/${gType}`, { headers: getHeaders() });
      setGenericCount(res.data?.result?.data?.verificationCount);
    }
    catch(err){
      console.log(err)
    }
  }

  const handleCodeChange = (e) => {
    setGenericError('');
    setLabelError('');
    setProductError('');
    if (e.target.value.length === 13 && e.target.name === 'G') {
      setFocusCode('G2');
      console.log(e.target.value, e.target.name);
      checkBoxCount(e.target.value?.split('-')[0]); 
      // if (e.target.value?.split('-')[0] === 'G01') {
      //   setGenericCount(3);
      // }
      // else{
      //   setGenericCount(2);
      // }
      setTimeout(() => {
        inputRef.current.focus();
      }, 500);
    }
    if (e.target.value.length === 13 && e.target.name === 'G2') {
      if (genericCount === 3) {
        setFocusCode('G3');
        console.log(e.target.value, e.target.name);
        setTimeout(() => {
          inputRef.current.focus();
        }, 500);
      } else {
        setFocusCode('S');
        console.log(e.target.value, e.target.name);
        setTimeout(() => {
          inputRef.current.focus();
        }, 500);
      }
    }
    if (e.target.value.length === 13 && e.target.name === 'G3') {
      setFocusCode('S');
      console.log(e.target.value, e.target.name);
      setTimeout(() => {
        inputRef.current.focus();
      }, 500);
    }
    if (e.target.value.length === 21 && e.target.name === 'S') {
      setFocusCode('R');
      console.log(e.target.value, e.target.name);
      setTimeout(() => {
        inputRef.current.focus();
      }, 500);
    }
  };

  return (
    <div className='dashboard-card container-fluid'>
      {contextHolder}
      <div className='row'>
        <div className='for-before col-12 col-md-7 head fs-3 fw-bold' style={{ backgroundColor: '#a7c1d9', borderBottomRightRadius: '10px', color: '#FFFFFF' }}>
          Package Verification
        </div>
      </div>
      <Form form={orderForm} name='returnLabelList' onFinish={onFinish} onFinishFailed={onFinishFailed} className='row p-5 px-3 pb-1' autoComplete='off' initialValues={{ remember: true }}>
        {loading ? (
          <LoaderImage />
        ) : (
          <div className='col-12 col-lg-4 col-md-6 input-column'>
            <div className='row'>
              <div className='col-md-6 col-12'>
                <label>
                  Generic Box Bar Code
                  <span className='required-star'>*</span>
                </label>
                <Form.Item
                  name='generic'
                  colon={false}
                  rules={[
                    {
                      required: true,
                      message: 'Enter Generic Box Bar Code',
                    },
                  ]}
                >
                  <Input name='G' ref={focusCode === 'G' ? inputRef : null} placeholder='Generic Box Bar Code' maxLength={13} onChange={(e) => handleCodeChange(e)} />
                </Form.Item>
              </div>
              <div className='col-md-6 col-12'>
                <label>
                  Generic Box Bar Code
                  <span className='required-star'>*</span>
                </label>
                <Form.Item
                  name='generic2'
                  colon={false}
                  rules={[
                    {
                      required: true,
                      message: 'Enter Generic Box Bar Code',
                    },
                  ]}
                >
                  <Input name='G2' ref={focusCode === 'G2' ? inputRef : null} placeholder='Generic Box Bar Code' maxLength={13} onChange={(e) => handleCodeChange(e)} />
                </Form.Item>
              </div>
              {genericCount === 3 && (
                <div className='col-md-6 col-12'>
                  <label>
                    Blood Sample Bar Code 3<span className='required-star'>*</span>
                  </label>
                  <Form.Item
                    name='generic3'
                    colon={false}
                    rules={[
                      {
                        required: true,
                        message: 'Enter Generic Box Bar Code',
                      },
                    ]}
                  >
                    <Input name='G3' ref={focusCode === 'G3' ? inputRef : null} placeholder='Generic Box Bar Code 3' maxLength={13} onChange={(e) => handleCodeChange(e)} />
                  </Form.Item>
                </div>
              )}

              {genericError && (
                <div className='col-12' style={{ marginTop: '-1rem', marginBottom: '0.5rem' }}>
                  <span className='text-danger'>{genericError}</span>
                </div>
              )}
              <div className='col-12'>
                <label>
                  Product QR Code
                  <span className='required-star'>*</span>
                </label>
                <Form.Item
                  name='serial'
                  colon={false}
                  rules={[
                    {
                      required: true,
                      message: 'Enter Product QR Code',
                    },
                  ]}
                >
                  <Input name='S' ref={focusCode === 'S' ? inputRef : null} placeholder='Product QR Code' maxLength={21} onChange={(e) => handleCodeChange(e)} />
                </Form.Item>
              </div>
              {productError && (
                <div className='col-12' style={{ marginTop: '-1rem', marginBottom: '0.5rem' }}>
                  <span className='text-danger'>{productError}</span>
                </div>
              )}
              <div className='col-12'>
                <label>
                  Shipment Return Label Bar Code
                  <span className='required-star'>*</span>
                </label>
                <Form.Item
                  name='returnlabel'
                  colon={false}
                  rules={[
                    {
                      required: true,
                      message: 'Enter Shipment Return Label Bar Code',
                    },
                  ]}
                >
                  <Input ref={focusCode === 'R' ? inputRef : null} placeholder='Shipment Return Label Bar Code' maxLength={22} onChange={(e) => handleCodeChange(e)} />
                </Form.Item>
              </div>
              {labelError && (
                <div className='col-12' style={{ marginTop: '-1rem', marginBottom: '0.5rem' }}>
                  <span className='text-danger'>{labelError}</span>
                </div>
              )}
            </div>
            <div className='row d-flex align-items-cener justify-content-end px-2 my-2'>
              <button type='primary' class='btn btn-primary button col-12 mb-2' htmlType='submit'>
                Verify
              </button>
            </div>
          </div>
        )}
      </Form>
    </div>
  );
};

export default BindCodes;
