import React from 'react';
import { Switch, Form } from 'antd';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { LoadingOutlined } from '@ant-design/icons';


export const QuestionaireForm = ({
  loading,
  extras,
  pageTitle,
  inputs,
  showQuestion,
  initialValues,
  onFinish,
  onFinishFailed,
  goBack,
  addUserResp,
  handleClose,
  show,
  submitData,
  addMessage,
}) => {
  return (
    <div className={`dashboard-card  pb-5 container-fluid`}>
      
      <div className='row p-0 pb-4'>
        
        <div className='for-before  col-12 col-md-7 head fs-3 fw-bold' style={{ backgroundColor: '#a7c1d9', borderBottomRightRadius: '10px', color: '#FFFFFF' }}>
          {pageTitle}
        </div>
      </div>
      <Form name='basic' onFinish={onFinish} onFinishFailed={onFinishFailed} autoComplete='off' initialValues={initialValues} className='row p-5 px-3 pb-1'>
        <div className={`col-12  input-column`}>
          <div className='row'>
            {inputs && inputs.map((item, key) => item)}

            {extras && extras.map((item) => item)}
            {/* {console.log(textEditorsValue)} */}

            {/* {switchBtn && (
              <div className='col-lg-12 mb-2 ms-3'>
                <Form.Item label='Active' className='selector-main' colon={false} valuePropName='checked' name='isActive'>
                  <Switch
                    onChange={(e) => {
                      console.log(e);
                    }}
                  />
                </Form.Item>
              </div>
            )} */}
            <button onClick={goBack} type='primary' className={`btn btn-primary button patient-cancel-btn  col-md-2 mx-md-2 mr-2 mb-2`}>
              Cancel
            </button>
            <button type='primary' className={`btn btn-primary button  col-md-2 mb-2`} htmlType='submit'>
              Save
            </button>
          </div>
        </div>
      </Form>

      {addUserResp?.code === 200 ? (
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title className='text-center'>Success</Modal.Title>
          </Modal.Header>
          <Modal.Body>{addUserResp.message}</Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      ) : addUserResp?.code === 400 ? (
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title className='text-center'>Failed</Modal.Title>
          </Modal.Header>
          <Modal.Body>{addUserResp.data.message}</Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      ) : (
        <Modal show={show} onHide={handleClose}>
          {/* <Modal.Header closeButton>
                      <Modal.Title>Confirmation</Modal.Title>
                    </Modal.Header> */}
          <Modal.Body>{addMessage}</Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={handleClose}>
              Cancel
            </Button>
            <Button disabled={loading} variant='primary' htmlType='submit' onClick={submitData}>
              {console.log('loading', loading)}
              {loading ? <LoadingOutlined /> : 'Confirm'}
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
};
