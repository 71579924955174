import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { app } from '../../config';
import { LoadingOutlined, MinusCircleOutlined, PlusOutlined, PrinterOutlined } from '@ant-design/icons';
import getHeaders from '../../utils/authHeaders';
import { Button as AButton, Form, Input, Space, Select, Row, Col, message, Tag } from 'antd';
import { CSVLink, CSVDownload } from 'react-csv';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
// import Excel from '../../assets/images/excel.png';
import Delete from '../../assets/images/delete.png';
import { extractObjValues } from '../../utils/extractObjValues';
import { dateConverter } from '../../utils/utcDateConversionCommon';

import { useForm } from 'antd/lib/form/Form'; // Import the useForm hook
import { useNavigate } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { useRef } from 'react';
import { InputField, SelectField } from '../../layouts/FormFields';
import useFetchProducts from '../../hooks/fetchProducts';
import { TweenOneGroup } from 'rc-tween-one';
import Excel from '../../assets/images/excel.png';
import { sortLabelArray } from '../inventory/inventoryHelpers';
const AddLabel = () => {
  const [invListData, setInvListData] = useState([]);
  const [addInvResp, setAddInvResp] = useState({});
  const [apiResp, setAPIResp] = useState(false);
  const [delBatchRes, setDelBatchRes] = useState({});
  const [show, setShow] = useState(false);
  const [showDelModal, setShowDelModal] = useState(false);
  const [uploadObj, setUploadObj] = useState([]);
  const [loading, setLoading] = useState(false);
  // const [allPrds, setAllPrds] = useState([]);
  const [noinvList, setNoinvList] = useState(false);
  const [products, setProducts] = useState([]);

  let prrds;

  const fetchedProducts = useFetchProducts(setProducts);
  prrds = fetchedProducts.response ? fetchedProducts.response.data : [];

  const navigate = useNavigate();

  const [form] = useForm();
  const handleClose = () => setShow(false);
  const handleDelModalClose = () => setShowDelModal(false);
  // const getAllProducts = async () => {
  //   const formData = {
  //     limit: 100,
  //     offset: 0,
  //     searchBy: 'name',
  //     searchTxt: '',
  //   };
  //   const res = await axios.post(`${app.URL}/api/v1/admin/product/listing`, formData, { headers: getHeaders() });
  //   // console.log(res.data.result.data);
  //   res.data.result.success && setAllPrds(res.data.result.data.data);
  // };

  // useEffect(() => {
  //   getAllProducts()
  // }, [])

  let user = JSON.parse(localStorage.getItem('user'));
  let userId = user.result ? user.result.data.user.userId : null;
  console.log(userId);

  const uploadData = async (userDetails) => {
    console.log(userDetails);
    let userId = user?.result.data.user.userId;
    userDetails[0].userId = userId;
    setLoading(true);
    try {
      const res = await axios.post(`${app.URL}/api/v1/admin/returnlabel/create`, userDetails, { headers: getHeaders() });
      console.log(res);
      setLoading(false);
      setShow(false);
      if (res.data.result.success) {
        setAPIResp(true);
        // console.log('dataaaaaaaaaaaaaaa');
        // console.log(res.data);
        // console.log(res.data.result.data);
        // setAddInvResp(res.data.result.data);
        let convertedData = dateConverter(res.data.result.data, 'issueDate');
        setInvListData(convertedData);
        console.log(convertedData);
        message.success(res.data.result.msg);
      } else if (!res.data.result.success) {
        message.warning(res.data.result.msg);
      }
    } catch (err) {
      setLoading(false);
      setShow(false);
      if (err) console.log(err);
      else return;
    }
  };
  const refinedArray = extractObjValues(invListData, ['batchId', 'type', 'issueDate', 'serialNo']);
  console.log(refinedArray);
  let newArray = [];
  refinedArray.forEach((ele) => {
    newArray.push({ [`Batch ID`]: ele.batchId, [`Title`]: ele.type, [`Date`]: ele.issueDate, [`Serial No.`]: ele.serialNo });
  });

  console.log(show);

  const onFinish = (values) => {
    const { returnList } = values;
    let tempArr = [];

    if (returnList.length > 0) {
      for (let i = 0; i < returnList.length; i++) {
        console.log(returnList[i]);
        tempArr.push({
          productId: returnList[i].product ? returnList[i].product : null,
          quantity: returnList[i].quantity ? returnList[i].quantity : null,
        });
      }

      setUploadObj(tempArr);
      setShow(true);
      setNoinvList(false);
    } else {
      setNoinvList(true);
    }
  };

  const submitData = () => {
    console.log('calling1111');
    if (uploadObj.length > 0) {
      uploadData(uploadObj);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo.values.returnList);

    // Extract boxType values from the invList array
    const boxTypesToRemove = errorInfo?.values?.returnList?.filter((item) => item.product !== undefined).map((item) => item.product);

    // Filter the main bocType array
    setProducts(prrds.filter((item) => !boxTypesToRemove.includes(item.productId)));
  };

  const deleteBatch = async (batchId) => {
    console.log(batchId);
    const res = await axios.delete(`${app.URL}/api/v1/admin/genericbox/delete/${batchId}`, { headers: getHeaders() });
    console.log(res);
    if (res && res.data.result.success) {
      setDelBatchRes(res.data.result.data);
      setShowDelModal(false);
      setInvListData([]);
      setAPIResp(false);
      form.resetFields();
    }
  };
  function goBack() {
    navigate('/returnlabel/view', { replace: true });
  }
  window.addEventListener('popstate', goBack);

  let printRef = useRef();

  const pageStyle = `
        @page {
        size: 4in 6in; /* Specify your custom page size here (width x height) */
          
           margin: 0;
           padding: 0;
       }
     
       body {
         font-family: Arial, sans-serif;
         margin: 0;
       }
       `;

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    pageStyle: pageStyle,
    onAfterPrint: goBack,
  });

  const [returnLabels, setreturnLabels] = useState([]);
  const [printLoader, setPrintLoader] = useState(false);
  // const [disableAdd, setDisableAdd] = useState(false)

  const generateReturnLabel = (e) => {
    setPrintLoader(true);
    e.stopPropagation();
    let labels = [];
    for (let i = 0; i < invListData.length; i++) {
      for (let j = 0; j < invListData[i].productLabels.length; j++) {
        labels.push(invListData[i].productLabels[j].labelURL);
      }
    }

    setreturnLabels(labels);

    setTimeout(() => {
      setPrintLoader(false);
      handlePrint();
    }, 1000);
  };

  console.log(returnLabels);

  function copyToClipboard(value) {
    console.log(navigator);

    // Copy the text inside the text field
    navigator.clipboard.writeText(value);

    // Provide feedback to the user (you can customize this part)
    message.success(`Tracking No has been copied to the clipboard: ${value}`);
  }
  const forMap = (tag) => {
    const tagElem = (
      <Tag
        style={{
          borderRadius: '5px',
          // backgroundColor: '#f2b544',
          boxShadow: '1px 1px 1px #ccc',
          cursor: 'pointer',
        }}
        id='serial'
        // value={tag}
        aria-disabled={true}
        onClick={() => copyToClipboard(tag)}
        className={`border-none d-flex align-items-center text-dark bg-light fs-6 p-2 mb-2`}
      >
        {tag}
      </Tag>
    );
    return (
      <span
        key={tag}
        style={{
          display: 'inline-block',
        }}
      >
        {tagElem}
      </span>
    );
  };

  // const tagChild = invListData?.map((item) => forMap(item.trackingNumber));
  let csvData;
  console.log('invListData', invListData);
  invListData.length > 0 ? (csvData = sortLabelArray(invListData)) : '';
  return (
    <div className='dashboard-card container-fluid'>
      <div className='row'>
        <div className='for-before col-12 col-md-7 head fs-3 fw-bold' style={{ backgroundColor: '#a7c1d9', borderBottomRightRadius: '10px', color: '#FFFFFF' }}>
          Add Return Label
        </div>

        <div style={{ display: 'none' }}>
          <div ref={printRef}>
            {returnLabels.map((item) => (
              <img src={item} />
            ))}
          </div>
        </div>
      </div>
      <Form name='returnLabelList' onFinish={onFinish} onFinishFailed={onFinishFailed} className='row p-5 px-3 pb-1' autoComplete='off' initialValues={{ returnList: [''] }}>
        <div className='col-md-8'>
          <div className='row'>
            <Form.List name='returnList'>
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <Space key={fields.key} className='col-md-12 m-0 ' align='baseline' gap='13'>
                      <div className='row w-100'>
                        <SelectField
                          {...restField}
                          col='col-lg-4'
                          placeholder='Select Product'
                          rules={[
                            {
                              required: true,
                              message: 'Select a product',
                            },
                          ]}
                          label='Product'
                          required={true}
                          name={[name, 'product']}
                          options={products.map((item) => (
                            <Select.Option value={item.productId}>{item.name}</Select.Option>
                          ))}
                        />
                        <InputField
                          {...restField}
                          col='col-lg-4'
                          rules={[
                            {
                              required: true,
                              message: 'Enter Quantity',
                            },
                          ]}
                          placeholder='Enter Quantity'
                          name={[name, 'quantity']}
                          label='Quantity'
                          type='number'
                          min="1"
                          required={true}
                        />

                        <div
                          className='col-5 col-md-3'
                          style={{
                            fontSize: '1rem',
                            color: 'red',
                            paddingTop: '1.8rem',
                          }}
                        >
                          <MinusCircleOutlined
                            onClick={() => {
                              if (fields.length > 1) {
                                remove(name);
                              }
                            }}
                          />
                        </div>
                      </div>
                    </Space>
                  ))}

                  {!apiResp && (
                    <button
                      disabled={apiResp}
                      onClick={() => {
                        add();
                        setNoinvList(false);
                      }}
                      type='primary'
                      className={`btn btn-primary button col-md-3 mx-md-2 ${noinvList ? 'mb-1' : 'mb-4'}`}
                    >
                      Add More
                    </button>
                  )}
                  <span className={`${noinvList ? 'd-block' : 'd-none'} mb-4 text-danger`}>Please add atleast one record!</span>
                </>
              )}
            </Form.List>
          </div>
        </div>

        <div className='col-8 mb-4'>
          <div className='row'>
            <button disabled={apiResp} onClick={goBack} type='primary' className='btn btn-primary button patient-cancel-btn col-md-3 mx-md-2 mr-2 mb-2'>
              Cancel
            </button>

            <button disabled={apiResp} type='primary' class='btn btn-primary button col-md-3 mb-2 mr-2' htmlType='submit'>
              Generate
            </button>

            {apiResp && (
              <span onClick={generateReturnLabel} disabled={printLoader} type='primary' class='btn btn-primary button col-md-3 mb-2 mr-2' htmlType='submit'>
                {printLoader ? <LoadingOutlined className='mr-2' /> : <PrinterOutlined className='mr-2' />}
                Print Return Labels
              </span>
            )}
          </div>
        </div>
        {/* <div className='col-md-8'>
          <div className='row'>
            <Form.List name='invList'>
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <Space key={fields.key} align='baseline'>
                      <Form.Item
                        {...restField}
                        name={[name, 'boxType']}
                        // style={{ width: '250px' }}
                        className='col-lg-12'
                        rules={[
                          {
                            required: true,
                            message: 'Select a box type',
                          },
                        ]}
                      >
                        <Select placeholder='Select Box Type'>
                          {boxTypes.map((box) => (
                            <Select.Option value={box.boxTypeId}>{box.type}</Select.Option>
                          ))}
                        </Select>
                      </Form.Item>

                      <Form.Item
                        // style={{ width: '250px' }}
                        className='col-lg-12'
                        {...restField}
                        name={[name, 'availableQty']}
                        rules={[
                          {
                            required: true,
                            message: 'Enter quantity',
                          },
                        ]}
                      >
                        <Input placeholder='Available Quantity' type='number' min='1' />
                      </Form.Item>

                      <div className='col-lg-4'>
                        <MinusCircleOutlined onClick={() => remove(name)} />
                      </div>
                    </Space>
                  ))}

                  {!apiResp && (
                    // <div className='inv-add-btn ' onClick={() => add()} style={{ cursor: 'pointer', color: '#fff', textAlign: 'center' }}>
                    //   Add
                    // </div>
                    <button disabled={apiResp} onClick={() => add()} type='primary' className='btn btn-primary patient-cancel-btn button col-md-3 mx-md-2 mb-5'>
                      Add
                    </button>
                  )}
                </>
              )}
            </Form.List>

            <div className='col-12 mb-4'>
              <div className='row'>
                <button disabled={apiResp} onClick={goBack} type='primary' className='btn btn-primary button patient-cancel-btn col-md-3 mx-md-2 mr-2 mb-2'>
                  Cancel
                </button>

                <button disabled={apiResp} type='primary' class='btn btn-primary button col-md-3 mb-2 ' htmlType='submit'>
                  Save
                </button>
              </div>
            </div>
          </div>
        </div> */}
      </Form>

      {addInvResp.code === 200 ? (
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title className='text-center'>Success</Modal.Title>
          </Modal.Header>
          <Modal.Body>{addInvResp.message}</Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      ) : addInvResp.code === 400 ? (
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title className='text-center'>Failed</Modal.Title>
          </Modal.Header>
          <Modal.Body>{addInvResp.data.message}</Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      ) : (
        <Modal show={show} onHide={handleClose}>
          {/* <Modal.Header closeButton>
            <Modal.Title>Confirmation</Modal.Title>
          </Modal.Header> */}
          <Modal.Body>Are you sure, you want to generate inventory?</Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={handleClose}>
              Cancel
            </Button>
            <Button disabled={loading} variant='primary' htmlType='submit' onClick={submitData}>
              {loading ? <LoadingOutlined /> : 'Confirm'}
            </Button>
          </Modal.Footer>
        </Modal>
      )}

      {delBatchRes.code === 200 ? (
        <Modal show={showDelModal} onHide={handleDelModalClose}>
          <Modal.Header closeButton>
            <Modal.Title className='text-center'>Success</Modal.Title>
          </Modal.Header>
          <Modal.Body>{delBatchRes.message}</Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      ) : addInvResp.code === 400 ? (
        <Modal show={showDelModal} onHide={handleDelModalClose}>
          <Modal.Header closeButton>
            <Modal.Title className='text-center'>Failed</Modal.Title>
          </Modal.Header>
          <Modal.Body>{delBatchRes.data.message}</Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      ) : (
        <Modal show={showDelModal} onHide={handleDelModalClose}>
          <Modal.Header closeButton>
            <Modal.Title>Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure, you want to Delete Batch?</Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={handleDelModalClose}>
              Cancel
            </Button>
            <Button
              variant='primary'
              htmlType='submit'
              onClick={() => {
                deleteBatch(refinedArray[0].batchId);
              }}
            >
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>
      )}

      {/* <button style={{ width: '70%', marginTop: '30px' }} type='primary' class='btn btn-primary button' htmlType='submit'>
                                        Save & Generate Serial No.
                                    </button> */}

      {apiResp ? (
        <>
          <div className='row'>
            <div className='col-lg-1 ml-6'>
              <CSVLink data={csvData}>
                <img src={Excel} style={{ width: '30px', height: '30px' }} />
              </CSVLink>

              {/* <CSVLink filename={'batchRecord.csv'} data={newArray}>
                <img src={Excel} style={{ width: '30px', height: '30px' }} />
                <img />
              </CSVLink> */}
            </div>
            <div className='col-lg-1'>
              <img
                src={Delete}
                style={{ width: '25px', height: '25px', cursor: 'pointer' }}
                onClick={() => {
                  setShowDelModal(true);
                }}
              />
            </div>
            {/* <div className='col-lg-1'>
              {printLoader ? (
                <LoadingOutlined
                  style={{
                    fontSize: '1.5rem',
                  }}
                />
              ) : (
                <PrinterOutlined
                  style={{
                    fontSize: '2rem',
                  }}
                  onClick={generateReturnLabel}
                />
              )}
            </div> */}
          </div>

          <div
            // className='row table-scrollbar mr-0'
            // style={{
            //   overflowX: 'auto',
            // }}
            className='row mr-0 px-2'
          >
            {/* <table id='customers'>
              <thead>
                <th>Batch No.</th>
                <th>Tracking No.</th>
                <th>Serial No.</th>
                <th>Partner</th>
                <th>Inventory Date</th>
                <th></th>
              </thead>
              <tbody>
                {invListData &&
                  invListData.map((ele) => {
                    return (
                      <tr>
                        <td>{ele.batchId}</td>
                        <td>{ele.trackingNumber}</td>
                        <td>{ele.serialNo}</td>
                        <td>{ele.partnerName}</td>
                        <td>{ele.issueDate}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table> */}

            {invListData.map((item) => (
              <Form
                initialValues={{
                  remember: true,
                  ['batchNo']: item?.batchId,
                  ['productName']: item?.productName,
                  ['createdAt']: item?.createdAt,
                  ['quantity']: item?.productLabels?.length,
                }}
                autoComplete='off'
                className='row p-2 px-3 pb-1 mb-3'
              >
                <InputField col='col-md-2' label='Batch No.' name='batchNo' disabled={true} />
                <InputField col='col-md-2' label='Product Name' name='productName' disabled={true} />
                <InputField col='col-md-2' label='Label Date' name='createdAt' disabled={true} />
                <InputField col='col-md-2' label='Quantity' name='quantity' disabled={true} />

                <div className='col-lg-12'>
                  <label>Tracking No.</label>
                  <TweenOneGroup
                    enter={{
                      scale: 0.8,
                      opacity: 0,
                      type: 'from',
                      duration: 100,
                    }}
                    onEnd={(e) => {
                      if (e.type === 'appear' || e.type === 'enter') {
                        e.target.style = 'display: inline-block';
                      }
                    }}
                    leave={{
                      opacity: 0,
                      width: 0,
                      scale: 0,
                      duration: 200,
                    }}
                    appear={false}
                  >
                    {item?.productLabels?.map((item) => forMap(item.trackingNumber, item.isUsed))}
                  </TweenOneGroup>
                </div>
              </Form>
            ))}
          </div>
        </>
      ) : (
        ' '
      )}
    </div>
  );
};

export default AddLabel;
