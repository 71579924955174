import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { message, Switch, Select, Form, Input, Row, Col, DatePicker, Upload, Space, Button } from 'antd';
import Modal from 'react-bootstrap/Modal';
import { app } from '../../config';
import getHeaders from '../../utils/authHeaders';
import TextArea from 'antd/lib/input/TextArea';
import { AddRecord } from '../../layouts/AddRecord';
import { InputField, SelectField, TextAreaField } from '../../layouts/FormFields';
import TagInput from '../Products/TagInput';
import { InfoCircleOutlined, LoadingOutlined, UploadOutlined } from '@ant-design/icons';
import getSampleVideoType from '../../hooks/getSampleVideoType';
import AddPhoto from '../../assets/images/add.png';

const AddBoxType = () => {
  const { t } = useTranslation(['common']);
  const [addUserResp, setAddUserResp] = useState({});
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tags, setTags] = useState([]);
  const [uploadVideo, setuploadVideo] = useState('');
  const [contentType, setContentType] = useState();
  const [uplVid, setuplVid] = useState(true);
  const sampleVideoRes = getSampleVideoType();
  // const [imageuploading, setimageuploading] = useState(false);
  // const [uploadImage, setuploadImage] = useState('');

  let headers = getHeaders();

  const sampleVideo = sampleVideoRes.response ? sampleVideoRes.response : [];
  console.log(sampleVideo);

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    // reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  };
  const beforeUpload = (file, e) => {
    console.log(file);

    if (file.type === 'image/jpeg' || file.type === 'image/png') {
      console.log('setting image type');
      setContentType('img');
    } else if (file.type === 'video/x-matroska' || file.type === 'video/mp4') setContentType('video');
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'video/x-matroska' || file.type === 'video/mp4';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    console.log('file Checked');
    const isLt2M = file.size / 1024 / 1024 < 20;
    if (!isLt2M) {
      setLargeFile(true);
      message.error('Image must smaller than 20MB!');
    }
    console.log('message');
    return isJpgOrPng && isLt2M;
  };

  const handleuploadChange = (info) => {
    setuploadVideo('');

    console.log(info);
    if (info.file.status === 'uploading') {
      return;
    }
    if (info.file.status === 'done') {
      info.file ? console.log(info.file.response.result.data.Location) : console.log('Not found');
      info.file ? setuploadVideo(info.file.response.result.data.Location) : ' ';
      getBase64(info.file.originFileObj, (url) => {
        setuplVid(false);
      });
    }
  };

  console.log(uploadVideo);
  let navigate = useNavigate();
  const [uploadObj, setUploadObj] = useState({});
  function goBack() {
    navigate('/boxtype/list', { replace: true });
  }
  window.addEventListener('popstate', goBack);

  const user = JSON.parse(localStorage.getItem('user'));

  const uploadData = async (userDetails) => {
    setLoading(true);
    let userId = user?.result.data.user.userId;
    userDetails.userId = userId;
    console.log(userDetails);

    try {
      const res = await axios.post(`${app.URL}/api/v1/admin/boxtype/create`, userDetails, { headers: getHeaders() });
      setLoading(false);
      setShow(false);

      if (res.data.result.success) {
        setAddUserResp(res.data.result.data);
        message.success(res.data.result.msg);

        setTimeout(goBack, 500);
      } else if (!res.data.result.success) {
        message.warning(res.data.result.msg);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
      setShow(false);

      if (err) console.log(err.response.data.message);
      else return;
    }
  };

  const onFinish = (values) => {
    console.log(values, 'values');
    // return;
    const payload = {
      ...values,
      videoUrl: uploadVideo ? uploadVideo : '',
    };
    if (Object.keys(payload).length > 0) {
      uploadData(payload);
    }
  };

  const submitData = () => {
    return;
  };
  const handleClose = () => setShow(false);
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const [videoNote, showVideoNote] = useState(false);

  const boxToggleVideo = () => {
    setuploadVideo('');
  };

  // const handleuploadImage = (info) => {
  //   if (info.file.status === 'uploading') {
  //     setimageuploading(true);
  //   }
  //   if (info.file.status === 'done') {
  //     // Get this url from response in real world.
  //     info.file ? console.log(info.file.response.result.data.Location) : console.log('Not found');
  //     info.file ? setuploadImage(info.file.response.result.data.Location) : ' ';
  //     setimageuploading(false);
  //   }
  // };

  return (
    <AddRecord
      // videoUrl={uploadVideo}
      loading={loading}
      pageTitle='Add BoxType'
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      handleClose={handleClose}
      show={show}
      addUserResp={addUserResp}
      goBack={goBack}
      initialValues={{
        remember: true,
        ['isActive']: true,
        ['verificationCount']: 2,

        ['boxIngredients']: [],
        // ['boxIngredient']: [{ boxName: 'box 1' }, { boxName: 'box 1' }, { boxName: 'box 1' }],
      }}
      inputs={[
        <InputField
          label='Box Type'
          name='boxType'
          col='col-lg-2 col-md-3 col-6'
          required={true}
          placeholder='Box Type'
          rules={[
            {
              required: true,
              message: 'Enter Box Type',
            },
          ]}
        />,
        <InputField
          label='No. of Steps'
          name='noOfSteps'
          col='col-lg-2 col-md-3 col-6'
          required={true}
          placeholder='No. of Steps'
          rules={[
            {
              required: true,
              message: 'Enter No. of Steps',
            },
          ]}
        />,
        <SelectField
          label='No. of Prints'
          name='verificationCount'
          col='col-lg-2 col-md-3 col-6'
          required={true}
          placeholder='No. of Prints'
          options={[2, 3].map((item) => (
            <Select.Option value={item}>{item}</Select.Option>
          ))}
          rules={[
            {
              required: true,
              message: 'Select No. of Steps',
            },
          ]}
        />,
        <div className='col-12 mb-2'>
          <div className='row'>
            <Form.List name='boxIngredients'>
              {(fields, { add, remove }, { errors }) => (
                <>
                  {console.log('Fields', fields)}
                  {fields.map((field, index, ...restField) => (
                    <>
                      <SelectField
                        {...restField}
                        col='col-md-4'
                        label='Box Ingredient'
                        name={[field.name, 'ingredientId']}
                        // name='sampleType'
                        // col='col-lg-2 col-md-3 col-6'
                        required={true}
                        placeholder='Box Ingredient'
                        options={sampleVideo.map((item) => (
                          <Select.Option value={item.ingredientId}>{item.name}</Select.Option>
                        ))}
                        rules={[
                          {
                            required: true,
                            message: 'Select Box Ingredient',
                          },
                        ]}
                      />
                      <InputField
                        label='Quantity'
                        required={true}
                        col='col-md-4'
                        name={[field.name, 'quantity']}
                        rules={[
                          {
                            required: true,
                            message: 'Enter Quantity',
                          },
                        ]}
                        {...restField}
                        placeholder='Enter Quantity'
                      />
                      <div className='col-md-3 mt-4'>
                        {fields.length > 0 && (
                          <div className='add-btn' style={{ textAlign: 'center', backgroundColor: '#F21D2F' }} onClick={() => remove(field.name)}>
                            Remove
                          </div>
                        )}
                      </div>
                    </>
                  ))}

                    <div style={{ textAlign: 'center' }} onClick={() => add()} className='col-lg-3 col-md-6 col-12 add-btn mx-2'>
                      Add
                    </div>
                  <Form.ErrorList errors={errors} />
                </>
              )}
            </Form.List>
            {/* <TagInput label='Box Ingredients' tags={tags} setTags={setTags} options={sampleVideo.map((item) => !tags.includes(item.sampleType) && <Select.Option value={item.sampleType}>{item.sampleType}</Select.Option>)} />, */}

            {/* <div className='col-md-4 text-center'>
              <Form.Item className='w-100 h-auto' valuePropName='fileList'>
                <Upload listType='picture-card' showUploadList={false} action={`${app.URL}/api/v1/general/banner/imageupload`} beforeUpload={beforeUpload} onChange={handleuploadImage} headers={headers}>
                  {uploadImage && !imageuploading ? (
                    <img className='added-img' src={uploadImage} alt='avatar' />
                  ) : !uploadImage && imageuploading ? (
                    <LoadingOutlined style={{ fontSize: '40px' }} />
                  ) : (
                    <div className='d-flex flex-column py-3'>
                      <img className='for-add-img' src={AddPhoto} alt='avatar' />
                      <p className='add-text'>Upload Image</p>
                    </div>
                  )}
                </Upload>

                {uploadImage && !imageuploading && (
                  <button onClick={() => setuploadImage('')} style={{ backgroundColor: 'rgb(242, 29, 47)' }} className='add-btn'>
                    Remove
                  </button>
                )}
              </Form.Item>
              <Form.Item colon={false} valuePropName='checked' defaultValue={true} label='Show Image' name='showImage'>
                <Switch />
              </Form.Item>
            </div> */}
          </div>
        </div>,

        // <div className='col-lg-12 mb-4'>
        //   <h2 className='dynamic-field-head'>Store</h2>
        //   <Form.List name='boxIngredient'>
        //     {(fields, { add, remove }, { errors }) => (
        //       <>
        //         {fields.map((field, index) => (
        //           // <Space className='col-lg-12' key={field.key}>
        //           <Form.Item required={false} key={field.key}>
        //             <div className='row'>
        //               <Form.Item
        //                 className='col-lg-9 '
        //                 {...field}
        //                 validateTrigger={['onChange', 'onBlur']}
        //                 // noStyle
        //               >
        //                 <Select placeholder={`Store ` + (index + 1)}>
        //                   {/* {storesList.map((item) => (
        //                     <Select.Option value={item.storeId}>{item.name}</Select.Option>
        //                   ))} */}
        //                 </Select>
        //               </Form.Item>
        //               <Form.Item className='col-lg-3 mt-1'>
        //                 {fields.length > 0 && (
        //                   <div className='add-btn' style={{ textAlign: 'center', backgroundColor: '#F21D2F' }} onClick={() => remove(field.name)}>
        //                     Remove
        //                   </div>
        //                 )}
        //               </Form.Item>
        //             </div>
        //           </Form.Item>
        //           // </Space>
        //         ))}

        //         <div className='row px-2'>
        //           <div style={{ textAlign: 'center' }} onClick={() => add()} className='col-lg-3 add-btn'>
        //             Add Store
        //           </div>
        //         </div>
        //         <Form.ErrorList errors={errors} />
        //       </>
        //     )}
        //   </Form.List>
        // </div>,
        <TextAreaField
          name='description'
          label='Description'
          col='col-lg-8 col-md-6 col-12'
          colon={false}
          rules={[
            {
              required: true,
              message: 'Enter Description',
            },
          ]}
          rows={6}
          showCount={true}
          maxLength={2500}
          placeholder='Box Description'
        />,
        <div className='col-lg-4 col-md-6 col-12 mb-lg-0 mb-2 '>
          {uploadVideo && <label>Video</label>}
          {uploadVideo && (
            <video style={{ width: '100%', borderRadius: '5px', boxShadow: '3px 3px 10px #ccc' }} controls title='YouTube video player'>
              <source src={uploadVideo} type='video/mp4'></source>
            </video>
          )}
          <div className='d-flex'>
            <Form.Item colon={false} name='video' className='mt-2 mb-0 w-100'>
              <Space
                direction='vertical'
                style={{
                  width: '100%',
                }}
                size='large'
              >
                <Upload onRemove={boxToggleVideo} listType='picture' maxCount={1} className='avatar-uploader' action={`${app.URL}/api/v1/general/boxtype/videoUpload`} beforeUpload={beforeUpload} onChange={handleuploadChange}>
                  <Button icon={<UploadOutlined />} className={'upload-btn'} style={{ color: 'black' }}>
                    Upload Video
                  </Button>
                  {/* <div className='mt-2'>Note: Upload size for video is 50mb supported format for video are (mkv/mp4)</div> */}
                </Upload>
              </Space>
            </Form.Item>
            <InfoCircleOutlined onClick={() => showVideoNote(!videoNote)} onMouseEnter={() => showVideoNote(true)} onMouseLeave={() => showVideoNote(false)} className='mx-3' style={{ fontSize: '1rem' }} />
            {videoNote && <div className='mt-5 absolute'>Note: Upload size for video is 50mb supported format for video are (mkv/mp4)</div>}
          </div>
        </div>,
      ]}
      submitData={submitData}
      switchBtn={true}
      addMessage='Are you sure you want to add box type?'
    />
  );
};

export default AddBoxType;
