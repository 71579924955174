import React, { useState } from 'react';
import { Spin, Form, message, Input } from 'antd';
import axios from 'axios';
// import { useLocation, useNavigate } from 'react-router-dom';
import { LoadingOutlined, UserOutlined, LockOutlined } from '@ant-design/icons';
import { app } from '../../config';
import './loginstyles.css';
import { FormInputItem } from '../../components/common/FormItem';
import { NavLink } from 'react-router-dom';

function AdmnSignIn() {
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
      }}
      spin
    />
  );
  // const [fieldsrequired, setFieldsRequired] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errorMsg, setErrorMsg] = useState();
  // const navigate = useNavigate();
  // const location = useLocation();
  // const from = location.state?.from?.pathname || '/dashboard/listing';
  window.document.title = 'Admin Sign In';

  console.log(process.env, process.env.REACT_APP_PLATFORM);

  let userResObj;
  const postData = async (login) => {
    console.log('function called');
    if (login.email && login.password) {
      setSuccess(true);
      try {
        let res = await axios.post(`${app.URL}/api/v1/admin/adminAccount/signIn`, login);
        userResObj = res ? res.data : ' ';
        if (!userResObj.result.success || userResObj.result.msg == 'Your Login Failed') {
          setErrorMsg('Invalid user Id and/or password.');
          setSuccess(false);
        } else {
          if (userResObj.result.data.user.isActive) {
            // console.log('Login with active user', `/${userResObj.result.data.permission[0].action}`);
            localStorage.setItem('user', JSON.stringify(userResObj));
            localStorage.setItem('userId', JSON.stringify(userResObj.result.data.user.userId));
            // console.log(userResObj?.result?.data?.permission?.filter((item) => item.action === 'dashboard/listing'));
            const dashboardPermission = userResObj?.result?.data?.permission?.filter((item) => item.action === 'dashboard/listing');
            if (localStorage.getItem('user')) {
              if(dashboardPermission.length > 0){
                window.location.replace(`/${dashboardPermission[0].action}`);
              }
              else{
                window.location.replace(`/${userResObj.result.data.permission[0].action}`);

              }
            }            
          } else {
            message.info('This user is not active.');
          }
        }
      } catch (err) {
        console.log(err);
        // message.error('Sorry! we are facing some issue.');
        // message.error('Try Again after some time.');
        setSuccess(false);
        err.response ? console.log(err.response.data.message) : console.log('Error');
        if (typeof err === 'string') setErrorMsg(err);
        else if (typeof err === 'object') setErrorMsg(err.response.data.message);
      }
    }
  };
  const onFinish = (values) => {
    const login = {
      email: values.email,
      password: values.password,
    };
    console.log(login)
    postData(login);
  };

  const onFinishFailed = (errorInfo) => {};

  return (
    <>
      <div className='w-100 login-bg-img'>
        {/* <div class='row'>
          <AdmnNav />
        </div> */}
        <div className='col-lg-12 col-md-8 col-12 '>
          <div className='row p-5 justify-content-center'>
            <div className='col-lg-4 col-12 login-card'>
              <div className='row justify-content-center'>
                <h2 className='fw-bold text-center mb-4 fs-4 mt-4 text-center'>Admin Login</h2>
                <div className='col-md-10 mt-4 '>
                  <Form
                    name='normal_login'
                    className='login-form'
                    initialValues={{
                      remember: true,
                    }}
                    width={80}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                  >
                    <FormInputItem
                      name='email'
                      rules={[
                        {
                          required: true,
                          message: 'Please enter Email!',
                        },
                      ]}
                      className='email-box'
                      type='email'
                      prefix={<UserOutlined className='site-form-item-icon' />}
                      placeholder='Email'
                    />

                    {/* <FormInputItem
                      name='password'
                     
                      rules={[
                        {
                          required: true,
                          message: 'Please enter Password!',
                        },
                      ]}
                      className='pwd-box mb-0'
                      style={{ padding: '10px'}}
                      prefix={<LockOutlined className='site-form-item-icon' />}
                      type='password'
                      placeholder='Password'
                    /> */}

                    <Form.Item
                      name='password'
                      rules={[
                        {
                          required: true,
                          message: 'Please enter Password!',
                        },
                      ]}
                    >
                      <Input.Password className='pwd-box mb-0' style={{ padding: '10px' }} prefix={<LockOutlined className='site-form-item-icon' />} type='password' placeholder='Password'></Input.Password>
                    </Form.Item>

                    <Form.Item className=''>
                      <NavLink to='/admin/forgotpassword' className='login-form-forgot'>
                        Forgot password?
                      </NavLink>
                    </Form.Item>

                    {/* <Input.Password
                      name='password'
                      rules={[
                        {
                          required: true,
                          message: 'Please enter Password!',
                        },
                      ]}
                      className='pwd-box mb-4'
                      style={{ padding: '10px' }}
                      prefix={<LockOutlined className='site-form-item-icon' />}
                      // type='password'
                      placeholder='Password'
                    ></Input.Password> */}

                    <Form.Item>
                      <button type='primary' htmlType='submit' className='btn btn-primary w-100 '>
                        {success ? <Spin indicator={antIcon} /> : 'Sign in'}
                      </button>
                    </Form.Item>
                    {/* <Form.Item className=''> */}

                    {/* </Form.Item> */}
                  </Form>
                  {errorMsg && (
                    <h4 className='' style={{ color: 'red' }}>
                      {errorMsg}
                    </h4>
                  )}
                </div>
                {/* <p class='not-a-member'>Not a member? Sign up now</p> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AdmnSignIn;
