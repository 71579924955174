// import React, { useState, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
// import axios from 'axios';
// import { useTranslation } from 'react-i18next';
// // import { message, Switch, Select, Form, Input, Space, Upload } from 'antd';
// import { message, DatePicker, Row, Col, Select, Form, Input, Upload, Space, Button as BTN, Switch, Textarea } from 'antd';
// import Button from 'react-bootstrap/Button';

// import Modal from 'react-bootstrap/Modal';
// import { app } from '../../config';
// import useFetchRoles from '../../hooks/fetchRoles';
// import getUser from '../../hooks/getUser';
// import InputMask from 'react-input-mask';
// import getHeaders from '../../utils/authHeaders';
// import { LoadingOutlined, MinusCircleOutlined, PlusCircleOutlined, PlusOutlined } from '@ant-design/icons';
// import AddPhoto from '../../assets/images/add.png';

// // console.log(app);
// const { TextArea } = Input;

// const EditProfile = () => {

//   const { t } = useTranslation(['common']);
//   const [userObj, setUserObj] = useState('');
//   const [phoneNumber, setPhoneNumber] = useState();
//   const [addUserResp, setAddUserResp] = useState({});
//   const [userImage, setUserImage] = useState(false);
//   const [pwd, setpwd] = useState('');
//   const [confirmPwd, setconfirmPwd] = useState('');
//   const [show, setShow] = useState(false);
//   const [loading, setLoading] = useState(false);
//   const [uploadedImg, setUploadedImg] = useState('');
//   const [imageuploading, setImageUploading] = useState(false);
//   const [uploadLogo, setUploadLogo] = useState(true);
//   const [isActive, setisActive] = useState(false);
//   // const [roles, setRoles ] = useSt
//   let navigate = useNavigate();

//   let user = JSON.parse(localStorage.getItem('user'));
//   let userId = user.result.data.user.userId;
//   getUser(userId, setUserObj);
//   useEffect(() => {
//     userObj && userObj.isActive == '1' ? setisActive(true) : ' ';
//     userObj && userObj.image ? setUserImage(true) : ' ';
//   }, [userObj]);

//   console.log(userObj.firstName)

//   const getBase64 = (img, callback) => {
//     const reader = new FileReader();
//     reader.addEventListener('load', () => callback(reader.result));
//     reader.readAsDataURL(img);
//   };
//   let header = getHeaders();
//   const uploadButton = (
//     <div>
//       {loading ? <LoadingOutlined /> : <PlusOutlined />}
//       <div
//         style={{
//           marginTop: 8,
//         }}
//       >
//         Upload
//       </div>
//     </div>
//   );

//   const beforeUpload = (file) => {
//     const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
//     if (!isJpgOrPng) {
//       message.error('You can only upload JPG/PNG file!');
//     }
//     const isLt2M = file.size / 1024 / 1024 < 2;
//     if (!isLt2M) {
//       message.error('Image must smaller than 2MB!');
//     }
//     return isJpgOrPng && isLt2M;
//   };
//   const handleuploadChange = async (info) => {
//     if (info.file.status === 'uploading') {
//       setUserImage(false);
//       setImageUploading(true);
//       setLoading(true);
//       return;
//     }
//     if (info.file.status === 'done') {
//       // Get this url from response in real world.
//       info.file ? console.log(info.file.response.result.data.Location) : console.log('Not found');
//       info.file ? setUploadedImg(info.file.response.result.data.Location) : ' ';
//       getBase64(info.file.originFileObj, (url) => {
//         setImageUploading(false);
//         setLoading(false);
//         setUploadLogo(false);
//       });
//     }
//   };
//   const fetchedRoles = useFetchRoles();
//   // console.log('fetchedRoles ', fetchedRoles);
//   const roles = fetchedRoles.response ? fetchedRoles.response.data : [];
//   // console.log('roles ', roles);
//   const [uploadObj, setUploadObj] = useState({});
//   const uploadData = async (userDetails) => {
//     try {
//       console.log('uploadObj ', userDetails);
//       const res = await axios.post(`${app.URL}/api/v1/admin/adminAccount/userUpdate/${userId}`, uploadObj, { headers: getHeaders() });
//       if (res.data.result.success) {
//         setAddUserResp(res.data.result.data);
//         setTimeout(() => {
//           navigate('/adminaccount/userlisting');
//         }, 1000);
//       } else if (!res.data.result.success) {
//         window.alert(res.data.result.msg);
//       }
//     } catch (err) {
//       console.log(err);
//       if (err) console.log(err.response.data.message);
//       else return;
//     }
//   };

//   const handleChange = (event) => {
//     setPhoneNumber(event.target.value);
//     // handle phone number input here
//   };
//   const onFinish = (values) => {
//     console.log('onFinish');
//     console.log('Success:', values);
//     if (pwd !== confirmPwd) {
//       console.log(pwd, confirmPwd);
//       message.error('Passwords do not match');
//       return;
//     }
//     // o={...values}
//     // console.log(values.validityDate.format().split('T')[0]);
//     setUploadObj({
//       firstName: values.firstName ? values.firstName : userObj.firstName,
//       lastName: values.lastName ? values.lastName : userObj.lastName,
//       image: uploadedImg ? uploadedImg : userObj.image,
//       password: values.password ? values.password : userObj.password,
//       phone: values.phone ? values.phone : userObj.phone,
//       roleId: values.roleId ? values.roleId : userObj.roleId,
//       isActive: isActive === true ? '1' : '0',
//       // userType: 'O',
//       createdByUserId: user.userId,
//       // knowUserId: values.knowUserId ? values.knowUserId : userObj.knowUserId,
//     });
//     setShow(true);
//   };
//   // console.log(uploadObj);
//   const submitData = () => {
//     console.log('calling');
//     if (Object.keys(uploadObj).length > 0) {
//       uploadData(uploadObj);
//     }
//   };
//   const handleClose = () => setShow(false);
//   // const handleShow = () => setShow(true);

//   // uploadData(uploadObj);
//   const onFinishFailed = (errorInfo) => {
//     console.log('Failed:', errorInfo);
//   };
//   function goBack() {
//     navigate('/adminaccount/userlisting', { replace: true });
//   }
//   window.addEventListener('popstate', goBack);
//   const validatePassword = (_, value) => {
//     if (value) {
//       if (value.length < 6) {
//         return Promise.reject('Password must be at least 6 characters long.');
//       }
//       if (!/[A-Z]/.test(value)) {
//         return Promise.reject('Password must contain at least one uppercase letter.');
//       }
//       if (!/[0-9]/.test(value)) {
//         return Promise.reject('Password must contain at least one digit.');
//       }
//     }
//     return Promise.resolve();
//   };

//   // console.log(userObj)
//   return (
//     <>
//       <div className='col-lg-12'>
//         <div className='dashboard-card'>
//           <div className='row'>
//             <div className='for-before col-lg-4 head fs-3 fw-bold' style={{ backgroundColor: '#a7c1d9', borderBottomRightRadius: '10px', color: '#FFFFFF' }}>
//            Edit Profile
//             </div>
//           </div>
//           <div class='col mb-5 mt-4'>
//             <div className='row' style={{ padding: '0 50px' }}>
//               <div class='col-lg-8 align-self-center mt-2 mb-4'>
//                 {userObj ? (
//                   <Form
//                     name='basic'
//                     labelCol={{
//                       span: 0,
//                     }}
//                     wrapperCol={{
//                       span: 24,
//                     }}
//                     initialValues={{
//                       remember: true,
//                       ['firstName']: userObj?.firstName,
//                       ['lastName']: userObj?.lastName,
//                       ['email']: userObj?.email,
//                       ['phone']: userObj?.phone,
//                       ['roleId']: userObj?.roleId,
//                       // ['knowUserId']: userObj?.knowUserId,
//                       ['isActive']: userObj?.isActive === '1' ? true : false,
//                     }}
//                     onFinish={onFinish}
//                     onFinishFailed={onFinishFailed}
//                     autoComplete='off'
//                   >
//                     {/* Select box for Role */}
//                     <div className='row'>
//                       <div className='col-lg-6'>
//                         <label>
//                           First Name <span className='required-star'>*</span>
//                         </label>
//                         <Form.Item
//                           // label='First Name'
//                           name='firstName'
//                           defaultValue='1'
//                           colon={false}
//                           rules={[
//                             {
//                               required: true,
//                               message: 'Enter Frist Name',
//                             },
//                           ]}
//                         >
//                           <Input value={userObj.firstName} placeholder='First Name' />
//                         </Form.Item>
//                       </div>
//                       <div className='col-lg-6'>
//                         <label>
//                           Last Name <span className='required-star'>*</span>
//                         </label>
//                         <Form.Item
//                           // label='Last Name'
//                           name='lastName'
//                           defaultValue='1'
//                           colon={false}
//                           rules={[
//                             {
//                               required: true,
//                               message: 'Enter Last Name',
//                             },
//                           ]}
//                         >
//                           <Input placeholder='Last Name' />
//                         </Form.Item>
//                       </div>
//                     </div>
//                     <div className='row'>
//                       <div className='col-lg-6'>
//                         <label>
//                           Mobile <span className='required-star'>*</span>
//                         </label>
//                         <Form.Item
//                           colon={false}
//                           label='Mobile'
//                           name='phone'
//                           rules={[
//                             {
//                               required: true,
//                               message: 'Enter Phone',
//                             },
//                           ]}
//                         >
//                           <InputMask mask='(999) 999-9999' onChange={handleChange}>
//                             {(inputProps) => <Input {...inputProps} placeholder='Mobile' />}
//                             {/* <Input /> */}
//                           </InputMask>
//                           {/* <Input placeholder='Input Contact' /> */}
//                         </Form.Item>
//                       </div>
//                       <div className='col-lg-6'>
//                         <label>Email</label>
//                         <Form.Item
//                           colon={false}
//                           label='Email'
//                           name='email'
//                           autoComplete='off'
//                           autofill='off'
//                           rules={[
//                             {
//                               required: true,
//                               type: 'email',
//                               message: 'Enter email',
//                             },
//                           ]}
//                         >
//                           <Input disabled={true} placeholder='Enter Email' autoComplete='new-email' defaultValue={' '} />
//                         </Form.Item>
//                       </div>
//                     </div>
//                     <div className='row'>
//                       {/* <div className='col-md-6'>
//                         <label>
//                           Know Id <span className='required-star'>*</span>
//                         </label>
//                         <Form.Item
//                           label='Know Id'
//                           colon={false}
//                           name='knowUserId'
//                           rules={[
//                             {
//                               required: true,
//                               message: 'Provide know Id',
//                             },
//                           ]}
//                         >
//                           <Input placeholder='Know Id' defaultValue={' '} />
//                         </Form.Item>
//                       </div> */}
//                       <div className='col-md-12'>
//                         <label>
//                           Role <span className='required-star'>*</span>
//                         </label>
//                         <Form.Item
//                           label='Role'
//                           colon={false}
//                           name='roleId'
//                           rules={[
//                             {
//                               required: true,
//                               message: 'Select Role',
//                             },
//                           ]}
//                         >
//                           <Select
//                             style={{
//                               width: 180,
//                             }}
//                             placeholder='Select Role'
//                           >
//                             {roles.map((role) => {
//                               return (
//                                 <Option key={role.roleId} value={role.roleId}>
//                                   {role.name}
//                                 </Option>
//                               );
//                             })}
//                           </Select>
//                         </Form.Item>
//                       </div>
//                     </div>
//                     <div className='row'>
//                       <div className='col-lg-6'>
//                         <label>Password </label>
//                         <Form.Item colon={false} label='Password' name='password' autoComplete='off'>
//                           <Input.Password onChange={(e) => setpwd(e.target.value)} autoComplete='new-password' placeholder='Password' />
//                         </Form.Item>
//                         <label className='mt-1' style={{ display: 'inline-block', width: '17%' }}>
//                           Active
//                         </label>
//                         <Form.Item style={{ display: 'inline-block', width: '50%' }} colon={false} valuePropName='checked' name='isActive'>
//                           <Switch onChange={(e) => setisActive(e)} />
//                         </Form.Item>
//                       </div>
//                       <div className='col-lg-6'>
//                         <label>Confirm Password </label>
//                         <Form.Item colon={false} label='Confirm Password' name='confirmPassword'>
//                           <Input.Password onChange={(e) => setconfirmPwd(e.target.value)} placeholder='Confirm Password' />
//                         </Form.Item>
//                       </div>
//                     </div>
//                     <div className='row'>
//                       {/* <div className='col-lg-6 text-right mb-5'>
//                       <Form.Item label='Active' colon={false} valuePropName='checked' name='isActive'>
//                         <Switch />
//                       </Form.Item>
//                     </div> */}

//                       <div className='col-lg-6 mb-5'>
//                         <Form.Item style={{ width: '100%', marginTop: '20px' }} wrapperCol={{}}>
//                           {addUserResp.code === 200 ? (
//                             <Modal show={show} onHide={handleClose}>
//                               <Modal.Header closeButton>
//                                 <Modal.Title className='text-center'>Success</Modal.Title>
//                               </Modal.Header>
//                               <Modal.Body>{addUserResp.message}</Modal.Body>
//                               <Modal.Footer></Modal.Footer>
//                             </Modal>
//                           ) : addUserResp.code === 400 ? (
//                             <Modal show={show} onHide={handleClose}>
//                               <Modal.Header closeButton>
//                                 <Modal.Title className='text-center'>Failed</Modal.Title>
//                               </Modal.Header>
//                               <Modal.Body>{addUserResp.data.message}</Modal.Body>
//                               <Modal.Footer></Modal.Footer>
//                             </Modal>
//                           ) : (
//                             <Modal show={show} onHide={handleClose}>
//                               {/* <Modal.Header closeButton>
//                                 <Modal.Title>Confirmation</Modal.Title>
//                               </Modal.Header> */}
//                               <Modal.Body>Are you sure you want to Update profile?</Modal.Body>
//                               <Modal.Footer>
//                                 <Button variant='secondary' onClick={handleClose}>
//                                   Cancel
//                                 </Button>
//                                 <Button variant='primary' htmlType='submit' onClick={submitData}>
//                                   Confirm
//                                 </Button>
//                               </Modal.Footer>
//                             </Modal>
//                           )}
//                         </Form.Item>
//                       </div>
//                     </div>
//                     <div className='row'>
//                       <button
//                         onClick={() => {
                         
//                             navigate('/dashboard/listing');
                          
//                         }}
//                         type='primary'
//                         className='btn btn-primary button patient-cancel-btn col-lg-3 mr-2 col-lg-5 mr-2'
//                       >
//                         Cancel
//                       </button>

//                       <button type='primary' className='col-lg-5 btn btn-primary button' htmlType='submit'>
//                         Save
//                       </button>
//                     </div>
//                     ;{/* Select box for Organization */}
//                   </Form>
//                 ) : (
//                   ' '
//                 )}
//               </div>
//               <div class='col-lg-4 mt-2 mb-4'>
//                 <div className='col-lg-9'>
//                   {userObj ? (
//                     <Form.Item valuePropName='fileList'>
//                       <Upload listType='picture-card' className='avatar-uploader' showUploadList={false} action={`${app.URL}/api/v1/general/user/imageupload`} beforeUpload={beforeUpload} onChange={handleuploadChange} headers={header}>
//                         {userObj?.image && userImage ? (
//                           <img className='' src={userObj?.image} alt='avatar' />
//                         ) : uploadedImg && !imageuploading ? (
//                           <img className='' src={uploadedImg} alt='avatar' />
//                         ) : !uploadedImg && imageuploading ? (
//                           <LoadingOutlined style={{ fontSize: '40px' }} />
//                         ) : (
//                           <>
//                             <img className='for-add-img' src={AddPhoto} alt='avatar' />
//                             <p className='add-text'>Upload Image</p>
//                           </>
//                         )}
//                       </Upload>
//                     </Form.Item>
//                   ) : (
//                     ' '
//                   )}
//                 </div>

//                 {/* <Form.Item valuePropName='fileList'>
//                     <Upload
//                       // name="avatar"
//                       listType='picture-card'
//                       className='avatar-uploader'
//                       showUploadList={false}
//                       action={`${app.URL}/api/v1/general/user/imageupload`}
//                       beforeUpload={beforeUpload}
//                       onChange={handleuploadChange}
//                       headers={header}
//                     >
//                       {uploadLogo ? (
//                         loading ? (
//                           uploadButton
//                         ) : (
//                           uploadButton
//                         )
//                       ) : (
//                         <img
//                           src={uploadedImg}
//                           alt='avatar'
//                           style={{
//                             width: '100%',
//                           }}
//                         />
//                       )}
//                     </Upload>
//                   </Form.Item> */}
//                 {/* <div className='selector-div' style={{backgroundColor:isActive?"#56A75F":" "}}>
                 
//                 </div> */}
//                 {/* <Form.Item
//                   style={{ width: "100%", marginTop: "20px" }}
//                   wrapperCol={{
//                   }}
//                 >
//                   {addUserResp.code === 200 ? (
//                     <Modal show={show} onHide={handleClose}>
//                       <Modal.Header closeButton>
//                         <Modal.Title className='text-center'>Success</Modal.Title>
//                       </Modal.Header>
//                       <Modal.Body>{addUserResp.message}</Modal.Body>
//                       <Modal.Footer>
//                       </Modal.Footer>
//                     </Modal>
//                   ) : addUserResp.code === 400 ? (
//                     <Modal show={show} onHide={handleClose}>
//                       <Modal.Header closeButton>
//                         <Modal.Title className='text-center'>Failed</Modal.Title>
//                       </Modal.Header>
//                       <Modal.Body>{addUserResp.data.message}</Modal.Body>
//                       <Modal.Footer></Modal.Footer>
//                     </Modal>
//                   ) : (
//                     <Modal show={show} onHide={handleClose}>
//                       <Modal.Header closeButton>
//                         <Modal.Title>Confirmation</Modal.Title>
//                       </Modal.Header>
//                       <Modal.Body>Are you sure you want to add user?</Modal.Body>
//                       <Modal.Footer>
//                         <Button variant='secondary' onClick={handleClose}>
//                           Cancel
//                         </Button>
//                         <Button variant='primary' htmlType='submit' onClick={submitData}>
//                       Confirm
//                         </Button>
//                       </Modal.Footer>
//                     </Modal>
//                   )}

//                   <button style={{ width: "100%" }} type='primary' class='btn btn-primary button' htmlType='submit'>
//                     Save
//                   </button>
//                 </Form.Item> */}
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//       {/* <div class='container'>
//       <div class='row mt-4 w-75 tbl'>
//         <div class='row topbar ' style={{ height: '70px' }}>
//           <div class='col-lg-4 fs-3 fw-bold'>
//             {' '}
//             <LeftCircleOutlined
//               onClick={() => {
//                 navigate('/adminaccount/userlisting');
//               }}
//               className='mt-3 ml-4 back-icon'
//               style={{ fontSize: '38px' }}
//             />
//           </div>
//           <div class='col-lg-7 fs-3 fw-bold '>
//             <h2 class=' mb-5 mt-4 fw-bold text-white fs-4 ml-4'> {t('Add User')} </h2>
//           </div>
//         </div>
        
//       </div>
//     </div> */}
//     </>
//   );
// };

// export default EditProfile;


import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
// import { message, Switch, Select, Form, Input, Space, Upload } from 'antd';
import { message, DatePicker, Row, Col, Select, Form, Input, Upload, Space, Button as BTN, Switch, Textarea } from 'antd';
import Button from 'react-bootstrap/Button';

import Modal from 'react-bootstrap/Modal';
import { app } from '../../config';
import useFetchRoles from '../../hooks/fetchRoles';
import getUser from '../../hooks/getUser';
import InputMask from 'react-input-mask';
import getHeaders from '../../utils/authHeaders';
import { LoadingOutlined, MinusCircleOutlined, PlusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import AddPhoto from '../../assets/images/add.png';
import { UpdateRecord } from '../../layouts/UpdateRecord';
import { InputField, InputMasker, SelectField, TextAreaField } from '../../layouts/FormFields';

// console.log(app);
const { TextArea } = Input;

const EditProfile = () => {
  const { t } = useTranslation(['common']);
  const [userObj, setUserObj] = useState('');
  const [phoneNumber, setPhoneNumber] = useState();
  const [addUserResp, setAddUserResp] = useState({});
  const [userImage, setUserImage] = useState(false);
  const [pwd, setpwd] = useState('');
  const [confirmPwd, setconfirmPwd] = useState('');
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [uploadedImg, setUploadedImg] = useState('');
  const [imageuploading, setImageUploading] = useState(false);
  const [uploadLogo, setUploadLogo] = useState(true);
  const [isActive, setisActive] = useState(false);
  // const [roles, setRoles ] = useSt
  let navigate = useNavigate();

  let user = JSON.parse(localStorage.getItem('user'));
  let userId = user.result.data.user.userId;
  getUser(userId, setUserObj, setUserImage, setUploadedImg);
  useEffect(() => {
    userObj && userObj.isActive == '1' ? setisActive(true) : ' ';
    // userObj && userObj.image ? setUserImage(true) : ' ';
  }, [userObj]);

  console.log(userObj.firstName);

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  };
  let header = getHeaders();
  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
  };
  const handleuploadChange = async (info) => {
    if (info.file.status === 'uploading') {
      setUserImage(false);
      setImageUploading(true);
      setLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      info.file ? console.log(info.file.response.result.data.Location) : console.log('Not found');
      info.file ? setUploadedImg(info.file.response.result.data.Location) : ' ';
      getBase64(info.file.originFileObj, (url) => {
        setImageUploading(false);
        setLoading(false);
        setUploadLogo(false);
      });
    }
  };
  const fetchedRoles = useFetchRoles();
  // console.log('fetchedRoles ', fetchedRoles);
  const roles = fetchedRoles.response ? fetchedRoles.response.data : [];
  // console.log('roles ', roles);
  const [uploadObj, setUploadObj] = useState({});
  const uploadData = async (userDetails) => {
    try {
      console.log('uploadObj ', userDetails);
      const res = await axios.post(`${app.URL}/api/v1/admin/adminAccount/userUpdate/${userId}`, uploadObj, { headers: getHeaders() });
      if (res.data.result.success) {
        setAddUserResp(res.data.result.data);
        setTimeout(() => {
          navigate('/adminaccount/userlisting');
        }, 1000);
      } else if (!res.data.result.success) {
        window.alert(res.data.result.msg);
      }
    } catch (err) {
      console.log(err);
      if (err) console.log(err.response.data.message);
      else return;
    }
  };

  const handleChange = (event) => {
    setPhoneNumber(event.target.value);
    // handle phone number input here
  };
  const onFinish = (values) => {
    console.log('onFinish');
    console.log('Success:', values);
    if (pwd !== confirmPwd) {
      console.log(pwd, confirmPwd);
      message.error('Passwords do not match');
      return;
    }
    // o={...values}
    // console.log(values.validityDate.format().split('T')[0]);
    setUploadObj({
      firstName: values.firstName ? values.firstName : userObj.firstName,
      lastName: values.lastName ? values.lastName : userObj.lastName,
      image: uploadedImg ? uploadedImg : userObj.image,
      password: values.password ? values.password : userObj.password,
      phone: values.phone ? values.phone : userObj.phone,
      roleId: values.roleId ? values.roleId : userObj.roleId,
      isActive: isActive === true ? '1' : '0',
      // userType: 'O',
      createdByUserId: user.userId,
      // knowUserId: values.knowUserId ? values.knowUserId : userObj.knowUserId,
    });
    setShow(true);
  };
  // console.log(uploadObj);
  const submitData = () => {
    console.log('calling');
    if (Object.keys(uploadObj).length > 0) {
      uploadData(uploadObj);
    }
  };
  const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);

  // uploadData(uploadObj);
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  function goBack() {
    navigate('/dashboard/listing', { replace: true });
  }
  window.addEventListener('popstate', goBack);
  const validatePassword = (_, value) => {
    if (value) {
      if (value.length < 6) {
        return Promise.reject('Password must be at least 6 characters long.');
      }
      if (!/[A-Z]/.test(value)) {
        return Promise.reject('Password must contain at least one uppercase letter.');
      }
      if (!/[0-9]/.test(value)) {
        return Promise.reject('Password must contain at least one digit.');
      }
    }
    return Promise.resolve();
  };

  // console.log(userObj)
  return (
    <UpdateRecord
      pageTitle='Edit Profile'
      response={userObj}
      handleClose={handleClose}
      submitData={submitData}
      switchBtn={true}
      show={show}
      updateMessage='Are you sure you want to update profile?'
      upload={true}
      handleuploadChange={handleuploadChange}
      beforeUpload={beforeUpload}
      action={`${app.URL}/api/v1/general/user/imageupload`}
      imageuploading={imageuploading}
      uploadedImg={uploadedImg}
      userImage={userImage}
      initialValues={{
        remember: true,
        ['firstName']: userObj?.firstName,
        ['lastName']: userObj?.lastName,
        ['email']: userObj?.email,
        ['phone']: userObj?.phone,
        ['roleId']: userObj?.roleId,
        // ['knowUserId']: userObj?.knowUserId,
        ['isActive']: userObj?.isActive === '1' ? true : false,
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      goBack={goBack}
      addUserResp={addUserResp}
      inputs={[
        <InputField
          col='col-lg-6'
          rules={[
            {
              required: true,
              message: 'Enter First Name',
            },
          ]}
          placeholder='First Name'
          name='firstName'
          label='First Name'
          required={true}
        />,
        <InputField
          rules={[
            {
              required: true,
              message: 'Enter Last Name',
            },
          ]}
          col='col-lg-6'
          placeholder='Last Name'
          name='lastName'
          label='Last Name'
          required={true}
        />,

        <InputField col='col-lg-6' placeholder='Email' name='email' label='Email' disabled={true} />,
        // <InputField
        //   rules={[
        //     {
        //       required: true,
        //       message: 'Provide Know Id',
        //     },
        //   ]}
        //   col='col-lg-6'
        //   placeholder='Know Id'
        //   name='knowUserId'
        //   label='Know Id'
        //   required={true}
        // />,
        <InputField
          rules={[
            {
              required: true,
              message: 'Password is required.',
              // type: 'password',
            },
            { validator: validatePassword },
          ]}
          col='col-lg-6'
          placeholder='Password'
          name='password'
          label='Password'
          required={true}
        />,
        <InputField
          rules={[
            {
              required: true,
              message: 'Confirm Password is required.',
              // type: 'password',
            },
            { validator: validatePassword },
          ]}
          col='col-lg-6'
          placeholder='Confirm Password'
          name='confirmPassword'
          label='Confirm Password'
          required={true}
        />,
      ]}
      inputMask={[<InputMasker col='col-md-6' placeholder='Mobile' name='phone' handleChange={handleChange} label='Mobile' disabled={true} />]}
      selects={[
        <SelectField
          options={roles.map((role) => (
            <Select.Option key={role.roleId} value={role.roleId}>
              {role.name}
            </Select.Option>
          ))}
          col='col-lg-6'
          label='Role'
          name='roleId'
          placeholder='Select Role'
          required={true}
          rules={[
            {
              required: true,
              message: 'Select Role',
            },
          ]}
        />,
        // <SelectField
        //   options={statusTypes}
        //   col='col-lg-6'
        //   label='Status'
        //   name='status'
        //   placeholder='Select Status'
        //   required={true}
        //   rules={[
        //     {
        //       required: true,
        //       message: 'Select Status',
        //     },
        //   ]}
        // />,
      ]}
      textareas={[]}
    />
  );
  
};

export default EditProfile;

