import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { app } from '../../config';
import getHeaders from '../../utils/authHeaders';
import { useNavigate } from 'react-router-dom';
import { UpdateRecord } from '../../layouts/UpdateRecord';
import { InputField, SelectField, TextAreaField } from '../../layouts/FormFields';
import { Button, Form, Space, Upload, message, ColorPicker, Select, Switch } from 'antd'; //4.24.7
import { InfoCircleOutlined, UploadOutlined } from '@ant-design/icons';
import DividerLine from '../../layouts/DividerLine';
import TestComponent from '../Products/TestComponent';

const UpdateGroup = () => {
  let navigate = useNavigate();
  function goBack() {
    navigate('/labtestgroups/view', { replace: true });
  }
  window.addEventListener('popstate', goBack);
  const [loader, setLoader] = useState(false);
  const [addUserResp, setAddUserResp] = useState({});
  const [labGroup, setlabGroup] = useState([]);
  const [uploadObj, setUploadObj] = useState({});
  const [show, setShow] = useState(false);
  const [uploadVideo, setuploadVideo] = useState('');
  const [whatNowVideo, setWhatNowVideo] = useState('');
  const [whatNowDesc, setWhatNowDesc] = useState('');
  const [riskVideo, setRiskVideo] = useState('');
  const [riskDesc, setRiskDesc] = useState('');
  const [contentType, setContentType] = useState();
  const [uplVid, setuplVid] = useState(true);
  let [textEditorValue, settextEditorValue] = useState();
  // const [resType, setResType] = useState('');
  const [allPrds, setAllPrds] = useState([]);
  const [comments, setComments] = useState('');

  // const [contentDis, setContentDis] = useState('');

  const getAllProducts = async () => {
    const formData = {
      limit: 100,
      offset: 0,
      searchBy: 'name',
      searchTxt: '',
    };
    const res = await axios.post(`${app.URL}/api/v1/admin/product/listing`, formData, { headers: getHeaders() });
    // console.log(res.data.result.data);
    res.data.result.success && setAllPrds(res.data.result.data.data);
  };

  const getLabTestById = async (resultElementGroupId) => {
    setLoader(true);
    const res = await axios.get(`${app.URL}/api/v1/admin/labtestgroups/view/${resultElementGroupId}`, { headers: getHeaders() });
    console.log(res);
    res.data.result.success && setlabGroup(res.data.result.data);
    res.data.result.success && settextEditorValue(res.data.result.data.description);
    res.data.result.success && setRiskDesc(res.data.result.data.riskDesc);
    res.data.result.success && setWhatNowDesc(res.data.result.data.whatNowDesc);
    res.data.result.success && setComments('');

    res.data.result.success && getAllProducts();
    res.data.result.data.videoUrl && setuploadVideo(res.data.result.data.videoUrl);
    res.data.result.data.whatNowVideoUrl && setWhatNowVideo(res.data.result.data.whatNowVideoUrl);
    res.data.result.data.riskVideoUrl && setRiskVideo(res.data.result.data.riskVideoUrl);

    setLoader(false);
  };

  useEffect(() => {
    const resultElementGroupId = localStorage.getItem('resultElementGroupId');
    getLabTestById(resultElementGroupId);
  }, []);

  let user = JSON.parse(localStorage.getItem('user'));

  const putlabPanel = async (details) => {
    // console.log(details);
    let userId = user?.result.data.user.userId;
    details.userId = userId;
    setLoader(true);
    try {
      const res = await axios.post(`${app.URL}/api/v1/admin/labtestgroups/update/${labGroup?.resultElementGroupId}`, details, { headers: getHeaders() });
      setLoader(false);
      setShow(false);
      console.log(res);

      if (res.data.result.success) {
        setAddUserResp(res.data.result.data);
        message.success(res.data.result.msg);
        setTimeout(goBack, 500);
      } else if (!res.data.result.success) {
        message.warning(res.data.result.msg);
      }
    } catch (err) {
      console.log(err);
      setLoader(false);
      setShow(false);
      if (err) console.log(err.response.data.message);
      else return;
    }
  };
  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    // reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  };
  const beforeUpload = (file, e) => {
    console.log(file);

    if (file.type === 'image/jpeg' || file.type === 'image/png') {
      console.log('setting image type');
      setContentType('img');
    } else if (file.type === 'video/x-matroska' || file.type === 'video/mp4') setContentType('video');
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'video/x-matroska' || file.type === 'video/mp4';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    console.log('file Checked');
    const isLt2M = file.size / 1024 / 1024 < 20;
    if (!isLt2M) {
      setLargeFile(true);
      message.error('Image must smaller than 20MB!');
    }
    console.log('message');
    return isJpgOrPng && isLt2M;
  };

  const handleuploadChange = (info) => {
    setuploadVideo('');

    console.log(info);
    if (info.file.status === 'uploading') {
      return;
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      console.log(info.file);
      info.file ? console.log(info.file.response.result.data.Location) : console.log('Not found');
      info.file ? setuploadVideo(info.file.response.result.data.Location) : ' ';
      getBase64(info.file.originFileObj, (url) => {
        setuplVid(false);
      });
    }
  };

  const handleuploadChange2 = (info, flag) => {
    console.log(info, flag);
    if (flag === 'whatNowVideoUrl') {
      setWhatNowVideo('');
      if (info.file.status === 'uploading') {
        return;
      }
      if (info.file.status === 'done') {
        // Get this url from response in real world.
        console.log(info.file);
        info.file ? console.log(info.file.response.result.data.Location) : console.log('Not found');
        info.file ? setWhatNowVideo(info.file.response.result.data.Location) : ' ';
        getBase64(info.file.originFileObj, (url) => {
          setuplVid(false);
        });
      }
    } else {
      setRiskVideo('');
      if (info.file.status === 'uploading') {
        return;
      }
      if (info.file.status === 'done') {
        // Get this url from response in real world.
        console.log(info.file);
        info.file ? console.log(info.file.response.result.data.Location) : console.log('Not found');
        info.file ? setRiskVideo(info.file.response.result.data.Location) : ' ';
        getBase64(info.file.originFileObj, (url) => {
          setuplVid(false);
        });
      }
    }
  };

  const onFinish = (values) => {
    console.log(values);
    if (values.ranges?.length === 0 && (values.resultType === 'R' || values.resultType === 'P')) {
      message.info('Please add minimum one range for result!');
    } else {
      setUploadObj({
        isActive: values.isActive ? 1 : 0,
        description: textEditorValue,
        videoUrl: uploadVideo ? uploadVideo : null,
        whatNowDesc,
        whatNowVideoUrl: whatNowVideo ? whatNowVideo : null,
        riskDesc,
        riskVideoUrl: riskVideo ? riskVideo : null,
        isPolarBarChart: values.isPolarBarChart ? 1 : 0,
        contentDisplay: values.contentDisplay ? values.contentDisplay : null,
        whatNowView: values.whatNowView ? values.whatNowView : null,
        riskView: values.riskView ? values.riskView : null,
      });
      setShow(true);
    }
    // console.log(uploadVideo);
  };
  const submitData = () => {
    console.log(uploadObj);
    if (Object.keys(uploadObj).length > 0) {
      console.log(uploadObj);
      putlabPanel(uploadObj);
    }
  };
  const onFinishFailed = () => {};

  const labTestToggleVideo = () => {
    setuploadVideo('');
    setTimeout(() => setuploadVideo(labGroup.videoUrl), 500);
  };

  const onRemoveToggle = (flag) => {
    if (flag === 'riskVideoUrl') {
      setRiskVideo('');
      setTimeout(() => setRiskVideo(labGroup.riskVideoUrl), 500);
    } else {
      setWhatNowVideo('');
      setTimeout(() => setWhatNowVideo(labGroup.whatNowVideoUrl), 500);
    }
  };

  const handleContentDisplay = (event) => {
    console.log(event);
    // setContentDis(event);
  };

  // const product = allPrds.filter((item) => item.productId === labGroup?.productId)

  const [videoNote, showVideoNote] = useState(false);

  const handleEditorChange = (htmlContent) => {
    settextEditorValue(htmlContent);
    // Do something with the htmlContent, such as saving it to state or sending it to an API
  };
  const handleWhatEditorChange = (htmlContent) => {
    setWhatNowDesc(htmlContent);
    // Do something with the htmlContent, such as saving it to state or sending it to an API
  };
  const handleRiskEditorChange = (htmlContent) => {
    setRiskDesc(htmlContent);
    // Do something with the htmlContent, such as saving it to state or sending it to an API
  };
   const handleCommentChange = (htmlContent) => {
     setComments(htmlContent);
     // Do something with the htmlContent, such as saving it to state or sending it to an API
   };

  return (
    <UpdateRecord
      pageTitle='Update CIS Panel'
      loading={loader}
      response={labGroup}
      goBack={goBack}
      upload={false}
      show={show}
      handleClose={() => setShow(false)}
      addUserResp={addUserResp}
      submitData={submitData}
      updateMessage='Are you sure you want to update CIS panel?'
      initialValues={{
        remember: true,
        ['cisPanelCode']: labGroup?.cisPanelCode,
        ['cisPanelName']: labGroup?.cisPanelName,
        // ['description']: labGroup?.description,
        // ['description']: textEditorValue,
        // ['whatNowDesc']: labGroup?.whatNowDesc,
        // ['whatNowDesc']: whatNowDesc,
        ['whatNowView']: labGroup?.whatNowView,
        ['contentDisplay']: labGroup?.contentDisplay,
        // ['riskDesc']: labGroup?.riskDesc,
        // ['riskDesc']: riskDesc,
        ['riskView']: labGroup?.riskView,
        ['productId']: labGroup?.productId,
        ['product']: labGroup?.knowPanelName,
        ['isPolarBarChart']: labGroup?.isPolarBarChart,
        ['isActive']: labGroup?.isActive,
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      switchBtn={false}
      inputs={[
        <InputField col='col-md-4' name='cisPanelCode' disabled={true} placeholder='Panel Code' label='Panel Code' />,
        <InputField col='col-md-4' name='cisPanelName' disabled={true} placeholder='Panel Name' label='Panel Name' />,
        <InputField col='col-md-4 capitalize' name='product' disabled={true} placeholder='Product' label='Product' />,
        // <SelectField
        //   col='col-md-4'
        //   name='productId'
        //   label='Product'
        //   rules={[
        //     {
        //       required: true,
        //       message: 'Select Product',
        //     },
        //   ]}
        //   placeholder='Product'
        //   required={true}
        //   options={allPrds.map((item) => (
        //     <Select.Option value={item.productId}>{item.name}</Select.Option>
        //   ))}
        // />,

        <div className='col-md-4'>
          <Form.Item label='Polar Bar Chart' className='selector-main' colon={false} valuePropName='checked' name='isPolarBarChart'>
            <Switch />
          </Form.Item>
        </div>,
        <div className='col-md-4'>
          <Form.Item label='Active' className='selector-main' colon={false} valuePropName='checked' name='isActive'>
            <Switch />
          </Form.Item>
        </div>,
        <DividerLine />,
        <div className='col-md-8'>
          <div className='row'>
            <SelectField
              col='col-md-5'
              name='contentDisplay'
              onChange={(e) => handleContentDisplay(e)}
              options={[
                { value: 'none', name: 'None' },
                { value: 'video', name: 'Video' },
                { value: 'text', name: 'Text' },
                { value: 'both', name: 'Both' },
              ].map((item) => (
                <Select.Option value={item.value}>{item.name}</Select.Option>
              ))}
              rules={[
                {
                  required: true,
                  message: 'Select Content Display',
                },
              ]}
              required={true}
              label='Show Description'
            />
          </div>
        </div>,
        // rich text editor
        <div className='col-md-8 mb-4'>
          <TestComponent title='Description' onChange={handleEditorChange} textvalue={textEditorValue} />
        </div>,
        // <TextAreaField showCount={true} maxLength={2500} col='col-md-8' name='description' placeholder='Enter description' label='Description' rows={8} />,
        <div className='col-md-4 col-12  mb-4'>
          {uploadVideo && <label>Video</label>}
          {uploadVideo && (
            <video style={{ width: '100%', borderRadius: '5px', boxShadow: '3px 3px 10px #ccc' }} controls title='YouTube video player'>
              <source src={uploadVideo} type='video/mp4'></source>
            </video>
          )}

          <div className='d-flex'>
            <Form.Item colon={false} name='video' className='mt-2 mb-0 w-100'>
              <Space
                direction='vertical'
                style={{
                  width: '100%',
                }}
                size='large'
              >
                <Upload onRemove={labTestToggleVideo} listType='picture' maxCount={1} className='avatar-uploader' headers={getHeaders()} action={`${app.URL}/api/v1/general/product/videoUpload`} beforeUpload={beforeUpload} onChange={handleuploadChange}>
                  <Button icon={<UploadOutlined />} className={'upload-btn'} style={{ color: 'black' }}>
                    Upload Video
                  </Button>
                </Upload>
              </Space>
            </Form.Item>

            <InfoCircleOutlined onClick={() => showVideoNote(!videoNote)} onMouseEnter={() => showVideoNote(true)} onMouseLeave={() => showVideoNote(false)} className='mx-3' style={{ fontSize: '1rem' }} />
            {videoNote && <div className='mt-5 absolute'>Note: Upload size for video is 50mb supported format for video are (mkv/mp4)</div>}
          </div>
        </div>,
        // panel comments
        // <div className='col-md-12'>
        //   <div className='row'>
        //     <DividerLine />
        //     <h1 style={{ fontSize: '1rem', fontWeight: '600', marginBottom: '1rem' }}>Comments</h1>

        //     <div className='col-md-8 mb-4'>
        //       <TestComponent onChange={handleCommentChange} textvalue={comments} />
        //     </div>
        //   </div>
        // </div>,
        // what now and risk videos and descriptions
        <div className='col-md-12'>
          {[
            { title: 'WHAT NOW', name: 'whatNowDesc', isVideo: whatNowVideo, videoName: 'whatNowVideoUrl', view: 'whatNowView', handlerFunc: handleWhatEditorChange, description: whatNowDesc },
            { title: 'RISK', name: 'riskDesc', isVideo: riskVideo, videoName: 'riskVideoUrl', view: 'riskView', handlerFunc: handleRiskEditorChange, description: riskDesc },
          ].map((item) => (
            <WhatNowRiskSection item={item} onRemoveToggle={onRemoveToggle} beforeUpload={beforeUpload} handleuploadChange2={handleuploadChange2} />
          ))}
        </div>,
      ]}
      //videoUrl={uploadVideo} // If video render on right side of page through component!
    />
  );
};

const WhatNowRiskSection = ({ item, onRemoveToggle, beforeUpload, handleuploadChange2 }) => {
  const [videoNotes, showVideoNotes] = useState(false);
  console.log(item);
  return (
    <div className='row'>
      <DividerLine />
      <h1 style={{ fontSize: '1rem', fontWeight: '600', marginBottom: '1rem' }}>{item.title}</h1>
      <div className='col-md-8 col-12'>
        <div className='row'>
          <SelectField
            col='col-md-5 col-12'
            name={item.view}
            label='Show Description'
            rules={[
              {
                required: true,
                message: 'Choose visual to display',
              },
            ]}
            placeholder='Group'
            required={true}
            options={[
              { title: 'None', value: 'none' },
              { title: 'Video', value: 'video' },
              { title: 'Text', value: 'text' },
              { title: 'Both', value: 'both' },
            ].map((item) => (
              <Select.Option value={item.value}>{item.title}</Select.Option>
            ))}
          />
        </div>
      </div>
      <div className='col-md-8 mb-4'>
        <TestComponent title='Description' onChange={item.handlerFunc} textvalue={item.description} />
      </div>

      {/* <TextAreaField showCount={true} maxLength={2500} col='col-md-8' name={item.name} placeholder='Enter description' label='Description' rows={8} /> */}
      <div className='col-md-4 col-12 mb-4'>
        {item.isVideo && <label>Video</label>}
        {item.isVideo && (
          <video style={{ width: '100%', borderRadius: '5px', boxShadow: '3px 3px 10px #ccc' }} controls title='YouTube video player'>
            <source src={item.isVideo} type='video/mp4'></source>
          </video>
        )}
        <div className='d-flex'>
          <Form.Item colon={false} name='video' className='mt-2 mb-0 w-100'>
            <Space
              direction='vertical'
              style={{
                width: '100%',
              }}
              size='large'
            >
              <Upload onRemove={() => onRemoveToggle(item.videoName)} listType='picture' maxCount={1} className='avatar-uploader' headers={getHeaders()} action={`${app.URL}/api/v1/general/product/videoUpload`} beforeUpload={beforeUpload} onChange={(e) => handleuploadChange2(e, item.videoName)}>
                <Button icon={<UploadOutlined />} className={'upload-btn'} style={{ color: 'black' }}>
                  Upload Video
                </Button>
              </Upload>
            </Space>
          </Form.Item>
          <InfoCircleOutlined onClick={() => showVideoNotes(!videoNotes)} onMouseEnter={() => showVideoNotes(true)} onMouseLeave={() => showVideoNotes(false)} className='mx-3' style={{ fontSize: '1rem' }} />
          {videoNotes && <div className='mt-5 absolute'>Note: Upload size for video is 50mb supported format for video are (mkv/mp4)</div>}
        </div>
      </div>
    </div>
  );
};

export default UpdateGroup;
