


import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { message, Switch, Form, Upload } from 'antd';
import { app } from '../../config';
import getHeaders from '../../utils/authHeaders';

import { InputField,  TextAreaField } from '../../layouts/FormFields';

import { LoadingOutlined} from '@ant-design/icons';

import AddPhoto from '../../assets/images/add.png';
import {  useState } from 'react';
import getBoxIngType from '../../hooks/getBoxIngType';
import LoaderImage from '../LoaderImage';

const UpdateModal = ({ showUpdateModal, setShowUpdateModal, setIsRender }) => {
  const { t } = useTranslation(['common']);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);

  const [contentType, setContentType] = useState();
  const [uplVid, setuplVid] = useState(true);

  const [imageuploading, setimageuploading] = useState(false);
  const [uploadImage, setuploadImage] = useState('');

  const ingredientId = localStorage.getItem('ingredientId');

  console.log(uploadImage && !imageuploading, uploadImage);

  let headers = getHeaders();

  const beforeUpload = (file, e) => {
    console.log(file);

    if (file.type === 'image/jpeg' || file.type === 'image/png') {
      console.log('setting image type');
      setContentType('img');
    } else if (file.type === 'video/x-matroska' || file.type === 'video/mp4') setContentType('video');
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'video/x-matroska' || file.type === 'video/mp4';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    console.log('file Checked');
    const isLt2M = file.size / 1024 / 1024 < 20;
    if (!isLt2M) {
      setLargeFile(true);
      message.error('Image must smaller than 20MB!');
    }
    console.log('message');
    return isJpgOrPng && isLt2M;
  };

  let navigate = useNavigate();
  const [uploadObj, setUploadObj] = useState({});
  function goBack() {
    navigate('/boxtypeing/view', { replace: true });
  }

  const user = JSON.parse(localStorage.getItem('user'));
  const uploadData = async (userDetails) => {
    setLoading(true);
    let userId = user?.result.data.user.userId;
    userDetails.userId = userId;
    try {
      const res = await axios.post(`${app.URL}/api/v1/admin/boxtypeing/update/${ingredientId}`, userDetails, { headers: getHeaders() });
      setLoading(false);
      setShow(false);

      if (res.data.result.success) {
        setShowUpdateModal(false);
         setIsRender(true)

        // setTimeout(goBack, 500);

        // navigate('boxtypeing/view');

        // setAddUserResp(res.data.result.data);
        message.success(res.data.result.msg);

       
      } else if (!res.data.result.success) {
        message.warning(res.data.result.msg);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
      setShow(false);

      if (err) console.log(err.response.data.message);
      else return;
    }
  };

  const onFinish = (values) => {
    console.log('On Finish', values);

    const boxObj = {
      ...values,
      image: uploadImage,
    };
    console.log('Box Obj', boxObj);

    if (Object.keys(boxObj).length > 0) {
      uploadData(boxObj);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const handleuploadImage = (info) => {
    console.log('Info', info);
    if (info.file.status === 'uploading') {
      setimageuploading(true);
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      info.file ? console.log(info.file.response.result.data.Location) : console.log('Not found');
      info.file ? setuploadImage(info.file.response.result.data.Location) : ' ';
      setimageuploading(false);
    }
  };

  // console.log(ingredientId,"ing")
  const bannerResp = getBoxIngType(ingredientId, setuploadImage, showUpdateModal);
  const bannerObj = bannerResp.response ? bannerResp.response : {};

  console.log(uploadImage);

  return (
    <div
      className={`modal fade ${showUpdateModal ? 'show' : ''}`}
      id='appointmentModal'
      tabIndex='-1'
      aria-labelledby='exampleModalLabel'
      aria-hidden='true'
      style={{
        backdropFilter: 'blur(5px)',
        display: showUpdateModal ? 'block' : 'none',
      }}
    >
      <div className={`modal-dialog position-relative modal-dialog-centered modal-lg `}>
        <div className='modal-content'>
          {/* <button
            type='button'
            className='btn-close btn bg-black  position-absolute top-0 end-0'
            onClick={() => setShowUpdateModal(false)}
            style={{
              marginTop: '-2rem',
              color: 'black',
            }}
            data-bs-dismiss='modal'
            aria-label='Close'
          ></button> */}
          {bannerResp.loading ? (
            <LoaderImage />
          ) : (
            <div className='modal-body p-3'>
              <Form
                name='basic'
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete='off'
                initialValues={{
                  ['isActive']: bannerObj?.isActive ? bannerObj?.isActive : false,
                  ['name']: bannerObj?.name ? bannerObj?.name : '',
                  ['SKU']: bannerObj?.SKU ? bannerObj?.SKU : '',
                  ['isShowImage']: bannerObj?.isShowImage ? bannerObj?.isShowImage : false,
                  ['description']: bannerObj?.description ? bannerObj?.description : '',
                  // ['image']: bannerObj?.image ? bannerObj?.image : null,
                }}
                className='row p-5 px-3 pb-1'
              >
                <InputField
                  label='Name'
                  name='name'
                  col=' col-md-3 col-6'
                  required={true}
                  placeholder='Name'
                  rules={[
                    {
                      required: true,
                      message: 'Enter Name',
                    },
                  ]}
                />
                ,
                <InputField
                  label='SKU'
                  name='SKU'
                  col='col-md-3 col-6'
                  required={true}
                  placeholder='SKU'
                  rules={[
                    {
                      required: true,
                      message: 'Enter SKU',
                    },
                  ]}
                />
                ,
                <div className='col-md-4 text-center'>
                  <Form.Item className='w-100 h-auto' valuePropName='fileList'>
                    <Upload listType='picture-card' showUploadList={false} action={`${app.URL}/api/v1/general/banner/imageupload`} beforeUpload={beforeUpload} onChange={handleuploadImage} headers={headers}>
                      {uploadImage && !imageuploading ? (
                        <img className='added-img' src={uploadImage} alt='avatar' />
                      ) : !uploadImage && imageuploading ? (
                        <LoadingOutlined style={{ fontSize: '40px' }} />
                      ) : (
                        <div className='d-flex flex-column py-3'>
                          <img className='for-add-img' src={AddPhoto} alt='avatar' />
                          <p className='add-text'>Upload Image</p>
                        </div>
                      )}
                    </Upload>

                    {uploadImage && !imageuploading && (
                      <button onClick={() => setuploadImage('')} style={{ backgroundColor: 'rgb(242, 29, 47)' }} className='add-btn'>
                        Remove
                      </button>
                    )}
                  </Form.Item>
                  <Form.Item colon={false} valuePropName='checked' defaultValue={true} label='Show Image' name='isShowImage'>
                    <Switch />
                  </Form.Item>
                </div>
                <TextAreaField
                  name='description'
                  label='Description'
                  col='col-lg-8 col-md-6 col-12'
                  colon={false}
                  rules={[
                    {
                      required: true,
                      message: 'Enter Description',
                    },
                  ]}
                  rows={6}
                  showCount={true}
                  maxLength={2500}
                  placeholder='Box Description'
                />
                ,
                <Form.Item colon={false} valuePropName='checked' defaultValue={true} label='Active' name='isActive'>
                  <Switch />
                </Form.Item>
                {/* <Form.Item label='Active' className='selector-main' colon={false} valuePropName='checked' name='isActive'>
                <Switch
                  onChange={(e) => {
                    console.log(e);
                    setisActiv(e);
                  }}
                />
              </Form.Item> */}
                <button onClick={() => setShowUpdateModal(false)} type='button' className={`btn btn-primary button patient-cancel-btn  ${location.pathname === '/patient/create' ? 'col-md-3' : 'col-md-4'} mx-md-2 mr-2 mb-2`}>
                  Cancel
                </button>
                <button type='primary' className={`btn btn-primary button ${location.pathname === '/patient/create' ? 'col-md-3' : 'col-md-4'}  mb-2`} htmlType='submit'>
                  Save
                </button>
              </Form>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default UpdateModal;
