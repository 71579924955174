import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { app } from '../../config';
import getHeaders from '../../utils/authHeaders';
import { useNavigate } from 'react-router-dom';
import { UpdateRecord } from '../../layouts/UpdateRecord';
import { InputField, SelectField, TextAreaField } from '../../layouts/FormFields';
import { Button, Form, Space, Upload, message, ColorPicker, Select, Switch, Checkbox } from 'antd'; //4.24.7
import { DeleteFilled, EditFilled, UploadOutlined } from '@ant-design/icons';
import DividerLine from '../../layouts/DividerLine';
import TestComponent from '../Products/TestComponent';
import TagSelect from '../Products/TagSelect';
import getAllPanelCode from '../../hooks/getAllPanelCode';

const LabTestUpdate = () => {
  let navigate = useNavigate();
  function goBack() {
    navigate('/labtest/view', { replace: true });
  }
  window.addEventListener('popstate', goBack);
  const [loader, setLoader] = useState(false);
  const [addUserResp, setAddUserResp] = useState({});
  const [labTestObj, setLabTestObj] = useState([]);
  const [uploadObj, setUploadObj] = useState({});
  const [show, setShow] = useState(false);
  const [uploadVideo, setuploadVideo] = useState('');
  const [contentType, setContentType] = useState();
  const [uplVid, setuplVid] = useState(true);
  const [resType, setResType] = useState('');
  const [testGroups, setTestGroups] = useState([]);
  let [textEditorValue, settextEditorValue] = useState();
  const [comments, setComments] = useState('');
  const [cisPanelNames, setCisPanelNames] = useState([]);
  const [testRanges, setTestRanges] = useState([]);

  const cisPanelCodeRes = getAllPanelCode();
  const cisPanelCode = cisPanelCodeRes.response ? cisPanelCodeRes.response : [];

  // const [contentDis, setContentDis] = useState('');

  const getLabTestGroups = async () => {
    const res = await axios.get(`${app.URL}/api/v1/admin/labtestgroups/getall`, { headers: getHeaders() });
    console.log(res);
    res.data.result.success && setTestGroups(res.data.result.data);
  };

  const getLabTestById = async (resultTestCode, resultElementGroup) => {
    setLoader(true);
    // const res = await axios.get(`${app.URL}/api/v1/admin/labtest/view/${resultTestCode}/${resultElementGroup}`, { headers: getHeaders() });
    const res = await axios.get(`${app.URL}/api/v1/admin/labtest/view/${resultTestCode}`, { headers: getHeaders() });
    console.log(res);
    res.data.result.success && setLabTestObj(res.data.result.data);
    res.data.result.success && setResType(res.data.result.data.resultType);
    res.data.result.success && settextEditorValue(res.data.result.data.description);
    res.data.result.success && setTestRanges(res.data.result.data.ranges);
    res.data.result.success && setCisPanelNames(res.data.result.data.resultElementGroupIds);
    res.data.result.success && setComments('');
    // res.data.result.success && setContentDis(res.data.result.data.viewType);
    res.data.result.success && getLabTestGroups();
    res.data.result.data.videoUrl && setuploadVideo(res.data.result.data.videoUrl);
    setLoader(false);
  };

  useEffect(() => {
    const resultTestCode = localStorage.getItem('resultTestCode');
    const resultElementGroup = localStorage.getItem('resultElementGroup');
    getLabTestById(resultTestCode, resultElementGroup);
    // console.log(resultTestCode);
  }, []);

  // console.log(testRanges);

  let user = JSON.parse(localStorage.getItem('user'));

  const putLabTestListing = async (details) => {
    // console.log(details);
    let userId = user?.result.data.user.userId;
    details.userId = userId;
    // console.log(details)
    setLoader(true);
    try {
      // const res = await axios.post(`${app.URL}/api/v1/admin/labtest/update?resultTestCode=${labTestObj.resultTestCode}&resultElementGroup=${labTestObj.resultElementGroup}`, details, { headers: getHeaders() });
      const res = await axios.post(`${app.URL}/api/v1/admin/labtest/update?resultTestCode=${labTestObj.resultTestCode}`, details, { headers: getHeaders() });
      setLoader(false);
      setShow(false);
      console.log(res);

      if (res.data.result.success) {
        setAddUserResp(res.data.result.data);
        message.success(res.data.result.msg);
        setTimeout(goBack, 500);
      } else if (!res.data.result.success) {
        message.warning(res.data.result.msg);
      }
    } catch (err) {
      console.log(err);
      setLoader(false);
      setShow(false);
      if (err) console.log(err.response.data.message);
      else return;
    }
  };
  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    // reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  };
  const beforeUpload = (file) => {
    console.log(file);
    setuploadVideo('');
    if (file.type === 'image/jpeg' || file.type === 'image/png') {
      console.log('setting image type');
      setContentType('img');
    } else if (file.type === 'video/x-matroska' || file.type === 'video/mp4') setContentType('video');
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'video/x-matroska' || file.type === 'video/mp4';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    console.log('file Checked');
    const isLt2M = file.size / 1024 / 1024 < 20;
    if (!isLt2M) {
      setLargeFile(true);
      message.error('Image must smaller than 20MB!');
    }
    console.log('message');
    return isJpgOrPng && isLt2M;
  };

  const handleuploadChange = (info) => {
    if (info.file.status === 'uploading') {
      return;
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      console.log(info.file);
      info.file ? console.log(info.file.response.result.data.Location) : console.log('Not found');
      info.file ? setuploadVideo(info.file.response.result.data.Location) : ' ';
      getBase64(info.file.originFileObj, (url) => {
        setuplVid(false);
      });
    }
  };

  const onFinish = (values) => {
    console.log(values, textEditorValue, testRanges);

    // return;
    let finalRanges;
    finalRanges = resType === 'C' || resType === 'R' ? testRanges : values.ranges;
    console.log(finalRanges);

    if (values.ranges?.length === 0 && (values.resultType === 'R' || values.resultType === 'P')) {
      message.info('Please add minimum one range for result!');
    } else {
      setUploadObj({
        resultTestCode: values.resultTestCode ? values.resultTestCode : null,
        resultElement: values.resultElement ? values.resultElement : null,
        // resultElementGroup: values.resultElementGroup ? values.resultElementGroup : null,
        resultElementGroupId: cisPanelNames,
        displayPosition: values.displayPosition ? values.displayPosition : '',
        displayName: values.displayName ? values.displayName : '',
        isActive: values.isActive ? 1 : 0,
        // isPolarBarChart: values.isPolarBarChart ? 1 : 0,
        description: textEditorValue,
        // productCode: values.productCode ? values.productCode : null,
        // productName: values.productName ? values.productName : null,
        resultType: values.resultType ? values.resultType : null,

        ranges: finalRanges,
        videoUrl: uploadVideo ? uploadVideo : null,
        viewType: values.viewType ? values.viewType : null,
        unit: values.unit ? values.unit : null,
      });
      setShow(true);
    }
    // console.log(uploadVideo);
  };
  const submitData = () => {
    console.log(uploadObj);
    if (Object.keys(uploadObj).length > 0) {
      console.log(uploadObj);

      // return
      putLabTestListing(uploadObj);
    }
  };
  const onFinishFailed = () => {};

  const labTestToggleVideo = () => {
    setuploadVideo('');
    setTimeout(() => setuploadVideo(labTestObj.videoUrl), 500);
  };
  const handleContentDisplay = (event) => {
    console.log(event);
    // setContentDis(event);
  };

  const colorsCodes = [
    { value: '#d24239', name: 'Red' },
    { value: '#59b05c', name: 'Green' },
    { value: '#fabc2e', name: 'Yellow' },
  ];

  const handleEditorChange = (htmlContent) => {
    settextEditorValue(htmlContent);
    // Do something with the htmlContent, such as saving it to state or sending it to an API
  };

  const handleCommentChange = (htmlContent) => {
    setComments(htmlContent);
    // Do something with the htmlContent, such as saving it to state or sending it to an API
  };

  return (
    <UpdateRecord
      pageTitle='Update Panel Test'
      loading={loader}
      response={labTestObj}
      goBack={goBack}
      upload={false}
      // form={form}
      show={show}
      handleClose={() => setShow(false)}
      addUserResp={addUserResp}
      submitData={submitData}
      updateMessage='Are you sure you want to update lab test element?'
      initialValues={{
        remember: true,
        ['resultElement']: labTestObj?.resultElement,
        ['resultTestCode']: labTestObj?.resultTestCode,
        // ['resultElementGroup']: labTestObj?.resultElementGroup,
        ['resultType']: labTestObj?.resultType,
        ['viewType']: labTestObj?.viewType,
        // ['productCode']: labTestObj?.productCode,
        // ['productName']: labTestObj?.productName,
        ['displayPosition']: labTestObj?.displayPosition,
        ['displayName']: labTestObj?.displayName,
        ['isActive']: labTestObj?.isActive,
        // ['description']: labTestObj?.description,
        ['ranges']: labTestObj?.ranges,
        ['isPolarBarChart']: labTestObj?.isPolarBarChart,
        ['unit']: labTestObj?.unit,
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      switchBtn={false}
      inputs={[
        // <SelectField
        //   col='col-lg-6'
        //   name='resultElementGroup'
        //   label='Panel Name'
        //   rules={[
        //     {
        //       required: true,
        //       message: 'Select Group',
        //     },
        //   ]}
        //   placeholder='Group'
        //   required={true}
        //   options={testGroups.map((item) => (
        //     <Select.Option value={item.resultElementGroupId}>{item.cisPanelName}</Select.Option>
        //   ))}
        // />,
        <div className='col-lg-6'>
          <TagSelect
            options={cisPanelCode.map((item) => {
              if (!cisPanelNames.includes(item.resultElementGroupId)) {
                return (
                  <Select.Option value={item.resultElementGroupId}>
                    {item.cisPanelName} ({item.cisPanelCode})
                  </Select.Option>
                );
              }
            })}
            col='col-12'
            tags={cisPanelNames}
            setTags={setCisPanelNames}
            label='Panel Name'
            cisPanelCode={cisPanelCode}
            // isPanelName={true}
            isPanelCode={true}
          />
        </div>,
        <div className='col-lg-6 d-none d-md-block'></div>,
        <InputField col='col-lg-6' name='resultTestCode' disabled={true} placeholder='Test Code' label='Test Code' />,
        <InputField col='col-lg-6' name='resultElement' disabled={true} placeholder='Element' label='Test Name' />,
        <InputField
          col='col-lg-4'
          name='displayName'
          required={true}
          rules={[
            {
              required: true,
              message: 'Enter Test Display Name',
            },
          ]}
          placeholder='Test Display Name'
          label='Test Display Name'
        />,
        <InputField
          col='col-lg-2'
          name='unit'
          // required={true}
          rules={[
            {
              // required: true,
              message: 'Enter Test Unit',
            },
          ]}
          placeholder='Test Unit'
          label='Test Unit'
        />,

        // <InputField col='col-lg-3' name='resultType' disabled={true} label='Result Type' />,
        <SelectField
          col='col-lg-3'
          name='resultType'
          label='Result Type'
          onChange={(e) => setResType(e)}
          rules={[
            {
              required: true,
              message: 'Select Result Type',
            },
          ]}
          placeholder='Result Type'
          required={true}
          options={[
            { name: 'Range', value: 'R' },
            { name: 'Single Value', value: 'S' },
            { name: 'Positive/Negative', value: 'P' },
            { name: 'Class', value: 'C' },
          ].map((item) => (
            <Select.Option value={item.value}>{item.name}</Select.Option>
          ))}
        />,
        <InputField
          col='col-lg-3'
          name='displayPosition'
          // rules={[
          //   {
          //     required: true,
          //     message: 'Enter Display Postion',
          //   },
          // ]}
          // required={true}
          label='Display Position'
        />,
        // d-flex align-items-center
        <div className='col-lg-3 mb-2 '>
          <Form.Item label='Active' className='selector-main' colon={false} valuePropName='checked' name='isActive'>
            <Switch />
          </Form.Item>
        </div>,
        // <div className='col-lg-3 mb-2 d-flex align-items-center'>
        //   <Form.Item label='Polar Bar Chart' className='selector-main' colon={false} valuePropName='checked' name='isPolarBarChart'>
        //     <Switch />
        //   </Form.Item>
        // </div>,
        <DividerLine />,
        <div className='col-lg-8'>
          <div className='row'>
            <SelectField
              col='col-lg-6'
              name='viewType'
              onChange={(e) => handleContentDisplay(e)}
              options={[
                { value: 'none', name: 'None' },
                { value: 'video', name: 'Video' },
                { value: 'text', name: 'Text' },
                { value: 'both', name: 'Both' },
              ].map((item) => (
                <Select.Option value={item.value}>{item.name}</Select.Option>
              ))}
              rules={[
                {
                  required: true,
                  message: 'Select Content Display',
                },
              ]}
              required={true}
              label='Show Description'
            />
          </div>
        </div>,
        // <TextAreaField
        //   showCount={true}
        //   maxLength={2500}
        //   col='col-lg-8'
        //   name='description'
        //   rules={[
        //     {
        //       required: true,
        //       message: 'Enter Description',
        //     },
        //   ]}
        //   required={true}
        //   placeholder='Enter description'
        //   label='Description'
        //   rows={8}
        // />,
        <div className='col-lg-8'>
          <TestComponent title='Description' onChange={handleEditorChange} textvalue={textEditorValue} />
        </div>,
        <div className='col-md-4 col-12 mb-lg-0 mb-2'>
          {uploadVideo && <label>Video</label>}
          {uploadVideo && (
            <video style={{ width: '100%', borderRadius: '5px', boxShadow: '3px 3px 10px #ccc' }} controls title='YouTube video player'>
              <source src={uploadVideo} type='video/mp4'></source>
            </video>
          )}
          <Form.Item colon={false} name='video' className='mt-2'>
            <Space
              direction='vertical'
              style={{
                width: '100%',
              }}
              size='large'
            >
              <Upload onRemove={labTestToggleVideo} listType='picture' maxCount={1} className='avatar-uploader' headers={getHeaders()} action={`${app.URL}/api/v1/general/product/videoUpload`} beforeUpload={beforeUpload} onChange={handleuploadChange}>
                <Button icon={<UploadOutlined />} className={'upload-btn'} style={{ color: 'black' }}>
                  Upload Video
                </Button>
                {/* <div className='mt-2'>Note: Upload size for video is 50mb supported format for video are (mkv/mp4)</div> */}
              </Upload>
            </Space>
          </Form.Item>
        </div>,
      ]}
      extras={
        (resType === 'P' || resType === 'R' || resType === 'C') && [
          <div className='col-lg-12 mb-4'>
            <h2 className='dynamic-field-head mb-0'>Ranges</h2>
            <table id='customers' className='px-0' style={{ width: `${resType === 'P' ? '55%' : '100%'}` }}>
              <thead>
                {resType === 'P' ? (
                  <tr>
                    <th>Normal Range</th>
                    <th></th>
                  </tr>
                ) : (
                  <tr>
                    <th style={{ width: '15%', paddingLeft: '1rem', textAlign: 'left' }}>Type</th>
                    <th style={{ width: '15%', paddingLeft: '1.1rem', textAlign: 'left' }}>Start</th>
                    <th style={{ width: '15%', paddingLeft: '2.1rem', textAlign: 'left' }}>End</th>
                    <th style={{ width: '15%', paddingLeft: '3rem', textAlign: 'left' }}>Color</th>
                    <th style={{ width: '40%', paddingLeft: '1rem' }}></th>
                    {/* style={{ width: '20%', paddingLeft: '1rem' }} */}
                  </tr>
                )}
              </thead>
            </table>
            <Form.List name='ranges'>
              {(fields, { add, remove }, { errors }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <RangesCollection setTestRanges={setTestRanges} testRanges={testRanges} remove={remove} fields={fields} name={name} key={key} restField={restField} resType={resType} colorsCodes={colorsCodes} ranges={labTestObj?.ranges} />
                  ))}
                  <Form.ErrorList errors={errors} />
                </>
              )}
            </Form.List>
          </div>,
        ]
      }
      //videoUrl={uploadVideo}
    />
  );
};

const RangesCollection = ({ setTestRanges, testRanges, key, resType, name, restField, colorsCodes, ranges, fields, remove }) => {
  const indx = [name, 'color'][0];

  const ROptions = [
    {
      name: 'Low',
      value: 'Low',
    },
    {
      name: 'Normal',
      value: 'Normal',
    },
    {
      name: 'High',
      value: 'High',
    },
  ];
  const COptions = [
    {
      name: '0',
      value: '0',
    },
    {
      name: '1',
      value: '1',
    },
    {
      name: '2',
      value: '2',
    },
    {
      name: '3',
      value: '3',
    },
    {
      name: '4',
      value: '4',
    },
    {
      name: '5',
      value: '5',
    },
    {
      name: '6',
      value: '6',
    },
  ];
  const POptions = [
    {
      name: 'Positive',
      value: 'POSITIVE',
    },
    {
      name: 'Negative',
      value: 'NEGATIVE',
    },
  ];

  const [newColor, setNewColor] = useState(ranges[indx]?.dailBandColor);
  const [isDefault, setIsDefault] = useState(false);
  const [showColor, setShowColor] = useState(true);
 
  // const [updateKey, setUpdateKey] = useState(1);

  const onColorChange = (color) => {
    setShowColor(false);
    setNewColor(color);

    setTimeout(() => {
      setShowColor(true);
      // if (resType === 'C') {
        const range = testRanges[indx];
        const filter = testRanges.filter((item) => item.type !== range.type);

        range.dailBandColor = color;
        filter.splice(indx, 0, range);
        setTestRanges(filter);
      // }
    }, 150);
  };

  const onDefaultChange = (def) => {
    const range = testRanges[indx];
    const filter = testRanges.filter((item) => item.type !== range.type);

    range.isDefault = def;
    filter.splice(indx, 0, range);
    setTestRanges(filter);
  };

  const [renderOptions, setRenderOptions] = useState([]);

  useEffect(() => {
    if (resType) {
      const arr = resType === 'P' ? POptions : resType === 'R' ? ROptions : COptions;
      setRenderOptions(arr);
    }
  }, [resType]);

  const handleColorPicker = (value, hex) => {
    console.log(hex)
    setNewColor(hex);

    const range = testRanges[indx];
    const filter = testRanges.filter((item) => item.type !== range.type);

    range.dailBandColor = hex;
    filter.splice(indx, 0, range);
    console.log(filter);
    setTestRanges(filter);
    
  };

  return (
    <div className='row mb-3 mb-md-0 px-1' key={key}>
      <SelectField
        col={resType === 'R' || resType === 'C' ? 'col-2' : 'col-6'}
        name={[name, 'type']}
        rules={[
          {
            required: true,
            message: 'Select Type',
          },
        ]}
        placeholder='Type'
        options={renderOptions.map((item) => (
          <Select.Option value={item.value}>{item.name}</Select.Option>
        ))}
        // label='Type'
        // required={true}
        {...restField}
      />
      {(resType === 'R' || resType === 'C') && (
        <InputField
          // col={resType === 'R' ? 'col-3' : 'col-4'}
          col='col-2'
          name={[name, 'rangeFrom']}
          rules={[
            {
              required: true,
              message: 'Enter Start Range',
            },
          ]}
          placeholder='Start Range'
          // label='Start'
          // required={true}
          {...restField}
        />
      )}
      {(resType === 'R' || resType === 'C') && (
        <InputField
          col='col-2'
          name={[name, 'rangeTo']}
          rules={[
            {
              required: true,
              message: 'Enter End Range',
            },
          ]}
          placeholder='End Range'
          // label='End'
          // required={true}
          {...restField}
        />
      )}
      {/* {resType === 'R' && (
        <SelectField
          onChange={(e) => onColorChange(e)}
          options={colorsCodes.map((item) => (
            <Select.Option value={item.value}>{item.name}</Select.Option>
          ))}
          // col={resType === 'R' ? 'col-2' : 'col-5'}
          col='col-2'
          name={[name, 'dailBandColor']}
          rules={[
            {
              required: true,
              message: 'Select Color',
            },
          ]}
          placeholder='Color'

          // label='Color'
          // required={true}
        />
      )} */}
      {(resType === 'C' || resType === 'R')  && (
        <InputField
          onChange={(e) => onColorChange(e.target.value)}
          col='col-2'
          // readOnly={true}
          // name={[name, 'dailBandColor']}
          value={newColor}
          rules={[
            {
              required: true,
              message: 'Enter Color',
            },
          ]}
          placeholder='Color'
        />
      )}
      {showColor && (resType === 'R' || resType === 'C') && (
        <ColorPicker
          // disabled={resType === 'C' ? false : true}
          disabled={false}
          onChange={handleColorPicker}
          defaultValue={newColor}
          className='col-1'
        />
      )}
      {resType === 'C' && (
        <div className='col-1 gap-1 d-flex align-items-start'>
          <Checkbox onChange={(e) => onDefaultChange(e.target.checked)}>Default</Checkbox>
        </div>
      )}
      {/* <div className='col-1 d-flex align-items-start pt-2'>{fields.length > 0 && <DeleteFilled style={{ fontSize: '1.2rem', color: 'crimson' }} onClick={() => remove(name)} />}</div> */}
      {/* {key === updateKey && (
        <div className='col-1 d-flex align-items-start pt-2'>
          <EditFilled />
        </div>
      )} */}
    </div>
  );
};

export default LabTestUpdate;
