import axios from 'axios';
import { useEffect, useState } from 'react';
import { app } from '../config';
import getHeaders from '../utils/authHeaders';
const getBoxIngType = (id, setImage, showUpdateModal) => {
  let reqid = id;
  const [loading, setLoading] = useState(true);
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  useEffect(() => {
    const get = async (id) => {
      try {
        setLoading(true);
       console.log("Box Ing Id",id)
        const res = await axios.get(`${app.URL}/api/v1/admin/boxtypeing/view/${id}`, { headers: getHeaders() });
        console.log('res', res);
        // console.log('response', res.data.result.data.image);
        setResponse(res.data.result.data);
        // setBoxIngData(res.data.result.data);


        setImage(res.data.result.data.image);

        setLoading(false);
       
      } catch (err) {
        setError(err);
        setLoading(false);
      }
    };
    get(reqid);
  }, [showUpdateModal]);

  return { loading, response, error };
};
export default getBoxIngType;
