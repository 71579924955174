import axios from 'axios';
import { useEffect, useState } from 'react';
import { app } from '../config';
import getHeaders from '../utils/authHeaders';

const getAllPanelCode = () => {
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(`${app.URL}/api/v1/admin/labtestgroups/getall`, { headers: getHeaders() });
        console.log(res);
        res.data.result.success && setResponse(res.data.result.data);
      } catch (err) {
        setError(err);
      }
    };
    fetchData();
  }, []);

  return {  response, error };
};

export default getAllPanelCode;
