export const extractObjValues = (arrayOfObjects, desiredKeys) => {
  console.log(arrayOfObjects)
  const extractedValues = arrayOfObjects.map((obj) => {
    const extractedObj = {};
    desiredKeys.forEach((key) => {
      extractedObj[key] = obj[key];
    });
    return extractedObj;
  });
  return extractedValues;
};
