import axios from 'axios';
import { useEffect, useState } from 'react';
import {app} from '../config'
import getHeaders from '../utils/authHeaders';
const getPatientById = (id, setZipInput, setUploadedImg, setAddressData) => {
  let reqid = id;
  const [loading, setLoading] = useState(true);
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  useEffect(() => {
    const getPatient = async (id) => {
      try {
        const res = await axios.get(`${app.URL}/api/v1/admin/patient/view/${id}`, { headers: getHeaders() });
        setResponse(res.data.result);
        setLoading(false);
        console.log(res.data.result);
        setZipInput(res.data.result.data.zipCode);
        setAddressData(res.data.result.data.addresses);
        if (res.data.result.data.userImage) {
          setUploadedImg(res.data.result.data.userImage);
          console.log(res.data.result.data.userImage);
        }
      } catch (err) {
        setError(err);
        setLoading(false);
      }
    };
    getPatient(reqid);
  }, []);

  return { loading, response, error };
};
export default getPatientById;