import axios from 'axios';
import { useEffect, useState } from 'react';
import { app } from '../../config';
import getHeaders from '../../utils/authHeaders';

// import getHeaders from '../utils/authHeaders';

const getAllMeasuredCategory = () => {
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    console.log("entered")
    const fetchData = async () => {
      try {
        const res = await axios.get(`${app.URL}/api/v1/admin/whatmeasuredcat/view?isActive=1`, { headers: getHeaders() });

        console.log('response is : ');

        console.log(res.data.data);
        setResponse(res.data.data);
      } catch (err) {
        setError(err);
      }
    };
    fetchData();
  }, []);

  return { response, error };
};

export default getAllMeasuredCategory;
