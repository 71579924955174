import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { message, Switch, Select, Form, Input, Row, Col, DatePicker, Upload, Space, InputNumber, Tooltip } from 'antd';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { app } from '../../config';
import useFetchRoles from '../../hooks/fetchRoles';
import { LoadingOutlined, MinusCircleOutlined, PlusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import InputMask from 'react-input-mask';
import getHeaders from '../../utils/authHeaders';
import useFetchCities from '../../hooks/fetchCities';
import useFetchStates from '../../hooks/fetchStates';
import useFetchStores from '../../hooks/fetchStores';
import AddImg from '../../assets/images/new-add.png';
import AddPhoto from '../../assets/images/partner.png';
import { AddRecord } from '../../layouts/AddRecord';
import { InputField, InputMasker, SelectField, TextAreaField } from '../../layouts/FormFields';
import { noNumbers, noPrespace } from '../user/ValidationFunctions';
// console.log(app);
const { TextArea } = Input;

const AddPartner = () => {
  const { t } = useTranslation(['common']);
  const [phoneNumber, setPhoneNumber] = useState();
  // const [zipCode, setZipCode] = useState("")
  const [addUserResp, setAddUserResp] = useState({});
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [uploadLogo, setUploadLogo] = useState(true);
  // const [phone, setPhone] = useState('');
  const [imageuploading, setImageUploading] = useState(false);
  const [uploadedImg, setUploadedImg] = useState('');
  let navigate = useNavigate();
  const fetchedRoles = useFetchRoles();
  const roles = fetchedRoles.response ? fetchedRoles.response.data : [];
  const [uploadObj, setUploadObj] = useState({});

  const [zipInput, setZipInput] = useState('');
  // const [tooltip, setTooltip] = useState('');

  const handleInputZip = (e) => {
    // setTooltip('');
    const inputValue = e.target.value;
    if (inputValue.length <= 5) {
      setZipInput(inputValue);
      // setTooltip('');
    }
    // if (inputValue.includes('0000') || inputValue.includes('00000')) {
    //   setTooltip('0000 or 00000 not valid zip');
    // }
  };

  const cityResponse = useFetchCities();
  const cities = cityResponse.response ? cityResponse.response.data : [];
  const stateResponse = useFetchStates();
  const states = stateResponse.response ? stateResponse.response.data : [];
  const fetchStoresResponse = useFetchStores();
  const storesList = fetchStoresResponse.response ? fetchStoresResponse.response.data : [];
  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  };
  let user = JSON.parse(localStorage.getItem('user'));
  const uploadData = async (userDetails) => {
    let userId = user?.result.data.user.userId;
    userDetails.userId = userId;
    console.log(userDetails);
    setLoading(true);
    try {
      const res = await axios.post(`${app.URL}/api/v1/admin/partner/create`, userDetails, { headers: getHeaders() });
      setLoading(false);
      setShow(false);

      if (res.data.result.success) {
        setAddUserResp(res.data.result.data);
        message.success(res.data.result.msg);
        setTimeout(() => {
          navigate('/partner/listing');
        }, 1000);
      } else if (!res.data.result.success) {
        message.warning(res.data.result.msg);
      }
    } catch (err) {
      setLoading(false);
      setShow(false);

      console.log(err);
      if (err) console.log(err.response.data.message);
      else return;
    }
  };
  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
  };
  const handleuploadChange = async (info) => {
    if (info.file.status === 'uploading') {
      setImageUploading(true);
      setLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      info.file ? console.log(info.file.response.result.data.Location) : console.log('Not found');
      info.file ? setUploadedImg(info.file.response.result.data.Location) : ' ';
      getBase64(info.file.originFileObj, (url) => {
        setImageUploading(false);
        setLoading(false);
        setUploadLogo(false);
      });
    }
  };
  const handleChange = (event) => {
    setPhoneNumber(event.target.value);
  };
  const onFinish = (values) => {
    let selectedStoresList = [];
    console.log(values);
    // return
    values.stores
      ? values.stores.map((item) => {
          let store = storesList.filter((st) => st.storeId == item);
          store.map((sto) => {
            let storeObj = {
              partnerStoreName: sto.name,
              storeId: sto.storeId,
            };
            selectedStoresList.push(storeObj);
          });
        })
      : (selectedStoresList = []);
    console.log('selectedStoresList :: ', selectedStoresList);
    setUploadObj({
      code: values.code ? values.code : null,
      partnerName: values.firstName ? values.firstName : null,
      address: values.streetAddress ? values.streetAddress : '',
      website: values.website ? values.website : null,
      logoUrl: uploadedImg ? uploadedImg : null,
      stateId: values.state ? values.state : null,
      mobile: values.phone ? values.phone : '',
      city: values.city ? values.city : null,
      email: values.email ? values.email : null,
      zipCode: zipInput ? zipInput : null,
      partnerStore: selectedStoresList.length > 0 ? selectedStoresList : [],
      partnerContact: values.contactPersons ? (values.contactPersons.length > 0 ? values.contactPersons : []) : [],
      isActive: values.isActive,
    });
    setShow(true);
  };
  let header = getHeaders();
  // const handleChange = (event) => {
  //   setPhone(event.target.value);
  // };
  const submitData = () => {
    if (Object.keys(uploadObj).length > 0) {
      if (phoneNumber.length < 12) {
        setShow(false);
        message.error('Invalid mobile number');
        // message.error('Invalid mobile number')
        return;
      } else if (zipInput.includes('00000')) {
        setShow(false);
        message.error('0000 or 00000 zip code is not acceptable!');
      }
      // else console.log("farig tester")
      else uploadData(uploadObj);
    }
  };
  const handleClose = () => setShow(false);
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  function goBack() {
    navigate('/partner/listing', { replace: true });
  }
  window.addEventListener('popstate', goBack);

  // const [zipCodeDisable, setZipCodeDisable] = useState(false)
  // useEffect(() => {
  //   if (zipCode.length < 5) {
  //     setZipCodeDisable(false);
  //     console.log("disable", zipCode.length)
  //   }
  //   else {
  //     setZipCodeDisable(true);
  //     console.log('disable', zipCode.length);

  //   }
  // }, [zipCode])

  const handleKeysPress = (e) => {
    console.log(e);
    noPrespace(e);
    noNumbers(e);
  };

  return (
    <AddRecord
      loading={loading}
      pageTitle='Add Partner'
      addUserResp={addUserResp}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      switchBtn={true}
      initialValues={{
        remember: true,
        ['isActive']: true,
      }}
      inputs={[
        <InputField
          col='col-lg-6 col-md-4 col-12'
          rules={[
            {
              required: true,
              message: 'Enter Partner Code',
            },
          ]}
          placeholder='Partner Code'
          name='code'
          label='Partner Code'
          required={true}
          onKeyPress={handleKeysPress}
        />,

        <InputField
          col='col-lg-6 col-md-4 col-12'
          rules={[
            {
              required: true,
              message: 'Enter Partner Name',
            },
          ]}
          placeholder='Partner Name'
          name='firstName'
          label='Partner Name'
          required={true}
          onKeyPress={handleKeysPress}
        />,
        <TextAreaField
          col='col-12'
          rules={[
            {
              required: true,
              message: 'Enter Address',
            },
          ]}
          placeholder='Enter Address'
          name='streetAddress'
          label='Address'
          required={true}
          rows={6}
          showCount={true}
          maxLength={1000}
        />,

        <InputField
          col='col-lg-6 col-md-4 col-12'
          rules={[
            {
              required: true,
              message: 'Enter Email',
              type: 'email',
            },
          ]}
          placeholder='Enter Email'
          name='email'
          label='Email'
          required={true}
          onKeyPress={noPrespace}
        />,
        <InputField
          col='col-lg-6 col-md-4 col-12'
          rules={[
            {
              required: true,
              message: 'Enter City',
            },
          ]}
          placeholder='Enter City'
          name='city'
          label='City'
          required={true}
          onKeyPress={handleKeysPress}
        />,
        <InputField
          col='col-lg-6 col-md-4 col-12'
          rules={[
            {
              required: true,
              message: 'Enter Website',
            },
          ]}
          placeholder='Enter Website'
          name='website'
          label='Website'
          required={true}
        />,

        <div className='col-lg-6 col-md-4 col-12'>
          <label>
            ZIP Code <span className='required-star'>*</span>
          </label>
          <Input value={zipInput} onChange={handleInputZip} className='num-input' type='number' maxLength={5} placeholder='ZIP Code' />
        </div>,
      ]}
      inputMask={[
        <InputMasker
          col='col-lg-6 col-md-4 col-12'
          handleChange={handleChange}
          rules={[
            {
              required: true,
              message: 'Enter Mobile',
            },
          ]}
          placeholder='Enter Mobile'
          name='phone'
          label='Mobile'
          required={true}
        />,
      ]}
      selects={[
        <SelectField
          col='col-lg-6 col-md-4 col-12'
          label='State'
          placeholder='Select State'
          required={true}
          name='state'
          options={states.map((item) => (
            <Select.Option value={item.stateId}>{item.name}</Select.Option>
          ))}
          rules={[
            {
              required: true,
              message: 'Select State',
            },
          ]}
        />,
      ]}
      extras={[
        <div className='col-lg-12 mb-4'>
          <h2 className='dynamic-field-head'>Store</h2>
          <Form.List name='stores'>
            {(fields, { add, remove }, { errors }) => (
              <>
                {fields.map((field, index) => (
                  // <Space className='col-lg-12' key={field.key}>
                  <Form.Item required={false} key={field.key}>
                    <div className='row'>
                      <Form.Item
                        className='col-lg-9 '
                        {...field}
                        validateTrigger={['onChange', 'onBlur']}
                        // noStyle
                      >
                        <Select placeholder={`Store ` + (index + 1)}>
                          {storesList.map((item) => (
                            <Select.Option value={item.storeId}>{item.name}</Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                      <Form.Item className='col-lg-3 mt-1'>
                        {fields.length > 0 && (
                          <div className='add-btn' style={{ textAlign: 'center', backgroundColor: '#F21D2F' }} onClick={() => remove(field.name)}>
                            Remove
                          </div>
                        )}
                      </Form.Item>
                    </div>
                  </Form.Item>
                  // </Space>
                ))}

                <div className='row px-2'>
                  <div style={{ textAlign: 'center' }} onClick={() => add()} className='col-lg-3 add-btn'>
                    Add Store
                  </div>
                </div>
                <Form.ErrorList errors={errors} />
              </>
            )}
          </Form.List>
        </div>,
        <div className='col-lg-12 mb-4'>
          <h3 className='dynamic-field-head'>Contact Person</h3>
          <Form.List name='contactPersons'>
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <>
                    <h3 className='dynamic-field-head'>Contact Person {key + 1}</h3>
                    <div className='row'>
                      {/* <div className='col-lg-6'>
                        <label>
                          Name <span className='required-star'>*</span>
                        </label>
                        <Form.Item
                          {...restField}
                          name={[name, 'partnerContactName']}
                          rules={[
                            {
                              required: true,
                              message: 'Enter Name',
                            },
                          ]}
                        >
                          <Input placeholder='Partner Contact Name' />
                        </Form.Item>
                      </div> */}
                      <InputField
                        label='Name'
                        required={true}
                        col='col-lg-6'
                        name={[name, 'partnerContactName']}
                        rules={[
                          {
                            required: true,
                            message: 'Enter Name',
                          },
                        ]}
                        {...restField}
                        placeholder='Enter Contact Name'
                      />
                      {/* <div className='col-lg-6'>
                        <label>
                          Email <span className='required-star'>*</span>
                        </label>
                        <Form.Item
                          {...restField}
                          name={[name, 'email']}
                          rules={[
                            {
                              required: true,
                              type: 'email',
                              message: 'Enter Email',
                            },
                          ]}
                        >
                          <Input placeholder='Email' />
                        </Form.Item>
                      </div> */}
                      <InputField
                        label='Email'
                        required={true}
                        col='col-lg-6'
                        name={[name, 'email']}
                        rules={[
                          {
                            required: true,
                            message: 'Enter Email',
                            type: 'email',
                          },
                        ]}
                        {...restField}
                        placeholder='Enter Email'
                      />
                      <InputMasker
                        label='Mobile'
                        required={true}
                        col='col-lg-8'
                        name={[name, 'mobile']}
                        rules={[
                          {
                            required: true,
                            message: 'Enter Phone',
                          },
                        ]}
                        {...restField}
                        placeholder='Mobile'
                        handleChange={handleChange}
                      />
                      {/* <div className='col-lg-4 d-flex align-items-center'>
                        <div className='add-btn' style={{ backgroundColor: '#F21D2F' }} onClick={() => remove(name)}>
                          Remove
                        </div>
                      </div> */}
                      <div className='col-lg-4  mb-md-0 mb-3 d-flex align-items-center'>
                        <div className='add-btn w-100' style={{ textAlign: 'center', backgroundColor: '#F21D2F' }} onClick={() => remove(name)}>
                          Remove
                        </div>
                      </div>
                    </div>

                    {/* <div className='row'>
                      <div className='col-lg-4'>
                        <label>
                          Mobile <span className='required-star'>*</span>
                        </label>
                        <Form.Item
                          {...restField}
                          name={[name, 'mobile']}
                          rules={[
                            {
                              required: true,
                              message: 'Enter Phone',
                            },
                          ]}
                        >
                          <InputMask mask='(999) 999-9999' onChange={handleChange}>
                            {(inputProps) => <Input {...inputProps} placeholder='Mobile' style={{ width: '100% !important' }} />}
                            <Input />
                          </InputMask>
                        </Form.Item>
                      </div>

                      <div>
                        <Form.Item>
                          <div style={{ textAlign: 'center' }} onClick={() => add()} className='col-lg-3 add-btn'>
                            Add Contact
                          </div>
                          <Form.ErrorList errors={errors} />
                        </Form.Item>
                      </div>
                      <div className='col-lg-4 mb-3'>
                        <div className='add-btn' style={{ backgroundColor: '#F21D2F' }} onClick={() => remove(name)}>
                          Remove
                        </div>
                      </div>
                    </div> */}
                  </>
                ))}
                <div className='row px-2'>
                  <div className='add-btn col-lg-3' onClick={() => add()} style={{ cursor: 'pointer', color: '#fff', textAlign: 'center' }}>
                    {/* <img style={{ margin: "auto 10px", width: "20px" }} src={AddImg} /> */}
                    Add Contact Person
                  </div>
                </div>
                {/* <div className='add-btn' onClick={() => add()} style={{ cursor: 'pointer', color:"#fff",display:"flex"}}>
                                <img style={{margin:"auto 10px",width:"20px"}} src={AddImg}/><span> Add Contact Person</span>
                              </div> */}
              </>
            )}
          </Form.List>
        </div>,
      ]}
      addMessage='Are you sure you want to add partner?'
      handleClose={handleClose}
      show={show}
      goBack={goBack}
      upload={true}
      action={`${app.URL}/api/v1/general/user/imageupload`}
      beforeUpload={beforeUpload}
      handleuploadChange={handleuploadChange}
      imageuploading={imageuploading}
      submitData={submitData}
      uploadedImg={uploadedImg}
      setUploadedImg={setUploadedImg}
    />
  );
  // return (
  //   <>
  //     <div className='col-lg-12'>
  //       <div className='dashboard-card'>
  //         <div className='row' style={{ paddingRight: '10px' }}>
  //           <div className='for-before col-lg-4 head fs-3 fw-bold' style={{ backgroundColor: '#a7c1d9', borderBottomRightRadius: '10px', color: '#FFFFFF' }}>
  //             Add Partner
  //           </div>
  //         </div>
  //         <Form
  //           name='basic'
  //           onFinish={onFinish}
  //           onFinishFailed={onFinishFailed}
  //           autoComplete='off'
  //           initialValues={{
  //             remember: true,
  //             ['isActive']: true,
  //           }}
  //         >
  //           <div className='row' style={{ padding: '0px 50px', paddingTop: '40px' }}>
  //             <div className='col-lg-8'>
  //               {/* Select box for Role */}

  //               <div className='row'>
  //                 <div className='col-lg-6'>
  //                   <label>
  //                     Partner Code <span className='required-star'>*</span>
  //                   </label>
  //                   <Form.Item
  //                     name='code'
  //                     defaultValue='1'
  //                     colon={false}
  //                     rules={[
  //                       {
  //                         required: true,
  //                         message: 'Enter Partner Code',
  //                       },
  //                     ]}
  //                   >
  //                     <Input className='' placeholder='Partner Code' />
  //                   </Form.Item>
  //                 </div>
  //                 <div className='col-lg-6'>
  //                   <label>
  //                     Partner Name <span className='required-star'>*</span>
  //                   </label>
  //                   <Form.Item
  //                     name='firstName'
  //                     defaultValue='1'
  //                     colon={false}
  //                     rules={[
  //                       {
  //                         required: true,
  //                         message: 'Enter Partner Name',
  //                       },
  //                     ]}
  //                   >
  //                     <Input className='' placeholder='Partner Name' />
  //                   </Form.Item>
  //                 </div>
  //                 <div className='col-lg-12'>
  //                   <label>
  //                     Address <span className='required-star'>*</span>
  //                   </label>
  //                   <Form.Item
  //                     name='streetAddress'
  //                     defaultValue='1'
  //                     colon={false}
  //                     style={{ wordWrap: 'normal' }}
  //                     rules={[
  //                       {
  //                         required: true,
  //                         message: 'Enter Address',
  //                       },
  //                     ]}
  //                   >
  //                     <Input placeholder='Address' />
  //                   </Form.Item>
  //                 </div>
  //               </div>
  //               <div className='row'>
  //                 <div className='col-lg-6'>
  //                   <label>
  //                     City <span className='required-star'>*</span>
  //                   </label>
  //                   <Form.Item
  //                     name='city'
  //                     defaultValue='1'
  //                     colon={false}
  //                     rules={[
  //                       {
  //                         required: true,
  //                         message: 'Enter City',
  //                       },
  //                     ]}
  //                   >
  //                     <Input placeholder='City' />
  //                   </Form.Item>
  //                 </div>
  //                 <div className='col-lg-6'>
  //                   <label>
  //                     State <span className='required-star'>*</span>
  //                   </label>
  //                   <Form.Item
  //                     name='state'
  //                     defaultValue='1'
  //                     colon={false}
  //                     rules={[
  //                       {
  //                         required: true,
  //                         message: 'Select State',
  //                       },
  //                     ]}
  //                   >
  //                     <Select placeholder='Select State'>
  //                       {states.map((item) => (
  //                         <Select.Option value={item.stateId}>{item.name}</Select.Option>
  //                       ))}
  //                     </Select>
  //                   </Form.Item>
  //                 </div>
  //               </div>
  //               <div className='row'>
  //                 <div className='col-lg-6'>
  //                   <label>
  //                     ZIP Code <span className='required-star'>*</span>
  //                   </label>
  //                   {/* <Form.Item
  //                     name='zipCode'
  //                     defaultValue='1'
  //                     colon={false}
  //                     rules={[
  //                       {
  //                         required: true,
  //                         message: 'Enter Zip-code',
  //                       },
  //                     ]}
  //                   > */}
  //                   {/* <Tooltip title={tooltip}> */}
  //                   <Input value={zipInput} onChange={handleInputZip} className='num-input' type='number' maxLength={5} placeholder='ZIP Code' />
  //                   {/* </Tooltip> */}

  //                   {/* </Form.Item> */}
  //                 </div>
  //                 <div className='col-lg-6'>
  //                   <label>Website</label>
  //                   <Form.Item name='website'>
  //                     <Input placeholder='Website' className='admin' style={{ width: '100% !important' }} />
  //                   </Form.Item>
  //                 </div>
  //                 {/* <div className='col-lg-6'>
  //           <Form.Item label='Active' colon={false} valuePropName='checked' name='isActive'>
  //             <Switch />
  //           </Form.Item>
  //                  </div> */}
  //               </div>

  //               {/* <div className='row'>
  //                 <div className='col-lg-6'> */}

  //               {/* </div>
  //               </div> */}
  //             </div>
  //             <div className='col-lg-4 mt-4'>
  //               <div className='col-lg-9' style={{ height: '300px' }}>
  //                 <Form.Item valuePropName='fileList'>
  //                   <Upload listType='picture-card' className='avatar-uploader' showUploadList={false} action={`${app.URL}/api/v1/general/user/imageupload`} beforeUpload={beforeUpload} onChange={handleuploadChange} headers={header}>
  //                     {uploadedImg && !imageuploading ? (
  //                       <img className='' src={uploadedImg} alt='avatar' />
  //                     ) : !uploadedImg && imageuploading ? (
  //                       <LoadingOutlined style={{ fontSize: '40px' }} />
  //                     ) : (
  //                       <>
  //                         <img className='for-add-img' src={AddPhoto} alt='avatar' />
  //                         <p className='add-text'>Upload Image</p>
  //                       </>
  //                     )}
  //                   </Upload>
  //                 </Form.Item>
  //               </div>
  //             </div>
  //           </div>
  //           <div className='col-lg-12' style={{ padding: '0 50px', paddingBottom: '20px' }}>
  //             <div className='row'>
  //               <div className='col-lg-6'>
  //                 <label>
  //                   Mobile <span className='required-star'>*</span>
  //                 </label>
  //                 <Form.Item
  //                   colon={false}
  //                   name='phone'
  //                   rules={[
  //                     {
  //                       required: true,
  //                       message: 'Enter Mobile',
  //                     },
  //                   ]}
  //                 >
  //                   <InputMask mask='(999) 999-9999' onChange={handleChange}>
  //                     {(inputProps) => <Input {...inputProps} placeholder='Mobile' style={{ width: '100% !important' }} />}
  //                     {/* <Input /> */}
  //                   </InputMask>
  //                   {/* <Input placeholder='Input Contact' /> */}
  //                 </Form.Item>
  //                 <Form.Item label='Active' className='selector-main' colon={false} valuePropName='checked' name='isActive'>
  //                   <Switch />
  //                 </Form.Item>
  //               </div>
  //               <div className='col-lg-6'>
  //                 <label>
  //                   Email <span className='required-star'>*</span>
  //                 </label>
  //                 <Form.Item
  //                   colon={false}
  //                   name='email'
  //                   rules={[
  //                     {
  //                       required: true,
  //                       type: 'email',
  //                       message: 'Enter Email',
  //                     },
  //                   ]}
  //                 >
  //                   <Input placeholder='Email' className='admin' style={{ width: '100% !important' }} />
  //                 </Form.Item>
  //               </div>
  //             </div>
  //             <div className='col-lg-12'>

  //                 <h2 className='dynamic-field-head'>Store</h2>

  //               <Form.List name='stores' className='col-lg-12'>
  //                 {(fields, { add, remove }, { errors }) => (
  //                   <>
  //                     {fields.map((field, index) => (
  //                       // <Space className='col-lg-12' key={field.key}>
  //                       <Form.Item className='col-lg-12' required={false} key={field.key}>
  //                         <div className='row' style={{ marginBottom: '-25px' }}>
  //                           <Form.Item
  //                             className='col-lg-9'
  //                             {...field}
  //                             validateTrigger={['onChange', 'onBlur']}
  //                             // noStyle
  //                           >
  //                             <Select placeholder={`Store ` + (index + 1)} className='col-lg-12'>
  //                               {storesList.map((item) => (
  //                                 <Select.Option value={item.storeId}>{item.name}</Select.Option>
  //                               ))}
  //                             </Select>
  //                           </Form.Item>
  //                           <Form.Item className='col-lg-2'>
  //                             {fields.length > 0 ? (
  //                               <div className=' add-btn ' style={{ textAlign: 'center', backgroundColor: '#F21D2F' }} onClick={() => remove(field.name)}>
  //                                 Remove
  //                               </div>
  //                             ) : null}
  //                           </Form.Item>
  //                         </div>
  //                       </Form.Item>
  //                       // </Space>
  //                     ))}
  //                     <Form.Item>
  //                       <div style={{ textAlign: 'center' }} onClick={() => add()} className='col-lg-3 add-btn'>
  //                         Add Store
  //                       </div>
  //                       <Form.ErrorList errors={errors} />
  //                     </Form.Item>
  //                   </>
  //                 )}
  //               </Form.List>
  //             </div>
  //             <div className='col-lg-12'>
  //               {/* <h3 className='dynamic-field-head'>Contact Person</h3> */}
  //               <Form.List name='contactPersons' className='col-lg-12'>
  //                 {(fields, { add, remove }) => (
  //                   <>
  //                     {fields.map(({ key, name, ...restField }) => (
  //                       <>
  //                         <h3 className='dynamic-field-head'>Contact Person {key + 1}</h3>
  //                         <div className='row'>
  //                           <div className='col-lg-6'>
  //                             <label>
  //                               Name <span className='required-star'>*</span>
  //                             </label>
  //                             <Form.Item
  //                               {...restField}
  //                               name={[name, 'partnerContactName']}
  //                               rules={[
  //                                 {
  //                                   required: true,
  //                                   message: 'Enter Name',
  //                                 },
  //                               ]}
  //                             >
  //                               <Input placeholder='Partner Contact Name' />
  //                             </Form.Item>
  //                           </div>
  //                           <div className='col-lg-6'>
  //                             <label>
  //                               Email <span className='required-star'>*</span>
  //                             </label>
  //                             <Form.Item
  //                               {...restField}
  //                               name={[name, 'email']}
  //                               rules={[
  //                                 {
  //                                   required: true,
  //                                   type: 'email',
  //                                   message: 'Enter Email',
  //                                 },
  //                               ]}
  //                             >
  //                               <Input placeholder='Email' />
  //                             </Form.Item>
  //                           </div>
  //                         </div>
  //                         <div className='row'>
  //                           <div className='col-lg-4'>
  //                             <label>
  //                               Mobile <span className='required-star'>*</span>
  //                             </label>
  //                             <Form.Item
  //                               {...restField}
  //                               name={[name, 'mobile']}
  //                               rules={[
  //                                 {
  //                                   required: true,
  //                                   message: 'Enter Phone',
  //                                 },
  //                               ]}
  //                             >
  //                               <InputMask mask='(999) 999-9999' onChange={handleChange}>
  //                                 {(inputProps) => <Input {...inputProps} placeholder='Mobile' style={{ width: '100% !important' }} />}
  //                                 {/* <Input /> */}
  //                               </InputMask>
  //                             </Form.Item>
  //                           </div>

  //                           <div className='col-lg-4'>
  //                             <div className='col-lg-4 mb-3 mt-4'>
  //                               <div className='add-btn' style={{ backgroundColor: '#F21D2F' }} onClick={() => remove(name)}>
  //                                 Remove
  //                               </div>
  //                             </div>
  //                           </div>

  //                           <div>
  //                             {/* <Form.Item> */}
  //                             {/* <div style={{ textAlign: "center" }} onClick={() => add()} className='col-lg-3 add-btn'>
  //                                 Add Contact
  //                               </div> */}
  //                             {/* <Form.ErrorList errors={errors} /> */}
  //                             {/* </Form.Item> */}
  //                           </div>
  //                           {/* <div className='col-lg-4 mb-3'><div className='add-btn' style={{ backgroundColor: "#F21D2F" }} onClick={() => remove(name)}>Remove</div></div> */}
  //                         </div>
  //                       </>
  //                     ))}

  //                     <div className='col-lg-3'>
  //                       <div className='add-btn' onClick={() => add()} style={{ cursor: 'pointer', color: '#fff', textAlign: 'center' }}>
  //                         {/* <img style={{ margin: "auto 10px", width: "20px" }} src={AddImg} /> */}
  //                         Add Contact Person
  //                       </div>
  //                     </div>
  //                     {/* <div className='add-btn' onClick={() => add()} style={{ cursor: 'pointer', color:"#fff",display:"flex"}}>
  //                         <img style={{margin:"auto 10px",width:"20px"}} src={AddImg}/><span> Add Contact Person</span>
  //                       </div> */}
  //                   </>
  //                 )}
  //               </Form.List>
  //             </div>
  //             <Form.Item>
  //               {addUserResp.code === 200 ? (
  //                 <Modal show={show} onHide={handleClose}>
  //                   <Modal.Header closeButton>
  //                     <Modal.Title className='text-center'>Success</Modal.Title>
  //                   </Modal.Header>
  //                   <Modal.Body>{addUserResp.message}</Modal.Body>
  //                   <Modal.Footer></Modal.Footer>
  //                 </Modal>
  //               ) : addUserResp.code === 400 ? (
  //                 <Modal show={show} onHide={handleClose}>
  //                   <Modal.Header closeButton>
  //                     <Modal.Title className='text-center'>Failed</Modal.Title>
  //                   </Modal.Header>
  //                   <Modal.Body>{addUserResp.data.message}</Modal.Body>
  //                   <Modal.Footer></Modal.Footer>
  //                 </Modal>
  //               ) : (
  //                 <Modal show={show} onHide={handleClose}>
  //                   {/* <Modal.Header closeButton>
  //                     <Modal.Title>Confirmation</Modal.Title>
  //                   </Modal.Header> */}
  //                   <Modal.Body>Are you sure you want to add partner?</Modal.Body>
  //                   <Modal.Footer>
  //                     <Button variant='secondary' onClick={handleClose}>
  //                       Cancel
  //                     </Button>
  //                     <Button variant='primary' htmlType='submit' onClick={submitData}>
  //                       Confirm
  //                     </Button>
  //                   </Modal.Footer>
  //                 </Modal>
  //               )}
  //             </Form.Item>
  //             <button
  //               onClick={() => {
  //                 navigate('/partner/listing');
  //               }}
  //               type='primary'
  //               className='btn btn-primary button patient-cancel-btn col-lg-3 mr-2'
  //             >
  //               Cancel
  //             </button>

  //             <button type='primary' class='btn btn-primary button col-lg-3' htmlType='submit'>
  //               Save
  //             </button>
  //           </div>
  //         </Form>
  //       </div>
  //     </div>
  //   </>
  // );
};

export default AddPartner;
