import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
// import { message, Switch, Select, Form, Input, Space, Upload } from 'antd';
import { message, DatePicker, Row, Col, Select, Form, Input, Upload, Space, Button as BTN, Switch, Textarea } from 'antd';
// import Button from 'react-bootstrap/Button';
// import Modal from 'react-bootstrap/Modal';
import { app } from '../../config';
import useFetchRoles from '../../hooks/fetchRoles';
// import { LeftCircleOutlined, UploadOutlined } from '@ant-design/icons';
// import InputMask from 'react-input-mask';
import getHeaders from '../../utils/authHeaders';
import { LoadingOutlined, MinusCircleOutlined, PlusCircleOutlined, PlusOutlined } from '@ant-design/icons';
// import AddPhoto from '../../assets/images/add.png';
import { AddRecord } from '../../layouts/AddRecord';
import { InputField, InputMasker, SelectField, TextAreaField } from '../../layouts/FormFields';
import { noPrespace, noPrespaceNoAlphabetsNoSpecialChar } from './ValidationFunctions';
// console.log(app);
const { TextArea } = Input;

const Useradd = () => {
  const { t } = useTranslation(['common']);
  const [phoneNumber, setPhoneNumber] = useState();
  const [addUserResp, setAddUserResp] = useState({});
  const [pwd, setpwd] = useState('');
  const [confirmPwd, setconfirmPwd] = useState('');
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [uploadedImg, setUploadedImg] = useState('');
  const [imageuploading, setImageUploading] = useState(false);
  const [uploadLogo, setUploadLogo] = useState(true);
  const [isActive, setisActive] = useState(false);
  // const [roles, setRoles ] = useSt
  let navigate = useNavigate();
  let user = JSON.parse(localStorage.getItem('user'));
  // console.log("user " ,user);
  // user?console.log(user.result.data.user.userId):" ";
  // let ot = useFetchOrganizationTypes();
  // console.log(ot);
  // let orgTypes = ot.response ? ot.response.data : [];
  // console.log(orgTypes);
  // const o = useFetchOrganizations();
  // const orgs = o.response ? o.response.data : [];
  // console.log(orgs);
  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  };
  let header = getHeaders();
  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
  };
  const handleuploadChange = async (info) => {
    if (info.file.status === 'uploading') {
      setImageUploading(true);
      setLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      info.file ? console.log(info.file.response.result.data.Location) : console.log('Not found');
      info.file ? setUploadedImg(info.file.response.result.data.Location) : ' ';
      getBase64(info.file.originFileObj, (url) => {
        setImageUploading(false);
        setLoading(false);
        setUploadLogo(false);
      });
    }
  };
  const fetchedRoles = useFetchRoles();
  console.log('fetchedRoles ', fetchedRoles);
  const roles = fetchedRoles.response ? fetchedRoles.response.data : [];
  console.log('roles ', roles);
  const [uploadObj, setUploadObj] = useState({});


  const uploadData = async (userDetails) => {
    let userId = user?.result.data.user.userId;
    userDetails.userId = userId;
    setLoading(true)
    try {
      console.log('uploadObj ', userDetails);
      const res = await axios.post(`${app.URL}/api/v1/admin/adminAccount/userCreate`, userDetails, { headers: getHeaders() });
      setLoading(false);
    setShow(false);

      if (res.data.result.success) {
        setAddUserResp(res.data.result.data);
        message.success(res.data.result.msg);

        setTimeout(() => {
          navigate('/adminaccount/userlisting');
        }, 1000);
      } else if (!res.data.result.success) {
        message.warning(res.data.result.msg);
      }
    } catch (err) {
      setLoading(false);
    setShow(false);

      console.log(err);
      if (err) console.log(err.response.data.message);
      else return;
    }
  };

  const handleChange = (event) => {
    setPhoneNumber(event.target.value);
    // handle phone number input here
  };
  const onFinish = (values) => {
    console.log('onFinish');
    console.log('Success:', values);
    if (pwd !== confirmPwd) {
      console.log(pwd, confirmPwd);
      message.error('Passwords do not match');
      return;
    }
    // o={...values}
    // console.log(values.validityDate.format().split('T')[0]);
    setUploadObj({
      firstName: values.firstName ? values.firstName : null,
      lastName: values.lastName ? values.lastName : null,
      phone: phoneNumber ? phoneNumber : '',
      email: values.email ? values.email : null,
      password: values.password ? values.password : null,
      roleId: values.roleId ? values.roleId : 1,
      image: uploadedImg ? uploadedImg : null,
      // organizationId: values.organizationId ? values.organizationId : null,
      isActive: values.isActive == true ? 1 : 0,
      // knowUserId: values.knowUserId ? values.knowUserId : null,
      userType: 'O',
      createdByUserId: user.result ? user.result.data.user.userId : null,
    });
    setShow(true);
  };
  console.log(uploadObj);
  const submitData = () => {
    console.log('calling');
    if (Object.keys(uploadObj).length > 0) {
      uploadData(uploadObj);
    }
  };
  const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);

  // uploadData(uploadObj);
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  // const ca = useFetchCauseCategories();
  // console.log(c);
  // let causes = c ? c.response : [];
  // console.log(causes);

  function goBack() {
    navigate('/adminaccount/userlisting', { replace: true });
  }
  window.addEventListener('popstate', goBack);
  const validatePassword = (_, value) => {
    if (value) {

        if (value.length < 6) {
          return Promise.reject('Password must be at least 6 characters long.');
        }
      if (!/[A-Z]/.test(value)) {
        return Promise.reject('Password must contain at least one uppercase letter.');
      }
      if (!/[0-9]/.test(value)) {
        return Promise.reject('Password must contain at least one digit.');
      }
    }
    return Promise.resolve();
  };
   const handleKeyPress = (e) => {
    console.log(e);
noPrespaceNoAlphabetsNoSpecialChar(e);
 
  };
   const handleKeysPress = (e) => {
     console.log(e);
     noPrespace(e);
   };
  ;
  return (
    <AddRecord
      loading={loading}
      pageTitle='Add User'
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      handleClose={handleClose}
      show={show}
      addUserResp={addUserResp}
      goBack={goBack}
      initialValues={{
        remember: true,
        ['id']: 1,
        ['email']: null,
        ['password']: null,
        ['isActive']: true,
      }}
      inputs={[
        <InputField
          col='col-lg-6 col-md-4 col-12'
          rules={[
            {
              required: true,
              message: 'Enter First Name',
            },
          ]}
          placeholder='First Name'
          name='firstName'
          label='First Name'
          required={true}
          onKeyPress={handleKeyPress}
        />,
        <InputField
          rules={[
            {
              required: true,
              message: 'Enter Last Name',
            },
          ]}
          col='col-lg-6 col-md-4 col-12'
          placeholder='Last Name'
          name='lastName'
          label='Last Name'
          required={true}
          onKeyPress={handleKeyPress}
        />,

        <InputField
          rules={[
            {
              required: true,
              message: 'Enter Email',
              type: 'email',
            },
          ]}
          col='col-lg-6 col-md-4 col-12'
          placeholder='Email'
          name='email'
          label='Email'
          required={true}
          onKeyPress={handleKeysPress}
        />,
        <InputMasker
          col='col-lg-6 col-md-4 col-12'
          placeholder='Mobile'
          name='mobile'
          handleChange={handleChange}
          label='Mobile'
          required={true}
          rules={[
            {
              required: true,
              message: 'Enter Mobile',
            },
          ]}
        />,
        // <InputField
        //   rules={[
        //     {
        //       required: true,
        //       message: 'Provide Know Id',
        //     },
        //   ]}
        //   col='col-lg-6'
        //   placeholder='Know Id'
        //   name='knowUserId'
        //   label='Know Id'
        //   required={true}
        // />,
        <InputField
          rules={[
            {
              required: true,
              message: 'Password is required.',
              // type: 'password',
            },
            { validator: validatePassword },
          ]}
          col='col-lg-6 col-md-4 col-12'
          placeholder='Password'
          name='password'
          label='Password'
          required={true}
          onKeyPress={handleKeysPress}
          onChange={(e) => setpwd(e.target.value)}
        />,
        <InputField
          rules={[
            {
              required: true,
              message: 'Confirm Password is required.',
              // type: 'password',
            },
            { validator: validatePassword },
          ]}
          col='col-lg-6 col-md-4 col-12'
          placeholder='Confirm Password'
          name='confirmPassword'
          label='Confirm Password'
          onChange={(e) => setconfirmPwd(e.target.value)}
          required={true}
          onKeyPress={handleKeysPress}
        />,
      ]}
      // inputMask={[
      //   <InputMasker
      //     col='col-lg-6 col-md-4 col-12'
      //     placeholder='Mobile'
      //     name='mobile'
      //     handleChange={handleChange}
      //     label='Mobile'
      //     required={true}
      //     rules={[
      //       {
      //         required: true,
      //         message: 'Enter Mobile',
      //       },
      //     ]}
      //   />,
      // ]}
      selects={[
        <SelectField
          options={roles.map((role) => (
            <Select.Option key={role.roleId} value={role.roleId}>
              {role.name}
            </Select.Option>
          ))}
          col='col-lg-6 col-md-4 col-12'
          label='Role'
          name='roleId'
          placeholder='Select Role'
          required={true}
          rules={[
            {
              required: true,
              message: 'Select Role',
            },
          ]}
        />,
        // <SelectField
        //   options={statusTypes}
        //   col='col-lg-6'
        //   label='Status'
        //   name='status'
        //   placeholder='Select Status'
        //   required={true}
        //   rules={[
        //     {
        //       required: true,
        //       message: 'Select Status',
        //     },
        //   ]}
        // />,
      ]}
      textareas={[]}
      submitData={submitData}
      switchBtn={true}
      addMessage='Are you sure you want to add user?'
      upload={true}
      handleuploadChange={handleuploadChange}
      beforeUpload={beforeUpload}
      action={`${app.URL}/api/v1/general/user/imageupload`}
      imageuploading={imageuploading}
      uploadedImg={uploadedImg}
      setUploadedImg={setUploadedImg}
    />
  );

  // return (
  //   <>
  //     <div className='col-lg-12'>
  //       <div className='dashboard-card'>
  //         <div className='row'>
  //           <div className='for-before col-lg-4 head fs-3 fw-bold' style={{ backgroundColor: '#a7c1d9', borderBottomRightRadius: '10px', color: '#FFFFFF' }}>
  //             Add User
  //           </div>
  //         </div>
  //         <div class='col mb-5 mt-4'>
  //           <div className='row' style={{ padding: '0 50px' }}>
  //             <div class='col-lg-8 align-self-center mt-2 mb-4'>
  //               <Form
  //                 name='basic'
  //                 labelCol={{
  //                   span: 0,
  //                 }}
  //                 wrapperCol={{
  //                   span: 24,
  //                 }}
  //                 initialValues={{
  //                   remember: true,
  //                   ['id']: 1,
  //                   ['email']: null,
  //                   ['password']: null,
  //                   ['isActive']: true,
  //                 }}
  //                 onFinish={onFinish}
  //                 onFinishFailed={onFinishFailed}
  //                 autoComplete='off'
  //               >
  //                 {/* Select box for Role */}

  //                 <div className='row'>
  //                   <div className='col-lg-6'>
  //                     <label>
  //                       First Name <span className='required-star'>*</span>
  //                     </label>
  //                     <Form.Item
  //                       // label='First Name'
  //                       name='firstName'
  //                       defaultValue='1'
  //                       colon={false}
  //                       rules={[
  //                         {
  //                           required: true,
  //                           message: 'Enter Frist Name',
  //                         },
  //                       ]}
  //                     >
  //                       <Input placeholder='First Name' />
  //                     </Form.Item>
  //                   </div>
  //                   <div className='col-lg-6'>
  //                     <label>
  //                       Last Name <span className='required-star'>*</span>
  //                     </label>
  //                     <Form.Item
  //                       // label='Last Name'
  //                       name='lastName'
  //                       defaultValue='1'
  //                       colon={false}
  //                       rules={[
  //                         {
  //                           required: true,
  //                           message: 'Enter Last Name',
  //                         },
  //                       ]}
  //                     >
  //                       <Input placeholder='Last Name' />
  //                     </Form.Item>
  //                   </div>
  //                 </div>

  //                 <div className='row'>
  //                   <div className='col-lg-6'>
  //                     <label>
  //                       Mobile <span className='required-star'>*</span>
  //                     </label>
  //                     <Form.Item
  //                       colon={false}
  //                       label='Mobile'
  //                       name='phone'
  //                       rules={[
  //                         {
  //                           required: true,
  //                           message: 'Enter Mobile',
  //                         },
  //                       ]}
  //                     >
  //                       <InputMask mask='(999) 999-9999' onChange={handleChange}>
  //                         {(inputProps) => <Input {...inputProps} placeholder='Mobile' />}
  //                         {/* <Input /> */}
  //                       </InputMask>
  //                       {/* <Input placeholder='Input Contact' /> */}
  //                     </Form.Item>
  //                   </div>
  //                   <div className='col-lg-6'>
  //                     <label>
  //                       Email <span className='required-star'>*</span>
  //                     </label>
  //                     <Form.Item
  //                       colon={false}
  //                       label='Email'
  //                       name='email'
  //                       autoComplete='off'
  //                       autofill='off'
  //                       rules={[
  //                         {
  //                           required: true,
  //                           type: 'email',
  //                           message: 'Enter Email',
  //                         },
  //                       ]}
  //                     >
  //                       <Input placeholder='Email' autoComplete='new-email' defaultValue={' '} />
  //                     </Form.Item>
  //                   </div>
  //                 </div>
  //                 <div className='row'>
  //                   {/* <div className='col-md-6'>
  //                     <label>
  //                       Know Id <span className='required-star'>*</span>
  //                     </label>
  //                     <Form.Item
  //                       label='Know Id'
  //                       colon={false}
  //                       name='knowUserId'
  //                       rules={[
  //                         {
  //                           required: true,
  //                           message: 'Provide know Id',
  //                         },
  //                       ]}
  //                     >
  //                       <Input placeholder='Know Id' />
  //                     </Form.Item>
  //                   </div> */}
  //                   <div className='col-md-12'>
  //                     <label>
  //                       Role <span className='required-star'>*</span>
  //                     </label>
  //                     <Form.Item
  //                       label='Role'
  //                       colon={false}
  //                       name='roleId'
  //                       rules={[
  //                         {
  //                           required: true,
  //                           message: 'Select Role',
  //                         },
  //                       ]}
  //                     >
  //                       <Select
  //                         style={{
  //                           width: 180,
  //                         }}
  //                         placeholder='Select Role'
  //                       >
  //                         {roles.map((role) => {
  //                           return (
  //                             <Option key={role.roleId} value={role.roleId}>
  //                               {role.name}
  //                             </Option>
  //                           );
  //                         })}
  //                       </Select>
  //                     </Form.Item>
  //                   </div>
  //                 </div>

  //                 <div className='row'>
  //                   <div className='col-lg-6'>
  //                     <label>
  //                       Password <span className='required-star'>*</span>
  //                     </label>
  //                     <Form.Item colon={false} label='Password' name='password' autoComplete='off' rules={[{ required: true, message: 'Password is required.' }, { validator: validatePassword }]}>
  //                       <Input.Password onChange={(e) => setpwd(e.target.value)} autoComplete='new-password' placeholder='Password' />
  //                     </Form.Item>
  //                     <label className='mt-1' style={{ display: 'inline-block', width: '17%' }}>
  //                       Active
  //                     </label>
  //                     <Form.Item style={{ display: 'inline-block', width: '50%' }} colon={false} valuePropName='checked' name='isActive'>
  //                       <Switch onChange={(e) => setisActive(e)} />
  //                     </Form.Item>
  //                   </div>
  //                   <div className='col-lg-6'>
  //                     <label>
  //                       Confirm Password <span className='required-star'>*</span>
  //                     </label>
  //                     <Form.Item colon={false} label='Confirm Password' name='confirmPassword' rules={[{ required: true, message: 'Password is required.' }, { validator: validatePassword }]}>
  //                       <Input.Password onChange={(e) => setconfirmPwd(e.target.value)} placeholder='Confirm Password' />
  //                     </Form.Item>
  //                   </div>
  //                 </div>
  //                 <div className='row'>
  //                   {/* <div className='col-lg-6 text-right mb-5'>
  //                     <Form.Item label='Active' colon={false} valuePropName='checked' name='isActive'>
  //                       <Switch />
  //                     </Form.Item>
  //                   </div> */}

  //                   <div className='col-lg-12 mb-5'>
  //                     <Form.Item style={{ width: '100%', marginTop: '20px' }} wrapperCol={{}}>
  //                       {addUserResp.code === 200 ? (
  //                         <Modal show={show} onHide={handleClose}>
  //                           <Modal.Header closeButton>
  //                             <Modal.Title className='text-center'>Success</Modal.Title>
  //                           </Modal.Header>
  //                           <Modal.Body>{addUserResp.message}</Modal.Body>
  //                           <Modal.Footer></Modal.Footer>
  //                         </Modal>
  //                       ) : addUserResp.code === 400 ? (
  //                         <Modal show={show} onHide={handleClose}>
  //                           <Modal.Header closeButton>
  //                             <Modal.Title className='text-center'>Failed</Modal.Title>
  //                           </Modal.Header>
  //                           <Modal.Body>{addUserResp.data.message}</Modal.Body>
  //                           <Modal.Footer></Modal.Footer>
  //                         </Modal>
  //                       ) : (
  //                         <Modal show={show} onHide={handleClose}>
  //                           {/* <Modal.Header closeButton>
  //                             <Modal.Title>Confirmation</Modal.Title>
  //                           </Modal.Header> */}
  //                           <Modal.Body>Are you sure you want to add user?</Modal.Body>
  //                           <Modal.Footer>
  //                             <Button variant='secondary' onClick={handleClose}>
  //                               Cancel
  //                             </Button>
  //                             <Button variant='primary' htmlType='submit' onClick={submitData}>
  //                               Confirm
  //                             </Button>
  //                           </Modal.Footer>
  //                         </Modal>
  //                       )}
  //                     </Form.Item>
  //                   </div>
  //                 </div>

  //                 <div className='row'>
  //                   <button
  //                     onClick={() => {
  //                       navigate('/adminaccount/userlisting');
  //                     }}
  //                     type='primary'
  //                     className='btn btn-primary button patient-cancel-btn col-lg-3 mr-2 col-lg-5 mr-2'
  //                   >
  //                     Cancel
  //                   </button>

  //                   <button type='primary' className='col-lg-5 btn btn-primary button' htmlType='submit'>
  //                     Save
  //                   </button>
  //                 </div>

  //                 {/* Select box for Organization */}
  //               </Form>
  //             </div>
  //             <div class='col-lg-4 mt-2 mb-4'>
  //               <div class='col-lg-9'>
  //                 <Form.Item valuePropName='fileList'>
  //                   <Upload listType='picture-card' className='avatar-uploader' showUploadList={false} action={`${app.URL}/api/v1/general/user/imageupload`} beforeUpload={beforeUpload} onChange={handleuploadChange} headers={header}>
  //                     {uploadedImg && !imageuploading ? (
  //                       <img className='' src={uploadedImg} alt='avatar' />
  //                     ) : !uploadedImg && imageuploading ? (
  //                       <LoadingOutlined style={{ fontSize: '40px' }} />
  //                     ) : (
  //                       <>
  //                         <img className='for-add-img' src={AddPhoto} alt='avatar' />
  //                         <p className='add-text'>Upload Image</p>
  //                       </>
  //                     )}
  //                   </Upload>
  //                 </Form.Item>
  //               </div>
  //               {/* <Form.Item valuePropName='fileList'>
  //                   <Upload
  //                     // name="avatar"
  //                     listType='picture-card'
  //                     className='avatar-uploader'
  //                     showUploadList={false}
  //                     action={`${app.URL}/api/v1/general/user/imageupload`}
  //                     beforeUpload={beforeUpload}
  //                     onChange={handleuploadChange}
  //                     headers={header}
  //                   >
  //                     {uploadLogo ? (
  //                       loading ? (
  //                         uploadButton
  //                       ) : (
  //                         uploadButton
  //                       )
  //                     ) : (
  //                       <img
  //                         src={uploadedImg}
  //                         alt='avatar'
  //                         style={{
  //                           width: '100%',
  //                         }}
  //                       />
  //                     )}
  //                   </Upload>
  //                 </Form.Item> */}
  //               {/* <div className='selector-div' style={{backgroundColor:isActive?"#56A75F":" "}}>

  //               </div> */}
  //               {/* <Form.Item
  //                 style={{ width: "100%", marginTop: "20px" }}
  //                 wrapperCol={{
  //                 }}
  //               >
  //                 {addUserResp.code === 200 ? (
  //                   <Modal show={show} onHide={handleClose}>
  //                     <Modal.Header closeButton>
  //                       <Modal.Title className='text-center'>Success</Modal.Title>
  //                     </Modal.Header>
  //                     <Modal.Body>{addUserResp.message}</Modal.Body>
  //                     <Modal.Footer>
  //                     </Modal.Footer>
  //                   </Modal>
  //                 ) : addUserResp.code === 400 ? (
  //                   <Modal show={show} onHide={handleClose}>
  //                     <Modal.Header closeButton>
  //                       <Modal.Title className='text-center'>Failed</Modal.Title>
  //                     </Modal.Header>
  //                     <Modal.Body>{addUserResp.data.message}</Modal.Body>
  //                     <Modal.Footer></Modal.Footer>
  //                   </Modal>
  //                 ) : (
  //                   <Modal show={show} onHide={handleClose}>
  //                     <Modal.Header closeButton>
  //                       <Modal.Title>Confirmation</Modal.Title>
  //                     </Modal.Header>
  //                     <Modal.Body>Are you sure you want to add user?</Modal.Body>
  //                     <Modal.Footer>
  //                       <Button variant='secondary' onClick={handleClose}>
  //                         Cancel
  //                       </Button>
  //                       <Button variant='primary' htmlType='submit' onClick={submitData}>
  //                     Confirm
  //                       </Button>
  //                     </Modal.Footer>
  //                   </Modal>
  //                 )}

  //                 <button style={{ width: "100%" }} type='primary' class='btn btn-primary button' htmlType='submit'>
  //                   Save
  //                 </button>
  //               </Form.Item> */}
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //     {/* <div class='container'>
  //     <div class='row mt-4 w-75 tbl'>
  //       <div class='row topbar ' style={{ height: '70px' }}>
  //         <div class='col-lg-4 fs-3 fw-bold'>
  //           {' '}
  //           <LeftCircleOutlined
  //             onClick={() => {
  //               navigate('/adminaccount/userlisting');
  //             }}
  //             className='mt-3 ml-4 back-icon'
  //             style={{ fontSize: '38px' }}
  //           />
  //         </div>
  //         <div class='col-lg-7 fs-3 fw-bold '>
  //           <h2 class=' mb-5 mt-4 fw-bold text-white fs-4 ml-4'> {t('Add User')} </h2>
  //         </div>
  //       </div>

  //     </div>
  //   </div> */}
  //   </>
  // );
};

export default Useradd;
