import axios from 'axios';
import { useEffect, useState } from 'react';
import { app } from '../config';
import getHeaders from '../utils/authHeaders';
const getBoxType = (id, setVideo) => {
  let reqid = id;
  const [loading, setLoading] = useState(true);
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  useEffect(() => {
    const get = async (id) => {
      try {
        const res = await axios.get(`${app.URL}/api/v1/admin/boxtype/view/${id}`, { headers: getHeaders() });
        setResponse(res.data.result);
        setVideo(res.data.result.data.videoUrl);
        // setImage(res.data.result.data.imageUrl);
        setLoading(false);
      } catch (err) {
        setError(err);
        setLoading(false);
      }
    };
    get(reqid);
  }, []);

  return { loading, response, error };
};
export default getBoxType;
