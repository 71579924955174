import React, { useState } from 'react'

import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { message, Switch, Select, Form, Input, Row, Col, DatePicker, Upload, Space, Button } from 'antd';
import Modal from 'react-bootstrap/Modal';
import { app } from '../../config';
import getHeaders from '../../utils/authHeaders';
import TextArea from 'antd/lib/input/TextArea';
import { AddRecord } from '../../layouts/AddRecord';
import { InputField, SelectField, TextAreaField } from '../../layouts/FormFields';
import TagInput from '../Products/TagInput';
import { InfoCircleOutlined, LoadingOutlined, UploadOutlined } from '@ant-design/icons';
import getSampleVideoType from '../../hooks/getSampleVideoType';
import AddPhoto from '../../assets/images/add.png';
import getBoxIngType from '../../hooks/getBoxIngType';
import LoaderImage from '../LoaderImage';

const AddModal = ({ showAddModal, setShowAddModal, setIsRender, modalLoader }) => {
  const { t } = useTranslation(['common']);
  const [addUserResp, setAddUserResp] = useState({});
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tags, setTags] = useState([]);
  const [uploadVideo, setuploadVideo] = useState('');
  const [contentType, setContentType] = useState();
  const [uplVid, setuplVid] = useState(true);
  const sampleVideoRes = getSampleVideoType();
  const [imageuploading, setimageuploading] = useState(false);
  const [uploadImage, setuploadImage] = useState('');
  let headers = getHeaders();

  const [form] = Form.useForm();

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    // reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  };
  const beforeUpload = (file, e) => {
    console.log(file);

    if (file.type === 'image/jpeg' || file.type === 'image/png') {
      console.log('setting image type');
      setContentType('img');
    } else if (file.type === 'video/x-matroska' || file.type === 'video/mp4') setContentType('video');
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'video/x-matroska' || file.type === 'video/mp4';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    console.log('file Checked');
    const isLt2M = file.size / 1024 / 1024 < 20;
    if (!isLt2M) {
      setLargeFile(true);
      message.error('Image must smaller than 20MB!');
    }
    console.log('message');
    return isJpgOrPng && isLt2M;
  };

  const handleuploadChange = (info) => {
    setuploadVideo('');

    console.log(info);
    if (info.file.status === 'uploading') {
      return;
    }
    if (info.file.status === 'done') {
      info.file ? console.log(info.file.response.result.data.Location) : console.log('Not found');
      info.file ? setuploadVideo(info.file.response.result.data.Location) : ' ';
      getBase64(info.file.originFileObj, (url) => {
        setuplVid(false);
      });
    }
  };

  let navigate = useNavigate();
  const [uploadObj, setUploadObj] = useState({});
  function goBack() {
    navigate('/boxtypeing/view', { replace: true });
  }
  window.addEventListener('popstate', goBack);

  const user = JSON.parse(localStorage.getItem('user'));

  const uploadData = async (userDetails) => {
    setLoading(true);
    let userId = user?.result.data.user.userId;
    userDetails.userId = userId;
    try {
      const res = await axios.post(`${app.URL}/api/v1/admin/boxtypeing/add`, userDetails, { headers: getHeaders() });
      setLoading(false);
      setShow(false);

      if (res.data.result.success) {
        form.resetFields();
        setTimeout(() => {
          setShowAddModal(false);
          setIsRender(true);
        }, 1000);

        // navigate('boxtypeing/view');
        message.success(res.data.result.msg);

        // setTimeout(goBack, 500);
      } else if (!res.data.result.success) {
        message.warning(res.data.result.msg);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
      setShow(false);

      if (err) console.log(err.response.data.message);
      else return;
    }
  };

  const onFinish = (values) => {
    console.log('On Finish', values);

    const boxObj = {
      ...values,
      image: uploadImage,
    };
    console.log('Box Obj', boxObj);
    uploadData(boxObj);
  };

  const submitData = async () => {
    console.log(uploadObj);
    if (Object.keys(uploadObj).length > 0) {
      uploadData(uploadObj);
    }
  };
  const handleClose = () => setShow(false);
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const handleuploadImage = (info) => {
    console.log('Info', info);
    if (info.file.status === 'uploading') {
      setimageuploading(true);
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      info.file ? console.log(info.file.response.result.data.Location) : console.log('Not found');
      info.file ? setuploadImage(info.file.response.result.data.Location) : ' ';
      setimageuploading(false);
    }
  };

  return (
    <div
      className={`modal fade ${showAddModal ? 'show' : ''}`}
      id='appointmentModal'
      tabIndex='-1'
      aria-labelledby='exampleModalLabel'
      aria-hidden='true'
      style={{
        backdropFilter: 'blur(5px)',
        display: showAddModal ? 'block' : 'none',
      }}
    >
      <div className={`modal-dialog position-relative modal-dialog-centered modal-lg `}>
        <div className='modal-content'>
          {/* <button
            type='button'
            className='btn-close btn-success bg-dark position-absolute top-0 end-0'
            onClick={() => setShowAddModal(false)}
            style={{
              marginTop: '-2rem',
              color: 'black',
            }}
            data-bs-dismiss='modal'
            aria-label='Close'
          ></button> */}
          {modalLoader ? (
            <LoaderImage />
          ) : (
            <div className='modal-body p-3'>
              <Form
                form={form}
                name='basic'
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete='off'
                initialValues={{
                  ['isActive']: true,
                  ['name']: '',
                  ['SKU']: '',
                  ['isShowImage']: false,
                }}
                className='row p-5 px-3 pb-1'
              >
                <InputField
                  label='Name'
                  name='name'
                  col='col-md-3 col-6'
                  required={true}
                  placeholder='Name'
                  rules={[
                    {
                      required: true,
                      message: 'Enter Name',
                    },
                  ]}
                />
                ,
                <InputField
                  label='SKU'
                  name='SKU'
                  col='col-md-3 col-6'
                  required={true}
                  placeholder='SKU'
                  rules={[
                    {
                      required: true,
                      message: 'Enter SKU',
                    },
                  ]}
                />
                ,
                <div className='col-md-4 text-center'>
                  <Form.Item className='w-100 h-auto' valuePropName='fileList'>
                    <Upload listType='picture-card' showUploadList={false} action={`${app.URL}/api/v1/general/banner/imageupload`} beforeUpload={beforeUpload} onChange={handleuploadImage} headers={headers}>
                      {uploadImage && !imageuploading ? (
                        <img className='added-img' src={uploadImage} alt='avatar' />
                      ) : !uploadImage && imageuploading ? (
                        <LoadingOutlined style={{ fontSize: '40px' }} />
                      ) : (
                        <div className='d-flex flex-column py-3'>
                          <img className='for-add-img' src={AddPhoto} alt='avatar' />
                          <p className='add-text'>Upload Image</p>
                        </div>
                      )}
                    </Upload>

                    {uploadImage && !imageuploading && (
                      <button onClick={() => setuploadImage('')} style={{ backgroundColor: 'rgb(242, 29, 47)' }} className='add-btn'>
                        Remove
                      </button>
                    )}
                  </Form.Item>
                  <Form.Item colon={false} valuePropName='checked' defaultValue={true} label='Show Image' name='isShowImage'>
                    <Switch />
                  </Form.Item>
                </div>
                <TextAreaField
                  name='description'
                  label='Description'
                  col='col-lg-8 col-md-6 col-12'
                  colon={false}
                  rules={[
                    {
                      required: true,
                      message: 'Enter Description',
                    },
                  ]}
                  rows={6}
                  showCount={true}
                  maxLength={2500}
                  placeholder='Box Description'
                />
                ,
                <Form.Item colon={false} valuePropName='checked' defaultValue={true} label='Active' name='isActive'>
                  <Switch />
                </Form.Item>
                {/* <Form.Item label='Active' className='selector-main' colon={false} valuePropName='checked' name='isActive'>
                <Switch
                  onChange={(e) => {
                    console.log(e);
                    setisActiv(e);
                  }}
                />
              </Form.Item> */}
                <button
                  onClick={() => {
                    setShowAddModal(false);
                  }}
                  type='button'
                  className={`btn btn-primary button patient-cancel-btn  ${location.pathname === '/patient/create' ? 'col-md-3' : 'col-md-4'} mx-md-2 mr-2 mb-2`}
                >
                  Cancel
                </button>
                <button type='primary' className={`btn btn-primary button ${location.pathname === '/patient/create' ? 'col-md-3' : 'col-md-4'}  mb-2`} htmlType='submit'>
                  Save
                </button>
              </Form>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AddModal