import { React, useEffect, useState } from 'react';
import RoutesFilter from './RoutesFilter';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import DefaultRoutes from './routes/defaultRoutes';

import fetchCurrentAccount from './hooks/fetchCurrentAccount';
import { app } from './config';
import getHeaders from './utils/authHeaders';
import axios from 'axios';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import LoaderImage from './pages/LoaderImage';

const user = JSON.parse(localStorage.getItem('user'));

async function checkCurrentAccount(navigate, location) {
  const currentAccocunt = await axios.get(`${app.URL}/api/v1/admin/adminAccount/currentAccount?email=${user.result.data.user.email}`, { headers: getHeaders() });
  console.log(currentAccocunt);
  if (currentAccocunt?.data.result.success) {
    if (location.pathname === '/') {
      const dashboardPermission = currentAccocunt?.data.result?.data?.permission?.filter((item) => item.action === 'dashboard/listing');
      if (dashboardPermission.length > 0) {
        navigate(`/${dashboardPermission[0].action}`);
      } else {
        navigate(`/${currentAccocunt?.data.result?.data?.permission[0]?.action}`);
      }
    }
  }
}

const checkUserSession = async (setisExpired, navigate, location) => {
  const session = await axios.get(`${app.URL}/api/v1/admin/session`, { headers: getHeaders() });
  if (session?.data?.result?.data?.isExpired) {
    navigate('/admin/login');
    localStorage.clear();
    setisExpired(session?.data?.result?.data?.isExpired);
  } else {
    setisExpired(session?.data?.result?.data?.isExpired);
    checkCurrentAccount(navigate, location);
  }
};
const App = () => {
  const [isExpired, setisExpired] = useState();
  const [splash, setSplash] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (user) {
      checkUserSession(setisExpired, navigate, location);
    } else {
      navigate('/admin/login');
      localStorage.clear();
      setisExpired(true)
    }
    setTimeout(() => {
      setSplash(false);
    }, 2000);
  }, []);

  if(!splash){
    if (!isExpired) {
      return <RoutesFilter />;
    } else {
      return <DefaultRoutes />;
    }
  }
  else{
    return <div className='d-flex vh-100 align-items-center justify-content-center'>
      <LoaderImage />
    </div>
  }

};
export default App;
