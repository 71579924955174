import React, { useState, useEffect, useRef } from 'react';
import { InputNumber } from 'antd';
import axios from 'axios';
import { app } from '../../config';
import getHeaders from '../../utils/authHeaders';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import { message, Image, Select, Form, Input, Row, Col, DatePicker } from 'antd';
const { TextArea } = Input;
import Button from 'react-bootstrap/Button';

import { CloseCircleOutlined, CheckCircleOutlined, CheckOutlined, ConsoleSqlOutlined, LoadingOutlined, CodeSandboxCircleFilled } from '@ant-design/icons';
import { useForm } from 'antd/lib/form/Form'; // Import the useForm hook

const ShipmentOrderVerificationModal = ({ isVisible, setIsVisible, productData, setPageRefresh, pageRefresh }) => {
  const [genericBoxSerial, setGenericBoxSerial] = useState('');
  const [productSerial, setProductSerial] = useState('');
  const [errorGenBox, setErrorGenBox] = useState();
  const [errorSleeve, setErrorSleeve] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isRef, setIsRef] = useState(false);

  const [isSerialFailed, setIsSerialFailed] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
      setIsSerialFailed(false);
    }
  }, [isVisible, isSerialFailed]);

  const [sleeveSerialForm] = useForm();

  console.log('productData', productData);
  console.log('condition 1: ', productData.sleeveVerified !== null && productData.sleeveVerified === 1);
  console.log('condition 2: ', productData.boxVerified !== null && productData.boxVerified === 1);

  const [isProductSerialVerified, setIsProductSerialVerified] = useState(false);

  console.log('product data outside the functions: ', productData);
  const user = JSON.parse(localStorage.getItem('user'));

  const verifySleeveSerial = async (payload) => {
    console.log(payload);
    let userId = user?.result.data.user.userId;
    payload.userId = userId;
    try {
      const apiResponse = await axios.post(`${app.URL}/api/v1/admin/ecomorders/checksleeve`, payload, {
        headers: getHeaders(),
      });

      console.log('verifySleeveSerial apiResponse: ', apiResponse.data);

      if (apiResponse.data.result.code === 200) {
        setErrorSleeve(null);
        setIsRef(false);
        setIsProductSerialVerified(true);
      } else {
        setErrorSleeve(null);
        setIsProductSerialVerified(false);
      }
    } catch (error) {
      sleeveSerialForm.resetFields();
      setIsSerialFailed(true);
      setIsRef(true);
      console.log('verifySleeveSerial: ', error);
      console.log('error.response.data.msg: ', error.response.data);
      setErrorSleeve(error.response.data.data);
    }
  };

  const onConfirmProductSerialTrigger = async (eventData) => {
    console.log('eventData', productSerial);
    console.log('onConfirmProductSerialTrigger product data: ', productData);

    let verifySleeveUploadObj = {
      ItemCartShipId: productData.ItemCartShipId,
      boxType: productData.boxType,
      sleeveSerial: eventData ? eventData : productSerial,
      productId: productData.productId,
    };
    console.log('onConfirmProductSerialTrigger verifySleeveUploadObj: ', verifySleeveUploadObj);

    await verifySleeveSerial(verifySleeveUploadObj);
  };

  const onModalClose = () => {
    setErrorGenBox(null);
    setErrorSleeve(null);
    setIsRef(false);
    setIsProductSerialVerified(false);
    setIsVisible(false);
    setPageRefresh(!pageRefresh);
  };

  const onConfirmShipment = async () => {
    try {
      setIsLoading(true);

      const payload = {
        ItemCartShipId: productData.ItemCartShipId,
        productId: productData.productId,
        productCode: productData.productCode,
        boxType: productData.boxType,
        sleeveSerial: productSerial,
      };

      console.log('payload: ', payload);

      const apiResponse = await axios.post(`${app.URL}/api/v1/admin/ecomorders/confirmshipment`, payload, {
        headers: getHeaders(),
      });

      console.log('apiResponse: ', apiResponse.data.result);
      setIsLoading(false);
      if (apiResponse.data.result.success) {
        sleeveSerialForm.resetFields();
        setPageRefresh(!pageRefresh);
        onModalClose();
      }
    } catch (error) {
      console.log('error: ', error);
      onModalClose();
      message.error(error.response.data.result.msg);
      setIsLoading(false);

      // genericBoxForm.resetFields();
      // sleeveSerialForm.resetFields();
      setPageRefresh(!pageRefresh);
    }
  };

  const onHandleProductSleeveChange = (event) => {
    console.log('event data: ', event.target.value);
    setProductSerial(event.target.value);
    if (event.target?.value?.length === 21) {
      onConfirmProductSerialTrigger(event.target.value);
    }
  };

  return (
    <Modal
      show={isVisible}
      size='lg'
      onHide={() => {
        setIsVisible(!isVisible);
      }}
      centered
    >
      <Modal.Header
        style={{
          display: 'block',
        }}
      >
        <Modal.Title style={{ display: 'block', padding: '9px 13px 8px 8px', fontSize: '20px' }}>
          <h1>Product Verification</h1>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ padding: '0px 20px !important' }}>
        <div className='container-fluid'>
          <div className='row mb-4'>
            <div className='col-md-12'>
              <table id='customers' style={{ width: '100%' }}>
                <thead>
                  <tr
                    style={{
                      fontWeight: 'bold',
                    }}
                  >
                    <th>Product Code</th>
                    <th>Product Name </th>
                    <th>Box Type</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{productData?.productCode}</td>
                    <td>{productData?.productName}</td>
                    <td>{productData?.boxType}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <Form
            form={sleeveSerialForm}
            initialValues={{
              ['productSerial']: null,
            }}
            className='my-2 mb-0 row '
          >
            <div className='col-lg-2 col-md-3 col-3 d-flex align-items-center justify-content-center'>
              <label>Product QR</label>
            </div>
            <div className='col-lg-3 col-md-6 col-6'>
              <Form.Item
                style={{
                  width: '183px',
                }}
                colon={false}
                name='productSerial'
                rules={[]}
                className='m-0'
              >
                <Input
                  ref={inputRef}
                  style={{
                    color: 'black',
                    height: '30px',
                  }}
                  placeholder='Scan QR code'
                  maxLength={21}
                  disabled={isProductSerialVerified ? true : false}
                  onChange={(event) => {
                    onHandleProductSleeveChange(event);
                  }}
                />
              </Form.Item>
            </div>
            <div className='col-md-3 col-3'>
              {isProductSerialVerified ? (
                <>
                  <CheckCircleOutlined
                    style={{
                      color: 'green',
                      // background:"lightgray",
                      fontSize: '30px',
                      marginRight: '20px',
                    }}
                  />

                  <Button
                    variant='primary'
                    htmlType='submit'
                    size='small'
                    style={{
                      // height: "30px",
                      textAlign: 'center',
                      fontSize: '15px',
                    }}
                    onClick={() => {
                      // setIsVisible(false);
                      setIsRef(true);
                      setIsSerialFailed(true);
                      setIsProductSerialVerified(false);
                      sleeveSerialForm.resetFields();
                    }}
                  >
                    Reverify
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    variant='primary'
                    htmlType='submit'
                    size='small'
                    style={{
                      // height: "30px",
                      textAlign: 'center',
                      fontSize: '15px',
                    }}
                    onClick={() => {
                      // setIsVisible(false);
                      onConfirmProductSerialTrigger();
                    }}
                  >
                    Verify
                  </Button>
                </>
              )}
            </div>

            {errorSleeve && (
              <div className='row mb-2'>
                <div className='col-lg-10 offset-lg-2 offset-3'>
                  <small
                    style={{
                      color: 'crimson',
                      fontWeight: '500',
                      fontSize: '0.8rem',
                    }}
                  >
                    {errorSleeve}
                  </small>
                </div>
              </div>
            )}
          </Form>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Form.Item>
          <Button
            variant='primary'
            htmlType='submit'
            onClick={() => {
              sleeveSerialForm.resetFields();
              onModalClose();
            }}
          >
            Close
          </Button>
        </Form.Item>
        {isProductSerialVerified && (
          <Form.Item>
            <Button
              variant='primary'
              htmlType='submit'
              onClick={() => {
                onConfirmShipment();
              }}
            >
              {isLoading ? <LoadingOutlined /> : 'Confirm'}
            </Button>
          </Form.Item>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default ShipmentOrderVerificationModal;
