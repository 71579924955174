import React from 'react'
import Modal from 'react-bootstrap/Modal';

const ViewOrderModal = ({isVisible,setIsVisible, requestObj }) => {
  console.log("RequestObj", requestObj)
  const existingProducts = requestObj?.data?.products?.filter((product) => product.isExistingProduct);
  const newProducts = requestObj?.data?.products?.filter((product) => !product.isExistingProduct);
  return (
    <Modal
      show={isVisible}
      size='lg'
      onHide={() => {
        setIsVisible(!isVisible);
      }}
      centered
    >
      <Modal.Header
        style={{
          display: 'block',
        }}
      >
        <Modal.Title style={{ display: 'block', padding: '9px 13px 8px 8px', fontSize: '20px' }}>
          <div className='row'>
            <div className='col-lg-6'>{requestObj?.reqNo}</div>
            <div className='col-lg-6 text-right'>{requestObj?.createdAt}</div>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ padding: '0px 20px !important' }}>
        <div className='row'>
          {/* <div className='row'>
            <div className='col-lg-6 fs-5 mt-0 mb-0 pt-0 pb-0 pl-3'>{item.addressTitle}</div>
            <div className='col-lg-6 mt-0 mb-0 pt-2 pb-0 pl-3 text-right'>{item.requestStatus}</div>
          </div> */}
          {/* <div className='row'>
            <div className='col-lg-6  mt-0 mb-0 pt-0 pb-0 pl-3'>{item.address}</div>
            
          </div> */}
          <div className='row'>
            {/* <div className='col-lg-6  mt-0 mb-0 pt-0 pb-0 pl-3'>{item.shipmentPackage}</div> */}
            {requestObj?.requestId && <div className='col-lg-12  mt-0 mb-0 pt-0 pb-0 pl-2 '>Request Type: {requestObj?.requestType}</div>}
            {requestObj?.requestId && <div className='col-lg-12  mt-0 mb-0 pt-0 pb-0 pl-2 '>Request Status: {requestObj?.requestStatus}</div>}
          </div>

          {requestObj.reqTypeId === 2 && (
            <div className='mb-4'>
              <table id='customers' className='w-100'>
                <thead>
                  <tr
                    style={{
                      fontWeight: 'bold',
                    }}
                  >
                   
                    <th>Product Name </th>
                    <th>Quantity</th>
                    <th> Product Status </th>
                  </tr>
                </thead>
                <tbody>
                  {requestObj?.data?.products?.map((product, index) => {
                    return (
                      <tr
                        style={{ cursor: 'pointer' }}
                        // onClick={() => {
                        //   setAddressId(item.addressId);
                        //   console.log('Row clicked');
                        //   setForceUpdate(!forceUpdate);
                        // }}
                      >
                       
                        <td style={{ width: '100px' }}>{product.productName}</td>
                        <td style={{ width: '100px' }}>{Number(product.quantity)}</td>
                        <td style={{ width: '100px' }}>{product.isAccepted ? 'Accepted' : product.isRejected ? 'Rejected' : 'Pending'}</td>

                        {/* <td style={{ width: '100px' }}>${(Number(product.quantity).toFixed(2) * Number(product.unitPrice)?.toFixed(2)).toFixed(2)}</td> */}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}
          {/* <div className='row'>{requestObj?.requestId && <div className='col-lg-12  mt-0 mb-0 pt-0 pb-0 pl-0 text-right'>Request Status: {requestObj.requestStatus}</div>}</div> */}
          {/* <div className='col-lg-6 fs-5 mt-0 mb-0 pt-0 pb-0 pl-3'>{title}</div>
                    <div className='col-lg-6 mt-0 mb-0 pt-2 pb-0 pl-3 text-right'>{status}</div>
                    <div className='col-lg-6  mt-0 mb-0 pt-0 pb-0 pl-3'>{address}</div>
                    <div className='col-lg-6  mt-0 mb-0 pt-0 pb-0 pl-0 text-right'>Warehouse Status: {wareHouseStatus}</div>
                    <div className='col-lg-6  mt-0 mb-0 pt-0 pb-0 pl-3'>{shipmePackageName}</div>

                    {
                        requestId && (
                            <>
                                <div className='col-lg-6  mt-0 mb-0 pt-0 pb-0 pl-0 text-right'>Request Type: {requestType}</div>
                                <div className='col-lg-12  mt-0 mb-0 pt-0 pb-0 pl-0 text-right'>Request Status: {requestStatus}</div>
                            </>
                        )
                    } */}
          {requestObj.reqTypeId === 3 && (
            <div className='mb-4'>
              {requestObj?.data?.products && (
                <>
                  {existingProducts.length > 0 && (
                    <>
                      {/* <h3 className='fs-5 mt-4'>Products Received</h3> */}
                      <table id='customers' className='w-100'>
                        <thead>
                          <tr style={{ fontWeight: 'bold' }}>
                            <th>Product Name</th>
                            <th>Order No.</th>
                            <th>Quantity</th>
                            <th>Order Generated</th>
                          </tr>
                        </thead>
                        <tbody>
                          {existingProducts.map((product, index) => (
                            <tr key={index} style={{ cursor: 'pointer' }}>
                              <td style={{ width: '100px' }}>{product.productName}</td>
                              <td style={{ width: '100px' }}>{product?.newEcomOrderDetails?.ecomOrderNo}</td>
                              <td style={{ width: '100px' }}>{Number(product.quantity)}</td>
                              <td style={{ width: '100px' }}>{product.isOrderGenerated ? 'Generated' : 'Not Generated'}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </>
                  )}
                  {/* Conditionally render the table for New Products if there are any */}
                  {newProducts.length > 0 && (
                    <>
                      <h3 className='fs-5 mt-4'> Extra products</h3>

                      <table id='customers' className='w-100'>
                        <thead>
                          <tr style={{ fontWeight: 'bold' }}>
                            <th>Product Name</th>
                            <th>Order No.</th>
                            <th>Quantity</th>
                            <th>Order Generated</th>
                          </tr>
                        </thead>
                        <tbody>
                          {newProducts.map((product, index) => (
                            <tr key={index} style={{ cursor: 'pointer' }}>
                              <td style={{ width: '100px' }}>{product.productName}</td>
                              <td style={{ width: '100px' }}>{product?.newEcomOrderDetails?.ecomOrderNo}</td>
                              <td style={{ width: '100px' }}>{Number(product.quantity)}</td>
                              <td style={{ width: '100px' }}>{product.isOrderGenerated ? 'Generated' : 'Not Generated'}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </>
                  )}
                </>
              )}
            </div>
          )}
          <div className='row'>
            {requestObj?.reqTypeId !== 4 && requestObj?.images?.length > 0 && (
              <div className='col-12 p-3  mb-3'>
                <h4 className='fw-bold mb-2'>Images</h4>
                <div className='d-flex gap-2'>
                  {requestObj?.images?.map((item, index) => (
                    <div>
                      <img src={item} alt={`image-${index}`} key={index} style={{ width: '100px', height: '100px' }} />
                    </div>
                  ))}
                </div>
              </div>
            )}

            {requestObj?.questions?.length > 0 && (
              <div className='col-12  mt-2'>
                <h4 className='fw-bold mb-1'>Reasons</h4>
                <ul>
                  {requestObj?.questions?.map((item) => (
                    <>
                      <span> {item.question} </span>
                      <li> {item.options ? item.options[0] : item.option ? item.option : item.answer} </li>
                    </>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </div>
       

        {/* { locationURL !==result ? <div className='row'>
                    <div className='col-lg-12  mt-0 mb-0 pt-0 pb-0 pl-3 text-right'>Total Amount: ${totalShipmentAmount?.toFixed(2)}</div>
                    <div className='col-lg-12  mt-0 mb-0 pt-0 pb-0 pl-3 text-right'>Total Tax: ${tax?.toFixed(2)}</div>
                    <div className='col-lg-12  mt-0 mb-0 pt-0 pb-0 pl-3 text-right'>Shipment Amount: { Number(shipmentCharges) <=0 ? "Free" :  `$${Number(shipmentCharges)?.toFixed(2)}`} </div>
                    <div className='col-lg-12  mt-0 mb-0 pt-0 pb-0 pl-3 text-right'>Grand Total: ${(grandTotal + tax).toFixed(2)}</div>
                </div>:" " } */}
      </Modal.Body>
      <Modal.Footer>
        <button
          variant='primary'
          className='btn btn-primary'
          style={{
            textAlign: 'left',
          }}
          onClick={() => {
            setIsVisible(!isVisible);
          }}
        >
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ViewOrderModal



