import axios from 'axios';
import { useEffect, useState } from 'react';
import {app} from '../config'
import getHeaders from '../utils/authHeaders';
const fetchCurrentAccount = (email) => {
    const [loading, setLoading] = useState(true);
    const [response, setResponse] = useState(null);
    const [error, setError] = useState(null);
    useEffect(() => {
        const getCurrentAcccount = async () => {
          try {
            
            const res = await axios.get(`${app.URL}/api/v1/admin/adminAccount/currentAccount?email=${email}`, { headers: getHeaders() });
            localStorage.removeItem("user")
            // console.log(res)
            setResponse(res.data.result);
            localStorage.setItem("user", JSON.stringify(res.data))
            setLoading(false);
          } catch (err) {
            setError(err);
            setLoading(false);
          }
        };
        getCurrentAcccount();
      }, []);
    
      return { loading, response, error };
    };
export default fetchCurrentAccount;