import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faCircleUser } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { DownOutlined } from '@ant-design/icons';
import { Dropdown, Space } from 'antd';
import { useState } from 'react';
import { logOutHandler } from '../../hooks/logoutUser';
export const Navbar = () => {
  const { t } = useTranslation(['common']);
  const [isAdmin, setisAdmin] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loginuser = useSelector((state) => state.admin);
  const user = JSON.parse(localStorage.getItem('user'));
  localStorage.setItem('userId', JSON.stringify(user?.userId));
  // const logOutHandler = () => {
  //   console.log('logout called');
  //   let items = ['editProductId', 'userId', 'user', 'subOrderId', 'orderId'];
  //   for (let keys in items) {
  //     console.log('for in called');
  //     console.log(keys);
  //     localStorage.removeItem(items[keys]);
  //   }
  //   // isAdmin?navigate('/adminlogin'):navigate('/');
  //   // window.location.reload();
  //   navigate('/admin/login');
  //   window.location.reload();
  // };

  //* Drop Down
  const items = [
    {
      label: (
        <NavLink rel='noopener noreferrer' to={`/profile/edit`}>
          Profile
        </NavLink>
      ),
      key: '0',
    },
    {
      label: (
        <a target='_blank' onClick={() => logOutHandler(navigate)} rel='noopener noreferrer'>
          Sign Out
        </a>
      ),
      key: '1',
    },
    // {
    //   label: (
    //     <a target='_blank' rel='noopener noreferrer' href='https://www.aliyun.com'>
    //      Edit Profile
    //     </a>
    //   ),
    //   key: '1',
    // },
    // {
    //   type: 'divider',
    // },
    // {
    //   label: '3rd menu item（disabled）',
    //   key: '3',
    //   disabled: true,
    // },
  ];
  //* Dropdown
  return (
    <nav className='color4' style={{ paddingTop: '20px' }}>
      <div className='d-flex'>
        <div style={{ width: '100%', textAlign: '-webkit-right' }} class='mr-auto'>
          <img src='/logo.png' alt='Logo Image' />
        </div>
        {/* <div class=' p-2 mt-3 mr-3 fw-bold text-white'>
          {t('Hi')}! {user ? user.name : ' '}{' '}
        </div> */}
        {/* <LanguageChanger class=" p-2 mt-2 mr-3"  /> */}
        <Dropdown
          className='mt-3 mr-3 text-white '
          menu={{
            items,
          }}
        >
          <a onClick={(e) => e.preventDefault()}>
            <Space>
              {/* <FontAwesomeIcon   className='mr-2 text-white fa-2xl' icon={faCircleUser} /> */}
              <DownOutlined style={{ width: '40px' }} />
            </Space>
          </a>
        </Dropdown>
      </div>
    </nav>
  );
};
export default Navbar;
