import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { app } from '../../config';
// import CalenderImg from '../../assets/images/calender.png';
import { useNavigate } from 'react-router-dom';
import { Pagination, Input, Form, Select, Row, Col, Space, Spin, message, Collapse, DatePicker } from 'antd';
//Collapse
import { CaretRightOutlined, CaretDownOutlined, SettingOutlined, LoadingOutlined } from '@ant-design/icons';
import moment from 'moment';
//Collapse End
// import { useTranslation } from 'react-i18next';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
// import { EditTwoTone, BranchesOutlined } from '@ant-design/icons';
import getHeaders from '../../utils/authHeaders';
// import ListinngCard from '../../layouts/ListingCard';
import { dateConverter } from '../../utils/utcDateConversionCommon';

// import EcommerceDashboardCard from '../../layouts/EcommerceDashboardCard';
import NoDataFound from '../../layouts/NoDataFound';
import SubOrderViewModal from './SubOrderViewModal';
import MainOrderViewModal from './MainOrderViewModal';
import EcommerceCards from './EcommerceCards';
import EcomDashboardHeader from './EcomDashboardHeader';
import { SearchBar } from '../../layouts/SearchBar';
import LoaderImage from '../LoaderImage';

const InTransit = () => {
  // const { RangePicker } = DatePicker;
  const [activeIndex, setActiveIndex] = useState(1);
  const [cancelShow, setCancelShow] = useState(false);
  const [editbtn, setEditBtn] = useState('none');
  const [pageSize, setPageSize] = useState(10);
  const [fetchPartners, setFetchPartners] = useState([]);
  const [subOrderObj, setSubOrderObj] = useState({});
  const [pagesCount, setPagesCount] = useState(0);
  const [searchShow, setSearchShow] = useState({});
  const [renderList, setRenderList] = useState([]);
  const [tableShow, setTableShow] = useState(true);
  const [isCustomerRequest, setIsCustomerRequest] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [err, setErr] = useState('');
  const [tableHeaders, setTableHeaders] = useState([]);
  // const [show, setShow] = useState(false);
  // const [lockModalShow, setLockModalShow] = useState(false);
  // const [delBatchResp, setDelBatchResp] = useState({});
  // const [lockBatchResp, setLockBatchResp] = useState({});
  const [status, setStatus] = useState([]);
  // const [dashboardCardsCounts, setDashboardCardsCounts] = useState({
  //   totalInProgress: 0,
  //   totalInTransit: 0,
  //   totalDelivered: 0,
  //   totalCancelled: 0,
  //   totalReturnClaim: 0,
  // });
  const [cancelData, setCancelData] = useState({});
  const [orderCancelResp, setOrderCancelResp] = useState();
  const [cancelStatus, setCancelStatus] = useState(false);

  const [reciveBackData, setReciveBackData] = useState({});
  const [reciveBackResp, setReciveBackResp] = useState();
  const [reciveBackStatus, setReciveBackStatus] = useState(false);
  const [reciveBackShow, setReciveBackShow] = useState(false);
  // const [isRecivedBack, setIsReciveBack] = useState(false);

  const [resendOrderData, setResendOrderData] = useState({});
  const [resendOrderResp, setResendOrderResp] = useState();
  const [resendOrderStatus, setResendOrderStatus] = useState(false);
  const [resendOrderShow, setResendOrderShow] = useState(false);

  const [missingItem, setMissingItem] = useState(false);

  const [refreshPage, setRefreshPage] = useState(false);
  const [singleOrderOBj, setSingleOrderObj] = useState({});
  const [showSingleOrder, setShowSingleOrder] = useState(false);

  const [singleMainOrderOBj, setSingleMainOrderObj] = useState({});
  const [loader, setLoader] = useState(false);
  const [dLoader, setDLoader] = useState(false);

  const [stockOutResp, setStockOutResp] = useState([]);
  const [orderIndx, setOrderIndx] = useState('');

  const [deliverData, setDeliverData] = useState({});
  const [orderDeliverResp, setOrderDeliverResp] = useState();
  const [deliverStatus, setDeliverStatus] = useState(false);
  const [deliverShow, setDeliverShow] = useState(false);
  // const handleClose = () => setShow(false);
  // const handleLockClose = () => setLockModalShow(false);

  let navigate = useNavigate();
  let convertedObj;

  let ecomHaaders = ['Order No.', 'Order Date', 'Customer', 'Action'];
  // let inProgressHeaders = ['Order No.', 'Order Date', 'Customer', 'Action'];
  // let deliveredHeaders = ['Order No.', 'Order Date', 'Customer', 'Action'];
  // let customerRequestHeaders = ['Order No.', 'Order Date', 'Customer', 'Action'];
  // let cancelledHeaders = ['Order No.', 'Order Date', 'Customer', 'Action'];

  let headersMap = new Map();
  headersMap.set(1, ecomHaaders);
  headersMap.set(2, ecomHaaders);
  headersMap.set(3, ecomHaaders);
  headersMap.set(4, ecomHaaders);
  headersMap.set(5, ecomHaaders);

  // {{base_url}}/api/v1/admin/ecomorders/orderscount
  // let statusArr = [];

  useEffect(() => {
    const getState = async () => {
      const statusResp = await axios.get(`${app.URL}/api/v1/admin/ecomorders/orderscount`, { headers: getHeaders() });
      statusResp.data ? console.log(statusResp.data.result.data) : console.log('data');
      statusResp.status == 200 ? setStatus(statusResp.data.result.data) : ' ';
      console.log(status);
    };
    getState();
  }, [refreshPage]);
  console.log(status);

  const user = JSON.parse(localStorage.getItem('user'));
  if (user.userType === 'A') setEditBtn('block');
  const userRequestListingAPI = async (formData) => {
    console.log(formData);
    const partnerResp = await axios.post(`${app.URL}/api/v1/admin/userrequest/view`, formData, { headers: getHeaders() });
    // console.log(partnerResp)
    partnerResp ? console.log(partnerResp.data.result.data) : '';
    partnerResp ? (convertedObj = dateConverter(partnerResp.data.result.data.data, 'createdAt')) : ' ';
    partnerResp.data.result ? setFetchPartners(convertedObj) : ' ';
    partnerResp.data.result ? setPagesCount(partnerResp.data.result.data.count) : ' ';
    partnerResp.data.result ? setRenderList(convertedObj) : ' ';
  };

  const getTransitData = async (formData) => {
    console.log(formData);
    // setActiveIndex(1);
    setLoader(true);
    setSearchShow(formData);
    

    const partnerResp = await axios.post(`${app.URL}/api/v1/admin/ecomorders/vieworders/3`, formData, { headers: getHeaders() });
    // console.log(partnerResp)
    partnerResp ? console.log(partnerResp.data.result.data) : '';
    partnerResp ? (convertedObj = dateConverter(partnerResp.data.result.data.data, 'createdAt')) : ' ';
    partnerResp.data.result ? setFetchPartners(convertedObj) : ' ';
    partnerResp.data.result ? setPagesCount(partnerResp.data.result.data.count) : ' ';
    partnerResp.data.result ? setRenderList(convertedObj) : ' ';
    partnerResp.data.result && setLoader(false);
    partnerResp.data.result && setCurrentPage(1);

    // setDashboardCardsCounts((prevCounts) => ({
    //   ...prevCounts,
    //   totalInProgress: partnerResp.data.result.data.count,
    //   totalInTransit: partnerResp.data.result.data.count,
    // }));
  };

  let formData = {
    limit: pageSize,
    offset: 0,
    searchBy: '',
    searchTxt: '',
  };
  const getData = async () => {
    console.log(formData);
    const partnerResp = await axios.post(`${app.URL}/api/v1/admin/userrequest/view`, formData, { headers: getHeaders() });
    console.log(partnerResp);
    partnerResp ? console.log(partnerResp.data.result.data) : ' ';
    partnerResp.data.result ? (convertedObj = await dateConverter(partnerResp.data.result.data.data, 'createdAt')) : ' ';
    partnerResp.data.result ? setFetchPartners(convertedObj) : ' ';
    partnerResp.data.result ? setPagesCount(partnerResp.data.result.data.count) : ' ';
    partnerResp.data.result ? setRenderList(convertedObj) : ' ';
    return console.log('return called');
  };
  const onRequestChange = async (page, pageSize) => {
    console.log(page, pageSize);
    setPageSize(pageSize);
    setCurrentPage(page);
    if (!page) page = 1;
    let limit = pageSize;
    try {
      let reqObj;
      if (Object.keys(searchShow).length !== 0) {
        reqObj = {
          limit: limit,
          offset: limit * (page - 1),
          searchBy: searchShow.searchFor,
          searchTxt: searchShow.searchItem,
        };
      } else {
        reqObj = {
          limit: limit,
          offset: limit * (page - 1),
          searchBy: 'orderNo.',
          searchTxt: '',
        };
      }
      console.log(reqObj);
      await userRequestListingAPI(reqObj);
    } catch (e) {
      console.log('Error');
      if (e.message) setErr(e.message);
      setTableShow(false);
    }
  };
  const onChange = async (page, pageSize) => {
    console.log(page, pageSize);
    setPageSize(pageSize);
    setCurrentPage(page);
    if (!page) page = 1;
    let limit = pageSize;
    try {
      let reqObj;
      if (Object.keys(searchShow).length !== 0) {
        reqObj = {
          limit: limit,
          offset: limit * (page - 1),
          searchBy: searchShow.searchBy,
          searchTxt: searchShow.searchTxt,
        };
      } else {
        reqObj = {
          limit: limit,
          offset: limit * (page - 1),
          searchBy: 'orderNo.',
          searchTxt: '',
        };
      }
      console.log(reqObj);
      await getTransitData(reqObj);
    } catch (e) {
      console.log('Error');
      if (e.message) setErr(e.message);
      setTableShow(false);
    }
  };

  //Approve and Refund Workflow
  const handleCancelModalClose = () => setCancelShow(false);
  const cancelShipment = async (data) => {
    try {
      let resp = await axios.post(`${app.URL}/api/v1/admin/userrequest/cancelshipment`, data, { headers: getHeaders() });
      console.log('resp');
      setOrderCancelResp(resp.data.result);
      setCancelStatus(true);
      setTimeout(() => {
        setCancelShow(false);
      }, 500);
      await getData();
    } catch (e) {
      throw e;
    }
  };
  // const onCancelTrigger = (data) => {
  //   setOrderCancelResp({});
  //   console.log(data);
  //   let cancelObj = {
  //     requestId: data.requestId,
  //     patientId: data.patientId,
  //     ecomOrderId: data.ecomOrderId,
  //     addressId: data.addressId,
  //   };

  //   console.log(cancelObj);
  //   setCancelShow(true);
  //   setCancelData(cancelObj);
  // };
  const onCancelClick = async () => {
    console.log(cancelData);
    await cancelShipment(cancelData);
  };
  // Recive back order work flow

  const handleReciveBackModalClose = () => setReciveBackShow(false);
  const reciveorder = async (data) => {
    try {
      let resp = await axios.post(`${app.URL}/api/v1/admin/ecomorders/receiveback`, data, { headers: getHeaders() });
      console.log('resp');
      setReciveBackResp(resp.data.result);
      setReciveBackStatus(true);
      setTimeout(() => {
        setReciveBackShow(false);
      }, 500);
      await getData();
    } catch (e) {
      throw e;
    }
  };
  // const onReciveBackTrigger = (data) => {
  //   setReciveBackResp({});
  //   console.log(data);
  //   let recivebackObj = {
  //     requestId: data.requestId,
  //     patientId: data.patientId,
  //     ecomOrderId: data.ecomOrderId,
  //     addressId: data.addressId,
  //   };

  //   console.log(recivebackObj);
  //   setReciveBackShow(true);
  //   setReciveBackData(recivebackObj);
  // };
  const onReciveBackClick = async () => {
    console.log(reciveBackData);
    await reciveorder(reciveBackData);
  };

  //Recive back order workflow end

  //Resend Order Workflow

  const handleResendOrderModal = () => setResendOrderShow(false);
  const resendOrder = async (data) => {
    try {
      let resp = await axios.post(`${app.URL}/api/v1/admin/ecomorders/resendorder`, data, { headers: getHeaders() });
      console.log('resp');
      setResendOrderResp(resp.data.result);
      setResendOrderStatus(true);
      setTimeout(() => {
        setResendOrderShow(false);
      }, 500);
      await getData();
    } catch (e) {
      throw e;
    }
  };
  // const onResendOrderTrigger = (data) => {
  //   setResendOrderResp({});
  //   console.log(data);
  //   if (data.requestType == 'Missing Item' || data.requestType == 'missing item') {
  //     setMissingItem(true);
  //   }
  //   let resendOrderObj = {
  //     requestId: data.requestId,
  //     patientId: data.patientId,
  //     ecomOrderId: data.ecomOrderId,
  //     addressId: data.addressId,
  //   };

  //   console.log(resendOrderObj);
  //   setResendOrderShow(true);
  //   setResendOrderData(resendOrderObj);
  // };
  const onResendOrderonClick = async () => {
    console.log(resendOrderData);
    await resendOrder(resendOrderData);
  };

  //Resend Order Work flow End
  console.log(renderList);
  useEffect(() => {
    setTableHeaders(headersMap.get(1));
    let formData = {
      limit: pageSize,
      offset: 0,
      searchBy: '',
      searchTxt: '',
    };
    console.log(formData);
    getTransitData(formData);
  }, [refreshPage]);

  const { Panel } = Collapse;
  const customExpandIcon = (panelProps) => {
    const { isActive } = panelProps;

    return <span>{isActive ? <CaretDownOutlined /> : <CaretRightOutlined />}</span>;
  };
  

  const onCollapseChange = async (key) => {
    console.log('Collapse change:', key);
    console.log(key);
    
  };
  console.log(subOrderObj);

  const onSearchFinish = async (values) => {
    console.log('onSearchFinish');
    console.log('Success:', values);
    let formData = {
      limit: 10,
      offset: 0,
      searchBy: values.searchBy, //partnerName, serialNo, orderNo
      searchTxt: values.searchTxt,
    };

    if (!formData.searchTxt) message.error('Please enter search text');
    else await getTransitData(formData);
  };

  const onSearchFinishFailed = (values) => {
    console.log('Failed:', values);
  };
  const onCardViewClick = (data) => {
    if (data == 1) {
      setActiveIndex(data);
      navigate('/ecom/dashboard/inprogress', { replace: true });
    } else if (data == 2) {
      setActiveIndex(data);
      navigate('/ecom/dashboard/intransit', { replace: true });
    } else if (data == 3) {
      setActiveIndex(data);
      navigate('/ecom/dashboard/delivered', { replace: true });
    } else if (data == 4) {
      setActiveIndex(data);
      navigate('/ecom/dashboard/request', { replace: true });
    } else if (data == 5) {
      setActiveIndex(data);
      navigate('/ecom/dashboard/cancel', { replace: true });
    }
  };

  // const onCardClick = async (index) => {
  //   setActiveIndex(index);
  //   // console.log("active Index ", index)
  //   setTableHeaders(headersMap.get(index));
  //   let formData = {
  //     limit: pageSize,
  //     offset: 0,
  //     searchBy: 'orderNo.',
  //     searchTxt: '',
  //   };

  //   console.log('index before: ', index);

  //   index == 5 ? await getTransitData(formData, 5) : await getTransitData(formData, index + 1);

  //   console.log('index after: ', index);
  // };
  const deliverShipment = async (data) => {
    console.log(data)

     let userId = user?.result.data.user.userId;
     data.userId = userId;
    setDLoader(true);
    try {
      let resp = await axios.post(`${app.URL}/api/v1/admin/ecomorders/deliverorder`, data, { headers: getHeaders() });
      console.log('resp');
      setOrderDeliverResp(resp.data.result);
      setDeliverStatus(true);
      setDLoader(false);
      setTimeout(() => {
        setOrderDeliverResp('');
        setDeliverShow(false);
        setRefreshPage(!refreshPage);
        setSubOrderObj({})
      }, 500);
    } catch (e) {
      setDLoader(false);
    }
  };
  const handleDeliverModalClose = () => setDeliverShow(false);
  const onDeliverTrigger = async (data) => {
    setStockOutResp({});
    console.log(data);
    let deliverObj = {
      requestId: data.requestId,
      patientId: data.products[0].patientId,
      ecomOrderId: data.products[0].eCommOrderId,
      addressId: data.addressId,
    };
    console.log(deliverObj);
    setDeliverShow(true);
    
    setDeliverData(deliverObj);
  };
  const onDeliverClick = async () => {
    console.log(deliverData);
    await deliverShipment(deliverData);
  };
  // const onRequestClick = async (index) => {
  //   setActiveIndex(index);
  //   setTableHeaders(headersMap.get(index));
  //   await getData();
  // };
  const [showSingleMainOrder, setShowSingleMainOrder] = useState(false);
  const [isModalLoading, setIsModalLoading] = useState(false);

  // const getMainOrderData = async (id) => {
  //   try {
  //     let resp = await axios.get(`${app.URL}/api/v1/admin/ecomorders/view/${id}`, { headers: getHeaders() });
  //     setSingleMainOrderObj(resp.data.result.data);
  //     setIsModalLoading(!isModalLoading);
  //   } catch (error) {
  //     console.log('Error: ', error);
  //   }
  // };

  const [mainOrderObj, setMainOrderObj] = useState({});

  const mainOrderModal = (order) => {
    console.log('Main Order Modal Running');
    const getData = async () => {
      let resp = await axios.get(`${app.URL}/api/v1/admin/ecomorders/view/${order.ecomOrderId}`, { headers: getHeaders() });
      setMainOrderObj(resp.data.result.data);
      setSingleMainOrderObj(resp.data.result.data);
      openMainOrderModal();
    };

    getData();
  };

  const openMainOrderModal = () => {
    setShowSingleMainOrder(true);
  };

  const handleOnChange = (e) => {
    if (e.target.value.length === 0) {
      setRefreshPage(!refreshPage);
      setSearchShow({});
    }
  };

  const genExtra = (order) => (
    <table className='w-100'>
      <tbody>
        <tr>
          <td
            className='text-center'
            style={{
              width: '25%',
            }}
          >
            {order.ecomOrderNo}
          </td>
          <td
            className='text-center'
            style={{
              width: '25%',
            }}
          >
            {order.createdAt}
          </td>
          <td
            className='text-center'
            style={{
              width: '25%',
            }}
          >
            {order.patientName}
          </td>
          <td
            className='text-center'
            style={{
              width: '25%',
            }}
          >
            <Button
              onClick={() => {
                // getMainOrderData(order.ecomOrderId);
                mainOrderModal(order);
              }}
              style={{ width: '80px', height: '34px', fontSize: 'small' }}
              size='small'
            >
              View
            </Button>
          </td>
        </tr>
      </tbody>
    </table>
  );
  useEffect(() => {
    let resp;
    Object.keys(subOrderObj).length > 0 ? (resp = subOrderObj.address.filter((ele) => ele.requestId)) : '';
    console.log(resp);
    resp && resp.length > 0 ? setIsCustomerRequest(true) : setIsCustomerRequest(false);
  }, [subOrderObj]);

  console.log(isCustomerRequest);

  let searchItems = [
    {
      name: 'Order No.',
      value: 'orderNo',
    },
    {
      name: 'Customer Name',
      value: 'patientName',
    },
  ];

  return (
    <div className='dashboard-card container-fluid'>
      <EcomDashboardHeader onChange={onChange} />
      <EcommerceCards status={status} onCardViewClick={onCardViewClick} />
      <SearchBar onFinish={onSearchFinish} onFinishFailed={onSearchFinishFailed} searchItems={searchItems} onChange={handleOnChange} />

      {/* orders table to copy*/}

      {loader && <LoaderImage />}
      {renderList.length > 0 && !loader ? (
        <div className='table-scrollbar'>
          <table
            id='customers'
            className='w-100'
            // style={{
            //   padding: '5px 0px',
            // }}
          >
            <thead>
              <tr>
                {['Order No.', 'Order Date', 'Customer', 'Action'].map((item) => (
                  <th key={item} style={{ fontWeight: 'bold', width: '25%' }}>
                    {item}
                  </th>
                ))}
              </tr>

              {/* {tableHeaders.map(header => (
                                        <th style={{ width: "150px", textAlign: "left", paddingLeft: "90px", fontWeight: "bold" }}>{header}</th>
                                    ))} */}
            </thead>
          </table>
          <div>
            <Collapse accordion obClexpandIcon={customExpandIcon} onChange={onCollapseChange}>
              {renderList.map((order, index) => (
                <Panel
                  onClick={(e) => {
                    e.stopPropagation();
                    setSubOrderObj(order);
                  }}
                  header={genExtra(order)}
                  key={order.ecomOrderId}
                  className='table-scrollbar '
                >
                  {/* //Single Order Object */}
                  {Object.keys(subOrderObj).length > 0 ? (
                    <>
                      {/* chat gpt solution here  start */}

                      <div className='table-scrollbar'>
                        <table id='customers' className='w-100'>
                          <thead class=''>
                            <tr
                              style={{
                                fontWeight: 'bold',
                              }}
                            >
                              <th>Sub Order No.</th>
                              <th>Address</th>
                              <th>Total</th>
                              <th>Grand Total</th>
                              {isCustomerRequest ? <th>customer Request</th> : ''}
                              <th>Action</th>
                            </tr>
                          </thead>

                          {subOrderObj.address.map((ele) => (
                            <tbody key={ele.addressId}>
                              <tr>
                                <td style={{ width: '170px' }}>
                                  <a>
                                    {order.ecomOrderNo}-{ele.addressId}
                                  </a>
                                </td>
                                <td style={{ width: '250px' }}>{ele.address}</td>
                                <td style={{ width: '150px' }}>${ele.totalShipmentAmount.toFixed(2)}</td>
                                <td style={{ width: '180px' }}>${(ele.totalAmountwithShipment + ele.taxAmount)?.toFixed(2)}</td>
                                {isCustomerRequest ? <td style={{ width: '150px' }}>{ele.requestId ? ele.requestType : 'None'}</td> : ''}
                                {!ele.requestId || ele.requestStatus === 'Completed' ? (
                                  <td style={{ display: 'flex', justifyContent: 'center' }}>
                                    <Button
                                      onClick={() => {
                                        onDeliverTrigger(ele);
                                      }}
                                      style={{ width: '75px', height: '34px', fontSize: 'small', marginRight: '10px' }}
                                      size='medium'
                                    >
                                      Deliver
                                    </Button>

                                    <Button
                                      onClick={() => {
                                        setSingleOrderObj(ele);
                                        setShowSingleOrder(true);
                                      }}
                                      style={{ width: '70px', height: '34px', fontSize: 'small', marginRight: '0px' }}
                                      size='medium'
                                    >
                                      View
                                    </Button>
                                  </td>
                                ) : null}
                              </tr>
                            </tbody>
                          ))}
                        </table>
                      </div>

                      {/* chat gpt solution here end */}
                    </>
                  ) : (
                    <div className='row text-center'>
                      <span>
                        {' '}
                        <Space size='middle'>
                          <Spin size='large' />
                        </Space>
                      </span>
                    </div>
                  )}
                </Panel>
              ))}
            </Collapse>
          </div>
        </div>
      ) : (
        <>{!loader && <NoDataFound />}</>
      )}

      {currentPage && pagesCount ? <Pagination style={{ marginTop: '10px' }} onChange={activeIndex == 4 ? onRequestChange : onChange} defaultCurrent={currentPage} total={pagesCount} showTotal={(total) => `Total ${total} items`} showSizeChanger /> : ' '}

      <MainOrderViewModal
        isVisible={showSingleMainOrder}
        setIsVisible={setShowSingleMainOrder}
        ecomOrderNo={singleMainOrderOBj.ecomOrderNo}
        ecomOrderCreatedData={moment(singleMainOrderOBj.createdAt).format('MM-DD-YYYY')}
        // patientName={"singleMainOrderOBj.patientName"}
        productTotalAmount={singleMainOrderOBj.totalProductAmount}
        shipmentTotal={singleMainOrderOBj.totalShipmentCharges}
        subOrdersData={singleMainOrderOBj?.orderDetails}
        isLoading={isModalLoading}
        setIsLoading={setIsModalLoading}
        mainOrderShipmentAmount={singleMainOrderOBj.totalShipmentCharges}
        mainOrderGrandTotal={singleMainOrderOBj.grandTotalAmount}
        totalProductAmount={singleMainOrderOBj.totalProductAmount}
        ecomOrderId={singleMainOrderOBj.ecomOrderId}
        isApiCallAble={false}
      />

      {/* single sub order view modal start */}

      <SubOrderViewModal
        isVisible={showSingleOrder}
        ecomOrderNo={`${subOrderObj.ecomOrderNo}-${singleOrderOBj.addressId}`}
        setIsVisible={setShowSingleOrder}
        address={`${singleOrderOBj.address}, ${singleOrderOBj.state}, ${singleOrderOBj.city}, ${singleOrderOBj.zipCode}`}
        ecomOrderCreatedDate={subOrderObj.createdAt}
        productsData={singleOrderOBj?.products}
        status={singleOrderOBj.status}
        title={singleOrderOBj.title}
        shipmentCharges={singleOrderOBj.shipmentCharges}
        grandTotal={singleOrderOBj.totalAmountwithShipment}
        totalShipmentAmount={singleOrderOBj.totalShipmentAmount}
        tax={singleOrderOBj.taxAmount}
        requestId={singleOrderOBj.requestId}
        requestStatus={singleOrderOBj.requestStatus}
        requestType={singleOrderOBj.requestStatus}
        shipmePackageName={singleOrderOBj.shipmePackageName}
        locationURL={window.location.pathname}
        wareHouseStatus={singleOrderOBj.wareHouseStatus}
        addressId={singleOrderOBj.addressId}
        ecomOrderId={subOrderObj.ecomOrderId}
      />

      {/* single sub order view modal end */}

      {/* Cancel Modal */}
      {orderCancelResp && orderCancelResp.code === 200 ? (
        <Modal show={cancelShow} onHide={handleCancelModalClose}>
          <Modal.Header closeButton>
            <Modal.Title className='text-center'>Success</Modal.Title>
          </Modal.Header>
          <Modal.Body>{orderCancelResp.msg}</Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      ) : orderCancelResp && orderCancelResp.code === 400 ? (
        <Modal show={cancelShow} onHide={handleCancelModalClose}>
          <Modal.Header closeButton>
            <Modal.Title className='text-center'>Failed</Modal.Title>
          </Modal.Header>
          <Modal.Body>{orderCancelResp.msg}</Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      ) : (
        <Modal show={cancelShow} onHide={handleCancelModalClose}>
          <Modal.Header closeButton>
            <Modal.Title>Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to cancel shipment?</Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={handleCancelModalClose}>
              Cancel
            </Button>
            <Button variant='primary' htmlType='submit' onClick={onCancelClick}>
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      {/* Recive Back Modal */}
      {reciveBackResp && reciveBackResp.code === 200 ? (
        <Modal show={reciveBackShow} onHide={handleReciveBackModalClose}>
          <Modal.Header closeButton>
            <Modal.Title className='text-center'>Success</Modal.Title>
          </Modal.Header>
          <Modal.Body>{reciveBackResp.msg}</Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      ) : reciveBackResp && reciveBackResp.code === 400 ? (
        <Modal show={reciveBackShow} onHide={handleReciveBackModalClose}>
          <Modal.Header closeButton>
            <Modal.Title className='text-center'>Failed</Modal.Title>
          </Modal.Header>
          <Modal.Body>{reciveBackResp.msg}</Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      ) : (
        <Modal show={reciveBackShow} onHide={handleReciveBackModalClose}>
          <Modal.Header closeButton>
            <Modal.Title>Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure, you want to recive shipment back?</Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={handleReciveBackModalClose}>
              Cancel
            </Button>
            <Button variant='primary' htmlType='submit' onClick={onReciveBackClick}>
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>
      )}

      {/* Resend Order Modal */}
      {resendOrderResp && resendOrderResp.code === 200 ? (
        <Modal show={resendOrderShow} onHide={handleResendOrderModal}>
          <Modal.Header closeButton>
            <Modal.Title className='text-center'>Success</Modal.Title>
          </Modal.Header>
          <Modal.Body>{resendOrderResp.msg}</Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      ) : resendOrderResp && resendOrderResp.code === 400 ? (
        <Modal show={resendOrderShow} onHide={handleResendOrderModal}>
          <Modal.Header closeButton>
            <Modal.Title className='text-center'>Failed</Modal.Title>
          </Modal.Header>
          <Modal.Body>{resendOrderResp.msg}</Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      ) : (
        <Modal show={resendOrderShow} onHide={handleResendOrderModal}>
          <Modal.Header closeButton>
            <Modal.Title>Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>{missingItem ? `Are you sure, you want to send the missing item?` : `Are you sure, you want to resend the order?`}</Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={handleResendOrderModal}>
              Cancel
            </Button>
            <Button variant='primary' htmlType='submit' onClick={onResendOrderonClick}>
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      {/* Deliver Modal */}
      {orderDeliverResp && orderDeliverResp.code === 200 ? (
        <Modal show={deliverShow} onHide={handleDeliverModalClose}>
          <Modal.Header closeButton>
            <Modal.Title className='text-center'>Success</Modal.Title>
          </Modal.Header>
          <Modal.Body>{orderDeliverResp.msg}</Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      ) : orderDeliverResp && orderDeliverResp.code === 400 ? (
        <Modal show={deliverShow} onHide={handleDeliverModalClose}>
          <Modal.Header closeButton>
            <Modal.Title className='text-center'>Failed</Modal.Title>
          </Modal.Header>
          <Modal.Body>{orderDeliverResp.msg}</Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      ) : (
        <Modal show={deliverShow} onHide={handleDeliverModalClose}>
          <Modal.Header closeButton>
            <Modal.Title>Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure, the order has been received?</Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={handleDeliverModalClose}>
              Cancel
            </Button>
            <Button variant='primary' htmlType='submit' onClick={onDeliverClick}>
              {dLoader ? <LoadingOutlined /> : 'Confirm'}
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
};

export default InTransit;
