import React, { useState, useEffect, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import axios from 'axios';
import { app } from '../../config';
import CalenderImg from '../../assets/images/calender.png';
import { useNavigate } from 'react-router-dom';
import { Pagination, Input, Form, Select, Row, Col, Space, Spin, message, Collapse, DatePicker } from 'antd';
//Collapse
import { CaretRightOutlined, CaretDownOutlined, SettingOutlined, PrinterOutlined, LoadingOutlined } from '@ant-design/icons';
import moment from 'moment';
//Collapse End
import { useTranslation } from 'react-i18next';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { EditTwoTone, BranchesOutlined } from '@ant-design/icons';
import getHeaders from '../../utils/authHeaders';
import ListinngCard from '../../layouts/ListingCard';
import { dateConverter } from '../../utils/utcDateConversionCommon';

import EcommerceDashboardCard from '../../layouts/EcommerceDashboardCard';
import NoDataFound from '../../layouts/NoDataFound';
import MainOrderViewModal from '../Ecom Orders/MainOrderViewModal';
import SubOrderViewModal from '../Ecom Orders/SubOrderViewModal';
import DashboardMainOrderModal from '../Ecom Orders/DashboardMainOrderModal';
import { SearchBar } from '../../layouts/SearchBar';
import LoaderImage from '../LoaderImage';
const { TextArea } = Input;

const ReadyPickup = () => {
  const { RangePicker } = DatePicker;
  const [activeIndex, setActiveIndex] = useState(1);
  const [cancelShow, setCancelShow] = useState(false);
  const [editbtn, setEditBtn] = useState('none');
  const [pageSize, setPageSize] = useState(10);
  const [fetchPartners, setFetchPartners] = useState([]);
  const [subOrderObj, setSubOrderObj] = useState({});
  const [pagesCount, setPagesCount] = useState(0);
  const [searchShow, setSearchShow] = useState({});
  const [renderList, setRenderList] = useState([]);
  const [tableShow, setTableShow] = useState(true);
  const [activeKey, setActiveKey] = useState(['1']);
  const [currentPage, setCurrentPage] = useState(1);

  const [err, setErr] = useState('');
  const [tableHeaders, setTableHeaders] = useState([]);
  const [show, setShow] = useState(false);
  const [status, setStatus] = useState([]);
  const [cancelData, setCancelData] = useState({});
  const [orderCancelResp, setOrderCancelResp] = useState();
  const [cancelStatus, setCancelStatus] = useState(false);

  const [reciveBackData, setReciveBackData] = useState({});
  const [reciveBackResp, setReciveBackResp] = useState();
  const [reciveBackStatus, setReciveBackStatus] = useState(false);
  const [reciveBackShow, setReciveBackShow] = useState(false);
  const [isRecivedBack, setIsReciveBack] = useState(false);
  const [loader, setLoader] = useState(false);

  const [resendOrderData, setResendOrderData] = useState({});
  const [resendOrderResp, setResendOrderResp] = useState();
  const [resendOrderStatus, setResendOrderStatus] = useState(false);
  const [resendOrderShow, setResendOrderShow] = useState(false);

  const [missingItem, setMissingItem] = useState(false);

  const [stockOutResp, setStockOutResp] = useState([]);
  const [dispatchData, setDispatchData] = useState([]);
  const [dispatchShow, setDispatchShow] = useState(false);
  const [refreshPage, setRefreshPage] = useState(false);
  const [singleOrderOBj, setSingleOrderObj] = useState({});
  const [showSingleOrder, setShowSingleOrder] = useState(false);

  const [singleMainOrderOBj, setSingleMainOrderObj] = useState({});
  const [showSingleMainOrder, setShowSingleMainOrder] = useState(false);
  const [isModalLoading, setIsModalLoading] = useState(false);
  const [shipLabel, setShipLabel] = useState();

  const [adminComment, setAdminComment] = useState('');

  const [printLoader, setPrintLoader] = useState(false);
  const [printOrder, setPrintOrder] = useState();

  const [inTransitShow, setIntransitShow] = useState(false);
  const [inTransitData, setIntransitData] = useState({});

  // const handleClose = () => setShow(false);
  // const handleLockClose = () => setLockModalShow(false);

  let navigate = useNavigate();
  let convertedObj;

  let inProgressHeaders = ['Order No.', 'Order Date', 'Customer', 'Action'];
  let inTransitHeaders = ['Order No.', 'Order Date', 'Customer', 'Total Amount', 'Action'];
  let deliveredHeaders = ['Order No.', 'Order Date', 'Customer', 'Total Amount', 'Action'];
  let customerRequestHeaders = ['Request Type', 'Order No.', 'Sub Order No.', 'Customer Name', 'Request Date', 'Request Status', 'Action', ''];
  let cancelledHeaders = ['Order No.', 'Order Date', 'Customer', 'Total Amount', 'Action'];

  let headersMap = new Map();
  headersMap.set(1, inProgressHeaders);
  headersMap.set(2, inTransitHeaders);
  headersMap.set(3, deliveredHeaders);
  headersMap.set(4, customerRequestHeaders);
  headersMap.set(5, cancelledHeaders);

  useEffect(() => {
    const getState = async () => {
      const statusResp = await axios.get(`${app.URL}/api/v1/admin/ecomorders/warehouseordercount`, { headers: getHeaders() });
      statusResp.data ? console.log(statusResp.data.result.data) : console.log('data');
      statusResp.status == 200 ? setStatus(statusResp.data.result.data) : ' ';
      console.log(status);
    };
    getState();
  }, [refreshPage]);
  console.log(status);

  const user = JSON.parse(localStorage.getItem('user'));
  if (user.userType === 'A') setEditBtn('block');
  const userRequestListingAPI = async (formData) => {
    console.log(formData);
    const partnerResp = await axios.post(`${app.URL}/api/v1/admin/userrequest/view`, formData, { headers: getHeaders() });
    // console.log(partnerResp)
    partnerResp ? console.log(partnerResp.data.result.data) : '';
    partnerResp ? (convertedObj = dateConverter(partnerResp.data.result.data.data, 'createdAt')) : ' ';
    partnerResp.data.result ? setFetchPartners(convertedObj) : ' ';
    partnerResp.data.result ? setPagesCount(partnerResp.data.result.data.count) : ' ';
    partnerResp.data.result ? setRenderList(convertedObj) : ' ';
  };
  const inventoryListingApi = async (formData) => {
    console.log(formData);
    // setActiveIndex(1);
    setLoader(true);

    const partnerResp = await axios.post(`${app.URL}/api/v1/admin/ecomorders/warehouseorders/3`, formData, { headers: getHeaders() });
    // console.log(partnerResp)
    partnerResp ? console.log(partnerResp.data.result.data) : '';
    partnerResp ? (convertedObj = dateConverter(partnerResp.data.result.data.data, 'createdAt')) : ' ';
    partnerResp.data.result ? setFetchPartners(convertedObj) : ' ';
    partnerResp.data.result ? setPagesCount(partnerResp.data.result.data.count) : ' ';
    partnerResp.data.result ? setRenderList(convertedObj) : ' ';
    partnerResp.data.result && setLoader(false);
  };
  const getData = async () => {
    let formData = {
      limit: pageSize,
      offset: 0,
      searchBy: 'orderNo',
      queryTxt: '',
    };
    console.log(formData);
    const partnerResp = await axios.post(`${app.URL}/api/v1/admin/userrequest/view`, formData, { headers: getHeaders() });
    console.log(partnerResp);
    partnerResp ? console.log(partnerResp.data.result.data) : ' ';
    partnerResp.data.result ? (convertedObj = await dateConverter(partnerResp.data.result.data.data, 'createdAt')) : ' ';
    partnerResp.data.result ? setFetchPartners(convertedObj) : ' ';
    partnerResp.data.result ? setPagesCount(partnerResp.data.result.data.count) : ' ';
    partnerResp.data.result ? setRenderList(convertedObj) : ' ';
    return console.log('return called');
  };
  const onRequestChange = async (page, pageSize) => {
    console.log(page, pageSize);
    setPageSize(pageSize);
    setCurrentPage(page);
    if (!page) page = 1;
    let limit = pageSize;
    try {
      let reqObj;
      if (Object.keys(searchShow).length !== 0) {
        reqObj = {
          limit: limit,
          offset: limit * (page - 1),
          // searchBy: searchShow.searchFor,
          // searchTxt: searchShow.searchItem,
        };
      } else {
        reqObj = {
          limit: limit,
          offset: limit * (page - 1),
          // searchBy: 'orderNo.',
          // searchTxt: '',
        };
      }
      console.log(reqObj);
      await userRequestListingAPI(reqObj);
    } catch (e) {
      console.log('Error');
      if (e.message) setErr(e.message);
      setTableShow(false);
    }
  };
  const onChange = async (page, pageSize) => {
    console.log(page, pageSize);
    setPageSize(pageSize);
    setCurrentPage(page);
    if (!page) page = 1;
    let limit = pageSize;
    try {
      let reqObj;
      if (Object.keys(searchShow).length !== 0) {
        reqObj = {
          limit: limit,
          offset: limit * (page - 1),
          searchBy: searchShow.searchBy, //partnerName, serialNo, orderNo
          queryTxt: searchShow.searchTxt,
        };
      } else {
        reqObj = {
          limit: limit,
          offset: limit * (page - 1),
          searchBy: 'orderNo.',
          searchTxt: '',
        };
      }
      console.log(reqObj);
      await inventoryListingApi(reqObj, activeIndex + 1);
    } catch (e) {
      console.log('Error');
      if (e.message) setErr(e.message);
      setTableShow(false);
    }
  };

  //Approve and Refund Workflow
  const handleCancelModalClose = () => setCancelShow(false);

  const cancelShipment = async (data) => {
    try {
      let resp = await axios.post(`${app.URL}/api/v1/admin/userrequest/cancelshipment`, data, { headers: getHeaders() });
      console.log('resp');
      setOrderCancelResp(resp.data.result);
      setCancelStatus(true);
      setTimeout(() => {
        setCancelShow(false);
      }, [3000]);
      await getData();
    } catch (e) {
      throw e;
    }
  };
  const onCancelTrigger = (data) => {
    setOrderCancelResp({});
    console.log(data);
    let cancelObj = {
      requestId: data.requestId,
      patientId: data.patientId,
      ecomOrderId: data.ecomOrderId,
      addressId: data.addressId,
    };

    console.log(cancelObj);
    setCancelShow(true);
    setCancelData(cancelObj);
  };
  const onCancelClick = async () => {
    console.log(cancelData);
    await cancelShipment(cancelData);
  };
  // Recive back order work flow

  const handleReciveBackModalClose = () => setReciveBackShow(false);
  const reciveorder = async (data) => {
    try {
      let resp = await axios.post(`${app.URL}/api/v1/admin/ecomorders/receiveback`, data, { headers: getHeaders() });
      console.log('resp');
      setReciveBackResp(resp.data.result);
      setReciveBackStatus(true);
      setTimeout(() => {
        setReciveBackShow(false);
      }, [3000]);
      await getData();
    } catch (e) {
      throw e;
    }
  };
  const onReciveBackTrigger = (data) => {
    setReciveBackResp({});
    console.log(data);
    let recivebackObj = {
      requestId: data.requestId,
      patientId: data.patientId,
      ecomOrderId: data.ecomOrderId,
      addressId: data.addressId,
    };

    console.log(recivebackObj);
    setReciveBackShow(true);
    setReciveBackData(recivebackObj);
  };
  const onReciveBackClick = async () => {
    console.log(reciveBackData);
    await reciveorder(reciveBackData);
  };

  //Recive back order workflow end

  //Resend Order Workflow

  const handleResendOrderModal = () => setResendOrderShow(false);
  const resendOrder = async (data) => {
    try {
      let resp = await axios.post(`${app.URL}/api/v1/admin/ecomorders/resendorder`, data, { headers: getHeaders() });
      console.log('resp');
      setResendOrderResp(resp.data.result);
      setResendOrderStatus(true);
      setTimeout(() => {
        setResendOrderShow(false);
      }, [3000]);
      await getData();
    } catch (e) {
      throw e;
    }
  };
  const onResendOrderTrigger = (data) => {
    setResendOrderResp({});
    console.log(data);
    if (data.requestType == 'Missing Item' || data.requestType == 'missing item') {
      setMissingItem(true);
    }
    let resendOrderObj = {
      requestId: data.requestId,
      patientId: data.patientId,
      ecomOrderId: data.ecomOrderId,
      addressId: data.addressId,
    };

    console.log(resendOrderObj);
    setResendOrderShow(true);
    setResendOrderData(resendOrderObj);
  };
  const onResendOrderonClick = async () => {
    console.log(resendOrderData);
    await resendOrder(resendOrderData);
  };

  //Resend Order Work flow End
  console.log(renderList);

  useEffect(() => {
    setTableHeaders(headersMap.get(1));
    let formData = {
      limit: pageSize,
      offset: 0,
      searchBy: 'orderNo',
      queryTxt: '',
    };
    inventoryListingApi(formData);
  }, [refreshPage]);

  const { Panel } = Collapse;
  const customExpandIcon = (panelProps) => {
    const { isActive } = panelProps;

    return <span>{isActive ? <CaretDownOutlined /> : <CaretRightOutlined />}</span>;
  };

  const onViewClick = (data) => {
    console.log('onViewClick');
    console.log(data.ecomOrderId);
    localStorage.setItem('orderId', JSON.stringify(data.ecomOrderId));
    navigate('/ecomorders/view', { replace: true });
  };

  const onSearchFinish = async (values) => {
    console.log('onSearchFinish');
    console.log('Success:', values);
    setSearchShow(values)
    let formData = {
      limit: 10,
      offset: 0,
      searchBy: values.searchBy, //partnerName, serialNo, orderNo
      queryTxt: values.searchTxt,
    };
    if (!values.searchTxt) message.error('Please enter search text');
    // console.log(formData)
    else await inventoryListingApi(formData);
  };

  const onSearchFinishFailed = (values) => {
    console.log('Failed:', values);
  };

  const onCardViewClick = (data) => {
    if (data == 1) {
      setActiveIndex(data);
      navigate('/ecom/shipment/prepare', { replace: true });
    } else if (data == 2) {
      setActiveIndex(data);
      navigate('/ecom/shipment/labelprint', { replace: true });
    } else if (data == 3) {
      setActiveIndex(data);
      navigate('/ecom/shipment/pickup', { replace: true });
    }
    // else if(data==4){
    //     setActiveIndex(data);
    //     navigate("/ecom/dashboard/request",{replace: true});
    // }
    // else if(data==5){
    //     setActiveIndex(data);
    //     navigate("/ecom/dashboard/cancel",{replace: true});
    // }
  };

  const stockOut = async (data) => {
    console.log(data);
    // return;
    try {
      let resp = await axios.post(`${app.URL}/api/v1/admin/stockout/create`, data, { headers: getHeaders() });
      console.log('resp');
      setStockOutResp(resp.data.result);
      setIntransitShow(true);

      setTimeout(() => {
        setIntransitShow(!inTransitShow);
      }, [3000]);
    } catch (e) {
      throw e;
    }
  };

  const onDispatchClick = async () => {
    console.log(dispatchData);
    let stockOutArr = [];
    dispatchData.products.forEach((ele) => {
      stockOutArr.push({
        productId: ele.productId,
        quantity: ele.quantity,
        unitPrice: ele.unitPrice,
        patientId: subOrderObj.patientId,
        ecomOrderId: subOrderObj.ecomOrderId,
        addressId: dispatchData.addressId,
      });
    });

    await stockOut(stockOutArr);
    console.log('stock out resp: ', stockOutResp);
    // getInProgressData();
    setRefreshPage(true);

    // console.log(stockOutResp)
  };

  const [mainEcomOrderId, setMainEcomOrderId] = useState(null);

  const getMainOrderData = async (id) => {
    try {
      let resp = await axios.get(`${app.URL}/api/v1/admin/ecomorders/view/${id}`, { headers: getHeaders() });
      // console.log("main order get api response: ", resp.data.result.data );
      // setSingleMainOrderObj(resp.data.result.data);
      // setSubOrderObj(resp.data.result.data);

      // console.log("sub order obj modal api before: ", subOrderObj);
      // console.log("main order single obj modal api before: ", singleMainOrderOBj);

      setSingleMainOrderObj(resp.data.result.data);

      console.log('main order obj: ', singleMainOrderOBj);

      setIsModalLoading(!isModalLoading);

      // console.log("sub order obj modal api after: ", subOrderObj);
      // console.log("main order single obj modal api after: ", singleMainOrderOBj);
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  const pageStyle = `
       @page {
           size: 4in 7in; /* Specify your custom page size here (width x height) */
           marginTop: 0px;
           margin: 0;
       }
       body {
         font-family: Arial, sans-serif;
         margin: 0;
       }
       `;
  const handleAfterPrint = () => {
    // This function will be executed after printing
    navigate('/ecom/shipment/pickup/', { replace: true });
  };
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: pageStyle,
    // onAfterPrint: handleAfterPrint,
  });
  const printLabelClick = (data) => {
    
    setPrintOrder(data.ecomOrderNo)
    setPrintLoader(true);
    console.log('data');
    console.log(data);
    setShipLabel(data.shipLabel);
    console.log('shipLabelClick');
    console.log(shipLabel);
    setTimeout(() => {
      setPrintLoader(false);
      data.shipLabel ? handlePrint() : ' ';
    }, 1000);
  };
  // useEffect(()=>{
  //     console.log("shipLabelClick")
  //     console.log(shipLabel)
  //   handlePrint()
  // },[setShipLabel])

  const [mainOrderObj, setMainOrderObj] = useState({});

  const mainOrderModal = (order) => {
    console.log('Main Order Modal Running');
    const getData = async () => {
      let resp = await axios.get(`${app.URL}/api/v1/admin/ecomorders/view/${order.ecomOrderId}`, { headers: getHeaders() });
      setMainOrderObj(resp.data.result.data);
      setSingleMainOrderObj(resp.data.result.data);
      openMainOrderModal();
    };

    getData();
  };

  const openMainOrderModal = () => {
    setShowSingleMainOrder(true);
  };

  const handleIntransitModalClose = () => setIntransitShow(false);
  const onIntransitTrigger = (data) => {
    console.log('InTransit data: ', data);
    setRefreshPage(false);
    setStockOutResp({});
    setIntransitShow(true);
    setIntransitData(data);
  };
  console.log(inTransitShow);
  console.log(inTransitData);
  const onIntransitClick = async () => {
    console.log(inTransitData);
    let stockOutArr = [];
    inTransitData.products.forEach((ele) => {
      stockOutArr.push({
        productId: ele.productId,
        quantity: ele.quantity,
        unitPrice: ele.unitPrice,
        patientId: inTransitData.patientId,
        ecomOrderId: inTransitData.ecomOrderId,
        addressId: inTransitData.addressId,
      });
    });
    // console.log(stockOutArr)
    await stockOut(stockOutArr);
    console.log('stock out resp: ', stockOutResp);
    setRefreshPage(true);
  };

  const handleOnChange = (e) => {
    if (e.target.value.length === 0) {
      setRefreshPage(!refreshPage);
      setSearchShow({});
    }
  };

  console.log(status);

  return (
    <div className='dashboard-card container-fluid'>
      <div className='row p-0 pb-4'>
        <div className='for-before col-12 col-lg-4 col-md-6 head fs-3 fw-bold' style={{ backgroundColor: '#a7c1d9', borderBottomRightRadius: '10px', color: '#FFFFFF' }}>
          Shipment Dashboard
        </div>
        <div className='col-12 mt-2 col-lg-4 col-md-3'>
          <RangePicker onChange={onChange} />
        </div>
        <div className='col-12 mt-2 col-lg-4 col-md-3 d-flex align-end' style={{ margin: 'auto', position: 'relative' }}>
          <img className='calender-img' src={CalenderImg} />
          <select className='form-control drop-down-select' name='dates' id='date' onChange={(e) => dateSelect(e.target.value)}>
            <option value={moment().subtract(24, 'hours').format('YYYY-MM-DD')}>last 24 hours</option>
            <option value={moment().subtract(7, 'd').format('YYYY-MM-DD')}>last week</option>
            <option value={moment().subtract(1, 'months').format('YYYY-MM-DD')}>last month</option>
            <option value={moment().subtract(1, 'year').format('YYYY-MM-DD')}>last year</option>
          </select>
        </div>
      </div>
      <div className='row px-3'>
        <div style={{ position: 'relative' }} className='col-md-4 col-12 my-1' onClick={() => onCardViewClick(1)}>
          <EcommerceDashboardCard
            count={status.length > 1 ? status[0].count : 0}
            // count={0}

            isActive={window.location.pathname == '/ecom/shipment/prepare'}
            activatedProducts={true}
            awaitingProducts={false}
            cardName={'Prepare Shipment'}
          />
        </div>
        <div style={{ position: 'relative' }} className='col-md-4 col-12 my-1' onClick={() => onCardViewClick(2)}>
          <EcommerceDashboardCard
            count={status.length > 1 ? status[1].count : 0}
            // count={0}
            isActive={window.location.pathname == '/ecom/shipment/labelprint'}
            activatedProducts={true}
            awaitingProducts={false}
            cardName={'Print Labels'}
          />
        </div>
        <div style={{ position: 'relative' }} className='col-md-4 col-12 my-1' onClick={() => onCardViewClick(3)}>
          <EcommerceDashboardCard
            count={status.length > 1 ? status[2].count : 0}
            // count={0}

            isActive={window.location.pathname == '/ecom/shipment/pickup'}
            activatedProducts={true}
            awaitingProducts={false}
            cardName={'Ready to Ship'}
          />
        </div>
      </div>
      <SearchBar
        onFinish={onSearchFinish}
        onFinishFailed={onSearchFinishFailed}
        onChange={handleOnChange}
        searchItems={[
          {
            name: 'Shipment Name',
            value: 'title',
          },
          {
            name: 'Order No.',
            value: 'orderNo',
          },
        ]}
      />
      <div
        className=' table-scrollbar'
        style={{
          overflowX: 'auto',
        }}
      >
        {loader && <LoaderImage />}

        {renderList.length > 0 && !loader ? (
          <table id='customers' className='w-100'>
            <thead class=''>
              <tr
                style={{
                  fontWeight: 'bold',
                }}
              >
                <th>Sub Order No.</th>
                <th>Shipment Name</th>
                <th>Address</th>
                <th>Shipment Package</th>
                <th>Customer Request</th>
                <th>Action</th>
                {/* <th></th>
                <th></th> */}
              </tr>
            </thead>
            <tbody>
              {renderList.map((tableValues, key) => (
                <tr>
                  {/* <td style={{ width: '200px', textTransform: "capitalize" }}>{tableValues.requestType ? tableValues.requestType : 'null'}</td> */}
                  <td style={{ width: '180px' }}>{tableValues.ecomOrderNo ? tableValues.ecomOrderNo + '-' + tableValues.addressId : 'null'}</td>
                  <td style={{ width: '180px' }}>{tableValues.title ? tableValues.title : 'null'}</td>
                  <td style={{ width: '220px', textAlign: 'center' }}>{`${tableValues.address}, ${tableValues.state}, ${tableValues.city}, ${tableValues.zipCode}`}</td>
                  <td style={{ width: '180px' }}>{tableValues.shipmePackageName ? tableValues.shipmePackageName : 'null'}</td>
                  <td style={{ width: '180px' }}>{tableValues.requestType ? tableValues.requestType : ''}</td>

                  <td>
                    <div className='d-flex aligm-items-center justify-content-center'>
                      {tableValues.isLabelGenerated === 1 && !tableValues.requestId && (
                        <>
                          <Button
                            onClick={() => {
                              printLabelClick(tableValues);
                            }}
                            style={{ width: '70px', height: '34px', fontSize: 'small', marginRight: '0.5rem' }}
                            size='medium'
                          >
                            {printLoader && printOrder === tableValues.ecomOrderNo ? (
                              <LoadingOutlined
                                style={{
                                  fontSize: '18px',
                                }}
                              />
                            ) : (
                              <PrinterOutlined
                                style={{
                                  fontSize: '18px',
                                }}
                              />
                            )}
                          </Button>
                          <div style={{ display: 'none' }}>
                            <div ref={componentRef}>
                              <img src={shipLabel} />
                            </div>
                          </div>
                        </>
                      )}
                      <Button
                        onClick={() => {
                          setSingleOrderObj(tableValues);
                          setShowSingleOrder(true);
                        }}
                        style={{ width: '70px', height: '34px', fontSize: 'small', marginRight: '0.5rem' }}
                        size='medium'
                      >
                        View
                      </Button>
                      {tableValues.isLabelGenerated === 1 && !tableValues.requestId && (
                        <Button onClick={() => onIntransitTrigger(tableValues)} style={{ width: '85px', height: '34px', fontSize: 'small', marginRight: '0.5rem' }} size='medium'>
                          Dispatch
                        </Button>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <>{!loader && <NoDataFound />}</>
        )}
      </div>
      {currentPage && pagesCount && !loader ? (
        <div className='row'>
          <Pagination style={{ marginTop: '10px' }} onChange={activeIndex == 4 ? onRequestChange : onChange} defaultCurrent={currentPage} total={pagesCount} showTotal={(total) => `Total ${total} items`} showSizeChanger />{' '}
        </div>
      ) : (
        ' '
      )}
      {/* show single main order modal start */}
      {showSingleMainOrder && (
        <MainOrderViewModal
          isVisible={showSingleMainOrder}
          setIsVisible={setShowSingleMainOrder}
          ecomOrderNo={singleMainOrderOBj.ecomOrderNo}
          ecomOrderCreatedData={moment(singleMainOrderOBj.createdAt).format('MM-DD-YYYY')}
          // patientName={"singleMainOrderOBj.patientName"}
          productTotalAmount={singleMainOrderOBj.totalProductAmount}
          shipmentTotal={singleMainOrderOBj.totalShipmentCharges}
          subOrdersData={singleMainOrderOBj?.orderDetails}
          isLoading={isModalLoading}
          setIsLoading={setIsModalLoading}
          mainOrderShipmentAmount={singleMainOrderOBj.totalShipmentCharges}
          mainOrderGrandTotal={singleMainOrderOBj.grandTotalAmount}
          totalProductAmount={singleMainOrderOBj.totalProductAmount}
          ecomOrderId={singleMainOrderOBj.ecomOrderId}
          isApiCallAble={false}
        />
      )}
      {/* show single main order modal end */}
      {/* show single sub order modal start */}
      {showSingleOrder && (
        <SubOrderViewModal
          isVisible={showSingleOrder}
          ecomOrderId={singleOrderOBj.ecomOrderId}
          addressId={singleOrderOBj.addressId}
          ecomOrderNo={`${singleOrderOBj.ecomOrderNo}-${singleOrderOBj.addressId}`}
          setIsVisible={setShowSingleOrder}
          address={`${singleOrderOBj.address}, ${singleOrderOBj.state}, ${singleOrderOBj.city}, ${singleOrderOBj.zipCode}`}
          ecomOrderCreatedDate={subOrderObj.createdAt}
          productsData={singleOrderOBj?.products}
          status={singleOrderOBj.status}
          title={singleOrderOBj.title}
          shipmentCharges={singleOrderOBj.shipmentCharges}
          grandTotal={singleOrderOBj.totalAmountwithShipment}
          totalShipmentAmount={singleOrderOBj.totalShipmentAmount}
          tax={singleOrderOBj.taxAmount}
          requestId={singleOrderOBj.requestId}
          requestStatus={singleOrderOBj.requestStatus}
          requestType={singleOrderOBj.requestType}
          shipmePackageName={singleOrderOBj.shipmePackageName}
          locationURL={window.location.pathname}
          wareHouseStatusId={singleOrderOBj.wareHouseStatusId}
          wareHouseStatus={singleOrderOBj.wareHouseStatus}
        />
      )}
      {/* show single sub order modal end */}
      {/* dispatch order modal start */}
      {stockOutResp && stockOutResp.code === 200 ? (
        <Modal show={dispatchShow} onHide={handleCancelModalClose}>
          <Modal.Header closeButton>
            <Modal.Title className='text-center'>Success</Modal.Title>
          </Modal.Header>
          <Modal.Body>{stockOutResp.msg}</Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      ) : stockOutResp && stockOutResp.code === 400 ? (
        <Modal show={dispatchShow} onHide={handleCancelModalClose}>
          <Modal.Header closeButton>
            <Modal.Title className='text-center'>Failed</Modal.Title>
          </Modal.Header>
          <Modal.Body>{stockOutResp.msg}</Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      ) : (
        <Modal show={dispatchShow} onHide={handleCancelModalClose}>
          <Modal.Header closeButton>
            <Modal.Title>Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to dispatch the shipment?</Modal.Body>

          <Modal.Footer>
            <Button
              variant='secondary'
              onClick={() => {
                setDispatchShow(!dispatchShow);
              }}
            >
              Cancel
            </Button>
            <Button
              variant='primary'
              htmlType='submit'
              onClick={() => {
                onDispatchClick();
              }}
            >
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      {/* dispatch order modal end */}
      {/* In Transit Modal */}
      {stockOutResp && stockOutResp.code === 200 ? (
        <Modal show={inTransitShow} onHide={handleIntransitModalClose}>
          <Modal.Header closeButton>
            <Modal.Title className='text-center'>Success</Modal.Title>
          </Modal.Header>
          <Modal.Body>{stockOutResp.msg}</Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      ) : stockOutResp && stockOutResp.code === 400 ? (
        <Modal show={inTransitShow} onHide={handleIntransitModalClose}>
          <Modal.Header closeButton>
            <Modal.Title className='text-center'>Failed</Modal.Title>
          </Modal.Header>
          <Modal.Body>{stockOutResp.msg}</Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      ) : (
        <Modal show={inTransitShow} onHide={handleIntransitModalClose}>
          <Modal.Header closeButton>
            <Modal.Title>Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to dispatch shipment?</Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={handleIntransitModalClose}>
              Cancel
            </Button>
            <Button variant='primary' htmlType='submit' onClick={onIntransitClick}>
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      {/* Cancel Modal */}
      {orderCancelResp && orderCancelResp.code === 200 ? (
        <Modal show={cancelShow} onHide={handleCancelModalClose}>
          <Modal.Header closeButton>
            <Modal.Title className='text-center'>Success</Modal.Title>
          </Modal.Header>
          <Modal.Body>{orderCancelResp.msg}</Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      ) : orderCancelResp && orderCancelResp.code === 400 ? (
        <Modal show={cancelShow} onHide={handleCancelModalClose}>
          <Modal.Header closeButton>
            <Modal.Title className='text-center'>Failed</Modal.Title>
          </Modal.Header>
          <Modal.Body>{orderCancelResp.msg}</Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      ) : (
        <Modal show={cancelShow} onHide={handleCancelModalClose}>
          <Modal.Header closeButton>
            <Modal.Title>Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to cancel shipment?</Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={handleCancelModalClose}>
              Cancel
            </Button>
            <Button variant='primary' htmlType='submit' onClick={onCancelClick}>
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      {/* Recive Back Modal */}
      {reciveBackResp && reciveBackResp.code === 200 ? (
        <Modal show={reciveBackShow} onHide={handleReciveBackModalClose}>
          <Modal.Header closeButton>
            <Modal.Title className='text-center'>Success</Modal.Title>
          </Modal.Header>
          <Modal.Body>{reciveBackResp.msg}</Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      ) : reciveBackResp && reciveBackResp.code === 400 ? (
        <Modal show={reciveBackShow} onHide={handleReciveBackModalClose}>
          <Modal.Header closeButton>
            <Modal.Title className='text-center'>Failed</Modal.Title>
          </Modal.Header>
          <Modal.Body>{reciveBackResp.msg}</Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      ) : (
        <Modal show={reciveBackShow} onHide={handleReciveBackModalClose}>
          <Modal.Header closeButton>
            <Modal.Title>Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure, you want to recive shipment back?</Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={handleReciveBackModalClose}>
              Cancel
            </Button>
            <Button variant='primary' htmlType='submit' onClick={onReciveBackClick}>
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      {/* Resend Order Modal */}
      {resendOrderResp && resendOrderResp.code === 200 ? (
        <Modal show={resendOrderShow} onHide={handleResendOrderModal}>
          <Modal.Header closeButton>
            <Modal.Title className='text-center'>Success</Modal.Title>
          </Modal.Header>
          <Modal.Body>{resendOrderResp.msg}</Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      ) : resendOrderResp && resendOrderResp.code === 400 ? (
        <Modal show={resendOrderShow} onHide={handleResendOrderModal}>
          <Modal.Header closeButton>
            <Modal.Title className='text-center'>Failed</Modal.Title>
          </Modal.Header>
          <Modal.Body>{resendOrderResp.msg}</Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      ) : (
        <Modal show={resendOrderShow} onHide={handleResendOrderModal}>
          <Modal.Header closeButton>
            <Modal.Title>Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>{missingItem ? `Are you sure, you want to send the missing item?` : `Are you sure, you want to resend the order?`}</Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={handleResendOrderModal}>
              Cancel
            </Button>
            <Button variant='primary' htmlType='submit' onClick={onResendOrderonClick}>
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
};

export default ReadyPickup;
