import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { app } from '../../config';
import getHeaders from '../../utils/authHeaders';

const SubOrderViews = ({ ecomOrderId,item, indx, locationURL, addressId, commentTableHeaders }) => {
  const [commentsData, setCommentsData] = useState([])

  useEffect(() => {
    const getAllSubOrderComments = async () => {
      // console.log('ecom', ecomOrderId);
    

      try {
        let resp = await axios.get(`${app.URL}/api/v1/admin/comment/viewbysuborder/${ecomOrderId}/${addressId ? addressId : ""}`, { headers: getHeaders() });
        console.log('comments respo: ', resp.data.result.data);

        // let convertedObj=dateConverter(resp.data.result.data,"createdAt");
        setCommentsData(resp.data.result.data);


      } catch (error) {
        console.log('error in getting comments data: ', error);
      }
    };

    console.log('call able');

    
    //   setTimeout(() => {
    //     getAllSubOrderComments();
    //   }, 1000);

        getAllSubOrderComments();
 

    return () => {
      setCommentsData([]);
    };
  }, [addressId]);

  return (
    <>
      <div className='row' key={indx}>
        <div className='row'>
          <div className='col-lg-6 fs-5 mt-0 mb-0 pt-0 pb-0 pl-3'>{item.addressTitle}</div>

          {item.requestId && <div className='col-lg-6  mt-0 mb-0 pt-0 pb-0 pl-0 text-right'>Request Type: {item.requestType}</div>}
          <div className='col-lg-6  mt-0 mb-0 pt-0 pb-0 pl-3'>{item.phone}</div>
          {item.requestId && <div className='col-lg-6  mt-0 mb-0 pt-0 pb-0 pl-0 text-right'>Request Status: {item.requestStatus}</div>}

          <div className='col-lg-6  mt-0 mb-0 pt-0 pb-0 pl-3'>{item.address}</div>
          <div className='col-lg-12 mt-0 mb-0 pt-0 pb-0 pl-3'>{item.shipmentPackage}</div>
        </div>
      </div>
      {/* {locationURL === '/ecom/dashboard/request' && (
        <div className='text-right'>
          <button
            variant='primary'
            className='btn btn-primary text-right mt-4'
            style={{
              textAlign: 'right',
            }}
            onClick={() => {
              if (addressIdd === item.addressId) {
                setAddressId(null);
              } else {
                setAddressId(item.addressId);
              }
            }}
          >
            {addressIdd === item.addressId ? 'Hide Comment History' : 'View Comment History'}
          </button>
        </div>
      )} */}

      <div className='mb-4 '>
        <table id='customers' className='w-100'>
          <thead>
            <tr
              style={{
                fontWeight: 'bold',
              }}
            >
              <th>Product Name </th>
              <th>Quantity</th>
              <th>Unit Price</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            {item.product?.map((product, index) => {
              return (
                <tr>
                  <td style={{ width: '100px' }}>{product.productName}</td>
                  <td style={{ width: '100px' }}>{product.quantity}</td>
                  <td style={{ width: '100px' }}>${Number(product.unitPrice)?.toFixed(2)}</td>

                  <td style={{ width: '100px' }}>${(Number(product.quantity).toFixed(2) * Number(product.unitPrice)?.toFixed(2)).toFixed(2)}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className='row'>
          {/* <div className='col-lg-12  mt-0 mb-0 pt-0 pb-0 pl-3 text-right'>Total Product Amount: ${item.grandTotal}</div> */}

          <div className='col-lg-12  mt-0 mb-0 pt-0 pb-0 pl-3 text-right'>Tax: ${item.taxAmount}</div>
          <div className='col-lg-12  mt-0 mb-0 pt-0 pb-0 pl-3 text-right'>Shipment Charges: {Number(item.shipmentCharges) <= 0 ? 'Free' : `$${Number(item.shipmentCharges)}`} </div>
          <div className='col-lg-12  mt-0 mb-0 pt-0 pb-0 pl-3 text-right'>Grand Total: ${item.grandTotal}</div>
        </div>
      </div>

      <div
        className='row mt-2'
        style={{
          border: '1px solid silver',
          borderRadius: 10,
        }}
      >
        <div className='col-lg-6 fs-5 mt-0 mb-0 pt-0 pb-0  mt-2' style={{ height: '40px' }}>
          History
        </div>

        <div
          style={{
            maxHeight: '200px',
            overflowY: 'auto',
          }}
        >
          <table
            className='table'
            style={{
              width: '100%',
              borderCollapse: 'collapse',
            }}
          >
            <thead
              className=''
              style={{
                backgroundColor: '#f2b544',
                color: '#fff',
              }}
            >
              <tr
                style={{
                  fontWeight: 'bold',
                }}
              >
                {commentTableHeaders.map((header, index) => {
                  return (
                    <th
                      style={{
                        textAlign: 'center',
                        padding: '8px',
                        fontSize: '13px',
                      }}
                    >
                      {header}
                    </th>
                  );
                })}
              </tr>
            </thead>

            {commentsData.length > 0 ? (
              <tbody>
                {commentsData.map((commentItem, index) => {
                  console.log(commentsData);
                  return (
                    <tr
                      key={index}
                      style={{
                        backgroundColor: index % 2 == 0 ? '' : '#dddddd',
                      }}
                    >
                      {/* <td
                                                    style={{
                                                        width: '40px',
                                                        textAlign: "center",
                                                        padding: "8px",
                                                        fontSize: "13px"
                                                    }}>{`${commentItem.ecomOrderNo}-${commentItem.addressId}`}

                                                </td> */}
                      <td
                        style={{
                          width: '60px',
                          textAlign: 'center',
                          padding: '8px',
                          fontSize: '13px',
                        }}
                      >
                        {moment(commentItem.createdAt).format('MM-DD-YYYY HH:mm')}
                      </td>

                      <td
                        style={{
                          width: '20px',
                          textAlign: 'center',
                          padding: '8px',
                          fontSize: '13px',
                        }}
                      >
                        {commentItem.commentBy}
                      </td>

                      <td
                        style={{
                          width: '200px',
                          textAlign: 'center',
                          padding: '8px',
                          fontSize: '13px',
                        }}
                      >
                        {commentItem.comment}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            ) : (
              <div className='row text-center'>
                <h2 className='text-center'>No Comment History</h2>
              </div>
            )}
          </table>
        </div>
      </div>
    </>
  );
};

export default SubOrderViews