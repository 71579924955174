// import AddUser from "../pages/AddUser";
// import AdmnSignIn from '../pages/adminAuth/AdmnSignIn';

import AddUser from '../pages/user/AddUser';
import EditUser from '../pages/user/EditUser';
import UsersListing from '../pages/user/UsersListing';

import Roles from '../pages/roles/RolesListing';
import AddRolePermission from '../pages/roles/AddRolePermission';
import EditRolepermissions from '../pages/roles/EditRolePermissions';

import Patients from '../pages/patients/PatientsListing';
import AddPatient from '../pages/patients/AddPatient';
import EditPatient from '../pages/patients/EditPatient';

import Stores from '../pages/store/StoreListing';
import AddStore from '../pages/store/AddStore';
import EditStore from '../pages/store/EditStore';

import Partners from '../pages/partners/PartnersListing';
import AddPartner from '../pages/partners/AddPartner';
import EditPartner from '../pages/partners/EditPartner';

import ProductCatListing from '../pages/ProductsCategory/ProductCatListing';
import AddProductCategory from '../pages/ProductsCategory/AddProductCategory';
import EditProductCategory from '../pages/ProductsCategory/EditProductCategory';

import AddProducts from '../pages/Products/AddProducts';
import EditProducts from '../pages/Products/EditProducts';
import ProductListing from '../pages/Products/ProductListing';

import Ethnicity from '../pages/ethnicity/EthnicityListing';
import AddEthnicity from '../pages/ethnicity/AddEthnicity';
import EditEthnicity from '../pages/ethnicity/EditEthnicity';

import Inventory from '../pages/inventory/InventoryListing';
import AddInventory from '../pages/inventory/AddInventory';
import EditInventory from '../pages/inventory/EditInventory';

import ProductConfListing from '../pages/productsConfiguration/ProductConfListing';
import AddProductConf from '../pages/productsConfiguration/AddProductConf';
import EditProductConf from '../pages/productsConfiguration/EditProductConf';

import Dashboard2 from '../pages/Dashboard2';
import InventoryBatchListing from '../pages/inventory/InventoryBatchListing';
import InventoryListing2 from '../pages/inventory/InventoryListing2';
import Listing from '../pages/Banners/Banners';
import Banners from '../pages/Banners/Banners';
import AddBanner from '../pages/Banners/AddBanner';
import UpdateBanner from '../pages/Banners/UpdateBanner';
import EcomInventory from '../pages/Ecom Inventory/EcomInventory';
import AddEcomInventory from '../pages/Ecom Inventory/AddEcomInventory';
import EcomOrders from '../pages/Ecom Orders/EcomOrders';
import ViewSingleEcomOrder from '../pages/Ecom Orders/ViewSingleEcomOrder2';
import UserRequests from '../pages/User Requests/UserRequests';
import EcommerceDashboard from '../pages/Ecom Orders/EcommerceDashboard';
import InProgress from '../pages/Ecom Orders/InProgress';
import InTransit from '../pages/Ecom Orders/InTransit';
import Delivered from '../pages/Ecom Orders/Delivered';
import Request from '../pages/Ecom Orders/Request';
import CancelledOrRefund from '../pages/Ecom Orders/CancelledOrRefund';
import CompletedEcomOrders from '../pages/Ecom Orders/CompletedEcomOrders';
import InCompleteEcomOrders from '../pages/Ecom Orders/IncompletedEcomOrders';
// import UserFeedBack from '../pages/userFeedback/UserFeedBack';
import UserFeedBackListing from "../pages/userFeedback/UserFeedbackListing";
import GenericBoxManagement from '../pages/genericBoxManagement/GenericBoxManagement';
import AddGenericBox from '../pages/genericBoxManagement/AddGenericBox';
import AddUserFeedback from '../pages/userFeedback/AddUserFeedBack';
import PrepareForShip from '../pages/WareHouse/PrepareForShip';
import LabelPrint from '../pages/WareHouse/LabelPrint';
import ReadyPickup from '../pages/WareHouse/ReadyPickup';
import GenericBoxBatchListing from '../pages/genericBoxManagement/GenericBoxBatchListing';
import ShipmentOrderView from '../pages/shipment/ShipmentOrderView';
import BoxTypeListing from '../pages/boxType/BoxTypeListing';
import AddBoxType from '../pages/boxType/AddBoxType';
import UpdateBoxType from '../pages/boxType/UpdateBoxType';
import CheckOrder from '../pages/checkOrder/CheckOrder';
import EditUserFeedback from '../pages/userFeedback/EditUserFeedback';
import EditProfile from '../pages/Profile/EditProfile';
import WarehouseUnverified from '../pages/WareHouse/WarehouseUnverified';
import LabelListing from '../pages/retunLabel/labelListing';
import LabelBatchListing from '../pages/retunLabel/labelBatchListing';
import AddLabel from '../pages/retunLabel/addLabel';
import AnuraCategories from '../pages/anuraFacialScan/AnuraCategories';
import AnuraElements from '../pages/anuraFacialScan/AnuraElements';
import AnuraElementUpdate from '../pages/anuraFacialScan/AnuraElementUpdate';
import LabTestList from '../pages/labTests/LabTestList';
import LabTestUpdate from '../pages/labTests/LabTestUpdate';
import AddProductsPrev from '../pages/Products/AddProductsPrev';
import GroupListing from '../pages/labTestsGroups/GroupsListing';
import UpdateGroup from '../pages/labTestsGroups/UpdateGroup';
import AddGroup from '../pages/labTestsGroups/AddGroup';
// import BindCodes from '../pages/retunLabel/bindCodes';
import BindCodes from '../pages/retunLabel/bindCodes';
import Confifurations from '../pages/Configurations';
import StockBalancereport from '../pages/Reports/StockBalancereport';
import BlogListing from '../pages/Reports/Blogs/BlogListing';
import AddBlog from '../pages/Reports/Blogs/AddBlog';
import EditBlog from '../pages/Reports/Blogs/EditBlog';
import AddBlogwithSelect from '../pages/Reports/Blogs/AddBlogwithSelect';
import MappingListing from '../pages/inventory/MappingListing';
import QuestionaireListing from '../pages/Questionnaire/Listing';
import QuestionaireEdit from '../pages/Questionnaire/Edit';
import QuestionaireAdd from '../pages/Questionnaire/Add';
import AddProducts240724 from '../pages/Products/AddProducts240724';
import EditProducts240724 from '../pages/Products/EditProducts240724';
import SearchOrder from '../pages/checkOrder/SearchOrder';
import RequestListing from '../pages/RequestButton/Listing';
import RequestButtonAdd from '../pages/RequestButton/Add';
import RequstButtonEdit from '../pages/RequestButton/Edit';
import Faq from '../pages/Faqs/Faq';
import FaqDetails from '../pages/Faqs/FaqDetails';
import UpdateFaqs from '../pages/Faqs/UpdateFaqs';
import BoxTypeIngredients from '../pages/BoxTypeIngredients/Listing';
import ReceiveOrder from '../pages/Ecom Orders/ReceiveOrder';

const allRoutes = [
  { id: 1, path: 'dashboard/listing', name: 'dashboard', component: <Dashboard2 /> },

  //  Users
  { id: 6, path: 'adminaccount/usercreate', name: 'adduser', component: <AddUser /> },
  { id: 7, path: 'adminaccount/userupdate', name: 'editUser', component: <EditUser /> },
  { id: 4, path: 'adminaccount/userlisting', name: 'userListing', component: <UsersListing /> },

  // Profile
  { id: 30, path: 'profile/edit', name: 'editProfile', component: <EditProfile /> },

  // Roles Management
  { id: 11, path: 'role/addrole', name: 'addRoles', component: <AddRolePermission /> },
  { id: 8, path: 'role/rolelisting', name: 'viewRole', component: <Roles /> },
  { id: 9, path: 'role/updaterole', name: 'editRole', component: <EditRolepermissions /> },

  // Patient Management
  { id: 11, path: 'patient/create', name: 'addPatient', component: <AddPatient /> },
  { id: 8, path: 'patient/listing', name: 'viewPatient', component: <Patients /> },
  { id: 9, path: 'patient/update', name: 'editPatient', component: <EditPatient /> },

  // Store Management
  { id: 15, path: 'store/create', name: 'addStore', component: <AddStore /> },
  { id: 16, path: 'store/listing', name: 'viewStore', component: <Stores /> },
  { id: 17, path: 'store/update', name: 'editStore', component: <EditStore /> },

  // Partner Management
  { id: 18, path: 'partner/create', name: 'addPartner', component: <AddPartner /> },
  { id: 19, path: 'partner/listing', name: 'viewPartners', component: <Partners /> },
  { id: 20, path: 'partner/update', name: 'editPartner', component: <EditPartner /> },

  // Vertical Management
  { id: 18, path: 'ethnicity/create', name: 'addPartner', component: <AddEthnicity /> },
  { id: 19, path: 'ethnicity/listing', name: 'viewEthnicity', component: <Ethnicity /> },
  { id: 20, path: 'ethnicity/update', name: 'editPartner', component: <EditEthnicity /> },

  // Product Catgegory Management
  { id: 21, path: 'category/create', name: 'addProductCat', component: <AddProductCategory /> },
  { id: 22, path: 'category/listing', name: 'viewProductCat', component: <ProductCatListing /> },
  { id: 23, path: 'category/update', name: 'editProductCat', component: <EditProductCategory /> },

  // Product Management
  { id: 24, path: 'product/create', name: 'addProduct', component: <AddProducts240724 /> },
  { id: 25, path: 'product/listing', name: 'viewProducts', component: <ProductListing /> },
  { id: 26, path: 'product/update', name: 'editProducts', component: <EditProducts240724 /> },

  // Product Inventory Management
  { id: 27, path: 'inventory/listing', name: 'viewInventory', component: <InventoryListing2 /> },
  // available product boxes
  { id: 27, path: 'inventory/mapview', name: 'viewInventory', component: <MappingListing /> },

  { id: 27, path: 'inventory/batchreport', name: 'viewInventory', component: <InventoryBatchListing /> },
  { id: 28, path: 'inventory/create', name: 'addInventory', component: <AddInventory /> },
  { id: 29, path: 'inventory/update', name: 'editInventory', component: <EditInventory /> },

  // Product Confifuration Management
  { id: 27, path: 'productconfiguration/listing', name: 'viewProductConfiguration', component: <ProductConfListing /> },
  { id: 28, path: 'productconfiguration/create', name: 'addProductConfiguration', component: <AddProductConf /> },
  { id: 29, path: 'productconfiguration/update', name: 'editProductConfiguration', component: <EditProductConf /> },

  //Banners Routes
  { id: 27, path: 'banner/listing', name: 'BannerListing', component: <Banners /> },
  { id: 28, path: 'banner/create', name: 'addBanner', component: <AddBanner /> },
  { id: 29, path: 'banner/update', name: 'updateBanner', component: <UpdateBanner /> },

  //Ecommerce Routes
  //Ecom Inventory Routes
  { id: 27, path: 'ecominv/list', name: 'EcominventoryListing', component: <EcomInventory /> },
  { id: 28, path: 'ecominv/add', name: 'addBanner', component: <AddEcomInventory /> },

  //Ecom Orders

  { id: 27, path: 'ecomorders/list', name: 'EcominventoryListing', component: <EcomOrders /> },
  { id: 27, path: 'ecomorders/view', name: 'View Single Ecom Order', component: <ViewSingleEcomOrder /> },

  //User Requests

  { id: 27, path: 'userrequest/view', name: 'EcominventoryListing', component: <UserRequests /> },

  //Ecommerce Dashboard
  { id: 27, path: 'ecom/dashboard', name: 'Ecommerce Dashboard', component: <InProgress /> },
  { id: 27, path: 'ecom/dashboard/inprogress', name: 'Ecommerce Dashboard In Progress', component: <InProgress /> },
  { id: 27, path: 'ecom/dashboard/intransit', name: 'Ecommerce Dashboard In Progress', component: <InTransit /> },
  { id: 27, path: 'ecom/dashboard/delivered', name: 'Ecommerce Dashboard In Progress', component: <Delivered /> },
  { id: 27, path: 'ecom/dashboard/request', name: 'Ecommerce Dashboard In Progress', component: <Request /> },
  { id: 27, path: 'ecom/dashboard/cancel', name: 'Ecommerce Dashboard In Progress', component: <CancelledOrRefund /> },
  { id: 27, path: 'ecom/dashboard/completed', name: 'Ecommerce Dashboard In Progress', component: <CompletedEcomOrders /> },
  { id: 27, path: 'ecom/dashboard/incomplete', name: 'Ecommerce Dashboard In Progress', component: <InCompleteEcomOrders /> },

  //WareHouse Dashboard
  { id: 27, path: 'ecom/shipment/prepare', name: 'Warehouse Dashboard Prepare for Shipment', component: <PrepareForShip /> },
  { id: 27, path: 'ecomorders/unverifiedwarehouse', name: 'Unverified Warehouse Products', component: <WarehouseUnverified /> },

  { id: 27, path: 'ecom/shipment/labelprint', name: 'Warehouse Dashboard Prepare for Shipment', component: <LabelPrint /> },
  { id: 27, path: 'ecom/shipment/pickup', name: 'Warehouse Dashboard Prepare for Shipment', component: <ReadyPickup /> },
  { id: 27, path: 'ecomorders/prepareshipment', name: 'Warehouse Shipment Order View', component: <ShipmentOrderView /> },

  // feddback
  { id: 27, path: 'feedback', name: 'Users Feedbacks', component: <UserFeedBackListing /> },
  { id: 27, path: 'feedback/create', name: 'Users Feedbacks', component: <AddUserFeedback /> },
  { id: 27, path: 'feedback/update', name: 'Users Feedbacks', component: <EditUserFeedback /> },

  { id: 28, path: 'genericbox/listing', name: 'Generic Box Listing', component: <GenericBoxManagement /> },
  { id: 28, path: 'genericbox/create', name: 'Add Generic Box', component: <AddGenericBox /> },
  { id: 27, path: 'genericbox/batchreport', name: 'Generic Box Batch Report', component: <GenericBoxBatchListing /> },

  // box type

  { id: 27, path: 'boxtype/list', name: 'Box Type Listing', component: <BoxTypeListing /> },
  { id: 27, path: 'boxtype/create', name: 'Box Type Add', component: <AddBoxType /> },
  { id: 27, path: 'boxtype/update', name: 'Box Type Update', component: <UpdateBoxType /> },

  // check order

  { id: 27, path: 'dashboard/checktestorder', name: 'Check Order Dashboard', component: <CheckOrder /> },
  { id: 27, path: 'searchorder', name: 'Search Order Screen', component: <SearchOrder /> },

  // return label
  { id: 27, path: 'returnlabel/view', name: 'Check Order Dashboard', component: <LabelListing /> },
  { id: 27, path: 'returnlabel/batchview', name: 'Check Order Dashboard', component: <LabelBatchListing /> },
  { id: 27, path: 'returnlabel/create', name: 'Check Order Dashboard', component: <AddLabel /> },

  // facial scan
  { id: 27, path: 'anura/categories/view', name: 'Facial Scan Categories', component: <AnuraCategories /> },
  { id: 27, path: 'anura/elements/view', name: 'Facial Scan Elements', component: <AnuraElements /> },
  { id: 27, path: 'anura/elements/update', name: 'Facial Scan Elements Update', component: <AnuraElementUpdate /> },

  // lab tests
  { id: 27, path: 'labtest/view', name: 'Lab Test Elements Listing', component: <LabTestList /> },
  { id: 27, path: 'labtest/update', name: 'Lab Test Elements Update', component: <LabTestUpdate /> },

  // lab test groups
  { id: 27, path: 'labtestgroups/view', name: 'Lab Test Groups Listing', component: <GroupListing /> },
  { id: 27, path: 'labtestgroups/update', name: 'Lab Test Groups Add', component: <UpdateGroup /> },
  { id: 27, path: 'labtestgroups/create', name: 'Lab Test Groups Update', component: <AddGroup /> },

  // inventory mapping
  { id: 27, path: 'inventory/mapping', name: 'Inventory Mapping', component: <BindCodes /> },

  // configuration
  { id: 27, path: 'config/view', name: 'Configuration Listing', component: <Confifurations /> },
  // { id: 27, path: 'config/view', name: 'Configuration Listing', component: <StockBalancereport /> },
  // reports
  { id: 27, path: 'reports/view', name: 'stockBalance Listing', component: <StockBalancereport /> },
  { id: 27, path: 'blogs/view', name: 'Blogs Listing', component: <BlogListing /> },
  { id: 27, path: 'blogs/create', name: 'Blogs Create', component: <AddBlog /> },
  // { id: 27, path: 'blogs/create', name: 'Blogs Create', component: <AddBlogwithSelect /> },

  { id: 27, path: 'blogs/edit', name: 'Blogs Edit', component: <EditBlog /> },
  { id: 28, path: 'faqcategory/listing', name: 'Faqs', component: <Faq /> },
  { id: 29, path: 'faqcategory/update', name: 'Faqs', component: <FaqDetails /> },
  { id: 29, path: 'faqcategory/edit', name: 'Faqs', component: <UpdateFaqs /> },

  // Questionnaire for doctor call
  { id: 323, path: 'questions/add', name: 'Add Questionnaire', component: <QuestionaireAdd /> },
  { id: 323, path: 'questions/update', name: 'Edit Questionnaire', component: <QuestionaireEdit /> },
  { id: 323, path: 'questions/listing', name: 'List Questionnaire', component: <QuestionaireListing /> },

  { id: 324, path: 'reqbuttons/create', name: 'Add RequestButtons', component: <RequestButtonAdd /> },
  { id: 324, path: 'reqbuttons/view', name: 'List RequestButtons', component: <RequestListing /> },
  { id: 324, path: 'reqbuttons/update', name: 'List RequestButtons', component: <RequstButtonEdit /> },

  { id: 324, path: 'boxtypeing/view', name: 'List RequestButtons', component: <BoxTypeIngredients /> },
  { id: 324, path: 'ecom/receiveorder', name: 'Receive Back Orders', component: <ReceiveOrder /> },
];
export default allRoutes;
