import { Form, Input, Select } from 'antd';
import { useState } from 'react';

export const SearchBar = ({ onFinish, onFinishFailed, searchItems, onChange }) => {
  // console.log(searchItems);  
  const [selectedSearch, setSelectedSearch] = useState("");
  return (
    <Form
      name='basic'
      initialValues={{
        remember: true,
        ['searchBy']: searchItems[0].value,
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete='off'
      className='row p-3 pb-0'
    >
      <div className='col-md-2 col-6'>
        <Form.Item colon={false} name='searchBy'>
          <Select onChange={(e) => setSelectedSearch(e)} placeholder='Search By'>
            {searchItems.map((item) => (
              <Select.Option value={item.value}>{item.name}</Select.Option>
            ))}
          </Select>
        </Form.Item>
      </div>
      <div className='col-md-2 col-6'>
        <Form.Item name='searchTxt'>
          <Input placeholder={selectedSearch === 'collectionDate' ? 'YYYY-MM-DD' : 'Enter value for search'} onChange={(e) => onChange(e)} />
        </Form.Item>
      </div>
      <div
        className='col-md-3 col-12'
        style={{
          paddingTop: '0.1rem',
        }}
      >
        <Form.Item colon={false}>
          <button type='primary' class='btn btn-primary button ' htmlType='submit'>
            Search
          </button>
        </Form.Item>
      </div>
    </Form>
  );
};
 