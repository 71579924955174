import { useState, useEffect } from 'react';
import ListinngCard from '../../layouts/ListingCard';
import axios from 'axios';
import { app } from '../../config';
import getHeaders from '../../utils/authHeaders';
import { useNavigate } from 'react-router-dom';

const QuestionaireListing = () => {
  const [editbtn, setEditBtn] = useState('none');
  const [search, setSearch] = useState('');
  const [pageSize, setPageSize] = useState(10);
  let navigate = useNavigate();

  const [pagesCount, setPagesCount] = useState(0);
  const [searchShow, setSearchShow] = useState({});
  const [renderList, setRenderList] = useState();
  
  const [currentPage, setCurrentPage] = useState(1);
  const [searchData, setSearchData] = useState();
  const [err, setErr] = useState('');
  const [loader, setLoader] = useState(false);
 

  const tableHeaders=["Product Name", "Test Name", ""]
  const ListingApi = async (formData) => {
    setLoader(true);
    const res = await axios.post(`${app.URL}/api/v1/admin/questions/listing`, formData, { headers: getHeaders() });
    res.data.result ? setPagesCount(res.data.result.data.count) : ' ';
    res.data.result ? setRenderList(res.data.result.data.data) : ' ';
    res.data.result && setLoader(false);
  };
  const formData = {
    limit: pageSize,
    searchBy: 'productName',
    offset: 0,
    searchTxt: '',
  };
  useEffect(() => {
    ListingApi(formData);
  }, []);

  let searchItems = [
    {
      name: 'Product',
      value: 'productName',
    },
  ];

  const onChange = async (page, pageSize) => {
    setPageSize(pageSize);
    setCurrentPage(page);
    if (!page) page = 1;
    let limit = pageSize;
    try {
      let reqObj;
      if (Object.keys(searchShow).length !== 0) {
        reqObj = {
          limit: limit,
          offset: limit * (page - 1),
          searchBy: searchShow.searchBy,
          searchTxt: searchShow.searchTxt,
        };
      } else {
        reqObj = {
          limit: limit,
          offset: limit * (page - 1),
          searchBy: 'productName',
          searchTxt: '',
        };
      }

      await ListingApi(reqObj);
    } catch (e) {
      console.log('Error');
      if (e.message) setErr(e.message);
      
    }
  };

  const onFinish = async (values) => {
    setSearchShow(values);
    let formData = {
      limit: 10,
      offset: 0,
      searchBy: values.searchBy,
      searchTxt: values.searchTxt,
    };
    if (!formData.searchTxt) message.error('Please enter search text');
    else await ListingApi(formData);
  };
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const editQuestion = (e) => {
    console.log(e.productId);
    localStorage.setItem('productId', JSON.stringify(e.productId));
    navigate('/questions/update', { replace: true });
  };
  const addQuestion = () => {
    navigate('/questions/add', { replace: true });
  };

  console.log(currentPage, pagesCount);

  console.log(renderList);
  const handleOnChange = (e) => {
    if (e.target.value.length === 0) {
      ListingApi(formData);
      setSearchShow({});
    }
  };
  console.log(renderList)
  return (
    <ListinngCard
      heading='Product Questionnaire'
      buttonName='Add Questionnaire'
      addButton={addQuestion}
      onSearch={onFinish}
      onSearchFailed={onFinishFailed}
      searchItems={searchItems}
      tableHeaders={tableHeaders}
      tableRows={renderList}
      editButton={editQuestion}
      onPageChange={onChange}
      currentPage={currentPage}
      pagesCount={pagesCount}
      patientsListing={false}
      inventoryListing={false}
      storeListing={false}
      productListing={false}
      userListings={false}
      questionnaireListing={true}
      searchValue={searchData}
      onChange={handleOnChange}
      loader={loader}
    />
  );
};

export default QuestionaireListing;
