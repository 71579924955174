import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { message, Switch, Select, Form, Input, Row, Col, DatePicker, InputNumber, Tooltip, Upload } from 'antd';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { app } from '../../config';
import useFetchRoles from '../../hooks/fetchRoles';
import { InfoCircleOutlined, LeftCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import InputMask from 'react-input-mask';
import getHeaders from '../../utils/authHeaders';
import AddPhoto from '../../assets/images/partner.png';

import useFetchCities from '../../hooks/fetchCities';
import useFetchStates from '../../hooks/fetchStates';
import fetchEthinicity from '../../hooks/fetchEthinicity';
import { AddRecord } from '../../layouts/AddRecord';
import { InputField, InputMasker, SelectField, TextAreaField } from '../../layouts/FormFields';
import { noPrespace, noPrespaceNoAlphabetsNoSpecialChar } from '../user/ValidationFunctions';
// console.log(app);
const { TextArea } = Input;

const AddPatient = () => {
  const { t } = useTranslation(['common']);
  const [phoneNumber, setPhoneNumber] = useState();
  const [addUserResp, setAddUserResp] = useState({});
  const [noImage, setNoImage] = useState('');
  const [imageuploading, setImageUploading] = useState(false);
  const [uploadedImg, setUploadedImg] = useState('');
  const [uploadLogo, setUploadLogo] = useState(true);
  const [loading, setLoading] = useState(false);

  const [pwd, setpwd] = useState('');
  const [confirmPwd, setconfirmPwd] = useState('');
  const [show, setShow] = useState(false);
  // const [roles, setRoles ] = useSt
  let navigate = useNavigate();
  let user = JSON.parse(localStorage.getItem('user'));
  const fetchedRoles = useFetchRoles();
  const roles = fetchedRoles.response ? fetchedRoles.response.data : [];
  const [uploadObj, setUploadObj] = useState({});
  let header = getHeaders();

  const cityResponse = useFetchCities();
  // const cities = cityResponse.response ? cityResponse.response.data : [];
  const stateResponse = useFetchStates();
  const states = stateResponse.response ? stateResponse.response.data : [];
  const ethicityList = fetchEthinicity();
  // const [tooltip, setTooltip] = useState('');
  const ethicity = ethicityList.response ? ethicityList.response.data : [];

  const uploadData = async (userDetails) => {
    console.log(userDetails);
    setLoading(true);
let userId = user?.result.data.user.userId;
userDetails.userId = userId;
    try {
      console.log('uploadObj ', userDetails);
      const res = await axios.post(`${app.URL}/api/v1/admin/patient/create`, userDetails, { headers: getHeaders() });
      setLoading(false);
      setShow(false);
      if (res.data.result.success) {
        setAddUserResp(res.data.result.data);
        message.success(res.data.result.msg);
        setTimeout(goBack, 1000);
      } else if (!res.data.result.success) {
        message.warning(res.data.result.msg);
      }
    } catch (err) {
      setLoading(false);
      setShow(false);
      console.log(err);
      if (err) console.log(err.response.data.message);
      else return;
    }
  };

  const [zipInput, setZipInput] = useState('');

  const handleInputZip = (e) => {
    // setTooltip('');
    const inputValue = e.target.value;
    if (inputValue.length <= 5) {
      setZipInput(inputValue);
      // setTooltip('');
    }
    // if (inputValue.includes('0000') || inputValue.includes('00000')) {
    //   setTooltip('0000 or 00000 not valid zip');
    // }
  };

  const handleChange = (event) => {
    setPhoneNumber(event.target.value);
    // handle phone number input here
  };
  const onFinish = (values) => {
    setUploadObj({
      firstName: values.firstName ? values.firstName : null,
      middleName: values.middleName ? values.middleName : '',
      lastName: values.lastName ? values.lastName : null,
      streetAddress: values.streetAddress ? values.streetAddress : null,
      stateId: values.state ? values.state : null,
      mobile: values.phone ? values.phone : '',
      dateOfBirth: values.birthDate ? values.birthDate.format().split('T')[0] : '',
      gender: values.gender ? values.gender : null,
      city: values.city ? values.city : null,
      email: values.email ? values.email : null,
      zipCode: zipInput ? zipInput : null,
      martialStatus: values.maritalStatus ? values.maritalStatus : null,
      ethnicId: values.ethnicId ? values.ethnicId : null,
      // organizationId: values.organizationId ? values.organizationId : null,
      isActive: values.isActive,
      // userType: 'O',
    });
    setShow(true);
  };
  // console.log(uploadObj);

  function validatePhoneNumber(phoneNumber) {
    // Regular expression for US phone numbers with optional area code and dashes
    const phoneRegex = /^\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/;

    // Test the phone number against the regex pattern
    return phoneRegex.test(phoneNumber);
  }

  const submitData = () => {
    console.log('calling');
    // console.log(phoneNumber?.split('_')[0].length);

    if (phoneNumber?.split('_')[0].length < 12 || !phoneNumber?.startsWith('1')) {
      setShow(false);
      message.error('Invalid mobile number');
    } else if (zipInput.includes('0000') && zipInput.length === 4) {
      setShow(false);
      message.error('0000 zip code is not acceptable!');
    } else if (zipInput.includes('00000')) {
      setShow(false);
      message.error('00000 zip code is not acceptable!');
    } else if (!validatePhoneNumber(phoneNumber)) {
      message.error('Phone number should contain US state codes!');
    }
    else {setShow(false); uploadData(uploadObj);} 
  };
  const handleClose = () => setShow(false);
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  function goBack() {
    navigate('/patient/listing', { replace: true });
  }
  window.addEventListener('popstate', goBack);

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
  };
  const handleuploadChange = async (info) => {
    if (info.file.status === 'uploading') {
      setImageUploading(true);
      setLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      info.file ? console.log(info.file.response.result.data.Location) : console.log('Not found');
      info.file ? setUploadedImg(info.file.response.result.data.Location) : ' ';
      getBase64(info.file.originFileObj, (url) => {
        setImageUploading(false);
        setLoading(false);
        setUploadLogo(false);
        setNoImage('');
      });
    }
  };
const handleKeyPress = (e) => {
  console.log(e);
  noPrespaceNoAlphabetsNoSpecialChar(e);
};
  return (
    <AddRecord
      loading={loading}
      pageTitle='Add Customer'
      addUserResp={addUserResp}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      switchBtn={true}
      upload={false}
      show={show}
      handleClose={handleClose}
      submitData={submitData}
      addMessage='Are you sure you want to add customer?'
      goBack={goBack}
      initialValues={{
        remember: true,
        ['isActive']: true,
      }}
      inputs={[
        <InputField
          name='firstName'
          required={true}
          label='First Name'
          placeholder='First Name'
          col='col-md-4 col-12'
          rules={[
            {
              required: true,
              message: 'First Name',
            },
          ]}
          onKeyPress={handleKeyPress}
        />,
        <InputField
          name='middleName'
          // required={true}
          label='Middle Name'
          placeholder='Middle Name'
          col='col-md-4 col-12'
          // rules={[
          //   {
          //     required: true,
          //     message: 'Middle Name',
          //   },
          // ]}
          onKeyPress={handleKeyPress}
        />,
        <InputField
          name='lastName'
          required={true}
          label='Last Name'
          placeholder='Last Name'
          col='col-md-4 col-12'
          rules={[
            {
              required: true,
              message: 'Last Name',
            },
          ]}
          onKeyPress={handleKeyPress}
        />,
        <InputField
          name='streetAddress'
          required={true}
          // rows={1}
          // showCount={true}
          // maxLength={1000}
          label='Address'
          placeholder='Address'
          col='col-12'
          rules={[
            {
              required: true,
              message: 'Street Address',
            },
          ]}
        />,
        <InputField
          name='city'
          required={true}
          label='City'
          placeholder='City'
          col='col-md-4 col-12'
          rules={[
            {
              required: true,
              message: 'Enter City',
            },
          ]}
          onKeyPress={handleKeyPress}
        />,
        <div className='col-md-4 col-12'>
          <label>
            ZIP Code <span className='required-star'>*</span>
          </label>

          <Input required={true} value={zipInput} onChange={handleInputZip} className='num-input' type='number' maxLength={5} placeholder='ZIP Code' />
        </div>,
        <div className='col-md-4 col-12'>
          <label>
            Date of Birth <span className='required-star'>*</span>
          </label>
          <Form.Item
            name='birthDate'
            rules={[
              {
                required: true,
                message: 'Select a date',
              },
            ]}
          >
            <DatePicker placeholder='Date of birth' format={'MM/DD/YYYY'} />
          </Form.Item>
        </div>,
        <InputField
          name='email'
          required={true}
          label='Email'
          placeholder='Email'
          col='col-md-4 col-12'
          onKeyPress={noPrespace}
          rules={[
            {
              required: true,
              message: 'Enter Email',
            },
          ]}
        />,
      ]}
      selects={[
        <SelectField
          options={states.map((item) => (
            <Select.Option value={item.stateId}>{item.name}</Select.Option>
          ))}
          col='col-md-4 col-12'
          placeholder='Select State'
          rules={[
            {
              required: true,
              message: 'Select State',
            },
          ]}
          label='State'
          name='state'
          required={true}
        />,
        <SelectField
          options={
            <>
              <Select.Option value='M'>Married</Select.Option>
              <Select.Option value='S'>Single</Select.Option>
              <Select.Option value='D'>Divorced</Select.Option>
              <Select.Option value='W'>Widowed</Select.Option>
            </>
          }
          col='col-md-4 col-12'
          placeholder='Select Marital Status'
          label='Marital Status'
          name='maritalStatus'
        />,
        <SelectField
          options={
            <>
              <Select.Option value='M'>Male</Select.Option>
              <Select.Option value='F'>Female</Select.Option>
              {/* <Select.Option value='O'>Other</Select.Option> */}
            </>
          }
          col='col-md-4 col-12'
          placeholder='Select Gender'
          label='Gender at Birth'
          name='gender'
        />,
        // <SelectField
        //   options={ethicity.map((item) => (
        //     <Select.Option value={item.ethnicId}>{item.verticleType}</Select.Option>
        //   ))}
        //   col='col-lg-4'
        //   placeholder='Select Ethnicity'
        //   label='Ethnicity'
        //   name='ethnicId'
        // />,
      ]}
      inputMask={[
        <InputMasker
          name='phone'
          required={true}
          label='Mobile'
          placeholder='Mobile'
          col='col-md-4 col-12'
          rules={[
            {
              required: true,
              message: 'Enter Mobile',
            },
          ]}
          handleChange={handleChange}
        />,
      ]}
    />
  );

  // return (
  //   <>
  //     <div className='col-lg-12'>
  //       <div className='dashboard-card'>
  //         <div className='row' style={{ paddingRight: '10px' }}>
  //           <div className='for-before col-lg-4 head fs-3 fw-bold' style={{ backgroundColor: '#a7c1d9', borderBottomRightRadius: '10px', color: '#FFFFFF' }}>
  //             Add Customer
  //           </div>
  //         </div>
  //         <div class='col mb-5 mt-4'>
  //           <div class='col-lg-12 align-self-center mt-2 mb-4' style={{ padding: '20px 50px' }}>
  //             <Form
  //               onFinish={onFinish}
  //               onFinishFailed={onFinishFailed}
  //               autoComplete='off'
  //               initialValues={{
  //                 remember: true,
  //                 ['isActive']: true,
  //               }}
  //             >
  //               {/* Select box for Role */}

  //               <div className='row'>
  //                 <div className='col-lg-4'>
  //                   <label>
  //                     First Name <span className='required-star'>*</span>
  //                   </label>
  //                   <Form.Item
  //                     name='firstName'
  //                     defaultValue='1'
  //                     colon={false}
  //                     rules={[
  //                       {
  //                         required: true,
  //                         message: 'Frist Name',
  //                       },
  //                     ]}
  //                   >
  //                     <Input placeholder='First Name' />
  //                   </Form.Item>
  //                 </div>
  //                 <div className='col-lg-4'>
  //                   <label>Middle Initial</label>
  //                   <Form.Item name='middleName' defaultValue='' colon={false}>
  //                     <Input placeholder='Middle Name' />
  //                   </Form.Item>
  //                 </div>
  //                 <div className='col-lg-4'>
  //                   <label>
  //                     Last Name <span className='required-star'>*</span>
  //                   </label>
  //                   <Form.Item
  //                     name='lastName'
  //                     defaultValue='1'
  //                     colon={false}
  //                     rules={[
  //                       {
  //                         required: true,
  //                         message: 'Last Name',
  //                       },
  //                     ]}
  //                   >
  //                     <Input placeholder='Last Name' />
  //                   </Form.Item>
  //                 </div>
  //                 {/* <div className='col-lg-3 mt-4'>
  //                   <div className='col-lg-9'>
  //                     <Form.Item
  //                       valuePropName='fileList'
  //                       rules={[
  //                         {
  //                           required: true,
  //                           message: 'Upload Image',
  //                         },
  //                       ]}
  //                     >
  //                       <Upload listType='picture-card' className='avatar-uploader' showUploadList={false} action={`${app.URL}/api/v1/general/banner/imageupload`} beforeUpload={beforeUpload} onChange={handleuploadChange} headers={header}>
  //                         {uploadedImg && !imageuploading ? (
  //                           <img className='' src={uploadedImg} alt='avatar' />
  //                         ) : !uploadedImg && imageuploading ? (
  //                           <LoadingOutlined style={{ fontSize: '40px' }} />
  //                         ) : (
  //                           <>
  //                             <img className='for-add-img' src={AddPhoto} alt='avatar' />
  //                             <p className='add-text'>Upload Image</p>
  //                           </>
  //                         )}
  //                       </Upload>
  //                       {noImage && (
  //                         <div
  //                           style={{
  //                             color: 'red',
  //                           }}
  //                         >
  //                           {noImage}
  //                         </div>
  //                       )}
  //                     </Form.Item>
  //                   </div>
  //                 </div> */}
  //               </div>

  //               <div className='row'>
  //                 <div className='col-lg-12'>
  //                   <label>
  //                     Street Address <span className='required-star'>*</span>
  //                   </label>
  //                   <Form.Item
  //                     name='streetAddress'
  //                     defaultValue='1'
  //                     colon={false}
  //                     style={{ wordWrap: 'normal' }}
  //                     rules={[
  //                       {
  //                         required: true,
  //                         message: 'Enter Address',
  //                       },
  //                     ]}
  //                   >
  //                     <Input placeholder='Address' />
  //                   </Form.Item>
  //                 </div>
  //               </div>

  //               <div className='row'>
  //                 <div className='col-lg-4'>
  //                   <label>
  //                     City <span className='required-star'>*</span>
  //                   </label>
  //                   <Form.Item
  //                     name='city'
  //                     defaultValue='1'
  //                     colon={false}
  //                     rules={[
  //                       {
  //                         required: true,
  //                         message: 'Enter City',
  //                       },
  //                     ]}
  //                   >
  //                     <Input placeholder='Enter City' />
  //                   </Form.Item>
  //                 </div>
  //                 <div className='col-lg-4'>
  //                   <label>
  //                     State <span className='required-star'>*</span>
  //                   </label>
  //                   <Form.Item
  //                     name='state'
  //                     defaultValue='1'
  //                     colon={false}
  //                     rules={[
  //                       {
  //                         required: true,
  //                         message: 'Enter State',
  //                       },
  //                     ]}
  //                   >
  //                     <Select placeholder='Select State'>
  //                       {states.map((item) => (
  //                         <Select.Option value={item.stateId}>{item.name}</Select.Option>
  //                       ))}
  //                     </Select>
  //                   </Form.Item>
  //                 </div>
  //                 <div className='col-lg-4'>
  //                   <label>
  //                     ZIP Code <span className='required-star'>*</span>
  //                   </label>
  //                   {/* <Form.Item
  //                     name='zipCode'
  //                     defaultValue='1'
  //                     colon={false}
  //                     rules={[
  //                       {
  //                         required: true,
  //                         message: 'Enter Zip-code',
  //                       },
  //                     ]}
  //                   > */}
  //                   {/* <Tooltip title={tooltip}> */}
  //                   <Input required={true} value={zipInput} onChange={handleInputZip} className='num-input' type='number' maxLength={5} placeholder='ZIP Code' />
  //                   {/* </Tooltip> */}

  //                   {/* </Form.Item> */}
  //                 </div>
  //               </div>

  //               <div className='row'>
  //                 <div className='col-lg-4'>
  //                   <label>
  //                     Date of Birth <span className='required-star'>*</span>
  //                   </label>
  //                   <Form.Item
  //                     name='birthDate'
  //                     rules={[
  //                       {
  //                         required: true,
  //                         message: 'Select a date',
  //                       },
  //                     ]}
  //                   >
  //                     {/* <RangePicker onChange={(val) => console.log(val)} /> */}
  //                     <DatePicker
  //                       placeholder='Date of birth'
  //                       format={'MM/DD/YYYY'}

  //                       //   onChange={(date, dateString) => {
  //                       //     console.log(dateString);
  //                       //   }}
  //                     />
  //                     {/* <Input type="date"  onChange={(e)=> setIssueDate(e.target.value)}/> */}
  //                   </Form.Item>
  //                 </div>
  //                 <div className='col-lg-4'>
  //                   <label>
  //                     Mobile <span className='required-star'>*</span>
  //                   </label>
  //                   <Form.Item
  //                     colon={false}
  //                     name='phone'
  //                     rules={[
  //                       {
  //                         required: true,
  //                         message: 'Enter mobile phone number',
  //                       },
  //                     ]}
  //                   >
  //                     <InputMask mask='999 999-9999' onChange={handleChange}>
  //                       {(inputProps) => <Input {...inputProps} placeholder='Mobile Number' style={{ width: '100% !important' }} />}
  //                     </InputMask>
  //                   </Form.Item>
  //                 </div>
  //                 <div className='col-lg-4'>
  //                   <label>
  //                     Email <span className='required-star'>*</span>
  //                   </label>
  //                   <Form.Item
  //                     colon={false}
  //                     name='email'
  //                     rules={[
  //                       {
  //                         required: true,
  //                         type: 'email',
  //                         message: 'Enter email',
  //                       },
  //                     ]}
  //                   >
  //                     <Input placeholder='Enter Email' className='admin' style={{ width: '100% !important' }} />
  //                   </Form.Item>
  //                 </div>
  //               </div>

  //               <div className='row'>
  //                 <div className='col-lg-4'>
  //                   <label>Marital Status</label>
  //                   <Form.Item
  //                     colon={false}
  //                     name='maritalStatus'
  //                     // rules={[
  //                     //   {
  //                     //     required: true,
  //                     //     message: 'Enter Ad. Title',
  //                     //   },
  //                     // ]}
  //                   >
  //                     {/* onChange={getProjectData} */}
  //                     <Select placeholder='Select Marital Status'>
  //                       <Select.Option value='M'>Married</Select.Option>
  //                       <Select.Option value='S'>Single</Select.Option>
  //                       <Select.Option value='D'>Divorced</Select.Option>
  //                       <Select.Option value='W'>Widowed</Select.Option>
  //                       {/* {searchItems.map((item) => (
  //                       <Select.Option value={item}>{item}</Select.Option>
  //                     ))} */}
  //                     </Select>
  //                     {/* <Input placeholder='Enter Title' /> */}
  //                   </Form.Item>
  //                 </div>
  //                 <div className='col-lg-4'>
  //                   <label>Gender </label>
  //                   <Form.Item
  //                     colon={false}
  //                     name='gender'
  //                     rules={[
  //                       {
  //                         required: false,
  //                         message: 'Select a gender',
  //                       },
  //                     ]}
  //                   >
  //                     {/* onChange={getProjectData} */}
  //                     <Select placeholder='Select Gender'>
  //                       <Select.Option value='M'>Male</Select.Option>
  //                       <Select.Option value='F'>Female</Select.Option>
  //                       <Select.Option value='O'>Other</Select.Option>
  //                       {/* {searchItems.map((item) => (
  //                       <Select.Option value={item}>{item}</Select.Option>
  //                     ))} */}
  //                     </Select>
  //                     {/* <Input placeholder='Enter Title' /> */}
  //                   </Form.Item>
  //                 </div>
  //                 <div className='col-lg-4'>
  //                   <label>Ethnicity</label>
  //                   <Form.Item
  //                     colon={false}
  //                     name='ethnicId'
  //                     // rules={[
  //                     //   {
  //                     //     required: true,
  //                     //     message: 'Enter Ad. Title',
  //                     //   },
  //                     // ]}
  //                   >
  //                     {/* onChange={getProjectData} */}
  //                     <Select placeholder='Select Ethnicity'>
  //                       {ethicity.map((item) => (
  //                         <Select.Option value={item.ethnicId}>{item.verticleType}</Select.Option>
  //                       ))}
  //                     </Select>
  //                     {/* <Input placeholder='Enter Title' /> */}
  //                   </Form.Item>
  //                 </div>
  //               </div>

  //               <div className='row'>
  //                 <div className='col-lg-12'>
  //                   <Form.Item label='Active' colon={false} valuePropName='checked' name='isActive'>
  //                     <Switch />
  //                   </Form.Item>

  //                   <button
  //                     onClick={() => {
  //                       navigate('/patient/listing');
  //                     }}
  //                     type='primary'
  //                     className='btn btn-primary button patient-cancel-btn  col-lg-3 mr-2'
  //                   >
  //                     Cancel
  //                   </button>

  //                   <button type='primary' className='btn btn-primary button col-lg-3' htmlType='submit'>
  //                     Save
  //                   </button>
  //                 </div>
  //                 <div className='col-lg-4 offset-lg-2'>
  //                   <Form.Item
  //                   // wrapperCol={{
  //                   //   offset: 8,
  //                   //   span: 16,
  //                   // }}
  //                   >
  //                     {addUserResp.code === 200 ? (
  //                       <Modal show={show} onHide={handleClose}>
  //                         <Modal.Header closeButton>
  //                           <Modal.Title className='text-center'>Success</Modal.Title>
  //                         </Modal.Header>
  //                         <Modal.Body>{addUserResp.message}</Modal.Body>
  //                         <Modal.Footer></Modal.Footer>
  //                       </Modal>
  //                     ) : addUserResp.code === 400 ? (
  //                       <Modal show={show} onHide={handleClose}>
  //                         <Modal.Header closeButton>
  //                           <Modal.Title className='text-center'>Failed</Modal.Title>
  //                         </Modal.Header>
  //                         <Modal.Body>{addUserResp.data.message}</Modal.Body>
  //                         <Modal.Footer></Modal.Footer>
  //                       </Modal>
  //                     ) : (
  //                       <Modal show={show} onHide={handleClose}>
  //                         {/* <Modal.Header closeButton>
  //                           <Modal.Title>Confirmation</Modal.Title>
  //                         </Modal.Header> */}
  //                         <Modal.Body>Are you sure to add the customer information?</Modal.Body>
  //                         <Modal.Footer>
  //                           <Button variant='secondary' onClick={handleClose}>
  //                             Cancel
  //                           </Button>
  //                           <Button variant='primary' htmlType='submit' onClick={submitData}>
  //                             Confirm
  //                           </Button>
  //                         </Modal.Footer>
  //                       </Modal>
  //                     )}
  //                   </Form.Item>
  //                 </div>
  //               </div>
  //             </Form>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   </>
  // );
};

export default AddPatient;
