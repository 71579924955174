import React from 'react';
import Modal from 'react-bootstrap/Modal';

const AvailableProductViewModal = ({ isVisible, setIsVisible, orderDetails }) => {
  return (
    <Modal show={isVisible} onHide={() => setIsVisible(false)} centered size='lg'>
      <Modal.Header closeButton>
        <Modal.Title>Product Box Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='mb-4'>
          <table id='customers' className='w-100'>
            <thead>
              <tr
                style={{
                  fontWeight: 'bold',
                }}
              >
                <th>Product Name</th>
                <th>Generic Box</th>
                <th>Product Serial</th>
                <th>Return Label Tracking</th>
                <th>Creation Date</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ width: '100px' }}>{orderDetails.productName || 'N/A'}</td>
                <td style={{ width: '100px' }}>{orderDetails.genericBoxSerial || 'N/A'}</td>
                <td style={{ width: '100px' }}>{orderDetails.productSerial || 'N/A'}</td>
                <td style={{ width: '100px' }}>{orderDetails.returnLabelTracking || 'N/A'}</td>
                <td style={{ width: '100px' }}>{new Date(orderDetails.createdAt).toLocaleDateString()}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          variant='primary'
          className='btn btn-primary'
          style={{
            textAlign: 'left',
          }}
          onClick={() => setIsVisible(false)}
        >
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default AvailableProductViewModal;
