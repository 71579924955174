import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { app } from '../../config';
import getHeaders from '../../utils/authHeaders';
import { useNavigate } from 'react-router-dom';
import { UpdateRecord } from '../../layouts/UpdateRecord';
import { InputField, SelectField, TextAreaField } from '../../layouts/FormFields';
import { Button, Divider, Form, Select, Space, Switch, Upload, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import DividerLine from '../../layouts/DividerLine';
import TestComponent from '../Products/TestComponent';

const AnuraElementUpdate = () => {
  let navigate = useNavigate();
  function goBack() {
    navigate('/anura/elements/view', { replace: true });
  }
  window.addEventListener('popstate', goBack);
  const [loader, setLoader] = useState(false);
  const [addUserResp, setAddUserResp] = useState({});
  const [anuraObj, setAnuraObj] = useState([]);
  const [anuraCat, setAnuraCat] = useState([]);
  const [uploadObj, setUploadObj] = useState({});
  const [show, setShow] = useState(false);
  // const [isAnuraVideo, setIsAnuraVideo] = useState(false);
  const [uploadVideo, setuploadVideo] = useState('');
  const [contentType, setContentType] = useState();
  const [uplVid, setuplVid] = useState(true);
  const [contentDis, setContentDis] = useState('')
  let user = JSON.parse(localStorage.getItem('user'));
  let [textEditorValue, settextEditorValue] = useState();


  

  const getAnuraElementCatListing = async () => {
    const res = await axios.get(`${app.URL}/api/v1/admin/anura/categories/view?isActive=1`, { headers: getHeaders() });
    console.log(res);
    res.data.result.success && setAnuraCat(res.data.result.data);
  };

  const getAnuraElementListing = async (paramRangeId) => {
    setLoader(true);
    const res = await axios.get(`${app.URL}/api/v1/admin/anura/elements/viewsingle/${paramRangeId}`, { headers: getHeaders() });
    console.log(res);
    res.data.result.success && setAnuraObj(res.data.result.data);
    res.data.result.success && settextEditorValue(res.data.result.data.description);
    res.data.result.success && setContentDis(res.data.result.data.contentDisplay);
    res.data.result.success && getAnuraElementCatListing();
    // res.data.result.data.isVideo && setIsAnuraVideo(true);
    res.data.result.data.videoURL && setuploadVideo(res.data.result.data.videoURL);
    // videoUrl={uploadVideo ? uploadVideo : anuraObj?.videoURL}
    setLoader(false);
  };

  useEffect(() => {
    const paramRangeId = localStorage.getItem('paramRangeId');
    getAnuraElementListing(paramRangeId);
  }, []);

  const putAnuraElementListing = async (details) => {
    // console.log(details);
    // return;
    setLoader(true);
    let userId = user?.result.data.user.userId;
    details.userId = userId;
    try {
      const res = await axios.post(`${app.URL}/api/v1/admin/anura/elements/update/${anuraObj.paramRangeId}`, details, { headers: getHeaders() });
      setLoader(false);
      setShow(false);
      console.log(res);

      if (res.data.result.success) {
        setAddUserResp(res.data.result.data);
        message.success(res.data.result.msg);
        setTimeout(goBack, 500);
      } else if (!res.data.result.success) {
        message.warning(res.data.result.msg);
      }
    } catch (err) {
      console.log(err);
      setLoader(false);
      setShow(false);
      if (err) console.log(err.response.data.message);
      else return;
    }
  };
  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    // reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  };
  const beforeUpload = (file) => {
    console.log(file);
    setuploadVideo('');
    if (file.type === 'image/jpeg' || file.type === 'image/png') {
      console.log('setting image type');
      setContentType('img');
    } else if (file.type === 'video/x-matroska' || file.type === 'video/mp4') setContentType('video');
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'video/x-matroska' || file.type === 'video/mp4';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    console.log('file Checked');
    const isLt2M = file.size / 1024 / 1024 < 20;
    if (!isLt2M) {
      setLargeFile(true);
      message.error('Image must smaller than 20MB!');
    }
    console.log('message');
    return isJpgOrPng && isLt2M;
  };

  const handleuploadChange = (info) => {
    // console.log("info", info)
    if (info.file.status === 'uploading') {
      // setLoader(true);
      return;
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      console.log(info.file);
      info.file ? console.log(info.file.response.result.data.Location) : console.log('Not found');
      info.file ? setuploadVideo(info.file.response.result.data.Location) : ' ';
      getBase64(info.file.originFileObj, (url) => {
        // setLoader(false);
        setuplVid(false);
      });
    }
  };

  const onFinish = (values) => {
    console.log(values);
    console.log(uploadVideo);
    setUploadObj({
      anuraParamName: values.anuraParamName ? values.anuraParamName : null,
      anuraGroup: values.anuraGroupId ? values.anuraGroupId : null,
      displayPosition: values.displayPosition ? values.displayPosition : '',
      contentDisplay: values.contentDisplay ? values.contentDisplay : null,
      isActive: values.isActive ? 1 : 0,
      // description: values.description ? values.description : null,
      description: textEditorValue,

      videoURL: uploadVideo ? uploadVideo : anuraObj.videoURL ? anuraObj.videoURL : null,
      // isVideo: values.isVideo ? 1 : 0,
    });
    setShow(true);
  };
  const submitData = () => {
    console.log(uploadObj);
    if (Object.keys(uploadObj).length > 0) {
      putAnuraElementListing(uploadObj);
    }
  };
  const onFinishFailed = () => {};

  // console.log(anuraCat);

  const anuraVideoToggle = () => {
    setuploadVideo('')
    setTimeout(() => setuploadVideo(anuraObj.videoURL), 500);
  };


  const handleContentDisplay = (event) => {
    console.log(event)
    setContentDis(event)
  }

   const handleEditorChange = (htmlContent) => {
     settextEditorValue(htmlContent);
     // Do something with the htmlContent, such as saving it to state or sending it to an API
   };

  return (
    <UpdateRecord
      pageTitle='Update Facial Scan Panel Test'
      loading={loader}
      response={anuraObj}
      goBack={goBack}
      upload={false}
      show={show}
      handleClose={() => setShow(false)}
      addUserResp={addUserResp}
      submitData={submitData}
      updateMessage='Are you sure you want to update facial scan panel test?'
      initialValues={{
        remember: true,
        ['anuraParamId']: anuraObj?.anuraParamId,
        ['anuraParamName']: anuraObj?.anuraParamName,
        ['anuraGroupId']: anuraObj?.anuraGroupId,
        ['contentDisplay']: anuraObj?.contentDisplay,
        ['displayPosition']: anuraObj?.displayPosition,
        ['unit']: anuraObj?.unit,
        ['isActive']: anuraObj?.isActive,
        ['description']: anuraObj?.description,
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      switchBtn={false}
      inputs={[
        <SelectField
          col='col-lg-6 col-md-4 col-12'
          name='anuraGroupId'
          rules={[
            {
              required: true,
              message: 'Select Param Group',
            },
          ]}
          placeholder='Param Group'
          label='Category'
          required={true}
          options={anuraCat.map((item) => (
            <Select.Option value={item.anuraGroupId}>{item.anuraGroup}</Select.Option>
          ))}
        />,
        <InputField col='col-lg-6 col-md-4 col-12' name='anuraParamId' disabled={true} label='ID' />,
        <InputField
          col='col-lg-6 col-md-4 col-12'
          name='anuraParamName'
          rules={[
            {
              required: true,
              message: 'Enter Param Name',
            },
          ]}
          placeholder='Param Name'
          label='Name'
          required={true}
        />,
        <InputField col='col-md-2 col-3' name='unit' disabled={true} placeholder='Unit' label='Unit' />,

        <InputField
          col='col-lg-3 col-md-4 col-6'
          name='displayPosition'
          rules={[
            {
              required: true,
              message: 'Enter Display Postion',
            },
          ]}
          required={true}
          label='Display Position'
        />,
        <div className='col-md-3 col-6 my-2 '>
          <Form.Item label='Active' className='selector-main' colon={false} valuePropName='checked' name='isActive'>
            <Switch />
          </Form.Item>
        </div>,
        <DividerLine />,
        <div className='col-lg-8'>
          <div className='row'>
            <SelectField
              col=' col-md-4 col-12'
              name='contentDisplay'
              onChange={(e) => handleContentDisplay(e)}
              options={[
                { value: 'none', name: 'None' },
                { value: 'video', name: 'Video' },
                { value: 'text', name: 'Text' },
                { value: 'both', name: 'Both' },  
              ].map((item) => (
                <Select.Option value={item.value}>{item.name}</Select.Option>
              ))}
              rules={[
                {
                  required: true,
                  message: 'Select Content Display',
                },
              ]}
              required={true}
              label='Show Description'
            />
          </div>
        </div>,
        <div className='col-md-8'>
          <TestComponent title='Description' onChange={handleEditorChange} textvalue={textEditorValue} />
        </div>,
        // <TextAreaField showCount={true} maxLength={2500} col='col-lg-8' name='description' placeholder='Enter description' label='Description' rows={8} />,
        <div className='col-lg-4 col-md-8 col-12 mb-lg-0 mb-2'>
          {uploadVideo && <label>Video</label>}
          {uploadVideo && (
            <video style={{ width: '100%', borderRadius: '5px', boxShadow: '3px 3px 10px #ccc' }} controls title='YouTube video player'>
              <source src={uploadVideo} type='video/mp4'></source>
            </video>
          )}
          <Form.Item colon={false} name='video' className='mt-2'>
            <Space
              direction='vertical'
              style={{
                width: '100%',
              }}
              size='large'
            >
              <Upload onRemove={anuraVideoToggle} listType='picture' maxCount={1} className='avatar-uploader' headers={getHeaders()} action={`${app.URL}/api/v1/general/product/videoUpload`} beforeUpload={beforeUpload} onChange={handleuploadChange}>
                <Button icon={<UploadOutlined />} className={'upload-btn'} style={{ color: 'black' }}>
                  Upload Video
                </Button>
                {/* <div className='mt-2'>Note: Upload size for video is 50mb supported format for video are (mkv/mp4)</div> */}
              </Upload>
            </Space>
          </Form.Item>
        </div>,
        // <div>
        //   {contentDis === 'text' ? (
        //     <TextAreaField showCount={true} maxLength={1000} col='col-lg-8' name='description' placeholder='Enter description' label='Description' rows={3} />
        //   ) : contentDis === 'video' ? (
        //     <div className='col-md-8'>
        //       <Form.Item colon={false} name='video'>
        //         <Space
        //           direction='vertical'
        //           style={{
        //             width: '100%',
        //           }}
        //           size='large'
        //         >
        //           <Upload onRemove={anuraVideoToggle} listType='picture' maxCount={1} className='avatar-uploader' headers={getHeaders()} action={`${app.URL}/api/v1/general/product/videoUpload`} beforeUpload={beforeUpload} onChange={handleuploadChange}>
        //             <Button icon={<UploadOutlined />} className={'upload-btn'} style={{ color: 'black' }}>
        //               Upload (Max: 1)
        //             </Button>
        //             <div className='mt-2'>Note: Upload size for video is 50mb supported format for video are (mkv/mp4)</div>
        //           </Upload>
        //         </Space>
        //       </Form.Item>
        //     </div>
        //   ) : (
        //     ''
        //   )}
        // </div>,
      ]}
      // videoUrl={uploadVideo}
    />
  );
};

export default AnuraElementUpdate;
