import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { app } from '../../config';
import { Input, Form, Modal as AntModal, message } from 'antd';
import getHeaders from '../../utils/authHeaders';
import { useForm } from 'antd/lib/form/Form';
import LoaderImage from '../LoaderImage';
import { LoadingOutlined } from '@ant-design/icons';
import OrderReceiveModal from './OrderRecieveModal';

function ReceiveOrder() {
  const [boxNumberError, setBoxNumberError] = useState('');
  const [orderResponseData, setOrderResponseData] = useState();
  const [orderResponseType,setOrderResponseType]=useState()
  const [showModal, setShowModal] = useState(false);
  const [msg, setMsg] = useState('');
  const [searchSerialNo, setSearchSerialNo] = useState('');
  const [state, setState] = useState('');

  const [orderForm] = useForm();

  const [loader, setLoader] = useState(false);

  const handleSerialNoChange = async (event) => {
    setSearchSerialNo(event.target.value);
    if (event.target.value.length === 21) {
      await checkOrderBySerialNo(event.target.value);
    }
  };

  const inputRef = useRef();
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const checkOrderBySerialNo = async (serialNo) => {
    setOrderResponseData();
    setLoader(true);

    try {
      const res = await axios.get(`${app.URL}/api/v1/admin/userrequest/findproduct?serialNo=${serialNo}`, { headers: getHeaders() });
      console.log(res.data);

      if (res.data.result.success) {
        setOrderResponseData(res.data.result.data);
        setOrderResponseType(res?.data?.result?.type);
        orderForm.resetFields();
        setLoader(false);
        setBoxNumberError('');
      }
      console.log(inputRef);

      setTimeout(() => {
        inputRef.current.focus();
      }, 300);
    } catch (error) {
      setLoader(false);
      setBoxNumberError(error.response.data.result.msg);
      console.log('error in check order api: ', error);
      orderForm.resetFields();
      setOrderResponseData();

      setTimeout(() => {
        inputRef.current.focus();
      }, 300);
    }
  };

  const onSearchTrigger = async () => {
    console.log('search trigger', searchSerialNo);
    if (searchSerialNo.length === 21) {
      await checkOrderBySerialNo(searchSerialNo);
    }
  };

  const handleReceiveOrder = async (isRejected, isAccepted) => {
    setState(isAccepted ? 'accept' : 'reject');
    setLoader(true);

    try {
      const res = await axios.post(
        `${app.URL}/api/v1/admin/userrequest/receiveproduct`,
        {
          serialNo: searchSerialNo,
          isAccepted,
          isRejected,
        },
        { headers: getHeaders() }
      );
      console.log(res.data);
      setLoader(false);
      setState('');
      
      if (res.data.result.success) {
        message.success(res?.data?.result?.msg);
        setOrderResponseData();
      } 
      // if (res.data.result.success) {
      // }
    } catch (error) {
      setLoader(false);
      setState('');
      setOrderResponseData();

      console.log('error in check order api: ', error);
      message.error(error?.response?.data?.result?.msg);
    }
  };

  return (
    <div className='dashboard-card container-fluid pb-3'>
      <div className='row p-0 pb-4'>
        <div className='for-before col-12 col-md-7 head fs-3 fw-bold' style={{ backgroundColor: '#a7c1d9', borderBottomRightRadius: '10px', color: '#FFFFFF' }}>
          Refund Receive Order
        </div>
      </div>

      <Form
        form={orderForm}
        name='basic'
        initialValues={{
          remember: true,
        }}
        onFinish={() => {
          onSearchTrigger();
        }}
        onFinishFailed={() => {}}
        className='row px-3 pb-1 d-flex justify-content-center justify-content-md-start'
        autoComplete='off'
      >
        <Form.Item
          name='searchItem'
          className='col-md-3 col-12 mb-2 mb-md-0'
          style={{
            width: 250,
          }}
        >
          <Input
            placeholder='Scan Product QR Code'
            maxLength={21}
            onChange={(event) => {
              handleSerialNoChange(event);
            }}
            ref={inputRef}
          />
        </Form.Item>

        {/* <button type='primary' className='col-md-2 col-6 btn btn-primary button' htmlType='submit'>
          Search
        </button> */}
      </Form>

      {boxNumberError && (
        <div className='row px-3 pb-1'>
          <div className='col-12'>
            <small
              style={{
                color: 'crimson',
                fontWeight: '500',
                fontSize: '0.8rem',
              }}
            >
              {boxNumberError}
            </small>
          </div>
        </div>
      )}

      {!loader && (
        <div className='row px-3'>
          <div className='col-12'>
            <span className={'text-success font-bold'}>
              {orderResponseData?.accessionNo && orderResponseData?.orderStatusId === 2 && 'The test order has already been sent to the lab.'}
              {!orderResponseData?.collectionProcess && `${msg}`}
            </span>
          </div>
        </div>
      )}

      {loader && <LoaderImage />}

      <div className='text-left px-3'>
        <p className='text-dark fs-5'> {orderResponseData?.ecomOrderNo} </p>
        <p className='text-dark fs-5'> {orderResponseData?.patientName} </p>
        <p className='text-dark fs-5'> {orderResponseData?.mobile} </p>
      </div>

      {orderResponseData && (
        <div className='px-3'>
          <div
            className='row table-scrollbar mr-0'
            style={{
              overflowX: 'auto',
            }}
          >
            <table id='customers'>
              <thead>
                <tr
                  style={{
                    fontWeight: 'bold',
                  }}
                >
                  <th>Request No.</th>
                  {/* <th>Product Code</th> */}
                  <th>Product Name </th>
                  <th>Request Type</th>
                  <th>Request Status</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{orderResponseData?.requestNo}</td>
                  {/* <td>{orderResponseData?.productCode}</td> */}
                  <td>{orderResponseData?.productName}</td>
                  <td>{orderResponseData?.requestType}</td>
                  <td>{orderResponseData?.requestStatus}</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className='row'>
            <div className='text-right col-md-12'>
              <button
                variant='primary'
                className='btn btn-secondary mr-2'
                onClick={() => {
                  setShowModal(true);
                }}
              >
                View
              </button>
              {/* <button
                variant='primary'
                // disabled={orderResponseData?.accessionNo && orderResponseData?.orderStatusId === 2}
                className='btn btn-secondary mr-2'
                onClick={() => handleReceiveOrder(true, false)}
                disabled={orderResponseData?.isDisabled || loader}
              >
                {loader && state == 'reject' ? <LoadingOutlined /> : 'Reject'}
              </button>
              <button
                variant='primary'
                // disabled={orderResponseData?.accessionNo && orderResponseData?.orderStatusId === 2}
                className='btn btn-secondary'
                onClick={() => handleReceiveOrder(false, true)}
                disabled={orderResponseData?.isDisabled || loader}
              >
                {loader && state == 'accept' ? <LoadingOutlined /> : 'Accept'}
              </button> */}
            </div>
          </div>
        </div>
      )}
      <OrderReceiveModal
        showModal={showModal}
        setShowModal={setShowModal}
        orderResponseData={orderResponseData}
        buttons={[
          <button
            variant='primary'
            // disabled={orderResponseData?.accessionNo && orderResponseData?.orderStatusId === 2}
            className='btn btn-secondary mr-2'
            onClick={() => handleReceiveOrder(true, false)}
            disabled={orderResponseData?.isDisabled || loader}
          >
            {loader && state == 'reject' ? <LoadingOutlined /> : 'Reject'}
          </button>,
          <button variant='primary' disabled={orderResponseType === 'Faliure' || loader} className='btn btn-secondary' onClick={() => handleReceiveOrder(false, true)} >
            {loader && state == 'accept' ? <LoadingOutlined /> : 'Accept'}
          </button>,
        ]}
      />

      {/* <Modal show={sendToLabResp} onHide={handleSendToLabResp}>
        <Modal.Body>
          <div className='container-fluid'>
            <div className='row'>
              <input className='col-12 text-center' disabled value={sendToLabResp} style={{ backgroundColor: '#fff', fontWeight: '500', fontSize: '1.5rem' }} />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className='container-fluid'>
            <div className='row'>
              <Button className='offset-md-4 col-lg-4 btn-style' variant='secondary' onClick={handleSendToLabResp}>
                Close
              </Button>
            </div>
          </div>
        </Modal.Footer>
      </Modal> */}
    </div>
  );
}

export default ReceiveOrder;
