import React from 'react';
import { Route, Routes as DefaultAppRoutes } from 'react-router-dom';
import AdmnSignIn from '../pages/auth/AdmnSignIn';
// import UserFeedBack from '../pages/userFeedback/UserFeedBack';
import ReactToPrint from '../pages/ReactToPrint';
import Example from '../pages/Example';
// import AddUserFeedback from '../pages/userFeedback/AddUserFeedBack';
// import EditUserFeedback from '../pages/userFeedback/EditUserFeedback';
// import UserFeedbackListing from '../pages/userFeedback/UserFeedbackListing';
import ForgotPassword from '../pages/auth/ForgotPassword';


// import OrgSignIn from '../pages/OrgSignIn';

const DefaultRoutes = () => {
  return (
    <div className=''>
      <DefaultAppRoutes>
        <Route path='/' element={<AdmnSignIn />} />
        <Route path='/admin/login' element={<AdmnSignIn />} />
        <Route path='/admin/forgotpassword' element={<ForgotPassword />} />
        {/* <Route path='/feedback' element={<UserFeedbackListing />} /> */}
        {/* <Route path='/feedback/create' element={<AddUserFeedback />} /> */}
        {/* <Route path='/feedback/update' element={<EditUserFeedback />} /> */}
        <Route path='/reactprint' element={<ReactToPrint />} />
        <Route path='/example' element={<Example />} />

        {/* <Route path='/newadmin' element={<OrgSignIn />} /> */}
        {/* <Route path='/adminsignin' element={<AdmnSignIn2 />} /> */}
        <Route path='*' element={<AdmnSignIn />} />
      </DefaultAppRoutes>
    </div>
  );
};

export default DefaultRoutes;
