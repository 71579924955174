import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { app } from '../../config';
import { useNavigate } from 'react-router-dom';
import { message, Pagination, Input, Form, Select, Row, Col, Card } from 'antd';
import getHeaders from '../../utils/authHeaders';
import ListinngCard from '../../layouts/ListingCard';
import LoaderImage from '../LoaderImage';

const WarehouseUnverified = () => {
  //  const [editbtn, setEditBtn] = useState('none');
  //  const [search, setSearch] = useState('');
  const [pageSize, setPageSize] = useState(10);
  // const [fetchUsers, setFetchUsers] = useState([]);
  const [pagesCount, setPagesCount] = useState(0);
  const [searchShow, setSearchShow] = useState({});
  const [renderList, setRenderList] = useState([]);
  const [tableShow, setTableShow] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [tableHeaders, setTableHeaders] = useState([]);
  //  const [searchData, setSearchData] = useState();
  const [err, setErr] = useState('');

  const unverifiedBatchListingApi = async (formData) => {
    const listingResp = await axios.post(`${app.URL}/api/v1/admin/ecomorders/unverifiedwarehouse`, formData, { headers: getHeaders() });
    //  listingResp.data.result ? setFetchUsers(listingResp.data.result.data) : ' ';
    listingResp.data.result ? setPagesCount(listingResp.data.result.data.count) : ' ';
    listingResp.data.result ? setRenderList(listingResp.data.result.data.data) : ' ';
    // console.log(listingResp);
  };



  useEffect(() => {
    const unverifiedList = async () => {
      //  const user = JSON.parse(localStorage.getItem('user'));
      let header = ['Code', 'Name', 'Type', 'Image'];
      setTableHeaders(header);
      //  if (user.userType === 'A') setEditBtn('block');
      let formData = {
        searchBy: 'productName',
        queryTxt: '',
        limit: 10,
        offset: 0,
      };
      await unverifiedBatchListingApi(formData);
    };
    unverifiedList();
  }, []);
  let searchItems = [
    {
      name: 'Product Code',
      value: 'productCode',
    },
    {
      name: 'Product Name',
      value: 'productName',
    },
    {
      name: 'Order No.',
      value: 'orderNumber',
    },
  ];

  const onChange = async (page, pageSize) => {
    setPageSize(pageSize);
    setCurrentPage(page);
    if (!page) page = 1;
    let limit = pageSize;

    try {
      let reqObj;
      if (Object.keys(searchShow).length !== 0) {
        reqObj = {
          limit: limit,
          offset: limit * (page - 1),
          searchBy: searchShow.searchFor,
          searchTxt: searchShow.searchItem,
        };
      } else {
        reqObj = {
          limit: limit,
          offset: limit * (page - 1),
          searchBy: 'productName',
          searchTxt: '',
        };
      }

      await unverifiedBatchListingApi(reqObj);
    } catch (e) {
      console.log('Error');
      if (e.message) setErr(e.message);
      setTableShow(false);
    }
  };

  const onFinish = async (values) => {
    setSearchShow(values);

    let formData = {
      limit: 10,
      offset: 0,
      searchBy: values.searchBy,
      searchTxt: values.searchTxt,
    };
    if (!formData.searchBy) message.error('Please enter search values');
    else await unverifiedBatchListingApi(formData);
  };
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  console.log(currentPage, pagesCount);

  // return <Table />
  console.log(renderList);

  useEffect(() => {
    let formData = {
      searchBy: 'productName',
      queryTxt: '',
      limit: 10,
      offset: 0,
    };
    const interval = setInterval(async () => {
      console.log('Interval');
      await unverifiedBatchListingApi(formData);
    }, 300000);
    return () => clearInterval(interval);
 })

  return (
    <div className='dashboard-card container-fluid'>
      <div className='row p-0 pb-4'>
        <div className='for-before col-12 col-md-4 head fs-3 fw-bold' style={{ backgroundColor: '#a7c1d9', borderBottomRightRadius: '10px', color: '#FFFFFF' }}>
          Shipment Batch Report
        </div>
      </div>
      {renderList.length > 0 ? (
        <div className='row px-2'>
          {renderList.map((tableValues) => (
            <div className='col-xl-4 col-lg-6 col-12 my-3'>
              <Card title={tableValues.subOrderNumber} bordered={false} style={{ boxShadow: 'none', overflow: "auto" }}>
                  <table id='customers' className='p-1' style={{width: "100%"}}>
                    <thead>
                      <tr>
                        {tableHeaders.map((header) => (
                          <th className='px-3'>{header}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody className='w-100'>
                      {tableValues.products.map((detail) => (
                        <tr>
                          <td className='ship-table-data'>{detail.productCode ? detail.productCode : 'null'}</td>
                          <td className='ship-table-data'>{detail.productName ? detail.productName : 'null'}</td>
                          <td className='ship-table-data'>{detail.boxType}</td>
                          <td className=''>
                            {detail.productImage ? (
                              <img
                                style={{
                                  width: '20px',
                                  height: '30px',
                                  overflow: 'hidden',
                                  margin: '0px auto',
                                }}
                                src={detail.productImage}
                                alt={detail.name}
                              />
                            ) : (
                              'null'
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
              </Card>
            </div>
          ))}
        </div>
      ) : (
        <LoaderImage />
      )}
    </div>
  );
};

export default WarehouseUnverified;
