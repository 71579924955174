import React, { useState } from 'react';
import axios from 'axios';
import { app } from '../../config';
import { LoadingOutlined, MinusCircleOutlined, PlusOutlined, PrinterOutlined } from '@ant-design/icons';
import getHeaders from '../../utils/authHeaders';
import { Button as AButton, Form, Input, Space, Select, Row, Col, message, Tag } from 'antd';
import useFetchProducts from '../../hooks/fetchProducts';
import useFetchPartners from '../../hooks/fetchPartner';
import { CSVLink } from 'react-csv';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import AddNewImg from '../../assets/images/add-new.png';
import Excel from '../../assets/images/excel.png';
import Delete from '../../assets/images/delete.png';
import { extractObjValues } from '../../utils/extractObjValues';
import { dateConverter } from '../../utils/utcDateConversionCommon';
import useFetchBoxTypes from '../../hooks/fetchBoxTypes';
import { useForm } from 'antd/lib/form/Form'; // Import the useForm hook
import { useNavigate } from 'react-router-dom';
import Barcode from 'react-barcode';
import ReactToPrint, { useReactToPrint } from 'react-to-print';
import { useRef } from 'react';
import { TweenOneGroup } from 'rc-tween-one';
import { InputField } from '../../layouts/FormFields';

const AddInventory = () => {
  const [invListData, setInvListData] = useState([]);
  const [addInvResp, setAddInvResp] = useState({});
  const [apiResp, setAPIResp] = useState(false);
  const [delBatchRes, setDelBatchRes] = useState({});
  const [show, setShow] = useState(false);
  const [showDelModal, setShowDelModal] = useState(false);
  const [uploadArr, setUploadArr] = useState([]);
  const [loading, setLoading] = useState(false);
  const [noinvList, setNoinvList] = useState(false);
  const [boxTypes, setBoxTypes] = useState([]);
  const [boxProductss, setBoxProductss] = useState([]);

  const navigate = useNavigate();

  const [form] = useForm();
  const handleClose = () => setShow(false);
  const handleDelModalClose = () => setShowDelModal(false);
  const fetchedProducts = useFetchProducts();
  const products = fetchedProducts.response ? fetchedProducts.response.data : [];
  const fetchedPartners = useFetchPartners();
  const partners = fetchedPartners.response ? fetchedPartners.response.data : [];
  console.log(partners);

  let boxes;

  const fetchedBoxTypes = useFetchBoxTypes(setBoxTypes);
  boxes = fetchedBoxTypes.response ? fetchedBoxTypes.response.data : [];

  let user = JSON.parse(localStorage.getItem('user'));
  let userId = user.result ? user.result.data.user.userId : null;
  console.log(userId);

  const uploadData = async (userDetails) => {
    console.log(userDetails);
    setLoading(true);
    let userId = user?.result.data.user.userId;
    userDetails[0].userId = userId;
    try {
      const res = await axios.post(`${app.URL}/api/v1/admin/genericbox/create`, userDetails, { headers: getHeaders() });
      console.log(res);
      setLoading(false);
      setShow(false);
      if (res.data.result.success) {
        setAPIResp(true);
        console.log('dataaaaaaaaaaaaaaa');
        console.log(res.data);
        console.log(res.data.result.data);
        setAddInvResp(res.data.result.data);
        let convertedData = dateConverter(res.data.result.data, 'issueDate');
        setInvListData(convertedData);
        message.success(res.data.result.msg);
      } else if (!res.data.result.success) {
        message.warning(res.data.result.msg);
      }
    } catch (err) {
      setLoading(false);
      setShow(false);
      if (err) console.log(err.response.data.message);
      else return;
    }
  };

  const refinedArray = extractObjValues(invListData, ['batchId', 'type', 'issueDate', 'codes']);
  console.log(refinedArray);
  let newArray = [];
  refinedArray.forEach((ele) => {
    newArray.push({ [`Batch ID`]: ele.batchId, [`Title`]: ele.type, [`Date`]: ele.issueDate, [`Serial No.`]: ele.codes.map((item) => `${item.serialNo} `) });
  });

  console.log(show);

  const onFinish = (values) => {
    console.log('Received values of form:', values.invList);
    const { invList } = values;
    let tempArr = [];
    if (invList.length > 0) {
      for (let i = 0; i < invList.length; i++) {
        tempArr.push({
          boxTypeId: invList[i].boxType ? invList[i].boxType : null,
          quantity: invList[i].availableQty ? invList[i].availableQty : null,
          issueDate: new Date(),
          isActive: 1,
          userId: userId,
        });
      }
      setUploadArr(tempArr);
      setShow(true);
      setNoinvList(false);
    } else {
      setNoinvList(true);
    }
  };

  const submitData = () => {
    console.log('calling1111');
    console.log(uploadArr);
    if (Object.keys(uploadArr).length > 0) {
      uploadData(uploadArr);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo.values.invList);

    // Extract boxType values from the invList array
    const boxTypesToRemove = errorInfo?.values?.invList?.filter((item) => item.boxType !== undefined).map((item) => item.boxType);

    // Filter the main bocType array
    setBoxTypes(boxes.filter((item) => !boxTypesToRemove.includes(item.boxTypeId)));
  };
  const deleteBatch = async (batchId) => {
    console.log(batchId);
    const res = await axios.delete(`${app.URL}/api/v1/admin/genericbox/delete/${batchId}`, { headers: getHeaders() });
    console.log(res);
    if (res && res.data.result.success) {
      setDelBatchRes(res.data.result.data);
      setShowDelModal(false);
      setInvListData([]);
      setAPIResp(false);
      form.resetFields();
    }
  };
  function goBack() {
    navigate('/genericbox/listing', { replace: true });
  }
  window.addEventListener('popstate', goBack);

  console.log(uploadArr);

  let printRef = useRef();

  const pageStyle = `
        @page {
        size: 4in 1in; /* Specify your custom page size here (width x height) */
           marginTop: 2px;
           margin: 0;
           padding: 0;
       }
      //   @page {
      //   size: 8.5in 11.5in; /* Specify your custom page size here (width x height) */
      //      marginTop: 4px;
      //      margin: 0;
      //  }
       body {
         font-family: Arial, sans-serif;
         margin: 0;
       }
       `;

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    pageStyle: pageStyle,
    onAfterPrint: goBack,
  });

  const [displayBarCode, setDisplayBarCode] = useState([]);
  const [printLoader, setPrintLoader] = useState(false);
  // const [disableAdd, setDisableAdd] = useState(false)

  const generateBarCode = (e) => {
    setPrintLoader(true);
    e.stopPropagation();

    let barCodes = [];
    for (let i = 0; i < invListData.length; i++) {
      for (let j = 0; j < invListData[i].codes.length; j++) {
        barCodes.push(
          <div>
            <div className='d-flex'>
              {/* left */}
              <Barcode margin={0} marginLeft={10} marginRight={14} height={73} value={invListData[i].codes[j].serialNo} />
              {/* right */}
              <Barcode margin={0} marginLeft={12} marginRight={10} height={73} value={invListData[i].codes[j].serialNo} />
            </div>
            {invListData[i].verificationCount === 3 && (
              <div className='d-flex'>
                {/* left */}
                <Barcode margin={0} marginLeft={10} marginRight={14} height={73} value={invListData[i].codes[j].serialNo} />
                {/* right */}
                <div style={{ width: '81.5%' }}></div>
              </div>
            )}
          </div>
        );
      }
    }
    // let barCodes = [];
    // for (let i = 0; i < invListData.length; i++) {
    //   // console.log(invListData[i].serialNo);
    //   barCodes.push(

    //     <div>
    //       <div className='d-flex'>
    //         {/* left */}
    //         <Barcode margin={0} marginLeft={10} marginRight={14} height={73} value={invListData[i].serialNo} />
    //         {/* right */}
    //         <Barcode margin={0} marginLeft={12} marginRight={10} height={73} value={invListData[i].serialNo} />
    //       </div>
    //       <div className='d-flex'>
    //         {/* left */}
    //         <Barcode margin={0} marginLeft={10} marginRight={14} height={73} value={invListData[i].serialNo} />
    //         {/* right */}
    //         <div style={{width: "81.5%"}}></div>
    //         {/* <Barcode margin={0} marginLeft={12} marginRight={10} height={73} value={batch[i].serialNo} /> */}
    //       </div>
    //     </div>
    //   );
    // }

    setDisplayBarCode(barCodes);

    setTimeout(() => {
      setPrintLoader(false);
      handlePrint();
    }, 1000);
  };

  function copyToClipboard(value) {
    console.log(navigator);

    // Copy the text inside the text field
    navigator.clipboard.writeText(value);

    // Provide feedback to the user (you can customize this part)
    message.success(`Serial No has been copied to the clipboard: ${value}`);
  }
  const forMap = (tag) => {
    const tagElem = (
      <Tag
        style={{
          borderRadius: '5px',
          // backgroundColor: '#f2b544',
          boxShadow: '1px 1px 1px #ccc',
          cursor: 'pointer',
        }}
        id='serial'
        // value={tag}
        aria-disabled={true}
        onClick={() => copyToClipboard(tag)}
        className={`border-none d-flex align-items-center text-dark bg-light fs-6 p-2 mb-2`}
      >
        {tag}
      </Tag>
    );
    return (
      <span
        key={tag}
        style={{
          display: 'inline-block',
        }}
      >
        {tagElem}
      </span>
    );
  };

  const handleBoxProducts = (boxTypeId, key) => {
    // console.log(boxTypeId, key);
    const [ele] = boxTypes.filter((item) => item.boxTypeId === boxTypeId);
    // console.log(ele.products, key);
    console.log(boxProductss.some((item) => item.id === key));

    if (boxProductss.some((item) => item.id === key)) {
      let filtered = boxProductss.filter((prod) => prod.id !== key);
      setBoxProductss([...filtered, { id: key, products: ele.products }]);
    } else {
      setBoxProductss([...boxProductss, { id: key, products: ele.products }]);
    }
  };

  // console.log(boxProductss);

  // const tagChild = invListData?.map((item) => forMap(item.serialNo));

  return (
    <div className='dashboard-card container-fluid'>
      <div className='row'>
        <div className='for-before col-12 col-md-7 head fs-3 fw-bold' style={{ backgroundColor: '#a7c1d9', borderBottomRightRadius: '10px', color: '#FFFFFF' }}>
          {/* Add Generic Box */}
          Generate Bar Code
        </div>

        <div style={{ display: 'none' }}>
          <div
            style={{
              margin: '0px !important',
              padding: '0px',
              width: '100% !important',
              height: '100% !important',
            }}
            ref={printRef}
          >
            {displayBarCode.map((item) => item)}
          </div>
        </div>
      </div>
      <Form name='inventoryList' onFinishFailed={onFinishFailed} onFinish={onFinish} className='row p-5 px-3 pb-1' autoComplete='off' initialValues={{ invList: [''] }}>
        <div className='col-md-8'>
          <div className='row'>
            <Form.List name='invList'>
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <Space key={fields.key} align='baseline'>
                      <div className='col-lg-12'>
                        <Form.Item
                          {...restField}
                          name={[name, 'boxType']}
                          // style={{ width: '250px' }}
                          rules={[
                            {
                              required: true,
                              message: 'Select a Generic Box Type',
                            },
                          ]}
                        >
                          <Select
                            placeholder='Select Generic Box Type'
                            onChange={(e) => {
                              handleBoxProducts(e, key);
                            }}
                          >
                            {boxTypes.map((box) => (
                              <Select.Option value={box.boxTypeId}>{box.type}</Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                        {boxProductss.map((item) => {
                          if (item.id === key) {
                            return item.products.map((prod) => (
                              <span style={{ borderRadius: '5px', boxShadow: 'rgb(204, 204, 204) 1px 1px 1px' }} className='ant-tag border-none text-light d-flex align-items-center bg-secondary p-2 mb-2 '>
                                {prod}
                              </span>
                            ));
                          }
                        })}
                      </div>

                      <Form.Item
                        // style={{ width: '250px' }}
                        className='col-lg-12'
                        {...restField}
                        name={[name, 'availableQty']}
                        rules={[
                          {
                            required: true,
                            message: 'Enter Quantity',
                          },
                        ]}
                      >
                        <Input placeholder='Quantity' type='number' min='1' />
                      </Form.Item>

                      <div
                        className='col-lg-4'
                        style={{
                          fontSize: '1rem',
                          color: 'red',
                          paddingTop: '0.5rem',
                        }}
                      >
                        <MinusCircleOutlined
                          onClick={() => {
                            if (fields.length > 1) {
                              remove(name);
                            }
                          }}
                        />
                      </div>
                    </Space>
                  ))}

                  {!apiResp && (
                    <button
                      disabled={apiResp}
                      onClick={() => {
                        add();
                        setNoinvList(false);
                      }}
                      type='primary'
                      className={`btn btn-primary button col-md-3 mx-md-2 ${noinvList ? 'mb-1' : 'mb-4'}`}
                    >
                      Add More
                    </button>
                  )}
                  <span className={`${noinvList ? 'd-block' : 'd-none'} mb-4 text-danger`}>Please add atleast one record!</span>
                </>
              )}
            </Form.List>

            <div className='col-12 mb-4'>
              <div className='row'>
                <button disabled={apiResp} onClick={goBack} type='primary' className='btn btn-primary button patient-cancel-btn col-md-3 mx-md-2 mr-2 mb-2'>
                  Cancel
                </button>

                <button disabled={apiResp} type='primary' class='btn btn-primary button col-md-3 mb-2 mr-2' htmlType='submit'>
                  Generate
                </button>

                {apiResp && (
                  <span onClick={generateBarCode} disabled={printLoader} type='primary' class='btn btn-primary button col-md-3 mb-2 mr-2' htmlType='submit'>
                    {printLoader ? <LoadingOutlined className='mr-2' /> : <PrinterOutlined className='mr-2' />}
                    Print Barcodes
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
      </Form>

      {addInvResp.code === 200 ? (
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title className='text-center'>Success</Modal.Title>
          </Modal.Header>
          <Modal.Body>{addInvResp.message}</Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      ) : addInvResp.code === 400 ? (
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title className='text-center'>Failed</Modal.Title>
          </Modal.Header>
          <Modal.Body>{addInvResp.data.message}</Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      ) : (
        <Modal show={show} onHide={handleClose}>
          {/* <Modal.Header closeButton>
            <Modal.Title>Confirmation</Modal.Title>
          </Modal.Header> */}
          <Modal.Body>Are you sure, you want to generate inventory?</Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={handleClose}>
              Cancel
            </Button>
            <Button disabled={loading} variant='primary' htmlType='submit' onClick={submitData}>
              {loading ? <LoadingOutlined /> : 'Confirm'}
            </Button>
          </Modal.Footer>
        </Modal>
      )}

      {delBatchRes.code === 200 ? (
        <Modal show={showDelModal} onHide={handleDelModalClose}>
          <Modal.Header closeButton>
            <Modal.Title className='text-center'>Success</Modal.Title>
          </Modal.Header>
          <Modal.Body>{delBatchRes.message}</Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      ) : addInvResp.code === 400 ? (
        <Modal show={showDelModal} onHide={handleDelModalClose}>
          <Modal.Header closeButton>
            <Modal.Title className='text-center'>Failed</Modal.Title>
          </Modal.Header>
          <Modal.Body>{delBatchRes.data.message}</Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      ) : (
        <Modal show={showDelModal} onHide={handleDelModalClose}>
          <Modal.Header closeButton>
            <Modal.Title>Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure, you want to Delete Batch?</Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={handleDelModalClose}>
              Cancel
            </Button>
            <Button
              variant='primary'
              htmlType='submit'
              onClick={() => {
                deleteBatch(refinedArray[0].batchId);
              }}
            >
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>
      )}

      {/* <button style={{ width: '70%', marginTop: '30px' }} type='primary' class='btn btn-primary button' htmlType='submit'>
                                        Save & Generate Serial No.
                                    </button> */}

      {apiResp ? (
        <>
          <div className='row'>
            <div className='col-lg-1 ml-6'>
              <CSVLink filename={'batchRecord.csv'} data={newArray}>
                <img src={Excel} style={{ width: '30px', height: '30px' }} />
                <img />
              </CSVLink>
            </div>
            <div className='col-lg-1'>
              <img
                src={Delete}
                style={{ width: '25px', height: '25px', cursor: 'pointer' }}
                onClick={() => {
                  setShowDelModal(true);
                }}
              />
            </div>
            {/* <div className='col-lg-1'>
              {printLoader ? (
                <LoadingOutlined
                  style={{
                    fontSize: '1.5rem',
                  }}
                />
              ) : (
                <PrinterOutlined
                  style={{
                    fontSize: '2rem',
                  }}
                  onClick={generateBarCode}
                />
              )}
            </div> */}
          </div>

          <div
            // className='row table-scrollbar mr-0'
            // style={{
            //   overflowX: 'auto',
            // }}
            className='row mr-0 px-2'
          >
            {invListData.map((item) => (
              <Form
                initialValues={{
                  remember: true,
                  ['batchNo']: item?.batchId,
                  ['type']: item?.type,
                  ['issueDate']: item?.issueDate,
                  ['quantity']: item?.codes?.length,
                }}
                autoComplete='off'
                className='row p-2 px-3 pb-1 mb-3'
              >
                <InputField col='col-md-2' label='Batch No.' name='batchNo' disabled={true} />
                <InputField col='col-md-2' label='Box Type' name='type' disabled={true} />
                <InputField col='col-md-2' label='Creation Date' name='issueDate' disabled={true} />
                <InputField col='col-md-2' label='Quantity' name='quantity' disabled={true} />
                <div className='col-lg-12'>
                  <label>Serial No.</label>
                  <TweenOneGroup
                    enter={{
                      scale: 0.8,
                      opacity: 0,
                      type: 'from',
                      duration: 100,
                    }}
                    onEnd={(e) => {
                      if (e.type === 'appear' || e.type === 'enter') {
                        e.target.style = 'display: inline-block';
                      }
                    }}
                    leave={{
                      opacity: 0,
                      width: 0,
                      scale: 0,
                      duration: 200,
                    }}
                    appear={false}
                  >
                    {item?.codes?.map((item) => forMap(item.serialNo, item.isBind))}
                  </TweenOneGroup>
                </div>
              </Form>
            ))}
          </div>
        </>
      ) : (
        ' '
      )}
    </div>
  );
};

export default AddInventory;
