import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
// import { useTranslation } from 'react-i18next';
import { message, Switch, Form, Input } from 'antd';
import { app } from '../../config';
// import { LeftCircleOutlined } from '@ant-design/icons';
import getHeaders from '../../utils/authHeaders';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
// import getStore from '../../hooks/getStore';
import getProductCategory from '../../hooks/getProductCategory';
import { UpdateRecord } from '../../layouts/UpdateRecord';
import { InputField } from '../../layouts/FormFields';

const EditProductCategory = () => {
  // const { t } = useTranslation(['common']);
  const [uploadObj, setUploadObj] = useState({});
  const [error, setError] = useState('');
  //   const [editStoreResp, setEditStoreResp] = useState({});
  const [editProdCategoryResp, setEditProdCategoryResp] = useState({});
  const [show, setShow] = useState(false);
  const [errorObj, setErrorObj] = useState({});
  const [addUserResp, setAddUserResp] = useState({});
  const [loading, setLoading] = useState(false);
  const [resetModal, setResetModal] = useState(false);
  const navigate = useNavigate();
  let prodCat = JSON.parse(localStorage.getItem('productCatId'));
  let res = getProductCategory(prodCat.productCategoryId);
  let prodCatObj = res.response ? res.response.data : null;
  console.log(prodCatObj);
  const uploadData = async (uploadObj) => {
    // console.log('Function Running');
    let user = JSON.parse(localStorage.getItem('user'));
    uploadObj.userId = user?.result?.data?.user?.userId;
    try {
      const res = await axios.post(`${app.URL}/api/v1/admin/category/update/${prodCatObj.productCategoryId}`, uploadObj, { headers: getHeaders() });
      console.log('🚀 ~ file: EditOrg.js:49 ~ uploadData ~ res', res);

      if (res.data.result.success) {
        setAddUserResp(res.data.result.data);
        message.success(res.data.result.msg);
        setTimeout(goBack, 500);
      } else if (!res.data.result.success) {
        message.warning(res.data.result.msg);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
      setShow(false);
      if (err) console.log(err.response.data.message);
      else return;
    }
  };

  const onFinish = (values) => {
    console.log('Success:', values);
    setUploadObj({
      name: values.name ? values.name : null,
      description: values.description ? values.description : null,
      isActive: values.isActive === true ? 1 : 0,
    });
    setResetModal(true);
    setShow(true);
  };
  console.log(uploadObj);
  const submitData = () => {
    console.log('calling');
    if (Object.keys(uploadObj).length > 0) {
      uploadData(uploadObj);
    }
  };

  const handleClose = () => setShow(false);
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  function goBack() {
    navigate('/category/listing', { replace: true });
  }
  window.addEventListener('popstate', goBack);

  return (
    <>
      <UpdateRecord
        loading={loading}
        pageTitle='Update Banner'
        initialValues={{
          remember: true,
          ['name']: prodCatObj?.name,
          ['description']: prodCatObj?.description,
          ['isActive']: prodCatObj?.isActive == '1' ? 1 : 0,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        switchBtn={true}
        addUserResp={addUserResp}
        handleClose={handleClose}
        show={show}
        updateMessage='Are you sure you want to update category?'
        goBack={goBack}
        submitData={submitData}
        response={prodCatObj}
        upload={false}
        inputs={[
          <InputField
            col='col-md-6 col-12'
            name='name'
            rules={[
              {
                required: true,
                message: 'Enter Product Category',
              },
            ]}
            placeholder='Enter Product Category'
            label='Product Category'
            required={true}
          />,
          <InputField
            col='col-md-6 col-12'
            name='description'
            rules={[
              {
                required: true,
                message: 'Enter Product Description',
              },
            ]}
            placeholder='Enter Product Description'
            label='Product Description'
            required={true}
          />,
        ]}
      />
    </>
  );
};

export default EditProductCategory;
