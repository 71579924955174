import { createSlice } from '@reduxjs/toolkit';

const initialState = [];
const organizationSlice = createSlice({
  name: 'organization',
  initialState,
  reducers: {
    addOrg: (state, action) => {
      state.push(action.payload);
    },
    removeOrg: (state, action) => {
      state = state.filter((a) => a.id === action.payload);
    },
  },
});

export const { addOrg, removeOrg } = organizationSlice.actions;
export default organizationSlice.reducer;
