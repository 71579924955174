import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { app } from '../../config';
import CalenderImg from '../../assets/images/calender.png';
import { useNavigate } from 'react-router-dom';
import { Pagination, Input, Form, Select, Row, Col, Space, Spin, message, Collapse } from 'antd';
//Collapse
import { CaretRightOutlined, CaretDownOutlined, SettingOutlined, CodeSandboxCircleFilled } from '@ant-design/icons';
import moment from 'moment';
//Collapse End
// import { useTranslation } from 'react-i18next';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
// import { EditTwoTone, BranchesOutlined } from '@ant-design/icons';
import getHeaders from '../../utils/authHeaders';
// import ListinngCard from '../../layouts/ListingCard';
import { dateConverter } from '../../utils/utcDateConversionCommon';

import EcommerceDashboardCard from '../../layouts/EcommerceDashboardCard';
import NoDataFound from '../../layouts/NoDataFound';
import MainOrderViewModal from './MainOrderViewModal';
import SubOrderViewModal from './SubOrderViewModal';
import DashboardMainOrderModal from './DashboardMainOrderModal';
import EcomDashboardHeader from './EcomDashboardHeader';
import EcommerceCards from './EcommerceCards';
import { SearchBar } from '../../layouts/SearchBar';
import LoaderImage from '../LoaderImage';
const { TextArea } = Input;
import { durationSelection, onChangeMethod } from '../../utils/SortingUtils';

const InProgress = () => {
  // const { RangePicker } = DatePicker;
  const [activeIndex, setActiveIndex] = useState(1);
  const [cancelShow, setCancelShow] = useState(false);
  const [editbtn, setEditBtn] = useState('none');
  const [pageSize, setPageSize] = useState(10);
  const [fetchPartners, setFetchPartners] = useState([]);
  const [subOrderObj, setSubOrderObj] = useState({});
  const [pagesCount, setPagesCount] = useState(0);
  const [searchShow, setSearchShow] = useState({});
  const [renderList, setRenderList] = useState([]);
  const [tableShow, setTableShow] = useState(true);
  const [isCustomerRequest, setIsCustomerRequest] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [err, setErr] = useState('');
  const [tableHeaders, setTableHeaders] = useState([]);
  const [show, setShow] = useState(false);
  const [status, setStatus] = useState([]);
  const [cancelData, setCancelData] = useState({});
  const [orderCancelResp, setOrderCancelResp] = useState();
  const [cancelStatus, setCancelStatus] = useState(false);

  const [reciveBackData, setReciveBackData] = useState({});
  const [reciveBackResp, setReciveBackResp] = useState();
  const [reciveBackStatus, setReciveBackStatus] = useState(false);
  const [reciveBackShow, setReciveBackShow] = useState(false);
  const [isRecivedBack, setIsReciveBack] = useState(false);

  const [resendOrderData, setResendOrderData] = useState({});
  const [resendOrderResp, setResendOrderResp] = useState();
  const [resendOrderStatus, setResendOrderStatus] = useState(false);
  const [resendOrderShow, setResendOrderShow] = useState(false);

  const [missingItem, setMissingItem] = useState(false);

  const [stockOutResp, setStockOutResp] = useState([]);
  const [dispatchData, setDispatchData] = useState([]);
  const [dispatchShow, setDispatchShow] = useState(false);
  const [refreshPage, setRefreshPage] = useState(false);
  const [singleOrderOBj, setSingleOrderObj] = useState({});
  const [showSingleOrder, setShowSingleOrder] = useState(false);
  const [loader, setLoader] = useState(false);

  const [singleMainOrderOBj, setSingleMainOrderObj] = useState({});
  const [showSingleMainOrder, setShowSingleMainOrder] = useState(false);
  const [isModalLoading, setIsModalLoading] = useState(false);

  const [adminComment, setAdminComment] = useState('');
  const [orderDateFrom, setorderDateFrom] = useState('');
  const [orderDateTo, setorderDateTo] = useState('');

  // const handleClose = () => setShow(false);
  // const handleLockClose = () => setLockModalShow(false);

  let navigate = useNavigate();
  let convertedObj;

  let inProgressHeaders = ['Order No.', 'Order Date', 'Customer', 'Action'];
  let inTransitHeaders = ['Order No.', 'Order Date', 'Customer', 'Total Amount', 'Action'];
  let deliveredHeaders = ['Order No.', 'Order Date', 'Customer', 'Total Amount', 'Action'];
  let customerRequestHeaders = ['Request Type', 'Order No.', 'Sub Order No.', 'Customer Name', 'Request Date', 'Request Status', 'Action', ''];
  let cancelledHeaders = ['Order No.', 'Order Date', 'Customer', 'Total Amount', 'Action'];

  let headersMap = new Map();
  headersMap.set(1, inProgressHeaders);
  headersMap.set(2, inTransitHeaders);
  headersMap.set(3, deliveredHeaders);
  headersMap.set(4, customerRequestHeaders);
  headersMap.set(5, cancelledHeaders);

  let statusArr = [];
  useEffect(() => {
    const getState = async () => {
      const statusResp = await axios.get(`${app.URL}/api/v1/admin/ecomorders/orderscount`, { headers: getHeaders() });
      statusResp.data ? console.log(statusResp.data.result.data) : console.log('data');
      statusResp.status == 200 ? setStatus(statusResp.data.result.data) : ' ';
      console.log(status);
    };
    getState();
  }, [refreshPage]);
  console.log(status);

  const user = JSON.parse(localStorage.getItem('user'));
  if (user.userType === 'A') setEditBtn('block');
  const userRequestListingAPI = async (formData) => {
    console.log(formData);
    const partnerResp = await axios.post(`${app.URL}/api/v1/admin/userrequest/view`, formData, { headers: getHeaders() });
    // console.log(partnerResp)
    partnerResp ? console.log(partnerResp.data.result.data) : '';
    partnerResp ? (convertedObj = dateConverter(partnerResp.data.result.data.data, 'createdAt')) : ' ';
    partnerResp.data.result ? setFetchPartners(convertedObj) : ' ';
    partnerResp.data.result ? setPagesCount(partnerResp.data.result.data.count) : ' ';
    partnerResp.data.result ? setRenderList(convertedObj) : ' ';
  };

  const getData = async () => {
    let formData = {
      limit: pageSize,
      offset: 0,
      searchBy: '',
      searchTxt: '',
    };
    console.log(formData);
    const partnerResp = await axios.post(`${app.URL}/api/v1/admin/userrequest/view`, formData, { headers: getHeaders() });
    console.log(partnerResp);
    partnerResp ? console.log(partnerResp.data.result.data) : ' ';
    partnerResp.data.result ? (convertedObj = await dateConverter(partnerResp.data.result.data.data, 'createdAt')) : ' ';
    partnerResp.data.result ? setFetchPartners(convertedObj) : ' ';
    partnerResp.data.result ? setPagesCount(partnerResp.data.result.data.count) : ' ';
    partnerResp.data.result ? setRenderList(convertedObj) : ' ';
    return console.log('return called');
  };
  const onRequestChange = async (page, pageSize) => {
    console.log(page, pageSize);
    setPageSize(pageSize);
    setCurrentPage(page);
    if (!page) page = 1;
    let limit = pageSize;
    try {
      let reqObj;
      if (Object.keys(searchShow).length !== 0) {
        reqObj = {
          limit: limit,
          offset: limit * (page - 1),
          // searchBy: searchShow.searchFor,
          // searchTxt: searchShow.searchItem,
        };
      } else {
        reqObj = {
          limit: limit,
          offset: limit * (page - 1),
          // searchBy: 'orderNo.',
          // searchTxt: '',
        };
      }
      console.log(reqObj);
      await userRequestListingAPI(reqObj);
    } catch (e) {
      console.log('Error');
      if (e.message) setErr(e.message);
      setTableShow(false);
    }
  };
  const onPageChange = async (page, pageSize) => {
    console.log(page, pageSize);
    setPageSize(pageSize);
    setCurrentPage(page);
    if (!page) page = 1;
    let limit = pageSize;
    try {
      let reqObj;
      if (Object.keys(searchShow).length !== 0) {
        reqObj = {
          limit: limit,
          offset: limit * (page - 1),
          searchBy: searchShow.searchBy,
          searchTxt: searchShow.searchTxt,
        };
      } else {
        reqObj = {
          limit: limit,
          offset: limit * (page - 1),
          searchBy: 'orderNo.',
          searchTxt: '',
        };
      }
      console.log(reqObj);
      await getInProgressData(reqObj);
    } catch (e) {
      console.log('Error');
      if (e.message) setErr(e.message);
      setTableShow(false);
    }
  };

  //Approve and Refund Workflow
  const handleCancelModalClose = () => setCancelShow(false);
  const cancelShipment = async (data) => {
    try {
      let resp = await axios.post(`${app.URL}/api/v1/admin/userrequest/cancelshipment`, data, { headers: getHeaders() });
      console.log('resp');
      setOrderCancelResp(resp.data.result);
      setCancelStatus(true);
      setTimeout(() => {
        setCancelShow(false);
      }, 500);
      await getData();
    } catch (e) {
      throw e;
    }
  };
  const onCancelTrigger = (data) => {
    setOrderCancelResp({});
    console.log(data);
    let cancelObj = {
      requestId: data.requestId,
      patientId: data.patientId,
      ecomOrderId: data.ecomOrderId,
      addressId: data.addressId,
    };

    console.log(cancelObj);
    setCancelShow(true);
    setCancelData(cancelObj);
  };
  const onCancelClick = async () => {
    console.log(cancelData);
    await cancelShipment(cancelData);
  };
  // Recive back order work flow

  const handleReciveBackModalClose = () => setReciveBackShow(false);
  const reciveorder = async (data) => {
    try {
      let resp = await axios.post(`${app.URL}/api/v1/admin/ecomorders/receiveback`, data, { headers: getHeaders() });
      console.log('resp');
      setReciveBackResp(resp.data.result);
      setReciveBackStatus(true);
      setTimeout(() => {
        setReciveBackShow(false);
      }, 500);
      await getData();
    } catch (e) {
      throw e;
    }
  };
  const onReciveBackTrigger = (data) => {
    setReciveBackResp({});
    console.log(data);
    let recivebackObj = {
      requestId: data.requestId,
      patientId: data.patientId,
      ecomOrderId: data.ecomOrderId,
      addressId: data.addressId,
    };

    console.log(recivebackObj);
    setReciveBackShow(true);
    setReciveBackData(recivebackObj);
  };
  const onReciveBackClick = async () => {
    console.log(reciveBackData);
    await reciveorder(reciveBackData);
  };

  const handleResendOrderModal = () => setResendOrderShow(false);
  const resendOrder = async (data) => {
    try {
      let resp = await axios.post(`${app.URL}/api/v1/admin/ecomorders/resendorder`, data, { headers: getHeaders() });
      console.log('resp');
      setResendOrderResp(resp.data.result);
      setResendOrderStatus(true);
      setTimeout(() => {
        setResendOrderShow(false);
      }, 500);
      await getData();
    } catch (e) {
      throw e;
    }
  };
  const onResendOrderTrigger = (data) => {
    setResendOrderResp({});
    console.log(data);
    if (data.requestType == 'Missing Item' || data.requestType == 'missing item') {
      setMissingItem(true);
    }
    let resendOrderObj = {
      requestId: data.requestId,
      patientId: data.patientId,
      ecomOrderId: data.ecomOrderId,
      addressId: data.addressId,
    };

    console.log(resendOrderObj);
    setResendOrderShow(true);
    setResendOrderData(resendOrderObj);
  };
  const onResendOrderonClick = async () => {
    console.log(resendOrderData);
    await resendOrder(resendOrderData);
  };

  //Resend Order Work flow End
  console.log(renderList);

  const getInProgressData = async (formData) => {
    console.log(formData);
    setLoader(true);
    setSearchShow(formData);

    const partnerResp = await axios.post(`${app.URL}/api/v1/admin/ecomorders/vieworders/2`, formData, { headers: getHeaders() });
    console.log('calling again data');
    console.log(partnerResp);
    partnerResp ? console.log(partnerResp.data.result.data) : ' ';
    partnerResp.data.result ? (convertedObj = await dateConverter(partnerResp.data.result.data.data, 'createdAt')) : ' ';
    partnerResp.data.result ? setFetchPartners(convertedObj) : ' ';
    partnerResp.data.result ? setPagesCount(partnerResp.data.result.data.count) : ' ';
    partnerResp.data.result ? setRenderList(convertedObj) : ' ';
    partnerResp.data.result && setLoader(false);

    return console.log('return called');
  };

  useEffect(() => {
    setTableHeaders(headersMap.get(1));
    let formData = {
      limit: pageSize,
      offset: 0,
      searchBy: '',
      searchTxt: '',
    };
    getInProgressData(formData);
  }, [refreshPage]);

  const { Panel } = Collapse;
  const customExpandIcon = (panelProps) => {
    const { isActive } = panelProps;

    return <span>{isActive ? <CaretDownOutlined /> : <CaretRightOutlined />}</span>;
  };

  const onCollapseChange = async (key) => {
    console.log('Collapse change:', key);
    console.log(key);
  };
  console.log(subOrderObj);
  const onViewClick = (data) => {
    console.log('onViewClick');
    console.log(data.ecomOrderId);
    localStorage.setItem('orderId', JSON.stringify(data.ecomOrderId));
    navigate('/ecomorders/view', { replace: true });
  };

  const onSearchFinish = async (values) => {
    console.log('onSearchFinish');
    console.log('Success:', values);
    let formData = {
      limit: 10,
      offset: 0,
      searchBy: values.searchBy, //partnerName, serialNo, orderNo
      searchTxt: values.searchTxt,
      // status: activeIndex, //1 = In-Transit (Inprogress), 2 = Sent to Lab, 3 = Completed
      // orderDateFrom: orderDateFrom ? orderDateFrom : '', //2023-03-03  only give date without time
      // orderDateTo: orderDateTo ? orderDateTo : '',
    };
    if (!formData.searchTxt) message.error('Please enter search text');
    else await getInProgressData(formData);
  };

  const onSearchFinishFailed = (values) => {
    console.log('Failed:', values);
  };

  const onCardViewClick = (data) => {
    if (data == 1) {
      setActiveIndex(data);
      navigate('/ecom/dashboard/inprogress', { replace: true });
    } else if (data == 2) {
      setActiveIndex(data);
      navigate('/ecom/dashboard/intransit', { replace: true });
    } else if (data == 3) {
      setActiveIndex(data);
      navigate('/ecom/dashboard/delivered', { replace: true });
    } else if (data == 4) {
      setActiveIndex(data);
      navigate('/ecom/dashboard/request', { replace: true });
    } else if (data == 5) {
      setActiveIndex(data);
      navigate('/ecom/dashboard/cancel', { replace: true });
    }
  };

  const [prepareForShipmentData, setPrepareForShipmentData] = useState({});

  const stockPrepareForShipment = async (data) => {
    try {
      let resp = await axios.post(`${app.URL}/api/v1/admin/ecomorders/readytoship`, data, { headers: getHeaders() });
      console.log('prepare shipment resp: ', resp.data);
      setStockOutResp(resp.data.result);
      // setStockOutStatus(true)
      setDispatchShow(true);

      setTimeout(() => {
        setDispatchShow(!dispatchShow);
      }, 500);
      setRefreshPage(true);
      window.location.reload();
      //  let  reqObj = {
      //     limit: pageSize,
      //     offset: 0,
      //     // searchBy: 'orderNo.',
      //     // searchTxt: '',
      //   };
    } catch (error) {
      console.log('error in stock prepare for shipment: ', error);
    }
  };

  const onPrepareShimentTrigger = (data) => {
    console.log('dispatch data: ', data);
    setStockOutResp({});
    setDispatchShow(!dispatchShow);
    setPrepareForShipmentData(data);
  };

  const onPrepareShipmentClick = async () => {
    // console.log("data")
    // console.log(data)
    console.log(prepareForShipmentData);

    let stockOutUploadObj = {
      patientId: prepareForShipmentData?.products[0]?.patientId,
      ecomOrderId: prepareForShipmentData?.products[0]?.eCommOrderId,
      addressId: prepareForShipmentData?.addressId,
      comment: adminComment,
    };

    console.log('api upload obj for prepare shipment: ', stockOutUploadObj);

    await stockPrepareForShipment(stockOutUploadObj);

    setAdminComment('');

    // navigate('/ecom/shipment/prepare', {replace:true})
    setRefreshPage(true);
  };

  const stockOut = async (data) => {
    try {
      let resp = await axios.post(`${app.URL}/api/v1/admin/stockout/create`, data, { headers: getHeaders() });
      console.log('resp');
      setStockOutResp(resp.data.result);
      // setStockOutStatus(true)
      setDispatchShow(true);

      setTimeout(() => {
        setDispatchShow(!dispatchShow);
      }, 500);
    } catch (e) {
      throw e;
    }
  };

  const onDispatchTrigger = (data) => {
    console.log('dispatch data: ', data);
    setStockOutResp({});
    setDispatchShow(!dispatchShow);
    setDispatchData(data);
  };

  const onDispatchClick = async () => {
    // console.log("data")
    // console.log(data)
    console.log(dispatchData);
    let stockOutArr = [];
    dispatchData.products.forEach((ele) => {
      stockOutArr.push({
        productId: ele.productId,
        quantity: ele.quantity,
        unitPrice: ele.unitPrice,
        patientId: subOrderObj.patientId,
        ecomOrderId: subOrderObj.ecomOrderId,
        addressId: dispatchData.addressId,
      });
    });

    await stockOut(stockOutArr);
    console.log('stock out resp: ', stockOutResp);
    // getInProgressData();
    setRefreshPage(true);

    // console.log(stockOutResp)
  };

  const [mainEcomOrderId, setMainEcomOrderId] = useState(null);

  const getMainOrderData = async (id) => {
    try {
      let resp = await axios.get(`${app.URL}/api/v1/admin/ecomorders/view/${id}`, { headers: getHeaders() });
      // console.log("main order get api response: ", resp.data.result.data );
      // setSingleMainOrderObj(resp.data.result.data);
      // setSubOrderObj(resp.data.result.data);

      // console.log("sub order obj modal api before: ", subOrderObj);
      // console.log("main order single obj modal api before: ", singleMainOrderOBj);

      setSingleMainOrderObj(resp.data.result.data);

      console.log('main order obj: ', singleMainOrderOBj);

      setIsModalLoading(!isModalLoading);

      // console.log("sub order obj modal api after: ", subOrderObj);
      // console.log("main order single obj modal api after: ", singleMainOrderOBj);
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  const genExtra = (order) => (
    <table className='w-100'>
      <tbody>
        <tr>
          <td
            className='text-center'
            style={{
              width: '25%',
            }}
          >
            {order.ecomOrderNo}
          </td>
          <td
            className='text-center'
            style={{
              width: '25%',
            }}
          >
            {order.createdAt}
          </td>
          <td
            className='text-center'
            style={{
              width: '25%',
            }}
          >
            {order.patientName ? order.patientName : order.patientEmail}
          </td>
          <td
            className='text-center'
            style={{
              width: '25%',
            }}
          >
            <Button
              onClick={() => {
                // getMainOrderData(order.ecomOrderId);
                mainOrderModal(order);
              }}
              style={{ width: '80px', height: '34px', fontSize: 'small' }}
              size='small'
            >
              View
            </Button>
          </td>
        </tr>
      </tbody>
    </table>
  );

  const [mainOrderObj, setMainOrderObj] = useState({});

  const mainOrderModal = (order) => {
    console.log('Main Order Modal Running');
    const getData = async () => {
      let resp = await axios.get(`${app.URL}/api/v1/admin/ecomorders/view/${order.ecomOrderId}`, { headers: getHeaders() });
      setMainOrderObj(resp.data.result.data);
      setSingleMainOrderObj(resp.data.result.data);
      openMainOrderModal();
    };

    getData();
  };

  const handleOnChange = (e) => {
    if (e.target.value.length === 0) {
      setRefreshPage(!refreshPage);
      setSearchShow({});
    }
  };

  const openMainOrderModal = () => {
    setShowSingleMainOrder(true);
  };

  console.log(subOrderObj);
  useEffect(() => {
    let resp;
    Object.keys(subOrderObj).length > 0 ? (resp = subOrderObj.address.filter((ele) => ele.requestId)) : '';
    console.log(resp);
    resp && resp.length > 0 ? setIsCustomerRequest(true) : setIsCustomerRequest(false);
  }, [subOrderObj]);

  console.log(isCustomerRequest);
  let searchItems = [
    {
      name: 'Order No.',
      value: 'orderNo',
    },
    {
      name: 'Customer Name',
      value: 'patientName',
    },
  ];

  console.log(subOrderObj);

   const durationSelect = async (value) => {
     console.log('value ', value);
     durationSelection(getInProgressData, value, setorderDateFrom, setorderDateTo, activeIndex, searchShow, 10, 0, 'productName');
   };
   const onChange = async (date, dateString) => {
     console.log(date, dateString);
     onChangeMethod(getInProgressData, dateString, setorderDateFrom, setorderDateTo, activeIndex, searchShow, pageSize, pageSize * (currentPage - 1), 'productName');
   };

  return (
    <div className='dashboard-card container-fluid'>
      <EcomDashboardHeader onChange={onChange} durationSelect={durationSelect} />
      <EcommerceCards status={status} onCardViewClick={onCardViewClick} />
      <SearchBar onFinish={onSearchFinish} onFinishFailed={onSearchFinishFailed} searchItems={searchItems} onChange={handleOnChange} />

      {/* <div className='row px-3'>
        
        <div style={{ position: 'relative' }} className='col-md-4 col-12 my-1' onClick={() => onCardViewClick(1)}>
          <EcommerceDashboardCard count={status.length > 1 ? status[1].count : 0} isActive={window.location.pathname == '/ecom/dashboard/inprogress' || window.location.pathname == '/ecom/dashboard'} activatedProducts={true} awaitingProducts={false} cardName={'In Progress'} />
        </div>
        <div style={{ position: 'relative' }} className='col-lg-2 mb-4' onClick={() => onCardViewClick(2)}>
          <EcommerceDashboardCard count={status.length > 1 ? status[2].count : 0} isActive={window.location.pathname == '/ecom/dashboard/intransit'} activatedProducts={true} awaitingProducts={false} cardName={'In Transit'} />
        </div>
        <div style={{ position: 'relative' }} className='col-lg-2 mb-4' onClick={() => onCardViewClick(3)}>
          <EcommerceDashboardCard count={status.length > 1 ? status[3].count : 0} isActive={window.location.pathname == '/ecom/dashboard/delivered'} activatedProducts={false} awaitingProducts={true} cardName={'Delivered'} />
        </div>
        <div style={{ position: 'relative', maxHeight: '150px' }} className='col-lg-2 mb-4' onClick={() => onCardViewClick(4)}>
          <EcommerceDashboardCard count={status.length > 1 ? status[status.length - 1].requestCount : 0} isActive={window.location.pathname == '/ecom/dashboard/request'} activatedProducts={false} awaitingProducts={false} cardName={'Request'} />
        </div>
        <div style={{ position: 'relative' }} className='col-lg-2 mb-4' onClick={() => onCardViewClick(5)}>
          <EcommerceDashboardCard count={status.length > 1 ? status[4].count : 0} isActive={window.location.pathname == '/ecom/dashboard/cancel'} activatedProducts={false} awaitingProducts={false} cardName={'Cancelled/Refund'} />
        </div>
      </div> */}

      {/* orders table to copy*/}

      {loader && <LoaderImage />}
      {renderList.length > 0 && !loader ? (
        <div className='row table-scrollbar '>
          <table
            id='customers'
            // style={{
            //   padding: '5px 0px',
            // }}
          >
            <thead>
              <tr>
                {['Order No.', 'Order Date', 'Customer', 'Action'].map((item) => (
                  <th key={item} style={{ fontWeight: 'bold', width: '25%' }}>
                    {item}
                  </th>
                ))}
              </tr>
            </thead>
          </table>
          <div>
            <Collapse accordion obClexpandIcon={customExpandIcon} onChange={onCollapseChange}>
              {renderList.map((order, index) => (
                <Panel
                  onClick={(e) => {
                    e.stopPropagation();
                    setSubOrderObj(order);
                  }}
                  header={genExtra(order)}
                  key={order.ecomOrderId}
                  className='table-scrollbar '
                >
                  {Object.keys(subOrderObj).length > 0 ? (
                    <table id='customers' className=' w-100'>
                      <thead>
                        <tr
                          style={{
                            fontWeight: 'bold',
                          }}
                        >
                          <th>Sub Order No.</th>
                          <th>Address</th>
                          <th>Total</th>
                          <th>Grand Total</th>
                          <th>Status</th>
                          {isCustomerRequest && <th>Customer Request</th>}
                          <th>Action</th>
                          <th></th>
                        </tr>
                      </thead>

                      {subOrderObj?.address?.map((ele) => (
                        //<div className='mb-2'>
                        <tbody>
                          <tr>
                            <td style={{ width: '150px' }}>
                              {order.ecomOrderNo}-{ele.addressId}
                            </td>
                            <td style={{ width: '250px' }}>{`${ele.address}, ${ele.state}, ${ele.city}, ${ele.zipCode}`}</td>
                            <td style={{ width: '150px' }}>${ele.totalShipmentAmount}</td>
                            <td style={{ width: '150px' }}>${(ele.totalAmountwithShipment + ele.taxAmount)?.toFixed(2)}</td>
                            <td style={{ width: '150px' }}>{ele.wareHouseStatusId ? ele.wareHouseStatus : ''}</td>
                            {isCustomerRequest ? <td style={{ width: '150px' }}>{ele.requestId ? ele.requestType : 'None'}</td> : ''}
                            {(ele.requestStatus === 'Reject' || !ele.requestStatus) && !ele.wareHouseStatus && (
                              <td style={{ width: '150px' }}>
                                <Button
                                  onClick={() => {
                                    onPrepareShimentTrigger(ele);
                                  }}
                                  style={{ width: '180px', height: '34px', fontSize: 'small', marginRight: '0px' }}
                                  size='medium'
                                >
                                  Send to Warehouse
                                </Button>
                              </td>
                            )}
                            <td>
                              <Button
                                onClick={() => {
                                  setSingleOrderObj(ele);
                                  setShowSingleOrder(true);
                                }}
                                style={{ width: '70px', height: '34px', fontSize: 'small', margin: '0px 10px' }}
                                size='medium'
                              >
                                View
                              </Button>
                            </td>
                          </tr>
                        </tbody>

                        // </div>
                      ))}
                    </table>
                  ) : (
                    <div className=' text-center'>
                      <span>
                        {' '}
                        <Space size='middle'>
                          <Spin size='large' />
                        </Space>
                      </span>
                    </div>
                  )}
                </Panel>
              ))}
            </Collapse>
          </div>
        </div>
      ) : (
        <>{!loader && <NoDataFound />}</>
      )}

      {currentPage && pagesCount ? <Pagination style={{ marginTop: '10px' }} onChange={activeIndex == 4 ? onRequestChange : onPageChange} defaultCurrent={currentPage} total={pagesCount} showTotal={(total) => `Total ${total} items`} showSizeChanger /> : ' '}

      {/* show single main order modal start */}

      <MainOrderViewModal
        isVisible={showSingleMainOrder}
        setIsVisible={setShowSingleMainOrder}
        ecomOrderId={singleMainOrderOBj.ecomOrderId}
        ecomOrderNo={singleMainOrderOBj.ecomOrderNo}
        ecomOrderCreatedData={moment(singleMainOrderOBj.createdAt).format('MM-DD-YYYY')}
        // patientName={"singleMainOrderOBj.patientName"}
        productTotalAmount={singleMainOrderOBj.totalProductAmount}
        shipmentTotal={singleMainOrderOBj.totalShipmentCharges}
        subOrdersData={singleMainOrderOBj?.orderDetails}
        isLoading={isModalLoading}
        setIsLoading={setIsModalLoading}
        mainOrderShipmentAmount={singleMainOrderOBj.totalShipmentCharges}
        mainOrderGrandTotal={singleMainOrderOBj.grandTotalAmount}
        totalProductAmount={singleMainOrderOBj.totalProductAmount}
        isApiCallAble={false}
      />

      {/* show single main order modal end */}

      {/* show single sub order modal start */}

      <SubOrderViewModal
        isVisible={showSingleOrder}
        ecomOrderNo={`${subOrderObj.ecomOrderNo}-${singleOrderOBj.addressId}`}
        setIsVisible={setShowSingleOrder}
        address={`${singleOrderOBj.address}, ${singleOrderOBj.state}, ${singleOrderOBj.city}, ${singleOrderOBj.zipCode}`}
        ecomOrderCreatedDate={subOrderObj.createdAt}
        productsData={singleOrderOBj?.products}
        status={singleOrderOBj.status}
        title={singleOrderOBj.title}
        shipmentCharges={singleOrderOBj.shipmentCharges}
        grandTotal={singleOrderOBj.totalAmountwithShipment}
        totalShipmentAmount={singleOrderOBj.totalShipmentAmount}
        tax={singleOrderOBj.taxAmount}
        requestId={singleOrderOBj.requestId}
        requestStatus={singleOrderOBj.requestStatus}
        requestType={singleOrderOBj.requestType}
        shipmePackageName={singleOrderOBj.shipmePackageName}
        addressId={singleOrderOBj.addressId}
        ecomOrderId={subOrderObj.ecomOrderId}
        isApiCall={true}
        locationURL={window.location.pathname}
        wareHouseStatus={singleOrderOBj.wareHouseStatus}
      />

      {/* show single sub order modal end */}

      {/* dispatch order modal start */}

      {stockOutResp && stockOutResp.code === 200 ? (
        <Modal show={dispatchShow} onHide={handleCancelModalClose}>
          <Modal.Header closeButton>
            <Modal.Title className='text-center'>Success</Modal.Title>
          </Modal.Header>
          <Modal.Body>{stockOutResp.msg}</Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      ) : stockOutResp && stockOutResp.code === 400 ? (
        <Modal show={dispatchShow} onHide={handleCancelModalClose}>
          <Modal.Header closeButton>
            <Modal.Title className='text-center'>Failed</Modal.Title>
          </Modal.Header>
          <Modal.Body>{stockOutResp.msg}</Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      ) : (
        <Modal show={dispatchShow} onHide={handleCancelModalClose}>
          <Modal.Header closeButton>
            <Modal.Title>Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure, you want to send the order to warehouse for shipment?
            {/* comment start */}
            <div className='row'>
              <div className='col-lg-12 mt-4'>
                <label>Comment</label>
              </div>
              <div className='col lg-12 mt-2'>
                <Form
                  initialValues={{
                    adminComment: adminComment,
                  }}
                >
                  <Row>
                    <Col>
                      <Form.Item
                        // label="Comment"
                        name='adminComment'
                        style={{ padding: '0px !important' }}
                      ></Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item name='adminComment'>
                        <TextArea
                          rows={3}
                          autoSize={false}
                          onChange={(event) => {
                            setAdminComment(event.target.value);
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </div>
            </div>
            {/* comment end */}
          </Modal.Body>

          <Modal.Footer>
            <Button
              variant='secondary'
              onClick={() => {
                setAdminComment('');
                setDispatchShow(!dispatchShow);
              }}
            >
              Cancel
            </Button>
            <Button
              variant='primary'
              htmlType='submit'
              onClick={() => {
                // onDispatchClick();
                onPrepareShipmentClick();
              }}
            >
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>
      )}

      {/* dispatch order modal end */}

      {/* Cancel Modal */}
      {orderCancelResp && orderCancelResp.code === 200 ? (
        <Modal show={cancelShow} onHide={handleCancelModalClose}>
          <Modal.Header closeButton>
            <Modal.Title className='text-center'>Success</Modal.Title>
          </Modal.Header>
          <Modal.Body>{orderCancelResp.msg}</Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      ) : orderCancelResp && orderCancelResp.code === 400 ? (
        <Modal show={cancelShow} onHide={handleCancelModalClose}>
          <Modal.Header closeButton>
            <Modal.Title className='text-center'>Failed</Modal.Title>
          </Modal.Header>
          <Modal.Body>{orderCancelResp.msg}</Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      ) : (
        <Modal show={cancelShow} onHide={handleCancelModalClose}>
          <Modal.Header closeButton>
            <Modal.Title>Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to cancel shipment?</Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={handleCancelModalClose}>
              Cancel
            </Button>
            <Button variant='primary' htmlType='submit' onClick={onCancelClick}>
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>
      )}

      {/* Recive Back Modal */}
      {reciveBackResp && reciveBackResp.code === 200 ? (
        <Modal show={reciveBackShow} onHide={handleReciveBackModalClose}>
          <Modal.Header closeButton>
            <Modal.Title className='text-center'>Success</Modal.Title>
          </Modal.Header>
          <Modal.Body>{reciveBackResp.msg}</Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      ) : reciveBackResp && reciveBackResp.code === 400 ? (
        <Modal show={reciveBackShow} onHide={handleReciveBackModalClose}>
          <Modal.Header closeButton>
            <Modal.Title className='text-center'>Failed</Modal.Title>
          </Modal.Header>
          <Modal.Body>{reciveBackResp.msg}</Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      ) : (
        <Modal show={reciveBackShow} onHide={handleReciveBackModalClose}>
          <Modal.Header closeButton>
            <Modal.Title>Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure, you want to recive shipment back?</Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={handleReciveBackModalClose}>
              Cancel
            </Button>
            <Button variant='primary' htmlType='submit' onClick={onReciveBackClick}>
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>
      )}

      {/* Resend Order Modal */}
      {resendOrderResp && resendOrderResp.code === 200 ? (
        <Modal show={resendOrderShow} onHide={handleResendOrderModal}>
          <Modal.Header closeButton>
            <Modal.Title className='text-center'>Success</Modal.Title>
          </Modal.Header>
          <Modal.Body>{resendOrderResp.msg}</Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      ) : resendOrderResp && resendOrderResp.code === 400 ? (
        <Modal show={resendOrderShow} onHide={handleResendOrderModal}>
          <Modal.Header closeButton>
            <Modal.Title className='text-center'>Failed</Modal.Title>
          </Modal.Header>
          <Modal.Body>{resendOrderResp.msg}</Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      ) : (
        <Modal show={resendOrderShow} onHide={handleResendOrderModal}>
          <Modal.Header closeButton>
            <Modal.Title>Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>{missingItem ? `Are you sure, you want to send the missing item?` : `Are you sure, you want to resend the order?`}</Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={handleResendOrderModal}>
              Cancel
            </Button>
            <Button variant='primary' htmlType='submit' onClick={onResendOrderonClick}>
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
};

export default InProgress;
