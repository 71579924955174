import { Form, Select, Upload } from 'antd';
import { useEffect, useState } from 'react';
import { InputField, SelectField } from '../../layouts/FormFields';
import TestComponent from './TestComponent';
import { app } from '../../config';
import AddPhoto from '../../assets/images/add.png';
import { LoadingOutlined } from '@ant-design/icons';

export const MeasuredForm = ({ setwhatMeasuredData, whatMeasuredData, screen, createWhatMeasured, onHide, measuredModal, renderListType, MeasuredCategoriesArray, beforeUpload, imagerenderuploading, setimagerenderuploading, header, updateWhatMeasured }) => {
  const [description, setDescription] = useState('');
  const [renderType, setRenderType] = useState(null);
  const [category, setCategory] = useState(null);
  const [renderuploadedImg, setrenderuploadedImg] = useState('');
  const [descriptionLoader, setdescriptionLoader] = useState(true);
  const [errorMsg, setErrorMsg] = useState('');
  const [errorType, setErrorType] = useState('');

  setTimeout(() => {
    setimagerenderuploading(false);
    setdescriptionLoader(false);
  }, 500);

  const handlerenderChange = (info) => {
    console.log(info);
    setimagerenderuploading(true);
    // if (info.file.status === 'uploading') {
    // }
    if (info.file.status === 'done') {
      if (info.file) {
        // whatMeasuredData[elementKey].image = info?.file?.response?.result?.data?.Location;
        setimagerenderuploading(false);
        setrenderuploadedImg(info?.file?.response?.result?.data?.Location);
      }
    }
  };

  console.log(measuredModal, screen, whatMeasuredData, MeasuredCategoriesArray);
  console.log(category);

  useEffect(() => {
    if (measuredModal) {
      setDescription(measuredModal?.data);
      setrenderuploadedImg(measuredModal?.image);
      setCategory(measuredModal?.productWhatMeasuredId);
      setRenderType(measuredModal?.renderType);
    }
  }, []);

  const onFinish = (values) => {
    values.data = description;
    values.image = renderuploadedImg;
    values.renderType = renderType;
    values.productWhatMeasuredId = category;
    console.log(values);
    if (!category) {
      setErrorMsg('Select a category');
      setErrorType('cat');
      return;
    } else if (!renderType) {
      setErrorMsg('Select a render type');
      setErrorType('render');
      return;
    } else {
      setErrorMsg('');
      setErrorType('');
      // update whatMeaured
      if (measuredModal) {
        if (screen === 'add') {
          // this is for handle add product screen
          const filter = whatMeasuredData.filter((item) => item.productWhatMeasuredId !== values.productWhatMeasuredId);
          setwhatMeasuredData([...filter, values]);
          onHide();
        } else {
          // this is for handle update product screen
          updateWhatMeasured(values);
          onHide();
        }
      }
      // add whatMeaured
      else {
        if (screen === 'add') {
          // this is for handle add product screen
          setwhatMeasuredData([...whatMeasuredData, values]);
          onHide();
        } else {
          // this is for handle update product screen
          createWhatMeasured(values);
          onHide();
        }
      }
    }
  };

  const onFinishFailed = () => {};
  return (
    <Form
      initialValues={{
        // ['productWhatMeasuredId']: measuredModal?.productWhatMeasuredId,
        // ['renderType']: measuredModal?.renderType,
        ['productWhatMeasuredElementId']: measuredModal?.productWhatMeasuredElementId,
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      className='row w-100'
    >
      <div className='d-none'>
        <InputField name='productWhatMeasuredElementId' />
      </div>
      {/* <SelectField
        col='col-md-6'
        name='productWhatMeasuredId'
        placeholder='Select Category'
        rules={[
          {
            required: true,
            message: 'Select a category',
          },
        ]}
        label='Category'
        required={true}
        options={MeasuredCategoriesArray.map((item) => (
          <Select.Option value={item.productWhatMeasuredId}>{item.title}</Select.Option>
        ))}
      /> */}

      {/* <SelectField
        col='col-md-6'
        placeholder='Select Render Type'
        name='renderType'
        rules={[
          {
            required: true,
            message: 'Select a render type',
          },
        ]}
        label='Render Type'
        required={true}
        options={renderListType.map((item) => (
          <Select.Option value={item.value}>{item.title}</Select.Option>
        ))}
      /> */}

      <div className='col-md-6 mb-3'>
        <div className='form-floating'>
          <select
            onChange={(e) => {
              setCategory(Number(e.target.value));
              setErrorMsg('');
              setErrorType('');
            }}
            value={category}
            className='form-select'
            id='floatingSelectGrid'
          >
            <option selected>Open to select category</option>
            {MeasuredCategoriesArray.map((item) => {
              // console.log(!whatMeasuredData.some((data) => data.productWhatMeasuredId !== item.productWhatMeasuredId));
              if (whatMeasuredData.length > 0) {
                if(!measuredModal){
                  const findItemInWhatMeasured = whatMeasuredData.find((data) => data.productWhatMeasuredId === item.productWhatMeasuredId);
                  if (!findItemInWhatMeasured) return <option value={item.productWhatMeasuredId}>{item.title}</option>;
                }
                else return <option value={item.productWhatMeasuredId}>{item.title}</option>; 
              } else {
                return <option value={item.productWhatMeasuredId}>{item.title}</option>;
              }
            })}
          </select>
          <label for='floatingSelectGrid'>Category</label>
          {errorType === 'cat' && <span className='ant-form-item-explain-error'>{errorMsg}</span>}
        </div>
      </div>

      <div className='col-md-6 mb-3'>
        <div className='form-floating'>
          <select
            onChange={(e) => {
              setRenderType(e.target.value);

              setErrorMsg('');
              setErrorType('');
            }}
            value={renderType}
            className='form-select'
            id='floatingSelectGrid'
          >
            <option selected>Open to select render type</option>

            {renderListType.map((item) => (
              <option value={item.value}>{item.title}</option>
            ))}
          </select>
          <label for='floatingSelectGrid'>Render Type</label>
          {errorType === 'render' && <span className='ant-form-item-explain-error'>{errorMsg}</span>}
        </div>
      </div>

      <div className='col-lg-9 col-12'>
        {!descriptionLoader && (
          <TestComponent
            textvalue={description}
            onChange={(htmlContent) => {
              setDescription(htmlContent);
            }}
            title='Description'
          />
        )}
        {descriptionLoader && <TestComponent textvalue='' title='Description' />}
      </div>

      <Form.Item className='col-lg-3 col-12 pt-3'>
        <Upload
          listType='picture-card'
          className='avatar-uploader'
          showUploadList={false}
          action={`${app.URL}/api/v1/general/product/logoUpload`}
          beforeUpload={beforeUpload}
          onChange={(e) => {
            setTimeout(() => {
              handlerenderChange(e);
            }, 500);
          }}
          headers={header}
        >
          {renderuploadedImg && !imagerenderuploading ? (
            <img className='added-img' src={renderuploadedImg} alt='avatar' />
          ) : imagerenderuploading ? (
            <LoadingOutlined style={{ fontSize: '40px' }} />
          ) : (
            <div className='d-flex flex-column py-3'>
              <img className='for-add-img' src={AddPhoto} alt='avatar' />
              <p className='add-text'>Upload Image</p>
            </div>
          )}
        </Upload>

        {renderuploadedImg && (
          <>
            <button style={{ backgroundColor: '#F21D2F' }} onClick={() => setrenderuploadedImg('')} className='add-btn'>
              Remove
            </button>
            <br />
          </>
        )}
      </Form.Item>

      <div className='col-12 pt-3 d-flex gap-3 justify-content-center'>
        <button onClick={onHide} className={`btn btn-primary patient-cancel-btn button w-25 mb-4`}>
          Cancel
        </button>
        <button type='submit' className={`btn btn-primary button w-25 mb-4`}>
          {measuredModal ? 'Update' : 'Add'} Details
        </button>
      </div>
    </Form>
  );
};
