import React from 'react';
import QRCode from 'react-qr-code';
import logoSrc from "../assets/images/k-logo-b.png";
// import {QRCodeCanvas} from "qrcode.react";
const QRCodeLogo = ({ value, flag }) => {
  
  return (
    <div id='canvas' className='d-flex flex-column align-items-center' style={{ position: 'relative' }}>
      {/* Generate the QR code */}
      <QRCode size={flag === 'G' ? 80 : 60} value={value} />
      {/* <QRCodeCanvas
        value={value}
        size={flag === 'G' ? 80 : 60}
        includeMargin={false}
        imageSettings={{
          src: logoSrc,
          width: `${flag === 'G' ? 24 : 15}`,
          height: `${flag === 'G' ? 24 : 15}`,
          excavate: true,
        }}
      /> */}
      <small style={{ fontSize: '0.4rem', margin: '0', position: 'absolute', bottom: '-10px', fontWeight: '700' }}>{value.split('-')[1]}</small>

      {/* Overlay the logo in the center */}
      <div
        style={{
          width: `${flag === 'G' ? '12%' : '12%'}`,
          display: "none",
          position: 'absolute',
          overflow: 'hidden',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          zIndex: 9999,
          backgroundColor: "#fff",
          borderRadius: '50%',
        }}
      >
        <img src={logoSrc}  alt='Logo' width={100} height={100} />
      </div>
    </div>
  );
};

export default QRCodeLogo;
