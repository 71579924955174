import React from 'react';

const NoDataFound = () => {
  return (
    <div className='row'>
      <div className='col-lg-12 text-center fw-bold p-4'>No Data Found</div>
    </div>
  );
};

export default NoDataFound;
