import moment from 'moment';
import React from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import { RxCrossCircled } from 'react-icons/rx';

// const renderGenericBoxTable = (data) => {
//   return (
//     <>
//       <td>{data.batchId ? data.batchId : 'null'}</td>
//       <td>{data.serialNo ? data.serialNo : 'null'}</td>
//       <td>{data.isBind ? 'True' : 'False'}</td>

//       <td>{data.createdAt ? moment(data.createdAt).format('MM-DD-YYYY hh:mm A') : 'null'}</td>

//       {/* <td>
//       <button onClick={() => viewBatchDetails(tableValues)}>ClickHere</button>
//     </td> */}
//     </>
//   );
// };

// const renderproductMapping = (data) => {
//   return (
//     <>
//       <td>{data.genericBoxSerial ? data.genericBoxSerial : 'null'}</td>
//       <td>{data.productSerial ? data.productSerial : 'null'}</td>
//       <td>{data.returnLabelTracking ? data.returnLabelTracking : 'null'}</td>

//       <td>{data.createdAt ? moment(data.createdAt).format('MM-DD-YYYY hh:mm A') : 'null'}</td>

//       {/* <td>
//       <button onClick={() => viewBatchDetails(tableValues)}>ClickHere</button>
//     </td> */}
//     </>
//   );
// };

// const renderEcomTable = (data) => {
//   return (
//     <>
//       <td>{data.productName ? data.productName : 'null'}</td>
//       <td>{data.ecomOrderNo ? data.ecomOrderNo : 'null'}</td>
//       <td>{data.verifiedBoxNo ? data.verifiedBoxNo : 'null'}</td>

//       <td>{data.verifiedSleeveNo ? data.verifiedSleeveNo : 'null'}</td>
//       <td>{data.returnLabelTracking ? data.returnLabelTracking : 'null'}</td>
//       <td>{data.createdAt ? moment(data.createdAt).format('MM-DD-YYYY hh:mm A') : 'null'}</td>

//       {/* <td>
//       <button onClick={() => viewBatchDetails(tableValues)}>ClickHere</button>
//     </td> */}
//     </>
//   );
// };

// const renderTestOrder = (data) => {
//   return (
//     <>
//       <td>{data.orderNo ? data.orderNo : 'null'}</td>
//       <td>{data.accessionNo ? data.accessionNo : 'null'}</td>
//       <td>{data.cisPanelCode ? data.cisPanelCode : 'null'}</td>

//       <td>{data.cisPanelName ? data.cisPanelName : 'null'}</td>
//       <td>{data.orderDate ? moment(data.orderDate).format('MM-DD-YYYY hh:mm A') : 'null'}</td>
//       <td>{data.collectionTime ? moment(data.collectionTime).format('MM-DD-YYYY hh:mm A') : 'null'}</td>
//       <td>{data.sendToLabDateTime ? moment(data.sendToLabDateTime).format('MM-DD-YYYY hh:mm A') : 'null'}</td>

//       {/* <td>
//       <button onClick={() => viewBatchDetails(tableValues)}>ClickHere</button>
//     </td> */}
//     </>
//   );
// };

// const renderTestResult = (data) => {
//   return (
//     <>
//       <td>{data.orderNo ? data.orderNo : 'null'}</td>
//       <td>{data.patientName ? data.patientName : 'null'}</td>
//       <td>{data.accessionNumber ? data.accessionNumber : 'null'}</td>

//       <td>{data.cisPanelCode ? data.cisPanelCode : 'null'}</td>
//       <td>{data.cisPanelName ? data.cisPanelName : 'null'}</td>
//       <td>{data.observationDateTime ? moment(data.observationDateTime).format('MM-DD-YYYY hh:mm A') : 'null'}</td>
//       <td>{data.specimenReceivedDateTime ? moment(data.observationDateTime).format('MM-DD-YYYY hh:mm A') : 'null'}</td>

//       {/* <td>
//       <button onClick={() => viewBatchDetails(tableValues)}>ClickHere</button>
//     </td> */}
//     </>
//   );
// };

const renderSearchTable = (data, handleClick) => {
  console.log('data', data);
  return (
    <>
      {data?.tableBody?.map((tbody, idx) => (
        <>
          <td className='cursor-pointer' onClick={() => handleClick(tbody?.ecomOrderNo, 'ecommerceOrder')}>
            {tbody?.ecomOrderNo}
          </td>
          <td className=''>{tbody?.productName}</td>
          <td className='cursor-pointer' onClick={() => handleClick(tbody?.serialNo, 'serialNo')}>
            {tbody?.serialNo}
          </td>
          <td className='cursor-pointer' onClick={() => handleClick(tbody?.genericBox, 'genericBox')}>
            {tbody?.genericBox}
          </td>
          <td className=''>{tbody?.returnLabel}</td>
          <td className='cursor-pointer' onClick={() => handleClick(tbody?.orderNo, 'orderNo')}>
            {tbody?.orderNo === null ? '-' : tbody?.orderNo}
          </td>
          <td className='text-center cursor-pointer' onClick={() => handleClick(tbody?.accessionNo, 'accessionNo')}>
            {tbody?.accessionNo === null ? '-' : tbody?.accessionNo}
          </td>
          <td className='text-center'>
            <div className='d-flex align-items-center justify-content-center'>{tbody?.testResultId === null ? <RxCrossCircled  color='red' size={20} /> : <FaCheckCircle color='green' size={20} />}</div>
          </td>
        </>
      ))}
    </>
  );
};

// const SearchOrderTable = ({tableName,tableHeaders=[],tableBody=[]}) => {
//   return (
//     <>
//       <div
//         className='table-scrollbar'
//         style={{
//           overflowX: 'auto',
//         }}
//       >
//         <table id='customers' className='w-100'>
//           <thead class=''>
//             <tr>
//               {tableHeaders.map((header) => (
//                 <th>{header}</th>
//               ))}
//             </tr>
//           </thead>
//           <tbody>
//             {tableBody?.map((data, idx) => (
//               <tr key={idx}>
//                 {data && tableName === 'product_serial' && renderGenericBoxTable(data)}
//                 {data && tableName === 'generic_Box' && renderGenericBoxTable(data)}
//                 {data && tableName === 'product_mapping' && renderproductMapping(data)}
//                 {data && tableName === 'ecommerce_order' && renderEcomTable(data)}
//                 {data && tableName === 'test_order' && renderTestOrder(data)}
//                 {data && tableName === 'test_result' && renderTestResult(data)}
//               </tr>
//             ))}
//           </tbody>
//         </table>
//       </div>
//     </>
//   );
// }

const SearchOrderTable = ({ tableHeaders = [], tableBody = [], searchOrderTable, handleClick }) => {
  console.log(searchOrderTable);
  console.log(tableBody);
  return (
    <>
      <div
        className='table-scrollbar'
        style={{
          overflowX: 'auto',
        }}
      >
        <table id='customers' className='w-100 '>
          <thead class=''>
            <tr>
              {tableHeaders.map((header) => (
                <th>{header}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {tableBody?.map((data, idx) => (
              <tr key={idx}>{searchOrderTable && renderSearchTable(data, handleClick)}</tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default SearchOrderTable;
