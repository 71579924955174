import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { message, Select, Form, Input } from 'antd';
import { app } from '../../config';
import getHeaders from '../../utils/authHeaders';
import useFetchStates from '../../hooks/fetchStates';
import getPartnerById from '../../hooks/getPartnerById';
import useFetchStores from '../../hooks/fetchStores';
import { UpdateRecord } from "../../layouts/UpdateRecord";
import { InputField, InputMasker, SelectField, TextAreaField } from '../../layouts/FormFields';
import { noNumbers, noPrespace, noPrespaceNoAlphabetsNoSpecialChar } from '../user/ValidationFunctions';

const EditPartner = () => {
  const { t } = useTranslation(['common']);
  const [phoneNumber, setPhoneNumber] = useState();
  const [addUserResp, setAddUserResp] = useState({});
  const [userImage, setUserImage] = useState(false);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  // const [logo,setLogo]=useState('');
  const [uploadLogo, setUploadLogo] = useState(true);
  const [imageuploading, setImageUploading] = useState(false);
  const [uploadedImg, setUploadedImg] = useState('');
  let navigate = useNavigate();
  let partnerId = JSON.parse(localStorage.getItem('editPartnerId'));
  const [zipInput, setZipInput] = useState();

  const partnerObj = getPartnerById(partnerId, setZipInput, setUserImage, setUploadedImg);

  const partnerData = partnerObj.response && partnerObj.response.data;
  console.log('partnerData');
  console.log(userImage);

  const fetchStoresResponse = useFetchStores();
  const storesList = fetchStoresResponse.response ? fetchStoresResponse.response.data : [];
  console.log(storesList);
  const [uploadObj, setUploadObj] = useState({});
  
  const stateResponse = useFetchStates();
  const states = stateResponse.response ? stateResponse.response.data : [];

  // const [tooltip, setTooltip] = useState('');
  const handleInputZip = (e) => {
    // setTooltip('');
    const inputValue = e.target.value;
    if (inputValue.length <= 5) {
      setZipInput(inputValue);
      // setTooltip('');
    }
    // if (inputValue.includes('0000') || inputValue.includes('00000')) {
    //   setTooltip('0000 or 00000 not valid zip');
    // }
  };

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  };
    let user = JSON.parse(localStorage.getItem('user'));
  const uploadData = async (userDetails) => {
    console.log(userDetails);
    let userId = user?.result.data.user.userId;
    userDetails.userId = userId;
    setLoading(true);
    try {
      const res = await axios.post(`${app.URL}/api/v1/admin/partner/update/${partnerId}`, userDetails, { headers: getHeaders() });
      setLoading(false);
      setShow(false);
      
      if (res.data.result.success) {
        setAddUserResp(res.data.result.data);
        message.success(res.data.result.msg);
        setTimeout(() => {
          navigate('/partner/listing');
        }, 1000);
      } else if (!res.data.result.success) {
        message.warning(res.data.result.msg);
      }
    } catch (err) {
      console.log(err);
    setLoading(false);
setShow(false);
      if (err) console.log(err.response.data.message);
      else return;
    }
  };
 
  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
  };
  const handleuploadChange = async (info) => {
    if (info.file.status === 'uploading') {
      setUserImage(false);
      setImageUploading(true);
      // setLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      info.file ? console.log(info.file.response.result.data.Location) : console.log('Not found');
      info.file ? setUploadedImg(info.file.response.result.data.Location) : ' ';
      getBase64(info.file.originFileObj, (url) => {
        setImageUploading(false);
        // setLoading(false);
        setUploadLogo(false);
      });
    }
  };
  const handleChange = (event) => {
    setPhoneNumber(event.target.value);
  };
  const onFinish = (values) => {
    let selectedStoresList = [];
    console.log('values ', values);
    values.stores.map((item) => {
      let store = storesList.filter((st) => st.storeId == item);
      console.log('Stores ', store);
      store.map((sto) => {
        let storeObj = {
          partnerStoreName: sto.name,
          storeId: sto.storeId,
        };
        selectedStoresList.push(storeObj);
      });
    });
    setUploadObj({
      code: values.code ? values.code : null,
      partnerName: values.firstName ? values.firstName : null,
      address: values.streetAddress ? values.streetAddress : '',
      website: values.website ? values.website : null,
      logoUrl: uploadedImg ? uploadedImg : null,
      stateId: values.state ? values.state : null,
      mobile: values.phone ? values.phone : '',
      city: values.city ? values.city : null,
      email: values.email ? values.email : null,
      zipCode: zipInput ? zipInput : null,
      partnerStore: selectedStoresList,
      partnerContact: values.contactPersons.length > 0 ? values.contactPersons : [],
      // userType: 'O',
      isActive: values.isActive,
    });
    setShow(true);
  };
 
  
  const submitData = () => {
    if (Object.keys(uploadObj).length > 0) {
      if (!phoneNumber && partnerData.partner.mobile.length < 12) {
        console.log('A');
        setShow(false);
        message.error('Invalid mobile number');
        return;
      } else if (phoneNumber?.length < 12) {
        console.log('B');
        setShow(false);
        message.error('Invalid mobile number');
        return;
      }
      else if (zipInput.includes('00000')) {
      setShow(false);
      message.error('0000 or 00000 zip code is not acceptable!');
    }
      else uploadData(uploadObj);
    }
  };
  const handleClose = () => setShow(false);
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  function goBack() {
    navigate('/partner/listing', { replace: true });
  }
  window.addEventListener('popstate', goBack);

  console.log(partnerData);
 const handleKeysPress = (e) => {
   console.log(e);
   noPrespace(e);
   noNumbers(e);
 };

 console.log('partnerData?.partnerContact', partnerData?.partnerContact);
  return (
    <UpdateRecord
      loading={loading}
      pageTitle='Update Partner'
      upload={true}
      addUserResp={addUserResp}
      goBack={goBack}
      handleuploadChange={handleuploadChange}
      beforeUpload={beforeUpload}
      handleClose={handleClose}
      response={partnerObj?.response}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      uploadedImg={uploadedImg}
      setUploadedImg={setUploadedImg}
      switchBtn={true}
      userImage={userImage}
      setUserImage={setUserImage}
      show={show}
      imageuploading={imageuploading}
      updateMessage='Are you sure you want to update partner?'
      action={`${app.URL}/api/v1/general/user/imageupload`}
      submitData={submitData}
      initialValues={{
        remember: true,
        ['id']: partnerData?.partner.partnerId,
        ['code']: partnerData?.partner.partnerCode,
        ['firstName']: partnerData?.partner.partnerName,
        ['streetAddress']: partnerData?.partner.address,
        ['city']: partnerData?.partner.cityId,
        ['state']: partnerData?.partner.stateId,
        // ['zipCode']: partnerData?.partner.zipCode,
        ['email']: partnerData?.partner.email,
        ['phone']: partnerData?.partner.mobile,
        ['website']: partnerData?.partner.website,
        ['stores']: partnerData?.partnerStore.map((i) => i.storeId),
        ['contactPersons']: partnerData?.partnerContact,
        ['isActive']: partnerData?.partner.isActive === '1' ? true : false,
      }}
      inputs={[
        <InputField
          col='col-lg-6 col-md-4 col-12'
          rules={[
            {
              required: true,
              message: 'Enter Partner Code',
            },
          ]}
          placeholder='Partner Code'
          name='code'
          label='Partner Code'
          required={true}
          onKeyPress={handleKeysPress}
        />,
        <InputField
          col='col-lg-6 col-md-4 col-12'
          rules={[
            {
              required: true,
              message: 'Enter Partner Name',
            },
          ]}
          placeholder='Partner Name'
          name='firstName'
          label='Partner Name'
          required={true}
          onKeyPress={handleKeysPress}
        />,
        <TextAreaField
          col='col-12'
          rules={[
            {
              required: true,
              message: 'Enter Address',
            },
          ]}
          placeholder='Enter Address'
          name='streetAddress'
          label='Address'
          required={true}
          rows={6}
          showCount={true}
          maxLength={1000}
        />,

        <InputField
          col='col-lg-6 col-md-4 col-12'
          disabled={true}
          rules={[
            {
              required: true,
              message: 'Enter Email',
              type: 'email',
            },
          ]}
          placeholder='Enter Email'
          name='email'
          label='Email'
          required={true}
          onKeyPress={noPrespace}
        />,
        <InputField
          col='col-lg-6 col-md-4 col-12'
          rules={[
            {
              required: true,
              message: 'Enter City',
            },
          ]}
          placeholder='Enter City'
          name='city'
          label='City'
          required={true}
          onKeyPress={handleKeysPress}
        />,
        <InputField
          col='col-lg-6 col-md-4 col-12'
          rules={[
            {
              required: true,
              message: 'Enter Website',
            },
          ]}
          placeholder='Enter Website'
          name='website'
          label='Website'
          required={true}
        />,

        <div className='col-lg-6 col-md-4 col-12'>
          <label>
            ZIP Code <span className='required-star'>*</span>
          </label>
          <Input value={zipInput} onChange={handleInputZip} className='num-input' type='number' maxLength={5} placeholder='ZIP Code' />
        </div>,
      ]}
      inputMask={[
        <InputMasker
          col='col-lg-6 col-md-4 col-12'
          handleChange={handleChange}
          rules={[
            {
              required: true,
              message: 'Enter Mobile',
            },
          ]}
          placeholder='Enter Mobile'
          name='phone'
          label='Mobile'
          required={true}
        />,
      ]}
      selects={[
        <SelectField
          col='col-lg-6'
          label='State'
          placeholder='Select State'
          required={true}
          name='state'
          options={states.map((item) => (
            <Select.Option value={item.stateId}>{item.name}</Select.Option>
          ))}
          rules={[
            {
              required: true,
              message: 'Select State',
            },
          ]}
        />,
      ]}
      extras={[
        <div className='col-lg-12 mb-4'>
          <h2 className='dynamic-field-head'>Store</h2>
          <Form.List name='stores'>
            {(fields, { add, remove }, { errors }) => (
              <>
              {console.log("Fields",fields)}
                {fields.map((field, index) => (
                  // <Space className='col-lg-12' key={field.key}>
                  <Form.Item required={false} key={field.key}>
                    <div className='row'>
                      <Form.Item
                        className='col-lg-9 '
                        {...field}
                        validateTrigger={['onChange', 'onBlur']}
                        // noStyle
                      >
                        <Select placeholder={`Store ` + (index + 1)}>
                          {storesList.map((item) => (
                            <Select.Option value={item.storeId}>{item.name}</Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                      <Form.Item className='col-md-3 mt-1'>
                        {fields.length > 0 && (
                          <div className='add-btn' style={{ textAlign: 'center', backgroundColor: '#F21D2F' }} onClick={() => remove(field.name)}>
                            Remove
                          </div>
                        )}
                      </Form.Item>
                    </div>
                  </Form.Item>
                  // </Space>
                ))}

                <div className='row px-2'>
                  <div style={{ textAlign: 'center' }} onClick={() => add()} className='col-lg-3 add-btn'>
                    Add Store
                  </div>
                </div>
                <Form.ErrorList errors={errors} />
              </>
            )}
          </Form.List>
        </div>,
        <div className='col-md-12 mb-4'>
          <h3 className='dynamic-field-head'>Contact Person</h3>
          <Form.List name='contactPersons'>
            {(fields, { add, remove }) => (
              <>
              {console.log('fields',fields)}
                {fields.map(({ key, name, ...restField }) => (
                  <>
                    <h3 className='dynamic-field-head'>Contact Person {fields.length}</h3>
                    <div className='row'>
                      <InputField
                        label='Name'
                        required={true}
                        col='col-lg-6'
                        name={[name, 'partnerContactName']}
                        rules={[
                          {
                            required: true,
                            message: 'Enter Name',
                          },
                        ]}
                        {...restField}
                        placeholder='Enter Contact Name'
                      />
                      <InputField
                        label='Email'
                        required={true}
                        col='col-lg-6'
                        name={[name, 'email']}
                        rules={[
                          {
                            required: true,
                            message: 'Enter Email',
                            type: 'email',
                          },
                        ]}
                        {...restField}
                        placeholder='Enter Email'
                      />
                      <InputMasker
                        label='Mobile'
                        required={true}
                        col='col-lg-8'
                        name={[name, 'mobile']}
                        rules={[
                          {
                            required: true,
                            message: 'Enter Phone',
                          },
                        ]}
                        {...restField}
                        placeholder='Mobile'
                        handleChange={handleChange}
                      />
                      <Form.Item className='col-md-3 pt-3 mt-1'>
                        {fields.length > 0 && (
                          <div className='add-btn' style={{ textAlign: 'center', backgroundColor: '#F21D2F' }} onClick={() => remove(name)}>
                            Remove
                          </div>
                        )}
                      </Form.Item>
                    </div>
                  </>
                ))}
                <div className='row px-2'>
                  <div className='add-btn col-lg-3' onClick={() => add()} style={{ cursor: 'pointer', color: '#fff', textAlign: 'center' }}>
                    Add Contact Person
                  </div>
                </div>
              </>
            )}
          </Form.List>
        </div>,
      ]}
    />
  );
};

export default EditPartner;


