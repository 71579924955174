import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { EditTwoTone } from '@ant-design/icons';
import { Input, Pagination, Form, Select, Card, Row, Col,message } from 'antd';
import { app } from '../../config';
import getHeaders from '../../utils/authHeaders';
import ListinngCard from '../../layouts/ListingCard';

const Stores = () => {
  const { t } = useTranslation(['common']);
  const [editbtn, setEditBtn] = useState('none');
  const [fetchedRoles, setFetchedRoles] = useState([]);
  const [search, setSearch] = useState('');
  const [searchShow, setSearchShow] = useState(false);
  const [pagesCount, setPagesCount] = useState(0);
  const [renderList, setRenderList] = useState([]);
  const [tableShow, setTableShow] = useState(true);
  const [pageSize, setPageSize] = useState(10);
  const [tableHeaders, setTableHeaders] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loader, setLoader] = useState(false);

  const [err, setErr] = useState('');
  let navigate = useNavigate();
  const editRole = (e) => {
    localStorage.setItem('storeId', JSON.stringify({ storeId: e.storeId, storeName: e.name, isActive: e.isActive }));
    navigate('/store/update', { replace: true });
  };
  const storeListingApi = async (formData) => {
    setLoader(true);
    const roleResp = await axios.post(`${app.URL}/api/v1/admin/store/listing`,formData,{ headers: getHeaders() });
    console.log(roleResp);
    roleResp.data ? setFetchedRoles(roleResp.data.result.data) : ' ';
    roleResp.data ? setPagesCount(roleResp.data.result.data.count) : ' ';
    roleResp.data ? setRenderList(roleResp.data.result.data.data) : ' ';
    roleResp.data && setLoader(false);
  };
  const fetchRoles = async () => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user.userType === 'A') setEditBtn('block');
    let header = ['Store Name', 'Created By', 'Status', 'Action']
    setTableHeaders(header);
    let formData = {
      limit: pageSize,
      offset: 0,
      searchBy: 'name',
      searchTxt: '',
    };
    await storeListingApi(formData);
  };
  useEffect(() => {
    setLoader(true);
    fetchRoles();
  }, []);

  let searchItems = [
    {
      name: 'Store Name',
      value: 'name',
    },
  ];

  const onFinish = async (values) => {
    console.log('Success:', values);
    // console.log(value);
    // if (values.searchFor == 'Name') {
    //   values.searchFor = 'firstName';
    // }
    setSearchShow(values);
    // try {
    //   let searchData = {
    //     limit: 10,
    //     offset: 0,
    //     searchBy: values.searchFor,
    //     searchTxt: values.searchItem,
    //   };
    //   console.log(searchData);
    //   if(!searchData.searchBy)message.error("Select search criteria")
    //   if(searchData.searchBy)await storeListingApi(searchData);
    // } catch (e) {
    //   console.log('Error');
    //   if (e.message) setErr(e.message);
    //   setTableShow(false);
    // }
    let formData = {
      limit: 10,
      offset: 0,
      searchBy: values.searchBy,
      searchTxt: values.searchTxt,
    };
    if (!formData.searchTxt) message.error('Please enter search text');
    else await storeListingApi(formData);
  };
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  const handleSearch = async () => {
    try {
      const result = search ? await axios.get(`${app.URL}/user/search/${search}`) : [];
      searchedUser = result.data;
    } catch (e) {
      console.log('Error');
      if (e.message) setErr(e.response.data.message);
      setTableShow(false);
    }

    console.log(searchedUser);
    if (searchedUser) {
      setSearchShow(true);
      setRenderList(searchedUser);
    }
    console.log(searchedUser);
  };
  const onSearch = async (value) => {
    console.log(value);
    try {
      const result = await axios.get(`${app.URL}/role/search/${value}`);
      console.log(result);
      let searchedRole = result.data.data;
      console.log(searchedRole);
    } catch (e) {
      console.log('Error');
      console.log(e);
      //  if(e.message)setErr(e.response.data.message);
      setTableShow(false);
    }
  };
  const onChange = async (page, pageSize) => {
    console.log(page);
    setPageSize(pageSize);
    setCurrentPage(page);
    if (!page) page = 1;
    let limit = pageSize;
    try {
      let reqObj;
      if (Object.keys(searchShow).length !== 0) {
        reqObj = {
          limit: limit,
          offset: limit * (page - 1),
          searchBy: searchShow.searchBy,
          searchTxt: searchShow.searchTxt,
        };
      } else {
        reqObj = {
          limit: limit,
          offset: limit * (page - 1),
          searchBy: 'name',
          searchTxt: '',
        };
      }
      
      await storeListingApi(reqObj);
    } catch (e) {
      console.log('Error');
      if (e.message) setErr(e.message);
      setTableShow(false);
    }
  };
  const handleSearchChange = (e) => {
    if (!e.target.value) {
      setRenderList(fetchedRoles.slice(0, 10));
      setPagesCount(fetchedRoles.length);
    }
  };
  const addStore = () => {
    navigate('/store/create', { replace: true });
  };

  const handleOnChange = (e) => {
    if (e.target.value.length === 0) {
      fetchRoles();
      setSearchShow({});
    }
  };

  return (
      <ListinngCard
        heading='Store Management'
        buttonName='Add Store'
        addButton={addStore}
        onSearch={onFinish}
        onSearchFailed={onFinishFailed}
        searchItems={searchItems}
        tableHeaders={tableHeaders}
        tableRows={renderList}
        editButton={editRole}
        onPageChange={onChange}
        currentPage={currentPage}
        pagesCount={pagesCount}
        patientsListing={false}
        inventoryListing={false}
        userListings={false}
        storeListing={true}
      onChange={handleOnChange}
      loader={loader}
      />
  );
  
};

export default Stores;
