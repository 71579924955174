import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { message, Switch, Select, Form, Input, Row, Col, DatePicker, Upload } from 'antd';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { app } from '../../config';
import useFetchRoles from '../../hooks/fetchRoles';
import { LoadingOutlined, MinusCircleOutlined, PlusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import InputMask from 'react-input-mask';
import getHeaders from '../../utils/authHeaders';
import useFetchCities from '../../hooks/fetchCities';
import useFetchStates from '../../hooks/fetchStates';
import useFetchStores from '../../hooks/fetchStores';
import AddImg from '../../assets/images/new-add.png';
import AddPhoto from '../../assets/images/partner.png';
import useFetchProducts from '../../hooks/fetchProducts';
// console.log(app);
const { TextArea } = Input;
import { AddRecord } from "../../layouts/AddRecord";
import { InputField, SelectField } from "../../layouts/FormFields";

const AddBanner = () => {
  const { t } = useTranslation(['common']);
  const user = JSON.parse(localStorage.getItem('user'));
  const [phoneNumber, setPhoneNumber] = useState();
  const [addUserResp, setAddUserResp] = useState({});
  
  const [show, setShow] = useState(false);
  const [noImage, setNoImage] = useState('');

   function goBack() {
     navigate('/banner/listing', { replace: true });
   }
   window.addEventListener('popstate', goBack);

  const [loading, setLoading] = useState(false);
  const [uploadLogo, setUploadLogo] = useState(true);
  // const [phone, setPhone] = useState('');
  const [imageuploading, setImageUploading] = useState(false);
  const [uploadedImg, setUploadedImg] = useState('');
  let navigate = useNavigate();
  const fetchedRoles = useFetchRoles();
  const roles = fetchedRoles.response ? fetchedRoles.response.data : [];
  const [uploadObj, setUploadObj] = useState({});
  const prodResp = useFetchProducts();
  const products = prodResp.response ? prodResp.response.data : [];
  const cityResponse = useFetchCities();
  const cities = cityResponse.response ? cityResponse.response.data : [];
  const stateResponse = useFetchStates();
  const states = stateResponse.response ? stateResponse.response.data : [];
  const fetchStoresResponse = useFetchStores();
  const storesList = fetchStoresResponse.response ? fetchStoresResponse.response.data : [];
  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  };
  const uploadData = async (userDetails) => {
    setLoading(true);
    let userId = user?.result.data.user.userId;
    userDetails.userId = userId;
    try {
      const res = await axios.post(`${app.URL}/api/v1/admin/banner/create`, userDetails, { headers: getHeaders() });
      setLoading(false);
       setShow(false);

      if (res.data.result.success) {
        setAddUserResp(res.data.result.data);
        setTimeout(goBack, 500);
        message.success(res.data.result.msg);
      } else if (!res.data.result.success) {
        message.warning(res.data.result.msg);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
       setShow(false);
      
      if (err) console.log(err.response.data.message);
      else return;
    }
  };
  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
  };
  const handleuploadChange = async (info) => {
    if (info.file.status === 'uploading') {
      setImageUploading(true);
      setLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      info.file ? console.log(info.file.response.result.data.Location) : console.log('Not found');
      info.file ? setUploadedImg(info.file.response.result.data.Location) : ' ';
      getBase64(info.file.originFileObj, (url) => {
        setImageUploading(false);
        setLoading(false);
        setUploadLogo(false);
        setNoImage('');
      });
    }
  };
  const handleChange = (event) => {
    setPhoneNumber(event.target.value);
  };
  const onFinish = (values) => {
    console.log(values);
    if (uploadedImg) {
      setShow(true);
      setUploadObj({
        name: values.name ? values.name : null,
        product: values.productId ? values.productId : '',
        image: uploadedImg ? uploadedImg : null,
        isActive: values.isActive,
        createdBy: user.result ? user.result.data.user.userId : ' ',
      });
      setNoImage('');
    } else {
      setNoImage('Please upload a banner image.');
    }
  };
  let header = getHeaders();
  // const handleChange = (event) => {
  //   setPhone(event.target.value);
  // };
  const submitData = () => {
    console.log(uploadObj);
    if (Object.keys(uploadObj).length > 0) {
      uploadData(uploadObj);
    }
  };
  const handleClose = () => {
    setShow(false);
  };
  const onFinishFailed = (errorInfo) => {
    if (!uploadedImg) {
      setNoImage('Please upload a banner image.');
    }
    console.log('Failed:', errorInfo);
  };
 

  return (
    <AddRecord
      loading={loading}
      pageTitle='Add Banner'
      initialValues={{
        remember: true,
        ['isActive']: true,
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      switchBtn={true}
      addUserResp={addUserResp}
      handleClose={handleClose}
      show={show}
      addMessage='Are you sure you want to add banner?'
      goBack={goBack}
      submitData={submitData}
      upload={true}
      beforeUpload={beforeUpload}
      handleuploadChange={handleuploadChange}
      imageuploading={imageuploading}
      uploadedImg={uploadedImg}
      setUploadedImg={setUploadedImg}
      action={`${app.URL}/api/v1/general/banner/imageupload`}
      inputs={[
        <InputField
          col='col-md-6 col-12'
          name='name'
          rules={[
            {
              required: true,
              message: 'Enter banner title',
            },
          ]}
          placeholder='Banner Title'
          label='Banner Title'
          required={true}
        />,
      ]}
      selects={[
        <SelectField
          name='productId'
          options={products.map((item) => (
            <Select.Option value={item.productId}>{item.name}</Select.Option>
          ))}
          rules={[
            {
              required: true,
              message: 'Select Product',
            },
          ]}
          placeholder='Select Product'
          label='Product'
          required={true}
          col='col-md-6 col-12'
        />,
      ]}
    />
  );
  // return (
  //   <>
  //     <div className='col-lg-12'>
  //       <div className='dashboard-card'>
  //         <div className='row' style={{ paddingRight: '10px' }}>
  //           <div className='for-before col-lg-4 head fs-3 fw-bold' style={{ backgroundColor: '#a7c1d9', borderBottomRightRadius: '10px', color: '#FFFFFF' }}>
  //             Add Banner
  //           </div>
  //         </div>
  //         <Form
  //           name='basic'
  //           onFinish={onFinish}
  //           onFinishFailed={onFinishFailed}
  //           autoComplete='off'
  //           initialValues={{
  //             remember: true,
  //             ['isActive']: true,
  //           }}
  //         >
  //           <div className='row' style={{ padding: '0px 50px', paddingTop: '40px' }}>
  //             <div className='col-lg-8'>
  //               {/* Select box for Role */}
  //               <div className='row'>
  //                 <div className='col-lg-6'>
  //                   <label>
  //                     Banner Title <span className='required-star'>*</span>
  //                   </label>
  //                   <Form.Item
  //                     name='name'
  //                     colon={false}
  //                     rules={[
  //                       {
  //                         required: true,
  //                         message: 'Enter banner title',
  //                       },
  //                     ]}
  //                   >
  //                     <Input className='' placeholder='Banner title' />
  //                   </Form.Item>
  //                 </div>
  //                 <div className='col-lg-6'>
  //                   <label>
  //                     Product <span className='required-star'>*</span>
  //                   </label>
  //                   <Form.Item
  //                     name='productId'
  //                     colon={false}
  //                     style={{ wordWrap: 'normal' }}
  //                     rules={[
  //                       {
  //                         required: true,
  //                         message: 'Select Product',
  //                       },
  //                     ]}
  //                   >
  //                     <Select placeholder='Select Product'>
  //                       {products.map((item) => (
  //                         <Select.Option value={item.productId}>{item.name}</Select.Option>
  //                       ))}
  //                     </Select>
  //                   </Form.Item>
  //                 </div>
  //               </div>
  //               <div className='row'>
  //                 <div className='col-lg-6'>
  //                   <Form.Item label='Active' className='selector-main' colon={false} valuePropName='checked' name='isActive'>
  //                     <Switch />
  //                   </Form.Item>
  //                 </div>
  //               </div>
  //               <div className='row ml-1 mt-3'>
  //                 <button
  //                   onClick={() => {
  //                     navigate('/banner/listing');
  //                   }}
  //                   type='primary'
  //                   className='btn btn-primary button patient-cancel-btn col-lg-5 mr-2'
  //                 >
  //                   Cancel
  //                 </button>
  //                 <button type='primary' class='btn btn-primary button col-lg-5' htmlType='submit'>
  //                   Save
  //                 </button>
  //               </div>
  //               ;
  //               {/* <div className='row'>
  //                 <div className='col-lg-6'>
  //                 <label>ZIP Code <span className="required-star">*</span></label>
  //                 <Form.Item
  //                     name='zipCode'
  //                     defaultValue='1'
  //                     colon={false}
  //                     rules={[
  //                       {
  //                         required: true,
  //                         message: 'Enter Zip Code',
  //                         min: 4, message: 'Enter Zip code',
  //                           max: 5, message: 'Enter Zip code'
  //                       },
  //                     ]}
  //                   >
  //                     <Input placeholder='ZIP Code' maxLength={5} />
  //                   </Form.Item>
                    
  //                 </div>
  //                 <div className='col-lg-6'>
  //                 <label>Website</label>
  //                 <Form.Item name='website'>
  //                     <Input placeholder='Website' className='admin' style={{ width: '100% !important' }} />
  //                   </Form.Item>
  //                 </div>
                  
  //               </div> */}
  //               {/* <div className='row'>
  //                 <div className='col-lg-6'> */}
  //               {/* </div>
  //               </div> */}
  //             </div>
  //             <div className='col-lg-4 mt-4'>
  //               <div className='col-lg-9' style={{ height: '270px' }}>
  //                 <Form.Item
  //                   valuePropName='fileList'
  //                   rules={[
  //                     {
  //                       required: true,
  //                       message: 'Upload Image',
  //                     },
  //                   ]}
  //                 >
  //                   <Upload listType='picture-card' className='avatar-uploader' showUploadList={false} action={`${app.URL}/api/v1/general/banner/imageupload`} beforeUpload={beforeUpload} onChange={handleuploadChange} headers={header}>
  //                     {uploadedImg && !imageuploading ? (
  //                       <img className='' src={uploadedImg} alt='avatar' />
  //                     ) : !uploadedImg && imageuploading ? (
  //                       <LoadingOutlined style={{ fontSize: '40px' }} />
  //                     ) : (
  //                       <>
  //                         <img className='for-add-img' src={AddPhoto} alt='avatar' />
  //                         <p className='add-text'>Upload Image</p>
  //                       </>
  //                     )}
  //                   </Upload>
  //                   {noImage && (
  //                     <div
  //                       style={{
  //                         color: 'red',
  //                       }}
  //                     >
  //                       {noImage}
  //                     </div>
  //                   )}
  //                 </Form.Item>
  //               </div>
  //             </div>
  //           </div>
  //           <div className='col-lg-12' style={{ padding: '0 50px', paddingBottom: '20px' }}>
  //             <Form.Item>
  //               {addUserResp.code === 200 ? (
  //                 <Modal show={show} onHide={handleClose}>
  //                   <Modal.Header closeButton>
  //                     <Modal.Title className='text-center'>Success</Modal.Title>
  //                   </Modal.Header>
  //                   <Modal.Body>{addUserResp.message}</Modal.Body>
  //                   <Modal.Footer></Modal.Footer>
  //                 </Modal>
  //               ) : addUserResp.code === 400 ? (
  //                 <Modal show={show} onHide={handleClose}>
  //                   <Modal.Header closeButton>
  //                     <Modal.Title className='text-center'>Failed</Modal.Title>
  //                   </Modal.Header>
  //                   <Modal.Body>{addUserResp.data.message}</Modal.Body>
  //                   <Modal.Footer></Modal.Footer>
  //                 </Modal>
  //               ) : (
  //                 <Modal show={show} onHide={handleClose}>
  //                   {/* <Modal.Header closeButton>
  //                     <Modal.Title>Confirmation</Modal.Title>
  //                   </Modal.Header> */}
  //                   <Modal.Body>Are you sure you want to add banner?</Modal.Body>
  //                   <Modal.Footer>
  //                     <Button variant='secondary' onClick={handleClose}>
  //                       Cancel
  //                     </Button>
  //                     <Button variant='primary' htmlType='submit' onClick={submitData}>
  //                       Confirm
  //                     </Button>
  //                   </Modal.Footer>
  //                 </Modal>
  //               )}
  //             </Form.Item>
  //           </div>
  //         </Form>
  //       </div>
  //     </div>
  //   </>
  // );
};

export default AddBanner;
