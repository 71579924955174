import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { app } from '../../config';
import { useNavigate } from 'react-router-dom';
import { Pagination, Input, Form, Select, Row, Col, DatePicker, message } from 'antd';
import { useTranslation } from 'react-i18next';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { EditTwoTone, BranchesOutlined } from '@ant-design/icons';
import getHeaders from '../../utils/authHeaders';
import ListinngCard from '../../layouts/ListingCard';
import { dateConverter } from '../../utils/utcDateConversionCommon';
import AddNewImg from '../../assets/images/add-new.png';
import editBtnImg from '../../assets/images/edit.png';
import lockIcon from '../../assets/images/lock.png';
import { SearchBar } from '../../layouts/SearchBar';
import LoaderImage from '../LoaderImage';
import NoDataFound from '../../layouts/NoDataFound';
const EcomInventory = () => {
  const [editbtn, setEditBtn] = useState('none');
  const [pageSize, setPageSize] = useState(10);
  const [fetchPartners, setFetchPartners] = useState([]);
  const [updbatchId, setUpdBatchId] = useState('');
  const [delBatchId, setDelBatchId] = useState('');
  const [pagesCount, setPagesCount] = useState(0);
  const [searchShow, setSearchShow] = useState({});
  const [renderList, setRenderList] = useState([]);
  const [tableShow, setTableShow] = useState(true);
  const [refreshPage, setRefreshPage] = useState(false);
  const [loader, setLoader] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  // const [searchItems, setSearchItems] = useState([]);
  const [err, setErr] = useState('');
  const [tableHeaders, setTableHeaders] = useState([]);
  const [show, setShow] = useState(false);
  const [lockModalShow, setLockModalShow] = useState(false);
  const [delBatchResp, setDelBatchResp] = useState({});
  const [lockBatchResp, setLockBatchResp] = useState({});
  const [batchListing, setBatchListing] = useState({});
  const handleClose = () => setShow(false);
  const handleLockClose = () => setLockModalShow(false);
  let navigate = useNavigate();
  let convertedObj;

  const user = JSON.parse(localStorage.getItem('user'));
  if (user.userType === 'A') setEditBtn('block');
  const addEcomInventory = () => {
    navigate('/ecominv/add', { replace: true });
  };
  const editButton = (values) => {
    // console.log(values.bannerId);
    // localStorage.setItem('bannerId', values.bannerId);
    // navigate('/banner/update', { replace: true });
  };

  const inventoryListingApi = async (formData) => {
    console.log(formData);
    setLoader(true);

    const partnerResp = await axios.post(`${app.URL}/api/v1/admin/ecominv/list`, formData, { headers: getHeaders() });
    // console.log(partnerResp)
    partnerResp ? console.log(partnerResp.data.result.data) : '';
    partnerResp ? (convertedObj = dateConverter(partnerResp.data.result.data.data, 'stockInDate')) : ' ';
    partnerResp.data.result ? setFetchPartners(convertedObj) : ' ';
    partnerResp.data.result ? setPagesCount(partnerResp.data.result.data.count) : ' ';
    partnerResp.data.result ? setRenderList(convertedObj) : ' ';
    partnerResp.data.result && setLoader(false);
  };
  const onChange = async (page, pageSize) => {
    console.log(page, pageSize);
    setPageSize(pageSize);
    setCurrentPage(page);
    if (!page) page = 1;
    let limit = pageSize;
    try {
      let reqObj;
      if (Object.keys(searchShow).length !== 0) {
        reqObj = {
          limit: limit,
          offset: limit * (page - 1),
          searchBy: searchShow.searchBy,
          searchTxt: searchShow.searchTxt,
        };
      } else {
        reqObj = {
          limit: limit,
          offset: limit * (page - 1),
          searchBy: 'bannerName',
          searchTxt: '',
        };
      }
      console.log(reqObj);
      await inventoryListingApi(reqObj);
    } catch (e) {
      console.log('Error');
      if (e.message) setErr(e.message);
      setTableShow(false);
    }
  };

  useEffect(() => {
    let formData = {
      limit: pageSize,
      offset: 0,
      searchBy: 'productName',
      searchTxt: '',
    };
    inventoryListingApi(formData);
  }, [refreshPage]);

  const onFinish = async (values) => {
    console.log(values);
    setSearchShow(values);
    try {
      let searchData = {
        limit: 10,
        offset: 0,
        searchBy: values.searchFor,
        searchTxt: values.searchTxt,
      };
      if (!searchData.searchTxt) message.error('Please enter search text');
      else await inventoryListingApi(searchData);
    } catch (e) {
      console.log('Error');
      if (e.message) setErr(e.message);
      setTableShow(false);
    }
  };
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const handleOnChange = (e) => {
    if (e.target.value.length === 0) {
      setRefreshPage(!refreshPage);
      setSearchShow({});
    }
  };
  return (
    <div className='dashboard-card container-fluid'>
      <div className='row p-0 pb-4'>
        <div className='for-before col-12 col-md-7 head fs-3 fw-bold' style={{ backgroundColor: '#a7c1d9', borderBottomRightRadius: '10px', color: '#FFFFFF' }}>
          Ecommerce Inventory Stock-In
        </div>

        <div className='col-12 col-md-5 d-flex justify-content-center'>
          <div onClick={addEcomInventory} className='icon-btn'>
            <img src={AddNewImg} />
            <p>Add Inventory</p>
          </div>
        </div>
      </div>

      <SearchBar
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        onChange={handleOnChange}
        searchItems={[
          {
            name: 'Product Name',
            value: 'productName',
          },
        ]}
      />

      <div
        className=' table-scrollbar'
        style={{
          overflowX: 'auto',
        }}
      >
        {loader && <LoaderImage />}
        {renderList.length > 0 ? (
          <table id='customers' className='w-100'>
            <thead class=''>
              <tr>
                <th>Product Name</th>
                <th>Quantity</th>
                <th>Unit Price</th>
                <th>Stock In Date</th>
                <th>Store</th>
                <th></th>
              </tr>
              {/* <tr>
                {tableHeaders.map(header => (
                  <th>{header}</th>
                ))}
              </tr> */}
            </thead>
            <tbody>
              {renderList.map((tableValues) => (
                <tr>
                  <td style={{ width: '250px' }}>{tableValues.productName ? tableValues.productName : 'null'}</td>
                  <td style={{ width: '200px' }}>
                    <a>{tableValues.quantity ? tableValues.quantity : 'null'}</a>
                  </td>
                  <td style={{ width: '200px' }}>
                    <a>{tableValues.unitPrice ? tableValues.unitPrice : 'null'}</a>
                  </td>
                  <td style={{ width: '200px' }}>{tableValues.stockInDate ? tableValues.stockInDate : 'null'}</td>
                  <td style={{ width: '200px' }}>{tableValues.storeName ? tableValues.storeName : 'null'}</td>
                  <td>
                    {/* <a>
                      <img style={{ width: '25px', display: 'block' }} alt='edit btn img' src={editBtnImg} onClick={() => editButton(tableValues)} />
                    </a> */}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <>{!loader && <NoDataFound />}</>
        )}
      </div>

      <div className='row'>{currentPage && pagesCount ? <Pagination style={{ marginTop: '10px' }} onChange={onChange} defaultCurrent={currentPage} total={pagesCount} showTotal={(total) => `Total ${total} items`} showSizeChanger /> : ' '}</div>
    </div>
  );
};

export default EcomInventory;
