import React, { useState } from 'react';

import { Chart, RadialLinearScale, Title, Tooltip, ArcElement } from 'chart.js';
import { PolarArea } from 'react-chartjs-2';
import { Cardiology } from './Cardiology';
Chart.register(RadialLinearScale, Title, Tooltip, ArcElement);
const RadarChart = ({ reportData }) => {
  const [selected, setSelected] = useState();
  const allLabels = () => {
    let labels = [];
    for (let i = 0; i < reportData.length; i++) {
      labels.push(`${reportData[i].type}--${reportData[i].testValue}`);
    }
    return labels;
  };
  const allTestValues = () => {
    let testValues = [];
    for (let i = 0; i < reportData.length; i++) {
      testValues.push(reportData[i].testValue);
    }
    return testValues;
  };

  const handleOnClick = (elements) => {
    if (elements.length > 0) {
      setSelected(reportData[elements[0].index]);
    }
  };

  let data = {
    labels: allLabels(),
    datasets: [
      {
        data: allTestValues(),
        backgroundColor: ['#fabc2e', '#59b05c', '#d24239'],
        borderWidth: 0,
      },
    ],
  };
  let options = {
    onClick: (event, elements) => {
      handleOnClick(event, elements);
    },
    responsive: true,
    scales: {
      r: {
        suggestedMin: 0,
        suggestedMax: 145,
        grid: {
          circular: true,
          color: '#000',
        },
        angleLines: {
          display: true,
          color: '#000',
          lineWidth: 1,
        },
        pointLabels: {
            display: true,
            color: "#000",
          font: {
            size: 11,
          },
          padding: 0,
          centerPointLabels: true,
        },
        ticks: {
          stepSize: 25,
          font: {
            size: 0,
          },
          color: '#000',
        },
      },
    },
  };


  return (
    <div className='d-flex mb-5 align-items-center justify-content-center'>
      <div style={{ width: '500px', height: '500px' }}>
        <PolarArea data={data} options={options} />
        {selected && <Cardiology title={selected?.type} pointer={selected?.testValue} category={selected?.category} total={selected?.category[2].end} />}
      </div>
    </div>
    //   <div id='chart'>{/* The chart will be rendered here */}</div>
  );
};

export default RadarChart;
