import React, { useEffect, useState } from 'react';
import { AddRecord } from '../../../layouts/AddRecord';
import { InputField, SelectField, TextAreaField } from '../../../layouts/FormFields';
// import { InputField, SelectField, TextAreaField } from '../../layouts/FormFields';
import { Button, Form, Space, Upload, message, ColorPicker, Select, Switch, Divider } from 'antd'; //4.24.7

import getHeaders from '../../../utils/authHeaders';
import { app } from '../../../config';
import { UploadOutlined } from '@ant-design/icons';
import axios, { Axios } from 'axios';
import { useNavigate } from 'react-router-dom';
import { UpdateRecord } from '../../../layouts/UpdateRecord';

const EditBlog = () => {
  const [loading, setLoading] = useState(false);
  const [updatedData, setUpdatedData] = useState({});
   const [addUserResp, setAddUserResp] = useState({});
   const [uploadVideo, setuploadVideo] = useState('');
   const [contentType, setContentType] = useState();
   const [videoupload, setvideoupload] = useState(false);

   let [show, setShow] = useState(false);
   const [uploadObj, setUploadObj] = useState({});
   const [uploadedImg, setUploadedImg] = useState('');
   let [textEditorValue, settextEditorValue] = useState();

   const [imageuploading, setImageUploading] = useState(false);

  let blogId = localStorage.getItem('blogId');

  let getBlog = async () => {
    try {
      let res = await axios.get(`${app.URL}/api/v1/admin/blogs/view?blogId=${blogId}`, { headers: getHeaders() });
setLoading(false);
      console.log(res.data?.result?.data);

      res.data?.result?.data ? setUpdatedData(res.data?.result?.data) : '';
      res.data?.result?.data ? setUploadedImg(res.data?.result?.data?.imageUrl) : '';
      res.data?.result?.data ? settextEditorValue(res.data?.result?.data?.description) : '';
    } catch (err) {
      console.log(err);
    }
  };
  
  useEffect(() => {
setLoading(true);
    getBlog();
  }, []);
 
  let navigate = useNavigate();
  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    // reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  };
  const beforeUpload = (file) => {
    console.log(file);
    setuploadVideo('');
    // return
    if (file.type === 'image/jpeg' || file.type === 'image/png') {
      console.log('setting image type');
      setContentType('img');
    } else if (file.type === 'video/x-matroska' || file.type === 'video/mp4' || file.type === 'video/mkv') setContentType('video');
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'video/x-matroska' || file.type === 'video/mp4' || file.type === 'video/mkv';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    console.log('file Checked');
    const isLt2M = file.size / 1024 / 1024 < 20;
    if (!isLt2M) {
      setLargeFile(true);
      message.error('Image must smaller than 20MB!');
    }
    console.log('message');
    return isJpgOrPng && isLt2M;
  };

  const handleuploadChange = (info) => {
    console.log(info);
    
    if (info.file.status === 'uploading') {
      setImageUploading(true);
      // setLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      console.log(info.file);
      info.file ? console.log(info.file.response.result.data.Location) : console.log('Not found');
      info.file ? setUploadedImg(info.file.response.result.data.Location) : ' ';
      info.file ? setImageUploading(false) : '';
      // info.file ? setLoading(false) : '';

      getBase64(info.file.originFileObj, (url) => {
        setImageUploading(false);
        // setLoading(false);
      });
    }
  };
  const submitData = () => {
    console.log('calling: ', uploadObj);

    if (Object.keys(uploadObj).length > 0) {
      uploadData(uploadObj);
    }
  };
  const user = JSON.parse(localStorage.getItem('user'));

  const uploadData = async (uploadObj) => {
    console.log(uploadObj);
    let userId = user?.result.data.user.userId;
    uploadObj.userId = userId;
    setLoading(true);

    try {
      //  return
      const res = await axios.post(`${app.URL}/api/v1/admin/blogs/edit?blogId=${blogId}`, uploadObj, { headers: getHeaders() });
      console.log(res);

      if (res.data.result.success) {
        setAddUserResp(res.data.result);
        message.success(res.data.result.msg);
        setTimeout(() => {
          navigate('/blogs/view');
        }, 1000);
      } else if (!res.data.result.success) {
        message.warning(res.data.result.msg);
      }
    } catch (err) {
      setLoading(false);
      setShow(false);

      console.log(err);
      if (err) console.log(err.response.data.message);
      else return;
    }
  };

  function goBack() {
    navigate('/blogs/view', { replace: true });
  }

  const onFinish = (values) => {
    setUploadObj({
      contentDisplay: 'Image',
      title: values.title,
      description: textEditorValue,
      imageUrl: uploadedImg,
      isActive: values.isActive,
    });
    setShow(true);
    // return;
  };
  console.log(uploadObj);
  const handleClose = () => setShow(false);

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const handleEditorChange = (htmlContent) => {
    settextEditorValue(htmlContent);
    // Do something with the htmlContent, such as saving it to state or sending it to an API
  };
  return (
    <UpdateRecord
      pageTitle='Edit Blog'
      loading={loading}
      response={updatedData}
      onFinish={onFinish}
      addUserResp={addUserResp}
      updateMessage='Are you sure you want to update blog?'
      onFinishFailed={onFinishFailed}
      initialValues={{
        remember: true,
        ['isActive']: updatedData?.isActive,
        // ['viewType']: 'Image',
        ['title']: updatedData?.title,
      }}
      inputs={[
        <div className='col-lg-8'>
          <div className='row'>
            <InputField
              col='col-md-6 col-12'
              name='title'
              label='Blog Title'
              rules={[
                {
                  required: true,
                  message: 'Enter title',
                },
              ]}
              placeholder='Enter title'
              required={true}
            />
          </div>
        </div>,
      ]}
      upload={true}
      vdoupload={videoupload}
      action={`${app.URL}/api/v1/general/user/imageupload`}
      beforeUpload={beforeUpload}
      handleuploadChange={handleuploadChange}
      imageuploading={imageuploading}
      submitData={submitData}
      uploadedImg={uploadedImg}
      setUploadedImg={setUploadedImg}
      isRichTextEditor={true}
      switchBtn={true}
      goBack={goBack}
      handleClose={handleClose}
      show={show}
      onChange={handleEditorChange}
      textEditorValue={textEditorValue}
    />
  );
};

export default EditBlog;
