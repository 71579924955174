import { createSlice } from '@reduxjs/toolkit';

const initialState = [];
const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    addAdmin: (state, action) => {
      state.push(action.payload);
    },
    removeAdmin: (state, action) => {
      state = state.filter((a) => a.id !== action.payload);
    },
  },
});

export const { addAdmin, removeAdmin } = adminSlice.actions;
export default adminSlice.reducer;
