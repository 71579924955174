import React, { useState, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import { Pagination, Input, Form, Select, Row, Col, Space, Spin, message, Collapse, DatePicker, Button } from 'antd';
import axios from 'axios';
import { app } from '../config';
import getHeaders from '../utils/authHeaders';
import moment from 'moment';

const TestOrderDetailModal = ({ isVisible, setIsVisible, testOrderDetails }) => {
  console.log(testOrderDetails);
  const commentTableHeaders = ['Date & Time', 'Comment By', 'Comment'];

  const [commentsData, setCommentsData] = useState([]);

  return (
    <Modal
      show={isVisible}
      size='lg'
      onHide={() => {
        setIsVisible(!isVisible);
      }}
      centered
    >
      <Modal.Header
        style={{
          display: 'block',
        }}
      >
        <Modal.Title style={{ display: 'block', padding: '9px 13px 8px 8px', fontSize: '20px' }}>
          <div className='row'>
            <div className='col-lg-6'>{testOrderDetails?.orderNo}</div>
            <div className='col-lg-6 text-right'>{moment(testOrderDetails?.orderDate).format('L')}</div>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ padding: '0px 20px !important' }}>
        <div className='row'>
          <div className='row'>
            {testOrderDetails?.ecomOrderNo && <div className='col-lg-6 mt-0 mb-0 pt-0 pb-0 pl-3'>Ecom Order No. {testOrderDetails?.ecomOrderNo}</div>}
            {testOrderDetails?.statusName && <div className='col-lg-6  mt-0 mb-0 pt-0 pb-0 pl-3 text-right'>Status: {testOrderDetails?.statusName}</div>}
          </div>
          <div className='row'>
            {testOrderDetails?.patientName && <div className='col-lg-6  mt-0 mb-0 pt-0 pb-0 pl-3'>Patient Name: {testOrderDetails?.patientName}</div>}
            {testOrderDetails?.mobile && <div className='col-lg-6  mt-0 mb-0 pt-0 pb-0 pl-0 text-right'>Mobile: {testOrderDetails?.mobile}</div>}
          </div>
          <div className='row'>
            {testOrderDetails?.accessionNo && <div className='col-lg-6  mt-0 mb-0 pt-0 pb-0 pl-3'>Accession No. {testOrderDetails?.accessionNo}</div>}
            {(testOrderDetails?.panelCode || testOrderDetails?.cisPanelCode) && <div className={testOrderDetails?.accessionNo ? 'col-lg-6  mt-0 mb-0 pt-0 pb-0 pl-0 text-right' : 'col-lg-6  mt-0 mb-0 pt-0 pb-0 pl-3'}>Cis Panel Code: {testOrderDetails?.panelCode || testOrderDetails?.cisPanelCode}</div>}
          </div>
          <div className='row'>
            {testOrderDetails?.observationDateTime && <div className='col-lg-6  mt-0 mb-0 pt-0 pb-0 pl-3'>Observation Date: {moment(testOrderDetails?.observationDateTime).format('L')}</div>}
            {testOrderDetails?.reportDate && <div className='col-lg-6  mt-0 mb-0 pt-0 pb-0 pl-0 text-right'>Report Date: {moment(testOrderDetails?.reportDate).format('L')}</div>}
          </div>
          <div className='row'>{testOrderDetails?.labReciveDate && <div className='col-lg-6  mt-0 mb-0 pt-0 pb-0 pl-3 '>Recieve Date: {moment(testOrderDetails?.labReciveDate).format('L')}</div>}</div>
        </div>
        <div className='mb-4'>
          <table id='customers' className='w-100'>
            <thead>
              <tr
                style={{
                  fontWeight: 'bold',
                }}
              >
                <th>Product Name </th>
                <th>Quantity</th>
                {/* <th>Unit Price</th> */}
                {/* <th>Total</th> */}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ width: '100px' }}>{testOrderDetails?.productName}</td>
                <td style={{ width: '100px' }}>{testOrderDetails?.quantity}</td>
                {/* <td style={{ width: '100px' }}>${Number(testOrderDetails?.unitPrice)?.toFixed(2)}</td>
                <td style={{ width: '100px' }}>${(Number(testOrderDetails?.quantity).toFixed(2) * Number(testOrderDetails?.unitPrice)?.toFixed(2)).toFixed(2)}</td> */}
              </tr>
            </tbody>
          </table>
        </div>

        <div
          className='row mt-2'
          style={{
            border: '1px solid silver',
            borderRadius: 10,
          }}
        >
          <div className='col-lg-6 fs-5 mt-0 mb-0 pt-0 pb-0  mt-2' style={{ height: '40px' }}>
            History
          </div>

          <div
            style={{
              maxHeight: '200px',
              overflowY: 'auto',
            }}
          >
            <table
              className='table'
              style={{
                width: '100%',
                borderCollapse: 'collapse',
              }}
            >
              <thead
                className=''
                style={{
                  backgroundColor: '#f2b544',
                  color: '#fff',
                }}
              >
                <tr
                  style={{
                    fontWeight: 'bold',
                  }}
                >
                  {commentTableHeaders.map((header, index) => {
                    return (
                      <th
                        style={{
                          textAlign: 'center',
                          padding: '8px',
                          fontSize: '13px',
                        }}
                      >
                        {header}
                      </th>
                    );
                  })}
                </tr>
              </thead>

              {testOrderDetails?.comments?.length > 0 ? (
                <tbody>
                  {testOrderDetails?.comments.map((commentItem, index) => {
                    // console.log(commentsData);
                    return (
                      <tr
                        key={index}
                        style={{
                          backgroundColor: index % 2 == 0 ? '' : '#dddddd',
                        }}
                      >
                        <td
                          style={{
                            width: '60px',
                            textAlign: 'center',
                            padding: '8px',
                            fontSize: '13px',
                          }}
                        >
                          {moment(commentItem.createdAt).format('MM-DD-YYYY HH:mm')}
                        </td>

                        <td
                          style={{
                            width: '20px',
                            textAlign: 'center',
                            padding: '8px',
                            fontSize: '13px',
                          }}
                        >
                          {commentItem.commentBy}
                        </td>

                        <td
                          style={{
                            width: '200px',
                            textAlign: 'center',
                            padding: '8px',
                            fontSize: '13px',
                          }}
                        >
                          {commentItem.comment}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              ) : (
                <div className='row text-center'>
                  <h2 className='text-center'>No Comment History</h2>
                </div>
              )}
            </table>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          variant='primary'
          className='btn btn-primary'
          style={{
            textAlign: 'left',
          }}
          onClick={() => {
            setIsVisible(!isVisible);
          }}
        >
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default TestOrderDetailModal;
