import moment from 'moment-timezone';

export const dateConverter = (object, key) => {
  console.log(object)
  const convertedData = object?.map((obj) => {
    const dateInUtc = moment(obj[key]).utc().format('MM-DD-YYYY');
    return { ...obj, [key]: dateInUtc };
  });
  return convertedData;
};
