import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { Form, Input, Switch, message } from 'antd';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { app } from '../../config';
import useFetchRoles from '../../hooks/fetchRoles';
import { LeftCircleOutlined } from '@ant-design/icons';
import getHeaders from '../../utils/authHeaders';
import getEthnicityById from '../../hooks/getEthnicityById';
import { UpdateRecord } from "../../layouts/UpdateRecord";
import { InputField } from '../../layouts/FormFields';

const editEthnicity = () => {
  const { TextArea } = Input;
  const { t } = useTranslation(['common']);
  const [addUserResp, setAddUserResp] = useState({});
  const [show, setShow] = useState(false);
  const [error, setError] = useState('');
   const [loading, setLoading] = useState(false);




  let navigate = useNavigate();
  let ethnicId = localStorage.getItem('ethnicId');

  const EthnicityById = getEthnicityById(ethnicId);
  let ethnicObj = EthnicityById.response ? EthnicityById.response.data : null;
  console.log('EthnicityById ', EthnicityById);

  const [uploadObj, setUploadObj] = useState({});
  const uploadData = async (userDetails) => {
    setLoading(true);

    try {
      console.log('uploadObj ', userDetails);
      const res = await axios.post(`${app.URL}/api/v1/admin/ethnicity/update/${ethnicId}`, userDetails, { headers: getHeaders() });
      setLoading(false);
       setShow(false);

      if (res.data.result.success) {
        setAddUserResp(res.data.result.data);
         message.success(res.data.result.msg);

        setTimeout(() => {
          navigate('/ethnicity/listing');
        }, 500);
      } else if (!res.data.result.success) {
       
         message.warning(res.data.result.msg);
      }
    } catch (err) {
      setLoading(false);
        setShow(false);
      console.log(err);
      if (err) console.log(err.response.data.message);
      else return;
    }
  };

  const handleChange = (event) => {
    setPhoneNumber(event.target.value);
    // handle phone number input here
  };
  const onFinish = (values) => {
    console.log('Success:', values);
    if (values.verType.startsWith(' ')) {
      values.verType = values.verType.slice(1);
      console.log(values.verType);
    }
    setUploadObj({
      verticleType: values.verType ? values.verType : null,
      code: values.code ? values.code : null,
      description: values.descr ? values.descr : null,
      isActive: values.isActive === true ? 1 : 0,
    });
    if (!error) setShow(true);
  };
  // console.log(uploadObj);
  const submitData = () => {
    console.log('calling');
    if (Object.keys(uploadObj).length > 0) {
      uploadData(uploadObj);
    }
  };
  const handleClose = () => setShow(false);
  const handleInputChange = (event) => {
    const newValue = event.target.value;

    // Check if the input starts with a space
    if (newValue.startsWith(' ')) {
      setError('Input cannot start with a space.');
    } else {
      setError('');
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  function goBack() {
    navigate('/ethnicity/listing', { replace: true });
  }
  window.addEventListener('popstate', goBack);
  console.log(ethnicObj);
  
  return (
    <UpdateRecord
      loading={loading}
      pageTitle='Update Ethnicity'
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      handleClose={handleClose}
      show={show}
      addUserResp={addUserResp}
      goBack={goBack}
      initialValues={{
        remember: true,
        ['verType']: ethnicObj?.verticleType,
        ['code']: ethnicObj?.code,
        ['descr']: ethnicObj?.description,
        ['isActive']: ethnicObj?.isActive === '1' ? true : false,
      }}
      response={EthnicityById.response}
      upload={false}
      inputs={[
        <InputField
          label='Ethnicity Code'
          name='code'
          col='col-lg-6'
          required={true}
          placeholder='Etthnicity Code'
          rules={[
            {
              required: true,
              message: 'Enter Ethnicity Code',
            },
          ]}
        />,
        <InputField
          label='Ethnicity'
          name='verType'
          col='col-lg-6'
          required={true}
          placeholder='Etthnicity'
          rules={[
            {
              required: true,
              message: 'Enter Ethnicity',
            },
          ]}
        />,

        <InputField
          label='Description'
          name='descr'
          col='col-lg-12'
          required={true}
          placeholder='Enter Description'
          rules={[
            {
              required: true,
              message: 'Enter Description',
            },
          ]}
        />,
      ]}
      submitData={submitData}
      switchBtn={true}
      updateMessage='Are you sure you want to update ethnicity?'
    />
  );

  // return (
  //   <>
  //     <div className='col-lg-12'>
  //       <div className='dashboard-card'>
  //         <div className='row' style={{ paddingRight: '10px' }}>
  //           <div className='for-before col-lg-4 head fs-3 fw-bold' style={{ backgroundColor: '#a7c1d9', borderBottomRightRadius: '10px', color: '#FFFFFF' }}>
  //             Update Ethnicity
  //           </div>
  //         </div>
  //         <div class='col mb-5 mt-4'>
  //           <div class='col-lg-12 align-self-center mt-2 mb-4'>
  //             {EthnicityById.response ? (
  //               <Form
  //                 name='basic'
  //                 onFinish={onFinish}
  //                 onFinishFailed={onFinishFailed}
  //                 autoComplete='off'
  //                 initialValues={{
  //                   remember: true,
  //                   ['verType']: ethnicObj.verticleType,
  //                   ['code']: ethnicObj.code,
  //                   ['descr']: ethnicObj.description,
  //                   ['isActive']: ethnicObj.isActive === '1' ? true : false,
  //                 }}
  //               >
  //                 {/* Select box for Role */}

  //                 <div className='row' style={{ padding: '20px 50px' }}>
  //                   <div className='col-lg-4'>
  //                     <label>
  //                       Ethnicity Code <span className='required-star'>*</span>
  //                     </label>
  //                     <Form.Item
  //                       name='code'
  //                       defaultValue='1'
  //                       colon={false}
  //                       rules={[
  //                         {
  //                           required: true,
  //                           message: 'Enter Ethnicity Code',
  //                         },
  //                       ]}
  //                     >
  //                       <Input placeholder='Ethnicity Code' onChange={handleInputChange} />
  //                     </Form.Item>
  //                   </div>
  //                   <div className='col-lg-4'>
  //                     <label>
  //                       Ethnicity <span className='required-star'>*</span>
  //                     </label>
  //                     <Form.Item
  //                       // label='Type of Vertical'
  //                       name='verType'
  //                       defaultValue='1'
  //                       colon={false}
  //                       rules={[
  //                         {
  //                           required: true,
  //                           message: 'Enter type of vertical',
  //                         },
  //                       ]}
  //                     >
  //                       <Input placeholder='Type Of Vertical' onChange={handleInputChange} />
  //                     </Form.Item>
  //                     {error && <p style={{ color: 'red' }}>{error}</p>}
  //                   </div>
  //                   <div className='col-lg-8'>
  //                     <label>Description </label>
  //                     <Form.Item
  //                       // label='Description'
  //                       name='descr'
  //                       defaultValue='1'
  //                       colon={false}
  //                       rules={[
  //                         {
  //                           required: false,
  //                           message: 'Enter Description',
  //                         },
  //                       ]}
  //                     >
  //                       <TextArea rows={1} placeholder='Enter Description' />
  //                       {/* <Input placeholder='Enter Description' /> */}
  //                     </Form.Item>
  //                   </div>

  //                   <div className='col-lg-12 mt-1'>
  //                     <Form.Item label='Active' className='' colon={false} valuePropName='checked' name='isActive'>
  //                       <Switch />
  //                     </Form.Item>
  //                     <div className='row ml-1'>
  //                       <button
  //                         onClick={() => {
  //                           navigate('/ethnicity/listing');
  //                         }}
  //                         type='primary'
  //                         className='btn btn-primary button patient-cancel-btn col-lg-3 mr-2 col-lg-4 mr-2 '
  //                       >
  //                         Cancel
  //                       </button>

  //                       <button type='primary' class='btn btn-primary button col-lg-4' htmlType='submit'>
  //                         Save
  //                       </button>
  //                     </div>
  //                   </div>
  //                 </div>

  //                 <div className='row'>
  //                   <div className='col-lg-6'>
  //                     <Form.Item
  //                       wrapperCol={{
  //                         offset: 8,
  //                         span: 16,
  //                       }}
  //                     >
  //                       {addUserResp.statusCode === 200 ? (
  //                         <Modal show={show} onHide={handleClose}>
  //                           <Modal.Header closeButton>
  //                             <Modal.Title className='text-center'>Success</Modal.Title>
  //                           </Modal.Header>
  //                           <Modal.Body>{addUserResp.message}</Modal.Body>
  //                           <Modal.Footer></Modal.Footer>
  //                         </Modal>
  //                       ) : addUserResp.statusCode === 400 ? (
  //                         <Modal show={show} onHide={handleClose}>
  //                           <Modal.Header closeButton>
  //                             <Modal.Title className='text-center'>Failed</Modal.Title>
  //                           </Modal.Header>
  //                           <Modal.Body>{addUserResp.data.message}</Modal.Body>
  //                           <Modal.Footer></Modal.Footer>
  //                         </Modal>
  //                       ) : (
  //                         <Modal show={show} onHide={handleClose}>
  //                           {/* <Modal.Header closeButton>
  //                             <Modal.Title>Confirmation</Modal.Title>
  //                           </Modal.Header> */}
  //                           <Modal.Body>Are you sure you want to update ethnicity?</Modal.Body>
  //                           <Modal.Footer>
  //                             <Button variant='secondary' onClick={handleClose}>
  //                               Cancel
  //                             </Button>
  //                             <Button variant='primary' htmlType='submit' onClick={submitData}>
  //                               Confirm
  //                             </Button>
  //                           </Modal.Footer>
  //                         </Modal>
  //                       )}
  //                     </Form.Item>
  //                   </div>
  //                 </div>
  //               </Form>
  //             ) : (
  //               ' '
  //             )}
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   </>
  // );
};

export default editEthnicity;
