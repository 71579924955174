import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { app } from '../../config';
import { useNavigate } from 'react-router-dom';
import { Pagination, Input, Form, Select, Row, Col, DatePicker, message } from 'antd';
import { useTranslation } from 'react-i18next';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { EditTwoTone, BranchesOutlined } from '@ant-design/icons';
import getHeaders from '../../utils/authHeaders';
import ListinngCard from '../../layouts/ListingCard';
import { dateConverter } from '../../utils/utcDateConversionCommon';
import AddNewImg from '../../assets/images/add-new.png';
import editBtnImg from '../../assets/images/edit.png';
import lockIcon from '../../assets/images/lock.png';
const Banners = () => {
  const [editbtn, setEditBtn] = useState('none');
  const [pageSize, setPageSize] = useState(10);
  const [fetchPartners, setFetchPartners] = useState([]);
  const [updbatchId, setUpdBatchId] = useState('');
  const [delBatchId, setDelBatchId] = useState('');
  const [pagesCount, setPagesCount] = useState(0);
  const [searchShow, setSearchShow] = useState({});
  const [renderList, setRenderList] = useState();
  const [tableShow, setTableShow] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [err, setErr] = useState('');
  const [show, setShow] = useState(false);
  const [lockModalShow, setLockModalShow] = useState(false);
  const [delBatchResp, setDelBatchResp] = useState({});
  const [lockBatchResp, setLockBatchResp] = useState({});
  const [batchListing, setBatchListing] = useState({});
  const handleClose = () => setShow(false);
  const [loader, setLoader] = useState(false);

  const handleLockClose = () => setLockModalShow(false);
  let navigate = useNavigate();
  let convertedObj;

  // let searchItems = [
  //   {
  //     name: 'Batch No.',
  //     value: 'batchno',
  //   },
  //   {
  //     name: 'Product',
  //     value: 'serialNo',
  //   },
  //   {
  //     name: 'Partner Name',
  //     value: 'partnerName',
  //   },
  //   {
  //     name: 'Date',
  //     value: 'date',
  //   },
  // ];

  const user = JSON.parse(localStorage.getItem('user'));
  if (user.userType === 'A') setEditBtn('block');
  const addBanner = () => {
    navigate('/banner/create', { replace: true });
  };
  const editButton = (values) => {
    console.log(values.bannerId);
    localStorage.setItem('bannerId', values.bannerId);
    navigate('/banner/update', { replace: true });
  };

  const inventoryListingApi = async (formData) => {
    console.log(formData);
    setLoader(true)
    const partnerResp = await axios.post(`${app.URL}/api/v1/admin/banner/listing`, formData, { headers: getHeaders() });
    // console.log(partnerResp)
    partnerResp ? console.log(partnerResp.data.result.data) : '';
    partnerResp ? (convertedObj = dateConverter(partnerResp.data.result.data.data, 'createdAt')) : ' ';
    partnerResp.data.result ? setFetchPartners(convertedObj) : ' ';
    partnerResp.data.result ? setPagesCount(partnerResp.data.result.data.count) : ' ';
    partnerResp.data.result ? setRenderList(convertedObj) : ' ';
    partnerResp.data.result && setLoader(false);
  };
  const onChange = async (page, pageSize) => {
    console.log(page, pageSize);
    setPageSize(pageSize);
    setCurrentPage(page);
    if (!page) page = 1;
    let limit = pageSize;
    try {
      let reqObj;
      if (Object.keys(searchShow).length !== 0) {
        reqObj = {
          limit: limit,
          offset: limit * (page - 1),
          searchBy: searchShow.searchBy,
          searchTxt: searchShow.searchTxt,
        };
      } else {
        reqObj = {
          limit: limit,
          offset: limit * (page - 1),
          searchBy: 'bannerName',
          searchTxt: '',
        };
      }
      console.log(reqObj);
      await inventoryListingApi(reqObj);
    } catch (e) {
      console.log('Error');
      if (e.message) setErr(e.message);
      setTableShow(false);
    }
  };

  const getData = async () => {
    let formData = {
      limit: pageSize,
      offset: 0,
      searchBy: '',
      searchTxt: '',
    };
    console.log(formData);
    const partnerResp = await axios.post(`${app.URL}/api/v1/admin/banner/listing`, formData, { headers: getHeaders() });
    console.log(partnerResp);
   
    partnerResp ? console.log(partnerResp.data.result.data) : ' ';
    partnerResp.data.result ? (convertedObj = await dateConverter(partnerResp.data.result.data.data, 'createdAt')) : ' ';
    partnerResp.data.result ? setFetchPartners(convertedObj) : ' ';
    partnerResp.data.result ? setPagesCount(partnerResp.data.result.data.count) : ' ';
    partnerResp.data.result ? setRenderList(convertedObj) : ' ';
    partnerResp.data.result && setLoader(false);
    return console.log('return called');
  };
  useEffect(() => {
    setLoader(true);
    getData();
  }, []);

  let searchItems = [
    {
      name: 'Banner Title',
      value: 'bannerName',
    },
    {
      name: 'Product Name',
      value: 'productName',
    },
  ];

  const onFinish = async (values) => {
    console.log(values);
    // if (values.searchFor == 'batchno') {
    //   values.searchFor = 'batchNo';
    // }

    setSearchShow(values);
    try {
      let searchData = {
        limit: 10,
        offset: 0,
        searchBy: values.searchBy,
        searchTxt: values.searchTxt,
      };
      if (!searchData.searchTxt) message.error('Please enter search text');
      else await inventoryListingApi(searchData);
    } catch (e) {
      console.log('Error');
      if (e.message) setErr(e.message);
      setTableShow(false);
    }
  };
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  const tableHeaders = ['Banner Title', 'Product Name', 'Created On', 'Status', 'Action'];

  const handleOnChange = (e) => {
    if (e.target.value.length === 0) {
      getData();
      setSearchShow({});
    }
  };
  return (
    <ListinngCard
      heading='Banner Management'
      buttonName='Add Banner'
      addButton={addBanner}
      onSearch={onFinish}
      onSearchFailed={onFinishFailed}
      searchItems={searchItems}
      tableHeaders={tableHeaders}
      tableRows={renderList}
      editButton={editButton}
      onPageChange={onChange}
      currentPage={currentPage}
      pagesCount={pagesCount}
      patientsListing={false}
      inventoryListing={false}
      storeListing={false}
      productListing={false}
      userListings={false}
      feedbackListing={false}
      bannerListing={true}
      onChange={handleOnChange}
      loader={loader}
    />
    // <div className='for-pseudo ml-3 col-lg-12 col-md-12 col-sm-12' style={{ backgroundColor: '#fff', borderRadius: '10px', marginBottom: '20px' }}>

    //   <div style={{ paddingBottom: '50px', paddingLeft: '50px' }}>

    //     <div className='col-lg-11 col-md-12 col-sm-12 '>
    //       <div>
    //         <table id='customers'>
    //           <thead class=''>
    //             <tr>
    //               <th>Title</th>
    //               <th>Product Name</th>
    //               <th>Created On</th>
    //               <th>Status</th>
    //               <th>Action</th>
    //             </tr>
    //             {/* <tr>
    //             {tableHeaders.map(header => (
    //               <th>{header}</th>
    //             ))}
    //           </tr> */}
    //           </thead>
    //           <tbody>
    //             {renderList
    //               ? renderList.map((tableValues) => (
    //                   <tr>
    //                     <td style={{ width: '200px' }}>
    //                       <a>{tableValues.bannerName ? tableValues.bannerName : 'null'}</a>
    //                     </td>
    //                     <td style={{ width: '250px' }}>{tableValues.productName ? tableValues.productName : 'null'}</td>
    //                     <td style={{ width: '200px' }}>{tableValues.createdAt ? tableValues.createdAt : 'null'}</td>
    //                     <td style={{ width: '200px' }}>{tableValues.isActive == '1' ? 'Active' : 'Inactive'}</td>
    //                     <td>
    //                       <a>
    //                         <img style={{ width: '25px', display: 'block' }} alt='edit btn img' src={editBtnImg} onClick={() => editButton(tableValues)} />
    //                       </a>
    //                     </td>
    //                   </tr>
    //                 ))
    //               : ' '}
    //           </tbody>
    //         </table>
    //       </div>
    //     </div>
    //     {currentPage && pagesCount ? <Pagination style={{ marginTop: '10px' }} onChange={onChange} defaultCurrent={currentPage} total={pagesCount} showTotal={(total) => `Total ${total} items`} showSizeChanger /> : ' '}
    //   </div>
    // </div>
  );
};

export default Banners;
