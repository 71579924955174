import axios from 'axios';
import { useEffect, useState } from 'react';
import { app } from '../config';
import getHeaders from '../utils/authHeaders';
const useFetchQuestionnaire = (setQuestionnaire,setIsShowQuestionnaire, productId) => {
  const [loading, setLoading] = useState(true);
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(`${app.URL}/api/v1/admin/questions/get/${productId}`, { headers: getHeaders() });
        setResponse(res.data.result);
        // console.log(res.data.result.data.map((item) => item.type));
        console.log(res.data.result.data.isShowQuestionnaire);
        setQuestionnaire(res.data.result.data.questionData);
        setIsShowQuestionnaire(res.data.result.data.isShowQuestionnaire);
        setLoading(false);
      } catch (err) {
        setError(err);
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  return { loading, response, error };
};

export default useFetchQuestionnaire;
