
export const Cardiology = ({ title, pointer, total, category }) => {
  return (
    <div className='cardiology'>
      <h4>
        <strong>{title}</strong>
      </h4>
      <div className='d-flex align-items-center cardiology-level'>
        <span
          className='pointer'
          style={{
            left: `${(pointer / total) * 100}%`,
          }}
        >
          {pointer}
        </span>
        {category.map((item, indx) => (
          <span
            style={{
              width: `${(item.end / total) * 100}%`,
              height: '20px',
              backgroundColor: item.color,
              border: '1px solid #fff',
              borderTopLeftRadius: indx === 0 && '10px',
              borderBottomLeftRadius: indx === 0 && '10px',
              borderTopRightRadius: indx === 2 && '10px',
              borderBottomRightRadius: indx === 2 && '10px',
            }}
          >
            <small>
              {item.start}-{item.end}
            </small>
          </span>
        ))}
       
      </div>
    </div>
  );
};
