import React, { useEffect, useState } from 'react';
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import fetchCurrentAccount from '../../hooks/fetchCurrentAccount';
import { logOutHandler } from '../../hooks/logoutUser';
import LoaderImage from '../../pages/LoaderImage';

const NewSideBar = () => {
  const { t } = useTranslation(['common']);
  const [checkRoute, setCheckRoute] = useState('');

  const [isAdmin, setisAdmin] = useState(false);
  const [currentLocation, setCurrentLocation] = useState('');
  const [trigger, setTrigger] = useState(false);
  const [fetchedRoutes, setFetchedRoutes] = useState([]);
  const navigate = useNavigate();
  const userEmail = JSON.parse(localStorage.getItem('user'));
  //  console.log(userEmail)

  let currentAccocuntListing = fetchCurrentAccount(userEmail?.result.data.user.email);
  const user = currentAccocuntListing.response ? currentAccocuntListing.response.data.permission : [];
  // console.log(currentAccocuntListing);
  // getStorageItem(`user`, (result) => {
  //   user = JSON.parse(result);
  // });
  useEffect(() => {
    setisAdmin(true);
  }, [user]);

  useEffect(() => {
    setCurrentLocation(window.location.pathname);
  }, [window.location.pathname]);

  function removeDuplicates(originalArray) {
    let uniqueArray = [];
    let uniqueObject = {};

    for (let i = 0; i < originalArray.length; i++) {
      const obj = originalArray[i];
      const key = JSON.stringify(obj);

      if (!uniqueObject[key]) {
        uniqueArray.push(obj);
        uniqueObject[key] = true;
      }
    }

    return uniqueArray;
  }
  console.log('user', user);

  function mergeObjectsByKey(arr, key) {
    const groupedObjects = {};

    arr.forEach((obj) => {
      const keyValue = obj[key];
      if (!groupedObjects[keyValue]) {
        groupedObjects[keyValue] = {};
      }
      for (let [k, v] of Object.entries(obj)) {
        if (k !== key) {
          groupedObjects[keyValue][k] = (groupedObjects[keyValue][k] || []).concat(v);
        } else {
          groupedObjects[keyValue][k] = v;
        }
      }
    });

    return Object.values(groupedObjects);
  }
  // let serverRoutes = [];
  const routes2 = user;
  // const unqArr = routes2 ? removeDuplicates(routes2) : [];
  // const mergedArr = routes2 ? mergeObjectsByKey(routes2, 'module') : [];
  let masha = [];
  let mirha = [];
  console.log(
    'user',
    routes2.filter((item) => item.parentElement === 'feedback')
  );

  routes2
    ? routes2.map((item, index) => {
        if (item.isRender && item.isRoot) {
          masha.push(item);
        }
        if (item.isRender && !item.isRoot) {
          mirha.push(item);
        }
      })
    : ' ';

  function extractValue(arr, module) {
    let extractedValue = arr.map((item) => {
      return item[module];
    });

    return extractedValue;
  }

  let modules = [];
  if (mirha.length > 0)
    mirha.forEach((ele) => {
      modules.push(ele.parentElement);
    });
  let newArr = [];
  if (routes2)
    routes2.forEach((ele) => {
      newArr.push({ module: ele.module, render: ele.is_render, action: ele.action, root: ele.is_root });
    });

  let newArray = [];

  if (routes2) {
    for (let i = 0; i < routes2.length; i++) {
      modules.forEach((ele) => {
        // console.log(ele, routes2[i].parentElement);
        if (ele == routes2[i].parentElement && routes2[i].isRender) {
          // console.log("In iffff")
          newArray.push(routes2[i]);
        }
      });
    }
  }
  modules = [...new Set(modules)];

  const myFunc = () => {
    setTrigger(true);
    document.getElementById('menu').style.cssText = 'background-color: #054155; color: white';
  };
  const revertChanges = () => {
    setTrigger(false);
    document.getElementById('menu').style.cssText = 'background-color: "white"; color: black';
  };

  return (
    <div style={{ display: 'flex' }} id='sidebar' role='navigation'>
      <Sidebar>
          {!isAdmin && <LoaderImage />}
        <Menu>
          {masha.map((ele) => {
            return (
              <>
                <MenuItem style={{ marign: '0px', padding: '0px', backgroundColor: `${'/' + ele.module + ele.action === currentLocation && !trigger ? '#054155' : ''}`, color: `${'/' + ele.module + ele.action === currentLocation && !trigger ? 'white' : ' '}` }} onClick={revertChanges} key={ele.roleId}>
                  <Link id='cls' to={`/${ele.action}`} style={{ textTransform: 'capitalize' }}>
                    {ele.menuName}...
                  </Link>
                </MenuItem>
              </>
            );
          })}
          {modules.map((element) => {
            // console.log(element)
            return (
              <>
                {element != 'orguser' && element != 'userperm' ? (
                  <SubMenu id='menu' style={{ textTransform: 'capitalize' }} onClick={myFunc} label={element === 'transaction' ? 'donation' : element === 'events' ? 'Courses & Events' : element === 'orgreport' ? 'reports' : element === 'view' ? 'cause' : element === 'orguser' ? 'Users' : element}>
                    {routes2.map((ele, indx) => {
                      if (ele.parentElement === element && ele.isRender && !ele.isRoot) {
                        // console.log(indx, ele);
                        // console.log('/' + ele.action === currentLocation);
                        // console.log(currentLocation);
                        // console.log(ele.action);
                        return (
                          <h1>
                            <MenuItem id='cls2' key={ele.roleId} onClick={revertChanges} style={{ textTransform: 'capitalize', color: `${'/' + ele.action === currentLocation && !trigger ? '#054155' : ' '}`, fontWeight: `${'/' + ele.action === currentLocation && !trigger ? 'bold' : ' '}` }}>
                              <Link id='cls2' to={`/${ele.action}`} onClick={myFunc} style={{ textTransform: 'capitalize', color: `${'/' + ele.action === currentLocation && !trigger ? '#054155' : ' '}`, fontWeight: `${'/' + ele.action === currentLocation && !trigger ? 'bold' : ' '}` }}>
                                {ele.menuName}
                              </Link>
                            </MenuItem>
                          </h1>
                        );
                      }
                    })}
                  </SubMenu>
                ) : (
                  ' '
                )}
              </>
            );
          })}
        </Menu>
        <div class='row'>
          <div class='col-lg-11 text-center'>
            <button class=' btn button mt-5 text-white' onClick={logOutHandler}>
              Sign out
            </button>
          </div>
        </div>
      </Sidebar>
    </div>
  );
};

export default NewSideBar;
