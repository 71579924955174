import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes, useNavigate, useParams, Navigate } from 'react-router-dom';
import { getStorageItem } from './utils/localStorage';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import routess from './routes/routes';
import { useSelector } from 'react-redux';
import Unauthorized from './pages/Unauthorized';
import NewSideBar from './components/Sidebar/NewSideBar';
import Navbar from './components/Navbars/Navbar';
import NavImg from './assets/images/navbar.png';
// import OrgNav from './components/Navbars/OrgNav';
import './styles.css';
import { logOutHandler } from './hooks/logoutUser';

function RoutesFilter() {
  // console.log('routess ', routess);
  const [showSidebar, setShowSidebar] = useState(true);

  const navigate = useNavigate();
  let user = {};
  getStorageItem(`user`, (result) => {
    user = JSON.parse(result);
    if (!user?.result) {
      localStorage.removeItem('user');
      // console.log('Done');
      navigate('/adminlogin');
      // console.log('After navigation complete');
    }
  });
  let allowed = [];
  let filteredRoutes = [];
  let allowed2 = [];
  // console.log('User ', user);
  if (!user) navigate('/admin/login');
  else {
    allowed = user.result.data.permission;
    // allowed routes for user
    allowed.forEach((element) => {
      allowed2.push(element.action);
    });
    // debugger;
    if (allowed) {
      filteredRoutes = routess.filter((value) => {
        return allowed2.includes(value.path);
      });
    }
  }
  const loginuser = useSelector((state) => state.admin);

  // Function to close the sidebar when clicking outside
  function closeSidebarOnClickOutside(event) {
    var sidebar = document.getElementById('sidebar');

    if (sidebar) {
      // Check if the click is outside the sidebar
      if (event.target !== sidebar && !sidebar.contains(event.target)) {
        setShowSidebar(false); // Close the sidebar
      }
    }
  }
  // Add click event listener to the document body
  // document.body.addEventListener('click', closeSidebarOnClickOutside);

  const [isScroll, setIsScroll] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  // This Will Logout User On Tab Close
  useEffect(() => {
    window.addEventListener('beforeunload', logOutHandler);

    return () => {
      window.removeEventListener('beforeunload', logOutHandler);
    };
  }, [isClosing]);

  // console.log('filteredRoutes ', filteredRoutes);
  if (user?.userType === 'A') {
    return (
      <>
        {allowed && loginuser ? (
          <div className='row'>
            {user?.result.data.user.userType === 'A' || 'S' ? <Navbar /> : ''}
            <div className='side-bar col-lg-2 col-md-12 col-sm-12'>
              <NewSideBar />
            </div>
            <div className='col-lg-10 col-md-12 col-sm-12 right-content'>
              <Routes>
                {filteredRoutes.map(({ id, path, component }) => {
                  return (
                    <>
                      {window.location.pathname === '/' ? (user.result.data.user.userType === 'A' || 'S' || 'O' ? navigate('/dashboard/listing') : navigate('/organization/dashboard')) : ' '}
                      {path != window.location.pathname ? <Route path='*' element={<Unauthorized />} /> : ' '}
                      <Route key={id} path={path} element={component} />
                    </>
                  );
                })}

                {/* </Routes>:<div>No Routes Found</div> */}
              </Routes>
            </div>
          </div>
        ) : (
          <div></div>
        )}
      </>
    );
  } else {
    return (
      <>
        {allowed && loginuser ? (
          <div className='container-fluid p-0'>
            <div className='row'>
              {user?.result.data.user.userType === 'A' || 'S' ? <Navbar /> : <OrgNav />}
              <div className='sidebar-toggle' style={{ position: 'fixed', opacity: isScroll ? 0.3 : 1, zIndex: 1 }}>
                <label className='for-image-nav' htmlFor='reveal'>
                  <img src={NavImg} onClick={() => setShowSidebar(!showSidebar)} />
                </label>
              </div>
              {showSidebar && (
                <div className='side-bar' id='sidebar' style={{ position: 'fixed', zIndex: 1 }}>
                  <NewSideBar />
                </div>
              )}
              <div className={`col-lg-${!showSidebar ? '12' : '9'}  col-12 right-content ${showSidebar && 'show'}`} onClick={closeSidebarOnClickOutside}>
                <Routes>
                  {filteredRoutes.map(({ id, path, component }) => {
                    return (
                      <>
                        {window.location.pathname === '/' ? (user.result.data.user.userType === 'A' || 'S' ? navigate('/dashboard/listing') : navigate('/organization/dashboard')) : ' '}
                        {path != window.location.pathname ? <Route path='*' element={<Unauthorized />} /> : ' '}
                        <Route key={id} styles={window.location.pathname === path ? `${{ backgroundColor: 'red' }}` : ' '} path={path} element={component} />
                      </>
                    );
                  })}
                </Routes>
              </div>
            </div>
          </div>
        ) : (
          <div></div>
        )}
      </>
    );
  }
}

export default RoutesFilter;
