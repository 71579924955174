import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { app } from '../../config';
import { useNavigate } from 'react-router-dom';
import useFetchUsers from '../../hooks/fetchUsers';
import { message, Pagination, Input, Form, Select, Row, Col, DatePicker } from 'antd';
import { useTranslation } from 'react-i18next';
import { EditTwoTone } from '@ant-design/icons';
import getHeaders from '../../utils/authHeaders';
import ListinngCard from '../../layouts/ListingCard';

function Patients() {
  const { t } = useTranslation(['common']);
  let navigate = useNavigate();
  let searchedUser;
  const [editbtn, setEditBtn] = useState('none');
  const [search, setSearch] = useState('');
  const [pageSize, setPageSize] = useState(10);
  const [fetchUsers, setFetchUsers] = useState([]);
  const [pagesCount, setPagesCount] = useState(0);
  const [searchShow, setSearchShow] = useState({});
  const [renderList, setRenderList] = useState();
  const [tableShow, setTableShow] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  // const [searchItems, setSearchItems] = useState([]);
  const [tableHeaders, setTableHeaders] = useState([]);
  const [loader, setLoader] = useState(false);

  const [err, setErr] = useState('');

  const patientListingApi = async (formData) => {
    setLoader(true);
    const userResp = await axios.post(`${app.URL}/api/v1/admin/patient/listing`, formData, { headers: getHeaders() });
    userResp.data.result ? setFetchUsers(userResp.data.result.data) : ' ';
    userResp.data.result ? setPagesCount(userResp.data.result.data.count) : ' ';
    userResp.data.result ? setRenderList(userResp.data.result.data.data) : ' ';
    userResp.data.result && setLoader(false);
  };

  const userList = async () => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user.userType === 'A') setEditBtn('block');
    let formData = {
      limit: pageSize,
      offset: 0,
      searchBy: 'firstName',
      searchTxt: '',
    };
    await patientListingApi(formData);
  };
  useEffect(() => {
    let header = ['Customer Name', 'Address', 'City', 'Email', 'Mobile', 'Status', 'Action'];
    setTableHeaders(header);
    setLoader(true);
    userList();
    // setSearchItems(searchFilters);
  }, []);
  let searchItems = [
    {
      name: 'Name',
      value: 'firstName',
    },
    {
      name: 'Email',
      value: 'email',
    },
    {
      name: 'Mobile',
      value: 'mobile',
    },
  ];
  
  const onChange = async (page, pageSize) => {
    setPageSize(pageSize);
    setCurrentPage(page);
    if (!page) page = 1;
    let limit = pageSize;

    try {
      let reqObj;
      if (Object.keys(searchShow).length !== 0) {
        reqObj = {
          limit: limit,
          offset: limit * (page - 1),
          searchBy: searchShow.searchBy,
          searchTxt: searchShow.searchTxt,
        };
      } else {
        reqObj = {
          limit: limit,
          offset: limit * (page - 1),
          searchBy: 'firstName',
          searchTxt: '',
        };
      }

      await patientListingApi(reqObj);
    } catch (e) {
      console.log('Error');
      if (e.message) setErr(e.message);
      setTableShow(false);
    }
  };

  const onFinish = async (values) => {
    setSearchShow(values);
     let formData = {
       limit: 10,
       offset: 0,
       searchBy: values.searchBy,
       searchTxt: values.searchTxt,
     };
     if (!formData.searchTxt) message.error('Please enter search text');
     else await patientListingApi(formData);
  };
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const handleSearchChange = (e) => {
    if (!e.target.value) {
      setRenderList(fetchUsers.slice(0, 10));
      //  console.log(organizations.length)
      setPagesCount(fetchUsers.length);
      setTableShow(true);
    }
    setSearch(e.target.value);
  };

  const editPatient = (e) => {
    localStorage.setItem('editPatientId', JSON.stringify(e.patientId));
    // console.log(e)
    navigate('/patient/update', { replace: true });
  };
  const addPatient = () => {
    navigate('/patient/create', { replace: true });
  };

  console.log(renderList);

  const handleOnChange = (e) => {
    if (e.target.value.length === 0) {
      userList();
      setSearchShow({});
    }
  };

  return <ListinngCard loader={loader} onChange={handleOnChange} heading='Customer Management' buttonName='Add Customer' addButton={addPatient} onSearch={onFinish} onSearchFailed={onFinishFailed} searchItems={searchItems} tableHeaders={tableHeaders} tableRows={renderList} editButton={editPatient} onPageChange={onChange} currentPage={currentPage} pagesCount={pagesCount} patientsListing={true} />;
}

export default Patients;
