import React, { useEffect, useState } from 'react';
import { InputField, TextAreaField } from '../layouts/FormFields';
import { Button, Form, Space, Switch, Upload } from 'antd';
import axios from 'axios';
import { app } from '../config';
import getHeaders from '../utils/authHeaders';
import LoaderImage from '../pages/LoaderImage';
import NoDataFound from '../layouts/NoDataFound';
import Modal from 'react-bootstrap/Modal';
import { LoadingOutlined, UploadOutlined } from '@ant-design/icons';
import { useLocation } from 'react-router-dom';

const Confifurations = () => {
  const [renderList, setRenderList] = useState([]);
  const [loader, setLoader] = useState(false);
  const [editloader, setEditLoader] = useState(false);
  const [configs, setConfigs] = useState([]);
  const [updateId, setUpdateId] = useState('');
  let location = useLocation();

  const [isWrite, setIsWrite] = useState(true);

  const getConfigsListing = async () => {
    console.log('Listing API Called');
    setLoader(true);
    const res = await axios.get(`${app.URL}/api/v1/admin/config/view`, { headers: getHeaders() });
    console.log(res);
    res.data.result.success && setRenderList(res.data.result.data);

    setLoader(false);
  };

  const user = JSON.parse(localStorage.getItem('user'));
  const putAnuraCategoryListing = async (detail, configId) => {
    console.log(detail, configId);
    let userId = user?.result.data.user.userId;
    detail.userId = userId;
    setEditLoader(true);
    console.log(detail);
    const res = await axios.post(`${app.URL}/api/v1/admin/config/update/${configId}`, detail, { headers: getHeaders() });
    setEditLoader(false);
    res.data.result.success && setConfigs([]);
    res.data.result.success && getConfigsListing();
  };

  useEffect(() => {
    getConfigsListing();
    const [filteredRoute] = user.result.data.permission.filter((item) => item.action === location.pathname.slice(1));
    setIsWrite(filteredRoute.isWrite);
  }, []);

  // const onFinish = (values) => {
  //   let updateData = {
  //     displayName: values.displayName,
  //     value: values.value,
  //   };
  //   setUpdateId(values.configId);
  //   putAnuraCategoryListing(updateData, values.configId);
  // };
  // const onFinishFailed = () => {};
  return (
    <div className='dashboard-card pb-5 container-fluid'>
      <div className='row p-0 pb-4'>
        <div className='for-before col-12 col-md-7 head fs-3 fw-bold' style={{ backgroundColor: '#a7c1d9', borderBottomRightRadius: '10px', color: '#FFFFFF' }}>
          Configurations Management
        </div>
      </div>
      <div className='mt-4'>
        {loader && <LoaderImage />}
        {!loader && renderList.length === 0 && <NoDataFound />}
        {!loader && renderList.length > 1 && (
          <table id='customers' className='px-3 facial-scan-cat-table config'>
            <thead>
              <tr>
                <th>Key</th>
                <th>Value</th>
              </tr>
            </thead>
          </table>
        )}
        {!loader && renderList.map((item, indx) => <ConfigurationDetails isWrite={isWrite} indx={indx} item={item} editloader={editloader} setUpdateId={setUpdateId} updateId={updateId} configs={configs} setConfigs={setConfigs} putAnuraCategoryListing={putAnuraCategoryListing} />)}
      </div>
    </div>
  );
};

const ConfigurationDetails = ({ isWrite, indx, item, updateId, configs, setConfigs, editloader, setUpdateId, putAnuraCategoryListing }) => {
  const [videoUrl, setVideoUrl] = useState(item.value);
  const [contentType, setContentType] = useState();
  const [uplVid, setuplVid] = useState(true);

  // State management for image
  const [imageUrl, setImageUrl] = useState(item.value);
  const [uplImg, setuplImg] = useState(true);

  // Video related functions
  const VideoToggle = () => {
    setVideoUrl('');
    setTimeout(() => setVideoUrl(item.value), 500);
  };

  // Image related functions
  const ImageToggle = () => {
    setImageUrl('');
    setTimeout(() => setImageUrl(item.value), 500);
  };

  const beforeUpload = (file) => {
    console.log(file);
    setVideoUrl('');
    setImageUrl('');
    if (file.type === 'image/jpeg' || file.type === 'image/png') {
      console.log('setting image type');
      setContentType('img');
    } else if (file.type === 'video/x-matroska' || file.type === 'video/mp4') {
      setContentType('video');
    }
    const isSupportedFileType = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'video/x-matroska' || file.type === 'video/mp4';
    if (!isSupportedFileType) {
      message.error('You can only upload JPG/PNG/MP4/MKV files!');
    }
    console.log('file Checked');
    const isLtSizeLimit = file.size / 1024 / 1024 < (file.type.includes('video') ? 60 : 5);
    if (!isLtSizeLimit) {
      setLargeFile(true);
      message.error(file.type.includes('video') ? 'Video must be smaller than 60MB!' : 'Image must be smaller than 5MB!');
    }
    console.log('message');
    return isSupportedFileType && isLtSizeLimit;
  };

  const handleuploadChange = (info) => {
    if (info.file.status === 'uploading') {
      return;
    }
    if (info.file.status === 'done') {
      if (info.file.type.includes('video')) {
        info.file ? setVideoUrl(info.file.response.data.Location) : ' ';
      } else {
        info.file ? setImageUrl(info.file.response.data.Location) : ' ';
      }
    }
  };

  useEffect(() => {
    if (videoUrl !== item.value || imageUrl !== item.value) {
      if (!configs.includes(item.configId)) {
        setConfigs([...configs, item.configId]);
      }
    } else {
      const filterId = configs.filter((id) => id !== item.configId);
      setConfigs(filterId);
    }
  }, [videoUrl, imageUrl]);

  const onFinish = (values) => {
    console.log(values);
    let updateData = {
      displayName: values.displayName,
      value: item.type === 'video' ? videoUrl : item.type === 'num' ? values.value : imageUrl,
    };
    setUpdateId(values.configId);
    console.log(updateData);
     putAnuraCategoryListing(updateData, values.configId);
  };

  const onFinishFailed = () => {};
  return (
    <Form
      key={indx}
      name='basic'
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete='off'
      initialValues={{
        remember: true,
        ['displayName']: item.displayName,
        ['value']: item.value,
        ['configId']: item.configId,
      }}
      className='row px-3'
    >
      <InputField
        col='col-md-3 col-4'
        name='displayName'
        // label='Name'
        placeholder='Enter Display Name'
        rules={[
          {
            required: true,
            message: 'Enter Display Name',
          },
        ]}
        onChange={(e) => {
          if (e.target.value !== item.displayName) {
            if (!configs.includes(item.configId)) {
              setConfigs([...configs, item.configId]);
            }
          } else {
            const filterId = configs.filter((id) => id !== item.configId);
            setConfigs(filterId);
          }
        }}
      />
      {item.type === 'text' && (
        <TextAreaField
          col='col-md-4 col-5'
          name='value'
          // label='Display Position'
          placeholder='Enter Value'
          rules={[
            {
              required: true,
              message: 'Enter Value',
            },
          ]}
          rows={3}
          // required={true}
          onChange={(e) => {
            if (e.target.value !== item.value) {
              if (!configs.includes(item.configId)) {
                setConfigs([...configs, item.configId]);
              }
            } else {
              const filterId = configs.filter((id) => id !== item.configId);
              setConfigs(filterId);
            }
          }}
        />
      )}

      {item.type === 'video' && (
        <div className='col-md-4 col-12 mb-3 bg-light py-2 rounded image-column'>
          {videoUrl && (
            <video className='m-0' style={{ width: '100%', borderRadius: '5px', boxShadow: '3px 3px 10px #ccc' }} controls title='Video player'>
              <source src={videoUrl} type='video/mp4'></source>
            </video>
          )}
          <Form.Item colon={false} className='mt-2'>
            <Space
              direction='vertical'
              style={{
                width: '100%',
              }}
              size='large'
            >
              <Upload onRemove={VideoToggle} listType='picture' maxCount={1} className='avatar-uploader' headers={getHeaders()} action={`${app.URL}/api/v1/general/config/videoupload`} beforeUpload={beforeUpload} onChange={handleuploadChange}>
                <Button icon={<UploadOutlined />} className={'upload-btn'} style={{ color: 'black' }}>
                  Upload Video
                </Button>
                <div className='mt-2'>Note: Upload size for video is 50mb supported format for video are (mkv/mp4)</div>
              </Upload>
            </Space>
          </Form.Item>
        </div>
      )}

      {item.type === 'image' && (
        <div className='col-md-4 col-12 mb-3 bg-light py-2 rounded image-column'>
          {imageUrl && <img src={imageUrl} alt='Uploaded Image' className='m-0' style={{ width: '100%', borderRadius: '5px', boxShadow: '3px 3px 10px #ccc' }} />}
          <Form.Item colon={false} className='mt-2'>
            <Space
              direction='vertical'
              style={{
                width: '100%',
              }}
              size='large'
            >
              <Upload onRemove={ImageToggle} listType='picture' maxCount={1} className='avatar-uploader' headers={getHeaders()} action={`${app.URL}/api/v1/general/config/logoupload`} beforeUpload={beforeUpload} onChange={handleuploadChange}>
                <Button icon={<UploadOutlined />} className={'upload-btn'} style={{ color: 'black' }}>
                  Upload Image
                </Button>
                <div className='mt-2'>Note: Upload size for images is 5MB, supported formats are (jpg/png/jpeg)</div>
              </Upload>
            </Space>
          </Form.Item>
        </div>
      )}

      {item.type === 'num' && (
        <InputField
          col='col-md-2 col-3'
          name='value'
          // label='Name'
          placeholder={`Enter Value ${item.key === 'sampleWaitingTime' && '(minutes)'}`}
          rules={[
            {
              required: true,
              message: 'Enter Value',
            },
          ]}
          type='number'
          onChange={(e) => {
            if (e.target.value !== item.value) {
              if (!configs.includes(item.configId)) {
                setConfigs([...configs, item.configId]);
              }
            } else {
              const filterId = configs.filter((id) => id !== item.configId);
              setConfigs(filterId);
            }
          }}
        />
      )}
      <Form.Item className='d-none selector-main' colon={false} valuePropName='checked' name='configId'></Form.Item>
      {configs.includes(item.configId) && isWrite && (
        <div className='col-2 pt-1'>
          <Button className=' btn btn-primary' htmlType='submit'>
            {editloader && item.configId === updateId ? <LoadingOutlined /> : 'Update'}
          </Button>
        </div>
      )}
    </Form>
  );
};

export default Confifurations;
