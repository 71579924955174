import React, { useEffect, useState } from 'react';
import { InputField, SelectField, TextAreaField } from '../../layouts/FormFields';
import { Form, Select, Space, Switch, InputNumber, message } from 'antd';
import { QuestionaireForm } from '../../layouts/AddQuestionaire';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { app } from '../../config';
import getHeaders from '../../utils/authHeaders';
import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';

const RequestButtonAdd = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [uploadObj, setUploadObj] = useState({});
  const [show, setShow] = useState(false);
  const [requestTypeOptions, setRequestTypeOptions] = useState([]);
  const [requestImage, setRequestImage] = useState(false);
  const [requestQuestion, setRequestQuestion] = useState(false);
  const [questionnaire, setQuestionnaire] = useState([
    {
      question: '',
      options: [''],
      type: '',
    },
  ]);

  const [typeLoader, setTypeLoader] = useState(false);

  useEffect(() => {
    const fetchRequestTypes = async () => {
      try {
        const res = await axios.get(`${app.URL}/api/v1/admin/reqbuttons/requestType`, { headers: getHeaders() });
        setRequestTypeOptions(
          res.data.result.data.map((item) => ({
            value: item.reqTypeId,
            label: item.reqTypeName,
          }))
        );
      } catch (err) {
        console.error(err);
      }
    };
    fetchRequestTypes();
  }, []);

  const onFinish = (values) => {
    const formattedQuestions = questionnaire.map((q) => ({
      ...q,
      displayPosition: q.displayPosition,
    }));

    setUploadObj({
      requestType: values.requestType,
      requestImage: requestImage ? 1 : 0,
      requestQuestion: requestQuestion ? 1 : 0,
      questions: formattedQuestions,
    });
    setShow(true);
  };

  const user = JSON.parse(localStorage.getItem('user'))

  const uploadData = async () => {
    setLoading(true);
    console.log('hello data:', uploadObj);
    let userId = user?.result.data.user.userId;
    uploadObj.userId = userId;
    try {
      const res = await axios.post(`${app.URL}/api/v1/admin/reqbuttons/create`, uploadObj, { headers: getHeaders() });
      setLoading(false);
      setShow(false);

      if (res.data.result.success) {
        message.success(res.data.result.msg);
        setTimeout(() => {
          navigate('/reqbuttons/view');
        }, 1000);
      } else {
        message.warning(res.data.result.msg);
      }
    } catch (err) {
      setLoading(false);
      setShow(false);
      console.error(err);
    }
  };

  const handleClose = () => {
    setShow(false);
  };

  const updateOptions = (inputValue, optionIndx, questionIndx) => {
    setQuestionnaire((prev) => {
      const updated = [...prev];
      updated[questionIndx].options[optionIndx] = inputValue;
      return updated;
    });
  };

  const deleteOption = (questionIndx, optionIndx) => {
    setQuestionnaire((prev) => {
      const updated = [...prev];
      updated[questionIndx].options.splice(optionIndx, 1);
      return updated;
    });
  };

  const deleteQuestion = (questionIndx) => {
    setQuestionnaire((prev) => {
      const updated = [...prev];
      updated.splice(questionIndx, 1);
      return updated;
    });
  };

  const addQuestion = () => {
    setQuestionnaire((prev) => [
      ...prev,
      {
        question: '',
        options: [''],
        type: '',
      },
    ]);
  };

  const changeQuestionType = (questionIndx, newType) => {
    setQuestionnaire((prev) => {
      const updated = [...prev];
      updated[questionIndx].type = newType;

      if (newType === 'text') {
        updated[questionIndx].options = ['']; // Ensure only one option exists
      }

      return updated;
    });
  };

  const changeQuestionText = (questionIndx, newText) => {
    setQuestionnaire((prev) => {
      const updated = [...prev];
      updated[questionIndx].question = newText;
      return updated;
    });
  };

  const changeQuestionRequired = (questionIndx, isRequired) => {
    setQuestionnaire((prev) => {
      const updated = [...prev];
      updated[questionIndx].isRequired = isRequired;
      return updated;
    });
  };

  function goBack() {
    navigate('/reqbuttons/view', { replace: true });
  }

  return (
    <QuestionaireForm
      loading={loading}
      pageTitle='Add Request Button'
      initialValues={{
        remember: true,
        questionnaire,
      }}
      onFinish={onFinish}
      handleClose={handleClose}
      show={show}
      addMessage='Are you sure you want to add Request Button?'
      goBack={goBack}
      submitData={uploadData}
      inputs={[
        <SelectField
          name='requestType'
          options={requestTypeOptions.map((option) => (
            <Select.Option value={option.value}>{option.label}</Select.Option>
          ))}
          rules={[{ required: true, message: 'Please select a request type!' }]}
          placeholder='Select Request Type'
          label='Request Type'
          required={true}
          col='col-lg-2 col-md-4 col-12'
        />,
      ]}
      extras={[
        <div className='col-md-12'>
          <div className='row'>
            <div className='d-flex align-items-center col-lg-2 col-md-4 col-12'>
              <Form.Item label='Get Image' valuePropName='checked'>
                <Switch onChange={setRequestImage} />
              </Form.Item>
            </div>
            <div className='d-flex align-items-center col-lg-2 col-md-4 col-12'>
              <Form.Item label='Ask Question' valuePropName='checked'>
                <Switch onChange={setRequestQuestion} />
              </Form.Item>
            </div>
            <Form.List name='questionnaire'>
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => {
                    function findOptions() {
                      const opt = questionnaire?.filter((item, ind) => ind === key);
                      if (opt.length > 0) {
                        return opt[0]?.options;
                      } else {
                        return [''];
                      }
                    }
                    return (
                      <Space key={key} className='col-md-12' align='baseline'>
                        <div className='row w-100'>
                          <InputField {...restField} col='col-lg-3 col-md-3 col-12' placeholder='Enter Question' rules={[{ required: true, message: 'Please Enter a Question' }]} label='Enter Question' name={[name, 'question']} type='text' onChange={(e) => changeQuestionText(key, e.target.value)} />

                          <SelectField
                            {...restField}
                            col='col-lg-2 col-md-3 col-12'
                            placeholder='Select Question Type'
                            rules={[{ required: true, message: 'Select a Question Type' }]}
                            label='Question Type'
                            required={true}
                            onChange={(e) => {
                              setTypeLoader(true);
                              changeQuestionType(key, e);
                              setTimeout(() => {
                                setTypeLoader(false);
                              }, 120);
                            }}
                            name={[name, 'type']}
                            options={[
                              { name: 'Select', value: '' },
                              { name: 'Multiple Choices', value: 'mcqs' },
                              { name: 'Policy', value: 'text' },
                            ].map((item) => (
                              <Select.Option value={item.value}>{item.name}</Select.Option>
                            ))}
                          />
                          <InputField
                            {...restField}
                            col='col-lg-2 col-md-2 col-12'
                            placeholder='Enter Display Position'
                            rules={[{ required: true, message: 'Please Enter a Display Position' }]}
                            label='Display Position'
                            name={[name, 'displayPosition']}
                            type='text'
                            onChange={(e) =>
                              setQuestionnaire((prev) => {
                                const updated = [...prev];
                                updated[key].displayPosition = Number(e.target.value);
                                return updated;
                              })
                            }
                            value={questionnaire[key].displayPosition}
                          />


                          {/* <Form.Item {...restField} name={[name, 'displayPosition']} label='Position' className='col-lg-2 col-md-3 col-12'>
                            <InputNumber
                              value={questionnaire[key].displayPosition}
                              
                            />
                          </Form.Item> */}

                          {questionnaire[key]?.type !== 'text' && !typeLoader && (
                            <div className='col-12'>
                              <div className='row'>
                                <AnswerOptions options={findOptions()} questionIndx={key} updateOptions={updateOptions} deleteOption={deleteOption} />
                              </div>
                            </div>
                          )}

                          {questionnaire[key]?.type === 'text' && !typeLoader && (
                            <TextAreaField
                              col='col-lg-7 col-md-7 col-7'
                              placeholder='Enter Answer'
                              rows={3}
                              label='Answer'
                              required={true}
                              type='textarea'
                              onChange={(e) => updateOptions(e.target.value, 0, key)} // Update first and only option
                            />
                          )}
                          {fields.length > 1 && (
                            <Form.Item
                              className='col-lg-2 d-flex align-items-center'
                              style={{
                                fontSize: '1rem',
                                paddingTop: '1rem',
                              }}
                            >
                              <div
                                className='add-btn'
                                style={{ textAlign: 'center', backgroundColor: '#F21D2F' }}
                                onClick={() => {
                                  remove(name);
                                  // setQuestionsCount(questionsCount - 1);
                                  deleteQuestion(key);
                                }}
                              >
                                Remove Question
                              </div>
                            </Form.Item>
                          )}
                        </div>
                      </Space>
                    );
                  })}
                  <button onClick={() => add() || addQuestion()} type='button' className='btn btn-primary col-md-2 mx-md-2 mb-4'>
                    Add Question
                  </button>
                </>
              )}
            </Form.List>
          </div>
        </div>,
      ]}
    />
  );
};

const AnswerOptions = ({ options, questionIndx, updateOptions, deleteOption }) => {
  const [optionState, setOptionState] = useState(options);
  return (
    <>
      {optionState.map((item, indx) => {
        return <Option optionValue={item} optionState={optionState} optionIndx={indx} setOptionState={setOptionState} questionIndx={questionIndx} updateOptions={updateOptions} deleteOption={deleteOption} />;
      })}
    </>
  );
};

const Option = ({ optionState, setOptionState, optionIndx, optionValue, questionIndx, updateOptions, deleteOption }) => {
  const [input, setInput] = useState(optionValue ? optionValue : '');
  return (
    <div className='col-lg-3 col-md-6 col-12'>
      <div className='row'>
        <InputField
          value={input}
          onChange={(e) => {
            setInput(e.target.value);
            updateOptions(e.target.value, optionIndx, questionIndx);
          }}
          col='col-lg-10 col-md-11 col-12'
          placeholder='Enter Option'
          label={`Option ${optionIndx + 1}`}
          required={true}
          type='text'
          suffix={
            optionState.length > 1 && (
              <MinusCircleOutlined
                style={{
                  fontSize: '1rem',
                  color: 'red',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setOptionState(optionState.filter((item, ind) => ind !== optionIndx));
                  deleteOption(questionIndx, optionIndx);
                }}
              />
            )
          }
        />

        <div className='col-lg-2 col-md-1 p-0 d-flex align-items-center justify-content-md-start justify-content-center m-md-0 mb-3'>
          {optionState.length < 5 && optionState.length === optionIndx + 1 && (
            <PlusCircleOutlined
              style={{
                fontSize: '1rem',
                color: 'blue',
                cursor: 'pointer',
              }}
              onClick={() => {
                setOptionState([...optionState, '']);
                updateOptions(input, optionIndx, questionIndx);
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default RequestButtonAdd;
