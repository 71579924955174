import React, { useState } from 'react';
import RichTextEditor from 'react-rte';

const TestComponent = ({ onChange, title, textvalue }) => {
  console.log(textvalue);
  const [editorValue, setEditorValue] = useState(RichTextEditor.createValueFromString(textvalue || '', 'html'));

  const handleChange = (value) => {
    setEditorValue(value);
    if (onChange) {
      onChange(value.toString('html')); // Update the form field with the HTML string
      console.log('text', value.toString('html'));
    }
  };
      // console.log('text', value.toString('html'));

  const toolbarConfig = {
    display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'LINK_BUTTONS'],
    
    INLINE_STYLE_BUTTONS: [
      { label: 'Bold', style: 'BOLD', className: 'custom-css-class' },
      { label: 'Italic', style: 'ITALIC' },
      { label: 'Underline', style: 'UNDERLINE' },
    ],
    BLOCK_TYPE_DROPDOWN: { display: 'none' },
    BLOCK_TYPE_BUTTONS: [
      { label: 'UL', style: 'unordered-list-item' },
      { label: 'OL', style: 'ordered-list-item' },
    ],
  };

  return (
    <>
      {title && <label htmlFor={name}>{title}</label>}
      <RichTextEditor className='rounded-3' toolbarConfig={toolbarConfig} value={editorValue}   onChange={handleChange} />
    </>
  );
};

export default TestComponent;
