import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
// import { message, Switch, Select, Form, Input, Space, Upload } from 'antd';
import { message, DatePicker, Row, Col, Select, Form, Input, Upload, Space, Button as BTN, Switch, Textarea, Pagination } from 'antd';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { app } from '../../config';
import { CodeSandboxCircleFilled, ConsoleSqlOutlined, LeftCircleOutlined, UploadOutlined } from '@ant-design/icons';
import { LoadingOutlined, MinusCircleOutlined, PlusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import AddPhoto from '../../assets/images/add.png';
const { TextArea } = Input;
import { useForm } from 'antd/lib/form/Form'; // Import the useForm hook
import moment from 'moment';
import { UpdateRecord } from '../../layouts/UpdateRecord';
import { InputField, SelectField, TextAreaField } from '../../layouts/FormFields';
// import getHeaders from '../../utils/authHeaders';

const EditUserFeedback = () => {
  //   const header = getHeaders();
  const navigate = useNavigate();
  const [form] = useForm(); // Initialize the form instance
  let userTestingFeedbackHeaders = ['Date', 'Full Name', 'Screen Name', 'Type', 'Title', 'Description', 'Attachment'];

  const [feedbackAddResponse, setFeedbackAddResponse] = useState({});
  const [feedbackGetResponse, setFeedbackGetResponse] = useState({});
  const [apiUploadDataObj, setApiUploadDataObj] = useState({});
  const [loading, setLoading] = useState(false);
  const [uploadedImg, setUploadedImg] = useState('');
  const [imageuploading, setImageUploading] = useState(false);
  const [tableHeaders, setTableHeaders] = useState(userTestingFeedbackHeaders);
  const [renderList, setRenderList] = useState([]);

  const [showModal, setShowModal] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [pagesCount, setPagesCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [feedbackObj, setFeedbackObj] = useState('');
  const [userImage, setUserImage] = useState(false);

  //     let header = {
  //     'Authorization': 'Bearer ' + authToken
  // }

  let feedbackID = JSON.parse(localStorage.getItem('setFeedbackId'));
  useEffect(() => {
    async function fetchData() {
      const res = await axios.get(`${app.URL}/api/v1/admin/feedback/view/${feedbackID}`);
      setFeedbackObj(res?.data?.result.data);
      if (res?.data?.result.data.attachment) {
        setUploadedImg(res?.data?.result.data.attachment);
        setUserImage(true);
      }
    }
    fetchData();
  }, []);

  function goBack() {
    navigate('/feedback', { replace: true });
  }
  window.addEventListener('popstate', goBack);
  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload G/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 <= 10;
    if (!isLt2M) {
      message.error('Image must smaller or equal to 10MB!');
    }
    return isJpgOrPng && isLt2M;
  };

  const handleuploadChange = async (info) => {
    if (info.file.status === 'uploading') {
      setImageUploading(true);
      // setLoading(true);
      setUserImage(false);

      return;
    }
    if (info.file.status === 'done') {
      info.file ? console.log(info.file.response.result.data.Location) : console.log('Not found');
      info.file ? setUploadedImg(info.file.response.result.data.Location) : '';
      getBase64(info.file.originFileObj, (url) => {
        setImageUploading(false);
        // setLoading(false);
      });
    }
  };

  const handleClear = () => {
    form.resetFields();
    setUploadedImg('');
  };

  const onFormSubmit = (formValues) => {
    setApiUploadDataObj({
      userName: formValues.userName ? formValues.userName : feedbackObj.userName,
      title: formValues.title ? formValues.title : feedbackObj.title,
      screenName: formValues.screenName ? formValues.screenName : feedbackObj.screenName,
      description: formValues.description ? formValues.description : feedbackObj.description,
      devComments: formValues.devComments ? formValues.devComments : feedbackObj.devComments,
      attachment: uploadedImg ? uploadedImg : feedbackObj.attachment,
      feedbackType: formValues.type ? formValues.type : feedbackObj.feedbackType,
      status: formValues.status ? formValues.status : feedbackObj.status,
      attachment: uploadedImg ? uploadedImg : feedbackObj.attachment,
    });

    setShowModal(!showModal);
  };

   const onFinishFailed = (errorInfo) => {
     console.log('Failed:', errorInfo);
   };

  const submitUserTestingFeedback = async () => {
    setLoading(true);
    try {
      const apiResponse = await axios.post(`${app.URL}/api/v1/admin/feedback/update/${feedbackID}`, apiUploadDataObj);
      console.log(apiResponse);
      setLoading(false);
      setRenderList(apiResponse.data.result.data.data);
      setTotalCount(apiResponse.data.result.data.count);
      setShowModal(!showModal);
      message.success(apiResponse.data.result.msg);
      setTimeout(goBack, 1000)
    } catch (error) {
    setLoading(false);

      console.log('Error in getAllUserTestingFeedbacks: ', error);
      setFeedbackGetResponse(error.response.data.result);
    }
  };

  const getAllUserTestingFeedbacks = async (requestObj = {}) => {
    try {
      const apiResponse = await axios.post(`${app.URL}/api/v1/admin/feedback/view`, requestObj);
      console.log(apiResponse);

      setRenderList(apiResponse.data.result.data.feedbacksData);
      setTotalCount(apiResponse.data.result.data.count);
    } catch (error) {
      console.log('Error in getAllUserTestingFeedbacks: ', error);
      setFeedbackGetResponse(error.response.data.result);
    }
  };

  const onChange = async (page, pageSize) => {
    console.log(page, pageSize);
    setPageSize(pageSize);
    setCurrentPage(page);
    if (!page) page = 1;
    let limit = pageSize;
    try {
      let reqObj;

      reqObj = {
        limit: limit,
        offset: limit * (page - 1),
      };

      console.log(reqObj);

      getAllUserTestingFeedbacks(reqObj);
    } catch (e) {
      console.log('Error');
    }
  };

  useEffect(() => {
    let query = {
      limit: pageSize,
      offset: 0,
    };

    getAllUserTestingFeedbacks(query);

    return () => {};
  }, []);

    let statusTypes = ['Open', 'Resolved', 'Closed', 'TBD'];

  let feedbackTypes = ['Bug', 'Feature'];
  const handleClose = () => {
    setShowModal(!showModal);
  };
  return (
    <UpdateRecord
      loading={loading}
      pageTitle='Update Feedback'
      response={feedbackObj}
      handleClose={handleClose}
      submitData={submitUserTestingFeedback}
      switchBtn={false}
      show={showModal}
      updateMessage='Are you sure you want to update feedback?'
      upload={true}
      handleuploadChange={handleuploadChange}
      beforeUpload={beforeUpload}
      action={`${app.URL}/api/v1/general/feedback/imageupload`}
      userImage={userImage}
      imageuploading={imageuploading}
      uploadedImg={uploadedImg}
      initialValues={{
        remember: true,
        ['userName']: feedbackObj?.userName,
        ['title']: feedbackObj?.title,
        ['description']: feedbackObj?.description,
        ['screenName']: feedbackObj?.screenName,
        ['type']: feedbackObj?.feedbackType,
        ['status']: feedbackObj?.status,
        ['devComments']: feedbackObj?.devComments,
        ['isActive']: feedbackObj?.isActive === '1' ? true : false,
      }}
      onFinish={onFormSubmit}
      onFinishFailed={onFinishFailed}
      goBack={goBack}
      addUserResp={feedbackAddResponse}
      inputs={[
        <InputField
          col='col-lg-6'
          rules={[
            {
              required: true,
              message: 'Enter Full Name',
            },
          ]}
          placeholder='Full Name'
          name='userName'
          label='Full Name'
          required={true}
        />,
        <InputField
          rules={[
            {
              required: true,
              message: 'Enter Screen Name',
            },
          ]}
          col='col-lg-6'
          placeholder='Screen Name'
          name='screenName'
          label='Screen Name'
          required={true}
        />,
        <InputField
          rules={[
            {
              required: true,
              message: 'Enter Title',
            },
          ]}
          col='col-lg-6'
          placeholder='Enter Title'
          name='title'
          label='Title'
          required={true}
        />,
      ]}
      selects={[
        <SelectField
          options={feedbackTypes.map((item) => (
            <Select.Option value={item}>{item}</Select.Option>
          ))}
          col='col-lg-6'
          label='Type'
          name='type'
          placeholder='Select Type'
          required={true}
          rules={[
            {
              required: true,
              message: 'Select Type',
            },
          ]}
        />,
        <SelectField
          options={statusTypes.map((item) => (
            <Select.Option value={item}>{item}</Select.Option>
          ))}
          col='col-lg-6'
          label='Status'
          name='status'
          placeholder='Select Status'
          required={true}
          rules={[
            {
              required: true,
              message: 'Select Status',
            },
          ]}
        />,
      ]}
      textareas={[
        <TextAreaField
          rules={[
            {
              required: true,
              message: 'Enter Description',
            },
          ]}
          col='col-lg-12'
          placeholder='Enter Description'
          name='description'
          label='Description'
          required={true}
          rows={3}
        />,
        <TextAreaField
          rules={[
            {
              required: false,
              message: 'Enter Developer Comments',
            },
          ]}
          col='col-lg-12'
          placeholder='Enter Developer Comments'
          name='devComments'
          label='Developer Comments'
          required={false}
          rows={3}
        />,
      ]}
    />
  );

  // return (
  //   <div className='dashboard-card container-fluid pb-5'>
  //     <div className='row p-0 pb-4'>
  //       <div className='for-before col-12 col-md-4 head fs-3 fw-bold' style={{ backgroundColor: '#a7c1d9', borderBottomRightRadius: '10px', color: '#FFFFFF' }}>
  //         Update Feedback
  //       </div>
  //     </div>

  //     {feedbackObj && (
  //       <Form
  //         form={form}
  //         name='basic'
  //         className='row p-5 px-3 pb-1'
  //         autoComplete='off'
  //         onFinish={onFormSubmit}
  //         initialValues={{
  //           remember: true,
  //           ['userName']: feedbackObj?.userName,
  //           ['title']: feedbackObj?.title,
  //           ['description']: feedbackObj?.description,
  //           ['screenName']: feedbackObj?.screenName,
  //           ['type']: feedbackObj?.feedbackType,
  //           ['status']: feedbackObj?.status,
  //           ['isActive']: feedbackObj?.isActive === '1' ? true : false,
  //         }}
  //       >
  //         <div className='col-md-8 col-12 input-column'>
  //           <div className='row'>
  //             <div className='col-lg-6'>
  //               <label>
  //                 Full Name <span className='required-star'>*</span>
  //               </label>
  //               <Form.Item
  //                 // label='fullName'
  //                 name='userName'
  //                 defaultValue='1'
  //                 colon={false}
  //                 rules={[
  //                   {
  //                     required: true,
  //                     message: 'Enter Full Name',
  //                   },
  //                 ]}
  //               >
  //                 <Input placeholder='Full Name' />
  //               </Form.Item>
  //             </div>
  //             <div className='col-lg-6'>
  //               <label>
  //                 Screen Name <span className='required-star'>*</span>
  //               </label>
  //               <Form.Item
  //                 // label='screenName'
  //                 name='screenName'
  //                 defaultValue='1'
  //                 colon={false}
  //                 rules={[
  //                   {
  //                     required: true,
  //                     message: 'Enter Screen Name',
  //                   },
  //                 ]}
  //               >
  //                 <Input placeholder='Screen Name' />
  //               </Form.Item>
  //             </div>
  //             <div className='col-lg-6'>
  //               <label>
  //                 Type <span className='required-star'>*</span>
  //               </label>
  //               <Form.Item
  //                 // label='type'
  //                 name='type'
  //                 colon={false}
  //                 rules={[
  //                   {
  //                     required: true,
  //                     message: 'Select Type',
  //                   },
  //                 ]}
  //               >
  //                 <Select placeholder='Select Type'>
  //                   {feedbackTypes.map((item) => (
  //                     <Select.Option value={item}>{item}</Select.Option>
  //                   ))}
  //                 </Select>
  //               </Form.Item>
  //             </div>
  //             <div className='col-lg-6'>
  //               <label>
  //                 Status <span className='required-star'>*</span>
  //               </label>
  //               <Form.Item
  //                 // label='status'
  //                 name='status'
  //                 colon={false}
  //                 rules={[
  //                   {
  //                     required: true,
  //                     message: 'Select Status',
  //                   },
  //                 ]}
  //               >
  //                 <Select placeholder='Select Status'>
  //                   {statusTypes.map((item) => (
  //                     <Select.Option value={item}>{item}</Select.Option>
  //                   ))}
  //                 </Select>
  //               </Form.Item>
  //             </div>
  //             <div className='col-lg-12'>
  //               <label>
  //                 Title <span className='required-star'>*</span>
  //               </label>
  //               <Form.Item
  //                 colon={false}
  //                 // label='title'
  //                 name='title'
  //                 rules={[
  //                   {
  //                     required: true,
  //                     message: 'Enter Title',
  //                   },
  //                 ]}
  //               >
  //                 <Input placeholder='Title' />
  //               </Form.Item>
  //             </div>
  //             <div className='col-lg-12'>
  //               <label>
  //                 Description <span className='required-star'>*</span>
  //               </label>
  //               <Form.Item
  //                 colon={false}
  //                 // label='description'
  //                 name='description'
  //                 autoComplete='off'
  //                 autofill='off'
  //                 rules={[
  //                   {
  //                     required: true,
  //                     message: 'Enter Description',
  //                   },
  //                 ]}
  //               >
  //                 <TextArea rows={3} placeholder='Enter Description' />
  //               </Form.Item>
  //             </div>
  //             <button
  //               type='primary'
  //               className='btn btn-primary button patient-cancel-btn col-md-4 mr-2 mb-2'
  //               htmlType='submit'
  //               onClick={() => {
  //                 navigate('/feedback');
  //               }}
  //             >
  //               Cancel
  //             </button>
  //             <button type='primary' className='btn btn-primary button col-md-4 mb-2' htmlType='submit'>
  //               Save
  //             </button>
  //           </div>
  //         </div>
  //         <div className='col-md-4 col-12 order-first order-md-last image-column'>
  //           {feedbackObj && (
  //             <Form.Item valuePropName='fileList'>
  //               <Upload listType='picture-card' className='avatar-uploader' showUploadList={false} action={`${app.URL}/api/v1/general/feedback/imageupload`} beforeUpload={beforeUpload} onChange={handleuploadChange}>
  //                 {feedbackObj?.attachment && userImage ? (
  //                   <img className='' src={feedbackObj?.attachment} alt='avatar' />
  //                 ) : uploadedImg && !imageuploading ? (
  //                   <img className='' src={uploadedImg} alt='avatar' />
  //                 ) : !uploadedImg && imageuploading ? (
  //                   <LoadingOutlined style={{ fontSize: '40px' }} />
  //                 ) : (
  //                   <>
  //                     <img className='for-add-img' src={AddPhoto} alt='avatar' />
  //                     <p className='add-text'>Upload Image</p>
  //                   </>
  //                 )}
  //               </Upload>
  //             </Form.Item>
  //           )}
  //         </div>
  //       </Form>
  //     )}

  //     {feedbackAddResponse.code === 200 ? (
  //       <Modal show={showModal}>
  //         <Modal.Header closeButton>
  //           <Modal.Title className='text-center'>Success</Modal.Title>
  //         </Modal.Header>
  //         <Modal.Body>{feedbackAddResponse.msg}</Modal.Body>
  //         <Modal.Footer></Modal.Footer>
  //       </Modal>
  //     ) : feedbackAddResponse.code === 400 ? (
  //       <Modal show={showModal}>
  //         <Modal.Header closeButton>
  //           <Modal.Title className='text-center'>Failed</Modal.Title>
  //         </Modal.Header>
  //         <Modal.Body>{feedbackAddResponse.msg}</Modal.Body>
  //         <Modal.Footer></Modal.Footer>
  //       </Modal>
  //     ) : (
  //       <Modal show={showModal}>
  //         {/* <Modal.Header closeButton>
  //                               <Modal.Title>Confirmation</Modal.Title>
  //                             </Modal.Header> */}
  //         <Modal.Body>Are you sure you want to update feedback?</Modal.Body>
  //         <Modal.Footer>
  //           <Button
  //             variant='secondary'
  //             onClick={() => {
  //               setShowModal(!showModal);
  //             }}
  //           >
  //             Cancel
  //           </Button>
  //           <Button
  //             variant='primary'
  //             htmlType='submit'
  //             onClick={() => {
  //               submitUserTestingFeedback();
  //             }}
  //           >
  //             Confirm
  //           </Button>
  //         </Modal.Footer>
  //       </Modal>
  //     )}
  //   </div>
  // );
};

export default EditUserFeedback;
