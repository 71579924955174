import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { app } from '../../config';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Pagination, Input, Form, Select, Row, Col, DatePicker, message } from 'antd';
import { useTranslation } from 'react-i18next';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
// import { EditTwoTone, BranchesOutlined, CodeSandboxCircleFilled } from '@ant-design/icons';
import { CaretRightOutlined, CaretDownOutlined, LoadingOutlined, CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import getHeaders from '../../utils/authHeaders';
import ListinngCard from '../../layouts/ListingCard';
import { dateConverter } from '../../utils/utcDateConversionCommon';
import AddNewImg from '../../assets/images/add-new.png';
import editBtnImg from '../../assets/images/edit.png';
import lockIcon from '../../assets/images/lock.png';

import moment from 'moment';
import ShipmentOrderVerificationModal from '../WareHouse/ShipmentOrderVerificationModal';
import LoaderImage from '../LoaderImage';
import NoDataFound from '../../layouts/NoDataFound';

const orderData = {
  ecomOrderId: 276,
  ecomOrderNo: 'EOR-230000131',
  createdAt: '10-23-2023',
  patientId: 305,
  patientName: 'Saad Baig',
  productTotalAmount: '268',
  shipTotalAmount: '0',
  address: [
    {
      addressId: 49,
      name: null,
      title: 'My home',
      address: 'Street Road 2',
      contactPerson: 'ghufran@gmail.com',
      mobile: '+1 337-337-1633',
      stateId: 4,
      state: 'Alaska',
      city: 'New York',
      zipCode: '85254',
      isDefault: 1,
      requestId: 77,
      requestType: 'Cancel',
      requestStatus: 'Pending',
      shipmentPackageId: 1,
      shipmePackageName: 'Over Night',
      shipmentCharges: '40.00',
      estimatedDeliveryHours: '24',
      deliveryHours: null,
      isRecivedBack: null,
      orderStatus: 2,
      status: 'In Progress',
      products: [
        {
          productId: 73,
          productName: 'Blood Strong',
          patientId: 305,
          eCommOrderId: 276,
          quantity: '1',
          unitPrice: '89.00',
        },
        {
          productId: 43,
          productName: 'Broken Hearts',
          patientId: 305,
          eCommOrderId: 276,
          quantity: '1',
          unitPrice: '45.00',
        },
      ],
      totalShipmentAmount: 134,
      taxAmount: 2.68,
      totalAmountwithShipment: 174,
    },
    {
      addressId: 51,
      name: null,
      title: 'test',
      address: 'teststreet',
      contactPerson: 'test@test.com',
      mobile: '+1 805-555-7777',
      stateId: 7,
      state: 'Arkansas',
      city: 'test',
      zipCode: '85254',
      isDefault: 0,
      requestId: null,
      requestType: null,
      requestStatus: null,
      shipmentPackageId: 2,
      shipmePackageName: 'Standard Delivery',
      shipmentCharges: '0.00',
      estimatedDeliveryHours: '48',
      deliveryHours: null,
      isRecivedBack: null,
      orderStatus: 2,
      status: 'In Progress',
      products: [
        {
          productId: 73,
          productName: 'Blood Strong',
          patientId: 305,
          eCommOrderId: 276,
          quantity: '1',
          unitPrice: '89.00',
        },
        {
          productId: 43,
          productName: 'Broken Hearts',
          patientId: 305,
          eCommOrderId: 276,
          quantity: '1',
          unitPrice: '45.00',
        },
      ],
      totalShipmentAmount: 134,
      taxAmount: 2.68,
      totalAmountwithShipment: 134,
    },
  ],
  totalShipmentAmount: 268,
  totalShipmentCharges: 40,
  totalAmountWithShipment: 308,
};

// const subOrderData = {
//     addressId: 51,
//     name: null,
//     title: "test",
//     address: "teststreet",
//     contactPerson: "test@test.com",
//     mobile: "+1 805-555-7777",
//     stateId: 7,
//     state: "Arkansas",
//     city: "test",
//     zipCode: "85254",
//     isDefault: 0,
//     requestId: null,
//     requestType: null,
//     requestStatus: null,
//     shipmentPackageId: 2,
//     shipmePackageName: "Standard Delivery",
//     shipmentCharges: "0.00",
//     estimatedDeliveryHours: "48",
//     deliveryHours: null,
//     isRecivedBack: null,
//     orderStatus: 2,
//     status: "In Progress",
//     products: [
//         {
//             productId: 73,
//             productName: "Blood Strong",
//             patientId: 305,
//             eCommOrderId: 276,
//             quantity: "1",
//             unitPrice: "89.00"
//         },
//         {
//             productId: 43,
//             productName: "Broken Hearts",
//             patientId: 305,
//             eCommOrderId: 276,
//             quantity: "1",
//             unitPrice: "45.00"
//         }
//     ],
//     totalShipmentAmount: 134,
//     taxAmount: 2.68,
//     totalAmountwithShipment: 134
// };

const ShipmentOrderView = () => {
  const inputRef = useRef();
  const [editbtn, setEditBtn] = useState('none');
  const [pageSize, setPageSize] = useState(10);
  const [fetchPartners, setFetchPartners] = useState([]);
  const [updbatchId, setUpdBatchId] = useState('');
  const [delBatchId, setDelBatchId] = useState('');
  const [pagesCount, setPagesCount] = useState(0);
  const [searchShow, setSearchShow] = useState({});
  const [renderList, setRenderList] = useState();
  const [tableShow, setTableShow] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchItems, setSearchItems] = useState([]);
  const [err, setErr] = useState('');
  const [tableHeaders, setTableHeaders] = useState([]);
  const [show, setShow] = useState(false);
  const [lockModalShow, setLockModalShow] = useState(false);
  const [delBatchResp, setDelBatchResp] = useState({});
  const [lockBatchResp, setLockBatchResp] = useState({});
  const [batchListing, setBatchListing] = useState({});
  const [pageRefresh, setPageRefresh] = useState(false);
  const [subOrderData, setSubOrderData] = useState({});
  const [selectedProduct, setSelectedProduct] = useState({});
  const [loader, setLoader] = useState(false);

  // let socket = JSON.parse(localStorage.getItem('socket'));

  const [isButtonShow, setIsButtonShow] = useState(false);

  const [showVerificationModal, setShowVerificationModal] = useState(false);

  const handleClose = () => setShow(false);
  const handleLockClose = () => setLockModalShow(false);
  let navigate = useNavigate();
  let convertedObj;

  // const [genericBoxSerial, setGenericBoxSerial] = useState("");
  // const [productSerial, setProductSerial] = useState("");

  let productVerifcationsArr = [];

  const checkAllProductVerifications = () => {
    console.log('subOrderData: ', subOrderData);
    subOrderData?.products?.forEach((product, index) => {
      productVerifcationsArr.push({
        productId: product.productId,
        boxVerified: product.boxVerified,
        sleeveVerified: product.sleeveVerified,
      });
    });
    console.log('productVerifcationsArr: ', productVerifcationsArr);
  };

  const verifyGenericBoxSerial = async (payload) => {
    try {
      const apiResponse = await axios.post(`${app.URL}/api/v1/admin/ecomorders/checkgenbox`, payload, {
        headers: getHeaders(),
      });

      console.log('verifyGenericBoxSerial apiResponse: ', apiResponse.data);

      await inventoryListingApi(null);

      if (apiResponse.data.result.code === 200) {
        message.success(apiResponse.data.result.msg);
      }
    } catch (error) {
      console.log('verifyGenericBoxSerial: ', error);
      message.error(error.response.data.message);
    }
  };

  const verifySleeveSerial = async (payload) => {
    try {
      const apiResponse = await axios.post(`${app.URL}/api/v1/admin/ecomorders/checksleeve`, payload, {
        headers: getHeaders(),
      });

      console.log('verifySleeveSerial apiResponse: ', apiResponse.data);

      await inventoryListingApi(null);

      if (apiResponse.data.result.code === 200) {
        message.success(apiResponse.data.result.msg);
      }
    } catch (error) {
      console.log('verifySleeveSerial: ', error);
      message.error(error.response.data.message);
    }
  };

  const onConfirmProductSerialTrigger = async (data) => {
    console.log('onConfirmProductSerialTrigger product data: ', data);

    setSelectedProduct(data);

    setShowVerificationModal(true);
  };

  const onConfirmGenericBoxSerialTrigger = async (data) => {
    console.log('onConfirmGenericBoxSerialTrigger product data: ', data);
    // console.log("onConfirmProductSerialTrigger generic box serial value: ", genericBoxSerial);

    setSelectedProduct(data);

    onShowVerificationModal();
    // inputRef.current.focus();

    // let verifyGenericUploadObj = {
    //     ItemCartShipId: data.ItemCartShipId,
    //     boxType: data.boxType,
    //     genBoxSerial: data.genBoxSerial,
    // }
    // console.log("onConfirmGenericBoxSerialTrigger verifyGenericUploadObj: ", verifyGenericUploadObj);

    // await verifyGenericBoxSerial(verifyGenericUploadObj);
  };

  const user = JSON.parse(localStorage.getItem('user'));
  const localOrder = JSON.parse(localStorage.getItem('order'));

  const inventoryListingApi = async (formData) => {
    console.log(formData);
    const partnerResp = await axios.get(`${app.URL}/api/v1/admin/ecomorders/warehouseorder/${localOrder?.ecomOrderId}/${localOrder?.addressId}`, { headers: getHeaders() });
    setSubOrderData(partnerResp.data.result.data);

    checkAllProductVerifications();

    // partnerResp ? console.log(partnerResp.data.result.data) : "";
    // partnerResp ? convertedObj = dateConverter(partnerResp.data.result.data.data, "stockInDate") : " ";
    // partnerResp.data.result ? setFetchPartners(convertedObj) : ' ';
    // partnerResp.data.result ? setPagesCount(partnerResp.data.result.data.count) : ' ';
    // partnerResp.data.result ? setRenderList(convertedObj) : ' ';
  };
  let arr = [];
  Object.keys(subOrderData).length > 0
    ? subOrderData.products.forEach((ele) => {
        arr.push(ele.sleeveVerified);
        arr.push(ele.boxVerified);
      })
    : console.log('no Data');
  let checkedArr = arr.every((ele) => ele);

  const getData = async () => {
    setLoader(true);
    try {
      const partnerResp = await axios.get(`${app.URL}/api/v1/admin/ecomorders/warehouseorder/${localOrder?.ecomOrderId}/${localOrder?.addressId}`, { headers: getHeaders() });
      console.log(partnerResp);
      setSubOrderData(partnerResp.data.result.data);
      partnerResp ? console.log(partnerResp.data.result.data) : ' ';
      partnerResp.data.result ? (convertedObj = await dateConverter(partnerResp.data.result.data.data, 'stockInDate')) : ' ';
      partnerResp.data.result ? setFetchPartners(convertedObj) : ' ';
      partnerResp.data.result ? setRenderList(convertedObj) : ' ';
      setPageRefresh(false);
      setLoader(false);
    } catch (err) {
      console.log(err);
      setLoader(false);
      message.warning(err.response.data.message);
    }
  };
  console.log('check is button', isButtonShow);
  useEffect(() => {
    getData();
    checkAllProductVerifications();
  }, [pageRefresh]);

  const onFinish = async (values) => {
    console.log(values);
    // if (values.searchFor == 'batchno') {
    //   values.searchFor = 'batchNo';
    // }

    setSearchShow(values);
    try {
      let searchData = {
        limit: 10,
        offset: 0,
        searchBy: values.searchFor,
        searchTxt: values.searchItem,
      };
      if (!searchData.searchBy) message.error('Select search criteria ');
      if (searchData.searchBy) await inventoryListingApi(searchData);
    } catch (e) {
      console.log('Error');
      if (e.message) setErr(e.message);
      setTableShow(false);
    }
  };
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  const sendToPrinting = async () => {
    let data = {
      patientId: subOrderData.patientId,
      addressId: subOrderData.addressId,
      ecomOrderId: subOrderData.ecomOrderId,
      comment: 'Shipment has been sent for label printing',
    };
    console.log(data);
    // return;

    try {
      let resp = await axios.post(`${app.URL}/api/v1/admin/ecomorders/printlabel`, data, { headers: getHeaders() });
      console.log(resp);
      message.success('Sent for printing Successfully');
      resp.data && resp.data.result.code == 200 ? navigate('/ecom/shipment/prepare', { replace: true }) : console.log('Some error');
      // setStockOutStatus(true)
      //setDispatchShow(true);
    } catch (error) {
      message.error('error in stock prepare for shipment: ', error);
    }
  };

  const onShowVerificationModal = () => {
    setShowVerificationModal(true);
  };

  // return <div>HEllo</div>
  console.log(subOrderData);

  const assignUserToOrder = async (formData) => {
    if (formData.ItemCartShipId) {
      try {
        let resp = await axios.post(`${app.URL}/api/v1/admin/ecomorders/assignuser`, formData, { headers: getHeaders() });
        console.log('Assign User to Shipment', resp);
        // const messageToSend = { name: 'Shipment Process Start', process: 1 };
        // socket.send(JSON.stringify(messageToSend));
      } catch (error) {
        console.log('Error: ', error);
      }
    }
  };

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    const userId = user.result.data.user.userId;
    const ItemCartShipId = subOrderData?.products?.map((item) => item.ItemCartShipId);

    setTimeout(() => {
      const formData = {
        userId,
        ItemCartShipId,
      };
      console.log(formData);
      assignUserToOrder(formData);
    }, 500);

    return () => {
      const formData = {
        userId: null,
        ItemCartShipId,
      };
      assignUserToOrder(formData);
    };
  }, [subOrderData]);

  return (
    <div className='dashboard-card container-fluid'>
      <div className='row p-0 pb-4'>
        <div className='for-before col-12 col-md-4 head fs-3 fw-bold' style={{ backgroundColor: '#a7c1d9', borderBottomRightRadius: '10px', color: '#FFFFFF' }}>
          Shipment Order
        </div>
      </div>

      {loader && <LoaderImage />}
      {Object.keys(subOrderData).length > 1 ? (
        <div className='row p-5'>
          <div className='col-8 col-lg-6 fs-5 mt-0 mb-0 pt-0 pb-0 pl-3'>
            {`${subOrderData.ecomOrderNo}-${subOrderData.addressId}`}
            <br />
            {subOrderData.title}
            <br />
            {`${subOrderData.address}, ${subOrderData.state}, ${subOrderData.city}, ${subOrderData.zipCode}`}
          </div>
          <div className='col-4 col-lg-6 mt-0 mb-0 pt-1 pb-0 pl-3 text-right'>
            {moment(subOrderData.createdAt).format('MM-DD-YYYY')}
            <br />
            {subOrderData.status}
            <br />
            {subOrderData.shipmePackageName}
            <br />
            {subOrderData.requestId && (
              <>
                Request Type: {subOrderData.requestType}
                <br />
                Request Status: {subOrderData.requestStatus}
              </>
            )}
          </div>

          <div className='col-12 my-3'>
            <table id='customers' style={{ width: '100%' }}>
              <thead>
                <tr
                  style={{
                    fontWeight: 'bold',
                  }}
                >
                  <th>Product Code</th>
                  <th>Product Name </th>
                  <th>Box Type</th>
                  <th>Verification Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {subOrderData?.products?.map((product, index) => {
                  return (
                    <>
                      <tr>
                        <td style={{ width: '100px' }}>{product.productCode}</td>
                        <td style={{ width: '100px' }}>{product.productName}</td>
                        <td style={{ width: '100px' }}>{product.boxType}</td>

                        {console.log('condtion check 2222222: ', product?.sleeveVerified === 1 && product?.boxVerified === 1)}
                        {product?.sleeveVerified === 1 ? (
                          <>
                            <td
                              style={{
                                width: '100px',
                              }}
                            >
                              <CheckCircleOutlined
                                style={{
                                  color: 'green',
                                  // background:"lightgray",
                                  fontSize: '30px',
                                  marginRight: '10px',
                                }}
                              />
                            </td>
                            <td
                              style={{
                                width: '100px',
                              }}
                            >
                              <Button
                                onClick={() => {
                                  console.log('fhfjhsfjhsdfkhsdfkh');
                                  // onShowVerificationModal();
                                  onConfirmGenericBoxSerialTrigger(product);
                                }}
                              >
                                Reverify
                              </Button>
                            </td>
                          </>
                        ) : (
                          <>
                            <td
                              style={{
                                width: '100px',
                              }}
                            ></td>

                            <td
                              style={{
                                width: '100px',
                              }}
                            >
                              <Button
                                onClick={() => {
                                  console.log('fhfjhsfjhsdfkhsdfkh');
                                  // onShowVerificationModal();
                                  onConfirmGenericBoxSerialTrigger(product);
                                }}
                              >
                                Verify
                              </Button>
                            </td>
                          </>
                        )}
                      </tr>
                    </>
                  );
                })}
              </tbody>
            </table>
          </div>

          <div className='col-lg-12  mt-0 mb-0 pt-0 pb-0 pl-3 text-right'>
            Total Amount: ${subOrderData.totalProductAmount?.toFixed(2)}
            <br />
            Total Tax: ${subOrderData?.taxAmount?.toFixed(2)}
            <br />
            Shipment Amount: {Number(subOrderData.shipmentCharges) <= 0 ? 'Free' : `$${Number(subOrderData.shipmentCharges)?.toFixed(2)}`}
            <br />
            Grand Total: ${subOrderData?.grandTotal?.toFixed(2)}
          </div>

          {checkedArr && (
            <div className='col-lg-12 text-right mt-4'>
              <button className='btn btn-primary' onClick={sendToPrinting}>
                Proceed
              </button>
            </div>
          )}
        </div>
      ) : (
        <>{!loader && <NoDataFound />}</>
      )}

      <ShipmentOrderVerificationModal setSubOrderData={setSubOrderData} isVisible={showVerificationModal} setIsVisible={setShowVerificationModal} productData={selectedProduct} setPageRefresh={setPageRefresh} pageRefresh={pageRefresh} />
    </div>
  );
};

export default ShipmentOrderView;
