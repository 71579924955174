import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { app } from '../../config';
import { useNavigate } from 'react-router-dom';
import { Pagination, Input, Form, Select, Row, Col, DatePicker, Button, message, Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import Modal from 'react-bootstrap/Modal';
import { EditTwoTone, BranchesOutlined } from '@ant-design/icons';
import getHeaders from '../../utils/authHeaders';
import ListinngCard from '../../layouts/ListingCard';
import AddNewImg from '../../assets/images/add-new.png';
import { dateConverter } from '../../utils/utcDateConversionCommon';
import { SearchBar } from '../../layouts/SearchBar';
import NoDataFound from '../../layouts/NoDataFound';
import LoaderImage from '../LoaderImage';
import { TweenOneGroup } from 'rc-tween-one';
import { InputField } from '../../layouts/FormFields';
import { CSVLink, CSVDownload } from "react-csv";

import Excel from '../../assets/images/excel.png'
import { genericBoxArray, sortLabelArray } from '../inventory/inventoryHelpers';
function GenericBoxBatchListing() {
  const { t } = useTranslation(['common']);
  let navigate = useNavigate();
  const [editbtn, setEditBtn] = useState('none');
  const [pageSize, setPageSize] = useState(10);
  const [fetchUsers, setFetchPartners] = useState([]);
  const [pagesCount, setPagesCount] = useState(0);
  const [searchShow, setSearchShow] = useState({});
  const [renderList, setRenderList] = useState([]);
  const [tableShow, setTableShow] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [loader, setLoader] = useState(false);
  // const [searchItems, setSearchItems] = useState([]);
  const [err, setErr] = useState('');
  const [show, setShow] = useState(false);
  const [tableHeaders, setTableHeaders] = useState([]);
  const batchId = JSON.parse(localStorage.getItem('batchId'));
  // useEffect(()=>{window.location.reload();},[window.onload])
  const handleClose = () => setShow(false);
  let convertedObj;
  const inventoryListingApi = async (formData) => {
    // console.log(formData);
    setLoader(true);
    const partnerResp = await axios.post(`${app.URL}/api/v1/admin/genericbox/batchreport`, formData, { headers: getHeaders() });
    // console.log(partnerResp.data.result.data.listing);
    // return;
    
    partnerResp ? (convertedObj = dateConverter(partnerResp.data.result.data.listing, 'issueDate')) : ' ';
    partnerResp.data.result ? setFetchPartners(convertedObj) : ' ';
    partnerResp.data.result ? setPagesCount(partnerResp.data.result.data.count) : ' ';
    partnerResp.data.result ? setRenderList(convertedObj) : ' ';
    console.log(convertedObj)
    partnerResp.data.result && setLoader(false);
  };
  const userList = async () => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user.userType === 'A') setEditBtn('block');
    let formData = {
      batchId: batchId,
      // limit: pageSize,
      limit: "",
      offset: 0,
      searchBy: '',
      searchTxt: '',
    };
    await inventoryListingApi(formData);
  };
  useEffect(() => {
    userList();
    let header = ['Batch No.', 'Title', 'Date', 'Serial No.'];
    setTableHeaders(header);

    // setSearchItems(searchFilters);
  }, []);

  let searchItems = [
    {
      name: 'Serial No.',
      value: 'serialNo',
    },
  ];
  const onChange = async (page, pageSize) => {
    console.log(page, pageSize);
    setPageSize(pageSize);
    setCurrentPage(page);
    if (!page) page = 1;
    let limit = pageSize;

    try {
      let reqObj;
      if (Object.keys(searchShow).length !== 0) {
        reqObj = {
          limit: limit,
          offset: limit * (page - 1),
          batchId: batchId,
          searchBy: searchShow.searchFor,
          searchTxt: searchShow.searchItem,
        };
      } else {
        reqObj = {
          limit: limit,
          batchId: batchId,
          offset: limit * (page - 1),
          searchBy: 'serialNo',
          searchTxt: '',
        };
      }

      await inventoryListingApi(reqObj);
    } catch (e) {
      console.log('Error');
      if (e.message) setErr(e.message);
      setTableShow(false);
    }
  };

  const onSearchFinish = async (values) => {
    console.log(values);
    setSearchShow(values);
    try {
      let searchData = {
        limit: 10,
        offset: 0,
        batchId: batchId,
        searchBy: values.searchBy,
        searchTxt: values.searchTxt,
      };
      if (!searchData.searchTxt) message.error('Please enter search text');
      else await inventoryListingApi(searchData);
    } catch (e) {
      console.log('Error');
      if (e.message) setErr(e.message);
      setTableShow(false);
    }
  };
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const handleOnChange = (e) => {
    if (e.target.value.length === 0) {
      userList();
      setSearchShow({});
    }
  };

  function goBack() {
    navigate('/genericbox/listing');
  }

  function copyToClipboard(value, bind) {
    console.log(navigator);
    if (!bind) {
      // Copy the text inside the text field
      navigator.clipboard.writeText(value);

      // Provide feedback to the user (you can customize this part)
      message.success(`Serial No has been copied to the clipboard: ${value}`);
    } else {
      message.error(`Serial No: ${value} already used!`);
    }
  }
  const forMap = (tag, bind) => {
    const tagElem = (
      <Tag
        style={{
          borderRadius: '5px',
          // backgroundColor: '#f2b544',
          boxShadow: '1px 1px 1px #ccc',
          cursor: bind ? 'not-allowed' : 'pointer',
        }}
      
        id='serial'
        // value={tag}
        aria-disabled={true}
        onClick={() => copyToClipboard(tag, bind)}
        className={`border-none d-flex align-items-center ${bind ? 'text-secondary bg-light' : 'text-dark bg-light'} fs-6 p-2 mb-2`}
      >
        {tag}
      </Tag>
    );
    return (
      <span
        key={tag}
        style={{
          display: 'inline-block',
        }}
      >
        {tagElem}
      </span>
    );
  };

  const tagChild = renderList?.map((item) => forMap(item.serialNo, item.isBind));
  let csvData;
  console.log("RenderList",renderList)
  renderList.length>0 ? csvData= genericBoxArray(renderList) : '';
  return (
    <div className='dashboard-card container-fluid pb-5'>
      <div className='row p-0 pb-4'>
        <div className='for-before col-12 col-md-7 head fs-3 fw-bold' style={{ backgroundColor: '#a7c1d9', borderBottomRightRadius: '10px', color: '#FFFFFF' }}>
          Generic Box Batch List
        </div>
      </div>
      <SearchBar onFinish={onSearchFinish} onFinishFailed={onFinishFailed} searchItems={searchItems} onChange={handleOnChange} />

      <div className='row mr-0 px-2'>
        {loader && <LoaderImage />}
        {renderList.length > 0 && !loader ? (
          <>
            {renderList.map((item) => (
              <Form
                initialValues={{
                  remember: true,
                  ['batchNo']: item?.batchId,
                  ['type']: item?.type,
                  ['issueDate']: item?.issueDate,
                  ['quantity']: item?.serialNo?.length,
                }}
                autoComplete='off'
                className='row p-2 px-3 pb-1 mb-3'
              >
                <InputField col='col-md-2' label='Batch No.' name='batchNo' disabled={true} />
                <InputField col='col-md-2' label='Creation Date' name='issueDate' disabled={true} />
                <InputField col='col-md-2' label='Box Type' name='type' disabled={true} />
                <InputField col='col-md-2' label='Box Quantity' name='quantity' disabled={true} />
                <CSVLink data={csvData}>
                  <img src={Excel} style={{ width: '30px', height: '30px' }} />
      
      </CSVLink>
                <div className='col-lg-12'>
                  <label>Serial No.</label>
                  <TweenOneGroup
                    enter={{
                      scale: 0.8,
                      opacity: 0,
                      type: 'from',
                      duration: 100,
                    }}
                    onEnd={(e) => {
                      if (e.type === 'appear' || e.type === 'enter') {
                        e.target.style = 'display: inline-block';
                      }
                    }}
                    leave={{
                      opacity: 0,
                      width: 0,
                      scale: 0,
                      duration: 200,
                    }}
                    appear={false}
                  >
                    {item?.serialNo?.map((item) => forMap(item.serialNo, item.isBind))}
                  </TweenOneGroup>
                </div>
              </Form>
            ))}
          </>
        ) : (
          <>{!loader && <NoDataFound />}</>
        )}
      </div>

      {/* {renderList.length > 0 && <div className='row'>{currentPage && pagesCount ? <Pagination style={{ marginTop: '10px' }} onChange={onChange} defaultCurrent={currentPage} total={pagesCount} showTotal={(total) => `Total ${total} items`} showSizeChanger /> : ' '}</div>} */}
      <div className='row pl-4'>
        <button onClick={goBack} type='primary' className='col-md-2  btn btn-primary button patient-cancel-btn mb-2'>
          Back
        </button>
      </div>
    </div>
  );
}

export default GenericBoxBatchListing;
