import axios from 'axios';
import { useEffect, useState } from 'react';
import {app} from '../config'
import getHeaders from '../utils/authHeaders';
const getPartnerById = (id, setZipCode, setUserImage, setUploadedImg) => {
  let reqid = id;
  const [loading, setLoading] = useState(true);
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  useEffect(() => {
    const getPartner = async (id) => {
      try {
        const res = await axios.get(`${app.URL}/api/v1/admin/partner/view/${id}`, { headers: getHeaders() });
        setResponse(res.data.result);
        setLoading(false);
        if (res.data.result.data.partner.logoUrl) {
          setUserImage(true);
          setUploadedImg(res.data.result.data.partner.logoUrl);
        }
        setZipCode(res.data.result.data.partner.zipCode);
      } catch (err) {
        setError(err);
        setLoading(false);
      }
    };
    getPartner(reqid);
  }, []);

  return { loading, response, error };
};
export default getPartnerById;