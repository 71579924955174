import React, { useState } from 'react'
import getEcomOrder from '../../hooks/getEcomOrder';
import { dateConverter } from '../../utils/utcDateConversionCommon';
import moment from 'moment';
import { useEffect } from 'react';
import axios from 'axios';
import { app } from '../../config';
import getHeaders from '../../utils/authHeaders';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const ViewSingleEcomOrder = async () => {
    const [order, setOrder] = useState({})
    const [dispatchData, setDispatchData] = useState()
    const [cancelData, setCancelData] = useState({})
    // const [deliverData, setDeliverData] = useState({})
    // const [deliverShow, setDeliverShow] = useState(false)
    const [show, setShow] = useState(false);
    const [cancelShow, setCancelShow] = useState(false);
    const [stockOutResp, setStockOutResp] = useState()
    const [orderCancelResp, setOrderCancelResp] = useState();
    const [cancelStatus, setCancelStatus] = useState(false)
    const [stockOutStatus, setStockOutStatus] = useState(false);
    // let order;
    let orderId = localStorage.getItem("orderId");
    console.log("Hereeeee00")

    useEffect(() => {
        // Call the function within useEffect
        async function getOrder(orderId) {
            try{
console.log("Order Id: " + orderId)
            let res = await axios.get(`${app.URL}/api/v1/admin/ecomorders/view/${orderId}`, { headers: getHeaders() });
            console.log("resp");
            console.log(res)
            let convertedObj;
            // let respObj = res&&res.data ? res.data.result.data : {};
            // console.log(respObj)
            // respObj ? respObj.orderDate = moment(res.data.result.data.orderDate).format('DD-MMM-YYYY') : respObj.orderDate;
            // console.log(respObj);
            //  respObj ? setOrder(respObj) : {};
            }
            catch(e){
                console.log("error: ")
                console.log(e)
            }
            
        }
        getOrder(orderId);

        // You can also perform other side effects or cleanup here

        return () => {
            // Cleanup code (if needed)
        };
    }, [])
    console.log(order);
    // Stock Out Function
    const stockOut = async (data) => {
        try {
            let resp = await axios.post(`${app.URL}/api/v1/admin/stockout/create`, data, { headers: getHeaders() });
            console.log("resp");
            setStockOutResp(resp.data.result);
            setStockOutStatus(true)
            setTimeout(() => {
                setShow(false);
            }, [3000])
        }
        catch (e) {
            throw e
        }
    }
    const cancelShipment = async (data) => {
        try {
            let resp = await axios.post(`${app.URL}/api/v1/admin/userrequest/cancelshipment`, data, { headers: getHeaders() });
            console.log("resp");
            console.log(resp)
            setOrderCancelResp(resp.data.result);
            setOrderCancelResp([]);
            setCancelStatus(true)
            setTimeout(() => {
                setCancelShow(false);
            }, [3000])
        }
        catch (e) {
            throw e
        }
    }
    const handleClose = () => setShow(false);
    const handleCancelModalClose = () => setCancelShow(false);
    // const handleDeliverModalClose = () => setDeliverShow(false);

    // const deliverShipment = async () => {
    //     try {
    //         let resp = await axios.post(`${app.URL}/api/v1/admin/userrequest/cancelshipment`, data, { headers: getHeaders() });
    //         console.log("resp");
    //         setOrderCancelResp(resp.data.result);
    //         setCancelStatus(true)
    //         setTimeout(() => {
    //             setCancelShow(false);
    //         }, [3000])
    //     }
    //     catch (e) {
    //         throw e
    //     }
    // }

    const onDeliverTrigger = async (data) => {
        console.log(data);
        // let cancelObj = {
        //     requestId: data.requestId,
        //     patientId: data.product[0].patientId,
        //     ecomOrderId: data.product[0].ecomOrderId,
        //     addressId: data.addressId
        // }
        // setDeliverShow(true);
        // setDeliverData(cancelObj);

    }
    // const onDeliverClick = async () => {
    //     console.log(deliverData);

    // }

    const onDispatchTrigger = (data) => {
        setShow(true);
        setDispatchData(data);
    }
    const onDispatchClick = async () => {
        // console.log("data")
        // console.log(data)
        console.log(dispatchData);
        let stockOutArr = [];
        dispatchData.product.forEach((ele) => {
            stockOutArr.push({
                "productId": ele.productId,
                "quantity": ele.quantity,
                "unitPrice": ele.unitPrice,
                "patientId": ele.patientId,
                "ecomOrderId": ele.ecomOrderId,
                "addressId": dispatchData.addressId,
            })
        })
        await stockOut(stockOutArr)
        console.log("stock out resp");
        console.log(stockOutResp)


    }
    const onCancelTrigger = (data) => {
        console.log(data);
        let cancelObj = {
            requestId: data.requestId,
            patientId: data.product[0].patientId,
            ecomOrderId: data.product[0].ecomOrderId,
            addressId: data.addressId
        }

        console.log(cancelObj);
        setCancelShow(true);
        setCancelData(cancelObj);
    }
    const onCancelClick = async () => {

        console.log(cancelData);
        await cancelShipment(cancelData)
        console.log("stock out resp");
        console.log(stockOutResp)


    }

    return (
        <>
            {Object.keys(order).length>0 ? <div className='for-pseudo ml-3 col-lg-12 col-md-12 col-sm-12' style={{ backgroundColor: "#fff", borderRadius: "10px", marginBottom: "20px" }}>
                <div className='row' style={{ paddingRight: "10px" }}>
                    <div className="for-before col-lg-4 head fs-3 fw-bold" style={{ backgroundColor: "#a7c1d9", borderBottomRightRadius: "10px", color: "#FFFFFF" }}>
                        Ecommerce Order Detail
                    </div>
                </div>
                <div className='p-5' >
                    <div className='row p-3'>
                        <div class="col-lg-6 fw-bold">
                            Order No: {order.ecomOrderNo}
                        </div>
                        <div class="col-lg-6 text-right">
                            Order Date: {order.orderDate}
                        </div>
                        <div class="row mb-4 mt-3">
                            <div class="col-lg-6">
                                Status <span style={{ color: "white", border: "1px green", backgroundColor: "#04AA6D", borderRadius: "15px", padding: "10px" }}>{order.ecomOrderStatus}</span>
                            </div>
                        </div>
                        {order.address.map((ele) => {
                            { ele.dispatchDate ? ele.dispatchDate = moment(ele.dispatchDate).format('DD-MMM-YYYY') : ele.dispatchDate }
                            return (
                                <div className='row thing mt-2 mb-2 p-4'>
                                    <div className='row mb-4'>
                                        <div className='col-lg-6'>{ele.requestId ?
                                            <div className='row'>
                                                <div className='col-lg-12'>
                                                    Request Type: <span style={{ textTransform: "capitalize" }}>{ele.requestType}</span>
                                                </div>
                                                <div className='col-lg-12'>
                                                    Request Status: <span style={{ textTransform: "capitalize" }}>{ele.requestStatus}</span>
                                                </div>
                                            </div> : " "} </div>
                                        <div className='col-lg-6 text-right p-0'>
                                            {ele.subOrderStatus == "In Progress" && !ele.requestId ? <button className='button btn btn-primary mr-3' onClick={() => { onDispatchTrigger(ele) }}>Dispatch</button> : " "}
                                            {ele.subOrderStatus == "In Progress" ? <button className='button btn btn-primary' onClick={() => { onCancelTrigger(ele) }}>Cancel</button> : " "}
                                            {ele.subOrderStatus == "Cancelled" && ele.requestStatus == "Approved" ? <button className='button btn btn-primary' onClick={() => { console.log("Refund") }}>Refund</button> : " "}
                                            {ele.subOrderStatus == "In Transit" ? <span>Dispatch Date: {ele.dispatchDate}</span> : " "}
                                            {ele.subOrderStatus == "In Transit" ? <div><button className='button btn btn-primary' onClick={() => {onDeliverTrigger(ele)}}>Deliver</button></div> : " "}
                                        </div>
                                        <div className='col-lg-6 text-right p-0'>
                                            {/* {ele.subOrderStatus == "In Transit" ?<button className='button btn btn-primary'>Deliver</button> : " "} */}
                                        </div>

                                    </div>
                                    <div className='col-lg-6 pl-9 fw-bold fs-5' style={{ color: "#6a6a6a" }}>{ele.addressTitle}</div>
                                    <div className='col-lg-6 text-right pr-5'>
                                        Sub Order Status: <span style={{ color: "white", border: "1px green", backgroundColor: "#04AA6D", borderRadius: "15px", padding: "10px" }}>{ele.subOrderStatus}</span>
                                    </div>
                                    <div className='row'>
                                        <div className='col-lg-12 pl-9' style={{ color: "#6a6a6a" }}>
                                            {ele.address}
                                        </div>
                                        {ele.product.map((ele) => (
                                            <table id="customers">
                                                <thead class=''>
                                                    <tr>
                                                        <th>Product</th>
                                                        <th>Quantity</th>
                                                        <th>Price</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td style={{ width: '200px' }}><a>{ele.productName}</a></td>
                                                        <td style={{ width: '200px' }}><a>{ele.quantity}</a></td>
                                                        <td style={{ width: '200px' }}>{ele.unitPrice}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        ))}

                                    </div>
                                </div>


                            )
                        })}
                        <div className='row mt-3'>
                            <div className='col-lg-12 text-right'>Shipment Package: {order.shipmentPackage}</div>
                            <div className='col-lg-12 text-right'>Total Amount: {order.totalAmount.toString()}</div>
                            <div className='col-lg-12 text-right'>Tax Amount: {order.taxAmount}</div>
                            <div className='col-lg-12 text-right'>Shipment Charges: {order.shipmentCharges}</div>
                            <div className='col-lg-12 text-right'>Grand Total: {order.grandTotal}</div>
                        </div>

                    </div>
                </div>
                {stockOutResp.code === 200 ? (
                    <Modal show={show} onHide={handleClose}>
                        <Modal.Header closeButton>
                            <Modal.Title className='text-center'>Success</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>{stockOutResp.msg}</Modal.Body>
                        <Modal.Footer></Modal.Footer>
                    </Modal>
                ) : stockOutResp.code === 400 ? (
                    <Modal show={show} onHide={handleClose}>
                        <Modal.Header closeButton>
                            <Modal.Title className='text-center'>Failed</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>{stockOutResp.msg}</Modal.Body>
                        <Modal.Footer></Modal.Footer>
                    </Modal>
                ) : (
                    <Modal show={show} onHide={handleClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>Confirmation</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>Are you sure, you want to dispatch the order?</Modal.Body>
                        <Modal.Footer>
                            <Button variant='secondary' onClick={handleClose}>
                                Cancel
                            </Button>
                            <Button variant='primary' htmlType='submit' onClick={onDispatchClick}>
                                Confirm
                            </Button>
                        </Modal.Footer>
                    </Modal>
                )}
                {/* Cancel Modal */}
                {stockOutResp.code === 200 ? (
                    <Modal show={cancelShow} onHide={handleCancelModalClose}>
                        <Modal.Header closeButton>
                            <Modal.Title className='text-center'>Success</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>{stockOutResp.msg}</Modal.Body>
                        <Modal.Footer></Modal.Footer>
                    </Modal>
                ) : stockOutResp.code === 400 ? (
                    <Modal show={cancelShow} onHide={handleCancelModalClose}>
                        <Modal.Header closeButton>
                            <Modal.Title className='text-center'>Failed</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>{stockOutResp.msg}</Modal.Body>
                        <Modal.Footer></Modal.Footer>
                    </Modal>
                ) : (
                    <Modal show={cancelShow} onHide={handleCancelModalClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>Confirmation</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>Are you sure you want to cancel shipment?</Modal.Body>
                        <Modal.Footer>
                            <Button variant='secondary' onClick={handleCancelModalClose}>
                                Cancel
                            </Button>
                            <Button variant='primary' htmlType='submit' onClick={onCancelClick}>
                                Confirm
                            </Button>
                        </Modal.Footer>
                    </Modal>
                )}

                {/* Deliver Modal    */}
                {/* {stockOutResp.code === 200 ? (
                    <Modal show={cancelShow} onHide={handleDeliverModalClose}>
                        <Modal.Header closeButton>
                            <Modal.Title className='text-center'>Success</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>{stockOutResp.msg}</Modal.Body>
                        <Modal.Footer></Modal.Footer>
                    </Modal>
                ) : stockOutResp.code === 400 ? (
                    <Modal show={cancelShow} onHide={handleDeliverModalClose}>
                        <Modal.Header closeButton>
                            <Modal.Title className='text-center'>Failed</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>{stockOutResp.msg}</Modal.Body>
                        <Modal.Footer></Modal.Footer>
                    </Modal>
                ) : (
                    <Modal show={cancelShow} onHide={handleDeliverModalClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>Confirmation</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>Are you sure, you want to deliver the shipment?</Modal.Body>
                        <Modal.Footer>
                            <Button variant='secondary' onClick={handleDeliverModalClose}>
                                Cancel
                            </Button>
                            <Button variant='primary' htmlType='submit' onClick={onDeliverClick}>
                                Confirm
                            </Button>
                        </Modal.Footer>
                    </Modal>
                )} */}
            </div> : " "}
        </>
    )
}

export default ViewSingleEcomOrder