export const sortAddInventoryArray = (invArr) => { 
    let returnArr = []
    let tempArr=[]
    for (let i = 0; i < invArr.length; i++) {
        invArr[i].productSerials?.forEach((ele) => { 
            returnArr.push({productName:invArr[i].name,issueDate:invArr[i].issueDate,isBind: ele.isBind, isLocked: ele.isLocked, isUsed: ele.isUsed, serialNo: ele.serialNo })
        })
    }
    return returnArr;

}


export const sortLabelArray = (invArr) => {
    console.log('invArr',invArr)
    let returnArr = []
    let tempArr=[]
    for (let i = 0; i < invArr.length; i++) {
        invArr[i].productLabels?.forEach((ele) => { 
            returnArr.push({productName:invArr[i].productName,createdAt:invArr[i].createdAt, trackingNumber: ele.trackingNumber, isUsed: ele.isUsed })
        })
    }
    console.log('returnArr',returnArr)
    return returnArr;
}
export const genericBoxArray = (invArr) => {
    console.log('invArr',invArr)
    let returnArr = []
    let tempArr=[]
    for (let i = 0; i < invArr.length; i++) {
        invArr[i].serialNo?.forEach((ele) => { 
            returnArr.push({genBoxId:invArr[i].genBoxId,type: invArr[i].type ,issueDate:invArr[i].issueDate, serialNo: ele.serialNo, isBind: ele.isBind})
        })
    }
    console.log('returnArr',returnArr)
    return returnArr;
}
export const availableBoxArray = (invArr) => {
    console.log('invArr',invArr)
    let returnArr = []
    for (let i = 0; i < invArr.length; i++) {
            returnArr.push({productName:invArr[i].productName,genericBoxSerial: invArr[i].genericBoxSerial ,productSerial:invArr[i].productSerial, returnLabelTracking:invArr[i].returnLabelTracking})
      
    }
    console.log('returnArr',returnArr)
    return returnArr;
}
