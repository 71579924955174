import React, { useState } from 'react';
import { Switch, Form, Upload } from 'antd';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import AddPhoto from '../assets/images/add.png';
import { LoadingOutlined } from '@ant-design/icons';
import getHeaders from '../utils/authHeaders';
import { useLocation } from 'react-router-dom';
import TestComponent from '../pages/Products/TestComponent';
// import TestComponent from './TestComponent';

export const AddRecord = ({ onChange, videoUrl, loading, extras, upload,
vdoupload, isRichTextEditor, setisActiv, textEditorsValue, uploadedImg, 
imageuploading, action, beforeUpload, handleuploadChange, pageTitle, inputs, selects, 
textareas, inputMask, initialValues, onFinish, onFinishFailed, switchBtn, goBack, addUserResp, 
handleClose, show, submitData, addMessage , setUploadedImg=()=>{}}) => {
  let headers = getHeaders();
  const location = useLocation();

  return (
    <div className={`dashboard-card pb-5 ${location.pathname === '/feedback/create' ? 'container' : 'container-fluid'}`}>
      <div className='row p-0 pb-4'>
        <div className='for-before col-12 col-md-7 head fs-3 fw-bold' style={{ backgroundColor: '#a7c1d9', borderBottomRightRadius: '10px', color: '#FFFFFF' }}>
          {pageTitle}
        </div>
      </div>
      <Form name='basic' onFinish={onFinish} onFinishFailed={onFinishFailed} autoComplete='off' initialValues={initialValues} className='row p-5 px-3 pb-1'>
        <div className={`${location.pathname === '/patient/create' ? '' : 'col-md-8'} col-12 input-column`}>
          <div className='row'>
            {inputs && inputs.map((item, key) => item)}
            {inputMask && inputMask.map((item) => item)}
            {selects && selects.map((item) => item)}
            {textareas && textareas.map((item) => item)}

            {extras && extras.map((item) => item)}
            {console.log(textEditorsValue)}
            {isRichTextEditor && (
              <div className={`  col-md-12 col-12 mb-5  required`}>
                <TestComponent title={pageTitle} onChange={onChange} textvalue='' />
              </div>
            )}
            {switchBtn && (
              <div className='col-lg-12 mb-2 ms-3'>
                <Form.Item label='Active' className='selector-main' colon={false} valuePropName='checked' name='isActive'>
                  <Switch
                    onChange={(e) => {
                      console.log(e);
                      setisActiv(e);
                    }}
                  />
                </Form.Item>
              </div>
            )}
            <button onClick={goBack} type='primary' className={`btn btn-primary button patient-cancel-btn  ${location.pathname === '/patient/create' ? 'col-md-3' : 'col-md-4'} mx-md-2 mr-2 mb-2`}>
              Cancel
            </button>
            <button type='primary' className={`btn btn-primary button ${location.pathname === '/patient/create' ? 'col-md-3' : 'col-md-4'}  mb-2`} htmlType='submit'>
              Save
            </button>
          </div>
        </div>
        {console.log(uploadedImg, imageuploading)}
        {upload && (
          <div className='col-md-4 col-12 order-first order-md-last image-column'>
            <Form.Item valuePropName='fileList'>
              <Upload listType='picture-card' showUploadList={false} action={action} beforeUpload={beforeUpload} onChange={handleuploadChange} headers={headers}>
                {uploadedImg && !imageuploading ? (
                  <img className='added-img' src={uploadedImg} alt='avatar' />
                ) : !uploadedImg && imageuploading ? (
                  <LoadingOutlined style={{ fontSize: '40px' }} />
                ) : (
                  <div className='d-flex flex-column py-3'>
                    <img className='for-add-img' src={AddPhoto} alt='avatar' />
                    <p className='add-text'>Upload Image</p>
                  </div>
                )}
              </Upload>
           
               {(uploadedImg && !imageuploading ) && <button onClick={()=>setUploadedImg("")}  style={{ backgroundColor: 'rgb(242, 29, 47)' }} className='add-btn'>
                  Remove
                </button>}
            
             
            </Form.Item>
          </div>
        )}
        {vdoupload && (
          <div className='col-md-4 col-12 order-first order-md-last image-column'>
            <Form.Item valuePropName='fileList'>
              <Upload listType='picture-card' showUploadList={false} action={action} beforeUpload={beforeUpload} onChange={handleuploadChange} headers={headers}>
                {uploadedImg && !imageuploading ? (
                  <video style={{ width: '100%', borderRadius: '5px', boxShadow: '3px 3px 10px #ccc' }} controls title='YouTube video player'>
                    <source className='for-add-img' src={uploadedImg} type='video/mp4' alt='avatar'></source>
                  </video>
                ) : !uploadedImg && imageuploading ? (
                  <LoadingOutlined style={{ fontSize: '40px' }} />
                ) : (
                  <div className='d-flex flex-column py-3'>
                    {uploadedImg ? (
                      <video style={{ width: '100%', borderRadius: '5px', boxShadow: '3px 3px 10px #ccc' }} controls title='YouTube video player'>
                        <source className='for-add-img' src={uploadedImg} type='video/mp4' alt='avatar'></source>
                      </video>
                    ) : (
                      ''
                    )}
                    <p className='add-text'>Upload Video</p>
                  </div>
                )}
              </Upload>
            </Form.Item>
          </div>
        )}
        {/* {(location.pathname === '/anura/elements/create' || '/labtest/create' || 'boxtype/create') && ( */}
        {videoUrl && (
          <div className='col-md-4 col-12 order-first order-md-last mb-3 m-md-0 image-column'>
            <video style={{ width: '100%', borderRadius: '5px', boxShadow: '3px 3px 10px #ccc' }} controls title='YouTube video player'>
              <source src={videoUrl} type='video/mp4'></source>
            </video>
          </div>
        )}
      </Form>

      {addUserResp?.code === 200 ? (
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title className='text-center'>Success</Modal.Title>
          </Modal.Header>
          <Modal.Body>{addUserResp.message}</Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      ) : addUserResp?.code === 400 ? (
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title className='text-center'>Failed</Modal.Title>
          </Modal.Header>
          <Modal.Body>{addUserResp.data.message}</Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      ) : (
        <Modal show={show} onHide={handleClose}>
          {/* <Modal.Header closeButton>
                      <Modal.Title>Confirmation</Modal.Title>
                    </Modal.Header> */}
          <Modal.Body>{addMessage}</Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={handleClose}>
              Cancel
            </Button>
            <Button disabled={loading} variant='primary' htmlType='submit' onClick={submitData}>
              {console.log('loading', loading)}
              {loading ? <LoadingOutlined /> : 'Confirm'}
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
};
