// import { Button, Form, Switch } from 'antd';
// import React from 'react'
// import Modal from 'react-bootstrap/Modal';
// import { InputField } from '../../layouts/FormFields';

// const UpdateFaqs = ({show,handleClose=()=>{}}) => {
//   return (
//     <>
//       {/* Modal for Show Partner Stores */}
//       <Modal show={show} onHide={handleClose} centered>
//         <Modal.Header closeButton>
//           <Modal.Title>Add FAQ Category</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           <Form name='basic' autoComplete='off' className='row p-5 px-3 pb-1'>
//             <div className='row'>
//               <InputField
//                 col='col-12'
//                 rules={[
//                   {
//                     required: true,
//                     message: 'Enter Name',
//                   },
//                 ]}
//                 placeholder='FAQ Name'
//                 name='name'
//                 label='Name'
//                 required={true}
//                 // onKeyPress={handleKeysPress}
//               />
//               <InputField
//                 col='col-12'
//                 rules={[
//                   {
//                     required: true,
//                     message: 'Enter Index',
//                   },
//                 ]}
//                 placeholder='Index'
//                 name='index'
//                 label='Index'
//                 required={true}
//                 // onKeyPress={handleKeysPress}
//               />
//               <div className='col-lg-12 mb-2 ms-3'>
//                 <Form.Item label='Active' className='selector-main' colon={false} valuePropName='checked' name='isActive'>
//                   <Switch
//                   // onChange={(e) => {
//                   //   console.log(e);
//                   //   setisActiv(e);
//                   // }}
//                   />
//                 </Form.Item>
//               </div>
//               <div className='d-flex align-items-center justify-content-center'>
//                 <button onClick={() => setUploadedImg('')} style={{ backgroundColor: 'rgb(242, 29, 47)' }} className='add-btn'>
//                   Add
//                 </button>
//               </div>
//             </div>
//           </Form>
//         </Modal.Body>

//       </Modal>
//     </>
//   );
// }

// export default UpdateFaqs

import { Button, Form, Switch } from 'antd';
import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { InputField } from '../../layouts/FormFields';
import axios from 'axios';
import getHeaders from '../../utils/authHeaders';
import { app } from '../../config';

const UpdateFaqs = ({ show, setShow, handleClose = () => {}, selectedFaq, setisRender , loader, setLoader}) => {
  const [form] = Form.useForm();

  const submit = async (values) => {
    try {
      let resp = await axios.post(`${app.URL}/api/v1/admin/faqcategory/update/${selectedFaq.faqCategoryId}`, values, { headers: getHeaders() });
      console.log(resp);
      if (resp.status == 200) {
        form.resetFields(); // Reset form fields after successful submission
          setLoader(true);
          setShow(false); // Close modal
          setisRender(true); 
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onFinish = (values) => {
    console.log('Form Values:', values);
    submit(values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Form Submission Failed:', errorInfo);
  };

  return (
    <>
      {loader && <LoaderImage />}
      {!loader && (
        <Modal show={show} onHide={handleClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>Update FAQ Category </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form
              form={form}
              name='basic'
              autoComplete='off'
              initialValues={{
                remember: true,
                ['name']: selectedFaq?.name,
                ['index']: selectedFaq?.index,
                ['isActive']: selectedFaq?.isActive,
              }}
              className='row  px-3 pb-1'
              onFinish={onFinish} // This will handle form submission
              onFinishFailed={onFinishFailed} // This will handle form validation failure
            >
              <div className='row'>
                <InputField
                  col='col-12'
                  rules={[
                    {
                      required: true,
                      message: ' Enter Category Name',
                    },
                  ]}
                  placeholder='Category Name'
                  name='name'
                  label='Category Name'
                  required={true}
                />
                <InputField
                  col='col-12'
                  rules={[
                    {
                      required: true,
                      message: ' Enter Index',
                    },
                  ]}
                  placeholder='Index'
                  name='index'
                  label='Index'
                  required={true}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />
                <div className='col-lg-12 mb-2 ms-3'>
                  <Form.Item label='Active' className='selector-main' colon={false} valuePropName='checked' name='isActive'>
                    <Switch />
                  </Form.Item>
                </div>
                <div className='col-12'>
                  <div className='row justify-content-center'>
                    <button onClick={() => setShow(false)} htmlType='button' type='primary' className='btn btn-primary button patient-cancel-btn col-md-5 mr-2 mb-2'>
                      Cancel
                    </button>

                    <button disabled={loader} type='primary' class='btn btn-primary button col-md-5 mb-2' htmlType='submit'>
                      {loader ? <LoadingOutlined /> : 'Save'}
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default UpdateFaqs;
