import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { app } from '../../config';
import { useNavigate } from 'react-router-dom';
import { Pagination, Input, Form, Select, Row, Col, DatePicker, Button, message, Modal as AntModal } from 'antd';
import { useTranslation } from 'react-i18next';
import Modal from 'react-bootstrap/Modal';
import { EditTwoTone, BranchesOutlined, CodeSandboxCircleFilled, ConsoleSqlOutlined, LoadingOutlined } from '@ant-design/icons';
import getHeaders from '../../utils/authHeaders';
import ListinngCard from '../../layouts/ListingCard';
import AddNewImg from '../../assets/images/add-new.png';
import { dateConverter } from '../../utils/utcDateConversionCommon';
import moment from 'moment';
import { useForm } from 'antd/lib/form/Form';
import { useReactToPrint } from 'react-to-print';
import Barcode from 'react-barcode';
import TextArea from 'antd/lib/input/TextArea';
import LoaderImage from '../LoaderImage';

function CheckOrder() {
  let navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem('user'));

  // const [accessionNo, setAccessionNo] = useState(false);
  const [boxNumberError, setBoxNumberError] = useState('');
  const [orderResponseData, setOrderResponseData] = useState();
  const [msg, setMsg] = useState('');
  // const [orderReceiveData, setOrderReceiveData] = useState();
  const [searchSerialNo, setSearchSerialNo] = useState('');
  const [sendToLabResp, setSendToLabResp] = useState('');
  const [orderForm] = useForm();
  const [show, setShow] = useState(false);
  const [loader, setLoader] = useState(false);
  // const [disableButton, setDisableButton] = useState(false);
  // const handleAccClose = () => setAccessionNo(!accessionNo);
  const handleSerialNoChange = async (event) => {
    setSearchSerialNo(event.target.value);
    if (event.target.value.length === 21) {
      await checkOrderBySerialNo('S', event.target.value);
    }
    if (event.target.value.length === 13) {
      await checkOrderBySerialNo('G', event.target.value);
    }
  };

  const inputRef = useRef();
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const checkOrderBySerialNo = async (flag, serialNo) => {
    console.log(flag, serialNo);
    setOrderResponseData();
    setLoader(true);

    try {
      const res = await axios.get(`${app.URL}/api/v1/admin/dashboard/checktestorder/${flag}/${serialNo}`, { headers: getHeaders() });
      console.log(res.data);

      if (res.data.result.code === 200) {
        setOrderResponseData(res.data.result.data);
        !res.data.result.data.collectionProcess && setMsg(res.data.result.msg);

        //  setOrderResponseData((prevItems) => {
        //    return { ...prevItems, ...res.data?.result?.data };
        //  });
        orderForm.resetFields();
        setLoader(false);
        setBoxNumberError('');
      }
      console.log(inputRef);

      // if (res.data.result.code === 404) {
      //   setBoxNumberError(res.data.result.msg);
      //   console.log(res.data.result.msg);
      //   setOrderResponseData();
      //   orderForm.resetFields();
      //   setLoader(false);
      // }
      // if (res.data.result.code === 400) {
      //   setOrderResponseData(res.data.result.msg);
      //   orderForm.resetFields();
      //   setLoader(false);
      //   setBoxNumberError('');
      // }
      setTimeout(() => {
        inputRef.current.focus();
      }, 300);
    } catch (error) {
      setLoader(false);
      setBoxNumberError(error.response.data.result.msg);
      console.log('error in check order api: ', error);
      orderForm.resetFields();
      setOrderResponseData();

      setTimeout(() => {
        inputRef.current.focus();
      }, 300);
    }
  };

  console.log(orderResponseData);

  // let resp;
  // const onReceiveOrder = async () => {
  //   // setLoader(true);
  //   let userId = user?.result.data.user.userId;

  //   const payload = {
  //     testOrderId: orderResponseData?.testOrderId,
  //     patientId: orderResponseData?.patientId,
  //     productId: orderResponseData?.productId,
  //     serialNo: orderResponseData?.serialNo,
  //     userId,
  //   };

  //   try {
  //     const res = await axios.post(`${app.URL}/api/v1/admin/dashboard/reciveorder`, payload, { headers: getHeaders() });
  //     console.log(res.data);

  //     // console.log("res",res.data.result)
  //     // return;

  //     if (res.data.result.success) {
  //       setOrderResponseData(res.data?.result?.data);
  //       console.log(res.data?.result?.data);
  //       resp = res.data?.result?.data;

  //       setTimeout(() => {
  //         handlePrint();
  //         setLoader(false);
  //       }, 2000);
  //     }
  //   } catch (error) {
  //     console.log('error in check order api: ', error);
  //   }
  // };

  const onProceedtolab = async () => {
    setLoader(true);
    let userId = user?.result.data.user.userId;

    const payload = {
      testOrderId: orderResponseData?.testOrderId,
      patientId: orderResponseData?.patientId,
      productId: orderResponseData?.productId,
      serialNo: orderResponseData?.serialNo,
      userId,
    };

    try {
      const res = await axios.post(`${app.URL}/api/v1/admin/dashboard/proceedtolab`, payload, { headers: getHeaders() });
      console.log(res.data);

      // console.log("res",res.data.result)
      // return;

      if (res.data.result.success) {
        setOrderResponseData(res.data?.result?.data);
        console.log(res.data?.result?.data);
        // resp = res.data?.result?.data;
        // setDisableButton(true);

        setTimeout(() => {
          handlePrint();
          setLoader(false);
          // setDisableButton(true);
        }, 2000);
      } else {
        setLoader(false);
      }
    } catch (error) {
      console.log('error in check order api: ', error);
      setLoader(false);
    }
  };

  const onSearchTrigger = async () => {
    console.log('search trigger', searchSerialNo);
    if (searchSerialNo.length === 21) {
      await checkOrderBySerialNo('S', searchSerialNo);
    }
    if (searchSerialNo.length === 13) {
      await checkOrderBySerialNo('G', searchSerialNo);
    }
  };
  // console.log(orderResponseData);

  // const sendToLab = async () => {
  //   console.log(resp);
  //   let userId = user?.result.data.user.userId;
  //   resp.userId = userId;

  //   try {
  //     const res = await axios.post(`${app.URL}/api/v1/admin/dashboard/sendtolab/${orderResponseData.testOrderId}`, resp, { headers: getHeaders() });

  //     console.log(res);
  //     // navigate('/dashboard/listing');
  //     setOrderResponseData();
  //     // setShow(false);
  //     setSendToLabResp(res.data.result.msg);
  //     localStorage.removeItem('accessionCode');
  //     // message.success(res.data.result.msg);
  //     setLoader(false);
  //   } catch (err) {
  //     console.log(err);
  //     setLoader(false);
  //     if (err) setSendToLabResp(err.response.data.result.msg);
  //     else return;
  //   }
  // };

  const handleSendToLabResp = () => {
    setSendToLabResp('');
    setTimeout(() => {
      inputRef.current.focus();
    }, 300);
  };
  const pageStyle = `
       @page {
        size: 2in 1in; /* Specify your custom page size here (width x height) */
        padding: 0;
        margin: 0;
       }
      //   @page {
      //   size: 8.5in 11.5in; /* Specify your custom page size here (width x height) */
      //      marginTop: 4px;
      //      margin: 0;
      //  }
       body {
         font-family: Arial, sans-serif;
         margin: 0;
       }
       `;

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: pageStyle,
    // onAfterPrint: sendToLab,
  });

  const handleRePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: pageStyle,
  });

  // const handleOpen = () => {
  //   setShow(true);
  // };
  // const handleClose = () => {
  //   setShow(false);
  // };

  const concatName = () => {
    // orderResponseData || orderReceiveData;
    if (orderResponseData) {
      //  let fullname = `${orderReceiveData ? orderReceiveData?.firstName : orderResponseData?.firstName} ${orderReceiveData ? orderReceiveData?.lastName : orderResponseData?.lastName} `;
      let fullname = `${orderResponseData?.firstName} ${orderResponseData?.lastName} `;
      if (fullname && fullname.length > 12) {
        // return `${orderReceiveData ? orderReceiveData?.firstName.slice(0, 1) : orderResponseData?.firstName.slice(0, 1)}. ${orderReceiveData ? orderReceiveData?.lastName : orderResponseData?.lastName}`;
        return `${orderResponseData?.firstName.slice(0, 1)}. ${orderResponseData?.lastName}`;
      } else {
        return fullname;
      }
    }
  };

  return (
    <div className='dashboard-card container-fluid pb-3'>
      <div className='row p-0 pb-4'>
        <div className='for-before col-12 col-md-7 head fs-3 fw-bold' style={{ backgroundColor: '#a7c1d9', borderBottomRightRadius: '10px', color: '#FFFFFF' }}>
          {/* Receive Shipment  */}
          {/* Scan QR / Bar code */}
          Receive Test Order
        </div>
      </div>

      <Form
        form={orderForm}
        name='basic'
        initialValues={{
          remember: true,
        }}
        onFinish={() => {
          onSearchTrigger();
        }}
        onFinishFailed={() => {}}
        className='row px-3 pb-1 d-flex justify-content-center justify-content-md-start'
        autoComplete='off'
      >
        <Form.Item
          name='searchItem'
          className='col-md-3 col-12 mb-2 mb-md-0'
          style={{
            width: 250,
          }}
        >
          <Input
            placeholder='Scan QR / Bar code'
            maxLength={21}
            onChange={(event) => {
              handleSerialNoChange(event);
            }}
            ref={inputRef}
          />
        </Form.Item>

        <button type='primary' className='col-md-2 col-6 btn btn-primary button' htmlType='submit'>
          Search
        </button>
      </Form>

      {boxNumberError && (
        <div className='row px-3 pb-1'>
          <div className='col-12'>
            <small
              style={{
                color: 'crimson',
                fontWeight: '500',
                fontSize: '0.8rem',
              }}
            >
              {boxNumberError}
            </small>
          </div>
        </div>
      )}

      {!loader && (
        <div className='row px-3'>
          <div className='col-12'>
            <span className={'text-success font-bold'}>
              {orderResponseData?.accessionNo && orderResponseData?.orderStatusId === 2 && 'The test order has already been sent to the lab.'}
              {!orderResponseData?.collectionProcess && `${msg}`}
            </span>
          </div>
        </div>
      )}

      {loader && <LoaderImage />}

      {orderResponseData && (
        <div className='px-3'>
          <div className='row mt-4'>
            {/* <div className='row'> */}
            <div className='col-12 fw-bold fs-5 mt-0 mb-0 pt-0 pb-0 pl-3'>{orderResponseData?.orderNo}</div>
            {/* <div className='col-12 mt-0 mb-0 pt-2 pb-0 pl-3'>{moment(orderResponseData?.orderDate).format('MM-DD-YYYY')}</div> */}
            {/* </div> */}
            {/* <div className='row'> */}
            <div className='col-12 mt-0 mb-0 pt-0 pb-0 pl-3'>{orderResponseData?.patientName}</div>
            <div className='col-12  mt-0 mb-0 pt-0 pb-0 pl-3'>{orderResponseData?.mobile}</div>

            {/* <div className='col-lg-6  mt-0 mb-0 pt-0 pb-0 pl-0 text-right'>{orderResponseData?.statusName}</div> */}
            {/* </div> */}
            {/* <div className='row'>
              
            </div> */}
          </div>
          <div
            className='row table-scrollbar mr-0'
            style={{
              overflowX: 'auto',
            }}
          >
            <table id='customers'>
              <thead>
                <tr
                  style={{
                    fontWeight: 'bold',
                  }}
                >
                  {/* <th>Partner Name</th> */}
                  <th>Product Code</th>
                  <th>Product Name </th>
                  <th>Panel Code</th>
                  <th>Panel Name</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  {/* <td>{orderResponseData?.partnerName}</td> */}
                  <td>{orderResponseData?.productCode}</td>
                  <td>{orderResponseData?.productName}</td>
                  <td>{orderResponseData?.cisPanelCode}</td>
                  <td>{orderResponseData?.cisPanelName}</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div style={{ display: 'none' }}>
            <div ref={componentRef} className='checkorderprint'>
              <div className='d-flex align-items-center justify-content-between'>
                <small>{concatName()}</small>
                {/* <small>{orderReceiveData ? orderReceiveData?.gender : orderResponseData?.gender}</small> */}
                <small>{orderResponseData?.gender}</small>
                {/* <small>{orderReceiveData ? orderReceiveData?.cisPanelCode : orderResponseData?.cisPanelCode}</small> */}
                <small>{orderResponseData?.cisPanelCode}</small>
              </div>
              <div className='d-flex align-items-center justify-content-between'>
                {/* <small>{orderReceiveData ? moment(orderReceiveData?.dateOfBirth).format('MM-DD-YYYY') : moment(orderResponseData?.dateOfBirth).format('MM-DD-YYYY')}</small> */}
                <small>{moment(orderResponseData?.dateOfBirth).format('MM-DD-YYYY')}</small>
                {/* <small>{orderReceiveData ? moment(orderReceiveData?.orderDate).format('MM-DD-YY hh:mm') : moment(orderResponseData?.orderDate).format('MM-DD-YY hh:mm')}</small> */}
                <small>{moment(orderResponseData?.collectionTime).format('MM-DD-YY hh:mm')}</small>
              </div>
              {/* <div className='d-flex align-items-center justify-content-between'></div> */}
              <div className='d-flex align-items-center justify-content-center'>
                {/* <Barcode margin={0} width={1.5} fontSize={14} height={31} value={orderReceiveData ? orderReceiveData?.accessionNo : orderResponseData?.accessionNo} /> */}
                <Barcode margin={0} width={1.5} fontSize={14} height={31} value={orderResponseData?.accessionNo} />
              </div>
            </div>
          </div>

          <div className='row'>
            <div className='text-right col-md-12'>
              {orderResponseData?.accessionNo && orderResponseData?.orderStatusId === 2 && !loader ? (
                <button variant='primary' className='btn btn-primary' onClick={handleRePrint}>
                  Re Print Label
                  {/* Print Accession No. */}
                </button>
              ) : (
                <button
                  variant='primary'
                  // disabled={orderResponseData?.accessionNo && orderResponseData?.orderStatusId === 2}
                  className='btn btn-secondary'
                  onClick={orderResponseData?.collectionProcess ? onProceedtolab : () => {}}
                  disabled={!orderResponseData?.collectionProcess || loader}
                >
                  {/* Generate & Print Label  */}
                  Print Accession No.
                </button>
              )}
              {/* <button
                variant='primary'
                className='btn btn-primary'
                onClick={onReceiveOrder}
                disabled={orderResponseData.isReceiveOrder}
                
              >
                Receive
              </button> */}

              {/* <button
                variant='primary'
                className='btn btn-primary'
                onClick={handlePrint}
                style={{
                  margin: '0px 20px',
                }}
                disabled={!orderReceiveData && !orderResponseData.isReceiveOrder}
              >
                Print Label
              </button> */}

              {/* {orderResponseData.orderStatusId === 1 ? (
                <button variant='primary' className='btn btn-primary' disabled={!orderReceiveData && !orderResponseData.isReceiveOrder} onClick={handleOpen}>
                  Send To Lab
                </button>
              ) : (
                <button variant='primary' className='btn btn-primary' disabled={true}>
                  Send To Lab
                </button>
              )} */}
            </div>
          </div>
        </div>
      )}

      {/* <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Order - Send To Lab</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            initialValues={{
              remember: true,
            }}
            className='row'
            onFinish={sendToLab}
            onFinishFailed={() => {}}
            autoComplete='off'
          >
            <div className='col-lg-12'>
              <label>Enter Comments</label>
              <Form.Item name='comments'>
                <TextArea placeholder='Enter Comments' className='admin' style={{ width: '100% !important' }} />
              </Form.Item>
            </div>

            <div className='col-lg-12 text-center'>
              <button variant='success' className='dashboard col-lg-6 btn btn-primary' htmlType='submit'>
                Send To Lab
              </button>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button className='col-lg-2 btn-style' variant='secondary' onClick={handleClose}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal> */}

      {/* <Modal show={accessionNo} onHide={handleAccClose}>
        <Modal.Body>
          <div className='container-fluid'>
            <div className='row'>
              <label className='col-md-6 d-flex align-items-center' style={{ backgroundColor: '#fff', fontWeight: '500', fontSize: '1.8rem' }}>
                Accession Code
              </label>
              <input className='col-md-6 ' disabled value={orderReceiveData ? orderReceiveData?.accessionNo : orderResponseData?.accessionNo} style={{ backgroundColor: '#fff', fontWeight: '700', fontSize: '2rem' }} />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className='container-fluid'>
            <div className='row'>
              <Button className='offset-md-4 col-lg-4 btn-style' variant='secondary' onClick={handleAccClose}>
                Close
              </Button>
            </div>
          </div>
        </Modal.Footer>
      </Modal> */}

      <Modal show={sendToLabResp} onHide={handleSendToLabResp}>
        <Modal.Body>
          <div className='container-fluid'>
            <div className='row'>
              <input className='col-12 text-center' disabled value={sendToLabResp} style={{ backgroundColor: '#fff', fontWeight: '500', fontSize: '1.5rem' }} />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className='container-fluid'>
            <div className='row'>
              <Button className='offset-md-4 col-lg-4 btn-style' variant='secondary' onClick={handleSendToLabResp}>
                Close
              </Button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default CheckOrder;
