import React from 'react';
import loaderImg from '../assets/img/know-yellow-loader.gif';
const LoaderImage = () => {
  return (
    <div className='row mx-2'>
      <div className='col-12 d-flex justify-content-center'>
        <img src={loaderImg} width='100px' />
      </div>
    </div>
  );
};

export default LoaderImage;
