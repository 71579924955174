import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { app } from '../../config';
import { useNavigate } from 'react-router-dom';
import { Pagination, Input, Form, Select, Row, Col, DatePicker, message } from 'antd';
import { useTranslation } from 'react-i18next';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { EditTwoTone, BranchesOutlined, PrinterOutlined, LoadingOutlined } from '@ant-design/icons';
import getHeaders from '../../utils/authHeaders';
import ListinngCard from '../../layouts/ListingCard';
import { dateConverter } from '../../utils/utcDateConversionCommon';
import AddNewImg from '../../assets/images/add-new.png';
import lockIcon from '../../assets/images/lock.png';
import { SearchBar } from '../../layouts/SearchBar';
import { useReactToPrint } from 'react-to-print';
import { useRef } from 'react';
import LoaderImage from '../LoaderImage';
import NoDataFound from '../../layouts/NoDataFound';
import QRCodeLogo from '../../lib/QRCodeLogo';
import { CSVLink, CSVDownload } from 'react-csv';
import Excel from '../../assets/images/excel.png';
import { availableBoxArray, sortLabelArray } from './inventoryHelpers';
import { extractObjValues } from '../../utils/extractObjValues';
import AvailableProductViewModal from './AvailableProductViewModal';

const MappingListing = () => {
  const [editbtn, setEditBtn] = useState('none');
  const [pageSize, setPageSize] = useState(10);
  const [fetchPartners, setFetchPartners] = useState([]);
  const [updbatchId, setUpdBatchId] = useState('');
  const [delBatchId, setDelBatchId] = useState('');
  const [pagesCount, setPagesCount] = useState(0);
  const [searchShow, setSearchShow] = useState({});
  const [renderList, setRenderList] = useState([]);
  const [tableShow, setTableShow] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [err, setErr] = useState('');
  const [tableHeaders, setTableHeaders] = useState([]);
  const [show, setShow] = useState(false);
  const [lockModalShow, setLockModalShow] = useState(false);
  const [detailModalShow, setDetailModalShow] = useState(false);
  const [delBatchResp, setDelBatchResp] = useState({});
  const [lockBatchResp, setLockBatchResp] = useState({});
  const [inventoryListingResp, setInventoryListingResp] = useState([]);
  const [loader, setLoader] = useState(false);
  const [boxDetail, setBoxDetails] = useState({});
  const [showView, setShowView] = useState(false);

  const handleClose = () => setShow(false);
  const handleLockClose = () => {
    setLockModalShow(false);
    setDetailModalShow(false);
  };
  let navigate = useNavigate();
  let convertedObj;

  const user = JSON.parse(localStorage.getItem('user'));
  if (user.userType === 'A') setEditBtn('block');
  const viewBatchDetails = (data) => {
    localStorage.setItem('batchId', JSON.stringify(data.batchId));
    console.log('Local Storage Set');
    navigate(`/inventory/batchreport`);
  };
  const addInventory = () => {
    navigate('/inventory/create', { replace: true });
  };

  const lockBatch = async () => {
    console.log('Consoling updated batch');
    console.log(updbatchId);
    const res = await axios.post(`${app.URL}/api/v1/admin/inventory/updatebatch`, { batchId: updbatchId }, { headers: getHeaders() });
    console.log(res);
    if (res && res.data.result.success) {
      console.log(res.data.result);
      setLockModalShow(false);
    }
  };
  const lockBatchFunc = (data) => {
    console.log(data);
    setLockModalShow(true);
    setUpdBatchId(data.batchId);
  };
  const deleteBatch = async (batchId) => {
    console.log('Consoling batch deleted');
    console.log(delBatchId);
    const res = await axios.delete(`${app.URL}/api/v1/admin/inventory/delete/${delBatchId}`, { headers: getHeaders() });
    console.log(res);
    if (res && res.data.result.success) {
      console.log(res.data.result);
      setLockBatchResp(res.data.result);
      setShow(false);
      // window.location.reload()
    }
  };
  const deleteBatchFunc = (data) => {
    console.log(data);
    setShow(true);
    setDelBatchId(data.batchId);
  };
  const inventoryListingApi = async (formData) => {
    console.log(formData);
    setLoader(true);

    const partnerResp = await axios.get(`${app.URL}/api/v1/admin/inventory/mapview?limit=${formData.limit}&offset=${formData.offset}&searchBy=${formData.searchBy}&searchTxt=${formData.searchTxt}`, { headers: getHeaders() });
    console.log(partnerResp);
    partnerResp ? console.log(partnerResp.data.result.data) : '';
    // partnerResp ? (convertedObj = dateConverter(partnerResp.data.result.data.data, 'issueDate')) : ' ';
    // partnerResp.data.result ? setFetchPartners(convertedObj) : ' ';
    partnerResp.data.result ? setPagesCount(partnerResp.data.result.data.count) : ' ';
    partnerResp.data.result ? setRenderList(partnerResp.data.result.data.data) : ' ';
    partnerResp.data.result && setLoader(false);
  };
  const onChange = async (page, pageSize, formData) => {
    console.log(page, pageSize);
    setPageSize(pageSize);
    setCurrentPage(page);
    if (!page) page = 1;
    let limit = pageSize;
    try {
      let reqObj;
      if (Object.keys(searchShow).length !== 0) {
        reqObj = {
          limit: limit,
          offset: limit * (page - 1),
          searchBy: searchShow.searchBy,
          searchTxt: searchShow.searchTxt,
        };
      } else {
        reqObj = {
          limit: limit,
          offset: limit * (page - 1),
          searchBy: 'genericBox',
          searchTxt: '',
        };
      }
      console.log(reqObj);
      await inventoryListingApi(reqObj);
    } catch (e) {
      console.log('Error');
      if (e.message) setErr(e.message);
      setTableShow(false);
    }
  };

  // const getData = async () => {
  //   let formData = {
  //     limit: pageSize,
  //     offset: 0,
  //     searchBy: '',
  //     searchTxt: '',
  //   };
  //   console.log(formData);
  //   const partnerResp = await axios.post(`${app.URL}/api/v1/admin/inventory/batchlisting`, formData, { headers: getHeaders() });
  //   console.log(partnerResp);
  //   partnerResp ? console.log(partnerResp.data.result.data) : ' ';
  //   partnerResp.data.result ? (convertedObj = await dateConverter(partnerResp.data.result.data.data, 'issueDate')) : ' ';
  //   partnerResp.data.result ? setFetchPartners(convertedObj) : ' ';
  //   partnerResp.data.result ? setPagesCount(partnerResp.data.result.data.count) : ' ';
  //   partnerResp.data.result ? setRenderList(convertedObj) : ' ';
  //   return console.log('return called');
  // };
  useEffect(() => {
     let formData = {
       limit: pageSize,
       offset: 0,
       searchBy: 'genericBox',
       searchTxt: '',
     };
    inventoryListingApi(formData);
  }, [lockModalShow, show]);

  let searchItems = [
    //     genericBox
    // productSerial
    // returnLabel
    // productName
    {
      name: 'Generic Box',
      value: 'genericBox',
    },
    {
      name: 'Product Serial',
      value: 'productSerial',
    },
    {
      name: 'Return Label',
      value: 'returnLabel',
    },
    {
      name: 'Product Name',
      value: 'productName',
    },
  ];

  const onFinish = async (values) => {
    let formData = {
      limit: pageSize,
      offset: 0,
      searchBy: values?.searchBy,
      searchTxt: values?.searchTxt || "",
    };
    inventoryListingApi(formData)
    
  };
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const fetchInventoryByBatchId = async (invId) => {
    console.log(invId);
    setInventoryListingResp([]);
    const responseData = await axios.get(`${app.URL}/api/v1/admin/inventory/bybatch/${invId}`, { headers: getHeaders() });
    console.log(responseData);
    if (responseData.status === 200) {
      setInventoryListingResp(responseData.data.result.data);
    }
  };

  let printRef = useRef();

  const pageStyle = `
       @page {
        size: 1in 1in; /* Specify your custom page size here (width x height) */
           marginTop: 4px;
           margin: 0;
       }
      //   @page {
      //   size: 8.5in 11.5in; /* Specify your custom page size here (width x height) */
      //      marginTop: 4px;
      //      margin: 0;
      //  }
       body {
         font-family: Arial, sans-serif;
         margin: 0;
       }
       `;

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    pageStyle: pageStyle,
  });

  const [displayQRCode, setDisplayQRCode] = useState([]);

  const [printLoader, setPrintLoader] = useState(false);
  const [batchID, setBatchID] = useState('');

  const generateBarCode = (batch) => {
    let qrCodes;
    console.log(batch);

    // console.log(invListData[i].serialNo);
    let flag = 'G';
    console.log(flag);
    qrCodes = <QRCodeLogo value={batch.productSerial} flag={flag} />;

    setDisplayQRCode(qrCodes);

    setTimeout(() => {
      setPrintLoader(false);
      handlePrint();
      // setBatchID('');
    }, 2000);
  };

  const handleRePrint = async (listItem) => {
    // setBatchID(batchId);
    setPrintLoader(true);
    // let formData = {
    //   batchId: batchId,
    //   limit: qty,
    //   offset: 0,
    //   searchBy: '',
    //   searchTxt: '',
    // };
    // const batchResp = await axios.post(`${app.URL}/api/v1/admin/inventory/batchreport`, formData, { headers: getHeaders() });
    // batchResp ? console.log(batchResp.data.result.data.listing) : '';
    console.log('listItem', listItem);
    generateBarCode(listItem);
  };

  const handleOnChange = async (e) => {
    if (e.target.value.length === 0) {
      let formData = {
        limit: pageSize,
        offset: 0,
        searchBy: '',
        searchTxt: '',
      };
      setSearchShow({});
      // await inventoryListingApi(formData);
    }
  };

  const refinedArray = extractObjValues(renderList, ['productName', 'genericBoxSerial', 'productSerial', 'returnLabelTracking']);
  let newArray = [];
  refinedArray.forEach((ele) => {
    newArray.push({ [`Product`]: ele.productName, [`Generic Box`]: ele.genericBoxSerial, [`Product Serial`]: ele.productSerial, [`Return Label`]: ele.returnLabelTracking });
  });

  // const createCsv = (prd, genSerial, prdSerial, reLabel) => {
  //   let newArray = [];
  //   newArray.push({ [`Product`]: prd, [`Generic Box`]: genSerial, [`Product Serial`]: prdSerial, [`Return Label`]: reLabel });
  //   console.log(newArray);
  //   return (
  //     <CSVLink filename={`mappingRecord-${prd}-${prdSerial}.csv`} data={newArray}>
  //       <img src={Excel} style={{ width: '30px', height: '30px' }} />
  //     </CSVLink>
  //   );
  // };

  return (
    <div className='dashboard-card container-fluid'>
      <div className='row p-0 pb-4'>
        <div className='for-before col-12 col-md-7 head fs-3 fw-bold' style={{ backgroundColor: '#a7c1d9', borderBottomRightRadius: '10px', color: '#FFFFFF' }}>
          Available Product Boxes
        </div>

        <div className='col-12 col-md-5 d-flex align-items-center justify-content-end pe-5'>
          <CSVLink filename={`mappingRecord.csv`} data={newArray}>
            <img src={Excel} style={{ width: '30px', height: '30px' }} />
          </CSVLink>
        </div>
      </div>

      <SearchBar onFinish={onFinish} onFinishFailed={onFinishFailed} searchItems={searchItems} onChange={handleOnChange} />
      <div style={{ display: 'none' }}>
        <div ref={printRef}>{displayQRCode}</div>
      </div>

      <div
        className='row table-scrollbar mr-0'
        style={{
          overflowX: 'auto',
        }}
      >
        {loader && <LoaderImage />}
        {renderList.length > 0 && !loader ? (
          <table id='customers'>
            <thead>
              <tr>
                <th>Product Name</th>
                <th>Generic BoxSerial</th>
                <th>Product Serial</th>
                <th>Return Label Tracking</th>
                <th> </th>
              </tr>
            </thead>
            <tbody>
              {renderList.map((tableValues) => (
                <tr key={tableValues.productMappingId}>
                  <td>{tableValues.productName ? tableValues.productName : 'null'}</td>
                  <td>{tableValues.genericBoxSerial ? tableValues.genericBoxSerial : 'null'}</td>
                  <td>{tableValues.productSerial ? tableValues.productSerial : 'null'}</td>
                  <td>{tableValues.returnLabelTracking ? tableValues.returnLabelTracking : 'null'}</td>
                  <td className='d-flex align-items-center'>
                    {/* {createCsv(tableValues.productName, tableValues.genericBoxSerial, tableValues.productSerial, tableValues.returnLabelTracking)} */}
                    <button disabled={batchID === tableValues.batchId} className='btn mx-3' onClick={() => handleRePrint(tableValues)}>
                      {printLoader && batchID === tableValues.batchId ? (
                        <LoadingOutlined
                          style={{
                            fontSize: '1.5rem',
                            color: 'white',
                          }}
                        />
                      ) : (
                        <PrinterOutlined
                          style={{
                            fontSize: '1.5rem',
                            color: 'white',
                          }}
                        />
                      )}
                    </button>

                    <Button
                      onClick={() => {
                        setBoxDetails(tableValues);
                        setShowView(true);
                      }}
                      style={{ width: '70px', height: '34px', fontSize: 'small' }}
                      size='medium'
                    >
                      View
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <>{!loader && <NoDataFound />}</>
        )}
      </div>

      {showView && <AvailableProductViewModal isVisible={showView} setIsVisible={setShowView} orderDetails={boxDetail} />}
      {lockBatchResp.code === 200 ? (
        <Modal show={lockModalShow} onHide={handleLockClose}>
          <Modal.Header closeButton>
            <Modal.Title className='text-center'>Success</Modal.Title>
          </Modal.Header>
          <Modal.Body>Batch Locked successfully</Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      ) : lockBatchResp.code === 400 ? (
        <Modal show={lockModalShow} onHide={handleLockClose}>
          <Modal.Header closeButton>
            <Modal.Title className='text-center'>Failed</Modal.Title>
          </Modal.Header>
          <Modal.Body>{lockBatchResp.data.message}</Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      ) : (
        <Modal show={lockModalShow} onHide={handleLockClose}>
          <Modal.Header closeButton>
            <Modal.Title>Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure, you want to lock the Batch?</Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={handleLockClose}>
              Cancel
            </Button>
            <Button variant='primary' htmlType='submit' onClick={lockBatch}>
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      {delBatchResp.code === 200 ? (
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title className='text-center'>Success</Modal.Title>
          </Modal.Header>
          <Modal.Body>Batch Deleted successfully</Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      ) : delBatchResp.code === 400 ? (
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title className='text-center'>Failed</Modal.Title>
          </Modal.Header>
          <Modal.Body>{delBatchResp.data.message}</Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      ) : (
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure, you want to delete the Batch?</Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={handleClose}>
              Cancel
            </Button>
            <Button
              variant='primary'
              htmlType='submit'
              onClick={() => {
                deleteBatch(tableValues.batchId);
              }}
            >
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      <Modal show={detailModalShow} onHide={handleLockClose}>
        <Modal.Header closeButton>
          <Modal.Title className='text-center'>Batch-{inventoryListingResp[0]?.batchId}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table id='customers'>
            <thead class=''>
              <tr>
                {/* <th>Batch No.</th> */}
                <th>Product Name</th>
                <th>Quantity</th>
              </tr>
            </thead>
            <tbody>
              {inventoryListingResp
                ? inventoryListingResp.map((tableValues) => (
                    <tr>
                      {/* <td  style={{ width: '200px' }} ><a >{tableValues.batchId ? tableValues.batchId : 'null'}</a></td> */}
                      <td style={{ width: '200px' }}>{tableValues.productName ? tableValues.productName : 'null'}</td>
                      <td style={{ width: '150px', textAlign: 'center' }}>{tableValues.prodQty ? tableValues.prodQty : 'null'}</td>
                    </tr>
                  ))
                : ' '}
            </tbody>
          </table>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>

      {!loader && <div className='row'>{currentPage && pagesCount ? <Pagination style={{ marginTop: '10px' }} onChange={onChange} defaultCurrent={currentPage} total={pagesCount} showTotal={(total) => `Total ${total} items`} showSizeChanger /> : ' '}</div>}
    </div>
  );
};

export default MappingListing;
