
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { app } from '../../config';
import getHeaders from '../../utils/authHeaders';
import { useNavigate } from 'react-router-dom';
import { InputField, SelectField, TextAreaField } from '../../layouts/FormFields';
import { Button, Form, Space, Upload, message, ColorPicker, Select, Switch } from 'antd'; //4.24.7
import { DeleteFilled, UploadOutlined } from '@ant-design/icons';
import { AddRecord } from '../../layouts/AddRecord';

const AddGroup = () => {
  let navigate = useNavigate();
  function goBack() {
    navigate('/labtestgroups/view', { replace: true });
  }
  window.addEventListener('popstate', goBack);
  const [loader, setLoader] = useState(false);
  const [addUserResp, setAddUserResp] = useState({});
  const [labTestObj, setLabTestObj] = useState([]);
  const [uploadObj, setUploadObj] = useState({});
  const [show, setShow] = useState(false);
  const [uploadVideo, setuploadVideo] = useState('');
  const [contentType, setContentType] = useState();
  const [uplVid, setuplVid] = useState(true);
  const [resType, setResType] = useState('');
  const [testGroups, setTestGroups] = useState([]);

  const getLabTestGroups = async () => {
    const res = await axios.get(`${app.URL}/api/v1/admin/labtestgroups/getall`, { headers: getHeaders() });
    console.log(res);
    res.data.result.success && setTestGroups(res.data.result.data);
  };

  const getLabTestById = async (resultTestCode) => {
    setLoader(true);
    const res = await axios.get(`${app.URL}/api/v1/admin/labtest/view/${resultTestCode}`, { headers: getHeaders() });
    console.log(res);
    res.data.result.success && setLabTestObj(res.data.result.data);
    res.data.result.success && setResType(res.data.result.data.resultType);
    res.data.result.success && getLabTestGroups();
    res.data.result.data.videoUrl && setuploadVideo(res.data.result.data.videoUrl);
    setLoader(false);
  };

  useEffect(() => {
    const resultTestCode = localStorage.getItem('resultTestCode');
    getLabTestById(resultTestCode);
  }, []);

  const putLabTestListing = async (details) => {
    // console.log(details);

    setLoader(true);
    try {
      const res = await axios.post(`${app.URL}/api/v1/admin/labtest/update?resultTestCode=${labTestObj.resultTestCode}`, details, { headers: getHeaders() });
      setLoader(false);
      setShow(false);
      console.log(res);

      if (res.data.result.success) {
        setAddUserResp(res.data.result.data);
        message.success(res.data.result.msg);
        setTimeout(goBack, 500);
      } else if (!res.data.result.success) {
        message.warning(res.data.result.msg);
      }
    } catch (err) {
      console.log(err);
      setLoader(false);
      setShow(false);
      if (err) console.log(err.response.data.message);
      else return;
    }
  };
  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    // reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  };
  const beforeUpload = (file) => {
    console.log(file);
    setuploadVideo('');
    if (file.type === 'image/jpeg' || file.type === 'image/png') {
      console.log('setting image type');
      setContentType('img');
    } else if (file.type === 'video/x-matroska' || file.type === 'video/mp4') setContentType('video');
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'video/x-matroska' || file.type === 'video/mp4';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    console.log('file Checked');
    const isLt2M = file.size / 1024 / 1024 < 20;
    if (!isLt2M) {
      setLargeFile(true);
      message.error('Image must smaller than 20MB!');
    }
    console.log('message');
    return isJpgOrPng && isLt2M;
  };

  const handleuploadChange = (info) => {
    if (info.file.status === 'uploading') {
      return;
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      console.log(info.file);
      info.file ? console.log(info.file.response.result.data.Location) : console.log('Not found');
      info.file ? setuploadVideo(info.file.response.result.data.Location) : ' ';
      getBase64(info.file.originFileObj, (url) => {
        setuplVid(false);
      });
    }
  };

  const onFinish = (values) => {
    console.log(values);
    if (values.ranges?.length === 0 && (values.resultType === 'R' || values.resultType === 'P')) {
      message.info('Please add minimum one range for result!');
    } else {
      setUploadObj({
        resultTestCode: values.resultTestCode ? values.resultTestCode : null,
        resultElement: values.resultElement ? values.resultElement : null,
        resultElementGroup: values.resultElementGroup ? values.resultElementGroup : null,
        displayPosition: values.displayPosition ? values.displayPosition : '',
        isActive: values.isActive ? 1 : 0,
        description: values.description ? values.description : null,
        // productCode: values.productCode ? values.productCode : null,
        // productName: values.productName ? values.productName : null,
        resultType: values.resultType ? values.resultType : null,
        ranges: values.ranges ? values.ranges : [],
        videoUrl: uploadVideo ? uploadVideo : null,
      });
      setShow(true);
    }
    // console.log(uploadVideo);
  };
  const submitData = () => {
    console.log(uploadObj);
    if (Object.keys(uploadObj).length > 0) {
      console.log(uploadObj);
      putLabTestListing(uploadObj);
    }
  };
  const onFinishFailed = () => {};

  const labTestToggleVideo = () => {
    setuploadVideo('');
    setTimeout(() => setuploadVideo(labTestObj.videoUrl), 500);
  };


  return (
    <AddRecord
      pageTitle='Add Lab Test Panel'
      loading={loader}
      response={labTestObj}
      goBack={goBack}
      upload={false}
      show={show}
      handleClose={() => setShow(false)}
      addUserResp={addUserResp}
      submitData={submitData}
      updateMessage='Are you sure you want to add lab test group?'
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      switchBtn={false}
      inputs={[
        <SelectField
          col='col-lg-4'
          name='resultElementGroup'
          label='Panel Name'
          rules={[
            {
              required: true,
              message: 'Select Group',
            },
          ]}
          placeholder='Group'
          required={true}
          options={testGroups.map((item) => (
            <Select.Option value={item.resultElementGroupId}>{item.cisPanelName}</Select.Option>
          ))}
        />,
        // <div className='col-lg-6 d-none d-md-block'></div>,
        <InputField col='col-lg-6' name='resultTestCode' disabled={true} placeholder='Test Code' label='Test Code' />,
        <InputField col='col-lg-6' name='resultElement' disabled={true} placeholder='Element' label='Test Name' />,

        // <InputField col='col-lg-3' name='resultType' disabled={true} label='Result Type' />,
        <SelectField
          col='col-lg-3'
          name='resultType'
          label='Result Type'
          onChange={(e) => setResType(e)}
          rules={[
            {
              required: true,
              message: 'Select Result Type',
            },
          ]}
          placeholder='Result Type'
          required={true}
          options={[
            { name: 'Range', value: 'R' },
            { name: 'Single Value', value: 'S' },
            { name: 'Positive/Negative', value: 'P' },
          ].map((item) => (
            <Select.Option value={item.value}>{item.name}</Select.Option>
          ))}
        />,
        <InputField
          col='col-lg-3'
          name='displayPosition'
          rules={[
            {
              required: true,
              message: 'Enter Display Postion',
            },
          ]}
          required={true}
          label='Display Position'
        />,
        <div className='col-lg-3 mb-2 d-flex align-items-center'>
          <Form.Item label='Active' className='selector-main' colon={false} valuePropName='checked' name='isActive'>
            <Switch />
          </Form.Item>
        </div>,
       
        <TextAreaField showCount={true} maxLength={1000} col='col-lg-12' name='description' placeholder='Enter description' label='Description' rows={2} />,
        <div className='col-md-8'>
          <Form.Item colon={false} name='video'>
            <Space
              direction='vertical'
              style={{
                width: '100%',
              }}
              size='large'
            >
              <Upload onRemove={labTestToggleVideo} listType='picture' maxCount={1} className='avatar-uploader' headers={getHeaders()} action={`${app.URL}/api/v1/general/product/videoUpload`} beforeUpload={beforeUpload} onChange={handleuploadChange}>
                <Button icon={<UploadOutlined />} className={'upload-btn'} style={{ color: 'black' }}>
                  Upload Video
                </Button>
                <div className='mt-2'>Note: Upload size for video is 50mb supported format for video are (mkv/mp4)</div>
              </Upload>
            </Space>
          </Form.Item>
        </div>,
      ]}
      
      videoUrl={uploadVideo}
    />
  );
};

export default AddGroup;
