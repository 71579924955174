import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { app } from '../../config';
import { useNavigate } from 'react-router-dom';
import { Pagination, Input, Form, Select, Row, Col, DatePicker, Button, message } from 'antd';
import { useTranslation } from 'react-i18next';
import Modal from 'react-bootstrap/Modal';
import { EditTwoTone, BranchesOutlined } from '@ant-design/icons';
import getHeaders from '../../utils/authHeaders';
import ListinngCard from '../../layouts/ListingCard';

function PartnersListing() {
  const { t } = useTranslation(['common']);
  let navigate = useNavigate();
  const [editbtn, setEditBtn] = useState('none');
  const [pageSize, setPageSize] = useState(10);
  const [fetchUsers, setFetchPartners] = useState([]);
  const [pagesCount, setPagesCount] = useState(0);
  const [searchShow, setSearchShow] = useState({});
  const [renderList, setRenderList] = useState();
  const [tableShow, setTableShow] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [err, setErr] = useState('');
  const [show, setShow] = useState(false);
  const [storesList, setStoresList] = useState([]);
  const [tableHeaders, setTableHeaders] = useState([]);
  const [loader, setLoader] = useState(false);


  const handleClose = () => setShow(false);

  const partnerListingApi = async (formData) => {
    setLoader(true);
    const partnerResp = await axios.post(`${app.URL}/api/v1/admin/partner/listing`, formData, { headers: getHeaders() });
    partnerResp.data.result ? setFetchPartners(partnerResp.data.result.data) : ' ';
    partnerResp.data.result ? setPagesCount(partnerResp.data.result.data.count) : ' ';
    partnerResp.data.result ? setRenderList(partnerResp.data.result.data.data) : ' ';
    partnerResp.data.result && setLoader(false);
  };

  let formData = {
    limit: pageSize,
    offset: 0,
    searchBy: 'partnerName',
    searchTxt: '',
  };
  const userList = async () => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user.userType === 'A') setEditBtn('block');
    let header = ['Name', 'Email', 'Moblie', 'Address', 'City', 'State', 'Status', 'Store(s)', 'Action'];
    setTableHeaders(header);
    await partnerListingApi(formData);
  };
  useEffect(() => {
    setLoader(true);
    userList();

    // setSearchItems(searchFilters);
  }, []);

  let searchItems = [
    {
      name: 'Name',
      value: 'partnerName',
    },
    {
      name: 'Email',
      value: 'email',
    },
    {
      name: 'Mobile',
      value: 'mobile',
    },
  ];

  const onChange = async (page, pageSize) => {
    setPageSize(pageSize);
    setCurrentPage(page);
    if (!page) page = 1;
    let limit = pageSize;

    try {
      let reqObj;
      if (Object.keys(searchShow).length !== 0) {
        console.log('searchShow ', searchShow);
        reqObj = {
          limit: limit,
          offset: limit * (page - 1),
          searchBy: searchShow.searchBy,
          searchTxt: searchShow.searchTxt,
        };
      } else {
        reqObj = {
          limit: limit,
          offset: limit * (page - 1),
          searchBy: 'partnerName',
          searchTxt: '',
        };
      }

      await partnerListingApi(reqObj);
    } catch (e) {
      console.log('Error');
      if (e.message) setErr(e.message);
      setTableShow(false);
    }
  };

  const onFinish = async (values) => {
    console.log(values);
    setSearchShow(values)
    let formData = {
      limit: 10,
      offset: 0,
      searchBy: values.searchBy,
      searchTxt: values.searchTxt,
    };
    if (!formData.searchTxt) message.error('Please enter search text');
    else await partnerListingApi(formData);
  };
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const viewStores = async (id) => {
    try {
      const resp = await axios.get(`${app.URL}/api/v1/admin/partner/partnerstoreview/${id}`, { headers: getHeaders() });
      resp.data.result.data ? setStoresList(resp.data.result.data) : ' ';
      setShow(true);
    } catch (error) {
      message.error('Error in getting partner stores');
      console.log(error);
    }
  };

  const editPartner = (e) => {
    localStorage.setItem('editPartnerId', JSON.stringify(e.partnerId));
    navigate('/partner/update', { replace: true });
  };
  const addPartner = () => {
    navigate('/partner/create', { replace: true });
  };
  const handleSearchChange = async (e) => {
    if (!e.target.value) {
      try {
        let searchData = {
          limit: pageSize,
          offset: 0,
          searchBy: 'partnerName',
          searchTxt: '',
        };
        await partnerListingApi(searchData);
        setSearchShow({});
      } catch (e) {
        if (e.message) setErr(e.message);
        setTableShow(false);
      }
    }
  };

  const handleOnChange = (e) => {
    if (e.target.value.length === 0) {
      userList();
      setSearchShow({});
    }
  };
  return (
    <>
      <ListinngCard
        heading='Partner Management'
        buttonName='Add Partner'
        addButton={addPartner}
        onSearch={onFinish}
        onSearchFailed={onFinishFailed}
        searchItems={searchItems}
        tableHeaders={tableHeaders}
        tableRows={renderList}
        editButton={editPartner}
        onPageChange={onChange}
        currentPage={currentPage}
        pagesCount={pagesCount}
        patientsListing={false}
        viewStores={viewStores}
        partnerListings={true}
        inventoryListing={false}
        onChange={handleOnChange}
        loader={loader}
      />
      {/* Modal for Show Partner Stores */}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Partner Stores</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            {storesList.length > 0 ? (
              <table id='customers'>
                <thead>
                  <tr>
                    <th>{t('Store ID')}</th>
                    <th>{t('Store Name')}</th>
                  </tr>
                </thead>
                <tbody>
                  {storesList.length > 0
                    ? storesList.map((store) => (
                        <tr>
                          <td>{store.storeId ? store.storeId : 'null'}</td>
                          <td>{store.partnerStoreName ? store.partnerStoreName : 'null'}</td>
                        </tr>
                      ))
                    : ''}
                </tbody>
              </table>
            ) : (
              'No stores availaible for this partner.'
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' className='btn' onClick={handleClose}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default PartnersListing;
