import { BranchesOutlined, EditOutlined, EditTwoTone } from '@ant-design/icons';
import { Card, Col, Form, Input, Pagination, Switch, Row, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AddNewImg from '../assets/images/add-new.png';
import editBtnImg from '../assets/images/edit.png';
import lockIcon from '../assets/images/lock.png';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { app } from '../config';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import getHeaders from '../utils/authHeaders';
import moment from 'moment';
import { useForm } from 'antd/lib/form/Form';
import TextArea from 'antd/lib/input/TextArea';
import { SearchBar } from './SearchBar';
import LoaderImage from '../pages/LoaderImage';
import NoDataFound from './NoDataFound';
import { Checkbox } from 'antd';
import TestComponent from '../pages/Products/TestComponent';

const inventoryListing = (tableValues) => {
  const [show, setShow] = useState(false);
  const [lockModalShow, setLockModalShow] = useState(false);
  const [delBatchResp, setDelBatchResp] = useState({});
  const [lockBatchResp, setLockBatchResp] = useState({});
  const handleClose = () => setShow(false);
  const handleLockClose = () => setLockModalShow(false);
  let navigate = useNavigate();
  const viewBatchDetails = (data) => {
    console.log(data);
    localStorage.setItem('batchId', JSON.stringify(data.batchId));
    navigate('/inventory/batchreport');
  };
  const lockBatch = async (batchId) => {
    console.log('Consoling updated batch');
    console.log(batchId);
    const res = await axios.post(`${app.URL}/api/v1/admin/inventory/updatebatch`, { batchId: batchId }, { headers: getHeaders() });
    console.log(res);
    if (res && res.data.result.success) {
      console.log(res.data.result);
      // setUpd(res.data.result)
      // setShow(false)
      window.location.reload();
    }
  };
  const deleteBatch = async (batchId) => {
    console.log('Consoling batch deleted');
    console.log(batchId);
    const res = await axios.delete(`${app.URL}/api/v1/admin/inventory/delete/${batchId}`, { headers: getHeaders() });
    console.log(res);
    if (res && res.data.result.success) {
      console.log(res.data.result);
      setLockBatchResp(res.data.result);
      setShow(false);
      window.location.reload();
    }
  };
  return (
    <>
      <td onClick={() => viewBatchDetails(tableValues)} style={{ width: '300px' }}>
        {tableValues.batchId ? tableValues.batchId : 'null'}
      </td>
      <td onClick={() => viewBatchDetails(tableValues)} style={{ width: '500px' }}>
        {tableValues.issueDate ? tableValues.issueDate : 'null'}
      </td>
      <td onClick={() => viewBatchDetails(tableValues)} style={{ width: '300px' }}>
        {tableValues.ProductName ? tableValues.ProductName : 'null'}
      </td>
      <td onClick={() => viewBatchDetails(tableValues)} style={{ width: '250px', textAlign: 'center' }}>
        {tableValues.availableQty ? tableValues.availableQty : 'null'}
      </td>
      <td onClick={() => viewBatchDetails(tableValues)} style={{ width: '300px' }}>
        {tableValues.partnerName ? tableValues.partnerName : 'null'}
      </td>
      {tableValues.isLocked ? (
        <>
          <td></td>
          <td>
            <img style={{ width: '25px', height: '25px', textAlign: 'center' }} src={lockIcon} alt='lockicon' />
          </td>
        </>
      ) : (
        <td style={{ width: '80px' }}>
          {lockBatchResp.code === 200 ? (
            <Modal show={lockModalShow} onHide={handleLockClose}>
              <Modal.Header closeButton>
                <Modal.Title className='text-center'>Success</Modal.Title>
              </Modal.Header>
              <Modal.Body>Batch Locked successfully</Modal.Body>
              <Modal.Footer></Modal.Footer>
            </Modal>
          ) : lockBatchResp.code === 400 ? (
            <Modal show={lockModalShow} onHide={handleLockClose}>
              <Modal.Header closeButton>
                <Modal.Title className='text-center'>Failed</Modal.Title>
              </Modal.Header>
              <Modal.Body>{lockBatchResp.data.message}</Modal.Body>
              <Modal.Footer></Modal.Footer>
            </Modal>
          ) : (
            <Modal show={lockModalShow} onHide={handleLockClose}>
              <Modal.Header closeButton>
                <Modal.Title>Confirmation</Modal.Title>
              </Modal.Header>
              <Modal.Body>Are you sure, you want to lock the Batch?</Modal.Body>
              <Modal.Footer>
                <Button variant='secondary' onClick={handleLockClose}>
                  Cancel
                </Button>
                <Button
                  variant='primary'
                  htmlType='submit'
                  onClick={() => {
                    lockBatch(tableValues.batchId);
                  }}
                >
                  Confirm
                </Button>
              </Modal.Footer>
            </Modal>
          )}
          <button class='btn' onClick={() => setLockModalShow(true)}>
            Lock{' '}
          </button>
        </td>
      )}
      {tableValues.isLocked ? (
        ' '
      ) : (
        <td style={{ width: '80px' }}>
          {delBatchResp.code === 200 ? (
            <Modal show={show} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title className='text-center'>Success</Modal.Title>
              </Modal.Header>
              <Modal.Body>Batch Deleted successfully</Modal.Body>
              <Modal.Footer></Modal.Footer>
            </Modal>
          ) : delBatchResp.code === 400 ? (
            <Modal show={show} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title className='text-center'>Failed</Modal.Title>
              </Modal.Header>
              <Modal.Body>{delBatchResp.data.message}</Modal.Body>
              <Modal.Footer></Modal.Footer>
            </Modal>
          ) : (
            <Modal show={show} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>Confirmation</Modal.Title>
              </Modal.Header>
              <Modal.Body>Are you sure, you want to delete the Batch?</Modal.Body>
              <Modal.Footer>
                <Button variant='secondary' onClick={handleClose}>
                  Cancel
                </Button>
                <Button
                  variant='primary'
                  htmlType='submit'
                  onClick={() => {
                    deleteBatch(tableValues.batchId);
                  }}
                >
                  Confirm
                </Button>
              </Modal.Footer>
            </Modal>
          )}

          <button class='btn' onClick={() => setShow(true)}>
            Delete
          </button>
        </td>
      )}

      {/* <td style={{ width: '100px' }}>{tableValues.isActive === '1' ? 'Active' : 'Inactive'}</td> */}
    </>
  );
};

const partnerListing = (tableValues, viewStores) => {
  return (
    <>
      {/* <td>{tableValues.partnerId}</td> */}
      <td className='px-1'>{tableValues.partnerName} </td>
      <td>{tableValues.email}</td>
      <td>{tableValues.mobile}</td>
      <td>{tableValues.streetAddress}</td>
      <td>{tableValues.cityName ? tableValues.cityName : 'null'}</td>
      <td>{tableValues.stateName ? tableValues.stateName : 'null'}</td>
      <td>{tableValues.isActive === '1' ? 'Active' : 'Inactive'}</td>
      <td>
        <BranchesOutlined displayName='view stores' title='View Stores' style={{ fontSize: '20px', display: 'inline-block', width: '50%', paddingRight: '10px' }} onClick={() => viewStores(tableValues.partnerId)}>
          View Stores
        </BranchesOutlined>
      </td>
    </>
  );
};

const userListing = (tableValues) => {
  return (
    <>
      {/* <td>{tableValues.userId}</td> */}
      <td>
        {tableValues.firstName} {tableValues.lastName}
      </td>
      <td>{tableValues.email}</td>
      <td>{tableValues.phone}</td>
      <td>{tableValues.roleName}</td>
      <td>{tableValues.isActive == '1' ? 'Active' : 'Inactive'}</td>
    </>
  );
};
const FaqDetailsListing = (tableValues) => {
  return (
    <>
      <td>{tableValues.question}</td>
      <td
        dangerouslySetInnerHTML={{
          __html: tableValues.answer,
        }}
      ></td>
      <td>{tableValues.index}</td>

      <td>{tableValues.isActive == '1' ? 'Active' : 'Inactive'}</td>
    </>
  );
};
const patientsListing = (tableValues) => {
  return (
    <>
      {/* <td>{tableValues.patientId}</td> */}
      <td>
        {tableValues.firstName} {tableValues.lastName}
      </td>
      <td>{tableValues.streetAddress}</td>
      <td>{tableValues.city}</td>
      <td>{tableValues.email}</td>
      <td>{tableValues.mobile}</td>
      <td>{tableValues.isActive == '1' ? 'Active' : 'Inactive'}</td>
    </>
  );
};
const categoryListing = (tableValues) => {
  return (
    <>
      {/* <td>{tableValues.productCategoryId}</td> */}
      <td>
        {tableValues.firstName} {tableValues.name}
      </td>
      <td>{tableValues.description}</td>
      <td>{tableValues.isActive === '1' ? 'Active' : 'Inactive'}</td>
    </>
  );
};
const configurationListing = (tableValues) => {
  return (
    <>
      {/* <td>{tableValues.prodConfigId}</td> */}
      <td>
        {tableValues.firstName} {tableValues.partnerName}
      </td>
      <td>{tableValues.productName}</td>
      <td>{tableValues.serialNo}</td>
    </>
  );
};

const storeListing = (tableVlaues) => {
  return (
    <>
      {/* <td>{tableVlaues.storeId ? tableVlaues.storeId : 'null'}</td> */}
      <td>{tableVlaues.name ? tableVlaues.name : 'null'}</td>
      <td>{tableVlaues.createdBy}</td>
      <td>{tableVlaues.isActive === '1' ? 'Active' : 'Inactive'}</td>
    </>
  );
};

const productListing = (tableValues) => {
  console.log(tableValues);
  return (
    <>
      {/* <td>{tableValues.productId ? tableValues.productId : 'null'}</td> */}
      <td>{tableValues.productCode ? tableValues.productCode : 'null'}</td>
      <td>{tableValues.name ? tableValues.name : 'null'}</td>
      {/* <td>{tableValues.productCategoryName ? tableValues.productCategoryName : 'null'}</td> */}
      <td>{(tableValues?.isActive && !tableValues?.isCommingSoon && !tableValues?.inValidation ? 'Active' : '') || (tableValues?.isActive && tableValues?.isCommingSoon ? 'Comming Soon' : '') || (tableValues?.isActive && tableValues?.inValidation ? 'In Validation' : '') || (!tableValues?.isActive ? 'Inactive' : '')}</td>
    </>
  );
};
const stockBalanceListing = (tableValues) => {
  return (
    <>
      <td>{tableValues.productCode ? tableValues.productCode : 'null'}</td>
      <td>{tableValues.productName ? tableValues.productName : 'null'}</td>
      <td>{tableValues.stockBalance ? tableValues.stockBalance : 'null'}</td>
    </>
  );
};
const BlogsListing = (tableValues) => {
  return (
    <>
      <td>{tableValues.title ? tableValues.title : 'null'}</td>
      <td

      // dangerouslySetInnerHTML={{
      //   __html: tableValues.description,
      // }}
      >
        {tableValues.description ? tableValues.description : 'null'}
      </td>
      {/* <td>{tableValues.isActive ? tableValues.isActive : 'null'}</td> */}

      <td>{tableValues.isActive == true ? 'Active' : 'Inactive'}</td>
    </>
  );
};
const inventoryBatchListing = (tableValues) => {
  return (
    <>
      {/* <td>{tableValues.productId ? tableValues.productId : 'null'}</td> */}
      <td>{tableValues.batchId ? tableValues.batchId : 'null'}</td>
      <td>{tableValues.issueDate ? tableValues.issueDate : 'null'}</td>
      <td>{tableValues.name ? tableValues.name : 'null'}</td>
      <td>{tableValues.serialNo ? tableValues.serialNo : 'null'}</td>
      <td>{tableValues.partnerName ? tableValues.partnerName : 'null'}</td>
      {/* <td style={{ width: '100px' }}>{tableValues.isActive === '1' ? 'Active' : 'Inactive'}</td> */}
    </>
  );
};

const ethnicListing = (tableValues) => {
  return (
    <>
      {/* <td >{tableValues.ethnicId ? tableValues.ethnicId : 'null'}</td> */}
      <td>{tableValues.verticleType ? tableValues.verticleType : 'null'}</td>
      <td>{tableValues.description ? tableValues.description : 'null'}</td>
      <td>{tableValues.isActive == '1' ? 'Active' : 'Inactive'}</td>
    </>
  );
};

const roleListing = (tableValues) => {
  return (
    <>
      <td>{tableValues.name ? tableValues.name : 'null'}</td>
      {/* <td>{tableValues.roleType === 'S' ? 'Super Admin' : tableValues.roleType === 'A' ? 'Admin' : 'Organizations'}</td> */}
      <td>{tableValues.isActive === '1' ? 'Active' : 'Inactive'}</td>
    </>
  );
};

const bannerListing = (tableValues) => {
  return (
    <>
      <td>{tableValues.bannerName ? tableValues.bannerName : 'null'}</td>
      <td>{tableValues.productName ? tableValues.productName : 'null'}</td>
      <td>{tableValues.createdAt ? tableValues.createdAt : 'null'}</td>
      <td>{tableValues.isActive === 1 ? 'Active' : 'Inactive'}</td>
    </>
  );
};

const feedbackListing = (tableValues, setFeedbackDetail, setShowDetailModal) => {
  const handleOpenExternalLink = (url) => {
    // Open the external URL in a new tab or window
    window.open(url, '_blank');
  };
  const onOrderDetailClick = (data) => {
    console.log(data);
    setFeedbackDetail(() => data);
    setShowDetailModal(true);
  };

  return (
    <>
      <td style={{ width: '180px' }}>
        <a>{tableValues.createdAt ? moment(tableValues.createdAt).format('MM-DD-YYYY hh:mm A') : 'NULL'}</a>
      </td>
      <td>
        <a>{tableValues.userName ? tableValues.userName : 'null'}</a>
      </td>
      <td>
        <a>{tableValues.screenName ? tableValues.screenName : 'null'}</a>
      </td>
      <td>
        <a>{tableValues.feedbackType ? tableValues.feedbackType : 'null'}</a>
      </td>
      <td>
        <a>{tableValues.title ? tableValues.title : 'null'}</a>
      </td>
      <td>
        {tableValues.description
          ? tableValues.description.length > 30 // Set your desired character limit
            ? tableValues.description.substring(0, 30) + '...' // Truncate the text
            : tableValues.description
          : 'null'}
      </td>
      <td>{tableValues.devComments ? tableValues.devComments : 'null'}</td>
      <td>
        <a>{tableValues.status ? tableValues.status : 'null'}</a>
      </td>

      {/* <td>
        <button className='btn button btn-primary' size={'small'} onClick={() => onOrderDetailClick(tableValues)}>
          View
        </button>
      </td> */}
      <td>
        {tableValues.attachment ? (
          <button className='btn button btn-primary' size={'small'} onClick={() => handleOpenExternalLink(tableValues.attachment)}>
            Download
          </button>
        ) : (
          ''
        )}
      </td>
    </>
  );
};

const anuraListing = (tableValues) => {
  return (
    <>
      {/* <td>{tableValues.anuraParamId}</td> */}
      <td>{tableValues.anuraParamName}</td>
      <td>{tableValues.anuraGroup}</td>
      <td>{tableValues.displayPosition}</td>
      <td>{tableValues.unit}</td>
      <td>{tableValues.isActive ? 'Active' : 'Inactive'}</td>
    </>
  );
};

const testResListing = (tableValues) => {
  return (
    <>
      <td>{tableValues.resultElement}</td>
      <td>{tableValues.cisPanelName}</td>
      {/* <td>{tableValues.resultElementGroupId}</td> */}
      <td>{tableValues.displayPosition}</td>
      <td>{tableValues.isActive ? 'Active' : 'Inactive'}</td>
    </>
  );
};

const labTestsGroupListing = (tableValues) => {
  return (
    <>
      <td>{tableValues.cisPanelCode}</td>
      <td>{tableValues.cisPanelName}</td>
      <td>{tableValues.isActive ? 'Active' : 'Inactive'}</td>
    </>
  );
};

const boxListing = (tableValues) => {
  console.log(tableValues);
  return (
    <>
      <td>{tableValues.type ? tableValues.type : 'null'}</td>
      <td>{tableValues.isActive == '1' ? 'Active' : 'Inactive'}</td>
      <td>{tableValues.createdAt ? tableValues.createdAt : 'null'}</td>
      <td>{tableValues.updatedAt ? moment(tableValues.updatedAt).format('MM-DD-YYYY') : 'null'}</td>
    </>
  );
};

const RequestBoxIngListing = (tableValues) => {
  console.log(tableValues);
  return (
    <>
      <td> {tableValues.name} </td>
      <td> {tableValues.SKU} </td>
      {/* <td>
        <div
          style={{
            width: '60px',
            height: '60px',
            display: 'flex',
            alignItems: 'center',
            justifyContent:'center'
            // padding: "5px",
          }}
        >
          <img
            style={{
              width: '100%',
              
            }}
            src={tableValues.image}
          />
        </div>
      </td> */}
      <td> {tableValues.description} </td>
      <td> {tableValues.isShowImage ? 'Show' : 'Hide'} </td>
      <td>{tableValues.isActive ? 'Active' : 'Inactive'}</td>
    </>
  );
};

const questionnaireListing = (tableValues) => {
  // console.log(tableValues);
  return (
    <>
      <td>{tableValues.productName ? tableValues.productName : 'null'}</td>
      <td>{tableValues.testName ? tableValues.testName : 'null'}</td>
    </>
  );
};

const RequestButtonListing = (tableValues) => {
  // console.log(tableValues);
  return (
    <>
      <td>{tableValues.buttonName ? tableValues.buttonName : 'null'}</td>
      <td>{tableValues.requestImage == '1' ? 'True' : 'False'}</td>
      <td>{tableValues.requestQuestion == '1' ? 'True' : 'False'}</td>
    </>
  );
};

const FaqListing = (output, faqDetailNavigate) => {
  return (
    <>
      <td>{output.name}</td>
      <td>{output.noOfQuestions}</td>
      <td>{output.index}</td>
      <td>{output.isActive == 1 ? 'Active' : 'InActive'}</td>
      <td>
        <div className='d-flex align-items-center justify-content-center'>
          <Button onClick={() => faqDetailNavigate(output)}>Detail</Button>
        </div>
      </td>
    </>
  );
};

const ListinngCard = (props) => {
  const { t } = useTranslation(['common']);
  let location = useLocation();
  const [isWrite, setIsWrite] = useState(true);
  console.log(props);
  console.log(props.tableRows);
  const [feedbackDetail, setFeedbackDetail] = useState({});
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [form] = useForm();
  const navigate = useNavigate();

  const handleModalClose = () => {
    setShowDetailModal(false);
    setFeedbackDetail({});
  };
  console.log('Edit Obj in Listing card', props.editObj);
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    const [filteredRoute] = user.result.data.permission.filter((item) => item.action === location.pathname.slice(1));
    setIsWrite(filteredRoute.isWrite);
  }, []);

  return (
    <div className={`dashboard-card pb-5 ${location.pathname === '/feedback' ? 'container' : 'container-fluid'}`}>
      <div className='row p-0 pb-4'>
        <div className='for-before col-12 col-md-7 head fs-3 fw-bold' style={{ backgroundColor: '#a7c1d9', borderBottomRightRadius: '10px', color: '#FFFFFF' }}>
          {props.heading}
        </div>
        {!props.inventoryBatchListing && !props.anuraListing && !props.stockBalanceListing && !props.testResultListing && !props.FaqDetails ? (
          <div className='col-12 col-md-5 d-flex justify-content-center'>
            <div onClick={isWrite && props.addButton} className={`icon-btn ${!isWrite && 'disabled'} `}>
              <img src={AddNewImg} />
              <p>{props.buttonName}</p>
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
      {/* <SearchBar onFinish={props.onSearch} onFinishFailed={props.onSearchFailed} searchItems={props.searchItems} onChange={props.onChange} /> */}
      {console.log('props.searchItems', !props.searchItems)}
      {/* {!props.searchItems ? '' :  */}
      {/* `${ */}
      {props.stockBalanceListing || props.BlogsListing || props.FaqDetails ? '' : <SearchBar onFinish={props.onSearch} onFinishFailed={props.onSearchFailed} searchItems={props.searchItems} onChange={props.onChange} />}
      {/* }`} */}
      {props.faqQuestions}
      <div className='table-scrollbar'>
        {props.loader && <LoaderImage />}
        {props.tableRows && props.tableRows.length && !props.loader > 0 ? (
          <table id='customers' className='w-100'>
            <thead>
              <tr>
                {props.tableHeaders.map((header) => (
                  <th className='px-3'>{header}</th>
                ))}
              </tr>
            </thead>
            <tbody className='w-100'>
              {props.tableRows.map((output, index) => (
                <tr>
                  {' '}
                  {(() => {
                    if (props.inventoryListing) {
                      return inventoryListing(output);
                    } else if (props.partnerListings) {
                      return partnerListing(output, props.viewStores);
                    } else if (props.FaqDetails) {
                      return FaqDetailsListing(output);
                    } else if (props.inventoryBatchListing) {
                      return inventoryBatchListing(output);
                    } else if (props.userListings) {
                      return userListing(output);
                    } else if (props.patientsListing) {
                      return patientsListing(output);
                    } else if (props.categoryListing) {
                      return categoryListing(output);
                    } else if (props.configurationListing) {
                      return configurationListing(output);
                    } else if (props.storeListing) {
                      return storeListing(output);
                    } else if (props.productListing) {
                      return productListing(output);
                    } else if (props.stockBalanceListing) {
                      return stockBalanceListing(output);
                    } else if (props.BlogsListing) {
                      return BlogsListing(output);
                    } else if (props.ethnicListing) {
                      return ethnicListing(output);
                    } else if (props.roleListing) {
                      return roleListing(output);
                    } else if (props.feedbackListing) {
                      return feedbackListing(output, setFeedbackDetail, setShowDetailModal);
                    } else if (props.bannerListing) {
                      return bannerListing(output);
                    } else if (props.anuraListing) {
                      return anuraListing(output);
                    } else if (props.testResultListing) {
                      return testResListing(output);
                    } else if (props.labTestsGroup) {
                      return labTestsGroupListing(output);
                    } else if (props.boxListing) {
                      return boxListing(output);
                    } else if (props.questionnaireListing) {
                      return questionnaireListing(output);
                    } else if (props.RequestButtonListing) {
                      return RequestButtonListing(output);
                    } else if (props.boxIngListing) {
                      return RequestBoxIngListing(output);
                    } else if (props.faqListing) {
                      return FaqListing(output, props.faqDetailNavigate);
                    }
                  })()}
                  {!props.inventoryBatchListing && !props.stockBalanceListing && (
                    <td className='edit-data'>
                      {/* <EditOutlined style={{ fontSize: '1.5rem', color: '#f2b544', cursor: 'pointer' }} onClick={() => props.editButton(output)} /> */}
                      <img className='cursor-pointer' style={{ width: '25px' }} alt='edit btn img' src={editBtnImg} onClick={() => props.editButton(output)} />
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <>{!props.loader && <NoDataFound />}</>
        )}
      </div>
      <Modal show={showDetailModal}>
        <Modal.Header closeButton>
          <Modal.Title>Feedback</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {feedbackDetail ? (
            <div className='row'>
              <Form
                form={form}
                name='basic'
                labelCol={{
                  span: 0,
                }}
                wrapperCol={{
                  span: 24,
                }}
                autoComplete='off'
                initialValues={
                  {
                    // ['nameOfScreen']: feedbackDetail.screenName,
                    // ['feedBackType']:feedbackDetail.feedbackType,
                    // ['feedBackTitle']:feedbackDetail.title,
                    // ['feedBackDescp']:feedbackDetail.description,
                  }
                }
              >
                <div className='col-lg-12'>
                  <label>Date</label>
                  <Form.Item label='Date' name='date' colon={false}>
                    <Input placeholder='Full Name' defaultValue={moment(feedbackDetail.createdAt).format('MM-DD-YYYY hh:mm A')} />
                  </Form.Item>
                </div>
                <div className='col-lg-12'>
                  <label>Full Name</label>
                  <Form.Item label='fullName' name='name' colon={false}>
                    <Input placeholder='Full Name' defaultValue={feedbackDetail.userName} />
                  </Form.Item>
                </div>
                <div className='col-lg-12'>
                  <label>Screen Name</label>
                  <Form.Item label='screenName' name='nameOfScreen' colon={false}>
                    <Input placeholder='Full Name' defaultValue={feedbackDetail.screenName} />
                  </Form.Item>
                </div>
                <div className='col-lg-12'>
                  <label>Type</label>
                  <Form.Item label='type' name='feedBackType' colon={false}>
                    <Input placeholder='Full Name' defaultValue={feedbackDetail.feedbackType} />
                  </Form.Item>
                </div>
                <div className='col-lg-12'>
                  <label>Title</label>
                  <Form.Item label='title' name='feedBackTitle' defaultValue='1' colon={false}>
                    <Input placeholder='Full Name' defaultValue={feedbackDetail.title} />
                  </Form.Item>
                </div>
                <div className='col-lg-12'>
                  <label>Description</label>
                  <Form.Item colon={false} label='description' name='feedBackDescp' autoComplete='off' autofill='off'>
                    <TextArea rows={3} placeholder='Enter Description' defaultValue={feedbackDetail.description} />
                  </Form.Item>
                </div>
              </Form>
            </div>
          ) : (
            ' '
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleModalClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <div className='row'>{props.currentPage && props.pagesCount ? <Pagination style={{ marginTop: '10px' }} onChange={props.onPageChange} defaultCurrent={props.currentPage} total={props.pagesCount} showTotal={(total) => `Total ${total} items`} showSizeChanger /> : ' '}</div>
    </div>
  );
};

export default ListinngCard;
