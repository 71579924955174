import React from 'react'
import EcommerceDashboardCard from '../../layouts/EcommerceDashboardCard';

const EcommerceCards = ({ status, onCardViewClick }) => {
  return (
    <div className='row px-3 d-flex justify-content-center'>
      <div style={{ position: 'relative' }} className='col-md-2 col-12 my-1' onClick={() => onCardViewClick(1)}>
        <EcommerceDashboardCard count={status.length > 1 ? status[1].count : 0} isActive={window.location.pathname == '/ecom/dashboard/inprogress' || window.location.pathname == '/ecom/dashboard'} activatedProducts={true} awaitingProducts={false} cardName={'In Progress'} />
      </div>
      <div style={{ position: 'relative' }} className='col-md-2 col-12 my-1' onClick={() => onCardViewClick(2)}>
        <EcommerceDashboardCard count={status.length > 1 ? status[2].count : 0} isActive={window.location.pathname == '/ecom/dashboard/intransit'} activatedProducts={true} awaitingProducts={false} cardName={'In Transit'} />
      </div>
      <div style={{ position: 'relative' }} className='col-md-2 col-12 my-1' onClick={() => onCardViewClick(3)}>
        <EcommerceDashboardCard count={status.length > 1 ? status[3].count : 0} isActive={window.location.pathname == '/ecom/dashboard/delivered'} activatedProducts={false} awaitingProducts={true} cardName={'Delivered'} />
      </div>
      <div style={{ position: 'relative' }} className='col-md-2 col-12 my-1' onClick={() => onCardViewClick(4)}>
        <EcommerceDashboardCard count={status.length > 1 ? status[status.length - 1].count : 0} isActive={window.location.pathname == '/ecom/dashboard/request'} activatedProducts={false} awaitingProducts={false} cardName={'Request'} />
      </div>
      <div style={{ position: 'relative' }} className='col-md-2 col-12 my-1' onClick={() => onCardViewClick(5)}>
        <EcommerceDashboardCard count={status.length > 1 ? status[4].count : 0} isActive={window.location.pathname == '/ecom/dashboard/cancel'} activatedProducts={false} awaitingProducts={false} cardName={'Cancelled / Refund'} />
      </div>
    </div>
  );
};

export default EcommerceCards;