import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
// import { useTranslation } from 'react-i18next';
import { message, Switch, Select, Form, Input, Row, Col, DatePicker, Upload, Space, Button } from 'antd';
// import Button from 'react-bootstrap/Button';
// import Modal from 'react-bootstrap/Modal';
import { app } from '../../config';
// import useFetchRoles from '../../hooks/fetchRoles';
import { InfoCircleOutlined, LoadingOutlined, MinusCircleOutlined, PlusCircleOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
// import InputMask from 'react-input-mask';
import getHeaders from '../../utils/authHeaders';
// import useFetchCities from '../../hooks/fetchCities';
// import useFetchStates from '../../hooks/fetchStates';
// import useFetchStores from '../../hooks/fetchStores';
// import AddImg from '../../assets/images/new-add.png';
// import AddPhoto from '../../assets/images/partner.png';
// import useFetchProducts from '../../hooks/fetchProducts';
// import getBanner from '../../hooks/getBanner';
import getBoxType from '../../hooks/getBoxType';
import AddPhoto from '../../assets/images/add.png';

import TextArea from 'antd/lib/input/TextArea';
import { UpdateRecord } from '../../layouts/UpdateRecord';
import TagInput from '../Products/TagInput';
import getSampleVideoType from '../../hooks/getSampleVideoType';
import { InputField, SelectField, TextAreaField } from '../../layouts/FormFields';

const UpdateBoxType = () => {
  // const { t } = useTranslation(['common']);
  const boxTypeId = localStorage.getItem('boxTypeId');
  console.log(boxTypeId);

  const [tags, setTags] = useState([]);
  const [addUserResp, setAddUserResp] = useState({});
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [uploadVideo, setuploadVideo] = useState('');
  // const [uploadImage, setuploadImage] = useState('');
  const [contentType, setContentType] = useState();
  const [uplVid, setuplVid] = useState(true);
  // const [imageuploading, setimageuploading] = useState(false);

  const bannerResp = getBoxType(boxTypeId, setuploadVideo);
  const bannerObj = bannerResp.response ? bannerResp.response.data : {};

  useEffect(() => {
    console.log(bannerObj.sampleType);
    bannerObj.sampleType && setTags(bannerObj.sampleType);
  }, [bannerObj]);
  console.log(tags);
  const sampleVideoRes = getSampleVideoType();
  const sampleVideo = sampleVideoRes.response ? sampleVideoRes.response : [];

  let navigate = useNavigate();

  const [uploadObj, setUploadObj] = useState({});

  function goBack() {
    navigate('/boxtype/list', { replace: true });
  }
  window.addEventListener('popstate', goBack);

  const user = JSON.parse(localStorage.getItem('user'));

  const uploadData = async (userDetails) => {
    setLoading(true);
    let userId = user?.result.data.user.userId;
    userDetails.userId = userId;
    console.log(userDetails,"USer details")
  
    try {
      const res = await axios.post(`${app.URL}/api/v1/admin/boxtype/update/${boxTypeId}`, userDetails, { headers: getHeaders() });
      setLoading(false);
      setShow(false);

      if (res.data.result.success) {
        setAddUserResp(res.data.result.data);
        message.success(res.data.result.msg);
        setTimeout(goBack, 500);
      } else if (!res.data.result.success) {
        message.warning(res.data.result.msg);
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
      setShow(false);

      if (err) console.log(err.response.data.message);
      else return;
    }
  };
// const onFinish =(values)=>{
//   console.log('values',values)
// }
  
   const onFinish = (values) => {
     console.log(values, 'values');
      const payload ={
        ...values,
        videoUrl: uploadVideo? uploadVideo:""
      }
     if (Object.keys(payload).length > 0) {
       uploadData(payload);
     }
   };

  let headers = getHeaders();
  // const handleChange = (event) => {
  //   setPhone(event.target.value);
  // };
  const submitData = () => {
    return
  };
  const handleClose = () => setShow(false);
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    // reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  };
  const beforeUpload = (file, e) => {
    console.log(file);

    if (file.type === 'image/jpeg' || file.type === 'image/png') {
      console.log('setting image type');
      setContentType('img');
    } else if (file.type === 'video/x-matroska' || file.type === 'video/mp4') setContentType('video');
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'video/x-matroska' || file.type === 'video/mp4';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    console.log('file Checked');
    const isLt2M = file.size / 1024 / 1024 < 20;
    if (!isLt2M) {
      setLargeFile(true);
      message.error('Image must smaller than 20MB!');
    }
    console.log('message');
    return isJpgOrPng && isLt2M;
  };

  const handleuploadChange = (info) => {
    setuploadVideo('');

    console.log(info);
    if (info.file.status === 'uploading') {
      return;
    }
    if (info.file.status === 'done') {
      info.file ? console.log(info.file.response.result.data.Location) : console.log('Not found');
      info.file ? setuploadVideo(info.file.response.result.data.Location) : ' ';
      getBase64(info.file.originFileObj, (url) => {
        setuplVid(false);
      });
    }
  };

  // const handleuploadImage = (info) => {
  //   if (info.file.status === 'uploading') {
  //     setimageuploading(true);
  //   }
  //   if (info.file.status === 'done') {
  //     // Get this url from response in real world.
  //     info.file ? console.log(info.file.response.result.data.Location) : console.log('Not found');
  //     info.file ? setuploadImage(info.file.response.result.data.Location) : ' ';
  //     setimageuploading(false);
  //   }
  // };

  const [videoNote, showVideoNote] = useState(false);

  const boxToggleVideo = () => {
    setuploadVideo('');
    setTimeout(() => setuploadVideo(bannerObj.videoUrl), 500);
  };
console.log("Banner Obj",bannerObj)
  return (
    <UpdateRecord
      loading={loading}
      // videoUrl={uploadVideo}
      pageTitle='Update Box Type'
      response={bannerResp.response}
      addUserResp={addUserResp}
      updateMessage='Are you sure you want to update box type?'
      initialValues={{
        remember: true,
        ['boxType']: bannerObj?.type ? bannerObj?.type : '222',
        ['description']: bannerObj?.description ? bannerObj?.description : '',
        ['noOfSteps']: bannerObj?.noOfSteps ? bannerObj?.noOfSteps : 0,
        ['isActive']: bannerObj?.isActive ? bannerObj?.isActive : false,
        // ['showImage']: bannerObj?.showImage ? bannerObj?.showImage : null,
        ['verificationCount']: bannerObj?.verificationCount ? bannerObj?.verificationCount : 2,
        ['boxIngredients']: bannerObj?.boxTypeIngredients?.map((item) => {
          return {
            ingredientId: item.ingredientId,
            quantity: item.quantity,
          };
        }),
      }}
      inputs={[
        // <div className='col-lg-6'>
        //   <label>
        //     Box Type<span className='required-star'>*</span>
        //   </label>
        //   <Form.Item
        //     name='boxType'
        //     colon={false}
        //     rules={[
        //       {
        //         required: true,
        //         message: 'Enter Box Type',
        //       },
        //     ]}
        //   >
        //     <Input placeholder='Box Type' />
        //   </Form.Item>
        // </div>,
        <InputField
          label='Box Type'
          name='boxType'
          col='col-lg-2 col-md-3 col-6'
          required={true}
          placeholder='Box Type'
          rules={[
            {
              required: true,
              message: 'Enter Box Type',
            },
          ]}
        />,
        <InputField
          label='No. of Steps'
          name='noOfSteps'
          col='col-lg-2 col-md-3 col-6'
          required={true}
          placeholder='No. of Steps'
          rules={[
            {
              required: true,
              message: 'Enter No. of Steps',
            },
          ]}
        />,
        <SelectField
          label='No. of Prints'
          name='verificationCount'
          col='col-lg-2 col-md-3 col-6'
          required={true}
          placeholder='No. of Prints'
          options={[2, 3].map((item) => (
            <Select.Option value={item}>{item}</Select.Option>
          ))}
          rules={[
            {
              required: true,
              message: 'Select No. of Steps',
            },
          ]}
        />,
        <div className='col-12'>
          <div className='row'>
            <div className='col-md-8'>
              <div className='row'>
                <Form.List name='boxIngredients'>
                  {(fields, { add, remove }, { errors }) => (
                    <>
                      {console.log('Fields', fields)}
                      {fields.map((field, index, ...restField) => (
                        // <Space className='col-lg-12' key={field.key}>
                        <>
                          <Form.Item required={false} key={field.key}>
                            <div className='row'>
                              <Form.Item
                                className='col-md-4'
                                {...field}
                                validateTrigger={['onChange', 'onBlur']}
                                // noStyle
                              >
                                {/* <label> Box ingredients </label>
                                <Select placeholder={`Box Ingredient ` + (index + 1)}>
                                  {sampleVideo.map((item) => (
                                    <Select.Option value={item.sampleType}>{item.sampleType}</Select.Option>
                                  ))}
                                </Select> */}
                                <SelectField
                                  label='Box Ingredient'
                                  name={[field.name, 'ingredientId']}
                                  // name='sampleType'
                                  // col='col-lg-2 col-md-3 col-6'
                                  required={true}
                                  placeholder='Box Ingredient'
                                  options={sampleVideo.map((item) => (
                                    <Select.Option value={item.ingredientId}>{item.name}</Select.Option>
                                  ))}
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Select Box Ingredient',
                                    },
                                  ]}
                                />
                                ,
                              </Form.Item>
                              <InputField
                                label='Quantity'
                                required={true}
                                col='col-md-4'
                                name={[field.name, 'quantity']}
                                rules={[
                                  {
                                    required: true,
                                    message: 'Enter Quantity',
                                  },
                                ]}
                                {...restField}
                                placeholder='Enter Quantity'
                              />
                              <Form.Item className='col-md-3 mt-4 '>
                                {fields.length > 0 && (
                                  <div className='add-btn' style={{ textAlign: 'center', backgroundColor: '#F21D2F' }} onClick={() => remove(field.name)}>
                                    Remove
                                  </div>
                                )}
                              </Form.Item>
                            </div>
                          </Form.Item>
                        </>

                        // </Space>
                      ))}

                      <div className='row px-2'>
                        <div style={{ textAlign: 'center' }} onClick={() => add()} className='col-lg-3 add-btn'>
                          Add
                        </div>
                      </div>
                      <Form.ErrorList errors={errors} />
                    </>
                  )}
                </Form.List>
                {/* <TagInput label='Box Ingredients' tags={tags} setTags={setTags} options={sampleVideo.map((item) => !tags.includes(item.sampleType) && <Select.Option value={item.sampleType}>{item.sampleType}</Select.Option>)} />, */}
              </div>
            </div>

            {/* <div className='col-md-4 text-center'>
              <Form.Item className='w-100 h-auto' valuePropName='fileList'>
                <Upload listType='picture-card' showUploadList={false} action={`${app.URL}/api/v1/general/banner/imageupload`} beforeUpload={beforeUpload} onChange={handleuploadImage} headers={headers}>
                  {uploadImage && !imageuploading ? (
                    <img className='added-img' src={uploadImage} alt='avatar' />
                  ) : !uploadImage && imageuploading ? (
                    <LoadingOutlined style={{ fontSize: '40px' }} />
                  ) : (
                    <div className='d-flex flex-column py-3'>
                      <img className='for-add-img' src={AddPhoto} alt='avatar' />
                      <p className='add-text'>Upload Image</p>
                    </div>
                  )}
                </Upload>

                {uploadImage && !imageuploading && (
                  <button onClick={() => setuploadImage('')} style={{ backgroundColor: 'rgb(242, 29, 47)' }} className='add-btn'>
                    Remove
                  </button>
                )}
              </Form.Item>
              <Form.Item colon={false} valuePropName='checked' defaultValue={true} label='Show Image' name='showImage'>
                <Switch />
              </Form.Item>
            </div> */}
          </div>
        </div>,

        <TextAreaField
          name='description'
          label='Description'
          col='col-lg-8 col-md-6 col-12'
          colon={false}
          rules={[
            {
              required: true,
              message: 'Enter Description',
            },
          ]}
          rows={6}
          showCount={true}
          maxLength={2500}
          placeholder='Box Description'
        />,

        <div className='col-lg-4 col-md-6 col-12 mb-lg-0 mb-2 '>
          {uploadVideo && <label>Video</label>}
          {uploadVideo && (
            <video style={{ width: '100%', borderRadius: '5px', boxShadow: '3px 3px 10px #ccc' }} controls title='YouTube video player'>
              <source src={uploadVideo} type='video/mp4'></source>
            </video>
          )}
          <div className='d-flex mb-5'>
            <Form.Item colon={false} name='video' className='mt-2 mb-0 w-100'>
              <Space
                direction='vertical'
                style={{
                  width: '100%',
                }}
                size='large'
              >
                <Upload onRemove={boxToggleVideo} listType='picture' maxCount={1} className='avatar-uploader' action={`${app.URL}/api/v1/general/boxtype/videoUpload`} beforeUpload={beforeUpload} onChange={handleuploadChange}>
                  <Button icon={<UploadOutlined />} className={'upload-btn'} style={{ color: 'black' }}>
                    Upload Video
                  </Button>
                  {/* <div className='mt-2'>Note: Upload size for video is 50mb supported format for video are (mkv/mp4)</div> */}
                </Upload>
              </Space>
            </Form.Item>
            <InfoCircleOutlined onClick={() => showVideoNote(!videoNote)} onMouseEnter={() => showVideoNote(true)} onMouseLeave={() => showVideoNote(false)} className='mx-3' style={{ fontSize: '1rem' }} />
            {videoNote && <div className='mt-5 absolute'>Note: Upload size for video is 50mb supported format for video are (mkv/mp4)</div>}
          </div>
        </div>,
      ]}
      switchBtn={true}
      goBack={goBack}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      handleClose={handleClose}
      show={show}
      submitData={submitData}
    />
  );
};

export default UpdateBoxType;
