import React, { useState, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import { Pagination, Input, Form, Select, Row, Col, Space, Spin, message, Collapse, DatePicker, Button } from 'antd';
import axios from 'axios';
import { app } from '../../config';
import getHeaders from '../../utils/authHeaders';
import moment from 'moment';
import { dateConverter } from '../../utils/utcDateConversionCommon';
import { Loading3QuartersOutlined, LoadingOutlined } from '@ant-design/icons';
import SubOrderViews from './SubOrderViews';

const SubOrderViewModal = ({ viewOrder, isVisible, setIsVisible, ecomOrderNo, ecomOrderCreatedDate, title, status, address, productsData, shipmentCharges, totalShipmentAmount, tax, grandTotal, requestId, requestType, requestStatus, customerComment, image, shipmePackageName, addressId, ecomOrderId, isApiCall, wareHouseStatusId, subOrderObj, overallProductTotal, taxAmount,  locationURL, wareHouseStatus }) => {
  const commentTableHeaders = ['Date & Time', 'Comment By', 'Comment'];
  console.log('wareHouseStatus', wareHouseStatus);

  console.log('locationURL', locationURL);
  console.log('SubOrderObj in modal', subOrderObj);
  // console.log(ecomOrderId, addressId);

  const [commentsData, setCommentsData] = useState([]);
  const [addressIdd, setAddressId] = useState();
  const [commentsLoader, setCommentsLoader] = useState(false)
  
  // const [commentsObj, setCommentsObj] = useState({});
  // useEffect(() => {
  //   // setCommentsObj({
  //   //   ecomOrderId: ecomOrderId,
  //   //   addressId: addressId,
  //   // });
  // }, [ecomOrderId, addressId]);

  // console.log('commentsObj: ');
  // console.log(commentsObj);
  // useEffect(() => {
  //   const getAllSubOrderComments = async () => {
  //     // console.log('ecom', ecomOrderId);
  //     // console.log('address', addressId);
  //       if (viewOrder && !addressIdd) return;
  //   };

  //   console.log('call able');

  // if (addressId || addressIdd) {
  //   setTimeout(() => {
  //     getAllSubOrderComments();
  //   }, 2000);
  // }

  //   return () => {
  //     setCommentsData([]);
  //   };
  // }, [addressId, addressIdd]);

  useEffect(() => {
    const getAllSubOrderComments = async (addressIdd) => {
      // console.log('ecom', ecomOrderId);
      console.log('address', addressIdd);
      
      try {
        let resp = await axios.get(`${app.URL}/api/v1/admin/comment/viewbysuborder/${ecomOrderId}/${addressId ? addressId : addressIdd ? addressIdd : ''}`, { headers: getHeaders() });
        console.log('comments respo: ', resp.data.result.data);

        // let convertedObj=dateConverter(resp.data.result.data,"createdAt");
        setCommentsData(resp.data.result.data);
        
        console.log('comments data: ', commentsData);
      } catch (error) {
        console.log('error in getting comments data: ', error);
      }
    };

    console.log('call able');

   
      setTimeout(() => {
        getAllSubOrderComments();
      }, 2000);
 
 

    return () => {
      setCommentsData([]);
    };
  }, [addressId, addressIdd]);
  // let locationURLs = ['/ecom/shipment/prepare', '/ecom/shipment/labelprint', '/ecom/shipment/pickup'];
  // let result = locationURLs.find((ele) => ele == locationURL);
  // console.log(result);
  // console.log(locationURL !== '/ecom/shipment/prepare');
  // console.log(locationURL !== '/ecom/shipment/labelprint');
  // console.log(locationURL !== '/ecom/shipment/pickup');
  // console.log('Condition check');
  // console.log(locationURL !== '/ecom/shipment/labelprint' || locationURL !== '/ecom/shipment/prepare' || locationURL !== '/ecom/shipment/pickup');
  console.log('adressIdd', addressIdd);
  console.log('Location url', locationURL);
  return viewOrder ? (
    <Modal
      show={isVisible}
      size='lg'
      onHide={() => {
        setIsVisible(!isVisible);
      }}
      centered
    >
      <Modal.Header
        style={{
          display: 'block',
        }}
      >
        <Modal.Title style={{ display: 'block', padding: '9px 13px 8px 8px', fontSize: '20px' }}>
          <div className='row'>
            <div className='col-lg-6'>{ecomOrderNo}</div>
            <div className='col-lg-6 text-right'>{ecomOrderCreatedDate}</div>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ padding: '0px 20px !important' }}>
        {address &&
          address?.map((item, indx) => {
            return (
              <SubOrderViews
               ecomOrderId={ecomOrderId}
                item={item}
                indx={indx}
                locationURL={locationURL}
                addressId={item.addressId}
                commentTableHeaders={commentTableHeaders}
                commentsData={commentsData}
                // addressIdd={item.addressId}
              />
            )
           
          })}
        <hr />
        <div className='row pt-1'>
          {/* <div className='col-lg-12  mt-0 mb-0 pt-0 pb-0 pl-3 text-right'>Total Product Amount: ${ overallProductTotal}</div> */}

          <div className='col-lg-12  mt-0 mb-0 pt-0 pb-0 pl-3 text-right'>Tax: ${taxAmount}</div>
          <div className='col-lg-12  mt-0 mb-0 pt-0 pb-0 pl-3 text-right'>Shipment Charges: {Number(shipmentCharges) <= 0 ? 'Free' : `$${Number(shipmentCharges)}`} </div>
          <div className='col-lg-12  mt-0 mb-0 pt-0 pb-0 pl-3 text-right'>Grand Total: ${grandTotal}</div>
        </div>

        {/* { locationURL !==result ? <div className='row'>
                    <div className='col-lg-12  mt-0 mb-0 pt-0 pb-0 pl-3 text-right'>Total Amount: ${totalShipmentAmount?.toFixed(2)}</div>
                    <div className='col-lg-12  mt-0 mb-0 pt-0 pb-0 pl-3 text-right'>Total Tax: ${tax?.toFixed(2)}</div>
                    <div className='col-lg-12  mt-0 mb-0 pt-0 pb-0 pl-3 text-right'>Shipment Amount: { Number(shipmentCharges) <=0 ? "Free" :  `$${Number(shipmentCharges)?.toFixed(2)}`} </div>
                    <div className='col-lg-12  mt-0 mb-0 pt-0 pb-0 pl-3 text-right'>Grand Total: ${(grandTotal + tax).toFixed(2)}</div>
                </div>:" " } */}
      </Modal.Body>
      <Modal.Footer>
        <button
          variant='primary'
          className='btn btn-primary'
          style={{
            textAlign: 'left',
          }}
          onClick={() => {
            setIsVisible(!isVisible);
            setAddressId('');
          }}
        >
          Close
        </button>
      </Modal.Footer>
    </Modal>
  ) : (
    <Modal
      show={isVisible}
      size='lg'
      onHide={() => {
        setIsVisible(!isVisible);
      }}
      centered
    >
      <Modal.Header
        style={{
          display: 'block',
        }}
      >
        <Modal.Title style={{ display: 'block', padding: '9px 13px 8px 8px', fontSize: '20px' }}>
          <div className='row'>
            <div className='col-lg-6'>{ecomOrderNo}</div>
            <div className='col-lg-6 text-right'>{ecomOrderCreatedDate}</div>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ padding: '0px 20px !important' }}>
        <div className='row'>
          <div className='row'>
            <div className='col-lg-6 fs-5 mt-0 mb-0 pt-0 pb-0 pl-3'>{title}</div>
            <div className='col-lg-6 mt-0 mb-0 pt-2 pb-0 pl-3 text-right'>{status}</div>
          </div>
          <div className='row'>
            <div className='col-lg-6  mt-0 mb-0 pt-0 pb-0 pl-3'>{address}</div>
            {wareHouseStatus && <div className='col-lg-6  mt-0 mb-0 pt-0 pb-0 pl-0 text-right'>Shipment Status: {wareHouseStatus}</div>}
            {/* <div className='col-lg-6  mt-0 mb-0 pt-0 pb-0 pl-0 text-right'>Warehouse Status: {wareHouseStatus}</div> */}
          </div>
          <div className='row'>
            <div className='col-lg-6  mt-0 mb-0 pt-0 pb-0 pl-3'>{shipmePackageName}</div>
            {requestId && <div className='col-lg-6  mt-0 mb-0 pt-0 pb-0 pl-0 text-right'>Request Type: {requestType}</div>}
          </div>
          <div className='row'>{requestId && <div className='col-lg-12  mt-0 mb-0 pt-0 pb-0 pl-0 text-right'>Request Status: {requestStatus}</div>}</div>
          {/* <div className='col-lg-6 fs-5 mt-0 mb-0 pt-0 pb-0 pl-3'>{title}</div>
                    <div className='col-lg-6 mt-0 mb-0 pt-2 pb-0 pl-3 text-right'>{status}</div>
                    <div className='col-lg-6  mt-0 mb-0 pt-0 pb-0 pl-3'>{address}</div>
                    <div className='col-lg-6  mt-0 mb-0 pt-0 pb-0 pl-0 text-right'>Warehouse Status: {wareHouseStatus}</div>
                    <div className='col-lg-6  mt-0 mb-0 pt-0 pb-0 pl-3'>{shipmePackageName}</div>

                    {
                        requestId && (
                            <>
                                <div className='col-lg-6  mt-0 mb-0 pt-0 pb-0 pl-0 text-right'>Request Type: {requestType}</div>
                                <div className='col-lg-12  mt-0 mb-0 pt-0 pb-0 pl-0 text-right'>Request Status: {requestStatus}</div>
                            </>
                        )
                    } */}
        </div>
        <div className='mb-4'>
          <table id='customers' className='w-100'>
            <thead>
              <tr
                style={{
                  fontWeight: 'bold',
                }}
              >
                <th>Product Name </th>
                <th>Quantity</th>
                <th>Unit Price</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              {productsData?.map((product, index) => {
                return (
                  <tr>
                    <td style={{ width: '100px' }}>{product.productName}</td>
                    <td style={{ width: '100px' }}>{product.quantity}</td>
                    <td style={{ width: '100px' }}>${Number(product.unitPrice)?.toFixed(2)}</td>
                    <td style={{ width: '100px' }}>${(Number(product.quantity).toFixed(2) * Number(product.unitPrice)?.toFixed(2)).toFixed(2)}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className='row'>
          <div className='col-lg-12  mt-0 mb-0 pt-0 pb-0 pl-3 text-right'>Total Product Amount: ${totalShipmentAmount?.toFixed(2)}</div>
          <div className='col-lg-12  mt-0 mb-0 pt-0 pb-0 pl-3 text-right'>Tax: ${tax?.toFixed(2)}</div>
          <div className='col-lg-12  mt-0 mb-0 pt-0 pb-0 pl-3 text-right'>Shipment Charges: {Number(shipmentCharges) <= 0 ? 'Free' : `$${Number(shipmentCharges)?.toFixed(2)}`} </div>
          <div className='col-lg-12  mt-0 mb-0 pt-0 pb-0 pl-3 text-right'>Grand Total: ${grandTotal + tax}</div>
        </div>
        {/* { locationURL !==result ? <div className='row'>
                    <div className='col-lg-12  mt-0 mb-0 pt-0 pb-0 pl-3 text-right'>Total Amount: ${totalShipmentAmount?.toFixed(2)}</div>
                    <div className='col-lg-12  mt-0 mb-0 pt-0 pb-0 pl-3 text-right'>Total Tax: ${tax?.toFixed(2)}</div>
                    <div className='col-lg-12  mt-0 mb-0 pt-0 pb-0 pl-3 text-right'>Shipment Amount: { Number(shipmentCharges) <=0 ? "Free" :  `$${Number(shipmentCharges)?.toFixed(2)}`} </div>
                    <div className='col-lg-12  mt-0 mb-0 pt-0 pb-0 pl-3 text-right'>Grand Total: ${(grandTotal + tax).toFixed(2)}</div>
                </div>:" " } */}

        <div
          className='row mt-2'
          style={{
            border: '1px solid silver',
            borderRadius: 10,
          }}
        >
          <div className='col-lg-6 fs-5 mt-0 mb-0 pt-0 pb-0  mt-2' style={{ height: '40px' }}>
            History
          </div>

          <div
            style={{
              maxHeight: '200px',
              overflowY: 'auto',
            }}
          >
            <table
              className='table'
              style={{
                width: '100%',
                borderCollapse: 'collapse',
              }}
            >
              <thead
                className=''
                style={{
                  backgroundColor: '#f2b544',
                  color: '#fff',
                }}
              >
                <tr
                  style={{
                    fontWeight: 'bold',
                  }}
                >
                  {commentTableHeaders.map((header, index) => {
                    return (
                      <th
                        style={{
                          textAlign: 'center',
                          padding: '8px',
                          fontSize: '13px',
                        }}
                      >
                        {header}
                      </th>
                    );
                  })}
                </tr>
              </thead>

              {commentsData.length > 0 ? (
                <tbody>
                  {commentsData.map((commentItem, index) => {
                    console.log(commentsData);
                    return (
                      <tr
                        key={index}
                        style={{
                          backgroundColor: index % 2 == 0 ? '' : '#dddddd',
                        }}
                      >
                        {/* <td
                                                    style={{
                                                        width: '40px',
                                                        textAlign: "center",
                                                        padding: "8px",
                                                        fontSize: "13px"
                                                    }}>{`${commentItem.ecomOrderNo}-${commentItem.addressId}`}

                                                </td> */}
                        <td
                          style={{
                            width: '60px',
                            textAlign: 'center',
                            padding: '8px',
                            fontSize: '13px',
                          }}
                        >
                          {moment(commentItem.createdAt).format('MM-DD-YYYY HH:mm')}
                        </td>

                        <td
                          style={{
                            width: '20px',
                            textAlign: 'center',
                            padding: '8px',
                            fontSize: '13px',
                          }}
                        >
                          {commentItem.commentBy}
                        </td>

                        <td
                          style={{
                            width: '200px',
                            textAlign: 'center',
                            padding: '8px',
                            fontSize: '13px',
                          }}
                        >
                          {commentItem.comment}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              ) : (
                <div className='row text-center'>
                  <h2 className='text-center'>No Comment History</h2>
                </div>
              )}
            </table>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          variant='primary'
          className='btn btn-primary'
          style={{
            textAlign: 'left',
          }}
          onClick={() => {
            setIsVisible(!isVisible);
          }}
        >
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default SubOrderViewModal;
