import React, { useEffect, useRef, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { TweenOneGroup } from 'rc-tween-one';
import { Input, Select, Tag, message, theme } from 'antd';
import { SelectField } from '../../layouts/FormFields';
import { useLocation } from 'react-router-dom';

const TagInput = ({ tags, setTags, options, label }) => {
  //   const { token } = theme.useToken();
  const [warning, setWarning] = useState('');
  const location = useLocation()
  console.log(location)
  console.log(tags)

  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const inputRef = useRef(null);
  useEffect(() => {
    if (inputVisible) {
      inputRef.current?.focus();
    }
  }, [inputVisible]);

  useEffect(() => {
    setTimeout(() => {
      setWarning('');
    }, 7000);
  }, [warning]);

  const handleClose = (removedTag) => {
    const newTags = tags.filter((tag) => tag !== removedTag);
    console.log(newTags);
    setTags(newTags);
  };
  const showInput = () => {
    setInputVisible(true);
  };
  const handleInputChange = (e) => {
      setInputValue(e.target.value);
  };
  const handleInputConfirm = () => {
    if (tags.length <= 9) {
      if (inputValue && tags.indexOf(inputValue) === -1) {
        setTags([...tags, inputValue]);
      }
      setInputVisible(false);
      setInputValue('');
      setWarning('');
    } else {
      setInputVisible(false);
      setInputValue('');
      setWarning('You cannot add more than 10 tags!');
    }
  };

  const handleSelectChange = (e) => {
      if (e && tags.indexOf(e) === -1) {
        setTags([...tags, e]);
      }
    } 

   
  
  const forMap = (tag) => {
    const tagElem = (
      <Tag
        closable
        onClose={(e) => {
          e.preventDefault();
          handleClose(tag);
        }}
        style={{
          borderRadius: '5px',
          // backgroundColor: '#f2b544',
          boxShadow: '1px 1px 1px #ccc'
        }}
        className='border-none text-light d-flex align-items-center bg-secondary fs-6 p-2 mb-1'
      >
        {tag}
      </Tag>
    );
    return (
      <span
        key={tag}
        style={{
          display: 'inline-block',
        }}
      >
        {tagElem}
      </span>
    );
  };
  const tagChild = tags.map(forMap);
  const tagPlusStyle = {
    // background: token.colorBgContainer,
    borderStyle: 'dashed',
    borderRadius: '5px',
  };
  return (
    <div className='mb-4'>
      <div
        className='col-4'
        style={{
          marginBottom: 16,
        }}
      >
        {label && (
          <label>
            {label}
            {/* <span className='required-star'>*</span> */}
          </label>
        )}
        {inputVisible ? (
          <>
            {location.pathname === '/boxtype/create' || location.pathname === '/boxtype/update' ? (
              <Select
                required={true}
                rules={[
                  {
                    required: true,
                    message: 'Select atleast one of the following',
                  },
                ]}
                placeholder='Select Box Ingredients'
                onChange={handleSelectChange}
                onBlur={() => setInputVisible(false)}
              >
                {options}
              </Select>
            ) : (
              <Input ref={inputRef} type='text' size='small' placeholder='Add Tag' value={inputValue} onChange={handleInputChange} onBlur={handleInputConfirm} onPressEnter={handleInputConfirm} />
            )}
          </>
        ) : (
          <Tag className='d-flex align-items-center p-2 mb-1' onClick={showInput} style={tagPlusStyle}>
            <PlusOutlined /> {location.pathname === '/boxtype/create' || location.pathname === '/boxtype/update' ? ' Box Ingredients' : ' Search Tags'}
          </Tag>
        )}
        <small className='text-danger fs-6'>{warning}</small>
      </div>
      <div className='col-12'>
        <TweenOneGroup
          enter={{
            scale: 0.8,
            opacity: 0,
            type: 'from',
            duration: 100,
          }}
          onEnd={(e) => {
            if (e.type === 'appear' || e.type === 'enter') {
              e.target.style = 'display: inline-block';
            }
          }}
          leave={{
            opacity: 0,
            width: 0,
            scale: 0,
            duration: 200,
          }}
          appear={false}
        >
          {tagChild}
        </TweenOneGroup>
      </div>
    </div>
  );
};
export default TagInput;
