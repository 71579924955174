import React, { useEffect, useState } from 'react';
import { InputField, SelectField } from '../../layouts/FormFields';
import useFetchProducts from '../../hooks/fetchProducts';
import { Form, Select, Space, Switch, message } from 'antd';
import { QuestionaireForm } from '../../layouts/AddQuestionaire';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { app } from '../../config';
import getHeaders from '../../utils/authHeaders';
import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';

const QuestionaireAdd = () => {
  const [loading, setLoading] = useState(false);
  let navigate = useNavigate();
  const [uploadObj, setUploadObj] = useState({});
  const [addUserResp, setAddUserResp] = useState({});
  const [show, setShow] = useState(false);
  const [productByFilter,setProductByFilter] = useState([])
  const [questionnaire, setQuestionnaire] = useState([
    {
      question: '',
      options: [''],
      type: '',
      isRequired: false
    },
  ]);
  const [isShowQuestionnaire, setIsShowQuestionnaire] = useState(false)
  const [typeLoader, setTypeLoader] = useState(false);

  const prodResp = useFetchProducts();
  const products = prodResp.response ? prodResp.response.data : [];

  useEffect(()=>{
    const filter = products?.filter((item) => {
      if(Number(item.questionCount) === 0) return item
    });
    setProductByFilter(filter)
  }, [products])


  function goBack() {
    navigate('/questions/listing', { replace: true });
  }
  const onFinish = (values) => {
    console.log(questionnaire);
    setShow(true);
    setUploadObj({
      productId: values.productId,
      data: questionnaire,
      isShowQuestionnaire,
    });
  };
  const onFinishFailed = () => {};

  const uploadData = async (uploadObj) => {
    let user = JSON.parse(localStorage.getItem('user'));
    let userId = user?.result.data.user.userId;
    uploadObj.userId = userId;
    // setLoading(true);
    setLoading(true);

    // console.log('uploadObj ', uploadObj);
    // return;
    try {
      const res = await axios.post(`${app.URL}/api/v1/admin/questions/create`, uploadObj, { headers: getHeaders() });
      setLoading(false);
      setShow(false);

      console.log(res.data.result);

      if (res.data.result.success) {
        setAddUserResp(res.data.result.data);
        message.success(res.data.result.msg);

        setTimeout(() => {
          navigate('/questions/listing');
        }, 1000);
      } else if (!res.data.result.success) {
        message.warning(res.data.result.msg);
      }
    } catch (err) {
      setLoading(false);
      setShow(false);

      console.log(err);
      if (err) console.log(err.response.data.message);
      else return;
    }
  };
  const submitData = () => {
    if (Object.keys(uploadObj).length > 0) {
      uploadData(uploadObj);
    }
  };
  const handleClose = () => {
    setShow(false);
  };

  const updateOptions = (inputValue, optionIndx, questionIndx) => {
    console.log(inputValue, optionIndx, questionIndx);
    setQuestionnaire((prevQuestionnaire) => {
      // Create a copy of the questionnaire array
      const newQuestionnaire = [...prevQuestionnaire];

      // Check if the index exists before updating the first question's options
      // if (newQuestionnaire[questionIndx].options.length > 1) {
      //   newQuestionnaire[questionIndx].options[optionIndx] = inputValue;
      // } else {
      //   newQuestionnaire[questionIndx].options.push(inputValue);
      // }
      newQuestionnaire[questionIndx].options[optionIndx] = inputValue;
      return newQuestionnaire;
    });
  };

  const deleteOption = (questionIndx, optionIndx) => {
    setQuestionnaire((prevQuestionnaire) => {
      const newQuestionnaire = [...prevQuestionnaire];
      if (newQuestionnaire[questionIndx] && newQuestionnaire[questionIndx].options.length > optionIndx) {
        newQuestionnaire[questionIndx].options.splice(optionIndx, 1);
      }
      return newQuestionnaire;
    });
  };

  const deleteQuestion = (questionIndx) => {
    setQuestionnaire((prevQuestionnaire) => {
      const newQuestionnaire = [...prevQuestionnaire];
      if (newQuestionnaire.length > questionIndx) {
        newQuestionnaire.splice(questionIndx, 1);
      }
      return newQuestionnaire;
    });
  };

  const addQuestion = () => {
    const newQuestion = {
      question: '',
      options: [''],
      type: '',
      isRequired: false,
    };
    setQuestionnaire((prevQuestionnaire) => [...prevQuestionnaire, newQuestion]);
  };

  const changeQuestionType = (questionIndx, newType) => {
    setQuestionnaire((prevQuestionnaire) => {
      const newQuestionnaire = [...prevQuestionnaire];
      if (newQuestionnaire[questionIndx]) {
        newQuestionnaire[questionIndx].type = newType;
        // if(newType === "descriptive") newQuestionnaire[questionIndx].options = [];
      }
      return newQuestionnaire;
    });
  };

  const changeQuestionRequired = (questionIndx, newType) => {
    setQuestionnaire((prevQuestionnaire) => {
      const newQuestionnaire = [...prevQuestionnaire];
      if (newQuestionnaire[questionIndx]) {
        newQuestionnaire[questionIndx].isRequired = newType;
        // if(newType === "descriptive") newQuestionnaire[questionIndx].options = [];
      }
      return newQuestionnaire;
    });
  };

  const changeQuestionText = (questionIndx, newText) => {
    setQuestionnaire((prevQuestionnaire) => {
      const newQuestionnaire = [...prevQuestionnaire];
      if (newQuestionnaire[questionIndx]) {
        newQuestionnaire[questionIndx].question = newText;
      }
      return newQuestionnaire;
    });
  };

  // const changeQuestionActive = (questionIndx, isActive) => {
  //   console.log(questionIndx, isActive);
  //   setQuestionnaire((prevQuestionnaire) => {
  //     const newQuestionnaire = [...prevQuestionnaire];
  //     if (newQuestionnaire[questionIndx]) {
  //       newQuestionnaire[questionIndx].isActive = isActive;
  //     }
  //     return newQuestionnaire;
  //   });
  // };

  return (
    <QuestionaireForm
      loading={loading}
      pageTitle='Add Questionnaire'
      initialValues={{
        remember: true,
        questionnaire,
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      handleClose={handleClose}
      addUserResp={addUserResp}
      show={show}
      addMessage='Are you sure you want to add questionnaire?'
      goBack={goBack}
      submitData={submitData}
      upload={false}
      inputs={[
        <SelectField
          name='productId'
          options={productByFilter.map((item) => (
            <Select.Option value={item.productId}>{item.name}</Select.Option>
          ))}
          rules={[
            {
              required: true,
              message: 'Select Product',
            },
          ]}
          placeholder='Select Product'
          label='Product'
          required={true}
          col='col-lg-2 col-md-4 col-12'
        />,
        <div className='col-lg-2 col-md-4 offset-md-8 mb-5'>
          <label className='px-2'>Show Questions</label>
          <Switch
            value={isShowQuestionnaire}
            onChange={(e) => {
              console.log(e);
              setIsShowQuestionnaire(e);
            }}
          />
        </div>,
      ]}
      // showQuestion={[
      //      <div>
      //     <label className='px-2'>Show Questions</label>
      //     <Switch
      //       onChange={(e) => {
      //         console.log(e);
      //         setIsShowQuestionnaire(e);
      //       }}
      //     />
      //   </div>
      // ]}
      extras={[
        <div className='col-md-12 '>
          <div className='row'>
            <Form.List name='questionnaire'>
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => {
                    function findOptions() {
                      const opt = questionnaire?.filter((item, ind) => ind === key);
                      if (opt.length > 0) {
                        return opt[0]?.options;
                      } else {
                        return [''];
                      }
                    }
                    return (
                      <Space key={fields.key} className='col-md-12 m-0 ' align='baseline' gap='13'>
                        <div className='row w-100'>
                          <InputField
                            {...restField}
                            col='col-lg-4 col-md-3 col-12'
                            placeholder='Enter Question'
                            rules={[
                              {
                                required: true,
                                message: 'Please Enter a Question',
                              },
                            ]}
                            label='Enter Question'
                            required={false}
                            name={[name, 'question']}
                            type='text'
                            onChange={(e) => {
                              changeQuestionText(key, e.target.value);
                            }}
                          />

                          <SelectField
                            {...restField}
                            col='col-lg-2 col-md-4 col-12'
                            placeholder='Select Question Type'
                            rules={[
                              {
                                required: true,
                                message: 'Select a Question Type',
                              },
                            ]}
                            label='Question Type'
                            required={true}
                            onChange={(e) => {
                              // console.log(key, 1, e);
                              // questionTypesArr.splice(key, 1, e);
                              // setQuestionTypesArr(questionTypesArr);
                              setTypeLoader(true);
                              changeQuestionType(key, e);
                              setTimeout(() => {
                                setTypeLoader(false);
                              }, 120);
                            }}
                            name={[name, 'type']}
                            options={[
                              { name: 'Select', value: '' },
                              { name: 'Multiple Choices', value: 'mcqs' },
                              { name: 'Checkboxes', value: 'checkbox' },
                              { name: 'Dropdown', value: 'dropdown' },
                              { name: 'Descriptive', value: 'descriptive' },
                            ].map((item) => (
                              <Select.Option value={item.value}>{item.name}</Select.Option>
                            ))}
                          />
                          <div className='d-flex align-items-center col-lg-2 col-md-4 col-12'>
                            <Form.Item label='Required' className='selector-main' colon={false} valuePropName='checked' name={[name, 'isRequired']}>
                              <Switch
                                defaultValue={false}
                                onChange={(e) => {
                                  changeQuestionRequired(key, e);
                                }}
                              />
                            </Form.Item>
                          </div>
                          {questionnaire[key]?.type !== 'descriptive' && !typeLoader && (
                            <div className='col-12'>
                              <div className='row'>
                                <AnswerOptions options={findOptions()} questionIndx={key} updateOptions={updateOptions} deleteOption={deleteOption} />
                              </div>
                            </div>
                          )}
                          {fields.length > 1 && (
                            <Form.Item
                              className='col-lg-2 d-flex align-items-center'
                              style={{
                                fontSize: '1rem',
                                paddingTop: '1rem',
                              }}
                            >
                              <div
                                className='add-btn'
                                style={{ textAlign: 'center', backgroundColor: '#F21D2F' }}
                                onClick={() => {
                                  remove(name);
                                  // setQuestionsCount(questionsCount - 1);
                                  deleteQuestion(key);
                                }}
                              >
                                Remove Question
                              </div>
                            </Form.Item>
                          )}
                        </div>
                      </Space>
                    );
                  })}
                  {fields.length < 5 && (
                    <button
                      onClick={() => {
                        add();
                        // setQuestionsCount(questionsCount + 1);
                        addQuestion();
                      }}
                      type='primary'
                      className={`btn btn-primary patient-cancel-btn button col-md-2 mx-md-2 mb-4 `}
                    >
                      Add Question
                    </button>
                  )}
                </>
              )}
            </Form.List>
          </div>
        </div>,
      ]}
    />
  );
};

const AnswerOptions = ({ options, questionIndx, updateOptions, deleteOption }) => {
  const [optionState, setOptionState] = useState(options);
  return (
    <>
      {optionState.map((item, indx) => {
        return <Option optionValue={item} optionState={optionState} optionIndx={indx} setOptionState={setOptionState} questionIndx={questionIndx} updateOptions={updateOptions} deleteOption={deleteOption} />;
      })}
    </>
  );
};

const Option = ({ optionState, setOptionState, optionIndx, optionValue, questionIndx, updateOptions, deleteOption }) => {
  const [input, setInput] = useState(optionValue ? optionValue : '');
  return (
    <div className='col-lg-3 col-md-6 col-12'>
      <div className='row'>
        <InputField
          value={input}
          onChange={(e) => {
            setInput(e.target.value);
            updateOptions(e.target.value, optionIndx, questionIndx);
          }}
          col='col-lg-10 col-md-11 col-12'
          placeholder='Enter Option'
          label={`Option ${optionIndx + 1}`}
          required={true}
          type='text'
          suffix={
            optionState.length > 1 && (
              <MinusCircleOutlined
                style={{
                  fontSize: '1rem',
                  color: 'red',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setOptionState(optionState.filter((item, ind) => ind !== optionIndx));
                  deleteOption(questionIndx, optionIndx);
                }}
              />
            )
          }
        />

        <div className='col-lg-2 col-md-1 p-0 d-flex align-items-center justify-content-md-start justify-content-center m-md-0 mb-3'>
          {optionState.length < 5 && optionState.length === optionIndx + 1 && (
            <PlusCircleOutlined
              style={{
                fontSize: '1rem',
                color: 'blue',
                cursor: 'pointer',
              }}
              onClick={() => {
                setOptionState([...optionState, '']);
                updateOptions(input, optionIndx, questionIndx);
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};
export default QuestionaireAdd;
