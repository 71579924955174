export const logOutHandler = (navigate) => {
  console.log('logout called');
  let items = ['editProductId', 'userId', 'user', 'subOrderId', 'orderId'];
  for (let keys in items) {
    console.log('for in called');
    console.log(keys);
    localStorage.removeItem(items[keys]);
  }
  // navigate('/admin/login');
  window.location.reload();
};
