import axios from 'axios';
import { useEffect, useState } from 'react';
import {app} from '../config'
import getHeaders from '../utils/authHeaders';
let url=`${app.URL}/org/fetchall`;
const useFetchStores = () => {
  const [loading, setLoading] = useState(true);
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(`${app.URL}/api/v1/admin/store/getallstores`, {headers: getHeaders()});
        setResponse(res.data.result);
        setLoading(false);
      } catch (err) {
        setError(err);
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  return { loading, response, error };
};

export default useFetchStores;
