import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { message, DatePicker, Row, Col, Select, Form, Input, Upload, Space, Button as BTN, Switch, ColorPicker, Textarea, Divider } from 'antd';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { app } from '../../config';
import getHeaders from '../../utils/authHeaders';
import { LeftCircleOutlined, LoadingOutlined, PlusOutlined, CloseCircleOutlined } from '@ant-design/icons';
import InputMask from 'react-input-mask';
import getAllProductCategories from '../../hooks/getAllProductCategories';
import getProductById from '../../hooks/getProductById';
import AddPhoto from '../../assets/images/add.png';
import useFetchBoxTypes from '../../hooks/fetchBoxTypes';
import { InputField, SelectField, TextAreaField } from '../../layouts/FormFields';
import TagInput from './TagInput';
import getAllPanelCode from '../../hooks/getAllPanelCode';
import DividerLine from '../../layouts/DividerLine';
import TestComponent from './TestComponent';
import getAllMeasuredCategory from './getAllMeasuredCategory';
import { noPrespace, noNumbers, noPrespaceNoAlphabetsNoSpecialChar } from '../user/ValidationFunctions';
import { MeasuredSpace } from './MeasuredSpace';
import TagSelect from './TagSelect';
import editBtnImg from '../../assets/images/edit.png';
import Delete from '../../assets/images/delete.png';
import { MeasuredForm } from './MeasuredForm';

// getAllMeasuredCategory
const EditProducts240724 = () => {
  const { TextArea } = Input;
  const { t } = useTranslation(['common']);
  const [adddescriptionResp, setAddDescriptionResp] = useState({});
  const [imageuploading, setImageUploading] = useState(false);
  const [addUserResp, setAddUserResp] = useState({});
  const [uploadedImg, setUploadedImg] = useState('');
  const [uploadLogo, setuploadLogo] = useState(true);
  const [uploadVideo, setuploadVideo] = useState(null);
  const [uplVid, setuplVid] = useState(true);
  const [customURL, setCustomURL] = useState(false);
  const [customURLValue, setCustomURLValue] = useState('');
  const [largeFile, setLargeFile] = useState(false);
  const [contentType, setContentType] = useState();
  const [errorObj, setErrorObj] = useState({});
  const [resetModal, setResetModal] = useState(false);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [uploadObj, setUploadObj] = useState({});
  const [isActive, setisActive] = useState(false);
  const [prodType, setProdType] = useState('');
  const [tags, setTags] = useState([]);
  const [cisPanelCodes, setCisPanelCodes] = useState([]);
  const [productCat, setProductCat] = useState([]);
  let [mycolor, setmycolor] = useState();
  let [textEditorValue, settextEditorValue] = useState();
  const [whatMeasuredData, setwhatMeasuredData] = useState([]);
  const [measuredModal, setMeasuredModal] = useState({});
  const [addWhatMeasured, setaddWhatMeasured] = useState(false);

  const [renderuploadedImg, setrenderuploadedImg] = useState('');
  const [renderuploadedImg1, setrenderuploadedImg1] = useState('');
  const [renderuploadedImg2, setrenderuploadedImg2] = useState('');
  const [renderuploadedImg3, setrenderuploadedImg3] = useState('');
  const [firstRender, setFirstRender] = useState(true);

  const [imagerenderuploading, setimagerenderuploading] = useState(true);
  const [prodDescription, setProdDescription] = useState('');
  const [status, setStatus] = useState('');
  // const [measuredElementIndx, setMeasuredElementIndx] = useState('');
  let renderListType = [
    { value: 'richText', title: 'Rich Text' },
    { value: 'image', title: 'Image' },
  ];

  // const handletextEditorValue = (val) => {
  //   settextEditorValue(val);
  // };

  const [form] = Form.useForm();

  // const [value, setValue] = useState(1);
  let navigate = useNavigate();
  let header = getHeaders();
  let productId = localStorage.getItem('editProductId');
  const productCategoriesRes = getAllProductCategories();
  const cisPanelCodeRes = getAllPanelCode();
  const MeasuredCategoriesRes = getAllMeasuredCategory();
  const productCategories = productCategoriesRes.response ? productCategoriesRes.response.data : [];
  const cisPanelCode = cisPanelCodeRes.response ? cisPanelCodeRes.response : [];
  const MeasuredCategoriesArray = MeasuredCategoriesRes.response ? MeasuredCategoriesRes.response : [];
  const getProductRes = getProductById(productId);
  const productResp = getProductRes.response ? getProductRes.response.data : {};
  let productObj, measuredArrayvar;
  productResp ? (productObj = productResp) : ' ';
  productResp ? (measuredArrayvar = productResp.measuredElements) : '';

  let location = useLocation();
  const [isWrite, setIsWrite] = useState(true);
  let initalStatus;
  useEffect(() => {
    if (productResp?.productDetail?.isActive == true && !productResp?.productDetail?.isCommingSoon && !productResp?.productDetail?.inValidation) {
      setStatus('active');
    } else if (productResp?.productDetail?.isActive && productResp?.productDetail?.isCommingSoon) {
      setStatus('isCommingSoon');
    } else if (productResp?.productDetail?.isActive && productResp?.productDetail?.inValidation) setStatus('inValidation');
    else if (!productResp?.productDetail?.isActive) setStatus('inActive');
    productResp?.productDetail?.productTags && setTags(productResp?.productDetail?.productTags);
    setProdDescription(productResp?.productDetail?.description);
  }, [productResp]);
  const boxTypeRes = useFetchBoxTypes();
  let boxTypes = [];

  boxTypeRes.response ? (boxTypes = boxTypeRes.response.data) : [];

  console.log(whatMeasuredData);

  useEffect(() => {
    // productObj?.productDetail?.isCustomVideoUrl == '1' ? setCustomURL(true) : ' ';
    // productObj&&productObj.image?setUserImage(true):" ";
    setProdType(productObj?.productDetail?.boxTypeId ? 'generic' : 'retail');

    // measuredArray? measuredArrayvar = measuredArray:'';
    setmycolor(productObj?.productDetail?.backgroundColor || null);
    setProductCat(productObj?.productCategories);
    setUploadedImg(productObj?.productDetail?.logoUrl);
    setCisPanelCodes(productObj?.resultElementGroups);
    if (productObj?.productDetail?.productId) getWhatMeasuredListing(productObj?.productDetail?.productId);
  }, [productObj]);
  // productObj&&productObj.isCustomVideoUrl=='1'?setCustomURL(true):" ";
  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const handleChange = (info) => {
    if (info.file.status === 'uploading') {
      console.log('uploading data');
      setUploadedImg('');
      setImageUploading(true);
      setLoading(true);
      
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      setLargeFile(false);
      setLoading(false);
      console.log(info.file.response);
      info.file ? console.log(info.file.response.result.data.Location) : console.log('Not found');
      info.file ? setUploadedImg(info.file.response.result.data.Location) : ' ';
      getBase64(info.file.originFileObj, (url) => {
        //setLoading(false);
        setImageUploading(false);
        setuploadLogo(false);
      });
    }
  };

  const beforeUpload = (file) => {
    console.log(file);
    if (file.type === 'image/jpeg' || file.type === 'image/png') {
      console.log('setting image type');
      setContentType('img');
    } else if (file.type === 'video/x-matroska' || file.type === 'video/mp4') setContentType('video');
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'video/x-matroska' || file.type === 'video/mp4';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    console.log('file Checked');
    const isLt2M = file.size / 1024 / 1024 < 20;
    if (!isLt2M) {
      setLargeFile(true);
      message.error('Image must smaller than 20MB!');
    }
    console.log('message');
    return isJpgOrPng && isLt2M;
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );
  let user = JSON.parse(localStorage.getItem('user'));

  const uploadData = async (userDetails) => {
    setLoading(true);
    console.log('uploadeddddObj ', userDetails);
    let userId = user?.result.data.user.userId;
    userDetails.userId = userId;
    try {
      console.log('uploadObj ', userDetails);
      const res = await axios.post(`${app.URL}/api/v1/admin/product/update/${productId}`, userDetails, { headers: getHeaders() });
      setLoading(false);
      setShow(false);

      if (res.data.result.success) {
        console.log('success');
        console.log(res.data.result);
        setAddDescriptionResp(res.data.result);
        message.success(res.data.result.msg);
        // setResetModal(true);
        setTimeout(() => {
          navigate('/product/listing');
        }, 500);
      } else if (!res.data.result.success) {
        message.warning(res.data.result.msg);
      }
    } catch (err) {
      setShow(false);

      err ? console.log(err.response.data.result) : '';
      err ? setErrorObj(err.response.data.result) : '';
      setLoading(false);

      setTimeout(() => {
        setShow(false);
      }, 500);
      setResetModal(false);
      //   setAddDescriptionResp({code:"000"})
      if (err) console.log(err.response.data.message);
      else return;
    }
  };
  const handleStatusChange = (e) => {
    console.log('handle status change');
    setStatus(e);
    console.log(e);
  };
  const onFinish = (values) => {
    console.log('Success:', values, whatMeasuredData);
   
    if (cisPanelCodes?.length <= 0) {
      message.error('At least one CIS panel required');
      return;
    }
    setUploadObj({
      productCode: values.productCode ? values.productCode : null,
      name: values.name ? values.name : null,
      unitPrice: values.unitPrice ? values.unitPrice : null,
      taxRate: values.taxRate ? values.taxRate : null,
      otherCharges: values.otherCharges ? values.otherCharges : null,
      description: prodDescription,
      instruction: values.instruction ? values.instruction : null,
      // productCategoryId: 1,
      testName: values.testName,
      tagLine: values.tagLine,
      // backgroundColor: values.backgroundColor ? values.backgroundColor : null,
      backgroundColor: mycolor,
      logoUrl: uploadedImg ? uploadedImg : productObj.productDetail.logoUrl,
      videoUrl: !customURL ? uploadVideo : customURLValue ? customURLValue : productObj.productDetail.videoUrl,
      isCustomVideoUrl: customURL,
      // isCustomVideoUrl: values.isCustomVideoUrl ? values.isCustomVideoUrl : null,
      mostPopular: values.mostPopular ? values.mostPopular : false,
      isOutOfStock: values.isOutOfStock,
      type: values.type ? values.type : null,

      // cisPanelCode: values.cisPanelCode ? values.cisPanelCode : null,
      // cisPanelName: values.cisPanelName ? values.cisPanelName : null,
      // resultElementGroupId: values.resultElementGroupId ? values.resultElementGroupId : null,
      resultElementGroups: cisPanelCodes,
      productWhatMeasuredId: values.productWhatMeasuredId ? values.productWhatMeasuredId : null,
      // textEditorValue: textEditorValue ? textEditorValue : null,
      boxTypeId: prodType === 'generic' && values.boxTypeId ? values.boxTypeId : null,
      productTags: tags,
      isActive: status === 'active' || status === 'isCommingSoon' || status === 'inValidation' ? true : false,
      isCommingSoon: status === 'isCommingSoon' ? true : false,
      inValidation: status === 'inValidation' ? true : false,
      productCategories: productCat,
    });

    // console.log('uploadeddobject', uploadObj);

    setResetModal(true);

    setShow(true);
    // return;
  };
  console.log('uploadobject', uploadObj);
  // console.log(uploadObj);
  const submitData = () => {
    console.log('calling Helloooo');
    console.log('new upload object', uploadObj);

    // return;

    if (Object.keys(uploadObj).length > 0) {
      uploadData(uploadObj);
    }
  };

  // const onVideoCustomURLChange = (e) => {
  //   let tempVideoURL = e.target.value ? e.target.value.split('src=')[1].split(/[ >]/)[0] : ' ';
  //   tempVideoURL = tempVideoURL ? tempVideoURL.slice(1, tempVideoURL.length - 1) : ' ';
  //   console.log(tempVideoURL);
  //   setCustomURLValue(tempVideoURL);
  //   // setCustomVideoURL(e.target.value)
  // };
  // const onChange = (checked) => {
  //   console.log(`switch to ${checked}`);
  //   setCustomURL(checked);
  // };
  const handleClose = () => setShow(false);
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  // console.log(productObj?.productDetail?.isCustomVideoUrl);
  const handleNumberChange = (value) => {
    // Handle the number change
  };

  const handleKeyPress = (e) => {
    console.log(e);
    let test = false;
    console.log(e.charCode);
    const keyCode = e.which || e.keyCode;
    // && e.target.value.length<1
    if (e.charCode === 32) {
      console.log('Space key pressed');
      // 32 is the charCode for space key
      e.preventDefault(); // Prevent the default behavior (typing space)
    }
    // Block alphabetic characters (A-Z and a-z)  ((e.charCode >= 65 && e.charCode <= 90) || (e.charCode >= 97 && e.charCode <= 122))
    if (e.charCode >= 58 && e.charCode !== 46) {
      e.preventDefault();
    } else if (e.charCode <= 47 && e.charCode !== 46) {
      e.preventDefault();
    }
  };
  const handleKeysPress = (e) => {
    console.log(e);
    noPrespace(e);
    noNumbers(e);
  };
  function goBack() {
    navigate('/product/listing', { replace: true });
  }
  window.addEventListener('popstate', goBack);

  const handleColorChange = (e) => {
    setmycolor(e.target.value);
  };
  const handleColorFromPicker = (value, hex) => {
    // console.log(value, hex)
    setmycolor(hex);
  };

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    const [filteredRoute] = user.result.data.permission.filter((item) => item.action === location.pathname.slice(1));
    console.log(filteredRoute);
    setIsWrite(filteredRoute.isWrite);
  }, []);

  const handleProductDescription = (htmlContent) => {
    setProdDescription(htmlContent);
  };



  const getWhatMeasuredListing = async (id) => {
    try {
      const res = await axios.get(`${app.URL}/api/v1/admin/whatmeasuredele/view/${id}`, { headers: getHeaders() });
      console.log(res);
      setwhatMeasuredData(res?.data?.data);
    } catch (err) {}
  };
  const createWhatMeasured = async (values) => {
    values.productId=productObj?.productDetail?.productId;
    console.log(values);
    try {
      const res = await axios.post(`${app.URL}/api/v1/admin/whatmeasuredele/create`, values, { headers: getHeaders() });
      console.log(res);
      setwhatMeasuredData(res?.data?.data);
    } catch (err) {}
  };
  const deleteWhatMeasured = async (id) => {
    try {
      const res = await axios.delete(`${app.URL}/api/v1/admin/whatmeasuredele/delete/${id}`, { headers: getHeaders() });
      console.log(res);
      setwhatMeasuredData(res?.data?.data);
    } catch (err) {}
  };
  const updateWhatMeasured = async (values) => {
    console.log(values);
    try {
      const res = await axios.post(`${app.URL}/api/v1/admin/whatmeasuredele/update/${values?.productWhatMeasuredElementId}`,values, { headers: getHeaders() });
      console.log(res);
      setwhatMeasuredData(res?.data?.data);
      setMeasuredModal({});
    } catch (err) {}
  };

  const clickWhatMeasured = (id, flag) => {
    if (flag === 'edit') {
      //edit
      setimagerenderuploading(true);
      const findWhatMeaured = whatMeasuredData?.find((item) => item.productWhatMeasuredElementId === id);
      // console.log(findWhatMeaured);
      setMeasuredModal(findWhatMeaured);
    } else {
      //delete
      deleteWhatMeasured(id);
    }
  };
  return (
    <div className='dashboard-card pb-5 container-fluid'>
      <div className='row p-0 pb-4'>
        <div className='for-before col-12 col-md-4 head fs-3 fw-bold' style={{ backgroundColor: '#a7c1d9', borderBottomRightRadius: '10px', color: '#FFFFFF' }}>
          Update Product
        </div>
      </div>

      {getProductRes.response && (
        <Form
          form={form}
          initialValues={{
            ['productCode']: productObj?.productDetail?.productCode,
            ['name']: productObj?.productDetail?.name,
            // ['description']: productObj?.productDetail?.description,
            ['instruction']: productObj?.productDetail?.instruction,
            ['productCategoryId']: productObj?.productDetail?.productCategoryId,
            ['testName']: productObj?.productDetail?.testName,
            ['tagLine']: productObj?.productDetail?.tagLine,
            // ['backgroundColor']: productObj?.productDetail?.backgroundColor,
            ['unitPrice']: productObj?.productDetail?.unitPrice,
            ['taxRate']: productObj?.productDetail?.taxRate,
            ['otherCharges']: productObj?.productDetail?.otherCharges,
            // ['cisPanelName']: productObj?.cisPanelName,
            // ['cisPanelCode']: productObj?.cisPanelCode,
            ['resultElementGroupId']: productObj?.productDetail?.resultElementGroupId,
            ['productWhatMeasuredId']: productObj?.productDetail?.productWhatMeasuredId,
            // [{ textEditorName }]: 'blue',
            // ['picker']: productObj?.productDetail?.backgroundColor || '#00000',
            ['boxTypeId']: productObj?.productDetail?.boxTypeId,
            ['status']:
              (productObj?.productDetail?.isActive && !productObj?.productDetail?.isCommingSoon && !productObj?.productDetail?.inValidation ? 'active' : '') ||
              (productObj?.productDetail?.isActive && productObj?.productDetail?.isCommingSoon ? 'isCommingSoon' : '') ||
              (productObj?.productDetail?.isActive && productObj?.productDetail?.inValidation ? 'inValidation' : '') ||
              (!productObj?.productDetail?.isActive ? 'inActive' : ''),
            ['customURL']: productObj?.productDetail?.videoUrl,
            // ['isCustomVideoUrl']: productObj?.productDetail?.isCustomVideoUrl === '1' ? true : false,
            ['isActive']: productObj?.productDetail?.isActive == 1 ? true : false,
            ['productTags']: ['nddjknd'],
            ['productCategory']: productResp?.productCategories,
            ['mostPopular']: productObj?.productDetail?.mostPopular,
            ['isOutOfStock']: productObj?.productDetail?.isOutOfStock,
            ['isCommingSoon']: productObj?.productDetail?.isCommingSoon == 1 ? true : false,
            ['inValidation']: productObj?.productDetail?.inValidation == 1 ? true : false,
            ['type']: productObj?.productDetail?.type,
            ['heading']: 'asvdnasv',
            // ['measuredList']: whatMeasuredData,

            // ['logoUrl']: productObj?.productDetail?.logoUrl
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete='off'
          className='row p-5 px-3 pb-1'
        >
          <div className='col-12  input-column'>
            <div className='row'>
              <InputField
                col='col-md-1 col-12'
                name='productCode'
                label='Code'
                rules={[
                  {
                    required: true,
                    message: 'Enter Product Code',
                  },
                ]}
                placeholder='Enter Product Code'
                required={true}
                maxLength={6}
                onKeyPress={handleKeysPress}
              />
              <InputField
                col='col-md-2 col-12'
                name='name'
                label='Product Name'
                rules={[
                  {
                    required: true,
                    message: 'Enter Product Name',
                  },
                ]}
                placeholder='Enter Product Name'
                required={true}
                onKeyPress={handleKeysPress}
              />

              <InputField
                col='col-md-2 col-12'
                name='testName'
                label='Test Name'
                rules={[
                  {
                    required: true,
                    message: 'Enter Test Name',
                  },
                ]}
                placeholder='Enter Test Name'
                required={true}
                onKeyPress={handleKeysPress}
              />

              <div className='col-md-6 col-12'>
                <TagSelect
                  options={productCategories?.map((item) => {
                    if (!productCat?.includes(item.productCategoryId)) {
                      return <Select.Option value={item.productCategoryId}>{item.name}</Select.Option>;
                    }
                  })}
                  tags={productCat}
                  setTags={setProductCat}
                  label='Product Category'
                  cisPanelCode={productCategories}
                  isProductCat={true}
                />
              </div>

              {/* <div className='col-md-2 col-12 d-flex align-items-center gap-2 flex-wrap'>
                <Form.Item colon={false} valuePropName='checked' defaultValue={true} label='Product Active' name='isActive'>
                  <Switch onChange={(e) => setisActive(e)} />
                </Form.Item>
              </div>
              <div className='col-md-2 col-12 d-flex align-items-center gap-2 flex-wrap'>
                <Form.Item colon={false} valuePropName='checked' defaultValue={true} label='Coming Soon' name='isCommingSoon'>
                  <Switch />
                </Form.Item>
              </div>
              <div className='col-md-2 col-12 d-flex align-items-center gap-2 flex-wrap'>
                <Form.Item colon={false} valuePropName='checked' defaultValue={true} label='In Validation' name='inValidation'>
                  <Switch />
                </Form.Item>
              </div> */}
              {/* {console.log('stat8s', status)} */}
              {status.length > 2 && (
                <SelectField
                  onChange={(e) => handleStatusChange(e)}
                  options={[
                    { name: 'Active', value: 'active' },
                    { name: 'Coming Soon', value: 'isCommingSoon' },
                    { name: 'In Validation', value: 'inValidation' },
                    { name: 'In-Active', value: 'inActive' },
                  ].map((item) => (
                    <Select.Option value={item.value}>{item.name}</Select.Option>
                  ))}
                  name='status'
                  label='Status'
                  col='col-md-2 col-6'
                  required={true}
                  rules={[
                    {
                      required: true,
                      message: 'Select Status',
                    },
                  ]}
                  placeholder='Select Status'
                />
              )}
              <div className='col-md-12'>
                <DividerLine />
              </div>
              {status == 'active' && (
                <>
                  <InputField
                    col='col-md-4 col-12'
                    name='tagLine'
                    label='Tag Line'
                    rules={[
                      {
                        required: status == 'active' ? true : false,
                        message: 'Enter Tag Line',
                      },
                    ]}
                    placeholder='Enter Tag Line'
                    required={status == 'active' ? true : false}
                  />
                  <InputField
                    col='col-md-3 col-11'
                    // name='backgroundColor'
                    label='Background Color'
                    value={mycolor}
                    onChange={(e) => handleColorChange(e)}
                    rules={[
                      {
                        required: status == 'active' ? true : false,
                        message: 'Must select any color',
                      },
                    ]}
                    placeholder='Write hex code of color'
                    required={status == 'active' ? true : false}
                  />
                  <div className='col-1 pt-4'>
                    <ColorPicker value={mycolor} onChange={handleColorFromPicker} />
                  </div>
                  <div className='col-12 mb-4'>{prodDescription && <TestComponent title='Product Description' textvalue={prodDescription} onChange={handleProductDescription} />}</div>
                  <InputField
                    col='col-xl-2 col-md-2 col-12'
                    name='unitPrice'
                    label='Unit Price'
                    rules={[
                      {
                        required: status == 'active' ? true : false,
                        message: 'Enter Unit Price',
                      },
                    ]}
                    placeholder='Enter Unit Price'
                    required={status == 'active' ? true : false}
                    onKeyPress={handleKeyPress}
                    // onkeydown={handleKeyPress}
                    onChange={handleKeyPress}
                  />
                  <div className='col-md-2 col-12 h-100 pt-4 '>
                    <Form.Item colon={false} valuePropName='checked' defaultValue={true} label='Most Popular' name='mostPopular'>
                      <Switch />
                    </Form.Item>
                  </div>
                  <div className='col-md-2 col-12 pt-4 h-100 '>
                    <Form.Item colon={false} valuePropName='checked' defaultValue={true} label='Out Of Stock' name='isOutOfStock'>
                      <Switch />
                    </Form.Item>
                  </div>
                  <div className='col-md-4 media-column mb-3'>
                    <label>Product Image</label>
                    <Upload listType='picture-card' className='avatar-uploader' showUploadList={false} action={`${app.URL}/api/v1/general/product/logoUpload`} beforeUpload={beforeUpload} onChange={handleChange} headers={header}>
                      {uploadedImg && !imageuploading ? (
                        <img
                          style={{
                            width: '50%',
                          }}
                          className='added-img'
                          src={uploadedImg}
                          alt='avatar'
                        />
                      ) : !uploadedImg && imageuploading ? (
                        <LoadingOutlined style={{ fontSize: '40px' }} />
                      ) : (
                        <div className='d-flex flex-column py-3'>
                          <img className='for-add-img' src={AddPhoto} alt='avatar' />
                          <p className='add-text'>Upload Image</p>
                        </div>
                      )}
                    </Upload>

                    <small className='mt-2 font-weight-light' style={{ fontSize: '14px' }}>
                      Note: Upload max image size 2MB with 1100 x 2000 px.
                    </small>
                  </div>

                  <div className='col-12'>
                    <div className='row'>
                      <TagInput tags={tags} setTags={setTags} />
                    </div>
                  </div>
                  <div className='col-md-12'>
                    <DividerLine />
                    <h1 style={{ fontSize: '1rem', fontWeight: '600', marginBottom: '1rem' }}>Additional Details</h1>

                    <div className='row'>
                      <SelectField
                        options={boxTypes.map((item) => (
                          <Select.Option value={item.boxTypeId}>{item.type}</Select.Option>
                        ))}
                        name='boxTypeId'
                        label='Generic Box Code'
                        col='col-md-2 col-6'
                        required={status == 'active' ? true : false}
                        rules={[
                          {
                            required: status == 'active' ? true : false,
                            message: 'Select box type',
                          },
                        ]}
                        placeholder='Select box type'
                      />
                      {/* <SelectField
                        options={cisPanelCode.map((item) => (
                          <Select.Option value={item.resultElementGroupId}>{item.cisPanelCode}</Select.Option>
                        ))}
                        name='resultElementGroupId'
                        label='CIS Panel Code'
                        col='col-md-2 col-6'
                        required={status == 'active' ? true : false}
                        rules={[
                          {
                            required: status == 'active' ? true : false,
                            message: 'Select CIS Panel Code',
                          },
                        ]}
                        placeholder='Select CIS Panel Code'
                      /> */}
                      <div className='col-md-8 col-12'>
                        <TagSelect
                          col='col-4'
                          options={cisPanelCode.map((item) => {
                            if (!cisPanelCodes.includes(item.resultElementGroupId)) {
                              return (
                                <Select.Option value={item.resultElementGroupId}>
                                  {item.cisPanelName} ({item.cisPanelCode})
                                </Select.Option>
                              );
                            }
                          })}
                          tags={cisPanelCodes}
                          setTags={setCisPanelCodes}
                          label='CIS Panel Codes'
                          cisPanelCode={cisPanelCode}
                          isPanelCode={true}
                        />
                      </div>
                    </div>
                  </div>
                  <div className='col-md-12'>
                    <DividerLine />
                    <div className='row'>
                      {/* {whatMeasuredData?.map((i, indx) => {
                        return (
                          <MeasuredSpace
                            elementKey={indx}
                            renderListType={renderListType}
                            MeasuredCategoriesArray={MeasuredCategoriesArray}
                            beforeUpload={beforeUpload}
                            setrenderuploadedImg={setrenderuploadedImg}
                            setrenderuploadedImg1={setrenderuploadedImg1}
                            setrenderuploadedImg2={setrenderuploadedImg2}
                            setrenderuploadedImg3={setrenderuploadedImg3}
                            renderuploadedImg={renderuploadedImg}
                            imagerenderuploading={imagerenderuploading}
                            setimagerenderuploading={setimagerenderuploading}
                            renderuploadedImg1={renderuploadedImg1}
                            renderuploadedImg2={renderuploadedImg2}
                            renderuploadedImg3={renderuploadedImg3}
                            header={header}
                            whatMeasuredData={whatMeasuredData}
                            setwhatMeasuredData={setwhatMeasuredData}
                          />
                        );
                      })} */}

                      {whatMeasuredData.length > 0 && (
                        <table id='customers' className=' w-75 mb-3'>
                          <thead>
                            <tr>
                              <th>Category</th>
                              <th>Render Type</th>
                              <th>Description</th>
                              <th>Image</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {whatMeasuredData.map((item) => {
                              const findCategory = MeasuredCategoriesArray.find((cat) => cat.productWhatMeasuredId === item.productWhatMeasuredId);
                              const renderType = renderListType.find((rend) => rend.value === item.renderType);
                              // Create a temporary element to convert the HTML string into DOM elements
                              const tempElement = document.createElement('div');
                              tempElement.innerHTML = item?.data;
                              // Extract text content from the element (removes HTML tags)
                              const plainText = tempElement.textContent || tempElement.innerText;
                              return (
                                <tr>
                                  <td className='px-2'>{findCategory?.title}</td>
                                  <td>{renderType?.title}</td>
                                  <td className='w-50'>
                                    {plainText?.slice(0, 30)} {plainText?.length > 30 && '...'}
                                  </td>
                                  <td>{item?.image ? 'Yes' : 'No'}</td>
                                  <td className='d-flex justify-content-center gap-2 px-2'>
                                    <img className='cursor-pointer' style={{ width: '25px' }} alt='edit btn img' src={editBtnImg} onClick={() => clickWhatMeasured(item.productWhatMeasuredElementId, 'edit')} />
                                    <img className='cursor-pointer' style={{ width: '25px' }} alt='delete btn img' src={Delete} onClick={() => clickWhatMeasured(item.productWhatMeasuredElementId, 'delete')} />
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      )}

                      <div
                        onClick={() => {
                          setaddWhatMeasured(true);
                        }}
                        style={{
                          display: whatMeasuredData?.length < 4 ? 'block' : 'none',
                        }}
                        type='primary'
                        className={`btn btn-primary patient-cancel-btn button col-md-3 mx-md-2 mb-4 `}
                      >
                        Add Details
                      </div>
                    </div>
                  </div>
                </>
              )}

              <button onClick={goBack} type='primary' className={`btn btn-primary button patient-cancel-btn  col-md-3 mx-md-2 mr-2 mb-2`}>
                Cancel
              </button>
              <button disabled={!isWrite} type='primary' className={`btn btn-primary button col-md-3 mb-2`} htmlType='submit'>
                Save
              </button>
            </div>
          </div>
        </Form>
      )}

      <Modal
        size='lg'
        show={Object.keys(measuredModal).length > 0 ? true : false}
        onHide={() => {
          setMeasuredModal({});
        }}
        style={{
          background: '#e1e1e131',
        }}
      >
        <Modal.Header>
          <Modal.Title className='text-center w-100'>What Measured Category</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <MeasuredForm
            onHide={() => {
              setMeasuredModal({});
            }}
            measuredModal={measuredModal}
            renderListType={renderListType}
            MeasuredCategoriesArray={MeasuredCategoriesArray}
            beforeUpload={beforeUpload}
            imagerenderuploading={imagerenderuploading}
            setimagerenderuploading={setimagerenderuploading}
            header={header}
            updateWhatMeasured={updateWhatMeasured}
            screen={'update'}
            setwhatMeasuredData={setwhatMeasuredData}
            whatMeasuredData={whatMeasuredData}
          />
        </Modal.Body>
      </Modal>

      <Modal
        size='lg'
        show={addWhatMeasured}
        onHide={() => {
          setaddWhatMeasured(false);
        }}
        style={{
          background: '#e1e1e131',
        }}
      >
        <Modal.Header>
          <Modal.Title className='text-center w-100'>What Measured Category</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <MeasuredForm
            onHide={() => {
              setaddWhatMeasured(false);
            }}
            renderListType={renderListType}
            MeasuredCategoriesArray={MeasuredCategoriesArray}
            beforeUpload={beforeUpload}
            imagerenderuploading={imagerenderuploading}
            setimagerenderuploading={setimagerenderuploading}
            header={header}
            createWhatMeasured={createWhatMeasured}
            setwhatMeasuredData={setwhatMeasuredData}
            whatMeasuredData={whatMeasuredData}
            screen={'update'}
          />
        </Modal.Body>
      </Modal>

      {addUserResp.code === 200 ? (
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title className='text-center'>Success</Modal.Title>
          </Modal.Header>
          <Modal.Body>{addUserResp.message}</Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      ) : addUserResp.code === 400 ? (
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title className='text-center'>Failed</Modal.Title>
          </Modal.Header>
          <Modal.Body>{addUserResp.data.message}</Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      ) : (
        <Modal show={show} onHide={handleClose}>
          {/* <Modal.Header closeButton>
            <Modal.Title>Confirmation</Modal.Title>
          </Modal.Header> */}
          <Modal.Body>Are you sure you want to update product?</Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={handleClose}>
              Cancel
            </Button>
            {console.log(loading)}
            <Button disabled={loading} variant='primary' htmlType='submit' onClick={submitData}>
              {loading ? <LoadingOutlined /> : 'Confirm'}
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
};

export default EditProducts240724;
