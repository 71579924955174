import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { app } from '../../config';
import { useNavigate } from 'react-router-dom';
import { Pagination, Input, Form, Select, Row, Col, DatePicker, message } from 'antd';
import { useTranslation } from 'react-i18next';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { EditTwoTone, BranchesOutlined, PrinterOutlined, LoadingOutlined } from '@ant-design/icons';
import getHeaders from '../../utils/authHeaders';
import ListinngCard from '../../layouts/ListingCard';
import { dateConverter } from '../../utils/utcDateConversionCommon';
import AddNewImg from '../../assets/images/add-new.png';
import lockIcon from '../../assets/images/lock.png';
import { SearchBar } from '../../layouts/SearchBar';
import { useRef } from 'react';
import Barcode from 'react-barcode';
import { useReactToPrint } from 'react-to-print';
import NoDataFound from '../../layouts/NoDataFound';
import LoaderImage from '../LoaderImage';

const GenericBoxManagement = () => {
  const [editbtn, setEditBtn] = useState('none');
  const [pageSize, setPageSize] = useState(10);
  const [fetchPartners, setFetchPartners] = useState([]);
  const [updbatchId, setUpdBatchId] = useState('');
  const [delBatchId, setDelBatchId] = useState('');
  const [pagesCount, setPagesCount] = useState(0);
  const [searchShow, setSearchShow] = useState({});
  const [renderList, setRenderList] = useState([]);
  const [tableShow, setTableShow] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [err, setErr] = useState('');
  const [tableHeaders, setTableHeaders] = useState([]);
  const [show, setShow] = useState(false);
  const [lockModalShow, setLockModalShow] = useState(false);
  const [delBatchResp, setDelBatchResp] = useState({});
  const [lockBatchResp, setLockBatchResp] = useState({});
  const [batchListingModal, setBatchListingModal] = useState(false);
  const [batchListingResp, setBatchListingResp] = useState([]);
  const handleClose = () => setShow(false);
  const handleLockClose = () => setLockModalShow(false);
  const handleBatchListingClose = () => setBatchListingModal(false);
  const [loader, setLoader] = useState(false);

  let navigate = useNavigate();
  let convertedObj;

  const user = JSON.parse(localStorage.getItem('user'));
  if (user.userType === 'A') setEditBtn('block');
  const viewBatchDetails = (data) => {
    localStorage.setItem('batchId', JSON.stringify(data.batchId));
    console.log('Local Storage Set');
    navigate(`/genericbox/batchreport`);
  };

  let printRef = useRef();

  const pageStyle = `
       @page {
        size: 4in 1in; /* Specify your custom page size here (width x height) */
           marginTop: 2px;
           margin: 0;
           padding: 0;
       }
      //   @page {
      //   size: 8.5in 11.5in; /* Specify your custom page size here (width x height) */
      //      marginTop: 4px;
      //      margin: 0;


      //  }
       body {
         font-family: Arial, sans-serif;
         margin: 0;
       }
       `;

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    pageStyle: pageStyle,
  });

  const [displayBarCode, setDisplayBarCode] = useState([]);
  const [printLoader, setPrintLoader] = useState(false);
  const [batchID, setBatchID] = useState('');

  const generateBarCode = (batch) => {
    // let batch = [
    //   {
    //     genBoxId: 7424,
    //     type: 'G01',
    //     batchId: 'GB-24-0000064',
    //     verificationCount: 2,
    //     serialNo: [
    //       {
    //         serialNo: 'G01-777009425',
    //         isBind: 0,
    //       },
    //     ],
    //   },
    //   {
    //     genBoxId: 7434,
    //     type: 'G02',
    //     batchId: 'GB-24-0000064',
    //     verificationCount: 3,

    //     serialNo: [
    //       {
    //         serialNo: 'G02-787635818',
    //         isBind: 0,
    //       },
    //     ],
    //   },
    //   {
    //     genBoxId: 7454,
    //     type: 'G03',
    //     batchId: 'GB-24-0000064',
    //     verificationCount: 2,

    //     serialNo: [
    //       {
    //         serialNo: 'G03-792226500',
    //         isBind: 0,
    //       },
    //     ],
    //   },
    // ];
    let barCodes = [];
    for (let i = 0; i < batch.length; i++) {
      for (let j = 0; j < batch[i].serialNo.length; j++) {
        barCodes.push(
          <div>
            <div className='d-flex'>
              {/* left */}
              <Barcode margin={0} marginLeft={10} marginRight={14} height={73} value={batch[i].serialNo[j].serialNo} />
              {/* right */}
              <Barcode margin={0} marginLeft={12} marginRight={10} height={73} value={batch[i].serialNo[j].serialNo} />
            </div>
            {batch[i].verificationCount === 3 && (
              <div className='d-flex'>
                {/* left */}
                <Barcode margin={0} marginLeft={10} marginRight={14} height={73} value={batch[i].serialNo[j].serialNo} />
                {/* right */}
                <div style={{ width: '81.5%' }}></div>
              </div>
            )}
          </div>
        );
      }
    }
    setDisplayBarCode(barCodes);

    setTimeout(() => {
      setPrintLoader(false);
      handlePrint();
      setBatchID('');
    }, 1000);
  };

  const genericBoxByBatchId = async (formData) => {
    console.log(formData);
    const batchResp = await axios.post(`${app.URL}/api/v1/admin/genericbox/batchreport`, formData, { headers: getHeaders() });
    batchResp ? console.log(batchResp.data.result.data.listing) : '';
    batchResp && generateBarCode(batchResp.data.result.data.listing);
  };

  
  const handleRePrint = async (batchId) => {
    // console.log(batchId, qty, printCount);
    // return;
    setBatchID(batchId);
    setPrintLoader(true);
    let formData = {
      batchId: batchId,
      // limit: qty,
      limit: "",
      offset: 0,
      searchBy: '',
      searchTxt: '',
    };
    await genericBoxByBatchId(formData);
  };
  const addInventory = () => {
    navigate('/genericbox/create', { replace: true });
  };

  const lockBatch = async () => {
    console.log('Consoling updated batch');
    console.log(updbatchId);
    const res = await axios.post(`${app.URL}/api/v1/admin/inventory/updatebatch`, { batchId: updbatchId }, { headers: getHeaders() });
    console.log(res);
    if (res && res.data.result.success) {
      console.log(res.data.result);
      setLockModalShow(false);
    }
  };
  const lockBatchFunc = (data) => {
    console.log(data);
    setLockModalShow(true);
    setUpdBatchId(data.batchId);
  };
  const deleteBatch = async (batchId) => {
    console.log('Consoling batch deleted');
    console.log(delBatchId);
    const res = await axios.delete(`${app.URL}/api/v1/admin/inventory/delete/${delBatchId}`, { headers: getHeaders() });
    console.log(res);
    if (res && res.data.result.success) {
      console.log(res.data.result);
      setLockBatchResp(res.data.result);
      setShow(false);
      // window.location.reload()
    }
  };
  const deleteBatchFunc = (data) => {
    console.log(data);
    setShow(true);
    setDelBatchId(data.batchId);
  };
  const inventoryListingApi = async (formData) => {
    console.log(formData);
    setLoader(true);

    const partnerResp = await axios.post(`${app.URL}/api/v1/admin/genericbox/batchlisting`, formData, { headers: getHeaders() });
    // console.log(partnerResp)
    partnerResp ? console.log(partnerResp.data.result.data) : '';
    partnerResp ? (convertedObj = dateConverter(partnerResp.data.result.data.data, 'issueDate')) : ' ';
    partnerResp.data.result ? setFetchPartners(convertedObj) : ' ';
    partnerResp.data.result ? setPagesCount(partnerResp.data.result.data.count) : ' ';
    partnerResp.data.result ? setRenderList(convertedObj) : ' ';
    
    partnerResp.data.result && setLoader(false);

  };
  const onChange = async (page, pageSize) => {
    console.log(page, pageSize);
    setPageSize(pageSize);
    setCurrentPage(page);
    if (!page) page = 1;
    let limit = pageSize;
    try {
      let reqObj;
      if (Object.keys(searchShow).length !== 0) {
        reqObj = {
          limit: limit,
          offset: limit * (page - 1),
          searchBy: searchShow.searchBy,
          searchTxt: searchShow.searchTxt,
        };
      } else {
        reqObj = {
          limit: limit,
          offset: limit * (page - 1),
          searchBy: 'batchNo',
          searchTxt: '',
        };
      }
      console.log(reqObj);
      await inventoryListingApi(reqObj);
    } catch (e) {
      console.log('Error');
      if (e.message) setErr(e.message);
      setTableShow(false);
    }
  };

 
  useEffect(() => {
    let formData = {
      limit: pageSize,
      offset: 0,
      searchBy: 'batchNo',
      searchTxt: '',
    };
    inventoryListingApi(formData);

  }, [lockModalShow, show]);

  let searchItems = [
    {
      name: 'Batch No.',
      value: 'batchNo',
    },
    
  ];

  const onFinish = async (values) => {
    console.log(values);
    setSearchShow(values);
    let formData = {
      limit: 10,
      offset: 0,
      searchBy: values.searchBy,
      searchTxt: values.searchTxt,
    };
    if (!formData.searchTxt) message.error('Please enter search text');
    else await inventoryListingApi(formData);
  };
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const fetchGenericBoxByBatchId = async (batchId) => {
    console.log(batchId);
    setBatchListingResp([]);
    const responseData = await axios.get(`${app.URL}/api/v1/admin/genericbox/bybatch/${batchId}`, { headers: getHeaders() });
    console.log(responseData);
    if (responseData.status === 200) {
      setBatchListingResp(responseData.data.result.data);
    }
  };

  const handleOnChange = async (e) => {
    if (e.target.value.length === 0) {
      // getData();
      let formData = {
        limit: pageSize,
        offset: 0,
        searchBy: 'batchNo',
        searchTxt: '',
      };
      setSearchShow({});
      await inventoryListingApi(formData);
    }
  };

  console.log(renderList);
  return (
    <div className='dashboard-card container-fluid'>
      <div className='row p-0 pb-4'>
        <div className='for-before col-12 col-md-7 head fs-3 fw-bold' style={{ backgroundColor: '#a7c1d9', borderBottomRightRadius: '10px', color: '#FFFFFF' }}>
          Generic Box
        </div>
        <div style={{ display: 'none' }}>
          <div ref={printRef}>{displayBarCode.map((item) => item)}</div>
        </div>

        <div className='col-12 col-md-5 d-flex justify-content-center'>
          <div onClick={addInventory} className='icon-btn'>
            <img src={AddNewImg} />
            <p>Add Generic Box</p>
          </div>
        </div>
      </div>
      <div style={{ display: 'none' }}>
        <div
          style={{
            margin: '0px !important',
            padding: '0px',
            width: '100% !important',
            height: '100% !important',
          }}
          ref={printRef}
        >
          {displayBarCode.map((item) => item)}
        </div>
      </div>

      <SearchBar onFinish={onFinish} onFinishFailed={onFinishFailed} searchItems={searchItems} onChange={handleOnChange} />

      <div
        className=' table-scrollbar'
        style={{
          overflowX: 'auto',
        }}
      >
        {loader && <LoaderImage />}
        {renderList.length > 0 ? (
          <table id='customers' className='w-100'>
            <thead class=''>
              <tr>
                <th>Batch No.</th>
                {/* <th>Title</th> */}
                <th>Date</th>
                <th>Quantity</th>
                <th>Actions</th>
                {/* <th></th> */}
              </tr>
            </thead>
            <tbody>
              {renderList.map((tableValues, index) => (
                <tr>
                  <td
                  // onClick={() => {
                  //   fetchGenericBoxByBatchId(tableValues.batchId);
                  //   setBatchListingModal(true);
                  // }}
                  // className='batchlink'
                  >
                    {tableValues.batchId ? tableValues.batchId : 'null'}
                  </td>
                  {/* <td>{tableValues.type ? tableValues.type : 'null'}</td> */}
                  <td>{tableValues.issueDate ? tableValues.issueDate : 'null'}</td>
                  <td>{tableValues.quantity ? tableValues.quantity : 'null'}</td>
                  <td>
                    {' '}
                    <button className='btn mx-2 text-light' onClick={() => viewBatchDetails(tableValues)}>
                      View
                    </button>
                    <button disabled={batchID === tableValues.batchId} className='btn mx-2' onClick={() => handleRePrint(tableValues.batchId)}>
                      {printLoader && batchID === tableValues.batchId ? (
                        <LoadingOutlined
                          style={{
                            fontSize: '1.5rem',
                            color: 'white',
                          }}
                        />
                      ) : (
                        <PrinterOutlined
                          style={{
                            fontSize: '1.5rem',
                            color: 'white',
                          }}
                        />
                      )}
                    </button>
                  </td>
                  {/* <td> </td> */}
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <>{!loader && <NoDataFound />}</>
        )}
      </div>

      <div className='row'>{currentPage && pagesCount ? <Pagination style={{ marginTop: '10px' }} onChange={onChange} defaultCurrent={currentPage} total={pagesCount} showTotal={(total) => `Total ${total} items`} showSizeChanger /> : ' '}</div>
      <Modal show={batchListingModal} onHide={handleBatchListingClose}>
        <Modal.Header closeButton>
          <Modal.Title className='text-center'>Batch: {batchListingResp[0]?.batchId}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Item>
            {batchListingResp.map((item) => (
              <div className='row mb-4' key={item.boxTypeId}>
                <div className='col-md-6'>
                  <label>Type</label>
                  <Input value={item.type} />
                </div>
                <div className='col-md-6'>
                  <label>Quantity</label>
                  <Input value={item.boxQty} />
                </div>
              </div>
            ))}
          </Form.Item>
        </Modal.Body>
        {/* <Modal.Footer></Modal.Footer> */}
      </Modal>

      {delBatchResp.code === 200 ? (
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title className='text-center'>Success</Modal.Title>
          </Modal.Header>
          <Modal.Body>Batch Deleted successfully</Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      ) : delBatchResp.code === 400 ? (
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title className='text-center'>Failed</Modal.Title>
          </Modal.Header>
          <Modal.Body>{delBatchResp.data.message}</Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      ) : (
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure, you want to delete the Batch?</Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={handleClose}>
              Cancel
            </Button>
            <Button
              variant='primary'
              htmlType='submit'
              onClick={() => {
                deleteBatch(tableValues.batchId);
              }}
            >
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      {lockBatchResp.code === 200 ? (
        <Modal show={lockModalShow} onHide={handleLockClose}>
          <Modal.Header closeButton>
            <Modal.Title className='text-center'>Success</Modal.Title>
          </Modal.Header>
          <Modal.Body>Batch Locked successfully</Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      ) : lockBatchResp.code === 400 ? (
        <Modal show={lockModalShow} onHide={handleLockClose}>
          <Modal.Header closeButton>
            <Modal.Title className='text-center'>Failed</Modal.Title>
          </Modal.Header>
          <Modal.Body>{lockBatchResp.data.message}</Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      ) : (
        <Modal show={lockModalShow} onHide={handleLockClose}>
          <Modal.Header closeButton>
            <Modal.Title>Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure, you want to lock the Batch?</Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={handleLockClose}>
              Cancel
            </Button>
            <Button variant='primary' htmlType='submit' onClick={lockBatch}>
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
};

export default GenericBoxManagement;
