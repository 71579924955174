import React, { useEffect, useState } from 'react';
import { Switch, Form, Upload, Space, Divider } from 'antd';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import AddPhoto from '../assets/images/add.png';
import { LoadingOutlined, UploadOutlined } from '@ant-design/icons';
import getHeaders from '../utils/authHeaders';
import { useLocation } from 'react-router-dom';
import noimage from '../assets/img/no-image.jpg';
import LoaderImage from '../pages/LoaderImage';
import TestComponent from '../pages/Products/TestComponent';

// import { TextAreaField } from './FormFields';

export const UpdateRecord = ({isRichTextEditor,form,textEditorValue, onChange, loading, extras, upload, uploadedImg, imageuploading, userImage, action, beforeUpload, handleuploadChange, pageTitle, response, inputs, inputMask, textareas, selects, initialValues, onFinish, onFinishFailed, switchBtn, goBack, addUserResp, handleClose, show, submitData, updateMessage, videoUrl,setUploadedImg=()=>{}, setUserImage=()=>{} }) => {
  let headers = getHeaders();
  let location = useLocation();
  const [isWrite, setIsWrite] = useState(true);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    const [filteredRoute] = user.result.data.permission.filter((item) => item.action === location.pathname.slice(1));
    setIsWrite(filteredRoute.isWrite);
  }, []);
  console.log(userImage);
  return (
    <div className={`dashboard-card pb-5 ${location.pathname === '/feedback/update' ? 'container' : 'container-fluid'}`}>
      <div className='row p-0 pb-4'>
        <div className='for-before col-12 col-md-7 head fs-3 fw-bold' style={{ backgroundColor: '#a7c1d9', borderBottomRightRadius: '10px', color: '#FFFFFF' }}>
          {pageTitle}
        </div>
      </div>
      {response && !loading ? (
        <Form form={form} name='basic' onFinish={onFinish} onFinishFailed={onFinishFailed} autoComplete='off' initialValues={initialValues} className='row p-5 px-3 pb-1'>
          <div className='col-md-8 col-12 input-column'>
            <div className='row'>
              {inputs && inputs.map((item) => item)}
              {inputMask && inputMask.map((item) => item)}
              {selects && selects.map((item) => item)}
              {textareas && textareas.map((item) => item)}
              {switchBtn && (
                <div className='col-lg-12 mb-2'>
                  <Form.Item label='Active' className='selector-main' colon={false} valuePropName='checked' name='isActive'>
                    <Switch />
                  </Form.Item>
                </div>
              )}
              {extras && extras.map((item) => item)}

              {isRichTextEditor && (
                <div className={`  col-md-12 col-12 mb-5  required`}>
                  <TestComponent title={pageTitle} onChange={onChange} textvalue={textEditorValue} />
                </div>
              )}

              <button onClick={goBack} type='primary' className='btn btn-primary button patient-cancel-btn col-md-4 mx-md-2 mr-2 mb-2'>
                Cancel
              </button>

              <button type='primary' disabled={!isWrite} className='btn btn-primary button col-md-4 mb-2' htmlType='submit'>
                Save
              </button>
            </div>
          </div>
          {upload && location.pathname !== '/patient/update' && (
            <div className='col-md-4 col-12 order-first order-md-last image-column'>
              <Form.Item valuePropName='fileList'>
                
              <Upload listType='picture-card' className='avatar-uploader' showUploadList={false} action={action} beforeUpload={beforeUpload} onChange={handleuploadChange} headers={headers}>
                  {response && userImage ? (
                    <img className='added-img' src={uploadedImg} alt='avatar' />
                  ) : uploadedImg && !imageuploading ? (
                    <img className='added-img' src={uploadedImg} alt='avatar' />
                  ) : uploadedImg && imageuploading ? (
                    <LoadingOutlined style={{ fontSize: '40px' }} />
                  ) : (
                    <div className='d-flex flex-column py-3'>
                      <img className='for-add-img' src={AddPhoto} alt='s' />
                      <p className='add-text'>Upload Image</p>
                    </div>
                  )}
                </Upload>

                {( uploadedImg && !imageuploading) && (
                  <button onClick={() => {setUploadedImg(''); setUserImage(false)}} style={{ backgroundColor: 'rgb(242, 29, 47)' }} className='add-btn'>
                    Remove
                  </button>
                )}
              </Form.Item>
            </div>
          )}
          {location.pathname === '/patient/update' && (
            <div className='col-md-3 col-12 order-first order-md-last image-column'>
              <Form.Item valuePropName='fileList'>
                <img style={{ width: '100%', height: '100%' }} src={uploadedImg ? uploadedImg : noimage} alt='avatar' />
              </Form.Item>
            </div>
          )}
          {/* {(location.pathname === '/anura/elements/update' || '/labtest/update' || 'boxtype/update') && ( */}
          {videoUrl && (
            <div className='col-md-4 col-12 order-first order-md-last mb-3 m-md-0 image-column'>
              <video style={{ width: '100%', borderRadius: '10px', boxShadow: '3px 3px 10px #ccc' }} controls title='YouTube video player'>
                <source src={videoUrl} type='video/mp4'></source>
              </video>
            </div>
          )}
          {/* )} */}
        </Form>
      ) : (
        <LoaderImage />
      )}
      {addUserResp.code === 200 ? (
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title className='text-center'>Success</Modal.Title>
          </Modal.Header>
          <Modal.Body>{addUserResp.message}</Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      ) : addUserResp.code === 400 ? (
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title className='text-center'>Failed</Modal.Title>
          </Modal.Header>
          <Modal.Body>{addUserResp.data.message}</Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      ) : (
        <Modal show={show} onHide={handleClose}>
          {/* <Modal.Header closeButton>
                      <Modal.Title>Confirmation</Modal.Title>
                    </Modal.Header> */}
          <Modal.Body>{updateMessage}</Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={handleClose}>
              Cancel
            </Button>
            <Button disabled={loading} variant='primary' htmlType='submit' onClick={submitData}>
              {loading ? <LoadingOutlined /> : 'Confirm'}
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
};
