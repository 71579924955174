import CalenderImg from '../assets/images/calender.png';
import DashboardCard from '../layouts/DashboardCard';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { app } from '../config';
import getHeaders from '../utils/authHeaders';
import moment from 'moment';
import Modal from 'react-bootstrap/Modal';
import InputMask from 'react-input-mask';
import { Button, Col, Form, Input, Pagination, Row, DatePicker, Select, Modal as AntModal, message, Switch } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import logo from '../assets/images/image.png';
// import fetchOrderDetails from '../hooks/fetchOrderDetails';
import NoDataFound from '../layouts/NoDataFound';
import { SearchBar } from '../layouts/SearchBar';
import LoaderImage from './LoaderImage';
import RadarChart from './RadarChart';
import { Cardiology } from './Cardiology';
import { TableAntDesign } from './TableAntDesign';
import { durationSelection, onChangeMethod } from '../utils/SortingUtils';
import TestOrderDetailModal from './TestOrderDetailModal';
// import { Table } from 'reactstrap';

const Dashboard2 = () => {
  const [activeIndex, setActiveIndex] = useState(1);
  const [refreshView, setRefreshView] = useState(false);
  const [count, setCount] = useState();
  const [activatedCount, setActivatedCount] = useState();
  const [awatingCount, setawatingCount] = useState();
  const [completeCount, setCompletedCount] = useState();
  const [renderList, setRenderList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [searchShow, setSearchShow] = useState({});
  const [tableHeaders, setTableHeaders] = useState([]);
  const [tableShow, setTableShow] = useState(false);
  const [show, setShow] = useState(false);
  const [err, setErr] = useState();
  const [phoneNumber, setPhoneNumber] = useState();
  const [orderDetails, setOrderDetails] = useState(null);
  const [uploadObj, setUploadObj] = useState({});
  const [searchValue, setSearchValue] = useState('');
  const [searchType, setSearchType] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [orderDateFrom, setorderDateFrom] = useState('');
  const [orderDateTo, setorderDateTo] = useState('');
  const [loading, setLoading] = useState(false);
  const [reportData, setReportData] = useState([]);
  const [testOrderDetails, setTestOrderDetails] = useState({});

  const [isVisible, setIsVisible] = useState(false);

  console.log(app.URL);
  const showModal = async () => {
    // console.log('Show modal Running');
    // const fetchReportdata = await axios.get(`${app.URL}/api/v1/admin/testresult/view/OR-23000138`, { headers: getHeaders() });
    // console.log(fetchReportdata);
    // fetchReportdata.data.result.success && setReportData(fetchReportdata.data.result.data);
    // fetchReportdata.data.result.success && setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleClose = () => setShow(false);
  const { RangePicker } = DatePicker;
  let activatedProdHeaders = ['Order No.', 'Customer', 'Product', 'Activation Date & Time', 'Collection Date & Time', 'Serial No.', 'Action'];
  let awaitingResultHeaders = ['Order No.', 'Customer Name', 'Accession No', 'Product Name', 'CIS Code', 'CIS Name', 'Send to Lab', 'Action'];
  let completed = ['Order No.', 'Customer Name', 'Accession No', 'Product Name', 'CIS Code', 'CIS Name', 'Completion Date', 'Action'];
  let rejected = ['Order No.', 'Customer Name', 'Product Name', 'Reason for Rejection', 'Date Rejected'];

  let headersMap = new Map();
  headersMap.set(1, activatedProdHeaders);
  headersMap.set(2, awaitingResultHeaders);
  headersMap.set(3, completed);
  headersMap.set(4, rejected);
  

  const loginUserObj = JSON.parse(localStorage.getItem('user'));
  let userObj = loginUserObj ? loginUserObj.result.data.user : {};

  const uploadData = async (orderObj, orderDetails) => {
    try {
      // console.log('Order Object: ');
      // console.log(orderObj);
      // console.log('orderDetails: ');
      // console.log(orderDetails);
      const res = await axios.post(`${app.URL}/api/v1/admin/dashboard/sendtolab/${orderObj.testOrderId}`, { ...orderObj, ...orderDetails, ...{ adminUserId: userObj.userId } }, { headers: getHeaders() });
      console.log(res);
    } catch (err) {
      console.log(err);
      if (err) console.log(err.response.data.message);
      else return;
    }
  };

  const handleChange = (event) => {
    setPhoneNumber(event.target.value);
    // handle phone number input here
  };

  const setRender = (data) => {
    setLoading(false);
    setRenderList(data);
  };

  const dashboardListingApi = async (formData) => {
    // console.log(formData);
    setLoading(true);
    const resp = await axios.post(`${app.URL}/api/v1/admin/dashboard/listing`, formData, { headers: getHeaders() });
    // console.log(resp);
    // roleResp.data ? setFetchedRoles(roleResp.data.result.data) : ' ';
    resp.data ? setCount(resp.data.result.data.count) : ' ';
    resp.data ? setRender(resp.data.result.data.data) : ' ';
  };

  const getTestOrderDetail = async (testOrderId) => {
    const res = await axios.get(`${app.URL}/api/v1/admin/dashboard/view/${testOrderId}`, { headers: getHeaders() });
    // console.log(res.data.result.data);

    res.data && setTestOrderDetails(res.data.result.data);
    res.data && setIsVisible(true);
  };

  const activatedProductRows = (tableData) => {
    return (
      <>
        {/* <td>{tableDate.testOrderId ? tableDate.testOrderId : 'null'}</td> */}
        <td>{tableData.orderNo ? tableData.orderNo : 'null'}</td>
        <td>{tableData.patientName ? tableData.patientName : 'null'}</td>
        {/* <td>{tableData.partnerName ? tableData.partnerName : 'null'}</td> */}
        <td>{tableData.productName ? tableData.productName : 'null'}</td>
        <td>{tableData.orderDate ? moment(tableData.orderDate).format('MM-DD-YYYY HH:mm') : '-'}</td>
        <td>{tableData.collectionTime ? moment(tableData.collectionTime).format('MM-DD-YYYY HH:mm') : '-'}</td>
        <td>{tableData.serialNo ? tableData.serialNo : 'null'}</td>
        <td>
          <button className='btn text-light mx-2' onClick={() => getTestOrderDetail(tableData.testOrderId)}>
            View
          </button>
        </td>
      </>
    );
  };

  const awaitngProductRows = (tableData) => {
    // console.log(tableData);
    return (
      <>
        {/* <td>{tableDate.testOrderId ? tableDate.testOrderId : 'null'}</td> */}
        <td>{tableData.orderNo ? tableData.orderNo : 'null'}</td>
        <td>{tableData.patientName ? tableData.patientName : 'null'}</td>
        <td>{tableData.accessionNo ? tableData.accessionNo : 'null'}</td>
        <td>{tableData.productName ? tableData.productName : 'null'}</td>
        <td>{tableData.cisPanelCode ? tableData.cisPanelCode : 'null'}</td>
        <td>{tableData.cisPanelName ? tableData.cisPanelName : 'null'}</td>
        {/* <td>{tableData.isActive === '1' ? 'Active' : 'Inactive'}</td> */}
        <td>{tableData.sendToLabDateTime ? moment(tableData.sendToLabDateTime).format('MM-DD-YYYY HH:mm') : 'null'}</td>
        <td>
          <button className='btn text-light mx-2' onClick={() => getTestOrderDetail(tableData.testOrderId)}>
            View
          </button>
        </td>
      </>
    );
  };

  const completedProductRows = (tableData) => {
    // console.log(tableData);
    return (
      <>
        {/* <td>{tableDate.testOrderId ? tableDate.testOrderId : 'null'}</td> */}
        <td>{tableData.orderNo ? tableData.orderNo : 'null'}</td>
        <td>{tableData.patientName ? tableData.patientName : 'null'}</td>
        <td>{tableData.accessionNo ? tableData.accessionNo : 'null'}</td>
        <td>{tableData.productName ? tableData.productName : 'null'}</td>
        <td>{tableData.cisPanelCode ? tableData.cisPanelCode : 'null'}</td>
        <td>{tableData.cisPanelName ? tableData.cisPanelName : 'null'}</td>
        {/* <td>{tableData.isActive === '1' ? 'Active' : 'Inactive'}</td> */}
        <td>{tableData.orderDate ? moment(tableData.orderDate).format('MM-DD-YYYY HH:mm') : 'null'}</td>
        <td className='btn-send'>
          <button type='primary' className='btn btn-primary button' onClick={showModal}>
            Report
          </button>{' '}
          <button className='btn text-light mx-2' onClick={() => getTestOrderDetail(tableData.testOrderId)}>
            View
          </button>
        </td>
      </>
    );
  };

  useEffect(() => {
    let formData = {
      limit: 10,
      offset: 0,
      searchBy: 'productName', //partnerName, serialNo, orderNo
      searchTxt: '',
      status: activeIndex, //1 = In-Transit (Inprogress), 2 = Sent to Lab, 3 = Completed
      orderDateFrom: '', //2023-03-03  only give date without time
      orderDateTo: '',
    };
    dashboardListingApi(formData);
    setTableHeaders(headersMap.get(activeIndex));
    // return () => {};
  }, []);

const handleOnChange = async (e) => {
  console.log('Search input changed:', e.target.value);

  if (!e || !e.target || e.target.value === undefined) {
    console.error('Invalid event in handleOnChange');
    return;
  }

  if (e.target.value.length === 0) {
    let formData = {
      limit: 10,
      offset: 0,
      searchBy: 'productName',
      searchTxt: '',
      status: activeIndex,
      orderDateFrom: orderDateFrom ? orderDateFrom : '',
      orderDateTo: orderDateTo ? orderDateTo : '',
    };
    setSearchShow({});
    await dashboardListingApi(formData);
  }
};


  const dashboardListingApiforCount = async (formData) => {
    console.log(formData);

    const apiResp = await axios.post(`${app.URL}/api/v1/admin/dashboard/listing`, formData, { headers: getHeaders() });
    console.log(apiResp);
    apiResp ? console.log(apiResp.data.result.data.count) : ' ';
    apiResp.data && formData.status == 1 ? setActivatedCount(apiResp.data.result.data.count) : ' ';
    apiResp.data && formData.status == 2 ? setawatingCount(apiResp.data.result.data.count) : ' ';
    apiResp.data && formData.status == 3 ? setCompletedCount(apiResp.data.result.data.count) : ' ';
    // apiResp.data ? setFetchedRoles(apiResp.data.result.data) : ' ';
  };

  const onFinish = (values) => {
    console.log('Success:', values);

    setUploadObj({
      comments: values.instructions ? values.instructions : null,
    });
    setShow(true);
  };

  const onSearchFinish = async (values) => {
    console.log('onSearchFinish');

    console.log('Success:', values);
    let formData = {
      limit: 10,
      offset: 0,
      searchBy: values.searchBy, //partnerName, serialNo, orderNo
      searchTxt: values.searchTxt,
      status: activeIndex, //1 = In-Transit (Inprogress), 2 = Sent to Lab, 3 = Completed
      orderDateFrom: orderDateFrom ? orderDateFrom : '', //2023-03-03  only give date without time
      orderDateTo: orderDateTo ? orderDateTo : '',
    };
    if (!values.searchTxt) message.error('Please enter search text');
    // console.log(formData)
    else await dashboardListingApi(formData);
  };

  const onSearchFinishFailed = (values) => {
    console.log('Failed:', values);
  };
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  const submitData = (orderObj) => {
    console.log('submit data calling');
    if (Object.keys(uploadObj).length > 0) {
      uploadData(orderObj, uploadObj);
    }
  };
  useEffect(() => {
    const countAPI = async () => {
      for (let i = 1; i < 4; i++) {
        let formData1 = {
          limit: 10,
          offset: 0,
          searchBy: 'productName', //partnerName, serialNo, orderNo
          searchTxt: '',
          status: i, //1 = In-Transit (Inprogress), 2 = Sent to Lab, 3 = Completed
          orderDateFrom: '', //2023-03-03  only give date without time
          orderDateTo: '',
        };
        const activatedCount = await dashboardListingApiforCount(formData1);
        console.log(activatedCount);
      }
    };
    countAPI();
  }, []);

  const onPageChange = async (page, pageSize) => {
    console.log(page);
    setPageSize(pageSize);
    setCurrentPage(page);
    if (!page) page = 1;
    let limit = pageSize;
    try {
      let reqObj;
      if (Object.keys(searchShow).length !== 0) {
        reqObj = {
          limit: limit,
          offset: limit * (page - 1),
          status: activeIndex,
          searchBy: searchShow.searchFor,
          searchTxt: searchShow.searchItem,
          orderDateFrom: orderDateFrom ? orderDateFrom : '', //2023-03-03  only give date without time
          orderDateTo: orderDateTo ? orderDateTo : '',
        };
      } else {
        reqObj = {
          limit: limit,
          offset: limit * (page - 1),
          status: activeIndex,
          searchBy: 'productName',
          searchTxt: '',
          orderDateFrom: orderDateFrom ? orderDateFrom : '', //2023-03-03  only give date without time
          orderDateTo: orderDateTo ? orderDateTo : '',
        };
      }
      console.log(reqObj);
      await dashboardListingApi(reqObj);
    } catch (e) {
      console.log('Error');
      console.log(e);
      if (e.message) setErr(e.message);
      setTableShow(false);
    }
  };
  // const onDateFilter = async (index,obj) => {
  //   // setTableHeaders(headersMap.get(index))
  //   // setCount('')

  // }
  const durationSelect = async (value) => {
    console.log('value ', value);
    durationSelection(dashboardListingApi, value, setorderDateFrom, setorderDateTo, activeIndex, searchShow, 10, 0, 'productName');
  };
  const onChange = async (date, dateString) => {
    console.log(date, dateString);
    onChangeMethod(dashboardListingApi, dateString, setorderDateFrom, setorderDateTo, activeIndex, searchShow, pageSize, pageSize * (currentPage - 1), 'productName');
  };
  const onCardClick = async (index) => {
    setActiveIndex(index);
    console.log('active Index ', index);
    setTableHeaders(headersMap.get(index));
    console.log(headersMap.get(index));
    setCount('');
    setRenderList([]);

    if (index === 4) {
      return; // Skip API call for Rejected section if no data should be displayed
    }

    let formData = {
      limit: 10,
      offset: 0,
      searchBy: 'productName', //partnerName, serialNo, orderNo
      searchTxt: '',
      status: index, //1 = In-Transit (Inprogress), 2 = Sent to Lab, 3 = Completed
      orderDateFrom: '', //2023-03-03  only give date without time
      orderDateTo: '',
    };
    await dashboardListingApi(formData);
  };

  const ActivatedItem = [
    {
      value: 'productName',
      name: 'Product',
    },
    // {
    //   value: 'partnerName',
    //   name: 'Partner',
    // },
    {
      value: 'serialNo',
      name: 'Serial No.',
    },
    {
      value: 'orderNo',
      name: 'Order No.',
    },
    {
      value: 'customerName',
      name: 'Customer Name',
    },
    {
      value: 'collectionDate',
      name: 'Collection Date',
    },
  ];

  const AwaitingItem = [
    {
      value: 'productName',
      name: 'Product',
    },
    {
      value: 'orderNo',
      name: 'Order No.',
    },
    {
      value: 'accessionNo',
      name: 'Accession No.',
    },
    {
      value: 'cisPanelCode',
      name: 'CIS Code',
    },
    {
      value: 'customerName',
      name: 'Customer Name',
    },
  ];

  // console.log(process.env, app)

  return (
    <>
      <div className='dashboard-card container-fluid'>
        <div className='row p-0 pb-4'>
          <div className='for-before col-12 col-lg-4 col-md-6 head fs-3 fw-bold' style={{ backgroundColor: '#a7c1d9', borderBottomRightRadius: '10px', color: '#FFFFFF' }}>
            Dashboard
          </div>
          <div className='col-12 mt-2 col-lg-4 col-md-3'>
            <RangePicker onChange={onChange} />
          </div>
          <TestOrderDetailModal isVisible={isVisible} setIsVisible={setIsVisible} testOrderDetails={testOrderDetails} />
          <div className='col-12 mt-2 col-lg-4 col-md-3' style={{ position: 'relative' }}>
            <img className='calender-img' src={CalenderImg} />
            <select className='form-control drop-down-select' name='dates' id='date' onChange={(e) => durationSelect(e.target.value)}>
              <option value={moment().subtract(24, 'hours').format('YYYY-MM-DD')}>last 24 hours</option>
              <option value={moment().subtract(7, 'd').format('YYYY-MM-DD')}>last week</option>
              <option value={moment().subtract(1, 'months').format('YYYY-MM-DD')}>last month</option>
              <option value={moment().subtract(1, 'year').format('YYYY-MM-DD')}>last year</option>
            </select>
          </div>
        </div>

        <div className='row px-3'>
          <div style={{ position: 'relative' }} className='col-3 my-1' onClick={() => onCardClick(1)}>
            <DashboardCard count={activatedCount} isActive={activeIndex === 1} activatedProducts={true} awaitingProducts={false} cardName={'Activated Product'} />
          </div>
          <div style={{ position: 'relative' }} className=' col-3 my-1' onClick={() => onCardClick(2)}>
            <DashboardCard count={awatingCount} isActive={activeIndex === 2} activatedProducts={false} awaitingProducts={true} cardName={'Awaiting Result'} />
          </div>
          <div style={{ position: 'relative' }} className='col-3 my-1' onClick={() => onCardClick(3)}>
            <DashboardCard count={completeCount} isActive={activeIndex === 3} activatedProducts={false} awaitingProducts={false} cardName={'Completed'} />
          </div>
          <div style={{ position: 'relative' }} className='col-3 my-1' onClick={() => onCardClick(4)}>
            <DashboardCard count={completeCount} isActive={activeIndex === 4} activatedProducts={false} awaitingProducts={false} cardName={'Rejected'} />
          </div>
        </div>

        {/* <RadarChart reportData={reportData} /> */}

        {/* { activeIndex === 4 ? <di>No filters available for the selected section.</di> : <SearchBar onFinish={onSearchFinish} onFinishFailed={onSearchFinishFailed} searchItems={activeIndex === 1 ? ActivatedItem :  AwaitingItem } onChange={handleOnChange} />} */}
        <SearchBar onFinish={onSearchFinish} onFinishFailed={onSearchFinishFailed} searchItems={activeIndex === 1 ? ActivatedItem : AwaitingItem} onChange={handleOnChange} />
        {loading && <LoaderImage />}

        {/* <TableAntDesign /> */}

        <div className='table-scrollbar '>
          {tableHeaders.length > 0 && !loading ? (
            <table id='customers' className='w-100'>
              <thead className='w-100'>
                <tr>
                  {tableHeaders.map((header) => (
                    <th key={header}>{header}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {renderList.length > 0 ? (
                  renderList.map((output, index) => <tr key={index}>{activeIndex === 1 ? activatedProductRows(output) : activeIndex === 2 ? awaitngProductRows(output) : completedProductRows(output)}</tr>)
                ) : (
                  <tr>
                    <td colSpan={tableHeaders.length} className='text-center'>
                      No data available
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          ) : (
            <>{!loading && <NoDataFound />}</>
          )}
        </div>
        {renderList.length > 0 && (
          <div className='row'>
            <Pagination style={{ marginBottom: '20px' }} onChange={onPageChange} defaultCurrent={currentPage} total={count} showTotal={(total) => `Total ${total} items`} showSizeChanger />
          </div>
        )}
      </div>

      {/* <AntModal title='Test Report' open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        <img src={logo} />
      </AntModal> */}
      <Modal size='md' show={isModalOpen} onHide={handleCancel}>
        <Modal.Header closeButton>
          <Modal.Title>Blood Work Cardiology</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='px-2'>
            <h3 className='mb-4'>
              <strong>Patient Info</strong>
            </h3>
            <div style={{ borderTop: '2px dotted #000', width: '50%', marginBottom: '1rem' }}></div>
            <h3 className='mb-4'>
              <strong>About this test report</strong>
            </h3>
            <div style={{ borderTop: '2px dotted #000', width: '50%', marginBottom: '1rem' }}></div>

            <h3 className='mb-4'>
              <strong>Complete Blood Count</strong>
            </h3>
            {reportData.map((item) => (
              <Cardiology title={item.type} pointer={item.testValue} category={item.category} total={item.category[2].end} />
            ))}
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Order - Send To Lab</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {orderDetails ? (
            <div className='col-lg-12'>
              <Form
                initialValues={{
                  remember: true,
                  ['orderNo']: orderDetails.orderNo ? orderDetails.orderNo : '',
                  ['patientName']: orderDetails.patientName ? orderDetails.patientName : '',
                  ['mobile']: orderDetails.mobile ? orderDetails.mobile : '',
                  ['product']: orderDetails.productName ? orderDetails.productName : '',
                  ['serialNo']: orderDetails.serialNo ? orderDetails.serialNo : '',
                  ['sampleQuality']: orderDetails.quantity ? orderDetails.quantity : '',
                  ['status']: orderDetails.statusName ? orderDetails.statusName : '',
                  // ['sampleQuality']: orderDetails.quantity,?
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete='off'
              >
                <div className='row'>
                  <div className='col-lg-6'>
                    <label>
                      Order No. <span className='required-star'>*</span>
                    </label>
                    <Form.Item
                      name='orderNo'
                      defaultValue='1'
                      colon={false}
                      rules={[
                        {
                          required: true,
                          message: 'Enter Order Number',
                        },
                      ]}
                    >
                      <Input className='' placeholder='Enter Order Number' disabled />
                    </Form.Item>
                  </div>
                  <div className='col-lg-6'>
                    <label>
                      Customer <span className='required-star'>*</span>
                    </label>
                    <Form.Item
                      name='patientName'
                      defaultValue='1'
                      colon={false}
                      style={{ wordWrap: 'normal' }}
                      rules={[
                        {
                          required: true,
                          message: 'Enter Patient Name',
                        },
                      ]}
                    >
                      <Input placeholder='Enter Patient Name' disabled />
                    </Form.Item>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-lg-12'>
                    <label>
                      Mobile <span className='required-star'>*</span>
                    </label>
                    <Form.Item
                      name='mobile'
                      defaultValue='1'
                      colon={false}
                      rules={[
                        {
                          required: true,
                          message: 'Enter Mobile Number',
                        },
                      ]}
                    >
                      <InputMask mask='(999) 999-9999' onChange={handleChange} disabled>
                        {(inputProps) => <Input {...inputProps} placeholder='Enter Phone Number' style={{ width: '100% !important' }} disabled />}
                      </InputMask>
                    </Form.Item>
                  </div>
                  {/* <div className='col-lg-6'>
                    <Form.Item
                      name='status'
                      defaultValue='1'
                      colon={false}
                      rules={[
                        {
                          required: true,
                          message: 'Enter Email',
                        },
                      ]}
                    >
                      <Input placeholder='Enter Email' disabled />
                    </Form.Item>
                  </div> */}
                </div>
                <div className='row'>
                  <div className='col-lg-6'>
                    <label>
                      Product <span className='required-star'>*</span>
                    </label>
                    <Form.Item
                      colon={false}
                      name='product'
                      rules={[
                        {
                          required: true,
                          message: 'Enter product name',
                        },
                      ]}
                    >
                      <Input placeholder='Enter product name' className='admin' style={{ width: '100% !important' }} disabled />
                    </Form.Item>
                  </div>
                  <div className='col-lg-6'>
                    <label>
                      Serial No.<span className='required-star'>*</span>
                    </label>
                    <Form.Item
                      colon={false}
                      name='serialNo'
                      rules={[
                        {
                          required: true,
                          message: 'Enter Serial No',
                        },
                      ]}
                    >
                      <Input placeholder='Enter Serial No' className='admin' style={{ width: '100% !important' }} disabled />
                      {/* <InputMask mask='(999) 999-9999' onChange={handleChange}>
                    {(inputProps) => <Input {...inputProps} placeholder='Enter Phone Number' style={{ width: '100% !important' }} />} */}
                      {/* <Input /> */}
                      {/* </InputMask> */}
                      {/* <Input placeholder='Input Contact' /> */}
                    </Form.Item>
                  </div>
                </div>
                {/* <div className='row'>
                  <div className='col-lg-6'>
                    <Form.Item name='sampleQuality'>
                      <Input placeholder='Enter Sample Quality' className='admin' style={{ width: '100% !important' }} disabled />
                    </Form.Item>
                  </div>
                  <div className='col-lg-6'>
                    <Form.Item name='sampleQuality'>
                      <Input placeholder='Enter Sample Quality' className='admin' style={{ width: '100% !important' }} disabled />
                    </Form.Item>
                  </div>
                </div> */}
                <div className='col-lg-12'>
                  <label>Instructions</label>
                  <Form.Item name='instructions'>
                    <TextArea placeholder='Enter Instructions' className='admin' style={{ width: '100% !important' }} />
                  </Form.Item>
                </div>
                {/* <Row >
                  <Col>
                    <Form.Item label='Active' colon={false} valuePropName='checked' name='isActive'>
                      <Switch />
                    </Form.Item>
                  </Col>
                </Row> */}
                <div className='col-lg-12 text-center'>
                  <Button variant='success' className='dashboard col-lg-6 btn-style' htmlType='submit' onClick={() => submitData(orderDetails)}>
                    Send To Lab
                  </Button>
                </div>
              </Form>
            </div>
          ) : (
            ''
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button className='col-lg-2 btn-style' variant='secondary' onClick={handleClose}>
            Cancel
          </Button>
          {/* <Button variant='primary' htmlType='submit' onClick={submitData}>
            Save
          </Button> */}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Dashboard2;
