import moment from "moment";

export const durationSelection = async (listingApi, orderDateFrom, setorderDateFrom, setorderDateTo, status, searchShow, limit, offset, defaultSearch) => {
  setorderDateFrom(orderDateFrom);
  let orderDateTo = moment().format('YYYY-MM-DD');
  setorderDateTo(orderDateTo);

  let formData = {
    limit,
    offset,
    searchBy: Object.keys(searchShow).length !== 0 ? searchShow.searchBy : defaultSearch,
    searchTxt: Object.keys(searchShow).length !== 0 ? searchShow.searchTxt : '',
    status,
    orderDateFrom,
    orderDateTo,
  };
  console.log(formData);
  listingApi(formData);
};

export const onChangeMethod = async (listingApi, dateString, setorderDateFrom, setorderDateTo, status, searchShow, limit, offset, defaultSearch) => {
  
  dateString[0] ? setorderDateFrom(dateString[0]) : '';
  dateString[1] ? setorderDateTo(dateString[1]) : '';
  let formData = {
    limit,
    offset,
    searchBy: Object.keys(searchShow).length !== 0 ? searchShow.searchBy : defaultSearch,
    searchTxt: Object.keys(searchShow).length !== 0 ? searchShow.searchTxt : '',
    status, //1 = In-Transit (Inprogress), 2 = Sent to Lab, 3 = Completed
    orderDateFrom: dateString[0], //2023-03-03  only give date without time
    orderDateTo: dateString[1],
  };
  listingApi(formData);
};