import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import * as xlsx from 'xlsx';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { EditTwoTone } from '@ant-design/icons';
import { Input, Pagination, Form, Select, Card, Row, Col, message } from 'antd';
import { app } from '../../config';
import getHeaders from '../../utils/authHeaders';
import ListinngCard from '../../layouts/ListingCard';

const Ethnicity = () => {
  const { t } = useTranslation(['common']);
  const [editbtn, setEditBtn] = useState('none');
  const [fetchedRoles, setFetchedRoles] = useState([]);
  const [search, setSearch] = useState('');
  const [searchShow, setSearchShow] = useState(false);
  const [pagesCount, setPagesCount] = useState(0);
  const [renderList, setRenderList] = useState([]);
  const [tableShow, setTableShow] = useState(true);
  const [loader, setLoader] = useState(false);

  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [tableHeaders, setTableHeaders] = useState([]);
  const [err, setErr] = useState('');
  let navigate = useNavigate();
  const editEthnicity = (e) => {
    console.log('E ', e);
    localStorage.setItem('ethnicId', e.ethnicId);
    navigate('/ethnicity/update', { replace: true });
  };
  const ethnicityListingApi = async (formData) => {
    setLoader(true);
    const roleResp = await axios.post(`${app.URL}/api/v1/admin/ethnicity/listing`, formData, { headers: getHeaders() });
    roleResp.data ? setFetchedRoles(roleResp.data.result.data) : ' ';
    roleResp.data ? setPagesCount(roleResp.data.result.data.count) : ' ';
    roleResp.data ? setRenderList(roleResp.data.result.data.data) : ' ';
    roleResp.data && setLoader(false);
  };
  const fetchRoles = async () => {
    const user = JSON.parse(localStorage.getItem('user'));
    let header = ['Ethnicity', 'Description', 'Status', 'Action'];
    setTableHeaders(header);
    if (user.userType === 'A') setEditBtn('block');
    let formData = {
      limit: pageSize,
      offset: 0,
      searchTxt: '',
    };
    await ethnicityListingApi(formData);
  };
  useEffect(() => {
    setLoader(true);
    fetchRoles();
    // setSearchItems(searchFilters)
  }, []);

  let searchItems = [
    {
      name: 'Ethnicity',
      value: 'name',
    },
  ];

  const onFinish = async (values) => {
    // console.log(value);
    // if (values.searchFor == 'Name') {
    //   values.searchFor = 'verticleType';
    // }
    setSearchShow(values);
    // try {
    //   let searchData = {
    //     limit: 10,
    //     offset: 0,
    //     searchBy: values.searchFor,
    //     searchTxt: values.searchItem,
    //   };
    //   if (!searchData.searchBy) message.error('Select search criteria');
    //   if (searchData.searchBy) await ethnicityListingApi(searchData);
    // } catch (e) {
    //   console.log('Error');
    //   if (e.message) setErr(e.message);
    //   setTableShow(false);
    // }

    let formData = {
      limit: 10,
      offset: 0,
      searchBy: values.searchBy,
      searchTxt: values.searchTxt,
    };
    if (!formData.searchTxt) message.error('Please enter search text');
    else await ethnicityListingApi(formData);
  };
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  const handleSearchChange = async (e) => {
    if (!e.target.value) {
      try {
        let searchData = {
          limit: pageSize,
          offset: 0,
          searchTxt: '',
        };
        await ethnicityListingApi(searchData);
        setSearchShow({});
      } catch (e) {
        if (e.message) setErr(e.message);
        setTableShow(false);
      }
    }
  };
  const onSearch = async (value) => {
    try {
      const result = await axios.get(`${app.URL}/role/search/${value}`);
      let searchedRole = result.data.data;
    } catch (e) {
      console.log(e);
      //  if(e.message)setErr(e.response.data.message);
      setTableShow(false);
    }
  };
  const onChange = async (page, pageSize) => {
    setPageSize(pageSize);
    setCurrentPage(page);
    if (!page) page = 1;
    let limit = pageSize;
    try {
      let reqObj;
      if (Object.keys(searchShow).length !== 0) {
        reqObj = {
          limit: limit,
          offset: limit * (page - 1),
          searchBy: searchShow.searchBy,
          searchTxt: searchShow.searchTxt,
        };
      } else {
        reqObj = {
          limit: limit,
          offset: limit * (page - 1),
          searchBy: 'name',
          searchTxt: '',
        };
      }

      await ethnicityListingApi(reqObj);
    } catch (e) {
      console.log('Error');
      if (e.message) setErr(e.message);
      setTableShow(false);
    }
  };
  // const handleSearchChange = (e) => {
  //   if (!e.target.value) {
  //     setRenderList(fetchedRoles.slice(0, 10));
  //     setPagesCount(fetchedRoles.length);
  //   }
  // };
  const addEthnicity = () => {
    navigate('/ethnicity/create', { replace: true });
  };

    const handleOnChange = (e) => {
      if (e.target.value.length === 0) {
        fetchRoles();
        setSearchShow({});
      }
    };
  return (
 
    <ListinngCard
      heading='Ethnicity Management'
      buttonName='Add Ethnicity'
      addButton={addEthnicity}
      onSearch={onFinish}
      onSearchFailed={onFinishFailed}
      searchItems={searchItems}
      tableHeaders={tableHeaders}
      tableRows={renderList}
      editButton={editEthnicity}
      onPageChange={onChange}
      currentPage={currentPage}
      pagesCount={pagesCount}
      patientsListing={false}
      inventoryListing={false}
      userListings={false}
      storeListing={false}
      productListing={false}
      ethnicListing={true}
      onChange={handleOnChange}
      loader={loader}
      />
  
  );
};

export default Ethnicity;
