import React, { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import ReactToPrint, { PrintContextConsumer } from 'react-to-print';

import ComponentToPrint from './ComponentToPrint';

const Example = () => {
  const pageStyle = `
    @page {
        size: 58cm 40cm; /* Specify your custom page size here (width x height) */
        margin: 2;
    }
    body {
      font-family: Arial, sans-serif;
      margin: 0;
    }
  `;
  const handleAfterPrint = () => {
    // This function will be executed after printing
    alert('Printing completed!');
  };
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: pageStyle,
    onAfterPrint: handleAfterPrint,
  });
  window.addEventListener('afterprint', (event) => {
    console.log(event);
    console.log('After print');
  });

  return (
    <div>
      <div style={{ display: 'none' }}>
        <div ref={componentRef}>
          <img src='https://api.shipengine.com/v1/downloads/10/gJdXDyJCEkSeJ6coiJVxuQ/label-363851913.png' />
        </div>
      </div>
      {/* <ComponentToPrint ref={componentRef} /> */}
      <button onClick={handlePrint}>Print this out!</button>
    </div>
  );
};

export default Example;
