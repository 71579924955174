import React, { useEffect, useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import Resume from './Resume';

const ReactToPrint = () => {
  const [show, setShow] = useState(false);
  const [img, setImage] = useState('');
  const printRef = useRef();
  const handlePrint = useReactToPrint({
    trigger: () => {
      return;
    },
    content: () => printRef.current,
  });
  useEffect(() => {
    setImage('https://api.shipengine.com/v1/downloads/10/gJdXDyJCEkSeJ6coiJVxuQ/label-363851913.png');
  }, [handlePrint]);

  const reactToPrintTrigger = React.useCallback(() => {
    return <a href='https://api.shipengine.com/v1/downloads/10/gJdXDyJCEkSeJ6coiJVxuQ/label-363851913.png'>Print this out!</a>;
  }, []);
  // const printWork=()=>{
  //     return(

  //     )

  // }
  // printWork()
  // let printFunc=()=>{
  // let win = window.open();
  // win.document.write('<img src="https://api.shipengine.com/v1/downloads/10/gJdXDyJCEkSeJ6coiJVxuQ/label-363851913.png" >');
  // // document.write("<img src='https://api.shipengine.com/v1/downloads/10/gJdXDyJCEkSeJ6coiJVxuQ/label-363851913.png' style={{ width: '300px', height: '500px' }} alt='image'></img>");
  // win.print()
  // win.close()
  // }
  // function silentPrintContent(content) {
  //     var iframe = document.createElement('iframe');
  //     iframe.style.display = 'none';
  //     document.body.appendChild(iframe);
  //     iframe.contentDocument.open();
  //     iframe.contentDocument.write(content);
  //     iframe.contentDocument.close();

  //     iframe.contentWindow.print();
  //     document.body.removeChild(iframe);
  //   }

  //   // Example usage
  //   var contentToPrint = '<h1>This is the content to be printed silently.</h1>';
  // //   silentPrintContent(contentToPrint);

  return (
    <>
      <div id='printablediv' ref={printRef}>
        <img src={img} style={{ width: '300px', height: '500px' }} alt='image'></img>
      </div>
      {/* <ReactToPrint

  content={() => this.componentRef}
/> */}
      <button className='btn' onClick={handlePrint}>
        Print
      </button>

      {/* <button className='btn' onClick={printFunc}>print</button> */}
    </>
  );
};

export default ReactToPrint;
