import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { message, Switch, Select, Form, Input, Row, Col, DatePicker } from 'antd';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { app } from '../../config';
import getHeaders from '../../utils/authHeaders';
import useFetchStores from '../../hooks/fetchStores';
import { CSVLink } from 'react-csv';
import useFetchProducts from '../../hooks/fetchProducts';
import useFetchPartners from '../../hooks/fetchPartner';
import { dateConverter } from '../../utils/utcDateConversionCommon';
import { extractObjValues } from '../../utils/extractObjValues';
import AddNewImg from '../../assets/images/add-new.png';
import Excel from '../../assets/images/excel.png';
import Delete from '../../assets/images/delete.png';
import getProductById from '../../hooks/getProductById';
import { AddRecord } from '../../layouts/AddRecord';
import { LoadingOutlined } from '@ant-design/icons';
// console.log(app);
const { TextArea } = Input;

const AddEcomInventory = () => {
  const { t } = useTranslation(['common']);
  const [phoneNumber, setPhoneNumber] = useState();
  const [loading, setLoading] = useState(false);

  const [addInvResp, setAddInvResp] = useState({});
  const [jsonData, setJsonData] = useState(null);
  const [invListData, setInvListData] = useState([]);
  const [prodObj, setProdObj] = useState({});
  const [apiResp, setAPIResp] = useState(false);
  const [delBatchRes, setDelBatchRes] = useState({});
  const [confirmPwd, setconfirmPwd] = useState('');
  const [show, setShow] = useState(false);
  const [showDelModal, setShowDelModal] = useState(false);
  const [form] = Form.useForm();
  let unitPrice = prodObj ? prodObj.unitPrice : ' ';
  const priceValue = Form.useWatch(unitPrice, form);
  console.log(priceValue);
  // const [roles, setRoles ] = useSt
  let navigate = useNavigate();
  let user = JSON.parse(localStorage.getItem('user'));
  const fetchedProducts = useFetchProducts();
  const products = fetchedProducts.response ? fetchedProducts.response.data : [];
  const fetchedPartners = useFetchPartners();
  const partners = fetchedPartners.response ? fetchedPartners.response.data : [];
  console.log(partners);
  const fetchStores = useFetchStores();
  const stores = fetchStores.response ? fetchStores.response.data : [];
  console.log(stores);
  const [uploadObj, setUploadObj] = useState({});

  const onProductChange = (productId) => {
    console.log('on product change');
    const getproduct = async (id) => {
      try {
        setProdObj({});
        const res = await axios.get(`${app.URL}/api/v1/admin/product/view/${id}`, { headers: getHeaders() });
        res.data ? setProdObj(res.data.result.data) : console.log('no data');
      } catch (err) {
        console.log(err);
      }
    };
    const prodRes = getproduct(productId);
  };
  console.log(prodObj);

  const uploadData = async (data) => {
    setLoading(true);
 console.log(data);
 let userId = user?.result.data.user.userId;
 data.userId = userId;
    try {
      const res = await axios.post(`${app.URL}/api/v1/admin/ecominv/add`, data, { headers: getHeaders() });
      console.log(res);
        setLoading(false);

      if (res.data.result.success) {
        console.log('dataaaaaaaaaaaaaaa');
        console.log(res.data);
        console.log(res.data.result.data);
        setAddInvResp(res.data.result);
        setTimeout(() => {
          navigate('/ecominv/list');
        }, 500);
      } else if (!res.data.result.success) {
        window.alert(res.data.result.msg);
      }
    } catch (err) {
      console.log(err);
        setLoading(false);

      if (err) console.log(err.response.data.message);
      else return;
    }
  };

  console.log(addInvResp);
  // const refinedArray = extractObjValues(invListData, ['partnerName', 'batchId', 'name', 'serialNo', 'issueDate'])
  // console.log(refinedArray);
  // let newArray = [];
  // refinedArray.forEach((ele) => {
  //   newArray.push({ [`Batch ID`]: ele.batchId, [`Product`]: ele.name, [`Partner`]: ele.partnerName, [`Serial No.`]: ele.serialNo, [`Inventory Date`]: ele.issueDate });
  // })

  // console.log(show);
  const onFinish = (values) => {
    console.log('Success ', values);
    setUploadObj({
      productId: values.product ? values.product : null,
      storeId: values.storeId ? values.storeId : null,
      unitPrice: prodObj.unitPrice,
      // partnerId: values.partnerId ? values.partnerId : null,
      // partnerId: values.partner ? values.partner : 3,
      quantity: values.quantity ? values.quantity : null
      // stockInDate: values.date ? values.date.format().split('T')[0] : '',
      // isActive: 1,
    });
    setShow(true);
  };

  const submitData = () => {
    console.log('calling');
    console.log(uploadObj);
    if (Object.keys(uploadObj).length > 0) {
      uploadData(uploadObj);
    }
  };
  const handleClose = () => setShow(false);
  const handleDelModalClose = () => setShowDelModal(false);
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  const deleteBatch = async (batchId) => {
    console.log(batchId);
    const res = await axios.delete(`${app.URL}/api/v1/admin/inventory/delete/${batchId}`, { headers: getHeaders() });
    console.log(res);
    if (res && res.data.result.success) {
      setDelBatchRes(res.data.result.data);
      setShowDelModal(false);
      setInvListData([]);
      setAPIResp(false);
    }
  };
  function goBack() {
    navigate('/ecominv/list', { replace: true });
  }
  window.addEventListener('popstate', goBack);

  return (
    <div className='dashboard-card pb-5 container-fluid'>
      <div className='row p-0 pb-4'>
        <div className='for-before col-12 col-md-7 head fs-3 fw-bold' style={{ backgroundColor: '#a7c1d9', borderBottomRightRadius: '10px', color: '#FFFFFF' }}>
          Stock-In Inventory
        </div>
      </div>

      <Form
        name='basic'
        onFinish={onFinish}
        className='row p-5 px-3 pb-1'
        onFinishFailed={onFinishFailed}
        autoComplete='off'
        initialValues={{
          remember: true,
          ['unitPrice']: prodObj ? prodObj.unitPrice : 0,
        }}
      >
        <div className='col-md-8'>
          <div className='row'>
            <div className='col-lg-4'>
              <label className='mb-2'>
                Product <span className='required-star'>*</span>
              </label>
              <Form.Item
                // label='Product'

                name='product'
                defaultValue='1'
                colon={false}
                rules={[
                  {
                    required: true,
                    message: 'Select Product Name',
                  },
                ]}
              >
                <Select placeholder='Select Product' onChange={onProductChange}>
                  {products.map((item) => (
                    <Select.Option value={item.productId}>{item.name}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </div>

            {prodObj && prodObj.unitPrice && (
              <div className='col-lg-4'>
                <label className='mb-2'>
                  Unit Price <span className='required-star'>*</span>
                </label>
                <Form.Item
                  // label='Available Quantity'
                  name='unitPrice'
                  colon={false}
                  rules={[
                    {
                      required: false,
                      message: 'Enter Available Quantity',
                    },
                  ]}
                >
                  <Input placeholder='Enter Quantity' defaultValue={prodObj.unitPrice} disabled />
                </Form.Item>
              </div>
            )}

            <div className='col-lg-4'>
              <label className='mb-2'>
                Store <span className='required-star'>*</span>
              </label>
              <Form.Item
                name='storeId'
                // defaultValue='1'
                colon={false}
                rules={[
                  {
                    required: true,
                    message: 'Select Store',
                  },
                ]}
              >
                <Select placeholder='Select Store'>
                  {/* {stores.map((item) => ( */}
                  <Select.Option value={4}>Know-warehouse</Select.Option>
                  {/* ))} */}
                </Select>
              </Form.Item>
            </div>
            <div className='col-lg-4'>
              <label className='mb-2'>
                Quantity <span className='required-star'>*</span>
              </label>
              <Form.Item
                // label='Available Quantity'
                name='quantity'
                defaultValue='1'
                colon={false}
                rules={[
                  {
                    required: true,
                    message: 'Enter Available Quantity',
                  },
                ]}
              >
                <Input placeholder='Enter Quantity' type='number' min='1' />
              </Form.Item>
            </div>

            {/* <div className={`${prodObj && prodObj.unitPrice ? 'col-md-8' : 'col-md-12'}`}>
              <div className='row'>
                <div className={`${prodObj && prodObj.unitPrice ? 'col-md-6' : 'col-md-4'}`}>
                  <label className='mb-2'>
                    Inventory Date <span className='required-star'>*</span>
                  </label>
                  <Form.Item
                    name='date'
                    rules={[
                      {
                        required: true,
                        message: 'Select Date',
                      },
                    ]}
                  >
                    <DatePicker placeholder='MM/DD/YYYY' format={'MM/DD/YYYY'} />
                  </Form.Item>
                </div>
              </div>
            </div> */}

            {addInvResp.code == 200 ? (
              <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                  <Modal.Title className='text-center'>Success</Modal.Title>
                </Modal.Header>
                <Modal.Body>{addInvResp.msg}</Modal.Body>
                <Modal.Footer></Modal.Footer>
              </Modal>
            ) : addInvResp.code == 400 ? (
              <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                  <Modal.Title className='text-center'>Failed</Modal.Title>
                </Modal.Header>
                <Modal.Body>{addInvResp.data.message}</Modal.Body>
                <Modal.Footer></Modal.Footer>
              </Modal>
            ) : (
              <Modal show={show} onHide={handleClose}>
                {/* <Modal.Header closeButton>
                  <Modal.Title>Confirmation</Modal.Title>
                </Modal.Header> */}
                <Modal.Body>Are you sure, you want to add inventory?</Modal.Body>
                <Modal.Footer>
                  <Button variant='secondary' onClick={handleClose}>
                    Cancel
                  </Button>
                  <Button disabled={loading} variant='primary' htmlType='submit' onClick={submitData}>
                    {loading ? <LoadingOutlined /> : 'Confirm'}
                  </Button>
                </Modal.Footer>
              </Modal>
            )}

            {/* <button style={{ width: '70%', marginTop: '30px' }} type='primary' class='btn btn-primary button' htmlType='submit'>
              Save
            </button> */}
            <button onClick={goBack} type='primary' className={`btn btn-primary button patient-cancel-btn col-md-4 mx-md-2 mr-2 mb-2`}>
              Cancel
            </button>

            <button type='primary' className={`btn btn-primary button col-md-4 mb-2`} htmlType='submit'>
              Save
            </button>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default AddEcomInventory;
