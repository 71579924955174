import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { app } from '../../config';
import { useNavigate } from 'react-router-dom';
import { Pagination, message } from 'antd';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { PrinterOutlined, LoadingOutlined } from '@ant-design/icons';
import getHeaders from '../../utils/authHeaders';
import { dateConverter } from '../../utils/utcDateConversionCommon';
import AddNewImg from '../../assets/images/add-new.png';
import { SearchBar } from '../../layouts/SearchBar';
import { useReactToPrint } from 'react-to-print';
import { useRef } from 'react';
import LoaderImage from '../LoaderImage';
import NoDataFound from '../../layouts/NoDataFound';
import QRCodeLogo from '../../lib/QRCodeLogo';
import moment from "moment";

const LabelListing = () => {
  const [editbtn, setEditBtn] = useState('none');
  const [pageSize, setPageSize] = useState(10);
  const [fetchPartners, setFetchPartners] = useState([]);
  const [updbatchId, setUpdBatchId] = useState('');
  const [delBatchId, setDelBatchId] = useState('');
  const [pagesCount, setPagesCount] = useState(0);
  const [searchShow, setSearchShow] = useState({});
  const [renderList, setRenderList] = useState([]);
  const [tableShow, setTableShow] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [err, setErr] = useState('');
  //   const [tableHeaders, setTableHeaders] = useState([]);
  const [show, setShow] = useState(false);
  const [lockModalShow, setLockModalShow] = useState(false);
  const [detailModalShow, setDetailModalShow] = useState(false);
  const [delBatchResp, setDelBatchResp] = useState({});
  const [lockBatchResp, setLockBatchResp] = useState({});
  const [inventoryListingResp, setInventoryListingResp] = useState([]);
  const [loader, setLoader] = useState(false);

  const handleClose = () => setShow(false);
  const handleLockClose = () => {
    setLockModalShow(false);
    setDetailModalShow(false);
  };
  let navigate = useNavigate();
  let convertedObj;

  const user = JSON.parse(localStorage.getItem('user'));
  if (user.userType === 'A') setEditBtn('block');
  const viewBatchDetails = (data) => {
    localStorage.setItem('returnLabelBatchId', JSON.stringify(data.batchId));
    console.log('Local Storage Set');
    navigate(`/returnlabel/batchview`);
  };
  const addInventory = () => {
    navigate('/returnlabel/create', { replace: true });
  };

  const lockBatch = async () => {
    console.log('Consoling updated batch');
    console.log(updbatchId);
    const res = await axios.post(`${app.URL}/api/v1/admin/inventory/updatebatch`, { batchId: updbatchId }, { headers: getHeaders() });
    console.log(res);
    if (res && res.data.result.success) {
      console.log(res.data.result);
      setLockModalShow(false);
    }
  };
  const lockBatchFunc = (data) => {
    console.log(data);
    setLockModalShow(true);
    setUpdBatchId(data.batchId);
  };
  const deleteBatch = async (batchId) => {
    console.log('Consoling batch deleted');
    console.log(delBatchId);
    const res = await axios.delete(`${app.URL}/api/v1/admin/inventory/delete/${delBatchId}`, { headers: getHeaders() });
    console.log(res);
    if (res && res.data.result.success) {
      console.log(res.data.result);
      setLockBatchResp(res.data.result);
      setShow(false);
      // window.location.reload()
    }
  };
  const deleteBatchFunc = (data) => {
    console.log(data);
    setShow(true);
    setDelBatchId(data.batchId);
  };
  const inventoryListingApi = async (formData) => {
    console.log(formData);
    setLoader(true);

    const res = await axios.post(`${app.URL}/api/v1/admin/returnlabel/view`, formData, { headers: getHeaders() });
    // console.log(res)
    res ? console.log(res.data.result.data) : '';
    res ? (convertedObj = dateConverter(res.data.result.data.data, 'createdAt')) : ' ';
    res.data.result ? setFetchPartners(convertedObj) : ' ';
    res.data.result ? setPagesCount(res.data.result.data.count) : ' ';
    res.data.result ? setRenderList(convertedObj) : ' ';
    res.data.result && setLoader(false);
  };
  const onChange = async (page, pageSize) => {
    console.log(page, pageSize);
    setPageSize(pageSize);
    setCurrentPage(page);
    if (!page) page = 1;
    let limit = pageSize;
    try {
      let reqObj;
      if (Object.keys(searchShow).length !== 0) {
        reqObj = {
          limit: limit,
          offset: limit * (page - 1),
          searchBy: searchShow.searchBy,
          searchTxt: searchShow.searchTxt,
        };
      } else {
        reqObj = {
          limit: limit,
          offset: limit * (page - 1),
          searchBy: 'batchId',
          searchTxt: '',
        };
      }
      console.log(reqObj);
      await inventoryListingApi(reqObj);
    } catch (e) {
      console.log('Error');
      if (e.message) setErr(e.message);
      setTableShow(false);
    }
  };

  console.log(pageSize, currentPage)

  // 
  useEffect(() => {
    let formData = {
      searchTxt: '',
      searchBy: 'batchId',
      limit: pageSize,
      offset: '0',
    };
    inventoryListingApi(formData);
  }, [lockModalShow, show]);

  let searchItems = [
    {
      name: 'Batch No.',
      value: 'batchId',
    },
    {
      name: 'Product Name',
      value: 'productName',
    },
  ];

  const onFinish = async (values) => {
    console.log(values);
    setSearchShow(values);
    let formData = {
      limit: 10,
      offset: 0,
      searchBy: values.searchBy,
      searchTxt: values.searchTxt,
    };
    if (!formData.searchTxt) message.error('Please enter search text');
    else await inventoryListingApi(formData);
  };
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const fetchInventoryByBatchId = async (invId) => {
    console.log(invId);
    setInventoryListingResp([]);
    const responseData = await axios.get(`${app.URL}/api/v1/admin/inventory/bybatch/${invId}`, { headers: getHeaders() });
    console.log(responseData);
    if (responseData.status === 200) {
      setInventoryListingResp(responseData.data.result.data);
    }
  };

  let printRef = useRef();

  const pageStyle = `
       @page {
        size: 4in 6in; /* Specify your custom page size here (width x height) */
          margin: 0.5;
          
       }
      
       body {
         font-family: Arial, sans-serif;
         margin: 0;
       }
       `;

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    pageStyle: pageStyle,
  });

  const [returnLabels, setreturnLabels] = useState([]);

  const [printLoader, setPrintLoader] = useState(false);
  const [batchID, setBatchID] = useState('');

  const generateBarCode = (batch) => {
    
    let labels = [];
    for (let i = 0; i < batch.length; i++) {
      for (let j = 0; j < batch[i]?.productLabels?.length; j++) {
        labels.push(batch[i].productLabels[j].labelURL);
      }
    }

    setreturnLabels(labels);

    setTimeout(() => {
      setPrintLoader(false);
      setBatchID('');
      handlePrint();
    }, 1000);
  };

  const handleRePrint = async (batchId, qty) => {
    setBatchID(batchId);
    setPrintLoader(true);
    let formData = {
      limit: qty,
      offset: 0,
      searchBy: 'batchId',
      searchTxt: '',
    };
    let { searchBy, searchTxt, limit, offset } = formData;
    const batchResp = await axios.get(`${app.URL}/api/v1/admin/returnlabel/view?searchTxt=${searchTxt}&searchBy=${searchBy}&limit=${limit}&offset=${offset}&batchId=${batchId}`, { headers: getHeaders() });
    console.log(batchResp.data.result.data.data);
    batchResp && generateBarCode(batchResp.data.result.data.data);
  };

  const handleOnChange = async (e) => {
    if (e.target.value.length === 0) {
      let formData = {
        limit: pageSize,
        offset: 0,
        searchBy: '',
        searchTxt: '',
      };
      setSearchShow({});
      await inventoryListingApi(formData);
    }
  };

  console.log(renderList);

  return (
    <div className='dashboard-card container-fluid'>
      <div className='row p-0 pb-4'>
        <div className='for-before col-12 col-md-7 head fs-3 fw-bold' style={{ backgroundColor: '#a7c1d9', borderBottomRightRadius: '10px', color: '#FFFFFF' }}>
          Return Label
        </div>

        <div className='col-12 col-md-5 d-flex justify-content-center'>
          <div onClick={addInventory} className='icon-btn'>
            <img src={AddNewImg} />
            <p>Add Return Label</p>
          </div>
        </div>
      </div>

      <SearchBar onFinish={onFinish} onFinishFailed={onFinishFailed} searchItems={searchItems} onChange={handleOnChange} />
      <div style={{ display: 'none' }}>
        <div ref={printRef}>
          {returnLabels.map((item) => (
            <img src={item} />
          ))}
        </div>
      </div>

      <div
        className='table-scrollbar'
        style={{
          overflowX: 'auto',
        }}
      >
        {loader && <LoaderImage />}
        {renderList.length > 0 && !loader ? (
          <table id='customers' className='w-100'>
            <thead>
              <tr>
                <th>Batch No.</th>
                <th>Product</th>

                <th>Quantity</th>
                <th>Label Date</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {renderList.map((tableValues) => (
                <tr>
                  <td
                    onClick={() => {
                      // setDetailModalShow(true);
                      // fetchInventoryByBatchId(tableValues.batchId);
                    }}
                    // className='batchlink'
                  >
                    {tableValues.batchId ? tableValues.batchId : 'null'}
                  </td>
                  <td>{tableValues.productName ? tableValues.productName : 'null'}</td>
                  {/* <td style={{ width: '150px' }}>{tableValues.ProductName ? tableValues.ProductName : 'null'}</td> */}
                  <td>{tableValues.quantity ? tableValues.quantity : 'null'}</td>
                  <td>{tableValues.createdAt ? moment(tableValues.createdAt).format('MM-DD-YYYY') : 'null'}</td>
                  {/* <td><button onClick={()=>viewBatchDetails(tableValues)}>ClickHere</button></td> */}
                  <td>
                    <button className='btn mx-2 text-light' onClick={() => viewBatchDetails(tableValues)}>
                      View{' '}
                    </button>
                    <button disabled={batchID === tableValues.batchId} className='btn mx-2' onClick={() => handleRePrint(tableValues.batchId, tableValues.quantity)}>
                      {printLoader && batchID === tableValues.batchId ? (
                        <LoadingOutlined
                          style={{
                            fontSize: '1.5rem',
                            color: 'white',
                          }}
                        />
                      ) : (
                        <PrinterOutlined
                          style={{
                            fontSize: '1.5rem',
                            color: 'white',
                          }}
                        />
                      )}
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <>{!loader && <NoDataFound />}</>
        )}
      </div>
      {lockBatchResp.code === 200 ? (
        <Modal show={lockModalShow} onHide={handleLockClose}>
          <Modal.Header closeButton>
            <Modal.Title className='text-center'>Success</Modal.Title>
          </Modal.Header>
          <Modal.Body>Batch Locked successfully</Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      ) : lockBatchResp.code === 400 ? (
        <Modal show={lockModalShow} onHide={handleLockClose}>
          <Modal.Header closeButton>
            <Modal.Title className='text-center'>Failed</Modal.Title>
          </Modal.Header>
          <Modal.Body>{lockBatchResp.data.message}</Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      ) : (
        <Modal show={lockModalShow} onHide={handleLockClose}>
          <Modal.Header closeButton>
            <Modal.Title>Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure, you want to lock the Batch?</Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={handleLockClose}>
              Cancel
            </Button>
            <Button variant='primary' htmlType='submit' onClick={lockBatch}>
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      {delBatchResp.code === 200 ? (
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title className='text-center'>Success</Modal.Title>
          </Modal.Header>
          <Modal.Body>Batch Deleted successfully</Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      ) : delBatchResp.code === 400 ? (
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title className='text-center'>Failed</Modal.Title>
          </Modal.Header>
          <Modal.Body>{delBatchResp.data.message}</Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      ) : (
        <Modal show={show} onHide={handleClose}>
          {/* <Modal.Header closeButton>
            <Modal.Title>Confirmation</Modal.Title>
          </Modal.Header> */}
          <Modal.Body>Are you sure, you want to delete the Batch?</Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={handleClose}>
              Cancel
            </Button>
            <Button
              variant='primary'
              htmlType='submit'
              onClick={() => {
                deleteBatch(tableValues.batchId);
              }}
            >
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      <Modal show={detailModalShow} onHide={handleLockClose}>
        <Modal.Header closeButton>
          <Modal.Title className='text-center'>Batch-{inventoryListingResp[0]?.batchId}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table id='customers'>
            <thead class=''>
              <tr>
                {/* <th>Batch No.</th> */}
                <th>Product Name</th>
                <th>Quantity</th>
              </tr>
            </thead>
            <tbody>
              {inventoryListingResp
                ? inventoryListingResp.map((tableValues) => (
                    <tr>
                      {/* <td  style={{ width: '200px' }} ><a >{tableValues.batchId ? tableValues.batchId : 'null'}</a></td> */}
                      <td style={{ width: '200px' }}>{tableValues.productName ? tableValues.productName : 'null'}</td>
                      <td style={{ width: '150px', textAlign: 'center' }}>{tableValues.prodQty ? tableValues.prodQty : 'null'}</td>
                    </tr>
                  ))
                : ' '}
            </tbody>
          </table>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>

      <div className='row'>{currentPage && pagesCount ? <Pagination style={{ marginTop: '10px' }} onChange={onChange} defaultCurrent={currentPage} total={pagesCount} showTotal={(total) => `Total ${total} items`} showSizeChanger /> : ' '}</div>
    </div>
  );
};

export default LabelListing;
