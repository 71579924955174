import React, { useEffect, useState } from 'react';
import editBtnImg from '../../assets/images/edit.png';
import AddNewImg from '../../assets/images/add-new.png';
import ListingCard from '../../layouts/ListingCard';
import { Button, Pagination } from 'antd';
import AddFaq from './AddFaq';
import { useNavigate } from 'react-router-dom';
import { app } from '../../config';
import axios from 'axios';
import getHeaders from '../../utils/authHeaders';
import UpdateFaqs from './UpdateFaqs';
import { SearchBar } from '../../layouts/SearchBar';
import ListinngCard from '../../layouts/ListingCard';

const Faq = () => {
  const [loader, setLoader] = useState(false);

  const tableHeaders = ['Name', 'No. of Question', 'Index', 'Status', ' ', ''];
  const [tableBody, setTableBody] = useState([]);
  const [isRender, setisRender] = useState(false);
  const [pageSize, setPageSize] = useState(10);

  const [showUpdateFaq, setShowUpdateFaq] = useState(false);
  const [selectedFaq, setSelectedFaq] = useState([]);
  const [err, setErr] = useState('');

  const [pagesCount, setPagesCount] = useState(0);
  const [searchShow, setSearchShow] = useState({});
  const [currentPage, setCurrentPage] = useState(1);

  const getListing = async (formData) => {
    try {
      setLoader(true);
      const res = await axios.post(`${app.URL}/api/v1/admin/faqcategory/listing?limit=${formData.limit}&offset=${formData.offset}&searchBy=${formData.searchBy}&searchTxt=${formData.searchTxt ? formData.searchTxt : ''} `, {}, { headers: getHeaders() });
      console.log('response is:', res);
      res.data.result.data ? setPagesCount(res.data.result.data.count) : ' ';
      res.data.result.data ? setLoader(false) : ' ';
      res.data.result.data ? setTableBody(res?.data?.result?.data?.data) : ' ';
    } catch (err) {
      console.log(err);
    }
  };

  console.log(tableBody);

  useEffect(() => {
    // Wrap async call within useEffect
    let formData = {
      limit: pageSize,
      offset: 0,
      searchBy: 'name',
      searchTxt: '',
    };
    const fetchData = async () => {
      await getListing(formData);
    };
    fetchData();
  }, [isRender]);

  let navigate = useNavigate();
  const [show, setShow] = useState(false);

  const faqDetailNavigate = (e) => {
    console.log(e.faqCategoryId);
    localStorage.setItem('faqCategoryId', e.faqCategoryId);
    navigate('/faqcategory/update', { replace: true });
  };
  const handleEdit = (elem) => {
    console.log('jkfshkdj', elem);
    // <UpdateFaqs/>
    setSelectedFaq(elem);
    setShowUpdateFaq(true);
  };

  const onSearch = async (values) => {
    console.log(values);
    // if (values.searchFor == 'batchno') {
    //   values.searchFor = 'batchNo';
    // }

    setSearchShow(values);
    try {
      let searchData = {
        limit: 10,
        offset: 0,
        searchBy: values.searchBy,
        searchTxt: values.searchTxt,
      };
      if (!searchData.searchTxt) message.error('Please enter search text');
      else await getListing(searchData);
    } catch (e) {
      console.log('Error');
      if (e.message) setErr(e.message);
      // setTableShow(false);
    }
  };
  const onSearchFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const onPageChange = async (page, pageSize) => {
    console.log(page, pageSize);
    setPageSize(pageSize);
    setCurrentPage(page);
    if (!page) page = 1;
    let limit = pageSize;
    try {
      let reqObj;
      if (Object.keys(searchShow).length !== 0) {
        reqObj = {
          limit: limit,
          offset: limit * (page - 1),
          searchBy: searchShow.searchBy,
          searchTxt: searchShow.searchTxt,
        };
      } else {
        reqObj = {
          limit: limit,
          offset: limit * (page - 1),
          searchBy: 'name',
          searchTxt: '',
        };
      }
      console.log(reqObj);
      await getListing(reqObj);
    } catch (e) {
      console.log('Error');
      if (e.message) setErr(e.message);
      //  setTableShow(false);
    }
  };
  let searchItems = [
    {
      name: 'Faq Category Name',
      value: 'name',
    },
    // {
    //   name: 'SKU',
    //   value: 'SKU',
    // },
  ];
  const handleOnChange = (e) => {
    console.log('this is search onchange');
    if (e.target.value.length === 0) {
      //  getListing();
      setSearchShow({});
    }
  };

  return (
    <>
      <ListinngCard
        heading='FAQ Categories'
        buttonName='Add FAQ Category'
        addButton={() => setShow(true)}
        onSearch={onSearch}
        onSearchFailed={onSearchFailed}
        searchItems={searchItems}
        tableHeaders={tableHeaders}
        tableRows={tableBody}
        editButton={handleEdit}
        onPageChange={onPageChange}
        currentPage={currentPage}
        pagesCount={pagesCount}
        patientsListing={false}
        inventoryListing={false}
        storeListing={false}
        productListing={false}
        userListings={false}
        feedbackListing={false}
        bannerListing={false}
        faqListing={true}
        faqDetailNavigate={faqDetailNavigate}
        onChange={handleOnChange}
        loader={loader}
      />
      <AddFaq show={show} setShow={setShow} handleClose={() => setShow(false)} setisRender={setisRender} loader={loader} setLoader={setLoader} />
      {showUpdateFaq && <UpdateFaqs show={showUpdateFaq} setShow={setShowUpdateFaq} handleClose={() => setShowUpdateFaq(false)} selectedFaq={selectedFaq} setisRender={setisRender} loader={loader} setLoader={setLoader} />}
    </>
  );
};
export default Faq;
