import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { message, Switch, Select, Form, Input } from 'antd';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { app } from '../../config';
import { LeftCircleOutlined } from '@ant-design/icons';
import getHeaders from '../../utils/authHeaders';
import { AddRecord } from '../../layouts/AddRecord';
import { InputField } from '../../layouts/FormFields';

const addStore = () => {
  const { t } = useTranslation(['common']);
  const [addStoreResp, setAddStoreResp] = useState({});
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  let navigate = useNavigate();
  const [uploadObj, setUploadObj] = useState({});
  let user = JSON.parse(localStorage.getItem('user'));

  const uploadData = async (userDetails) => {
    let userId = user?.result.data.user.userId;
    userDetails.userId = userId;
    setLoading(true);
    try {
      console.log('uploadObj ', userDetails);
      const res = await axios.post(`${app.URL}/api/v1/admin/store/create`, userDetails, { headers: getHeaders() });
      setLoading(false)
      setShow(false);
      if (res.data.result.success) {
        setAddStoreResp(res.data.result.data);
        message.success(res.data.result.msg);
        setTimeout(() => {
          navigate('/store/listing');
        }, 1000);
      } else if (!res.data.result.success) {
        message.warning(res.data.result.msg);
      }
    } catch (err) {
      console.log(err);
      setLoading(false)
      setShow(false);
      if (err) console.log(err.response.data.message);
      else return;
    }
  };
  const onFinish = (values) => {
    console.log('Success:', values);
    setUploadObj({
      name: values.storeName ? values.storeName : null,
      isActive: values.isActive,
    });
    setShow(true);
  };
  const submitData = () => {
    console.log('calling');
    if (Object.keys(uploadObj).length > 0) {
      uploadData(uploadObj);
    }
  };
  const handleClose = () => setShow(false);
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  function goBack() {
    navigate('/store/listing', { replace: true });
  }
  window.addEventListener('popstate', goBack);

  return (
    <AddRecord
      loading={loading}
      addUserResp={addStoreResp}
      pageTitle='Add Store'
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      switchBtn={true}
      goBack={goBack}
      submitData={submitData}
      addMessage='Are you sure you want to add store?'
      show={show}
      handleClose={handleClose}
      upload={false}
      initialValues={{
        remember: true,
        ['isActive']: true,
      }}
      inputs={[
        <InputField
          label='Store Name'
          placeholder='Enter Store Name'
          name='storeName'
          col='col-lg-6 col-md-4 col-12'
          required={true}
          rules={[
            {
              required: true,
              message: 'Enter Store Name',
            },
          ]}
        />,
      ]}
    />
  );

  // return (
  //   <>
  //     <div className='col-lg-12'>
  //       <div className='dashboard-card'>
  //         <div className='row' style={{ paddingRight: '10px' }}>
  //           <div className='col-lg-4 head fs-3 fw-bold' style={{ backgroundColor: '#a7c1d9', borderBottomRightRadius: '10px', color: '#FFFFFF' }}>
  //             Add Store
  //           </div>
  //         </div>
  //         <div class='col mb-5 mt-4'>
  //           <div class='col-lg-12 align-self-center mt-2 mb-4'>
  //             <Form
  //               name='store'
  //               onFinish={onFinish}
  //               onFinishFailed={onFinishFailed}
  //               autoComplete='off'
  //               initialValues={{
  //                 remember: true,
  //                 ['isActive']: true,
  //               }}
  //             >
  //               {/* Select box for Role */}
  //               <div className='row' style={{ padding: '20px 50px' }}>
  //                 <div className='col-lg-6'>
  //                   <label>
  //                     Store Name <span className='required-star'>*</span>
  //                   </label>
  //                   <Form.Item
  //                     // label='Store Name'
  //                     name='storeName'
  //                     defaultValue='1'
  //                     colon={false}
  //                     rules={[
  //                       {
  //                         required: true,
  //                         message: 'Enter Store Name',
  //                       },
  //                     ]}
  //                   >
  //                     <Input placeholder='Enter Store Name' />
  //                   </Form.Item>
  //                   <Form.Item label='Active' colon={false} valuePropName='checked' name='isActive'>
  //                     <Switch />
  //                   </Form.Item>
  //                   <button
  //                     onClick={() => {
  //                       navigate('/store/listing');
  //                     }}
  //                     type='primary'
  //                     className='btn btn-primary button patient-cancel-btn col-lg-3 mr-2 col-lg-5 mr-2'
  //                   >
  //                     Cancel
  //                   </button>

  //                   <button type='primary' className='btn btn-primary button col-lg-5' htmlType='submit'>
  //                     Save
  //                   </button>
  //                   <Form.Item>
  //                     {addStoreResp.code === 200 ? (
  //                       <Modal show={show} onHide={handleClose}>
  //                         <Modal.Header closeButton>
  //                           <Modal.Title className='text-center'>Success</Modal.Title>
  //                         </Modal.Header>
  //                         <Modal.Body>{addStoreResp.message}</Modal.Body>
  //                         <Modal.Footer></Modal.Footer>
  //                       </Modal>
  //                     ) : addStoreResp.code === 400 ? (
  //                       <Modal show={show} onHide={handleClose}>
  //                         <Modal.Header closeButton>
  //                           <Modal.Title className='text-center'>Failed</Modal.Title>
  //                         </Modal.Header>
  //                         <Modal.Body>{addStoreResp.data.message}</Modal.Body>
  //                         <Modal.Footer></Modal.Footer>
  //                       </Modal>
  //                     ) : (
  //                       <Modal show={show} onHide={handleClose}>
  //                         <Modal.Header closeButton>
  //                           <Modal.Title>Confirmation</Modal.Title>
  //                         </Modal.Header>
  //                         <Modal.Body>Are you sure you want to add store?</Modal.Body>
  //                         <Modal.Footer>
  //                           <Button variant='secondary' onClick={handleClose}>
  //                             Cancel
  //                           </Button>
  //                           <Button variant='primary' htmlType='submit' onClick={submitData}>
  //                             Confirm
  //                           </Button>
  //                         </Modal.Footer>
  //                       </Modal>
  //                     )}
  //                   </Form.Item>
  //                   {/* <button type='primary' class='btn btn-primary button col-lg-12' htmlType='submit'>
  //                    Save
  //                  </button> */}
  //                 </div>
  //               </div>
  //             </Form>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   </>
  // );
};

export default addStore;
