import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
// import { useTranslation } from 'react-i18next';
// import { EditTwoTone } from '@ant-design/icons';
import { message,Input, Pagination, Form, Select, Card, Row, Col } from 'antd';
import { app } from '../../config';
import getHeaders from '../../utils/authHeaders';
import ListinngCard from '../../layouts/ListingCard';

const ProductCatListing = () => {
 
 
  const [fetchProdCategories, setFetchProductCategories] = useState([]);
  // const [search, setSearch] = useState('');
  const [searchShow, setSearchShow] = useState(false);
  const [pagesCount, setPagesCount] = useState(0);
  const [renderList, setRenderList] = useState([]);
  const [tableShow, setTableShow] = useState(true);
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [loader, setLoader] = useState(false);

  const [err, setErr] = useState('');
  let navigate = useNavigate();

  const producuctCatListingAPI = async (formData) => {
    setLoader(true);
    const productCatResp = await axios.post(`${app.URL}/api/v1/admin/category/listing`, formData, { headers: getHeaders() });
    console.log(productCatResp);
    productCatResp.data ? setFetchProductCategories(productCatResp.data.result.data) : ' ';
    productCatResp.data ? setPagesCount(productCatResp.data.result.data.count) : ' ';
    productCatResp.data ? setRenderList(productCatResp.data.result.data.data) : ' ';
    productCatResp.data.result && setLoader(false);

  };
  useEffect(() => {
    const fetchProductCategories = async () => {
      // const user = JSON.parse(localStorage.getItem('user'));
      // if (user.userType === 'A') setEditBtn('block');
      let formData = {
        limit: pageSize,
        offset: 0,
        // searchBy: 'name',
        searchTxt: '',
      };
      await producuctCatListingAPI(formData);
    };
    fetchProductCategories();
  }, []);
  let tableHeaders = ['Category Name', 'Description', 'Status', ''];

  const onFinish = async (values) => {
    console.log('Success:', values);
    setSearchShow(values);
    try {
      let searchData = {
        limit: 10,
        offset: 0,
        searchBy: values.searchBy,
        searchTxt: values.searchTxt,
      };
      console.log(searchData);
      if (!searchData.searchTxt) message.error('Please enter search text');
      else await producuctCatListingAPI(searchData);
    } catch (e) {
      console.log('Error');
      if (e.message) setErr(e.message);
      setTableShow(false);
    }
  };
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  
  const onChange = async (page, pageSize) => {
    console.log(page);
    console.log(pageSize)
    setPageSize(pageSize);
    setCurrentPage(page);
    if (!page) page = 1;
    let limit = pageSize;
    try {
      let reqObj;
      console.log(Object.keys(searchShow))
      if (Object.keys(searchShow).length !== 0) {
        reqObj = {
          limit: limit,
          offset: limit * (page - 1),
          // searchBy: searchShow.searchFor,
          searchTxt: searchShow.searchItem,
        };
      } else {
        reqObj = {
          limit: limit,
          offset: limit * (page - 1),
          // searchBy: 'name',
          searchTxt: '',
        };
      }
      console.log(reqObj)
      await producuctCatListingAPI(reqObj);
    } catch (e) {
      console.log('Error');
      if (e.message) setErr(e.message);
      setTableShow(false);
    }
  };
  
  const editProductCat = (e) => {
    localStorage.setItem('productCatId', JSON.stringify({ productCategoryId: e.productCategoryId, name: e.name, isActive: e.isActive }));
    navigate('/category/update', { replace: true });
  };
  const addProductCat = () => {
    navigate('/category/create', { replace: true });
  };


  let searchItems = [
    {
      name: 'Category Name',
      value: 'name',
    },
  ];


  const handleOnChange = async (e) => {
    if (e.target.value.length === 0) {
      // userList();
      setSearchShow({});
      let formData = {
        limit: pageSize,
        offset: 0,
        searchBy: 'name',
        searchTxt: '',
      };
      await producuctCatListingAPI(formData);
    }
  };

  return (
    <ListinngCard
      heading='Product Category Management'
      buttonName='Add Product Category'
      onChange={handleOnChange}
      addButton={addProductCat}
      onSearch={onFinish}
      onSearchFailed={onFinishFailed}
      searchItems={searchItems}
      tableHeaders={tableHeaders}
      tableRows={renderList}
      editButton={editProductCat}
      onPageChange={onChange}
      currentPage={currentPage}
      pagesCount={pagesCount}
      patientsListing={false}
      inventoryListing={false}
      categoryListing={true}
      loader={loader}
    />
  );
};

export default ProductCatListing;
