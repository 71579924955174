import Modal from 'react-bootstrap/Modal';
import React from 'react';

const OrderReceiveModal = ({ showModal, setShowModal, orderResponseData, buttons,orderResponseType }) => {
  console.log('Order Response', orderResponseData);
  return (
    <Modal
      show={showModal}
      size='lg'
      onHide={() => {
        setShowModal(!showModal);
      }}
      centered
    >
      <Modal.Header
        style={{
          display: 'block',
        }}
      >
        <Modal.Title style={{ display: 'block', padding: '9px 13px 8px 8px', fontSize: '20px' }}>
          <div className='row'>
            <div className='col-lg-6'>{orderResponseData?.ecomOrderNo}</div>
            {/* {/ <div className='col-lg-6 text-right'>xyz</div> /} */}
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ padding: '0px 20px !important' }}>
        <div className='row'>
          <div className='col-lg-12 fs-5 mt-0 mb-0 pt-0 pb-0 pl-3'>{orderResponseData?.patientName}</div>
          {/* {/ <div className='col-lg-6 mt-0 mb-0 pt-2 pb-0 pl-3 text-right'>{orderResponseData?.patientName}</div> /} */}
          <div className='col-lg-12 mt-0 mb-0 pt-0 pb-0 pl-3'>{orderResponseData?.mobile}</div>

          {/* {requestId && (
            <>
              <div className='col-lg-12  mt-0 mb-0 pt-0 pb-0 pl-0 text-right'>Request Type: {requestType}</div>
              <div className='col-lg-12  mt-0 mb-0 pt-0 pb-0 pl-0 text-right'>Request Status: {requestStatus}</div>
            </>
          )} */}

          {/* {/ {requestId ? <div className='col-lg-12  mt-0 mb-0 pt-0 pb-0 pl-3'>Request Type: {requestType}  Request Status: {requestStatus}</div> : " "} /} */}
        </div>
        <div className='mb-1'>
          <table id='customers' className='w-100'>
            <thead>
              <tr
                style={{
                  fontWeight: 'bold',
                }}
              >
                <th>Request No.</th>
                {/* {/ <th>Product Code</th> /} */}
                <th>Product Name </th>
                <th>Request Type</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ width: '100px' }}>{orderResponseData?.reqNo}</td>
                <td style={{ width: '100px' }}>{orderResponseData?.productName}</td>
                <td style={{ width: '100px' }}>{orderResponseData?.requestType}</td>
                {/* <td style={{ width: '100px' }}>{orderResponseData?.patientName}</td>
                <td style={{ width: '100px' }}>{orderResponseData?.productCode}</td>
                <td style={{ width: '100px' }}>{orderResponseData?.productName}</td>
                <td style={{ width: '100px' }}>{orderResponseData?.reqNo}</td>
                <td style={{ width: '100px' }}>{orderResponseData?.requestStatus}</td> */}
              </tr>
            </tbody>
          </table>
        </div>
        {/* <div
          className='row mt-2'
          style={{
            border: '1px solid silver',
            borderRadius: 10,
          }}
        >

        </div> */}
        <div className='row'>
          <div className='col-12 p-3 mb-3'>
            <h4 className='fw-bold mb-2'>Images</h4>
            {orderResponseData?.images.map((item, index) => (
              <div className='d-flex'>
                <img src={item} alt={`image-${index}`} key={index} style={{ width: '100px', height: '100px' }} />
              </div>
            ))}
          </div>{' '}
          <div className='col-12'>
            <h4 className='fw-bold mb-1'>Reasons</h4>
            <ul>
              {orderResponseData?.reasons?.map((item) => (
                <li> {item.answer} </li>
              ))}
            </ul>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        {buttons.map((item) => (
          <>{item}</>
        ))}
        <button
          variant='primary'
          className='btn btn-primary'
          style={{
            textAlign: 'left',
          }}
          onClick={() => {
            setShowModal(!showModal);
          }}
        >
          Cancel
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default OrderReceiveModal;