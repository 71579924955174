import { Form, Input, Select } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import InputMask from 'react-input-mask';
export const InputField = ({ pattern, suffix, min, type, col, label, required, value, name, rules, placeholder, disabled, maxLength, onChange, onKeyPress, readOnly }) => {
  return (
    <div className={col}>
      {label && (
        <label>
          {label}
          {required && <span className='required-star'>*</span>}
        </label>
      )}
      <Form.Item name={name} colon={false} value={value} rules={rules}>
        <Input pattern={pattern?pattern:null} prefix={suffix} min={min} type={type} disabled={disabled} value={value} placeholder={placeholder} maxLength={maxLength} onKeyPress={onKeyPress} onChange={onChange} readOnly={readOnly} />
      </Form.Item>
    </div>
  );
};

export const TextAreaField = ({ value, readOnly, col, label, required, name, rules, placeholder, rows, showCount, maxLength, onChange }) => {
  return (
    <div className={col}>
      {label && (
        <label>
          {label}
          {required && <span className='required-star'>*</span>}
        </label>
      )}
      <Form.Item name={name} colon={false} rules={rules}>
        <TextArea value={value} readOnly={readOnly} className='rounded' showCount={showCount} rows={rows} maxLength={maxLength} placeholder={placeholder} onChange={onChange} />
      </Form.Item>
    </div>
  );
};

export const SelectField = ({ col, label, name, rules, placeholder, required, options, onChange, ishidden, value, defaultVal }) => {
  return (
    <div className={col} hidden={ishidden}>
      {label && (
        <label>
          {label}
          {required && <span className='required-star'>*</span>}
        </label>
      )}

      <Form.Item name={name} colon={false} rules={rules}>
        <Select value={value} defaultValue={defaultVal} onChange={onChange} placeholder={placeholder}>
          {options}
        </Select>
      </Form.Item>
    </div>
  );
};

export const InputMasker = ({ col, label, name, rules, placeholder, required, handleChange, disabled }) => {
  return (
    <div className={col}>
      {label && (
        <label>
          {label}
          {required && <span className='required-star'>*</span>}
        </label>
      )}
      <Form.Item colon={false} name={name} rules={rules}>
        <InputMask mask='999-999-99999' onChange={handleChange} disabled={disabled}>
          {(inputProps) => <Input disabled={disabled} {...inputProps} placeholder={placeholder} />}
        </InputMask>
      </Form.Item>
    </div>
  );
};
