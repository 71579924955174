import React, { useEffect, useState } from 'react';
import ListinngCard from '../../layouts/ListingCard';
import axios from 'axios';
import { app } from '../../config';
import getHeaders from '../../utils/authHeaders';
import { useNavigate } from 'react-router-dom';
import { message } from 'antd';

const LabTestList = () => {
  let navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [renderList, setRenderList] = useState([]);
  const [searchShow, setSearchShow] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [pagesCount, setPagesCount] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  const labTestsListing = async (formData) => {
    setLoader(true);
    const { searchBy, searchTxt, limit, offset } = formData;
    const res = await axios.get(`${app.URL}/api/v1/admin/labtest/view?searchBy=${searchTxt}&searchTxt=${searchBy}&limit=${limit}&offset=${offset}`, { headers: getHeaders() });
    console.log(res);
    res.data.result.success && setRenderList(res.data.result.data.data);
    res.data.result.success && setPagesCount(res.data.result.data.count);
    setLoader(false);
  };
  let formData = {
    searchBy: 'resultElementGroup',
    searchTxt: '',
    limit: pageSize,
    offset: 0,
  };
  useEffect(() => {
    labTestsListing(formData);
  }, []);
  const handleOnChange = (e) => {
    if (e.target.value.length === 0) {
      labTestsListing(formData);
      setSearchShow({});
    }
  };
  let searchItems = [
    { name: 'Panel Test', value: 'resultElement' },
    { name: 'CIS Panel Name', value: 'resultElementGroup' },
  ];

  const onFinish = async (values) => {
    console.log(values);
    setSearchShow(values);
    try {
      let searchData = {
        limit: pageSize,
        offset: 0,
        searchBy: values.searchBy,
        searchTxt: values.searchTxt,
      };
      if (!searchData.searchTxt) message.error('Please enter search text');
      else await labTestsListing(searchData);
    } catch (e) {
      console.log('Error');
      if (e.message) console.log(e.message);
    }
  };
  const onFinishFailed = () => {};

  const tableHeaders = ['Panel Test', 'CIS Panel Name', 'Display Position', 'Status', ''];

  const editButton = (values) => {
    console.log(values);
    localStorage.setItem('resultTestCode', values.resultTestCode);
     localStorage.setItem('resultElementGroup', values.resultElementGroupId);
    navigate('/labtest/update', { replace: true });
  };

  const onChange = async (page, pageSize) => {
    console.log(page, pageSize);
    setPageSize(pageSize);
    setCurrentPage(page);
    if (!page) page = 1;
    let limit = pageSize;
    try {
      let reqObj;
      if (Object.keys(searchShow).length !== 0) {
        reqObj = {
          limit: limit,
          offset: limit * (page - 1),
          searchBy: searchShow.searchBy,
          searchTxt: searchShow.searchTxt,
        };
      } else {
        reqObj = {
          limit: limit,
          offset: limit * (page - 1),
          searchBy: 'resultElementGroup',
          searchTxt: '',
        };
      }
      console.log(reqObj);
      await labTestsListing(reqObj);
    } catch (e) {
      console.log('Error');
      if (e.message) console.log(e.message);
    }
  };

  return (
    <ListinngCard
      heading='Panel Test'
      onSearch={onFinish}
      onSearchFailed={onFinishFailed}
      searchItems={searchItems}
      tableHeaders={tableHeaders}
      tableRows={renderList}
      editButton={editButton}
      onPageChange={onChange}
      currentPage={currentPage}
      pagesCount={pagesCount}
      patientsListing={false}
      inventoryListing={false}
      storeListing={false}
      productListing={false}
      userListings={false}
      feedbackListing={false}
      bannerListing={false}
      anuraListing={false}
      testResultListing={true}
      onChange={handleOnChange}
      loader={loader}
    />
  );
};

export default LabTestList;
