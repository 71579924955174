import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { EditTwoTone } from '@ant-design/icons';
import { Input, Pagination, Form, Select, Card, Row, Col } from 'antd';
import { app } from '../../config';
import getHeaders from '../../utils/authHeaders';
import ListinngCard from '../../layouts/ListingCard';

const ProductConfListing = () => {
  const { t } = useTranslation(['common']);
  const [editbtn, setEditBtn] = useState('none');
  const [fetchProdCategories, setFetchProductCategories] = useState([]);
  const [search, setSearch] = useState('');
  const [searchShow, setSearchShow] = useState(false);
  const [pagesCount, setPagesCount] = useState(0);
  const [renderList, setRenderList] = useState([]);
  const [tableShow, setTableShow] = useState(true);
  const [searchItems, setSearchItems] = useState([]);
  const [tableHeaders, setTableHeaders] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [err, setErr] = useState('');
  let navigate = useNavigate();

  const productConfListingAPI = async (formData) => {
    const productConfResp = await axios.post(`${app.URL}/api/v1/admin/productconfiguration/listing`, formData, { headers: getHeaders() });
    console.log(productConfResp);
    productConfResp.data ? setFetchProductCategories(productConfResp.data.result.data) : ' ';
    productConfResp.data ? setPagesCount(productConfResp.data.result.data.count) : ' ';
    productConfResp.data ? setRenderList(productConfResp.data.result.data.data) : ' ';
  };
  useEffect(() => {
    const fetchProductCategories = async () => {
      const user = JSON.parse(localStorage.getItem('user'));
      if (user.userType === 'A') setEditBtn('block');
      let formData = {
        limit: pageSize,
        offset: 0,
        searchBy: 'productName',
        searchTxt: '',
      };
      await productConfListingAPI(formData);
      let header = ['Partner', 'Product Name', 'Serial No. Specification', '']
      setTableHeaders(header);
      let searchFilters = [
        {
          fieldName: 'Product Name',
          fieldMappedBy: 'productName',
        },
        {
          fieldName: 'Partner Name',
          fieldMappedBy: 'partnerName',
        },
        {
          fieldName: 'Serial No',
          fieldMappedBy: 'serialNo',
        },
      ];
      setSearchItems(searchFilters);
    };
    fetchProductCategories();
  }, []);
  const onFinish = async (values) => {
    console.log('Success:', values);
    setSearchShow(values);
    try {
      let searchData = {
        limit: 10,
        offset: 0,
        searchBy: values.searchFor,
        searchTxt: values.searchItem,
      };
      console.log("searchData ",searchData);
      await productConfListingAPI(searchData);
    } catch (e) {
      console.log('Error');
      if (e.message) setErr(e.message);
      setTableShow(false);
    }
  };
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  const handleSearch = async () => {
    try {
      const result = search ? await axios.get(`${app.URL}/user/search/${search}`) : [];
      searchedUser = result.data;
    } catch (e) {
      console.log('Error');
      if (e.message) setErr(e.response.data.message);
      setTableShow(false);
    }

    console.log(searchedUser);
    if (searchedUser) {
      setSearchShow(true);
      setRenderList(searchedUser);
    }
    console.log(searchedUser);
  };
  const onChange = async (page, pageSize) => {
    console.log(page);
    console.log(pageSize)
    setPageSize(pageSize);
    setCurrentPage(page);
    if (!page) page = 1;
    let limit = pageSize;
    try {
      let reqObj;
      console.log(Object.keys(searchShow))
      if (Object.keys(searchShow).length !== 0) {
        reqObj = {
          limit: limit,
          offset: limit * (page - 1),
          searchBy: searchShow.searchFor,
          searchTxt: searchShow.searchItem,
        };
      } else {
        reqObj = {
          limit: limit,
          offset: limit * (page - 1),
          // searchBy: 'name',
          searchTxt: '',
        };
      }
      console.log(reqObj)
      await productConfListingAPI(reqObj);
    } catch (e) {
      console.log('Error');
      if (e.message) setErr(e.message);
      setTableShow(false);
    }
  };
  const handleSearchChange = async (e) => {
    console.log(e.target.value)
    if (!e.target.value) {
      try {
        let searchData = {
          limit: pageSize,
          offset: 0,
          // searchBy: "name",
          searchTxt: " ",
        };
        console.log(searchData);
        await productConfListingAPI(searchData);
        setSearchShow({})
      } catch (e) {
        console.log('Error');
        if (e.message) setErr(e.message);
        setTableShow(false);
      }
    }
  };
  const editProductConf = (e) => {
    localStorage.setItem('productConfId', e.prodConfigId);
    navigate('/productconfiguration/update', { replace: true });
  };
  const addProductConf = () => {
    navigate('/productconfiguration/create', { replace: true });
  };

  return (
    <>
      <ListinngCard
        heading="Product Configuration Management"
        addButton={addProductConf}
        onSearch={onFinish}
        onSearchFailed={onFinishFailed}
        searchItems={searchItems}
        tableHeaders={tableHeaders}
        tableRows={renderList}
        editButton={editProductConf}
        onPageChange={onChange}
        currentPage={currentPage}
        pagesCount={pagesCount}
        patientsListing={false}
        inventoryListing={false}
        categoryListing={false}
        configurationListing={true}
      />
    </>
  );
};

export default ProductConfListing;
