import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { Select, Form, Input, Row, Col, DatePicker, Switch } from 'antd';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { app } from '../../config';
import useFetchProducts from '../../hooks/fetchProducts';
import useFetchPartners from '../../hooks/fetchPartner';
import getProductInventory from '../../hooks/getProductInventoryById';
import moment from 'moment';
import getProductConf from '../../hooks/getProductConfById';
import { LeftCircleOutlined } from '@ant-design/icons';
import getHeaders from '../../utils/authHeaders';
const { TextArea } = Input;




const EditProductConf = () => {
    const { t } = useTranslation(['common']);
    const [addUserResp, setAddUserResp] = useState({});
    const [show, setShow] = useState(false);
    let navigate = useNavigate();
    let configurationId = localStorage.getItem('productConfId');
    let productConf = getProductConf(configurationId);
    const config = productConf.response ? productConf.response.data : [];
    const fetchedProducts = useFetchProducts();
    const products = fetchedProducts.response ? fetchedProducts.response.data : [];
    const fetchedPartners = useFetchPartners();
    const partners = fetchedPartners.response ? fetchedPartners.response.data : [];
    const [uploadObj, setUploadObj] = useState({});

    const uploadData = async (userDetails) => {
        try {
            const res = await axios.post(`${app.URL}/api/v1/admin/productconfiguration/update/${configurationId}`, userDetails, { headers: getHeaders() });
            if (res.data.result.success) {
                setAddUserResp(res.data.result.data);
                setTimeout(() => {
                    navigate('/productconfiguration/listing');
                }, 1000);
            } else if (!res.data.result.success) {
                window.alert(res.data.result.msg);
            }
        } catch (err) {
            console.log(err);
            if (err) console.log(err.response.data.message);
            else return;
        }
    };
    const onFinish = (values) => {
        console.log("Success ", values)
        setUploadObj({
            productId: values.product ? values.product : null,
            partnerId: values.partner ? values.partner : '',
            serialNo: values.serialNumber ? values.serialNumber : null,
            isActive: values.isActive,
        });
        setShow(true);
    };
    const submitData = () => {
        if (Object.keys(uploadObj).length > 0) {
            uploadData(uploadObj);
        }
    };
    const handleClose = () => setShow(false);
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };
    return (
        <div class='container'>
            <div class='row mt-4 w-75 tbl'>
                <div class='row topbar ' style={{ height: '70px' }}>
                    <div class='col-lg-4 fs-3 fw-bold'>
                        {' '}
                        <LeftCircleOutlined
                            onClick={() => {
                                navigate('/productconfiguration/listing');
                            }}
                            className='mt-3 ml-4 back-icon'
                            style={{ fontSize: '38px' }}
                        />
                    </div>
                    <div class='col-lg-7 fs-3 fw-bold '>
                        <h2 class=' mb-5 mt-4 fw-bold text-white fs-4 ml-4'> {t('Edit Product Configuration')} </h2>
                    </div>{' '}
                </div>
                <div class='col mb-5 w-50 mt-4'>
                    <div class='col-lg-9 align-self-center mt-2 mb-4'>
                        {productConf.response ? <Form
                            name='basic'
                            labelCol={{
                                span: 8,
                            }}
                            wrapperCol={{
                                span: 16,
                            }}
                            style={{
                                maxWidth: 600,
                            }}
                            initialValues={{
                                remember: true,
                                ['product']: config.productId,
                                ['partner']: config.partnerId,
                                ['serialNumber']: config.serialNo,
                                ['isActive']: config.isActive === '1' ? true : false,
                            }}
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            autoComplete='off'
                        >
                            <Row>
                                <Col span={15}>
                                    <Form.Item
                                        label='Product'
                                        name='product'
                                        colon={false}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Select Product Name',
                                            },
                                        ]}
                                    >
                                        <Select placeholder='Select Product'>
                                            {products.map((item) => (
                                                <Select.Option value={item.productId}>{item.name}</Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={15}>
                                    <Form.Item
                                        label='Partner'
                                        name='partner'
                                        colon={false}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Select Partner Name',
                                            },
                                        ]}
                                    >
                                        <Select placeholder='Select Partner'>
                                            {partners.map((item) => (
                                                <Select.Option value={item.partnerId}>{item.partnerName}</Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row>
                                <Col span={15}>
                                    <Form.Item
                                        label='Serial No.'
                                        name='serialNumber'
                                        colon={false}
                                        style={{ wordWrap: 'normal' }}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Enter Serial No',
                                            },
                                        ]}
                                    >
                                        <Input placeholder='Enter Serial No' />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row >
                                <Col>
                                    <Form.Item label='Active' colon={false} valuePropName='checked' name='isActive'>
                                        <Switch />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Form.Item
                                wrapperCol={{
                                    offset: 8,
                                    span: 16,
                                }}
                            >
                                {addUserResp.code === 200 ? (
                                    <Modal show={show} onHide={handleClose}>
                                        <Modal.Header closeButton>
                                            <Modal.Title className='text-center'>Success</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>{addUserResp.message}</Modal.Body>
                                        <Modal.Footer></Modal.Footer>
                                    </Modal>
                                ) : addUserResp.code === 400 ? (
                                    <Modal show={show} onHide={handleClose}>
                                        <Modal.Header closeButton>
                                            <Modal.Title className='text-center'>Failed</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>{addUserResp.data.message}</Modal.Body>
                                        <Modal.Footer></Modal.Footer>
                                    </Modal>
                                ) : (
                                    <Modal show={show} onHide={handleClose}>
                                        <Modal.Header closeButton>
                                            <Modal.Title>Confirmation</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>Are you sure you want to add patient?</Modal.Body>
                                        <Modal.Footer>
                                            <Button variant='secondary' onClick={handleClose}>
                                                Cancel
                                            </Button>
                                            <Button variant='primary' htmlType='submit' onClick={submitData}>
                                            Confirm
                                            </Button>
                                        </Modal.Footer>
                                    </Modal>
                                )}

                                <button type='primary' class='btn btn-primary button' htmlType='submit'>
                                    Save
                                </button>
                            </Form.Item>
                        </Form> : ""}
                    </div>
                </div>
            </div>
        </div>
    );
}
export default EditProductConf;