import React from 'react';
import CalenderImg from '../../assets/images/calender.png';
import moment from 'moment';
import { DatePicker } from 'antd';

const EcomDashboardHeader = ({ onChange, title, durationSelect }) => {
  const { RangePicker } = DatePicker;

  return (
    <div className='row p-0 pb-4'>
      <div className='for-before col-12 col-lg-4 col-md-6 head fs-3 fw-bold' style={{ backgroundColor: '#a7c1d9', borderBottomRightRadius: '10px', color: '#FFFFFF' }}>
        {title ? title : 'Ecommerce Dashboard'}
      </div> 
      <div className='col-12 mt-2 col-lg-4 col-md-3'>
        <RangePicker onChange={onChange} />
      </div>

      <div className='col-12 mt-2 col-lg-4 col-md-3 d-flex align-end' style={{ margin: 'auto', position: 'relative' }}>
        <img className='calender-img' src={CalenderImg} />
        <select className='form-control drop-down-select' name='dates' id='date' onChange={(e) => durationSelect(e.target.value)}>
          <option value={moment().subtract(24, 'hours').format('YYYY-MM-DD')}>last 24 hours</option>
          <option value={moment().subtract(7, 'd').format('YYYY-MM-DD')}>last week</option>
          <option value={moment().subtract(1, 'months').format('YYYY-MM-DD')}>last month</option>
          <option value={moment().subtract(1, 'year').format('YYYY-MM-DD')}>last year</option>
        </select>
      </div>
    </div>
  );
};

export default EcomDashboardHeader;
