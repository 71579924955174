import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { message, Switch, Form, Input } from 'antd';
import { app } from '../../config';
import { LeftCircleOutlined } from '@ant-design/icons';
import getHeaders from '../../utils/authHeaders';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import getStore from '../../hooks/getStore';
import { UpdateRecord } from '../../layouts/UpdateRecord';
import { InputField } from '../../layouts/FormFields';
const editStore = () => {
  const { t } = useTranslation(['common']);
  const [uploadObj, setUploadObj] = useState({});
  const [loading, setLoading] = useState(false);
  const [editStoreResp, setEditStoreResp] = useState({});
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  let store = JSON.parse(localStorage.getItem('storeId'));
  let res = getStore(store.storeId);
  let storeObj = res.response ? res.response.data : null;
  let user = JSON.parse(localStorage.getItem('user'));

  const uploadData = async (uploadObj) => {
    console.log('Function Running');
    setLoading(true);
    let userId = user?.result.data.user.userId;
    uploadObj.userId = userId;
    try {
      const res = await axios.post(`${app.URL}/api/v1/admin/store/update/${store.storeId}`, uploadObj, { headers: getHeaders() });
      console.log('🚀 ~ file: EditOrg.js:49 ~ uploadData ~ res', res);
      setLoading(false);
      setShow(false)
      if (res.data.result.code === 200) {
        setEditStoreResp(res.data.result.data);
        message.success(res.data.result.msg);
        setTimeout(() => {
          navigate('/store/listing');
        }, 1000);
      }
    } catch (err) {
      setLoading(false);
      message.warning('Something went wrong');
      console.log(err);
      setShow(false);
      
    }
  };

  const onFinish = (values) => {
    console.log('Success:', values);
    setUploadObj({
      name: values.storeName ? values.storeName : null,
      isActive: values.isActive === true ? 1 : 0,
    });
    setShow(true);
  };
  console.log(uploadObj);
  const submitData = () => {
    console.log('calling');
    if (Object.keys(uploadObj).length > 0) {
      uploadData(uploadObj);
    }
  };
  const handleClose = () => setShow(false);
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  function goBack() {
    navigate('/store/listing', { replace: true });
  }
  window.addEventListener('popstate', goBack);

  return (
    <UpdateRecord
      loading={loading}
      pageTitle='Update Store'
      response={res.response}
      addUserResp={editStoreResp}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      switchBtn={true}
      goBack={goBack}
      submitData={submitData}
      updateMessage='Are you sure you want to update store?'
      show={show}
      handleClose={handleClose}
      upload={false}
      inputs={[
        <InputField
          label='Store Name'
          placeholder='Enter Store Name'
          name='storeName'
          col='col-lg-6 col-md-4 col-12'
          required={true}
          rules={[
            {
              required: true,
              message: 'Enter Store Name',
            },
          ]}
        />,
      ]}
      initialValues={{
        remember: true,
        ['storeName']: storeObj?.name,
        ['isActive']: storeObj?.isActive === '1' ? true : false,
      }}
    />
  );

  // return (

  //   <>
  //   <div className="col-lg-12">
  //     <div className="dashboard-card">
  //       <div className="row" style={{ paddingRight: "10px" }}>
  //         <div className="for-before col-lg-4 head fs-3 fw-bold" style={{ backgroundColor: "#a7c1d9", borderBottomRightRadius: "10px", color: "#FFFFFF" }}>
  //           Update Store
  //         </div>
  //       </div>
  //       <div class='col mb-5 mt-4'>
  //         <div class='col-lg-12 align-self-center mt-2 mb-4'>
  //         {res.response ?     <Form
  //               name='store'
  //                         // labelCol={{
  //                         //   span: 8,
  //                         // }}
  //                         // wrapperCol={{
  //                         //   span: 16,
  //                         // }}
  //                         // style={{
  //                         //   maxWidth: 600,
  //                         // }}
  //                         initialValues={{
  //                                         remember: true,
  //                                         ['storeName']: storeObj.name,
  //                                         ['isActive']: storeObj.isActive === '1' ? true : false,
  //                                       }}
  //                         onFinish={onFinish}
  //                         onFinishFailed={onFinishFailed}
  //                         autoComplete='off'
  //           >
  //             {/* Select box for Role */}

  //             <div className='row' style={{padding:"20px 50px"}}>
  //               <div className='col-lg-6'>
  //               <label>Store Name <span className="required-star">*</span></label>
  //               <Form.Item
  //               name='storeName'
  //               defaultValue='1'
  //               colon={false}
  //               rules={[
  //                 {
  //                   required: true,
  //                   message: 'Enter Store Name',
  //                 },
  //               ]}
  //             >
  //               <Input placeholder='Enter Store Name' />
  //             </Form.Item>
  //             <Form.Item label='Active' colon={false} valuePropName='checked' name='isActive'>
  //               <Switch />
  //                   </Form.Item>
  //                   <button
  //                     onClick={() => {
  //                       navigate('/store/listing');
  //                     }}
  //                     type='primary'
  //                     className='btn btn-primary button patient-cancel-btn col-lg-3 mr-2 col-lg-5 mr-2 '
  //                   >
  //                     Cancel
  //                   </button>

  //                   <button type='primary' class='btn btn-primary button col-lg-5' htmlType='submit'>
  //                     Update
  //                   </button>
  //             <Form.Item
  //               >
  //                 {editStoreResp.code === 200 ? (
  //                   <Modal show={show} onHide={handleClose}>
  //                     <Modal.Header closeButton>
  //                       <Modal.Title className='text-center'>Success</Modal.Title>
  //                     </Modal.Header>
  //                     <Modal.Body>{editStoreResp.message}</Modal.Body>
  //                     <Modal.Footer></Modal.Footer>
  //                   </Modal>
  //                 ) : editStoreResp.code === 400 ? (
  //                   <Modal show={show} onHide={handleClose}>
  //                     <Modal.Header closeButton>
  //                       <Modal.Title className='text-center'>Failed</Modal.Title>
  //                     </Modal.Header>
  //                     <Modal.Body>{editStoreResp.data.message}</Modal.Body>
  //                     <Modal.Footer></Modal.Footer>
  //                   </Modal>
  //                 ) : (
  //                   <Modal show={show} onHide={handleClose}>
  //                     <Modal.Header closeButton>
  //                       <Modal.Title>Confirmation</Modal.Title>
  //                     </Modal.Header>
  //                     <Modal.Body>Are you sure you want to update store?</Modal.Body>
  //                     <Modal.Footer>
  //                       <Button variant='secondary' onClick={handleClose}>
  //                         Cancel
  //                       </Button>
  //                       <Button variant='primary' htmlType='submit' onClick={submitData}>
  //                     Confirm
  //                       </Button>
  //                     </Modal.Footer>
  //                   </Modal>
  //                 )}

  //                </Form.Item>
  //               </div>

  //             </div>
  //             <div className='row' style={{padding:"0 50px"}}>
  //                <div className='col-lg-6'>

  //               </div>
  //               <div className='col-lg-6'>

  //               </div>
  //             </div>

  //             <div className='row'>

  //             </div>
  //           </Form>:" "}
  //         </div>
  //       </div>
  //     </div>
  //   </div>
  // </>
  // );
};

export default editStore;
