import React, { useState } from 'react';
import { Spin, Form, message } from 'antd';
import axios from 'axios';
// import { useLocation, useNavigate } from 'react-router-dom';
import { LoadingOutlined, UserOutlined, LockOutlined, KeyOutlined } from '@ant-design/icons';
import { app } from '../../config';
import './loginstyles.css';
import { FormInputItem } from '../../components/common/FormItem';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function ForgotPassword() {
  let navigate = useNavigate();
  const [forgetView, setForgetView] = useState('email');
  const [seconds, setSeconds] = useState(60);
  const [timerActive, setTimerActive] = useState(false);
  const [email, setEmail] = useState('');
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
      }}
      spin
    />
  );
  // const [fieldsrequired, setFieldsRequired] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errorMsg, setErrorMsg] = useState();
  // const navigate = useNavigate();
  // const location = useLocation();
  // const from = location.state?.from?.pathname || '/dashboard/listing';
  window.document.title = 'Forgot Password';

  const sendEmail = async () => {
    console.log('function called');
    if (email) {
      setSuccess(true);
      try {
        let res = await axios.post(`${app.URL}/api/v1/admin/adminAccount/sendotp`, { email });
        console.log(res);
        if (res.data.result.success) {
          setErrorMsg('');
          setSuccess(false);
          setForgetView('otp');
          setTimerActive(true);
          setSeconds(60);
          message.success(res.data.result.msg);
        } 
      } catch (err) {
        console.log(err);
        // message.error('Sorry! we are facing some issue.');
        message.error(err.response.data.result.msg);
        setSuccess(false);
        // err.response ? console.log(err.response.data.result.msg) : console.log('Error');
        // if (typeof err === 'string') setErrorMsg(err);
        // else if (typeof err === 'object') { setErrorMsg(err.response.data.result.msg); message.error(err.response.data.result.msg);}
          
        
      }
    }
  };

  const verifyOTP = async (data) => {
    console.log('function called');
    if (data.otp && data.email) {
      setSuccess(true);
      try {
        let res = await axios.post(`${app.URL}/api/v1/admin/adminAccount/verifyotp`, data);
        console.log(res);
        if (res.data.result.success) {
          setErrorMsg('');
          setSuccess(false);
          setForgetView('newpass');
          setTimerActive(false);
          message.success(res.data.result.msg);
        } else {
          setSuccess(false);
          message.error(res.data.result.msg);
        }
      } catch (err) {
        console.log(err);
       
        setSuccess(false);
       
        // if (typeof err === 'string') setErrorMsg(err);
        // else if (typeof err === 'object') {
        //   // setErrorMsg(err.response.data.result.msg);
        //   // message.error(err.response.data.result.msg);
        // }
      }
    }
  };

  const checkPassword = (values) => {
    const { password, confirmPassword } = values;
    // Check if password length is at least 8 characters
    if (password.length < 8) {
      return 'Password must be at least 8 characters long.';
    }

    // Check if password and confirm password match
    if (password !== confirmPassword) {
      return 'Passwords do not match.';
    }

    // Password meets all conditions
    return 'Password is valid.';
  };

  const setNewPassword = async (data) => {
    console.log('function called');
    if (data.password && data.email) {
      setSuccess(true);
      try {
        let res = await axios.post(`${app.URL}/api/v1/admin/adminAccount/updatepwd`, data);
        console.log(res);
        if (res.data.result.success) {
          setErrorMsg('');
          setSuccess(false);
          setTimerActive(false);
          message.success(res.data.result.msg);
          setForgetView('email');
          navigate('/admin/login');
        }
      } catch (err) {
        console.log(err);
        
        // message.error('Try Again after some time.');
        setSuccess(false);
        
      }
    }
  };

  const onFinish = (values) => {
    if (forgetView === 'email') {
      sendEmail();
    } else if (forgetView === 'otp') {
      const data = {
        otp: values.otp,
        email,
      };
      console.log(data)
      verifyOTP(data);
    } else if (forgetView === 'newpass') {
      const data = {
        password: values.password,
        email,
      };
      console.log(checkPassword(values));
      if (checkPassword(values) === 'Password is valid.') {
        
        
        setNewPassword(data);
      }
      else{
        message.error(checkPassword(values));
      }
    }
  };

  

  const onFinishFailed = (errorInfo) => {};

  useEffect(() => {
    let timer;
    if (timerActive && seconds > 0) {
      timer = setInterval(() => {
        setSeconds((prevSeconds) => {
          if (prevSeconds === 0) {
            clearInterval(timer);
            // Perform any action when the timer reaches 0
            return 0;
          }
          return prevSeconds - 1;
        });
      }, 1000);
    } else if (!timerActive && seconds !== 0) {
      clearInterval(timer);
    }

    // Clear interval on component unmount
    return () => clearInterval(timer);
  }, [timerActive, seconds]); // Empty dependency array ensures this effect runs only once

  // Format the seconds to display as minutes and seconds
  const displayTime = `${Math.floor(seconds / 60)
    .toString()
    .padStart(2, '0')}:${(seconds % 60).toString().padStart(2, '0')}`;


  return (
    <>
      <div className='bg-white w-100 login-bg-img'>
        {/* <div class='row'>
          <AdmnNav />
        </div> */}
        <div className='col-lg-12 col-md-6 col-sm-12 '>
          <div className='row p-5 justify-content-center'>
            <div className='col-md-12 col-lg-4 col-sm-12 login-card'>
              <div className='row justify-content-center'>
                <h2 className='fw-bold text-center mb-4 fs-4 mt-4 text-center'>Forgot Password</h2>
                {forgetView === 'otp' && (
                  <div
                    className='d-flex align-items-center justify-content-center'
                    style={{
                      fontSize: '1.5rem',
                    }}
                  >
                    {displayTime}
                  </div>
                )}

                <div className='col-lg-9 mt-4 '>
                  <Form
                    name='normal_login'
                    className='login-form'
                    initialValues={{
                      remember: true,
                    }}
                    width={80}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                  >
                    {forgetView === 'email' ? (
                      <FormInputItem
                        name='email'
                        rules={[
                          {
                            required: true,
                            message: 'Please enter Email!',
                          },
                        ]}
                        className='email-box'
                        onChange={(e) => setEmail(e.target.value)}
                        type='email'
                        prefix={<UserOutlined className='site-form-item-icon' />}
                        placeholder='Email'
                      />
                    ) : forgetView === 'otp' ? (
                      <FormInputItem
                        name='otp'
                        rules={[
                          {
                            required: true,
                            message: 'Please enter otp!',
                          },
                        ]}
                        className='email-box'
                          type='number'
                        disabled={displayTime === '00:00'}
                        pattern='\d*'
                        prefix={<LockOutlined className='site-form-item-icon' />}
                        placeholder='OTP'
                      />
                    ) : forgetView === 'newpass' ? (
                      <>
                        <FormInputItem
                          name='password'
                          rules={[
                            {
                              required: true,
                              message: 'Please enter password!',
                            },
                          ]}
                          className='email-box'
                          type='text'
                          prefix={<LockOutlined className='site-form-item-icon' />}
                          placeholder='Password'
                        />
                        <FormInputItem
                          name='confirmPassword'
                          rules={[
                            {
                              required: true,
                              message: 'Please enter confirm password!',
                            },
                          ]}
                          className='email-box'
                          type='text'
                          prefix={<LockOutlined className='site-form-item-icon' />}
                          placeholder='Confirm Password'
                        />
                      </>
                    ) : (
                      ''
                    )}

                    {displayTime !== '00:00' && (
                      <Form.Item>
                        <button type='primary' htmlType='submit' className='btn btn-primary w-100 ' disabled={displayTime === '00:00'}>
                          {success ? <Spin indicator={antIcon} /> : <>{forgetView === 'email' ? 'Send Email' : forgetView === 'otp' ? 'Vertify OTP' : forgetView === 'newpass' ? 'Change Password' : ''}</>}
                        </button>
                      </Form.Item>
                    )}
                  </Form>
                  {displayTime === '00:00' && (
                    <button type='primary' className='btn btn-primary w-100 my-1' onClick={sendEmail}>
                      {success ? <Spin indicator={antIcon} /> : 'Resend OTP'}
                    </button>
                  )}
                  {/* {errorMsg && (
                    <small className='m-0' style={{ color: 'crimson' }}>
                      {errorMsg}
                    </small>
                  )} */}
                </div>
                {/* <p class='not-a-member'>Not a member? Sign up now</p> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ForgotPassword;
