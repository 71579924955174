import React, { useState, useEffect } from 'react';
import { InputNumber } from 'antd';
import axios from 'axios';
import { app } from '../../config';
import getHeaders from '../../utils/authHeaders';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import { message, Image, Select, Form, Input, Row, Col, DatePicker } from 'antd';
const { TextArea } = Input;
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { CloseCircleOutlined, LoadingOutlined } from '@ant-design/icons';

const OrderRefundModal = ({  isVisible, setIsVisible, ecomOrderId, ecomOrderNo, ecomOrderCreatedDate, title, status, address, productsData, shipmentCharges, totalShipmentAmount, tax, grandTotal, requestId, addressId, requestType, requestStatus, customerComment, image, patientId, refreshView, shipmePackageName, orderPayment }) => {
  console.log(requestType);
  const [loader, setLoader] = useState(false);
  // const tooltipText = 'Order payment is not settled yet, Try again after sometime!';
  // const renderTooltip = (props) => (
  //   <Tooltip id='button-tooltip' {...props}>
  //     {tooltipText}
  //   </Tooltip>
  // );

  // const layout = {
  //   labelCol: {
  //     span: 20,
  //   },
  //   wrapperCol: {
  //     span: 18,
  //   },
  // };

  const [custComment, setCustComment] = useState('');
  const [custImage, setCustImage] = useState('');
  const [refundComment, setRefundComment] = useState('');
  const [refundAmount, setRefundAmount] = useState(0);

  // let condition = requestType?.toLowerCase() !== 'Replace' || requestType?.toLowerCase() !== 'Missing Item' || requestType?.toLowerCase() !== 'Missing Iem' || requestType?.toLowerCase() !== 'Return & Refund';

  // console.log('type: ', condition);
  useEffect(() => {
    setCustComment('');
    setCustImage('');
    customerComment.length > 0
      ? customerComment.forEach((ele) => {
          if (ele.commentBy == 'customer') {
            setCustComment(ele.comment);
            setCustImage(ele.image);
          }
        })
      : '';
  }, [customerComment]);

  console.log(custComment);
  const onFinish = (values) => {
    console.log('onFinish');
    console.log(values);
    if (!values.refundAmount) values.refundAmount = grandTotal + tax;
    console.log(values);
  };

  const resendOrder = async (data) => {
    console.log('resend order');
    try {
      let resp = await axios.post(`${app.URL}/api/v1/admin/ecomorders/resendorder`, data, { headers: getHeaders() });
      console.log('resp');
      console.log(resp);
      setRefundComment('');

      setTimeout(() => {
        setIsVisible(!isVisible);
      }, 500);
      refreshView(true);
    } catch (e) {
      throw e;
    }
  };

  const reciveorder = async (data) => {
    console.log(data);

    try {
      let resp = await axios.post(`${app.URL}/api/v1/admin/ecomorders/receiveback`, data, { headers: getHeaders() });
      console.log(resp);
      console.log('resp');
      setRefundComment('');
    setLoader(false);


      setTimeout(() => {
        setIsVisible(!isVisible);
      }, 500);
      refreshView(true);
    } catch (e) {
    setLoader(false);

      throw e;
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  const cancelShipment = async (data) => {
    console.log('In Cancel Shipment');
    console.log(data);
    try {
      let resp = await axios.post(`${app.URL}/api/v1/admin/userrequest/cancelshipment`, data, { headers: getHeaders() });
      console.log('resp');
      console.log(resp);
      setRefundComment('');
    setLoader(false);
      setTimeout(() => {
        setIsVisible(!isVisible);
      }, 500);
      refreshView(true);
    } catch (e) {
    setLoader(false);
      throw e;
    }
  };
  const onCancelClick = async () => {
    console.log(cancelData);
  };
  const submitData = async () => {
    let apiRefundAmount = refundAmount ? refundAmount : grandTotal;
    console.log('onSubmit Clicked');
    setLoader(true);
    const uploadObj = {
      comment: refundComment,
      refundAmount: requestType === 'Cancel' || requestType === 'Return & Refund' ? apiRefundAmount : null,
      requestId: requestId,
      patientId: patientId,
      ecomOrderId: ecomOrderId,
      addressId: addressId,
    };
    console.log('uploadObj: ', uploadObj);
    console.log('req type', requestType);
    console.log('request Status', requestStatus);

    if (requestType === 'Missing Item') {
      // missing item case resend the order
      console.log('missing case obj: ', uploadObj);
      await resendOrder(uploadObj);
      setRefundComment('');
    } else if (requestType === 'Return & Refund' && requestStatus?.toLowerCase() === 'pending') {
      // receive back case receive back order
      console.log('receive back case obj: ', uploadObj);

      await reciveorder(uploadObj);
      setRefundComment('');
    } else if (requestType === 'Replace' && requestStatus?.toLowerCase() === 'pending') {
      // receive back case receive back order
      console.log('receive back case obj: ', uploadObj);

      await reciveorder(uploadObj);
      setRefundComment('');
    } else if (requestType === 'Replace' && requestStatus?.toLowerCase() === 'order received') {
      // receive back case receive back order
      console.log('resend order case obj: ', uploadObj);

      await resendOrder(uploadObj);
      setRefundComment('');
    } else if (requestType === 'Cancel' || requestType === 'Return & Refund') {
      // approve and refund case cancel shipment
      console.log('refund and approve case obj: ', uploadObj);

      await cancelShipment(uploadObj);
      setRefundComment('');
    } else {
      console.log('no case match obj: ', uploadObj);

      setRefundComment('');
      console.log('none of the cases matches here');
    }

    //    if(uploadObj && uploadObj.refundAmount){
    //     console.log("uploadObj: ", uploadObj);
    //    await cancelShipment(uploadObj)
    // }
    // setIsVisible(!isVisible);
  };

  console.log('shipment charges: ', shipmentCharges);

  const rejectRequest = async () => {
    let apiRefundAmount = refundAmount ? refundAmount : grandTotal;

    console.log('Request Reject Clicked');
    const uploadObj = {
      refundAmount: requestType === 'Cancel' || requestType === 'Return & Refund' ? apiRefundAmount : null,
      requestId: requestId,
      patientId: patientId,
      ecomOrderId: ecomOrderId,
      addressId: addressId,
    };
    try {
      let resp = await axios.post(`${app.URL}/api/v1/admin/userrequest/reject`, uploadObj, { headers: getHeaders() });
      console.log(resp);
      setTimeout(() => {
        setIsVisible(!isVisible);
        setRefundComment('');
        refreshView(true);
      }, 500);
    } catch (e) {
      throw e;
    }
  };

  // message.info(requestType)

  return (
    <div>
      <Form
        name='basic'
        initialValues={{
          // userComment: custComment?custComment:"Nothing to display",
          refundAmount: grandTotal + tax,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Modal
          show={isVisible}
          size='lg'
          onHide={() => {
            setIsVisible(!isVisible);
            setRefundAmount(0);
          }}
          centered
        >
          <Modal.Header
            style={{
              display: 'block',
            }}
          >
            <Modal.Title style={{ display: 'block', padding: '9px 13px 8px 8px', fontSize: '20px' }}>
              <div className='row'>
                <div className='col-lg-6'>{ecomOrderNo}</div>
                <div className='col-lg-5 text-right'>{ecomOrderCreatedDate}</div>
                <div
                  className='col-lg-1 text-right '
                  style={{
                    fontSize: 20,
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    setIsVisible(!isVisible);
                  }}
                >
                  <CloseCircleOutlined style={{ paddingBottom: '4px' }} />
                </div>
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ padding: '0px 20px !important' }}>
            <div
              className='p-4 mb-3 mt-2'
              style={{
                border: '1px solid silver',
                borderRadius: 10,
              }}
            >
              <div className='row'>
                <div className='col-lg-6 fs-5 mt-0 mb-0 pt-0 pb-0 pl-3'>{title}</div>
                <div className='col-lg-6 mt-0 mb-0 pt-2 pb-0 pl-3 text-right'>{status}</div>
                <div className='col-lg-6  mt-0 mb-0 pt-0 pb-0 pl-3'>{address}</div>
                <div className='col-lg-6 mt-0 mb-0 pt-2 pb-0 pl-3 text-right'>{shipmePackageName}</div>

                {requestId && (
                  <>
                    <div className='col-lg-12  mt-0 mb-0 pt-0 pb-0 pl-0 text-right'>Request Type: {requestType}</div>
                    <div className='col-lg-12  mt-0 mb-0 pt-0 pb-0 pl-0 text-right'>Request Status: {requestStatus}</div>
                  </>
                )}

                {/* {requestId ? <div className='col-lg-12  mt-0 mb-0 pt-0 pb-0 pl-3'>Request Type: {requestType}  Request Status: {requestStatus}</div> : " "} */}
              </div>

              {/* <div className='p-4 mb-3 mt-2' style={{
                    border:"1px solid silver",
                    borderRadius:10,         
                }}> */}

              <div className='mb-1'>
                <table id='customers' className='w-100'>
                  <thead>
                    <tr
                      style={{
                        fontWeight: 'bold',
                      }}
                    >
                      <th>Product Name </th>
                      <th>Quantity</th>
                      <th>Unit Price</th>
                      <th>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {productsData?.map((product, index) => {
                      return (
                        <tr>
                          <td style={{ width: '100px' }}>{product.productName}</td>
                          <td style={{ width: '100px' }}>{product.quantity}</td>
                          <td style={{ width: '100px' }}>${Number(product.unitPrice)?.toFixed(2)}</td>
                          <td style={{ width: '100px' }}>${Number(parseFloat(product.quantity) * parseFloat(product.unitPrice))?.toFixed(2)}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <div className='row'>
                <div className='col-lg-12  mt-0 mb-0 pt-0 pb-0 pl-3 text-right'>Total Product Amount: ${totalShipmentAmount?.toFixed(2)}</div>
                <div className='col-lg-12  mt-0 mb-0 pt-0 pb-0 pl-3 text-right'>Tax: ${tax?.toFixed(2)}</div>
                <div className='col-lg-12  mt-0 mb-0 pt-0 pb-0 pl-3 text-right'>Shipment: {shipmentCharges == 0 ? `Free` : `$${shipmentCharges}`} </div>
                <div className='col-lg-12  mt-0 mb-0 pt-0 pb-0 pl-3 text-right'>Grand Total: ${grandTotal + tax}</div>
              </div>
            </div>

            <div className='row'>
              {custComment && custComment.length > 0 ? (
                <>
                  <div className='col-lg-3'>
                    <label>User Comment</label>
                  </div>
                  <div className='col-lg-9'>
                    <Form.Item name='userComment'>
                      <TextArea style={{ color: 'black' }} defaultValue={custComment} rows={2} autoSize={false} disabled={true} />
                    </Form.Item>
                  </div>
                </>
              ) : (
                ' '
              )}
              <div className='col-lg-12'>
                {custImage ? (
                  <div className='row'>
                    <div className='col-lg-3'>
                      <label>Images</label>
                    </div>
                    <div className='col-lg-9'>
                      <Image.PreviewGroup>
                        <Image width={200} height={250} src={custImage} />
                        {/* <Image
                                             width={200}
                                             src="https://gw.alipayobjects.com/zos/antfincdn/aPkFc8Sj7n/method-draw-image.svg"
                                         /> */}
                      </Image.PreviewGroup>
                    </div>
                  </div>
                ) : (
                  ''
                )}

                <div className='row'>
                  <div className='col-lg-3' style={{ width: '21% !important' }}>
                    <label>Admin Comment</label>
                  </div>
                  <div className='col-lg-9'>
                    <Form.Item
                      // label="Refund Comment"
                      colon={false}
                      name='refundComment'
                      rules={
                        [
                          // { required: true, message: 'Please Enter Admin Comment' }
                        ]
                      }
                    >
                      <TextArea
                        rows={2}
                        onChange={(event) => {
                          setRefundComment(event.target.value);
                        }}
                      />
                    </Form.Item>
                  </div>

                  {(requestType === 'Cancel' || requestType === 'Return & Refund') && (
                    <>
                      <div className='col-lg-3'>
                        <label>
                          Override Amount
                          <span className='required-star'>*</span>
                        </label>
                      </div>

                      <div className='col-lg-9'>
                        <Form.Item name='refundAmount' rules={[{ required: true, message: 'Please Enter Refund Amount' }]}>
                          <InputNumber
                            style={{ padding: '0px' }}
                            type='number'
                            defaultValue={grandTotal}
                            size='medium'
                            controls={false}
                            onChange={(event) => {
                              setRefundAmount(event);
                            }}
                          />
                        </Form.Item>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant='secondary'
              onClick={() => {
                console.log('cancel click');
                setRefundComment('');
                setIsVisible(!isVisible);
              }}
            >
              Cancel
            </Button>

            {/* <Form.Item> */}
            {/* <Button className='btn btn-primary button patient-cancel-btn  mr-2' htmlType='submit' onClick={rejectRequest}>
              Reject
            </Button> */}

            {/* <Button variant='primary' htmlType='submit' onClick={submitData}>
                Confirm
              </Button> */}
            {/* {orderPayment !== 'Settled' && (requestType === 'Cancel' || requestType === 'Refund') ? (
              <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={renderTooltip}>
                <Button variant='primary' htmlType='submit' style={{ width: '80px', height: '34px', backgroundColor: '#f2b544', fontSize: '1rem', border: 'none !important', borderRadius: '5px', color: '#fff', outline: 'none', cursor: 'not-allowed' }}>
                  Confirm
                </Button>
              </OverlayTrigger>
            ) : ( */}
            <Button variant='primary' htmlType='submit' onClick={submitData} style={{ width: '80px', height: '34px', backgroundColor: '#f2b544', fontSize: '1rem', border: 'none !important', borderRadius: '5px', color: '#fff', outline: 'none' }}>
              {loader ? <LoadingOutlined /> : "Confirm"}  
            </Button>
            {/* )} */}
            {/* </Form.Item> */}
          </Modal.Footer>
        </Modal>
      </Form>
    </div>
  );
};

export default OrderRefundModal;
