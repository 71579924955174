import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { message, Switch, Select, Form, Input } from 'antd';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { app } from '../../config';
import { LeftCircleOutlined } from '@ant-design/icons';
import getHeaders from '../../utils/authHeaders';
import { AddRecord } from '../../layouts/AddRecord';
import { InputField } from '../../layouts/FormFields';

const AddProductCategory = () => {
  const { t } = useTranslation(['common']);
  //   const [addStoreResp, setAddStoreResp] = useState({});
  // const [errorObj, setErrorObj] = useState({});
  
  const [resetModal, setResetModal] = useState(false);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [addUserResp, setAddUserResp] = useState({});

  let navigate = useNavigate();
  const [uploadObj, setUploadObj] = useState({});
  const uploadData = async (userDetails) => {
    let user = JSON.parse(localStorage.getItem("user"));
    userDetails.userId = user?.result?.data?.user?.userId;
    try {
      console.log('uploadObj ', userDetails);
      const res = await axios.post(`${app.URL}/api/v1/admin/category/create`, userDetails, { headers: getHeaders() });
      if (res.data.result.success) {
        setAddUserResp(res.data.result.data);
        setTimeout(goBack, 500);
        message.success(res.data.result.msg);

      } else if (!res.data.result.success) {
        message.warning(res.data.result.msg);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
      setShow(false);
      if (err) console.log(err.response.data.message);
      else return;
    }
  };

  const onFinish = (values) => {
    console.log('Success:', values);
    setUploadObj({
      name: values.name ? values.name : null,
      description: values.description ? values.description : null,
      isActive: values.isActive,
    });
    setResetModal(true);
    setShow(true);
  };

  const submitData = () => {
    console.log('calling');
    if (Object.keys(uploadObj).length > 0) {
      uploadData(uploadObj);
    }
  };
  const handleClose = () => setShow(false);
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  function goBack() {
    navigate('/category/listing', { replace: true });
  }
  window.addEventListener('popstate', goBack);

  return (
    <AddRecord
      loading={loading}
      pageTitle='Add Product Category'
      initialValues={{
        remember: true,
        ['isActive']: true,
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      switchBtn={true}
      addUserResp={addUserResp}
      handleClose={handleClose}
      show={show}
      addMessage='Are you sure you want to add category?'
      goBack={goBack}
      submitData={submitData}
      upload={false}
      inputs={[
        <InputField
          col='col-md-6 col-12'
          name='name'
          rules={[
            {
              required: true,
              message: 'Enter Product Category',
            },
          ]}
          placeholder='Enter Product Category'
          label='Product Category'
          required={true}
        />,
        <InputField
          col='col-md-6 col-12'
          name='description'
          rules={[
            {
              required: true,
              message: 'Enter Product Description',
            },
          ]}
          placeholder='Enter Product Description'
          label='Product Description'
          required={true}
        />,
      ]}
    />
  );
};

export default AddProductCategory;
