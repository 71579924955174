import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import axios from 'axios';
import DividerLine from '../../layouts/DividerLine';
import { useTranslation } from 'react-i18next';
import { message, Switch, Select, Form, Input, Row, Col, DatePicker, InputNumber, Tooltip } from 'antd';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { app } from '../../config';
import { LeftCircleOutlined } from '@ant-design/icons';
import InputMask from 'react-input-mask';
import getHeaders from '../../utils/authHeaders';
import useFetchCities from '../../hooks/fetchCities';
import useFetchStates from '../../hooks/fetchStates';
import getPatientById from '../../hooks/getPatientById';
import moment from 'moment';
import fetchEthinicity from '../../hooks/fetchEthinicity';
import { InputField, InputMasker, SelectField, TextAreaField } from '../../layouts/FormFields';
import { UpdateRecord } from '../../layouts/UpdateRecord';
import { noPrespace, noPrespaceNoAlphabetsNoSpecialChar } from '../user/ValidationFunctions';
import editBtnImg from '../../assets/images/edit.png';
import Delete from '../../assets/images/delete.png';
import UpdateAddressModal from './UpdateAddressModal';

const { TextArea } = Input;

const EditPatient = () => {
  const { t } = useTranslation(['common']);
  const [phoneNumber, setPhoneNumber] = useState();
  const [addUserResp, setAddUserResp] = useState({});
  const [loading, setLoading] = useState(false);
  const [pwd, setpwd] = useState('');
  const [confirmPwd, setconfirmPwd] = useState('');
  const [uploadedImg, setUploadedImg] = useState('');
  const [show, setShow] = useState(false);
  const [addressData, setAddressData] = useState([]);
  // const [roles, setRoles ] = useSt
  let navigate = useNavigate();
  let patientId = JSON.parse(localStorage.getItem('editPatientId'));
  const [zipInput, setZipInput] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState(null);
  let header = ['Name', 'Email Address', 'Location', 'Status', 'Action'];


  const handleEditClick = (address) => {
    setSelectedAddress(address);
    setShowModal(true);
  };

  const handleClosed = async() => {
    setShowModal(false);
    setSelectedAddress(null);

    setLoading(true);
    try {
      const patientId = localStorage.getItem('editPatientId');
      const res = await axios.get(`${app.URL}/api/v1/admin/patient/view/${patientId}`, { headers: getHeaders() });
      setLoading(false);

    

      console.log(res)

      if (res.data.result.success) {
        setAddUserResp(res.data.result.data);
         setZipInput(res.data.result.data.zipCode);
         setAddressData(res.data.result.data.addresses);
         if (res.data.result.data.userImage) {
           setUploadedImg(res.data.result.data.userImage);
         }
      } 
    } catch (err) {
      console.log(err);
      setLoading(false);
     
      if (err) console.log(err.response.data.message);
      else return;
    }
  };

   const handleSave = (selectedAddress) => {
     // Handle save logic
     setShowModal(false);
   };

  const handleStatusChange = (checked) => {
    setSelectedAddress({ ...selectedAddress, isActive: checked });
  };

  function goBack() {
    navigate('/patient/listing', { replace: true });
  }

  const patientObj = getPatientById(patientId, setZipInput, setUploadedImg, setAddressData);

  const patient = patientObj.response ? patientObj.response.data : null;
  const ethnicityList = fetchEthinicity();
  const ethnicity = ethnicityList.response ? ethnicityList.response.data : [];
  const [uploadObj, setUploadObj] = useState({});
  // const [tooltip, setTooltip] = useState('');
  // console.log(patientObj?.response?.data.zipCode);
  // useEffect(() => {
  // }, [getPatientById]);
  console.log(ethnicity);
  const handleInputZip = (e) => {
    // setTooltip('');
    const inputValue = e.target.value;
    if (inputValue.length <= 5) {
      setZipInput(inputValue);
      // setTooltip('');
    }
    // if (inputValue.includes('0000') || inputValue.includes('00000')) {
    //   setTooltip('0000 or 00000 not valid zip');
    // }
  };
  let user = JSON.parse(localStorage.getItem('user'));

  const cityResponse = useFetchCities();
  const cities = cityResponse.response ? cityResponse.response.data : [];
  const stateResponse = useFetchStates();
  const states = stateResponse.response ? stateResponse.response.data : [];
  const uploadData = async (userDetails) => {
    let userId = user?.result.data.user.userId;
    userDetails.userId = userId;
    setLoading(true);
    try {
      console.log('uploadObj ', userDetails);
      const patientId = localStorage.getItem('editPatientId');
      const res = await axios.post(`${app.URL}/api/v1/admin/patient/update/${patientId}`, userDetails, { headers: getHeaders() });
      setLoading(false);
      setShow(false);

      if (res.data.result.success) {
        setAddUserResp(res.data.result.data);
        message.success(res.data.result.msg);
        setTimeout(goBack, 1000);
      } else if (!res.data.result.success) {
        message.warning(res.data.result.msg);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
      setShow(false);
      if (err) console.log(err.response.data.message);
      else return;
    }
  };

  const handleChange = (event) => {
    setPhoneNumber(event.target.value);
    // handle phone number input here
  };


  const onFinish = (values) => {
    // o={...values}
    console.log(values);

    setUploadObj({
      firstName: values.firstName ? values.firstName : null,
      middleName: values.middleName ? values.middleName : '',
      lastName: values.lastName ? values.lastName : null,
      streetAddress: values.streetAddress ? values.streetAddress : null,
      stateId: values.state ? values.state : null,
      mobile: values.phone ? values.phone : '',
      dateOfBirth: values.birthDate ? values.birthDate.format().split('T')[0] : '',
      gender: values.gender ? values.gender : null,
      city: values.city ? values.city : null,
      email: values.email ? values.email : null,
      zipCode: zipInput ? zipInput : null,
      martialStatus: values.maritalStatus ? values.maritalStatus : null,
      ethnicId: values.ethnicId ? values.ethnicId : null,
      // organizationId: values.organizationId ? values.organizationId : null,
      isActive: values.isActive,
      // userType: 'O',
    });
    setShow(true);
  };
  console.log(uploadObj);
  const submitData = () => {
    console.log(phoneNumber, patient.mobile);
    // if ((!phoneNumber && patient.mobile.split('_')[0].length < 14) || !phoneNumber?.startsWith('1')) {
    //   setShow(false);
    //   message.error('Invalid mobile number');
    //   return;
    // } else if ((phoneNumber && phoneNumber.length < 11) || !phoneNumber?.startsWith('1')) {
    //   setShow(false);
    //   message.error('Invalid mobile number');
    //   return;
    // } else
    if (zipInput.includes('0000') && zipInput.length === 4) {
      setShow(false);
      message.error('0000 zip code is not acceptable!');
    } else if (zipInput.includes('00000')) {
      setShow(false);
      message.error('00000 zip code is not acceptable!');
    } else {
      setShow(false);
      uploadData(uploadObj);
    }
  };
  const handleClose = () => setShow(false);
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  window.addEventListener('popstate', goBack);

  // console.log(ethnicity);
  console.log(patient);
  const handleKeyPress = (e) => {
    console.log(e);
    noPrespaceNoAlphabetsNoSpecialChar(e);
  };

  const [form] = Form.useForm();
  return (
    <>
      <UpdateRecord
        loading={loading}
        pageTitle='Update Customer'
        addUserResp={addUserResp}
        response={patientObj.response}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        switchBtn={true}
        upload={false}
        uploadedImg={uploadedImg}
        show={show}
        handleClose={handleClose}
        submitData={submitData}
        updateMessage='Are you sure you want to udpate customer?'
        goBack={goBack}
        initialValues={{
          remember: true,
          ['id']: patient?.patientId,
          ['firstName']: patient?.firstName,
          ['middleName']: patient?.middleName,
          ['lastName']: patient?.lastName,
          ['streetAddress']: patient?.streetAddress,
          ['city']: patient?.city,
          ['state']: patient?.stateId,
          ['zipCode']: patient?.zipCode,
          ['phone']: patient?.mobile,
          ['email']: patient?.email,
          ['maritalStatus']: patient?.martialStatus,
          ['gender']: patient?.gender,
          ['birthDate']: patient?.dateOfBirth ? moment(patient?.dateOfBirth.split('T')[0]) : '',
          ['ethnicId']: patient?.ethnicId,
          ['isActive']: patient?.isActive,
        }}
        inputs={[
          <InputField
            name='firstName'
            required={true}
            label='First Name'
            placeholder='First Name'
            col='col-md-4 col-12'
            rules={[
              {
                required: true,
                message: 'First Name',
              },
            ]}
            onKeyPress={handleKeyPress}
          />,
          <InputField
            name='middleName'
            // required={true}
            label='Middle Name'
            placeholder='Middle Name'
            col='col-md-4 col-12'
            onKeyPress={handleKeyPress}

            // rules={[
            //   {
            //     required: true,
            //     message: 'Middle Name',
            //   },
            // ]}
          />,
          <InputField
            name='lastName'
            required={true}
            label='Last Name'
            placeholder='Last Name'
            col='col-md-4 col-12'
            rules={[
              {
                required: true,
                message: 'Last Name',
              },
            ]}
            onKeyPress={handleKeyPress}
          />,
          <InputField
            name='streetAddress'
            rows={6}
            // showCount={true}
            // maxLength={1000}
            label='Address'
            placeholder='Address'
            col='col-12'
            rules={[
              {
                message: 'Street Address',
              },
            ]}
          />,
          <InputField
            name='city'
            label='City'
            placeholder='City'
            col='col-md-4 col-12'
            rules={[
              {
                message: 'Enter City',
              },
            ]}
            onKeyPress={handleKeyPress}
          />,
          <div className='col-md-4 col-12'>
            <label>ZIP Code</label>

            <Input required={true} value={zipInput} onChange={handleInputZip} className='num-input' type='number' maxLength={5} placeholder='ZIP Code' />
          </div>,
          <div className='col-md-4 col-12'>
            <label>
              Date of Birth <span className='required-star'>*</span>
            </label>
            <Form.Item
              name='birthDate'
              rules={[
                {
                  required: true,
                  message: 'Select a date',
                },
              ]}
            >
              <DatePicker placeholder='Date of birth' format={'MM/DD/YYYY'} />
            </Form.Item>
          </div>,
          <InputField
            name='email'
            required={true}
            label='Email'
            disabled={true}
            placeholder='Email'
            col='col-md-4 col-12'
            rules={[
              {
                required: true,
                message: 'Enter Email',
              },
            ]}
            onKeyPress={noPrespace}
          />,
        ]}
        selects={[
          <SelectField
            options={states.map((item) => (
              <Select.Option value={item.stateId}>{item.name}</Select.Option>
            ))}
            col='col-md-4 col-12'
            placeholder='Select State'
            rules={[
              {
                required: true,
                message: 'Select State',
              },
            ]}
            label='State'
            name='state'
            required={true}
          />,
          <SelectField
            options={
              <>
                <Select.Option value='M'>Married</Select.Option>
                <Select.Option value='S'>Single</Select.Option>
                <Select.Option value='D'>Divorced</Select.Option>
                <Select.Option value='W'>Widowed</Select.Option>
              </>
            }
            col='col-md-4 col-12'
            placeholder='Select Marital Status'
            label='Marital Status'
            name='maritalStatus'
          />,
          <SelectField
            options={
              <>
                <Select.Option value='M'>Male</Select.Option>
                <Select.Option value='F'>Female</Select.Option>
                {/* <Select.Option value='O'>Other</Select.Option> */}
              </>
            }
            col='col-md-4 col-12'
            placeholder='Select Gender'
            label='Gender at Birth'
            name='gender'
          />,
          // <SelectField
          //   options={ethnicity.map((item) => (
          //     <Select.Option value={item.ethnicId}>{item.verticleType}</Select.Option>
          //   ))}
          //   col='col-lg-6'
          //   placeholder='Select Ethnicity'
          //   label='Ethnicity'
          //   name='ethnicId'
          // />,
        ]}
        inputMask={[
          <InputMasker
            name='phone'
            label='Mobile'
            placeholder='Mobile'
            col='col-md-4 col-12'
            rules={[
              {
                message: 'Enter Mobile',
              },
            ]}
            handleChange={handleChange}
          />,
        ]}
        extras={[
          <>
            <div className='col-md-12'>
              <DividerLine />
              <h1 style={{ fontSize: '1rem', fontWeight: '600', marginBottom: '1rem' }}>Contacts</h1>
              <div className='row'>
                {addressData.length > 0 && (
                  <table id='customers' className='w-100 mb-3'>
                    <thead>
                      <tr>
                        {header.map((item) => (
                          <th className='px-3'>{item}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {addressData.map((item, index) => (
                        <tr>
                          <td className='px-3'>{item.title}</td>
                          <td>{item.contactPerson}</td>
                          <td className='px-3'>
                            {item.address},{item.city},{item.state}
                          </td>
                          <td>{item.isActive ? 'Active' : 'Inactive'}</td>

                          <td className='edit-data'>
                            {/* <EditOutlined style={{ fontSize: '1.5rem', color: '#f2b544', cursor: 'pointer' }} onClick={() => props.editButton(output)} /> */}
                            <img className='cursor-pointer' style={{ width: '25px' }} alt='edit btn img' src={editBtnImg} onClick={() => handleEditClick(item)} />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>
            </div>

            {/* Modal for editing address */}
          </>,
        ]}
      />
      <UpdateAddressModal showModal={showModal} handleClosed={handleClosed} form={form} selectedAddress={selectedAddress} handleSave={handleSave} />
    </>
  );

  // return (
  //   <>
  //     <div className='col-lg-12'>
  //       <div className='dashboard-card'>
  //         <div className='row' style={{ paddingRight: '10px' }}>
  //           <div className='for-before col-lg-4 head fs-3 fw-bold' style={{ backgroundColor: '#a7c1d9', borderBottomRightRadius: '10px', color: '#FFFFFF' }}>
  //             Update Customer
  //           </div>
  //         </div>
  //         <div class='col mb-5 mt-4'>
  //           <div class='col-lg-12 align-self-center mt-2 mb-4' style={{ padding: '20px 50px' }}>
  //             {patientObj.response ? (
  //               <Form
  //                 onFinish={onFinish}
  //                 onFinishFailed={onFinishFailed}
  //                 autoComplete='off'
  //                 initialValues={{
  //                   remember: true,
  //                   ['id']: patient.patientId,
  //                   ['firstName']: patient.firstName,
  //                   ['middleName']: patient.middleName,
  //                   ['lastName']: patient.lastName,
  //                   ['streetAddress']: patient.streetAddress,
  //                   ['city']: patient.city,
  //                   ['state']: patient.stateId,
  //                   ['zipCode']: patient.zipCode,
  //                   ['phone']: patient.mobile,
  //                   ['email']: patient.email,
  //                   ['maritalStatus']: patient.martialStatus,
  //                   ['gender']: patient.gender,
  //                   ['birthDate']: patient.dateOfBirth ? moment(patient.dateOfBirth.split('T')[0]) : '',
  //                   ['ethnicId']: patient.ethnicId,
  //                   ['isActive']: patient.isActive,
  //                 }}
  //               >
  //                 {/* Select box for Role */}

  //                 <div className='row'>
  //                   <div className='col-lg-4'>
  //                     <label>
  //                       First Name <span className='required-star'>*</span>
  //                     </label>
  //                     <Form.Item
  //                       name='firstName'
  //                       defaultValue='1'
  //                       colon={false}
  //                       rules={[
  //                         {
  //                           required: true,
  //                           message: 'Frist Name',
  //                         },
  //                       ]}
  //                     >
  //                       <Input placeholder='First Name' />
  //                     </Form.Item>
  //                   </div>
  //                   <div className='col-lg-4'>
  //                     <label>Middle Initial</label>
  //                     <Form.Item name='middleName' defaultValue='' colon={false}>
  //                       <Input placeholder='Middle Name' />
  //                     </Form.Item>
  //                   </div>
  //                   <div className='col-lg-4'>
  //                     <label>
  //                       Last Name <span className='required-star'>*</span>
  //                     </label>
  //                     <Form.Item
  //                       name='lastName'
  //                       defaultValue='1'
  //                       colon={false}
  //                       rules={[
  //                         {
  //                           required: true,
  //                           message: 'Last Name',
  //                         },
  //                       ]}
  //                     >
  //                       <Input placeholder='Last Name' />
  //                     </Form.Item>
  //                   </div>
  //                 </div>
  //                 <div className='col-lg-12'>
  //                   <label>
  //                     Street Address <span className='required-star'>*</span>
  //                   </label>
  //                   <Form.Item
  //                     name='streetAddress'
  //                     defaultValue='1'
  //                     colon={false}
  //                     style={{ wordWrap: 'normal' }}
  //                     rules={[
  //                       {
  //                         required: true,
  //                         message: 'Enter Address',
  //                       },
  //                     ]}
  //                   >
  //                     <Input placeholder='Address' />
  //                   </Form.Item>
  //                 </div>

  //                 <div className='row'>
  //                   <div className='col-lg-4'>
  //                     <label>
  //                       City <span className='required-star'>*</span>
  //                     </label>
  //                     <Form.Item
  //                       name='city'
  //                       colon={false}
  //                       rules={[
  //                         {
  //                           required: true,
  //                           message: 'Enter City',
  //                         },
  //                       ]}
  //                     >
  //                       <Input placeholder='Enter City' />
  //                     </Form.Item>
  //                   </div>
  //                   <div className='col-lg-4'>
  //                     <label>
  //                       State <span className='required-star'>*</span>
  //                     </label>
  //                     <Form.Item
  //                       name='state'
  //                       defaultValue='1'
  //                       colon={false}
  //                       rules={[
  //                         {
  //                           required: true,
  //                           message: 'Enter State',
  //                         },
  //                       ]}
  //                     >
  //                       <Select placeholder='Select State'>
  //                         {states.map((item) => (
  //                           <Select.Option value={item.stateId}>{item.name}</Select.Option>
  //                         ))}
  //                       </Select>
  //                     </Form.Item>
  //                   </div>
  //                   <div className='col-lg-4'>
  //                     <label>
  //                       ZIP Code <span className='required-star'>*</span>
  //                     </label>
  //                     {/* <Form.Item
  //                       name='zipCode'
  //                       defaultValue='1'
  //                       colon={false}
  //                       rules={[
  //                         {
  //                           required: true,
  //                           message: 'Enter Zip-code',
  //                         },
  //                       ]}
  //                     > */}
  //                     {/* <Tooltip title={tooltip}> */}
  //                     <Input value={zipInput} onChange={handleInputZip} className='num-input' type='number' maxLength={5} placeholder='ZIP Code' />
  //                     {/* </Tooltip> */}
  //                     {/* </Form.Item> */}
  //                   </div>
  //                 </div>
  //                 <div className='row'>
  //                   <div className='col-lg-4'>
  //                     <label>
  //                       Date of Birth <span className='required-star'>*</span>
  //                     </label>
  //                     <Form.Item
  //                       name='birthDate'
  //                       rules={[
  //                         {
  //                           required: true,
  //                           message: 'Select a date',
  //                         },
  //                       ]}
  //                     >
  //                       {/* <RangePicker onChange={(val) => console.log(val)} /> */}
  //                       <DatePicker
  //                         placeholder='Date of birth'
  //                         format={'MM/DD/YYYY'}
  //                         //   onChange={(date, dateString) => {
  //                         //     console.log(dateString);
  //                         //   }}
  //                       />
  //                       {/* <Input type="date"  onChange={(e)=> setIssueDate(e.target.value)}/> */}
  //                     </Form.Item>
  //                   </div>
  //                   <div className='col-lg-4'>
  //                     <label>
  //                       Mobile <span className='required-star'>*</span>
  //                     </label>
  //                     <Form.Item
  //                       colon={false}
  //                       name='phone'
  //                       rules={[
  //                         {
  //                           required: true,
  //                           message: 'Enter mobile phone number',
  //                         },
  //                       ]}
  //                     >
  //                       <InputMask  mask='999 999-9999' disabled={true} onChange={handleChange}>
  //                         {(inputProps) => <Input {...inputProps} placeholder='Mobile Number' disabled style={{ width: '100% !important' }} />}
  //                       </InputMask>
  //                     </Form.Item>
  //                   </div>
  //                   <div className='col-lg-4'>
  //                     <label>
  //                       Email <span className='required-star'>*</span>
  //                     </label>
  //                     <Form.Item
  //                       colon={false}
  //                       name='email'
  //                       rules={[
  //                         {
  //                           required: true,
  //                           type: 'email',
  //                           message: 'Enter email',
  //                         },
  //                       ]}
  //                     >
  //                       <Input disabled={true} className='admin' style={{ width: '100% !important' }} />
  //                     </Form.Item>
  //                   </div>
  //                 </div>
  //                 <div className='row'>
  //                   <div className='col-lg-4'>
  //                     <label>Marital Status</label>
  //                     <Form.Item
  //                       colon={false}
  //                       name='maritalStatus'
  //                       // rules={[
  //                       //   {
  //                       //     required: true,
  //                       //     message: 'Enter Ad. Title',
  //                       //   },
  //                       // ]}
  //                     >
  //                       {/* onChange={getProjectData} */}
  //                       <Select placeholder='Select Marital Status'>
  //                         <Select.Option value='Married'>Married</Select.Option>
  //                         <Select.Option value='Single'>Single</Select.Option>
  //                         <Select.Option value='Divorced'>Divorced</Select.Option>
  //                         <Select.Option value='Widowed'>Widowed</Select.Option>
  //                         {/* {searchItems.map((item) => (
  //                       <Select.Option value={item}>{item}</Select.Option>
  //                     ))} */}
  //                       </Select>
  //                       {/* <Input placeholder='Enter Title' /> */}
  //                     </Form.Item>
  //                   </div>
  //                   <div className='col-lg-4'>
  //                     <label>Gender </label>
  //                     <Form.Item
  //                       colon={false}
  //                       name='gender'
  //                       rules={[
  //                         {
  //                           required: false,
  //                           message: 'Select a gender',
  //                         },
  //                       ]}
  //                     >
  //                       {/* onChange={getProjectData} */}
  //                       <Select placeholder='Select Gender'>
  //                         <Select.Option value='M'>Male</Select.Option>
  //                         <Select.Option value='F'>Female</Select.Option>
  //                         <Select.Option value='O'>Other</Select.Option>
  //                         {/* {searchItems.map((item) => (
  //                       <Select.Option value={item}>{item}</Select.Option>
  //                     ))} */}
  //                       </Select>
  //                       {/* <Input placeholder='Enter Title' /> */}
  //                     </Form.Item>
  //                   </div>
  //                   <div className='col-lg-4'>
  //                     <label>Ethnicity</label>
  //                     <Form.Item
  //                       colon={false}
  //                       name='ethnicId'
  //                       // rules={[
  //                       //   {
  //                       //     required: true,
  //                       //     message: 'Enter Ad. Title',
  //                       //   },
  //                       // ]}
  //                     >
  //                       {/* onChange={getProjectData} */}
  //                       <Select placeholder='Select Ethnicity'>
  //                         {ethnicity.map((item) => (
  //                           <Select.Option value={item.ethnicId}>{item.verticleType}</Select.Option>
  //                         ))}
  //                       </Select>
  //                       {/* <Input placeholder='Enter Title' /> */}
  //                     </Form.Item>
  //                   </div>
  //                 </div>
  //                 <div className='row'>
  //                   <div className='col-lg-12'>
  //                     <Form.Item label='Active' colon={false} valuePropName='checked' name='isActive'>
  //                       <Switch />
  //                     </Form.Item>

  //                     <button
  //                       onClick={() => {
  //                         navigate('/patient/listing');
  //                       }}
  //                       type='primary'
  //                       className=' btn btn-primary button patient-cancel-btn col-lg-3 mr-2'
  //                     >
  //                       Cancel
  //                     </button>

  //                     <button type='primary' className='btn btn-primary button col-lg-3' htmlType='submit'>
  //                       Save
  //                     </button>
  //                   </div>
  //                   <div className='col-lg-4 offset-lg-2'>
  //                     <Form.Item
  //                     // wrapperCol={{
  //                     //   offset: 8,
  //                     //   span: 16,
  //                     // }}
  //                     >
  //                       {addUserResp.code === 200 ? (
  //                         <Modal show={show} onHide={handleClose}>
  //                           <Modal.Header closeButton>
  //                             <Modal.Title className='text-center'>Success</Modal.Title>
  //                           </Modal.Header>
  //                           <Modal.Body>{addUserResp.message}</Modal.Body>
  //                           <Modal.Footer></Modal.Footer>
  //                         </Modal>
  //                       ) : addUserResp.code === 400 ? (
  //                         <Modal show={show} onHide={handleClose}>
  //                           <Modal.Header closeButton>
  //                             <Modal.Title className='text-center'>Failed</Modal.Title>
  //                           </Modal.Header>
  //                           <Modal.Body>{addUserResp.data.message}</Modal.Body>
  //                           <Modal.Footer></Modal.Footer>
  //                         </Modal>
  //                       ) : (
  //                         <Modal show={show} onHide={handleClose}>
  //                           {/* <Modal.Header closeButton>
  //                             <Modal.Title>Confirmation</Modal.Title>
  //                           </Modal.Header> */}
  //                           <Modal.Body>Are you sure, to update the customer information?</Modal.Body>
  //                           <Modal.Footer>
  //                             <Button variant='secondary' onClick={handleClose}>
  //                               Cancel
  //                             </Button>
  //                             <Button variant='primary' htmlType='submit' onClick={submitData}>
  //                               Confirm
  //                             </Button>
  //                           </Modal.Footer>
  //                         </Modal>
  //                       )}
  //                     </Form.Item>
  //                   </div>
  //                 </div>
  //               </Form>
  //             ) : (
  //               ''
  //             )}
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   </>
  // );
};

export default EditPatient;
