import { configureStore } from '@reduxjs/toolkit';
import adminReducer from './adminSlice';
import organizationReducer from './organizationSlice';
const store = configureStore({
  reducer: {
    admin: adminReducer,
    organization: organizationReducer,
  },
});

export default store;
