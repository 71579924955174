import axios from 'axios';
import { useEffect, useState } from 'react';
import {app} from '../config'
import getHeaders from '../utils/authHeaders';
const getProductById = (id) => {
    let reqid=id
    const [loading, setLoading] = useState(true);
    const [response, setResponse] = useState(null);
    const [error, setError] = useState(null);
    useEffect(() => {
        const getproduct = async (id) => {
          try {
            const res = await axios.get(`${app.URL}/api/v1/admin/product/view/${id}`, {headers: getHeaders()});
            setResponse(res.data.result);
            setLoading(false);
          } catch (err) {
            setError(err);
            setLoading(false);
          }
        };
        getproduct(reqid);
      }, []);
    
      return { loading, response, error };
    };
export default getProductById;