import React, { useEffect, useState } from 'react';
import axios from 'axios';
// import { app } from '../../config';
// import getHeaders from '../../utils/authHeaders';
import { Pagination, Input, Form, Select, Row, Col, DatePicker, Button, message, Switch, Space } from 'antd';
// import { app } from '../../../config';
// import getHeaders from '../../../utils/authHeaders';
import { useNavigate } from 'react-router-dom';
import ListinngCard from '../../layouts/ListingCard';
import { app } from '../../config';
import getHeaders from '../../utils/authHeaders';
import Modal from 'react-bootstrap/Modal';
import { MeasuredForm } from './MeasuredForm';
import singleFaqDetail from './singleFaqDetail';
import { InputField } from '../../layouts/FormFields';
import TestComponent from '../Products/TestComponent';
import LoaderImage from '../LoaderImage';

const FaqDetails = () => {
  //   const [tableHeaders, setTableHeaders] = useState([]);
  const [loader, setLoader] = useState(false);
  const [isActive, setisActive] = useState(false);
  const [question, setquestion] = useState('');
  const [answer, setanswer] = useState('');

  const [renderList, setrenderList] = useState([]);
  const [form] = Form.useForm();

  const [editObj, setEditObj] = useState({});
  const [formLoader, setFormLoader] = useState(false);
  let [textEditorValue, settextEditorValue] = useState('');

  let renderListType = [
    { value: 'richText', title: 'Rich Text' },
    { value: 'image', title: 'Image' },
  ];

  let navigate = useNavigate();

  let faqCategoryId = localStorage.getItem('faqCategoryId');
  const FaqDetailListingApi = async () => {
    setLoader(true);
    console.log('faqCategoryId', faqCategoryId);
    let productResp = await axios.get(`${app.URL}/api/v1/admin/faqcategory/view/${faqCategoryId}`, { headers: getHeaders() });
    console.log('xcdd', productResp);
    productResp?.data?.result?.data ? setrenderList(productResp.data.result.data) : '';
    productResp?.data?.result?.data && setLoader(false);
  };

  useEffect(() => {
    FaqDetailListingApi();
  }, []);

  useEffect(() => {
    console.log('entered');
    const fetchData = async () => {
      try {
        const res = await axios.get(`${app.URL}/api/v1/admin/faqs/view/${id}`, { headers: getHeaders() });

        console.log('response is : ', res);

        console.log(res.data.result.data);
        // setwhatMeasuredData(res.data.result.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, []);
  const onFinish = (values) => {
    console.log('Success:', values);
  };
  const tableHeaders = ['Question', 'Answer', 'Index', 'Status', 'Detail'];
  const updateWhatMeasured = async (values, faqId) => {
    console.log('values outerscope', values);
    try {
      const res = await axios.post(`${app.URL}/api/v1/admin/faqs/update/${faqId}`, values, { headers: getHeaders() });
      console.log(' res', res);
      resetForm();
      setLoader(false);
      setFormLoader(true);
      setTimeout(() => {
        setFormLoader(false);
      }, 100);
      FaqDetailListingApi();
      // setwhatMeasuredData(res?.data?.result?.data);
      setEditObj({});
    } catch (err) {
      setLoader(false);
      console.log(err);
    }
  };

  const submitform = async (e) => {
    try {
      const res = await axios.post(`${app.URL}/api/v1/admin/faqs/create`, e, { headers: getHeaders() });
      if (res.status == 200) {
        setLoader(false);
        res.data?.result?.data ? FaqDetailListingApi() : '';
        setquestion(''); // Reset the question state
        setanswer(''); // Reset the answer state
        setisActive(false); // Reset the active state if necessary
        setLoader(false);
        setFormLoader(true);
        settextEditorValue('');
        setTimeout(() => {
          setFormLoader(false);
        }, 100);

        FaqDetailListingApi();
        form.resetFields();
      }
      // setLoader(false);
      // res.data?.result?.data ? setrenderList(res.data.result.data.filter((item) => item.faqCategoryId == faqCategoryId)) : '';
      // renderList.push()

      console.log(res);
    } catch (error) {}
  };
  // Function For checking empty richtextfield
  const isEmpty = (content) => {
    const doc = new DOMParser().parseFromString(content, 'text/html');
    return !doc.body.innerText.trim();
  };
  const onFinishForm = (values) => {
    console.log('Values', values, textEditorValue);

    if (!textEditorValue||isEmpty(textEditorValue) ) {
      message.error('Please input answer for your question');
      return;
    }

   

    

    const payload = {
      ...values,
      answer: textEditorValue,
      faqCategoryId: faqCategoryId,
    };
    // let payload = {
    //   // faqId:6,
    //   question: values.Question ? values.Question : '',
    //   answer: values.Answer ? values.Answer : '',
    //   index: values.Index,
    //   isActive: values.isActive ==true ? true: false,
    //   faqCategoryId: faqCategoryId,
    // };
    setLoader(true);

    if (Object.keys(editObj).length > 0) {
      updateWhatMeasured(payload, editObj.faqId);

      // setTimeout(() => {
      //   settextEditorValue('');
      // }, 1000);
    } else {
      submitform(payload);

      // .then(() => {
      //   setLoader(false);

      //   setTimeout(() => {
      //     settextEditorValue('');
      //   }, 1000);

      //   form.resetFields();
      // })
      // .catch((err) => {
      //   setLoader(false);
      //   console.error('Error submitting form:', err);
      // });
    }
  };
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const editQuestions = (e) => {
    console.log('Edit Question Function', e);
    setFormLoader(true);
    setEditObj(e);
    setTimeout(() => {
      setFormLoader(false);
    }, 100);
  };
  console.log('answer', answer);
  const handleEditorChange = (htmlContent) => {
    settextEditorValue(htmlContent);
    // Do something with the htmlContent, such as saving it to state or sending it to an API
  };

  useEffect(() => {
    if (editObj) {
      // Set form field values when editObj changes
      form.setFieldsValue({
        question: editObj.question,
        index: editObj.index,
        isActive: editObj.isActive,
      });
      settextEditorValue(editObj.answer);
    }
  }, [editObj]);
  console.log('Text Editor Value', textEditorValue);
  const resetForm = () => {
    form.resetFields();
    settextEditorValue('');
    setEditObj();
    setFormLoader(true);

    setTimeout(() => {
      setFormLoader(false);
    }, 100);
  };

  return (
    <div>
      <ListinngCard
        heading='FAQ Question'
        faqQuestions={
          <Form form={form} name='basic' onFinish={onFinishForm} autoComplete='off' className='row bg-white rounded p-5 px-3 pb-1'>
            <div className='col-md-8 col-12 input-column'>
              <div className='row'>
                <InputField
                  col='col-md-6 '
                  name='question'
                  rules={[
                    {
                      required: true,
                      message: 'Enter Question',
                    },
                  ]}
                  placeholder='Question'
                  label='Question'
                  required={true}
                />

                <InputField
                  pattern='^[0-9]*$'
                  col='col-md-6'
                  name='index'
                  rules={[
                    {
                      required: true,
                      message: 'Enter Index',
                    },
                  ]}
                  placeholder='Index'
                  label='Index'
                  required={true}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />
              </div>
            </div>
            <div className='col-lg-12 mb-2'>
              <Form.Item label='Active' className='selector-main' colon={false} valuePropName='checked' name='isActive'>
                <Switch />
              </Form.Item>
            </div>
            <div className={`  col-md-6 col-12 mb-5  required`}>{!formLoader && <TestComponent title='Answer' onChange={handleEditorChange} textvalue={textEditorValue} />}</div>

            <div className='row'>
              <button onClick={resetForm} className='btn btn-primary button patient-cancel-btn col-md-2 mx-md-2 mr-2 mb-2' htmlType='button'>
                Cancel
              </button>

              <button type='primary' className='btn btn-primary button col-md-2 mb-2' htmlType='submit'>
                Save
              </button>
            </div>

            <button onClick={() => navigate('/faqcategory/listing')} type='primary' className='btn btn-primary button col-md-2 mt-2 mb-2 ms-2' htmlType='button'>
              Back
            </button>
          </Form>
        }
        formLoader={formLoader}
        editObj={editObj}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        tableHeaders={tableHeaders}
        tableRows={renderList}
        editButton={editQuestions}
        FaqDetails={true}
        anuraListing={false}
        setisActiv={setisActive}
        inventoryBatchListing={false}
        patientsListing={false}
        inventoryListing={false}
        userListings={false}
        storeListing={false}
        testResultListing={false}
        stockBalanceListing={false}
        productListing={false}
        BlogsListing={false}
        // searchValue={searchData}
        switchBtn={true}
        // onChange={handleOnChange}
        loader={loader}
        form={form}
        setquestion={setquestion}
        setanswer={setanswer}
        onFinishForm={onFinishForm}
        answer={answer}
      />

      {/* <div
                        onClick={() => {
                          setaddWhatMeasured(true);
                        }}
                        style={{
                          display: whatMeasuredData?.length < 4 ? 'block' : 'none',
                        }}
                        type='primary'
                        className={`btn btn-primary patient-cancel-btn button col-md-3 mx-md-2 mb-4 `}
                      >
                        Add Details
                      </div>
                  */}

      {/* <Modal
        size='lg'
        show={Object.keys(measuredModal).length > 0 ? true : false}
        onHide={() => {
          setMeasuredModal({});
        }}
        style={{
          background: '#e1e1e131',
        }}
      >
        <Modal.Header>
          <Modal.Title className='text-center w-100'>FAQ Question</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <MeasuredForm
            onHide={() => {
              setMeasuredModal({});
            }}
            measuredModal={measuredModal}
            renderListType={renderListType}
            MeasuredCategoriesArray={MeasuredCategoriesArray}
            updateWhatMeasured={updateWhatMeasured}
            screen={'update'}
            setwhatMeasuredData={setwhatMeasuredData}
            whatMeasuredData={whatMeasuredData}
          />
        </Modal.Body>
      </Modal> */}

      {/* <Modal
        size='lg'
        show={addWhatMeasured}
        onHide={() => {
          setaddWhatMeasured(false);
        }}
        style={{
          background: '#e1e1e131',
        }}
      >
        <Modal.Header>
          <Modal.Title className='text-center w-100'>What Measured Category</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <MeasuredForm
            onHide={() => {
              setaddWhatMeasured(false);
            }}
            renderListType={renderListType}
            MeasuredCategoriesArray={MeasuredCategoriesArray}
         
            // createWhatMeasured={createWhatMeasured}
            setwhatMeasuredData={setwhatMeasuredData}
            whatMeasuredData={whatMeasuredData}
            screen={'update'}
          />
        </Modal.Body>
      </Modal>  */}
    </div>
  );
};

export default FaqDetails;
