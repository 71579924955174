import axios from 'axios';
import { useEffect, useState } from 'react';
import { app } from "../config"
import getHeaders from '../utils/authHeaders';
const getProductInventory = (id) => {
    let reqid = id
    const [loading, setLoading] = useState(true);
    const [response, setResponse] = useState(null);
    const [error, setError] = useState(null);
    useEffect(() => {
        const getInventory = async (id) => {
            console.log(id)
            try {
                const res = await axios.get(`${app.URL}/api/v1/admin/inventory/view/${id}`, { headers: getHeaders() });
                console.log(res)
                setResponse(res.data.result);
                setLoading(false);
            } catch (err) {
                setError(err);
                setLoading(false);
            }
        };
        getInventory(reqid);
    }, []);

    return { loading, response, error };
};
export default getProductInventory;