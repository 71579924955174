export const noPrespaceNoAlphabetsNoSpecialChar = (e) => {
//   console.log('hi');
console.log(e.charCode);
console.log(e.target.value.length);
if (e.target.value.length == 0 && e.charCode === 32) {
  e.preventDefault();
}
if ((e.charCode <= 64 || e.charCode > 122 || e.charCode == 91 || e.charCode == 92 || e.charCode == 93 || e.charCode == 94 || e.charCode == 95 || e.charCode == 96) && e.charCode !== 32) {
  e.preventDefault();
}
};
export const noPrespace = (e) => {
  //   console.log('hi');
  console.log(e.charCode);
  console.log(e.target.value.length);
  if (e.target.value.length == 0 && e.charCode === 32) {
    e.preventDefault();
  }
  
};
export const onlyNumbers = (e) => {
  //   console.log('hi');
  console.log(e.charCode);
  console.log(e.target.value.length);
  if (e.charCode <= 47 || e.charCode > 58 ) {
    e.preventDefault();
  }
};
export const noNumbers = (e) => {
  //   console.log('hi');
  console.log(e.charCode);
  console.log(e.target.value.length);
  if (e.charCode >= 48 && e.charCode <= 58) {
    e.preventDefault();
  }
};
// module.exports = { noPrespaceNoAlphabetsNoSpecialChar };
